import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorswitcherService {

 
  private colors = new BehaviorSubject("default-theme");
  currentColors = this.colors.asObservable();
 
  private zoom = new BehaviorSubject("default-zoom");
  currentZoom = this.zoom.asObservable();

  //currentColors =  sessionStorage.getItem('theme-color');
  constructor() {  }
  
  
  updateColor(message: string) {
    sessionStorage.setItem('theme-color' , message);
    this.colors.next(message)
  }

  updateZoom(message: string) {
    sessionStorage.setItem('zoom-level' , message);
    this.zoom.next(message)
  }
 

}






import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroupDirective, NgForm, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Captcha, Citizen } from 'projects/common-lib/src/lib/models';
import { CountryModel } from 'projects/common-lib/src/lib/models/CountryModel';

import { CitizenUserProfileDetails, Demography } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-quick-reg-demographic-details',
  templateUrl: './quick-reg-demographic-details.component.html',
  styleUrls: ['./quick-reg-demographic-details.component.scss']
})
export class QuickRegDemographicDetailsComponent implements OnInit {

  panelOpenState = true;
  addisabled: boolean = false;
  detailCheckedVal: boolean = false;
  consentChecked: boolean = false;

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild('profilePersonalDetailHtml', { static: true }) form: NgForm;

  @Input() citizenType: any;
  @Input() isIndian: any;
  @Input() mobileNo: any;
  @Input() kycSource: string;
  @Input() kycObject: any;
  @Input() juristicPersonProfileModel: any;

  @Output() demographicDetailsSaveOut = new EventEmitter<any>();

  dobmaxdate: any = new Date();

  emailtaken = false;
  emailavail = false;

  stateId: any;
  districtId: any;

  confirmPass: any;

  districts: Demography[] = [];
  districtsPre: Demography[] = [];
  states: Demography[] = [];
  country: CountryModel[] = []

  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();

  profileImage: any;
  lan: number = 0
  relativeNameDisable: boolean = false;
  firstNameDisable: boolean = false;
  middleNameDisable: boolean = false;
  lastNameDisable: boolean = false;
  dobDisable: boolean = false;
  genderDisable: boolean = false;
  addressLine1Disable: boolean = false;
  addressLine2Disable: boolean = false;
  cityDisable: boolean = false;
  postalCodeDisable: boolean = false;
  invalidAltMob: boolean = false;
  invalidAltEmail: boolean = false;
  //invalidPasswordCheck: boolean = false;
  isCountryDisable: boolean = false;

  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec

  getOtpClicked: boolean = false;
  otpTouched: boolean = false;
  otpBtnVisibility: boolean = true;
  otp: any;
  verifyMobileNo: boolean = false;

  countDownForEmail: Subscription;
  counterForEmail = 0; // 10 min 10*60
  tickForEmail = 1000; // 1 sec

  getOtpClickedForEmail: boolean = false;
  otpTouchedForEmail: boolean = false;
  otpBtnVisibilityForEmail: boolean = true;
  otpForEmail: any;
  verifyEmail: boolean = false;

  captchaHandler?: Captcha = {};
  captchaImageBase64Img?: string = "";
  captchaId: any;
  captchaStr: any;

  citizen: Citizen = new Citizen();

  userEmails = new FormGroup({
    primaryEmail: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),

  });

  constructor(private common: CommonService
    , private citizenAuthService: CitizenAuthenticationService
    , private ngxService: NgxUiLoaderService
    , private translateService: TranslateHEService
    , private translate: TranslateService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , private router: Router) {
    try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 0;
        selectedLanguage = 'en';
      }

      //translate.use(selectedLanguage).toPromise();
      this.lan = selectedLanguage == 'hi' ? 1 : 0;
     // this.translateService.updateApprovalMessage(this.lan);
      this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
    } catch (err) {
     // console.log(err);
    }
    config.backdrop = 'static';
    config.keyboard = false;
    this.userProfileModel.title = 'Mr.';
  }
  open(content: any) {
    this.modalService.open(content, { size: 'xl' });
  }


  ngOnInit(): void {
    this.getStartupCaptcha();
    this.userProfileModel.state = '';
    this.userProfileModel.statePre = '';
    this.userProfileModel.country = '';
    //this.userProfileModel.countryName = '';
    this.stateId = "";
    this.districtId = "";

    this.getStates();

    this.userProfileModel.citizenType = 1;
    this.userProfileModel.isIndianResident = 1;
    this.userProfileModel.kycType = this.kycSource;

  }

  resetForm() {
    this.getOtpClicked = false;
    this.counter = 0;
    this.otp = null;
    this.otpTouched = false;
    this.otpBtnVisibility = true;
  }

  resetFormForEmail() {
    this.getOtpClickedForEmail = false;
    this.counterForEmail = 0;
    this.otpForEmail = null;
    this.otpTouchedForEmail = false;
    this.otpBtnVisibilityForEmail = true;
  }

  setOtpClicked() {
    // var regexp = new RegExp('^[6-9]\d{9}$');
    let mb: string = this.userProfileModel.contactNo;
    if (this.userProfileModel.contactNo == null || this.userProfileModel.contactNo == undefined || this.userProfileModel.contactNo == "") {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    } else if (mb.length != 10) {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }

    let mb1: string = mb.substring(0, 1);
    if (mb1 != '9' && mb1 != '8' && mb1 != '7' && mb1 != '6') {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    } else {
      this.citizen.mobileNo = this.userProfileModel.contactNo;
      this.citizenAuthService.getOtpForQuickReg(this.citizen).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.getOtpClicked = true;
          this.otpTouched = true;
          this.otpBtnVisibility = false;
          this.counter = 30;
          this.countDown = timer(0, this.tick).subscribe(() => {
            if (this.counter > 0) {
              --this.counter;
            }
          });
          this.alertmessage.successAlertMessage(res);
        } else {
          this.getOtpClicked = false;
          this.otpTouched = false;
          this.otpBtnVisibility = true;
          this.alertmessage.errorAlertMessage(res);
        }
      });
    }
  }

  verifyOtp() {
    if (this.otp == null || this.otp == undefined || this.otp == "") {
      this.alertmessage.errorAlertMessage('otp-error');
    } else {
      this.citizenAuthService.verifyOtp(this.userProfileModel.contactNo, this.otp).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          this.getOtpClicked = false;
          this.counter = 0;
          this.verifyMobileNo = true;

          this.otpTouched = false;
          this.otpBtnVisibility = false;
        } else {
          this.alertmessage.errorAlertMessage(res);
          this.otp = null;
        }
      });
    }
  }

  setOtpClickedForEmail() {
    if (this.userProfileModel.email == null || this.userProfileModel.email == undefined || this.userProfileModel.email == "") {
      this.getOtpClickedForEmail = false;
      this.alertmessage.errorAlertMessage('email-error');
      return;
    }

    //var pattern = '^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$';
    var pattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
    if (this.userProfileModel.email != undefined && this.userProfileModel.email != '') {
      if (!this.userProfileModel.email.match(pattern)) {
        this.alertmessage.errorAlertMessage('email-error');
        return;
      }
      else {
        this.citizen.mobileNo = this.userProfileModel.contactNo;
        this.citizen.email = this.userProfileModel.email;
        //this.citizen.userType = 'citizen';
        this.citizenAuthService.getOtpForQuickRegForEmail(this.citizen).subscribe(res => {
          // 
          if (res && res.responseStatus == 'true') {
            this.getOtpClickedForEmail = true;
            this.otpTouchedForEmail = true;
            this.otpBtnVisibilityForEmail = false;
            this.counterForEmail = 30;
            this.countDownForEmail = timer(0, this.tickForEmail).subscribe(() => {
              if (this.counterForEmail > 0) {
                --this.counterForEmail;
              }
            });
            this.alertmessage.successAlertMessage(res);
          } else {
            this.getOtpClickedForEmail = false;
            this.otpTouchedForEmail = false;
            this.otpBtnVisibilityForEmail = true;
            this.alertmessage.errorAlertMessage(res);
          }
        });
      }
    }
  }

  verifyOtpForEmail() {
    if (this.otpForEmail == null || this.otpForEmail == undefined || this.otpForEmail == "") {
      this.alertmessage.errorAlertMessage('otp-error');
    } else {
      this.citizenAuthService.verifyOtpForQuickRegForEmail(this.userProfileModel.email, this.otpForEmail, this.userProfileModel.contactNo).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          this.getOtpClickedForEmail = false;
          this.counterForEmail = 0;
          this.verifyEmail = true;

          this.otpTouchedForEmail = false;
          this.otpBtnVisibilityForEmail = false;
        } else {
          this.alertmessage.errorAlertMessage(res);
          this.otpForEmail = null;
        }
      });
    }
  }

  getStartupCaptcha() {
    // 
    this.ngxService.start();
    this.citizenAuthService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id;
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('captcha-error');
      }
    );
  }

  getStates() {
    // this.common.getStates().subscribe(res=>{
    //  this.states = [{ "id": 1, "nameEn": "Madhya Pradesh", "nameHi": "मध्य प्रदेश", "name": "Madhya Pradesh"}];
    // });

    this.common.getStates().subscribe(res => {
      this.states = res.responseData;
    });
  }

  getDistricts(state: string) {
    this.common.getDistrictsWithParent(state).subscribe(res => {
      this.districts = res.responseData;
    });
  }

  setState() {
    this.userProfileModel.state = this.stateId;
    let demoNameEn = this.states.find(t => t.id == this.stateId)?.demographyNameEn;
    let demoNameHi = this.states.find(t => t.id == this.stateId)?.demographyNameHi;
    this.userProfileModel.stateName = (demoNameEn ? demoNameEn : "") + " - " + (demoNameHi ? demoNameHi : "");
    this.getDistricts(this.userProfileModel.state);

    this.userProfileModel.demography = new Demography;
    this.districtId = "";
  }

  setDistrict() {
    let demo: Demography = new Demography();
    demo.id = this.districtId;
    let demoNameEn = this.districts.find(t => t.id == this.districtId)?.demographyNameEn;
    let demoNameHi = this.districts.find(t => t.id == this.districtId)?.demographyNameHi;

    demo.demographyNameEn = demoNameEn ? demoNameEn : "";
    demo.demographyNameHi = demoNameHi ? demoNameHi : "";
    this.userProfileModel.demography = demo;
  }

  getEmailAvailability(email: any) {
    if (email == null || email == '' || email == undefined) {
      this.emailtaken = false;
      this.emailavail = false;
    } else {
      this.citizenAuthService.checkAvailabilityOfEmail(email).subscribe(res => {
        // console.log(res);
        if (res && res.responseStatus == 'true') {
          this.emailtaken = true;
          this.emailavail = false;
          this.userProfileModel.email = '';
        } else {
          this.emailavail = true;
          this.emailtaken = false;
          this.userProfileModel.email = email;
        }
      });
    }

  }

  onSubmitForPersonalProfile(event: any, form: any) {
    let fld = "";
    if (form.status == 'INVALID') {
      for (const field in form.controls) { // 'field' is a string
        const control = form.get(field); // 'control' is a FormControl 
        if (control.status == "INVALID") {
          fld = field;
          // console.log("---------------controle--fld----11111-------- ",fld);
          break;
        }
      }
      // console.log("---------------controle--fld------------ ",fld);
      let err = "";
      if (fld == "firstNameEn") {
        err = 'citizen-registration-details.personal-details.fullname';
      }
      else if (fld == "lastNameEn") {
        err = 'citizen-registration-details.personal-details.fullname';
      }
      else if (fld == "firstNameHi") {
        err = 'citizen-registration-details.personal-details.fullnamehi';
      }
      else if (fld == "lastNameHi") {
        err = 'citizen-registration-details.personal-details.fullnamehi';
      }
      else if (fld == "stateIds") {
        err = 'citizen-profile-details.citizen-contact-details.state';
      }
      else if (fld == "districtIds") {
        err = 'citizen-profile-details.citizen-contact-details.district';
      }
      else if (fld == "addressLine1") {
        err = 'citizen-profile-details.citizen-contact-details.address-line-1';
      }
      else if (fld == "city") {
        err = 'citizen-profile-details.citizen-contact-details.city';
      }
      else if (fld == "postalCode") {
        err = 'citizen-profile-details.citizen-contact-details.postal-code';
      }
      else if (fld == "captchaStr") {
        err = 'mobile-captcha-error';
      }

      this.alertmessage.errorAlertMessage(err, 'required');

    }
    else if (!this.otpTouched && this.otpBtnVisibility) {
      this.alertmessage.errorAlertMessage('');
    }
    // else if (!this.otpTouchedForEmail && this.otpBtnVisibilityForEmail) {
    //   this.alertmessage.errorAlertMessage('mobile-captcha-error');
    // }

    else {
      this.ngxService.start()
      if(this.userProfileModel.postalCode=='000000' || this.userProfileModel.postalCode.charAt(0)=='0')
      {
        this.alertmessage.errorAlertMessage('postal-error');
        return;
      }
      //this.userProfileModel.contactNo = this.mobileNo;
      this.userProfileModel.userName = this.userProfileModel.contactNo;
      this.userProfileModel.captchaId = this.captchaId;
      this.userProfileModel.captchaStr = this.captchaStr;
      this.userProfileModel.isFullProfile = 0;
      this.citizenAuthService.updateCitizenProfile(this.userProfileModel).subscribe(res => {
        this.ngxService.stop();
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          setTimeout(() => {
            this.router.navigate(['/clogin']);
          }, 5000)

        } else {
          this.alertmessage.errorAlertMessage(res);
        }
      });
    }
  }

  send(source: any, destination: any) {
    if (this.lan == 0) {
      this.translateService.translateLang(source.value).subscribe(
        (res: any) => {
          let name: string = destination.name;
          this.form.controls[name].setValue(res);
        },
        err => {
        //  console.log(err);
        }
      );
    }
  }


  sendHi(source: any, destination: any) {
    if (this.lan == 1) {
      this.translateService.translateLangHi(source.value).subscribe(
        (res: any) => {
          let name: string = destination.name;
          this.form.controls[name].setValue(res);
        },
        err => {
       //   console.log(err);
        }
      );
    }
  }

  convertHindi(source: any, targetName: string): any {
    console.log('source: ' + source);
    let hiValue: string = source
    if (hiValue.includes("S/O") || hiValue.includes("C/O")) {
      hiValue = hiValue.substring(4, hiValue.length)
    }
    console.log('hiValue: ' + hiValue);

    if (source != undefined && source != null && source != '') {
      this.translateService.translateLang(hiValue).subscribe(
        (res: any) => {
          console.log('res: ' + res);

          if (targetName == 'fNameHi')
            this.userProfileModel.firstNameHi = res;
          if (targetName == 'mNameHi')
            this.userProfileModel.middleNameHi = res;
          if (targetName == 'lNameHi')
            this.userProfileModel.lastNameHi = res;
          if (targetName == 'relativeNameHi') {
            this.userProfileModel.relativeNameHi = res;
          }

          return res;
        },
        err => {
        //  console.log(err);
          return "";
        }
      );
    }
  }
}

<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>
          {{'detailsPages.eStampDetail' | translate}}
        </h2>
        <!-- <button mat-button class="btn btn-primary" id="sticky_Div1" >
           {{'detailsPages.stampDutyRegis' | translate}}
        </button> -->
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
        <div class="row">
          <div class="col-md-6">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is an e-stamp?</div>
                    <div *ngIf="lan == 1">ई-स्टाम्प क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      E-stamp is a digital method of paying stamp duty to the
                      government for various legal documents and transactions.
                      It is a secure and convenient way of stamping documents
                      online, without the need for physical stamps or franking
                      machines.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      ई-स्टांप विभिन्न कानूनी दस्तावेजों और लेनदेन के लिए सरकार
                      को स्टांप शुल्क का भुगतान करने का एक डिजिटल तरीका है। यह
                      भौतिक टिकटों या फ्रैंकिंग मशीनों की आवश्यकता के बिना,
                      दस्तावेजों पर ऑनलाइन मुहर लगाने का एक सुरक्षित और
                      सुविधाजनक तरीका है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Who can generate an e-stamp?</div>
                    <div *ngIf="lan == 1">ई स्टाम्प कौन जनरेट कर सकता है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      With Sampada 2.0, any person registered in the system can
                      generate the e-Stamp through the Sampada Portal by online
                      payment. The e-Stamp can be generated for self as well as
                      on behalf of others.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपदा 2.0 के साथ, सिस्टम में पंजीकृत कोई भी व्यक्ति ऑनलाइन
                      भुगतान करके संपदा पोर्टल के माध्यम से ई-स्टाम्प जनरेट कर
                      सकता है। ई-स्टाम्प स्वयं के लिए और साथ ही दूसरों की ओर से
                      जनरेट किया जा सकता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Type and use of e-stamp.</div>
                    <div *ngIf="lan == 1">
                      ई-स्टाम्प का प्रकार और उपयोग करें
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      There are following two types of e-Stamps -
                    </p>
                    <ol>
                      <li> Judicial - Judicial stamps are also known as court fee stamps and are used for legal purposes
                        in the courts such as filing affidavits, petitions,
                        suits etc. </li>
                      <li> Non-Judicial – Non-Judicial stamps are used for the registration of various documents and
                        transactions. </li>

                    </ol>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      ई-स्टाम्प निम्नलिखित दो प्रकार के होते हैं -

                    </p>
                    <ol>
                      <li>न्यायिक - न्यायिक टिकटों को कोर्ट शुल्क स्टाम्प के रूप में भी जाना जाता है और इसका उपयोग
                        अदालतों
                        में कानूनी उद्देश्यों जैसे हलफनामे, याचिका, मुकदमे आदि दाखिल करने के लिए किया जाता है।</li>
                      <li>गैर-न्यायिक - गैर-न्यायिक टिकटों का उपयोग विभिन्न दस्तावेजों और लेनदेन के पंजीकरण के लिए किया
                        जाता है।</li>
                    </ol>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Process of e-stamp.</div>
                    <div *ngIf="lan == 1">ई-स्टाम्प की प्रक्रिया।</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      To intiate E-Stamp follow below steps
                    </p>
                    <ul>

                      <li> Login on SAMPADA 2.0</li>
                      <li> Click on E-Stamp </li>
                      <li> Initiate E-Stamp </li>
                      <li> Select Document</li>
                      <li> Add Party Details</li>
                      <li> Make Payment</li>
                      <li> Do Deed Drafting </li>
                      <li> Parties perform E-sign </li>
                      <li> E-Stamp will get generated.</li>
                    </ul>
                  </div>
                  <div *ngIf="lan == 1">
                    <p> ई-स्टाम्प शुरू करने के लिए नीचे दिए गए चरणों का पालन करें </p>
                    <ul>

                      <li> सम्पदा 2.0 पर लॉग इन करें</li>
                      <li> ई-स्टांप पर क्लिक करें</li>
                      <li> ई-स्टाम्प आरंभ करें</li>
                      <li> दस्तावेज़ चुनें</li>
                      <li> पक्षकार विवरण जोड़ें</li>
                      <li> भुगतान करें</li>
                      <li> डीड ड्राफ्टिंग करें</li>
                      <li> पार्टियां ई-साइन करती हैं</li>
                      <li> ई-स्टांप जेनरेट हो जाएगा.</li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Pre-requisite to generate e-stamp.</div>
                    <div *ngIf="lan == 1">
                      ई-स्टाम्प जनरेट करने के लिए आवश्यक शर्तें
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      To generate an E-stamp below are the prerequisites:
                    </p>
                    <ol>
                      <li> The E-stamp initiator should be registered on SAMPADA 2.0.</li>
                      <li>The party should have Aadhaar for EKYC </li>
                      <li>The party should have DSC to generate an E-stamp.(for E-Sign)</li>

                    </ol>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      ई-स्टाम्प बनाने के लिए नीचे दी गई आवश्यक शर्तें हैं:
                    </p>
                    <ol>
                      <li>ई-स्टाम्प आरंभकर्ता को संपदा 2.0 पर पंजीकृत होना चाहिए।</li>
                      <li>EKYC के लिए पक्षकार के पास आधार होना चाहिए</li>
                      <li>पक्षकार के पास ई-स्टाम्प बनाने के लिए डीएससी होना चाहिए। (ई-साइन के लिए)</li>
                    </ol>
                  </div>
                </div>
              </mat-expansion-panel>


              <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Charges for e-stamp.</div>
                    <div *ngIf="lan == 1">
                      ई-स्टाम्प के लिए शुल्क।
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The charges of the e-stamp may differ based on instrument selection. To check the detailed list,
                      click on the duty calculation option.
                    </p>

                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      ई-स्टाम्प के शुल्क उपकरण चयन के आधार पर भिन्न हो सकते हैं। विस्तृत सूची देखने के लिए शुल्क गणना
                      विकल्प पर क्लिक करें।
                    </p>

                  </div>
                </div>
              </mat-expansion-panel>






            </mat-accordion>
          </div>
          <div class="col-md-6">
            <div class="process_img">
              <img src="assets/images/EStampLifeCycle.jpg" alt="EStampLifeCycle" style="width: 100%;" />
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>
          {{'detailsPages.PVDetails' | translate}}
        </h2>
 
        <button mat-button class="btn btn-primary" id="sticky_Div" routerLink="/property-valuation">
          
           {{'detailsPages.processForPVDetails' | translate}}
        </button> 
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-sm-12">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel
                [expanded]="step === 0"
                (opened)="setStep(0)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is a Property Valuation ?</div>
                    <div *ngIf="lan == 1">संपत्ति मूल्यांकन क्या है ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Property valuation is the process of determining the
                      guideline market value of a property. It involves
                      assessing various factors that can affect the guideline
                      market value of a property, such as its location, size,
                      usage type, construction year, and the sub clauses etc.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति का मूल्यांकन, संपत्ति के गाइडलाइन बाजार मूल्य को
                      निर्धारित करने की प्रक्रिया है। इसमें विभिन्न कारकों का
                      आकलन करना शामिल है जो की संपत्ति के गाइडलाइन बाजार मूल्य
                      को प्रभावित कर सकते हैं, जैसे कि इसकी स्थान, आकार, उपयोग
                      का प्रकार, निर्माण वर्ष और उपखंड आदि।हैं।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 1"
                (opened)="setStep(1)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Type of property ?</div>
                    <div *ngIf="lan == 1">संपत्ति का प्रकार ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      <b>1 Agricultural Land:</b> Agricultural land refers to
                      land that is used for farming, cultivation of crops, or
                      raising livestock. It is typically used for agricultural
                      purposes and is not intended for residential, commercial,
                      or industrial use.
                    </p>

                    <p>
                      <b>2 Municipal Property:</b> Municipal property refers to
                      properties owned or managed by a municipal or local
                      government. These properties can include public buildings
                      (such as town halls, libraries, and schools), parks,
                      roads, utilities (such as water and sewer systems), and
                      other infrastructure that serves the community.
                    </p>

                    <p>
                      <b>3 Panchayat Property:</b> Panchayat property refers to
                      properties owned or managed by a Panchayat, which is a
                      local self-government body in rural areas of India.
                      Panchayat properties can include community centers,
                      playgrounds, common grazing lands, water bodies, and other
                      assets that are owned or managed by the Panchayat for the
                      benefit of the local community
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      <b>1 कृषि भूमि:</b> कृषि भूमि से तात्पर्य उस भूमि से है
                      जिसका उपयोग खेती, फसल उगाने या पशुधन पालने के लिए किया
                      जाता है। इसका उपयोग आमतौर पर कृषि उद्देश्यों के लिए किया
                      जाता है और इसका उद्देश्य आवासीय, व्यावसायिक या औद्योगिक
                      उपयोग नहीं है।
                    </p>

                    <p>
                      <b>2 नगरपालिका संपत्ति:</b> नगरपालिका संपत्ति का तात्पर्य
                      नगरपालिका या स्थानीय सरकार के स्वामित्व या प्रबंधन वाली
                      संपत्तियों से है। इन संपत्तियों में सार्वजनिक भवन (जैसे
                      टाउन हॉल, पुस्तकालय और स्कूल), पार्क, सड़कें, उपयोगिताएँ
                      (जैसे पानी और सीवर सिस्टम), और समुदाय की सेवा करने वाले
                      अन्य बुनियादी ढांचे शामिल हो सकते हैं।
                    </p>

                    <p>
                      <b>3 पंचायत संपत्ति:</b> पंचायत संपत्ति का तात्पर्य पंचायत
                      के स्वामित्व या प्रबंधन वाली संपत्तियों से है, जो भारत के
                      ग्रामीण क्षेत्रों में एक स्थानीय स्व-सरकारी निकाय है।
                      पंचायत संपत्तियों में सामुदायिक केंद्र, खेल के मैदान, आम
                      चरागाह भूमि, जल निकाय और अन्य संपत्तियां शामिल हो सकती हैं
                      जिनका स्वामित्व या प्रबंधन स्थानीय समुदाय के लाभ के लिए
                      पंचायत द्वारा किया जाता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 2"
                (opened)="setStep(2)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the Khasra ?</div>
                    <div *ngIf="lan == 1">खसरा क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Khasra is a term used in India to refer to a survey number
                      given to a specific plot of land in rural areas. It is
                      used to uniquely identify and record agricultural land
                      parcels for revenue and land ownership purposes. The
                      Khasra number is typically assigned by the revenue
                      department or local authorities and is used to maintain
                      land records, determine ownership, and assess land taxes.
                      Each Khasra number corresponds to a specific area of land
                      and is part of the larger land survey and management
                      system in India
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      खसरा एक शब्द है जो भारत में ग्रामीण क्षेत्रों में एक विशेष
                      प्लॉट की एक सर्वेक्षण संख्या को संदर्भित करने के लिए
                      प्रयोग होता है। इसका उपयोग खेती के भूमि खंडों को अदायगी और
                      भूमि स्वामित्व के उद्देश्यों के लिए एक पर्याप्त पहचान करने
                      के लिए किया जाता है। खसरा संख्या सामान्यत: राजस्व विभाग या
                      स्थानीय प्राधिकारियों द्वारा निर्धारित की जाती है और भूमि
                      रिकॉर्ड, स्वामित्व की निर्धारण और भूमि करों का मूल्यांकन
                      करने के लिए उपयोग की जाती है। प्रत्येक खसरा संख्या एक
                      विशेष भूमि क्षेत्र के लिए होती है और भारत में भूमि
                      सर्वेक्षण और प्रबंधन प्रणाली का हिस्सा है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 3"
                (opened)="setStep(3)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the Land Parce ID ?</div>
                    <div *ngIf="lan == 1">लैंड पार्सल आईडी क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Land Parcel ID, also known as Parcel Number or Parcel ID,
                      is a unique identifier assigned to a specific parcel of
                      land or property. It is used to distinguish one parcel
                      from another and is typically assigned by local government
                      authorities or land management agencies.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      भूमि पार्सल आईडी, जिसे पार्सल नंबर या पार्सल आईडी भी कहा
                      जाता है, एक विशिष्ट भूमि खंड या संपत्ति को दर्शाने के लिए
                      एक अद्वितीय पहचानकर्ता है। यह एक पार्सल को एक अन्य से अलग
                      करने के लिए उपयोग किया जाता है और सामान्यत: स्थानीय सरकारी
                      अधिकारियों या भूमि प्रबंधन एजेंसियों द्वारा निर्धारित किया
                      जाता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="step === 4"
                (opened)="setStep(4)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the Land Parce ID ?</div>
                    <div *ngIf="lan == 1">लैंड पार्सल आईडी क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Land Parcel ID, also known as Parcel Number or Parcel ID,
                      is a unique identifier assigned to a specific parcel of
                      land or property. It is used to distinguish one parcel
                      from another and is typically assigned by local government
                      authorities or land management agencies.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      भूमि पार्सल आईडी, जिसे पार्सल नंबर या पार्सल आईडी भी कहा
                      जाता है, एक विशिष्ट भूमि खंड या संपत्ति को दर्शाने के लिए
                      एक अद्वितीय पहचानकर्ता है। यह एक पार्सल को एक अन्य से अलग
                      करने के लिए उपयोग किया जाता है और सामान्यत: स्थानीय सरकारी
                      अधिकारियों या भूमि प्रबंधन एजेंसियों द्वारा निर्धारित किया
                      जाता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="step === 5"
                (opened)="setStep(5)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the ULPIN ?</div>
                    <div *ngIf="lan == 1">यूएलपिन क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      ULPIN stands for "Unique Land Parcel Identification
                      Number." It is a unique identifier assigned to each land
                      parcel in India. The ULPIN is used to uniquely identify
                      and track land parcels across the country.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      यूएलपीआईएन का मतलब है "यूनिक लैंड पार्सल आईडेंटिफिकेशन
                      नंबर।" यह भारत में प्रत्येक भूमि पार्सल को एक अद्वितीय
                      पहचानकर्ता सौंपा जाता है। यूएलपीआईएन का उपयोग देशभर में
                      भूमि पार्सलों की अद्वितीय पहचान और ट्रैक करने के लिए किया
                      जाता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="step === 6"
                (opened)="setStep(6)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the E-Nagar Palika ID ?</div>
                    <div *ngIf="lan == 1">
                      संपत्ति का ई-नगर पालिका आईडी क्या है?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The E-Nagar Palika ID of a property is a unique identifier
                      assigned to properties within the jurisdiction of urban
                      local bodies (Nagar Palikas) in India.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति का ई-नगर पालिका आईडी एक पहचानकर्ता है जो भारत में
                      शहरी स्थानीय निकायों (नगर पालिकाओं) क्षेत्र में विभागीय
                      प्रशासन और शहरी सेवाओं के लिए सौंपी जाती है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="step === 7"
                (opened)="setStep(7)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the PTR ID ?</div>
                    <div *ngIf="lan == 1">पीटीआर आईडी क्या है ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The PTR ID of a property is a unique identifier assigned
                      to that property for the purpose of property tax
                      assessment and record-keeping. It helps in identifying the
                      property and tracking its tax-related information, such as
                      tax payments, assessments, and exemptions.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      किसी संपत्ति का पटीआर आईडी उस संपत्ति के लिए एक पहचानकर्ता
                      है जो संपत्ति कर निर्धारण और रिकॉर्ड रखने के उद्देश्य से
                      सौंपा गया है। यह संपत्ति की पहचान करने और इसके कर संबंधित
                      जानकारी, जैसे कर भुगतान, मूल्यांकन, और छूटों का ट्रैकिंग
                      करने में मदद करता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel
                [expanded]="step === 8"
                (opened)="setStep(8)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      What is the Swamitva Property ID ?
                    </div>
                    <div *ngIf="lan == 1">स्वामित्व संपत्ति आईडी क्या है ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Swamitva Property ID is a unique identifier assigned to
                      properties under the Swamitva Yojana, a government
                      initiative in India aimed at mapping rural residential
                      land ownership. The Swamitva Property ID helps in uniquely
                      identifying and digitizing property records in rural
                      areas, facilitating transparent land ownership and
                      reducing property-related disputes.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      स्वामित्व संपत्ति आईडी एक पहचानकर्ता है जो स्वामित्व योजना
                      के अंतर्गत संपत्तियों को सौंपा जाता है, जो भारत में
                      ग्रामीण आवासीय भूमि के स्वामित्व को मानचित्रित करने का एक
                      सरकारी पहल है। स्वामित्व संपत्ति आईडी से ग्रामीण क्षेत्रों
                      में संपत्ति रिकॉर्ड की अद्वितीय पहचान और डिजिटलीकरण करने
                      में मदद मिलती है, जिससे स्पष्ट स्वामित्व की स्थिति और
                      संपत्ति से संबंधित विवाद कम होता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 9"
                (opened)="setStep(9)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the SAMPADA Geo Tag ID ?</div>
                    <div *ngIf="lan == 1">संपदा जियो टैग आईडी क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The SAMPADA Geo Tag ID is generated when a user pins a
                      property on the map, clicks three angled photos, and saves
                      them through the SAMPADA mobile application. This ID
                      serves as a unique identifier assigned to properties for
                      tracking and identification purposes.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      सैम्पदा जियो टैग आईडी उत्पन्न होता है जब एक उपयोगकर्ता
                      सैम्पदा मोबाइल एप्लिकेशन के माध्यम से किसी संपत्ति को
                      मानचित्र पर पिन करता है, तीन कोनीय तस्वीरें क्लिक करता है,
                      और उन्हें सेव करता है। यह आईडी संपत्तियों के लिए ट्रैकिंग
                      और पहचान के उद्देश्यों के लिए एक पहचानकर्ता के रूप में काम
                      करता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 10"
                (opened)="setStep(10)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Who can evaluate the property ?</div>
                    <div *ngIf="lan == 1">
                      संपत्ति का मूल्यांकन कौन कर सकता है ?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      In Sampada 2.0, both registered and unregistered users can
                      evaluate the property through the portal.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      सम्पदा 2.0 में, पंजीकृत और अपंजीकृत दोनों प्रकार के
                      उपयोगकर्ता पोर्टल के माध्यम से संपत्ति का मूल्यांकन कर
                      सकते हैं।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 11"
                (opened)="setStep(11)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      Pre-requisite to evaluate the property ?.
                    </div>
                    <div *ngIf="lan == 1">
                      संपत्ति का मूल्यांकन करने के लिए पूर्व-आवश्यकता ?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>To Property Valuation below are the prerequisites:</p>
                    <ol>
                      <li>
                        If the precise location (latitude and longitude) of the
                        property is not available from the Custodian Department,
                        then the person should possess the knowledge to identify
                        and pin the property on the map in order to evaluate it.
                      </li>
                      <li>
                        The system requires specific details such as property
                        type, subtype, usage type, total and transacting area,
                        construction details, subclauses, etc., which are based
                        on the type of property.
                      </li>
                    </ol>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>संपत्ति मूल्यांकन के लिए नीचे दी गई शर्तें हैं:</p>
                    <ol>
                      <li>
                        यदि कस्टोडियन विभाग से संपत्ति का सटीक स्थान (अक्षांश और
                        देशांतर) उपलब्ध नहीं है, तो व्यक्ति को संपत्ति का
                        मूल्यांकन करने के लिए उसे पहचानने और मानचित्र पर पिन
                        करने का ज्ञान होना चाहिए।
                      </li>
                      <li>
                        सिस्टम को संपत्ति के प्रकार, उपप्रकार, उपयोग के प्रकार,
                        कुल और लेनदेन क्षेत्र, निर्माण विवरण, उपधारा आदि जैसे
                        विशिष्ट विवरणों की आवश्यकता होती है, जो संपत्ति के
                        प्रकार पर आधारित होते हैं।
                      </li>
                    </ol>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 12"
                (opened)="setStep(12)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Process of Property Valuation ?</div>
                    <div *ngIf="lan == 1">संपत्ति मूल्यांकन की प्रक्रिया ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>To evaluate property follow below steps :</p>
                    <ul>
                      <li>Access the SAMPADA 2.0 portal.</li>
                      <li>Click the Property Valuation button</li>
                      <li>
                        Select an option to search the property such as
                        griculture Land ID, Municipal Property ID, Panchayat
                        Property ID, By Address, or SAMPADA Geo Tag ID
                      </li>
                      <li>
                        Enter the property details such as ULPIN, Land Parcel
                        ID, Pre-Mutation ID, E Nagar Palika ID, PTR, Swamitva
                        ID, SAMPADA Geo Tag ID, or address based on the selected
                        search option
                      </li>
                      <li>Click on the Submit button</li>
                      <li>
                        Based on the precise location (latitude and longitude)
                        of the property received from the Custodian Department
                        (CLR / UADD / PNRD), the system identifies and pins the
                        property on the map. If the precise location (latitude
                        and longitude) of the property is not available from the
                        Custodian Department, the system allows the user to
                        manually pin / identify the property on the map
                      </li>
                      <li>
                        If the required details to calculate the Guideline
                        Market Value of a property don't come from the Custodian
                        Dept. then enter them, such as Property Type, Sub Type,
                        Area, Usage Type, Construction Details and Sub Clauses
                      </li>
                      <li>
                        Click on the Shown Guideline Market Value of property
                        button
                      </li>
                      <li>
                        System will display the property details and Guideline
                        Market Value.
                      </li>
                      <li>
                        Users can also print the Property Valuation details.
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति का मूल्यांकन करने के लिए नीचे दिए गए चरणों का पालन
                      करें:
                    </p>
                    <ul>
                      <li>संपदा 2.0 पोर्टल को ओपन करे।</li>

                      <li>संपत्ति मूल्यांकन बटन पर क्लिक करे।</li>

                      <li>
                        संपत्ति खोजने के लिए कृषि भूमि आईडी, नगरपालिका संपत्ति
                        आईडी, पंचायत संपत्ति आईडी, पते द्वारा, या संपदा जियो टैग
                        आईडी जैसे विकल्प का चयन करे।
                      </li>

                      <li>
                        चयनित विकल्प के आधार पर संपत्ति का विवरण जैसे
                        यूएलपीआईएन, लैंड पार्सल आईडी, प्री-म्यूटेशन आईडी, ई नगर
                        पालिका आईडी, पीटीआर, स्वामित्व आईडी, संपदा जियो टैग आईडी
                        या पता दर्ज करे।
                      </li>

                      <li>सबमिट बटन पर क्लिक करे।</li>

                      <li>
                        कस्टोडियन विभाग (सीएलआर/यूएडीडी/पीएनआरडी) से प्राप्त
                        संपत्ति के सटीक स्थान (अक्षांश और देशांतर) के आधार पर,
                        सिस्टम संपत्ति की पहचान और मानचित्र पर पिन करता है। यदि
                        कस्टोडियन विभाग से संपत्ति का सटीक स्थान (अक्षांश और
                        देशांतर) उपलब्ध नहीं है, तो सिस्टम उपयोगकर्ता को
                        मानचित्र पर संपत्ति को मैन्युअल रूप से पिन / पहचानने की
                        अनुमति देता है।
                      </li>

                      <li>
                        संपत्ति की गाइड लाइन बाजार मूल्य की गणना के लिए आवश्यक
                        विवरण जैसे संपत्ति का प्रकार, उप प्रकार, क्षेत्र, उपयोग
                        का प्रकार, निर्माण विवरण और उप खंड जो कस्टोडियन विभाग से
                        नहीं आ रहे है उन्हें दर्ज करे।
                      </li>

                      <li>
                        संपत्ति के दिखाए गए गाइडलाइन बाजार मूल्य बटन पर क्लिक
                        करे।
                      </li>
                      <li>
                        सिस्टम संपत्ति विवरण और गाइडलाइन बाजार मूल्यांकन
                        प्रदर्शित करेग।
                      </li>
                      <li>
                        उपयोगकर्ता संपत्ति मूल्यांकन विवरण भी प्रिंट कर सकते
                        हैं।
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 13"
                (opened)="setStep(13)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the GIS System ?</div>
                    <div *ngIf="lan == 1">जीआईएस सिस्टम क्या है ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      GIS stands for Geographic Information System. It is a
                      system designed to capture, store, manipulate, analyze,
                      manage, and present spatial or geographic data. In simpler
                      terms, GIS allows us to visualize, interpret, and
                      understand data related to locations on Earth's surface.
                    </p>

                    <p>
                      GIS technology is used in various fields such as urban
                      planning, environmental management, natural resource
                      management, agriculture, transportation planning, and
                      disaster management. It helps in making informed decisions
                      by providing spatial context and analyzing spatial
                      relationships between different data layers.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      जीआईस का मतलब है भूगोलिक सूचना प्रणाली। यह एक प्रणाली है
                      जो भूगोलिक डेटा को कैप्चर, स्टोर, मैनिपुलेट, विश्लेषण,
                      प्रबंधन, और प्रस्तुत करने के लिए डिज़ाइन किया गया है। सरल
                      शब्दों में, GIS हमें पृथ्वी की सतह पर स्थानों से संबंधित
                      डेटा को दृश्यीकरण, व्याख्या, और समझने की सुविधा प्रदान
                      करता है।
                    </p>

                    <p>
                      जीआईस प्रौद्योगिकी को शहरी योजना, पर्यावरण प्रबंधन,
                      प्राकृतिक संसाधन प्रबंधन, कृषि, परिवहन योजना, और आपदा
                      प्रबंधन जैसे विभिन्न क्षेत्रों में उपयोग किया जाता है। यह
                      विभिन्न डेटा परतों के बीच स्थानिक संदर्भ को प्रदान करके और
                      स्थानिक संबंधों का विश्लेषण करके सूचित निर्णय लेने में मदद
                      करता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 14"
                (opened)="setStep(14)"
                hideToggle="hideToggle"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      What are the different layers on the map ?
                    </div>
                    <div *ngIf="lan == 1">
                      मानचित्र पर विभिन्न परतें क्या हैं?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      <b>State Layer:</b> The state layer represents the
                      boundaries and features of a particular state or province.
                      It typically includes state boundaries, major cities, and
                      other geographic features specific to that state.
                    </p>

                    <p>
                      <b>District Layer:</b> The district layer represents the
                      boundaries and features of administrative districts within
                      a state. District layers are used to represent smaller
                      administrative units within a state or province.
                    </p>

                    <p>
                      <b>Urban Layer:</b> The urban layer represents areas that
                      are characterized by high population density and
                      significant infrastructure development. Urban areas
                      typically include cities, towns, and densely populated
                      areas with commercial, residential, and industrial land
                      uses.
                    </p>

                    <p>
                      <b>Rural Layer:</b> The rural layer represents areas that
                      are characterized by low population density and a
                      predominantly agricultural or natural land use. Rural
                      areas typically include farmland, forests, and other
                      sparsely populated areas outside of urban centers.
                    </p>

                    <p>
                      <b>Ward Layer:</b> The ward layer represents
                      administrative divisions within a city or municipality.
                      Wards are smaller units used for local governance and
                      administrative purposes, and they often correspond to
                      neighborhoods or districts within a city.
                    </p>

                    <p>
                      <b>Village Layer:</b> The village layer represents
                      individual villages or settlements within a rural area. It
                      includes boundaries and features of villages, such as
                      houses, roads, and agricultural land.
                    </p>

                    <p>
                      <b>Khasra Layer:</b> The khasra layer represents the
                      boundaries and features of individual land parcels or
                      plots, often identified by their khasra numbers. This
                      layer is used to represent land ownership and property
                      boundaries.
                    </p>
                    <p>
                      <b>Road Layer:</b> The road layer represents the road
                      network, including highways, streets, and other
                      transportation routes. It provides information about the
                      connectivity and accessibility of different locations on
                      the map.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      <b>राज्य परत:</b> राज्य परत एक विशेष राज्य या प्रांत की
                      सीमाओं और विशेषताओं का प्रतिनिधित्व करती है। इसमें
                      सामान्यत: राज्य की सीमाएँ, प्रमुख शहरों, और उस राज्य की
                      खास भूगोलीय विशेषताएँ शामिल होती हैं।
                    </p>

                    <p>
                      <b>जिला परत:</b> जिला परत एक राज्य के भीतर प्रशासनिक जिलों
                      की सीमाओं और विशेषताओं का प्रतिनिधित्व करती है। जिला परतें
                      राज्य या प्रांत के भीतर छोटे प्रशासनिक इकाइयों का
                      प्रतिनिधित्व करने के लिए उपयोग की जाती हैं।
                    </p>

                    <p>
                      <b>शहरी परत:</b> शहरी परत उन क्षेत्रों का प्रतिनिधित्व
                      करती है जो उच्च जनसंख्या घनत्व और महत्वपूर्ण बुनियादी
                      ढांचे के साथ विशेषता से चरित हैं। शहरी क्षेत्र सामान्यत:
                      नगरों, शहरों, और व्यावसायिक, आवासीय, और औद्योगिक भूमि के
                      उपयोग के साथ भरपूर जनसंख्या वाले क्षेत्रों को शामिल करते
                      हैं।
                    </p>

                    <p>
                      <b>ग्रामीण परत:</b> ग्रामीण परत उन क्षेत्रों का
                      प्रतिनिधित्व करती है जो कम जनसंख्या घनत्व और प्रमुखत: कृषि
                      या प्राकृतिक भूमि के उपयोग के लिए चरित हैं। ग्रामीण
                      क्षेत्र सामान्यत: खेती भूमि, वन्य और अन्य उपयुक्त स्थलों
                      को शामिल करते हैं जो शहरी केंद्रों के बाहर के दुर्गम
                      क्षेत्रों को शामिल करते हैं।
                    </p>

                    <p>
                      <b>वार्ड परत:</b> वार्ड परत शहर या नगरपालिका के भीतर
                      प्रशासनिक विभाजन का प्रतिनिधित्व करती है। वार्ड स्थानीय
                      शासन और प्रशासनिक उद्देश्यों के लिए उपयोग किए जाने वाले
                      छोटे इकाइयों हैं, और वे अक्सर शहर के नीबोरहुड या जिलों से
                      मिलते-जुलते हैं।
                    </p>

                    <p>
                      <b>गाँव परत:</b> गाँव परत ग्रामीण क्षेत्र में व्यक्तिगत
                      गाँवों या बस्तियों का प्रतिनिधित्व करती है। इसमें गाँवों
                      की सीमाएँ और विशेषताएँ शामिल होती हैं, जैसे कि मकान,
                      सड़कें, और कृषि भूमि।
                    </p>

                    <p>
                      <b>खसरा परत:</b> खसरा परत व्यक्तिगत भूमि पार्सल या प्लॉट
                      की सीमाओं और विशेषताओं का प्रतिनिधित्व करती है, जो अक्सर
                      उनके खसरा संख्याओं द्वारा पहचानी जाती हैं। यह परत भूमि
                      स्वामित्व और संपत्ति सीमाओं का प्रतिनिधित्व करने के लिए
                      उपयोग की जाती है।
                    </p>

                    <p>
                      <b>सड़क परत:</b> सड़क परत सड़क नेटवर्क का प्रतिनिधित्व
                      करती है, जिसमें हाईवे, सड़कें, और अन्य परिवहन मार्ग शामिल
                      हैं। यह विभिन्न स्थानों की कनेक्टिविटी और पहुँचने की
                      सुविधा के बारे में जानकारी प्रदान करती है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="col-xl-6 col-lg-6 col-sm-12">
            <div class="process_img">
              <img
                *ngIf="lan == 0"
                src="assets/images/PV-English.png"
                style="width: 100%"
              />
              <img
                *ngIf="lan == 1"
                src="assets/images/PV-Hindi.png"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

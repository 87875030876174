<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>
                    
                    {{'detailsPages.nonIntVideoKYC' | translate}}
                </h2>
                <!-- <button mat-button class="btn btn-primary" id="sticky_Div">
                     {{'detailsPages.processForNonIntVideoKYC' | translate}}
                </button> -->
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-md-6">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">What is Faceless Non Interactive Video KYC?</div>
                                        <div *ngIf="lan == 1">फेसलेस नॉन इंटरएक्टिव वीडियो केवाईसी क्या है?</div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Faceless Non-Interactive Video KYC refers to a process where Know Your
                                            Customer (KYC) verification is conducted through a video call without the
                                            need for direct interaction between the party and the verifying authority
                                            (Sub Registrar).
                                        </p>
                                        <p>
                                            Based on the selected nature of the document / instrument, the system will
                                            display this option to a party during the initiation of the Registry.
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            फेसलेस नॉन-इंटरएक्टिव वीडियो केवाईसी एक ऐसी प्रक्रिया को संदर्भित करता है
                                            जहां पक्षकारो और सत्यापन प्राधिकारी (उप पंजीयक) के बीच सीधे संपर्क की
                                            आवश्यकता के बिना वीडियो कॉल के माध्यम से अपनी पक्षकार केवाईसी सत्यापन किया
                                            जाता है।
                                        </p>
                                        <p>
                                            दस्तावेज़ / इंस्ट्रूमेंट की चयनित प्रकृति के आधार पर, सिस्टम पक्षकारो को यह
                                            विकल्प प्रदर्शित करेगा।
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Who can do faceless non interactive videos?</div>
                                        <div *ngIf="lan == 1">
                                            फेसलेस नॉन इंटरएक्टिव वीडियो कौन कर सकता है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Who can do faceless non interactive videos?
                                        </p>
                                        <ol>
                                            <li>
                                                The nature of the document / instrument should allow for Faceless
                                                Non-Interactive Video KYC.
                                            </li>
                                            <li>
                                                If a property transaction is involved in the selected document /
                                                instrument, then fully detailed information about the property should be
                                                available from the custodian department (CLR/UADD/PNRD).
                                            </li>
                                            <li>
                                                All party persons should be registered in the portal through Aadhaar
                                                e-KYC.
                                            </li>
                                            <li>
                                                In an initiated registry document, there should not be more than 10
                                                party persons.
                                            </li>

                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            सम्पदा 2.0 में, उपयोगकर्ताओं के लिए फेसलेस नॉन-इंटरएक्टिव वीडियो केवाईसी
                                            आयोजित करने की शर्तें नीचे दी गई हैं:
                                        </p>
                                        <ol>
                                            <li>
                                                दस्तावेज़ / इंस्ट्रूमेंट के लिए फेसलेस नॉन इंटरैक्टिव वीडियो केवाईसी की
                                                अनुमति होनी चाहिए।
                                            </li>
                                            <li>
                                                यदि चयनित दस्तावेज़ / इंस्ट्रूमेंट में संपत्ति का लेनदेन शामिल है, तो
                                                संपत्ति के बारे में पूरी विस्तृत जानकारी कस्टोडियन विभाग
                                                (सीएलआर/यूएडीडी/पीएनआरडी) से उपलब्ध होनी चाहिए।
                                            </li>
                                            <li>
                                                सभी पक्षकारो के व्यक्तियों को आधार ई-केवाईसी के माध्यम से पोर्टल में
                                                पंजीकृत होना चाहिए।
                                            </li>
                                            <li>
                                                रजिस्ट्री दस्तावेज़ में 10 से अधिक पक्षकार नहीं होने चाहिए।
                                            </li>

                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Pre-requisite for Faceless Non Interactive Video KYC</div>
                                        <div *ngIf="lan == 1">फेसलेस नॉन इंटरएक्टिव वीडियो केवाईसी के लिए पूर्व-आवश्यकता
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>To conduct Faceless Non-Interactive Video KYC, the following are the
                                            prerequisites:</p>
                                        <ol>
                                            <li>
                                                Ensure a secure network connection to maintain the confidentiality and
                                                integrity of the video KYC process.
                                            </li>
                                            <li>
                                                Have a device such as a smartphone or computer with a camera and
                                                microphone.
                                            </li>
                                            <li> Provide valid identification documents such as Aadhaar, PAN, Voter ID,
                                                or Passport.</li>
                                            <li>
                                                Conduct the video KYC in a stable and well-lit environment to ensure
                                                clear visibility.
                                            </li>
                                            <li>
                                                Authenticate identity using Aadhaar-based OTP verification.
                                            </li>
                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>फेसलेस नॉन-इंटरएक्टिव वीडियो केवाईसी आयोजित करने के लिए निम्नलिखित शर्तें
                                            हैं:</p>
                                        <ol>
                                            <li>
                                                वीडियो केवाईसी प्रक्रिया की गोपनीयता और अखंडता बनाए रखने के लिए एक
                                                सुरक्षित नेटवर्क कनेक्शन सुनिश्चित करें।

                                            </li>
                                            <li>
                                                एक कैमरा और माइक्रोफोन वाला स्मार्टफोन या कंप्यूटर जैसा उपकरण रखें।
                                            </li>
                                            <li>
                                                आधार, पैन, वोटर आईडी या पासपोर्ट जैसे वैध पहचान दस्तावेज प्रदान करें।
                                            </li>
                                            <li>
                                                स्पष्ट दृश्यता सुनिश्चित करने के लिए वीडियो केवाईसी को स्थिर और अच्छी
                                                रोशनी वाले वातावरण में संचालित करें।
                                            </li>
                                            <li>
                                                आधार-आधारित ओटीपी सत्यापन का उपयोग करके पहचान प्रमाणित करें।
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Process to do Faceless Non Interactive Video KYC?</div>
                                        <div *ngIf="lan == 1">फेसलेस नॉन इंटरएक्टिव वीडियो केवाईसी करने की प्रक्रिया?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>To do Faceless Non Interactive Video KYC follow below steps : </p>
                                        <ul>
                                            <li>
                                                Click on the Faceless Non Interactive Video KYC link received via SMS,
                                                Email, or WhatsApp, user will be directed to the SAMPADA 2.0 Faceless
                                                Non Interactive Video KYC welcome page.
                                            </li>
                                            <li>
                                                Select the language (Hindi / English).
                                            </li>
                                            <li>
                                                Accept the term and conditions..
                                            </li>
                                            <li>
                                                Click on the 'Proceed' button.
                                            </li>
                                            <li> Enter the OTP to validate the mobile.</li>
                                            <li>Upload any of the one document such as PAN, Driving License, Voter Card,
                                                or Passport.</li>
                                            <li>Click on the 'Next' button.</li>

                                            <li>
                                                On the screen system will display a list of all parties involved in the
                                                Document of Registry. There will also be a dummy video showing the
                                                process that users need to follow to complete their Faceless Non
                                                Interactive Video KYC.
                                            </li>
                                            <li>
                                                Click on the Proceed button to proceed.
                                            </li>
                                            <li>
                                                The system will display instructions. Click on the "Get Started" button.
                                            </li>
                                            <li>
                                                The system will ask for permission to use the camera, microphone, and
                                                location. Grant the permissions to proceed.
                                            </li>
                                            <li>
                                                Person capture self photo
                                            </li>
                                            <li>Person perform their liveliness check </li>
                                            <li>
                                                Person has to read and speak the displayed statement, which is shown on
                                                the screen
                                            </li>
                                            <li>
                                                Person captured the uploaded document photo
                                            </li>
                                            <li>
                                                Click on the Submit Video button
                                            </li>
                                            <li>Do the aadhaar authentication through OTP</li>
                                            <li> Click on the OK button. </li>
                                            <li> System displyed the successfully submited Faceless Non Interactive
                                                video KYC on the screen. </li>
                                        </ul>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>सम्पदा 2.0 फेसलेस नॉन इंटरएक्टिव वीडियो केवाईसी स्वागत पृष्ठ पर निर्देशित
                                            किया जाएगा ।</p>
                                        <ul>
                                            <li>
                                                भाषा (हिंदी/अंग्रेजी) का चयन करे।
                                            </li>
                                            <li>
                                                नियम और शर्तें स्वीकार करे।
                                            </li>
                                            <li>
                                                आगे बढ़ें बटन पर क्लिक करें.
                                            </li>
                                            <li>
                                                मोबाइल को वेरिफाई करने के लिए ओटीपी दर्ज करे।
                                            </li>
                                            <li> पैन, ड्राइविंग लाइसेंस, वोटर कार्ड या पासपोर्ट में से कोई एक दस्तावेज
                                                अपलोड करे। </li>
                                            <li>'नेक्स्ट' बटन पर क्लिक करे। </li>
                                            <li>
                                                स्क्रीन पर सिस्टम रजिस्ट्री के दस्तावेज़ में शामिल सभी पक्षों की एक सूची
                                                प्रदर्शित करेगा। एक डमी वीडियो भी होगा जिसमें वह प्रक्रिया दिखाई जाएगी
                                                जिसका उपयोगकर्ताओं को अपने फेसलेस नॉन इंटरएक्टिव वीडियो केवाईसी को पूरा
                                                करने के लिए पालन करना होगा। आगे बढ़ने के लिए प्रोसीड बटन पर क्लिक करें.
                                            </li>
                                            <li>
                                                सिस्टम निर्देश प्रदर्शित करेगा. "आरंभ करें" बटन पर क्लिक करें।
                                            </li>
                                            <li>
                                                सिस्टम कैमरा, माइक्रोफ़ोन और लोकेशन का उपयोग करने की अनुमति मांगेग। आगे
                                                बढ़ने के लिए अनुमति दें.
                                            </li>
                                            <li>
                                                व्यक्ति स्वयं की फोटो खींचे।
                                            </li>
                                            <li>
                                                व्यक्ति अपनी लिवेलिनेस्स जांचे।
                                            </li>
                                            <li>
                                                व्यक्ति को स्क्रीन पर दिखाए गए स्टेटमेंट को पढ़ना और बोलना होगा।
                                            </li>
                                            <li>
                                                व्यक्ति के द्वारा अपलोड किए गए दस्तावेज़ की फोटो खींच।
                                            </li>
                                            <li>
                                                सबमिट वीडियो बटन पर क्लिक करें
                                            </li>
                                            <li>
                                                आधार ओटीपी से ऑथेंटिकेशन करें
                                            </li>
                                            <li>
                                                ओके बटन पर क्लिक करें.
                                            </li>
                                            <li>
                                                सिस्टम स्क्रीन पर फेसलेस नॉन इंटरएक्टिव वीडियो केवाईसी सफलतापूर्वक सबमिट
                                                किए जाने का मैसेज प्रदर्शित करेगा।
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </mat-expansion-panel>


                        </mat-accordion>
                    </div>
                    <div class="col-md-6">
                        <div class="process_img">
                            <img src="assets/images/EnglishFacelessNonInteractiveVideoKYC.png" alt="PaymentWallet"
                                style="width: 100%" *ngIf="lan == 0" />
                            <img src="assets/images/HindiFacelessNonInteractiveVideoKYC.png" alt="PaymentWallet"
                                style="width: 100%" *ngIf="lan == 1" />
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
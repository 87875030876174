import { Component, Renderer2, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ColorswitcherService } from './shared/header/colorswitcher.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  lan: number=1;
   
  themeColor:string;
  defaultZoom:string;
  // storedTheme: string = sessionStorage.getItem('theme-color');

  storedTheme:string = sessionStorage.getItem('theme-color') || "";
  storedZoom:string = sessionStorage.getItem('zoom-level') || "";



 
  title = 'frontEnd-web';
  previousUrl: string;
  
  constructor(private router: Router, private renderer: Renderer2, 
    private colorservice:ColorswitcherService, 
    private translateService: TranslateHEService,
    private translate:TranslateService){
  

    this.colorservice.currentColors.subscribe(msg => this.themeColor = msg);
    this.themeColor = sessionStorage.getItem('theme-color') || 'default-theme';

    this.colorservice.currentZoom.subscribe(msg => this.defaultZoom = msg);
    this.defaultZoom = sessionStorage.getItem('zoom-level') || 'default-zoom';



    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
         // console.log("remove previous url",this.previousUrl)
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        let currentUrlSlug = event.url.slice(1)
        if (currentUrlSlug) {
          //console.log("add current url",currentUrlSlug)
          this.renderer.addClass(document.body, currentUrlSlug);
        }
        this.previousUrl = currentUrlSlug;
      }
    });
  }




  



}


<div class="fid-header"></div>
<div class="breadcrumb-content">
    <div class="container">
  <div class="d-flex justify-content-between">

<h2>{{'complaint-request.title' | translate}} </h2>
<a class="nav-link float-right btn btn-primary" routerLink="/complaint-status" role="button">{{'complaint-request.title-status' | translate}}</a>
</div>
</div>
</div>



<div class="auth-main145 mb-4">



    <div class="container">
        <mat-card class="mat-card">
            <mat-card-title class="mat-card-title"></mat-card-title>
              <mat-card-content class="mat-card-content">
        <lib-alertmessage></lib-alertmessage>
        <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
                    {{'admin-profile-page-data.center-personal-detail-useronboard.add-other-departmet-user' | translate}}
              </legend>
                <!-- <div class="card"> -->
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-4">
                                <label>{{'complaint-request.request-type' | translate}} <span class="rstarcolor">*</span></label>
                                <select class="form-control " formControlName="requestType"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.requestType.errors }"
                                        (change)="showForms()">
                                    <option value=null selected>{{'complaint-request.select-request-type' | translate}}</option>
                                    <!-- <option value="otherDepartment">{{'complaint-request.other-department' | translate}}</option> -->
                                    <option *ngFor="let crl of complaintReqList" value="{{crl.id}}">
                                        {{lan==1?crl.requestTypeNameHi:crl.requestTypeNameEn}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && registerForm.controls.requestType.errors" class="invalid-feedback">
                                    <div *ngIf="registerForm.controls.requestType.errors.required">{{'required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row" *ngIf="formCode==1">
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'department' | translate}} <span class="rstarcolor">*</span></label>
                                    <ng-select formControlName="departmentId"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.departmentId.errors }"
                                        (change)="getSubDepartment(registerForm.controls.departmentId)">
                                        <ng-option value="">{{'admin-profile-page-data.center-personal-detail-subrole.select-department'|translate}}</ng-option>
                                        <ng-option *ngFor="let dm of departmentList" value="{{dm.id}}">{{(lan==0)?dm.departmentNameEn:dm.departmentNameHi}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && registerForm.controls.departmentId.errors" class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.departmentId.errors.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-4 col-md-4" *ngIf="!enablesubdepartment"></div> -->

                            <div class="col-lg-4 col-md-4" *ngIf="enablesubdepartment">
                                <div class="form-group">
                                    <label>{{'sub-department' | translate}}</label>
                                    <ng-select formControlName="subDepartmentId" (change)="setSubDepartment(registerForm.controls.subDepartmentId)">
                                        <ng-option value="">{{'admin-profile-page-data.center-personal-detail-subrole.select-sub-department'|translate}}</ng-option>
                                        <ng-option *ngFor="let dm of subDepartmentList" value="{{dm.id}}">{{(lan==0)?dm.departmentNameEn:dm.departmentNameHi}}</ng-option>
                                    </ng-select>
                                </div>
                            </div>


                            <div class="col-lg-4 col-md-4" [hidden]="!enabledeptname">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-department.departmentName' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" alpha (change)="send(registerForm.controls.departmentNameEn,departmentNameHi)"
                                        formControlName="departmentNameEn"
                                        class="form-control" required
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-department.departmentName' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.departmentNameEn.errors }">
                                    <div *ngIf="submitted && registerForm.controls.departmentNameEn.errors" class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.departmentNameEn.errors.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4" [hidden]="!enabledeptname">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-department.departmentName-hindi' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                        <input minlength="1" maxlength="100" #departmentNameHi name="departmentNameHi" formControlName="departmentNameHi"
                                        (contextmenu)="false;" class="form-control" required
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-department.departmentName-hindi' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.departmentNameHi.errors }">
                                    <div *ngIf="submitted && registerForm.controls.departmentNameHi.errors " class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.departmentNameHi.errors.required">{{'required' | translate}}</div>
                                        <div *ngIf="registerForm.controls.departmentNameHi.errors.pattern">टाइप इन हिंदी</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">

                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.authorized-person-name' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" (change)="send(registerForm.controls.nameEn, nameHi)" alpha
                                        formControlName="nameEn" (contextmenu)="false;"
                                        class="form-control"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.authorized-person-name' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.nameEn.errors }">
                                    <div *ngIf="submitted && registerForm.controls.nameEn.errors" class="invalid-feedback">
                                        <div>{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.authorized-person-nameHi' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" formControlName="nameHi" name="nameHi" #nameHi
                                        (contextmenu)="false;" class="form-control"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.authorized-person-nameHi' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.nameHi.errors }">
                                    <div *ngIf="submitted && registerForm.controls.nameHi.errors " class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.nameHi.errors.required">{{'required' | translate}}</div>
                                        <div *ngIf="registerForm.controls.nameHi.errors.pattern">टाइप इन हिंदी</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.designationen' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" (change)="send(registerForm.controls.designationEn, designationHi)" alpha formControlName="designationEn"
                                        (contextmenu)="false;" class="form-control"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-designationen' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.designationEn.errors }">
                                    <div *ngIf="submitted && registerForm.controls.designationEn.errors && registerForm.controls.designationEn.errors" class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.designationEn.errors.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.designationhi' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" formControlName="designationHi"  name="designationHi" #designationHi
                                        (contextmenu)="false;" class="form-control"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-designationhi' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.designationHi.errors }">
                                    <div *ngIf="submitted && registerForm.controls.designationHi.errors " class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.designationHi.errors.required">{{'required' | translate}}</div>
                                        <div *ngIf="registerForm.controls.designationHi.errors.pattern">टाइप इन हिंदी</div>
                                    </div>

                                </div>
                            </div>


                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label> {{'admin-profile-page-data.center-personal-detail-department.is_ldap' | translate}} <span class="rstarcolor">*</span></label>
                                    <select class="form-control" formControlName="isLdap"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.isLdap.errors }"
                                        (change)="setLdap(registerForm.controls.isLdap)">
                                        <option value="">{{'admin-profile-page-data.center-personal-detail-department.is_ldap' | translate}}</option>
                                        <option value="1">{{'admin-profile-page-data.center-personal-detail-department.ldap' | translate}}</option>
                                        <option value="0">{{'admin-profile-page-data.center-personal-detail-department.nonldap' | translate}}</option>
                                    </select>
                                    <div *ngIf="submitted && registerForm.controls.isLdap.errors" class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.isLdap.errors.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4" *ngIf="ldap">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.office-email' | translate}} <span class="rstarcolor">*</span></label>
                                    <div>
                                        <input minlength="1" maxlength="100" type="email" class="form-control"
                                             (contextmenu)="false;" [appCopyPastValidation]="true"
                                            pattern="^[a-zA-Z0-9_.+-]+@(([a-z]+.gov|nic)).in$" formControlName="email" name="email" #email
                                            placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-official-email' | translate}}"
                                            [ngClass]="{ 'is-invalid': submitted && registerForm.controls.email.errors }"
                                            (change)="getAvailability(email.value)">
                                        <div *ngIf="submitted && registerForm.controls.email.errors && registerForm.controls.email.errors" class="invalid-feedback">
                                            <div *ngIf="registerForm.controls.email.errors.required">{{'required' | translate}}</div>
                                            <div *ngIf="registerForm.controls.email.errors.pattern">{{'enter-valid-email' | translate}}</div>
                                        </div>
                                        <label class="w-100" *ngIf="emailtaken" ><span style="color: red;"> '{{oldEmail}}' {{'citizen-profile-details.login-detail-profile.taken-email' | translate}}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4" *ngIf="!ldap">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.email' | translate}} <span class="rstarcolor">*</span></label>
                                    <div>
                                        <input minlength="1" maxlength="100" type="email" class="form-control"
                                             (contextmenu)="false;" [appCopyPastValidation]="true"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email" name="email" #email
                                            placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-email' | translate}}"
                                            [ngClass]="{ 'is-invalid': submitted && registerForm.controls.email.errors }"
                                            (change)="getAvailability(email.value)">
                                        <div *ngIf="submitted && registerForm.controls.email.errors && registerForm.controls.email.errors" class="invalid-feedback">
                                            <div *ngIf="registerForm.controls.email.errors.required">{{'required' | translate}}</div>
                                            <div *ngIf="registerForm.controls.email.errors.pattern">{{'enter-valid-email' | translate}}</div>
                                        </div>
                                        <label class="w-100" *ngIf="emailtaken"><span style="color: red;"> '{{oldEmail}}' {{'citizen-profile-details.login-detail-profile.taken-email' | translate}}</span></label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.email-confirm' |
                                        translate}} <span class="rstarcolor">*</span></label>

                                    <input minlength="1" maxlength="100" type="email" class="form-control" (contextmenu)="false;"  [appCopyPastValidation]="true"
                                        compare-password="email" formControlName="confirmEmail" name="confirmEmail"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-email' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.confirmEmail.errors }">

                                    <div *ngIf="submitted && registerForm.controls.confirmEmail.errors " class="invalid-feedback">
                                        {{'emailnotmatch-password-req' | translate}}
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.mobile-number' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" class="form-control"  (contextmenu)="false;" maxlength="10" minlength="10"
                                        [appCopyPastValidation]="true" [OnlyNumber]="true" pattern="^[6-9]\d{9}$"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-mobile-number' | translate}}"
                                        formControlName="mobileNo" name="mobileNo"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.mobileNo.errors }">
                                    <div *ngIf="submitted && registerForm.controls.mobileNo.errors " class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.mobileNo.errors.required">{{'required' | translate}}</div>
                                        <div *ngIf="registerForm.controls.mobileNo.errors.pattern">{{'admin-profile-page-data.center-personal-detail-useronboard.mobile-validate' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.mobile-number-confirm' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input minlength="1" maxlength="100" class="form-control"  (contextmenu)="false;" maxlength="10" minlength="10"
                                        pattern="^[6-9]\d{9}$" [appCopyPastValidation]="true" [OnlyNumber]="true"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-mobile-number' | translate}}"
                                        formControlName="confirmMobileNo" name="confirmMobileNo" compare-password="mobileNo"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.confirmMobileNo.errors }">
                                    <div *ngIf="submitted && registerForm.controls.confirmMobileNo.errors " class="invalid-feedback">
                                        {{'mobilenonotmatch-password' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="form-group">
                                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.upload-letter-of-authority' | translate}} <span class="rstarcolor">*</span></label>
                                    <ng-template #popContent>{{'file-note' | translate}}</ng-template>
                                    <i  class="fa fa-info-circle" [ngbPopover]="popContent" triggers="mouseenter:mouseleave" ></i>
                                    <input formControlName="authorityDocFile"  id='fileid' type='file' name="fileid"
                                        [(ngModel)]="authorityDocFile" (change)="ProfileChangeEvent($event)" (contextmenu)="false;"
                                        class="form-control"
                                        placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-designationhi' | translate}}"
                                        [ngClass]="{ 'is-invalid': submitted && registerForm.controls.authorityDocFile.errors }">
                                    <div *ngIf="submitted && registerForm.controls.authorityDocFile.errors" class="invalid-feedback">
                                        <div *ngIf="registerForm.controls.authorityDocFile.errors.required">{{'required' | translate}}</div>
                                    </div>
                                    <div *ngIf="fileUploadError!=''" class="custominvalid-feedback">
                                        <div >{{fileUploadError}}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <mat-card-footer *ngIf="formCode!=0 && formCode!='' && formCode!=undefined">
                            <div class="  text-right">
                                <button mat-raised-button class="btn btn-primary">{{'submit' | translate}}</button>
                            </div>
                        </mat-card-footer>


                        <div class="row noprint" *ngIf="complaintNo != null">
                            <div class="col-md-12 text-primary">
                                {{complaintNo}}
                            </div>
                        </div>
                    </form>
                <!-- </div> -->
                </fieldset>
                </mat-card-content>
                </mat-card>

            </div>
        </div>

import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { PropertyValuationService } from '../../property-valuation/property-valuation.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';
import { PnrdModel } from 'projects/common-lib/src/lib/models/PnrdModel';
import { MPenagarpalikaResponsModel } from 'projects/common-lib/src/lib/models/enagarpalikaModel';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';

@Component({
  selector: 'app-mannual-plot',
  templateUrl: './mannual.plot.component.html',
  styleUrls: ['./mannual.plot.component.scss']
})
export class MannualPlotComponent implements OnInit {
  pnrdForm:FormGroup
  mPenagarpalikaResponsModel:MPenagarpalikaResponsModel
  submitted = false;
  uomList: any[] = new Array();
  @Output() validData = new EventEmitter<any>();
  lan: number=1
  mstDempgraphy:MstDemographyBean
  prndModel:PnrdModel
  isPnrd:boolean=false
  propertyTypeId:number
  constructor(public fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private pvService: PropertyValuationService,
    private ngxService: NgxUiLoaderService,
    public utilityService:UtilityService,
    private translateService: TranslateHEService, 
    private translate: TranslateService,) { 
 
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       });
    }
   // पंचायत आईडी  //Panchayat Property ID  
   //मूनिसिपल संपत्ति आईडी //Municipal Property ID 
  changeLabel():string{
      return this.isPnrd?this.lan==0?'Panchayat Property ID':'पंचायत आईडी ':this.lan==0?'Municipal Property ID ':'मूनिसिपल संपत्ति आईडी '
     }

  ngOnInit(): void {
    this.pnrdForm=this.fb.group({
      id:['',[Validators.required]],
      propertyNo: ['', [Validators.required]],
      unit: ['',[Validators.required]],
      totalArea: ['', [Validators.required]],
      houseFlatNo: ['', [Validators.required]],
      buildingType: ['', ],
      landOwnerShip: ['', [Validators.required]],
      demographyId:['', [Validators.required]],
      propertyId:['',[Validators.required]]
  })
  this.getAllUOM()
  }

  get f() { return this.pnrdForm.controls; }

  setPropertyObectdata(propertyObject:any){
    console.log(propertyObject)
    this.pnrdForm.controls['propertyNo'].setValue(propertyObject.PropertyObject.propertyNo)
    this.pnrdForm.controls['propertyId'].setValue(propertyObject.PropertyObject.propertyNo)
    this.pnrdForm.controls['id'].setValue(propertyObject.PropertyObject.id)
    this.pnrdForm.controls['demographyId'].setValue(propertyObject.PropertyObject.demography.id)
    this.mstDempgraphy=propertyObject.PropertyObject.demography
    this.isPnrd=propertyObject.PropertyObject.isPnrd
  }
  getAllUOM(){
  this.pvService.getAllUOM().subscribe(dmodata => {
      this.uomList = dmodata.responseData;;
   })
  }
  convertedUOM(uom: any) {
    return this.utilityService.convertedUOM(uom,this.lan)
  }
  changePropertyType(propertyTypeId:number){
    this.propertyTypeId=propertyTypeId
  }

  onSubmit() {
    this.submitted = true;
    if(!this.pnrdForm.valid) {
    console.log(this.pnrdForm)
    
    }
      else {
        if(this.propertyTypeId==1){
        this.prndModel = <PnrdModel> this.pnrdForm.value;
        this.prndModel.demography=this.mstDempgraphy
        this.prndModel.propertyTypeId=1
        console.log(this.prndModel instanceof PnrdModel);
        console.log(this.prndModel);
        this.validData.emit(this.prndModel)
        }
        else{
          this.mPenagarpalikaResponsModel = <MPenagarpalikaResponsModel> this.pnrdForm.value;
          this.mPenagarpalikaResponsModel.demography=this.mstDempgraphy
          this.mPenagarpalikaResponsModel.propertyTypeId=1
          this.mPenagarpalikaResponsModel.id
          console.log(this.mPenagarpalikaResponsModel instanceof MPenagarpalikaResponsModel);
          console.log(this.mPenagarpalikaResponsModel);
          this.validData.emit(this.mPenagarpalikaResponsModel)
        }
    }
  }
}

import { Component, OnInit , Output , EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { ColorswitcherService } from '../header/colorswitcher.service'
declare var $: any;  

@Component({
  selector: 'front-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {

  lan: number=1;
  lanVal: string = sessionStorage.getItem("selectedLanguage") || "hi";
  checkedVal: boolean;

  closeResult = '';

  loginSu:any;
  themeColor:string;
  defaultZoom:string;
  isUserLoggedIn:boolean=false;
  userType:string;
  
  collapsed = true;
  langOptionFlag:number=1;

  @Output() parentFunction:EventEmitter<any>= new EventEmitter()


  constructor(private colorservice:ColorswitcherService, public translate: TranslateService,private authService: CitizenAuthenticationService,private router: Router,private translateService:TranslateHEService,private modalService: NgbModal
    ) {
    //translate.setDefaultLang('hi');

      //this.lan = sessionStorage.getItem("selectedLanguage") == 'en' ? 0 : 1;
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.langOptionFlag = this.lan;
      //this.translateService.updateApprovalMessage(this.lan);
      this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
      this.colorservice.currentColors.subscribe(msg => this.themeColor = msg);
      this.colorservice.currentColors.subscribe(msg => this.defaultZoom = msg);
   }

   open(loginOptions: any) {
    this.modalService.open(loginOptions,{ centered: true }, ).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  // open2(registerOptions: any) {
  //   this.modalService.open(registerOptions,{ centered: true , size: 'xl'}, ).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //   });
  // }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {

    $("#toggle").click(function() {
      $("#toggle").toggleClass("on");
      $("#menu").slideToggle();
    });

    
    
    if(sessionStorage.getItem("token")!=null && sessionStorage.getItem("authData")!=null){
      this.loginSu=1;
    }
    
    if(sessionStorage.getItem('isUserLoggedIn')!=undefined && sessionStorage.getItem('isUserLoggedIn')=="true"){
    this.isUserLoggedIn=true;
    }else{
      this.isUserLoggedIn=false;
    }

    if(sessionStorage.getItem('loginType')!=undefined && sessionStorage.getItem('loginType')!=null && sessionStorage.getItem('loginType')!=''){
      this.userType=sessionStorage.getItem('loginType')||'';
    }else{
      this.userType='';
    }

    
  }

  // slickInit(e: any) {
  //   
  //   if(sessionStorage.getItem("token")!=null && sessionStorage.getItem("authData")!=null){
  //     this.loginSu=1;
  //   }
  // }

  setTheme(theme:(any)){
    this.colorservice.updateColor(theme);
   }
   setZoom(zoom:(any)){
     this.colorservice.updateZoom(zoom);
    }


  sentData(){
    let data={name: 'jitu'}
    this.parentFunction.emit(data)    
  }
  
  changeLanguageToHindi()
  {
      this.translate.use("hi")
      this.langOptionFlag=1;
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage","hi")
      localStorage.setItem("selectedLanguage", "hi");
      this.translateService.updateApprovalMessage(this.lan);
      
  }
  changeLanguageToEnglish(){
    this.translate.use("en")
    this.langOptionFlag=0;
    this.lan = 0;
    sessionStorage.setItem("selectedLanguage","en")
    localStorage.setItem("selectedLanguage", "en");
    this.translateService.updateApprovalMessage(this.lan);
    
  }

  logoutAdmin() {
    this.authService.logout().subscribe(res => {
      res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
    });
    // this.router.navigate(['clogin']);
    if(sessionStorage.getItem('isUserLoggedIn')!=undefined && sessionStorage.getItem('isUserLoggedIn')=="true"){
      this.isUserLoggedIn=true;
    }else{
      this.isUserLoggedIn=false;
    }
  }
  
  changeLan(ev: any) {
    //
    
    if (ev.value == 1) {
      this.lan = 0;
      sessionStorage.setItem("selectedLanguage", 'en');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.translate.use(sessionStorage.getItem("selectedLanguage") || "en");
      this.translateService.updateApprovalMessage(this.lan);
    } else {
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage", 'hi');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    //  this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi");
      this.translateService.updateApprovalMessage(this.lan);
    }
    //window.location.reload();
    // this.translate.get("citizen-title").subscribe(name=>{
    //   this.titleService.setTitle(name);

    // });
  }

  
}

<!-- <button mat-icon-button matDialogClose class="colsebtn d-print-none" (click)="close()" [mat-dialog-close]="true">
  <mat-icon>close</mat-icon>
</button> -->

<!-- <h2 class="Consenterdialog-title">
  {{ "property-valuation.property-valuation-title" | translate }}
</h2> -->
  <div class="position-relative mt-1 p-4 pt-1">
   
            
     <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="building-tab" data-toggle="tab" href="#building" role="tab" aria-controls="building" aria-selected="true">
         <mat-icon>domain</mat-icon>  Building
          </a>
        </li>
        <li class="nav-item" >
          <a class="nav-link" id="plot-tab" data-toggle="tab" href="#plot" role="tab" aria-controls="plot" aria-selected="false">
            <mat-icon>picture_in_picture_alt</mat-icon> Plot</a>
        </li>
      
      </ul>
      <fieldset class="legend-section mt-0">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="building" role="tabpanel" aria-labelledby="building-tab">
            <app-mannual-building (validData)="emitTOMannualProcessComponent($event,2)"></app-mannual-building>

        </div>
        <div class="tab-pane fade" id="plot" role="tabpanel" aria-labelledby="profile-tab">
            <app-mannual-plot (validData)="emitTOMannualProcessComponent($event,1)"></app-mannual-plot>
        </div>
      </div>
      <!-- <citizen-demography-hierarchy-load style="width: 100%;" [componentFlag]="'columnwise'" [type_id]="2" [parent_id]="1"
      [lastdemograpgy_id]="15" (loadgldata)="getDemography($event)"></citizen-demography-hierarchy-load> -->
      </fieldset>
 
    <div class="d-flex justify-content-between">
        <button class="btn btn-secondary" (click)="close()">
            {{'goback' | translate}}
        </button>
        <button class="btn btn-primary" (click)="onSubmit()">
            {{'mannualProcess.conformsaveprDet' | translate}}
        </button>

    </div>
    </div>
 
 


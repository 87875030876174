import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServiceService } from 'projects/common-lib/src/lib/helper-lib/services/common/common-service.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { CommunicationServiceService } from '../duty-calculation/duty-service/communication-service.service';
import { DutyService } from '../duty-calculation/duty-service/duty.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EregistryService } from '../../service/eregistry.service';
import { IgrsEregPropIdentificationBean, IgrsEregTxnDetlsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { WebGisResponseBeanData } from 'projects/common-lib/src/lib/models/WebGisResponseData';
import Swal from "sweetalert2";
import { PartyTypeModel } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-qrcode-view-eregi',
  templateUrl: './qrcode-view-eregi.component.html',
  styleUrls: ['./qrcode-view-eregi.component.scss']
})
export class QrcodeViewEregiComponent implements OnInit {

  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild('myIframe') iframe: ElementRef;
  redType: any[] = [];
  lan: number;
  eregId: number;
  issueByEn: string;
  issueByHi: string;
  typeOfUser: string;
  IssueLocation: string;
  IssueLocationHi: string;
  typeOfUserHi: string;
  firstPartyModel: PartyTypeModel;
  secondPartyModel: PartyTypeModel;
  propertyValuationRequestBean:any;
  consAmount:any=0;
  firstPartyList: any[]= new Array();
  secondPartyList: any[] = new Array();
  ENPfirstPartyModel: any[]= new Array();
  ENPsecondPartyModel: any[]= new Array();
  PNRDfirstPartyModel: any[]= new Array();
  PNRDsecondPartyModel: any[]= new Array();
  CLRfirstPartyModel: any[]= new Array();
  CLRsecondPartyModel: any[]= new Array();
  firstPartyCount: number = 0;
  secondPartyAval: boolean = false;
  isDataFound: boolean = false;
  dataMessage: string;
  fromPartyCounter:any=0;
  toPartyCounter:any=0;
  igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
  webGisResponseBeanDataArr: WebGisResponseBeanData[] = new Array()
  igrsEregPropIdentificationBean: IgrsEregPropIdentificationBean = new IgrsEregPropIdentificationBean();
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;




  constructor(private router: Router, private route: ActivatedRoute, private ngxService: NgxUiLoaderService, private translateService: TranslateHEService,
    private translateServiceIns: TranslateService, private commServ: CommunicationServiceService,
    private dutyService: DutyService, private commonService: CommonServiceService, private eRegService: EregistryService) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
    this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;

    this.redType = this.route.snapshot.url.toString().split(",");
    this.eregId = this.redType[1];
    console.log("status code::"+this.redType[1]);
    if(this.redType[1] == "invalid"){
      Swal.fire("Invalid Registry Number !!");
    }

  }

  ngOnInit(): void {
    this.loadEregistryDataByEregId(this.eregId)
  }


  loadEregistryDataByEregId(eRegisId: any) {
    console.log("eRegisId== ", eRegisId);

    if (eRegisId != null && eRegisId != undefined) {

      this.eRegService.getEregDetailsByQrCode(eRegisId).subscribe(res => {

        if (res.responseStatus == "true") {
console.log("Response Data : ",res.responseData);
          if (res.responseData.regId != undefined && res.responseData.statusId == 72) {
            this.isDataFound = true;
            this.igrsEregTxnDetlsBean = res.responseData;
            let tempFirstName = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.firstNameEn;
            let tempMiddleName = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.middleNameEn == null ? "" : this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.middleNameEn;
            let tempLastName = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.lastNameEn;

            let tempFirstNameHi = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.firstNameHi;
            let tempMiddleNameHi = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.middleNameHi == null ? "" : this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.middleNameHi;
            let tempLastNameHi = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.lastNameHi;

            this.firstPartyModel = this.igrsEregTxnDetlsBean.partyTypeList?.find((el: PartyTypeModel) => (el.partySubTypeId == 1));

            this.secondPartyModel = this.igrsEregTxnDetlsBean.partyTypeList?.find((el: PartyTypeModel) => (el.partySubTypeId == 2));

            this.firstPartyList = this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList?.filter(p=>p.partySubTypeId==1);
            this.secondPartyList = this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList?.filter(p=>p.partySubTypeId==2);


            this.secondPartyList.forEach(party=>{

              if(party.transactingThroughPOA){
                this.secondPartyList.push(party.partyBean);
                const secondPartyList1 =party.partyBean.poaStatus=1;
                console.log("POA party ",this.secondPartyList);
                

              }
            });
            this.firstPartyList.forEach(party=>{

              if(party.transactingThroughPOA){
                this.firstPartyList.push(party.partyBean);
                const firstPartyList1 =party.partyBean.poaStatus=1;
                console.log("POA party ",this.firstPartyList);
                

              }
            });

            

            this.consAmount = this.igrsEregTxnDetlsBean?.dutyDetailsWithConsGlDataDto?.consAmount;
            if(this.igrsEregTxnDetlsBean?.dutyDetailsWithConsGlDataDto?.consAmount==undefined){
              this.consAmount=0;
            }

          this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean?.forEach(prop => {
           
            if(prop.propertySource=='ENP'){
              this.ENPfirstPartyModel = prop?.propertyValuationRequestBean?.mPenagarpalikaResponseData?.partyBeans?.filter(p=>p.partySubTypeId==1);
              this.ENPsecondPartyModel = prop?.propertyValuationRequestBean?.mPenagarpalikaResponseData?.partyBeans?.filter(p=>p.partySubTypeId==2);
              
            }if(prop.propertySource=='PNRD'){
              this.PNRDfirstPartyModel = prop?.propertyValuationRequestBean?.pNRDResponseData?.partyBeans?.filter(p=>p.partySubTypeId==1);
              this.PNRDsecondPartyModel = prop?.propertyValuationRequestBean?.pNRDResponseData?.partyBeans?.filter(p=>p.partySubTypeId==2);
              
            }if(prop.propertySource=='CLR'){
               prop?.propertyValuationRequestBean?.webGisResponseBeanDataArr?.forEach(kh=>{

               this.CLRfirstPartyModel= kh.partyBeans?.filter(p=>p.partySubTypeId==1);               

              });
                prop?.propertyValuationRequestBean?.webGisResponseBeanDataArr?.forEach(kh=>{

                this.CLRsecondPartyModel= kh.partyBeans?.filter(p=>p.partySubTypeId==2);               

              });

            }
           

          });
            this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList?.forEach(party => {

              if (party.partySubTypeId == 1) {
                this.firstPartyCount++;
               
              }
              if (party.partySubTypeId == 2) {
                this.secondPartyAval = true;
               
              }
            });

            this.issueByEn = tempFirstName + " " + tempMiddleName + " " + tempLastName;
            this.issueByHi = tempFirstNameHi + " " + tempMiddleNameHi + " " + tempLastNameHi

            if (this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.serviceProvider == undefined) {
              this.typeOfUser = "Self, E-mail Id: " + this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.email;
              this.typeOfUserHi="स्वयं, ईमेल आईडी: " + this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.email;
              this.IssueLocationHi= this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.addressLine1Hi, +" " + this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.addressLine2Hi;
              this.IssueLocation = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.addressLine1, +" " + this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.addressLine2;
            } else {
              this.typeOfUser = "Service Provider, License No. : " + this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.serviceProvider?.sPLicence;
               this.typeOfUserHi = "सेवा प्रदाता, लाइसेंस नंबर : " + this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.serviceProvider?.sPLicence;
              this.IssueLocation = this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.serviceProvider?.address;
              this.IssueLocationHi =this.igrsEregTxnDetlsBean?.igrsCitizenUserBean?.serviceProvider?.address;
            }




          } else {

            this.isDataFound = false;
            this.dataMessage = (this.lan == 0) ? "Data Not Found !" : "डेटा नहीं मिला !";
            Swal.fire({
              title: (this.lan == 0) ? "Data Not Found !" : "डेटा नहीं मिला !",
              // text: 'You will not be able to recover this file!',
              icon: 'warning',
              confirmButtonText: (this.lan == 0) ? "Ok" : "ठीक है",
 
              // preConfirm: () => { 
              //   alert('hello')
              //   //this.router.navigateByUrl('/')
              //   window.location.href = '/';
              // },

            }).then((result) => {
              if (result.isConfirmed) {
                
                // this.router.navigateByUrl('/').then(() => {
                //   this.router.navigate(['/']);
                // })
                window.location.href = '/';
              }
              
            });

          }

        } else if (res.responseMessage == 'IS NOT SEARCHABLE DOC') {
          this.isDataFound = false;
          this.dataMessage = (this.lan == 0) ? "'Document Search' feature is not available for this type of document." : "इस प्रकार के दस्तावेज़ के लिए 'दस्तावेज़ खोज' सुविधा उपलब्ध नहीं है !";
          Swal.fire((this.lan == 0) ? "'Document Search' feature is not available for this type of document." : "इस प्रकार के दस्तावेज़ के लिए 'दस्तावेज़ खोज' सुविधा उपलब्ध नहीं है !")
        } else {

          Swal.fire({
            title: res.responseMessage,
            // text: 'You will not be able to recover this file!',
            icon: 'warning',
            confirmButtonText: (this.lan == 0) ? "Ok" : "ठीक है",
            //preConfirm: () => {  window.location.href = '/';},

          }).then((result) => {
            if (result.isConfirmed) {             
              this.router.navigateByUrl('/').then(() => {
                this.router.navigate(['/']);
              })
             // window.location.href = '/';
            }
            
          });
        }

      },
        (err: any) => {
          //  console.log(err);

        });
    }
  }
  // fromPartyCounter:any=0;
  // toPartyCounter:any=0;

  firstPartyCounter(arg:any):string{
    
    this.fromPartyCounter= this.fromPartyCounter+1

    return this.fromPartyCounter;

  }

  parseAreaType(areaType: any): string {
    let typeOfArea = "";
    if ("R" == areaType) {
      typeOfArea = (this.lan == 0) ? "RURAL AREA" : "ग्राम - क्षेत्र";
    } if ("U" == areaType) {
      typeOfArea = (this.lan == 0) ? "URBAN AREA" : "नगरीय क्षेत्र";

    }
    return typeOfArea;

  }
  onBtnPrintClick() {
    let printContents, popupWin;
    printContents = document.getElementById('print-card').innerHTML;
    popupWin = window.open('', '');
    popupWin.document.open();
    popupWin.document.write(`
<html>
<head>
    <title>Print tab</title>


    <style type = "text/css" > @media screen {
          body {
            font-family: Roboto, "Helvetica Neue", sans-serif;
            color: #000;
            font-weight: 600;
          }
            table,
            td,
            th {border: 1px solid #828282;
                text-align: left;
            }

            table {border: 1px solid #828282;
                border-collapse: collapse;
                width: 98%;
                margin-bottom: 1rem;
            }

            th,
            td {border: 1px solid #828282;
                padding: 15px;
            }

            .col-md-12 {
              flex: 0 0 100%;
              max-width: 100%;

            }
            .row {
                display: flex;
                flex-wrap: wrap;
                margin-right: 0px;
                margin-left: 0px;
            }
            .col-6{
              flex: 0 0 50%;
              max-width: 50%;

            }
            .d-flex {
                display: flex !important;
            }
            .col-md-6{
              position: relative;
              flex: 0 0 49%;
              max-width: 49%;
              padding-top: 14px;
              padding-bottom: 0;
              line-height: normal;

          }
          .form-group {
            margin-bottom: 1rem;
        }
        fieldset{
          margin-bottom: 10px;
          border:none;
          margin-bottom: 14px;
          padding: 0px;
          width: 100%;
        }
        #print-card {
          padding: 15px;
        }
        fieldset.common-fieldset legend {
          font-size: 18px;
          background: none;
          display: inline-block;
          width: auto;
          border-radius: 10rem;
          padding: 6px 0px 6px;
          font-weight: 700;
        }
          .cardprint:last-child {
            page-break-after: auto;
       }
        }

        @media print {
          body{ background-image: url(./assets/images/sampada-logo11.png);
           filter: grayscale(100%);
          background-size: 10%;}
            table,
            td,
            th {border: 1px solid #828282;
                text-align: left;
            }

            table {border: 1px solid #828282;
                border-collapse: collapse;
                width: 98%;

            }

            th,
            td {border: 1px solid #828282;
                padding: 15px;
            }
            .col-md-12 {
              flex: 0 0 100%;
              max-width: 100%;

            }
            .row {
                display: flex;
                flex-wrap: wrap;
                margin-right: 0px;
                margin-left: 0px;
            }
            .col-6{
              flex: 0 0 49%;
              max-width: 49%;

            }
            .d-flex {
                display: flex !important;
            }
            .col-md-6{
              position: relative;
              flex: 0 0 50%;
              max-width: 50%;
              padding-top: 14px;
              padding-bottom: 0;
              line-height: normal;

          }
          .form-group {
            margin-bottom: 1rem;
        }
        fieldset{
          margin-bottom: 10px;
          border:none;
          margin-bottom: 14px;

          width: 100%;
        }
        #print-card {
          padding: 15px;
        }
        fieldset.common-fieldset legend {
          font-size: 18px;
          background: none;
          display: inline-block;
          width: auto;
          border-radius: 10rem;
          padding: 6px 0px 6px;
          font-weight: 700;
        }
          .cardprint:last-child {
            page-break-after: auto;
       }
       .name{ font-size:20px;}
       .font-weight-bold{font-weight:600;}
        }
        @media screen, print {
            table,
            td,
            th {border: 1px solid #828282;
                text-align: left;
            }

            table {border: 1px solid #828282;
                border-collapse: collapse;
                width: 98%;

            }

            th,
            td {border: 1px solid #828282;
                padding: 15px;
            }
            .col-md-12 {
              flex: 0 0 100%;
              max-width: 100%;

            }
            .row {
                display: flex;
                flex-wrap: wrap;
                margin-right: 0px;
                margin-left: 0px;
            }
            .col-6{
              flex: 0 0 50%;
              max-width: 50%;

            }
            .d-flex {
                display: flex !important;
            }
            .col-md-6{
              position: relative;
              flex: 0 0 49%;
              max-width: 49%;
              padding-top: 14px;
              padding-bottom: 0;
              line-height: normal;

          }
          .form-group {
            margin-bottom: 1rem;
        }
        fieldset{
          margin-bottom: 10px;
          border:none;
          margin-bottom: 14px;
          padding: 15px;
          width: 100%;
        }
        #print-card {
          padding: 15px;
        }
        fieldset.common-fieldset legend {
          font-size: 18px;
          background: none;
          display: inline-block;
          width: auto;
          border-radius: 10rem;
          padding: 6px 0px 6px;
          font-weight: 700;
        }
          .cardprint:last-child {
            page-break-after: auto;
       }
       .bg-grey{ background:#eee}
       .fid-ht { margin-top:-20px}
       .bg_new{background-image: url(/assets/images/sampada-logo11.png);}
        }

    </style>
</head>
<body onload="window.print();window.close()" style="font-family: Roboto, "Helvetica Neue", sans-serif;
color: #000;
font-weight: 600;">
<table width="100%" border="0" cellspacing="0" cellpadding="0" class="mt-3" style="text-align: center;
border: none;">
<tr>
  <td style="text-align: center;
  border: none;padding: 0px;">
    <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80" />
  </td>
</tr>
</table>
    ${printContents}

</body>
</html>`
    );
    popupWin.document.close();
  }



}

import { MasterOfficeModel } from "../api.masterdata.model/office.model";

export class Serviceprovider {

	id: number;
	docPath1: string;
	docType1: string;
	docPath2: string;
	docType2: string;
	docPath3: string;
	docType3: string;
	docPath4: string;
	docType4: string;
	docPath5: string;
	docType5: string
	conStartDate: string;
	conEndDate: string;
	conStartDateStr: string;
	conEndDateStr: string;
	conActivationDate: string;
	conLastRenewalDate: string;
	status: number;
	officeId: any;
	districtId: any;
	minCreditCapacity: string;
	education: any;
	isPassed: any;
	citizenId: number;
	address: string;
	registrationNo: string;
	rejectedRemarks: string;
	nomineeFirstName: string;
	nomineeLastName: string;
	nomineeRelation: string;
	appealRemarks: string;
	appealStatus: number;
	accountNo: string;
	confaccountNo: string;
	ifscCode: string;
	bankId: number;
	otp: string;
	nomineeMobileNo: string;
	flag: string;
	flaghi: string;
	spLicenseBaseCode:string;
	sPLicence: string;
	paymentStatus: string;
	unsignSpLicense: string;
	signSpLicense: string;
	requestUrn: string;
	serviceProviderStatusBean: ServiceProviderStatus;
	officeBean: MasterOfficeModel;

	sPStatusUpdateDetailsList: SPStatusUpdateDetails[] = new Array();

	serviceProviderStatusList: ServiceProviderStatus[] = new Array();
	mstBankIfscCodeBean: MstBankIfscCodeBean;
	isRenewal: boolean;
}


export class ServiceProviderStatus {
	id?: number;
	name?: string;
	nameHi?: string;
	description?: string;
	descriptionHi?: string;
}

export class SPStatusUpdateDetails {
	id?: number;
	spId?: number;
	oldStatusId?: number;
	newStatusId?: number;
	remarks?: string;
	docPath?: string;
	docType?: string;
	caseNo?: string;
	demographyName?: string;
	userName?: string;
	citizenId?: number;
	createdDateStr?: string;
	oldServiceProviderStatus: ServiceProviderStatus;
	newServiceProviderStatus: ServiceProviderStatus;
	officeId:number;
	sPLicence? : string;
}

export class MstBankIfscCodeBean {
	id?: number;
	bankMasterId?: string;
	ifscCode?: string;
	branchName?: string;
	address?: string;
	district?: string;
	state?: string;
	districtId?: number;
	stateId?:number;
	pincode?:number;
	mstBankMaster: MstBankMasterBean;
}

export class MstBankMasterBean {
	id?: number;
	bankName?: string;
	bankNameHi?: string;
}

export class eSignatureBean {
	id?: number;
	primaryId?: number;
	aadhaarLast4Digits?: string;
	type?: string;
	remark?: string;
	unsignedBase64?: string;
	spId?: string;
	estampId: string;
	partyName: string;
	mobileNo: string;
	title: string;
	requestParams: string;
	tempFilename: string;
	isEreg: string;
	partyAadharPhoto: string;
	partyAadharNumber: string;
	directionFlag: number;
	encryptedEregId: string;
	partyType: number;
	eregId: number;
	esignType:number;
	urnNo:any;
	aadharRefKey: string;
	esignAuthMode:any;
}
export class MstBankFinancialInstituteTypeBean {
	id: number;
	bankFinancialInstituteTypeEn: string;
	bankFinancialInstituteTypeHi: string;
	status: number;
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent implements OnInit {

 private settings!: AppSetting;
  isExpanded = true;

  panelOpenState = true;
  lan: number;
  constructor(private appSettingsService: AppSettingService,
     private translate: TranslateService,
    private translateServiceIns: TranslateService,
    private translateService: TranslateHEService, ) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });

 try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }

     // translate.use(selectedLanguage).toPromise();
     // this.lan = selectedLanguage == 'hi' ? 1 : 0;
    
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
       this.lan = msg
       this.translate.use(msg==1?'hi':'en')
      }
        );
    } catch (err) {
      console.log(err);
    }

 

   }

  ngOnInit(): void {
  }
  pdfView(content:any): void {

    var url = this.settings.igrs_gateway_url + "common/downloadDscUserManualPdf/"+  content + ".pdf";
    window.open(url, '_blank');

  }
 videoDownload(content:any): void {

    var url = this.settings.igrs_gateway_url + "common/downloadVideoManualMp4/"+  content + ".mp4";
    window.open(url, '_blank');
 
  }
}

<lib-alertmessage></lib-alertmessage>
<div class="auth-main"> 
    <div class="container">
  
    <div class="row justify-content-center">
        <div class="col-lg-12 align-self-center">
            <div class="row d-flex justify-content-center h-50" >
                <div class="col-lg-12 ">
                    <!-- <div class="d-flex justify-content-center">
                        <div class="login_logo_container"> <img src="./assets/images/logo-1.png" class="login_logo" alt="Logo"> </div>
                    </div>  -->
                    <div class="d-flex ">
                        <h2 class="login-title text-primary d-block ">{{'citizen-registration-details.kyc-detail' | translate}}</h2>
                    </div>
                    <app-kyc-details 
                        (verifyAdharOtpOut)="verifyAdharOtpOut($event)"
                        (verifyPanOut)="verifyPanOut($event)" 
                        [kycSource]="kycSource" 
                        [kycObject]="kycObject">
                    </app-kyc-details>



                </div>
                <!-- <div class="col-md-5 bg-light">
                    <div class="d-flex h-100 align-items-center twxt-center">
                        <img src="assets/images/forgot-password.png" class="w-100" alt="Forget Password">
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    
    </div>
</div>
     

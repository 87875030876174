import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent implements OnInit {
  @Input() lan:number=1;;
  expand: boolean = false;
  anchor: string ;
  anchorHi:string;

  constructor(
    private translate:TranslateService,
    private translateService:TranslateHEService
  ) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     })
   //this.anchor =  this.lan==0?'Read more' : 'अधिक पढ़ें';
   this.anchor = 'Read more' ;
   this.anchorHi='अधिक पढ़ें';
  }

  ngOnInit() {
    console.log("ShowMoreComponent-ngOnInit");
    //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    //this.anchor =  this.lan==0?'Read more' : 'अधिक पढ़ें';
    this.anchor = 'Read more' ;
    this.anchorHi='अधिक पढ़ें';
  }

  ngAfterViewInit(){
    console.log("ShowMoreComponent-ngAfterViewInit");
  }

  toggle() {
    console.log("ShowMoreComponent-toggle");
    this.expand = !this.expand;
   // this.anchor = this.expand ?  this.lan==0?'Show less' : 'कम दिखाएं': this.lan==0?'Read more' : 'अधिक पढ़ें';
    this.anchor = this.expand ?  'Show less' : 'Read more' ;
    this.anchorHi = this.expand ?   'कम दिखाएं':  'अधिक पढ़ें';
  }
}

import {  NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DemographyHierarchyLoadComponent } from './component/demography-hierarchy-load/demography-hierarchy-load.component';
import { DemographyLoadComponent } from './component/demography-load/demography-load.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { DepartmentSubLoadComponent } from './component/department-sub-load/department-sub-load.component';
import { RejectConfirmDialogComponent } from './component/reject-confirm-dialog/reject-confirm-dialog.component';
import { AlertmessageComponent } from './component/alertmessage/alertmessage.component';
import { DepartmentSearchComponent } from './component/department-search/department-search.component'; 
import { MapComponent } from './component/map/map.component';
import { AllDemographyHierarchyLoadComponent } from './component/all-demography-hierarchy-load/all-demography-hierarchy-load.component';
import { DeedInstrumentComponent } from './component/deed-instrument/deed-instrument.component';
import { ChallanComponent } from './component/challan/challan.component';
import { DeedContentHistoryComponent } from './component/deed-content-history/deed-content-history.component';
import { AmountPipe } from './pipes/amout.pipe';
import { OwmPrinterComponent } from './component/owm-printer/owm-printer.component';
import { WalletWiseChallanComponent } from './component/wallet-wise-challan/wallet-wise-challan.component';
import { CommonPaymentResponseComponent } from './component/common-payment-response/common-payment-response.component';
import { AutosizeModule } from 'ngx-autosize';
import { WalletPaymentResponseComponent } from './component/wallet-payment-response/wallet-payment-response.component';
import { TransactionHistoryChallanComponent } from './component/transaction-history-challan/transaction-history-challan.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { WebcamComponent } from './component/webcam/webcam.component';
import { EAuthComponent } from './component/e-auth/e-auth.component';
import { NewShowMaketValueComponent } from './component/new-show-maket-value/new-show-maket-value.component';
import { MeasureToolComponent } from './component/measure-tool/measure-tool.component';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { AmtToWords } from './pipes/amt-to-words.pipe';
import { IndianNumberFormatPipe } from './pipes/indian-number-format.pipe';
import { DemographyByMap } from './component/demographyByMap/demographyByMap';
import { AddressSearchComponent } from './component/address-search/address-search.component';
import { AddressSearchServices } from './component/address-search/address-search.service';

import { GisMapComponent } from './component/gis-map/gis-map.component';
import { AppSettingService } from './helper-lib/app.setting';
import { PanEkycComponent } from './page/pan-ekyc/pan-ekyc.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { EregistryForm60Component } from './page/eregistry-form60/eregistry-form60.component';
import { OnlyNumberDirective } from './validation/OnlyNumber/only-number.directive';
import { MaterialModule } from 'projects/common-lib/material/material.module';
import { EsignComponent } from './page/esign/esign.component';
import { DeedTemplateComponent } from './page/deed-template/deed-template.component';
import { ERegUserDetailComponent } from './page/e-reg-user-detail/e-reg-user-detail.component';
import { EregDeedTemplateComponent } from './page/ereg-deed-template/ereg-deed-template.component';
import { RegisteredUserComponent } from './page/registered-user/registered-user.component';
import { TrimValueAccessorDirective } from './validation/TrimValueAccessorDirective';
import { AlphaDirective } from './validation/Alpha/alpha.directive';
import { IntegerDirective } from './validation/Integer/integer.directive';
import { CopyPastValidationDirective } from './validation/copy-past-validation.directive';
import { ComparePasswordDirective } from './validation/ComparePassword/compare-password.directive';
import { AlphaNumericSpaceDirective } from './validation/AlphaNumericSpace/alpha-numeric-space.directive';
import { NotificationTemplateTextAllowed } from './validation/NotificationTemplateTextAllowed/NotificationTemplateTextAllowed';
import { NotificationTemplateTextAllowedHi } from './validation/NotificationTemplateTextAllowed/NotificationTemplateTextAllowedHi';
import { OrderByPipe } from './pipes/order-by.pipe';
import { FormatTimePipe } from './pipes/FormatTime';
import { TranslateHEService } from './helper-lib/services/common/translateHE.service';
import { ActiondeedComponentComponent } from './component/actiondeed-component/actiondeed-component.component';
import { SpinnerService } from './helper-lib/services/spinner.service';
//import { SafeHtmlPipe } from './validation/HtmlSafePipe';
import { PositiveNumbersOnlyDirectiveDirective } from 'projects/front-web/src/app/validation/positive-numbers-only-directive.directive';
import { SpecialCharacterDirective } from './validation/specialIsAlphaNumeric/SpecialIsAlphaNumeric';
import { SpecialCharacterDirectiveHi } from './validation/specialIsAlphaNumeric/SpecialIsAlphaNumericHi';
import { CommanDemographyHierarchyLoadComponent } from './component/comman-demography-hierarchy-load/comman-demography-hierarchy-load.component';
import { EregistryCommonPropertyIdentificationComponent } from './component/eregistry-common-property-identification/eregistry-common-property-identification.component';
import { UtilityService } from './service/UtilityService';
import { ViewRefundDetailsComponent } from './component/refundDetails/view-refund-details/view-refund-details.component';
 
import { RefundViewEregiComponent } from './component/refundDetails/refund-view-eregi/refund-view-eregi.component';
import { RefundViewEstampComponent } from './component/refundDetails/refund-view-estamp/refund-view-estamp.component';
import { ServiceProviderDetailsComponent } from './component/refundDetails/service-provider-details/service-provider-details.component';
import { ViewSearchOrderHistoryComponent } from './component/refundDetails/view-search-order-history/view-search-order-history.component';
import { RefundRequestListComponent } from './component/refundDetails/refund-request-list/refund-request-list.component';
//import { TwoDecimalPlacesDirective } from './validation/TwoDecimalPlacesDirective.directive';
import { TwoDigitDecimalNumberDirective } from './validation/TwoDigitDecimaNumber/two-digit-decima-number.directive';

//import { SpinnerViewComponent } from 'projects/front-web/src/app/spinner-view/spinner-view.component';
 
import { ProtestChargesRequestComponent } from './component/protestChargesCD/protest-charges-request/protest-charges-request.component';
import { CourtOrderDetailsComponent } from './component/protestChargesCD/court-order-details/court-order-details.component';
import { CaveatRequestComponent } from './component/protestChargesCD/caveat-request/caveat-request.component';
import { CaveatRequestManualComponent } from './component/protestChargesCD/caveat-request manual/caveat-request-manual.component';
import { ChatbotComponent } from './component/chatbot/chatbot.component';
import { BankLoanPaymentDetailsComponent } from './component/protestChargesCD/bank-loan-payment-details/bank-loan-payment-details.component';
import { RefundRequestDetailsComponent } from './component/refundDetails/refund-request-details/refund-request-details.component';
import { IdentifactionComponent } from './component/protestChargesCD/identifaction/identifaction.component';
import { RestrictedNumberValidatorDirective } from './validation/RestrictedNumberValidatorDirective ';
import { ChangeDemography } from './component/changeDemography/changeDemography.component';
import { FeedbackQuestionComponent } from './component/citizen-feedback/feedback-question/feedback-question.component';
import { CitizenFeedbackComponent } from './component/citizen-feedback/citizen-feedback.component';
import { TreeChecklistExample } from './component/tree-checklist/tree-checklist-component';
import { LayerListComponent } from './component/layer-list/layer-list.component';
import { BasemapComponent } from './component/basemap/basemap.component';
import { MatTreeModule } from '@angular/material/tree';
import { SelectionInstrumentComponent } from './component/selection-instrument/selection-instrument.component';
import { OnlyBAllowedCharacter } from 'projects/front-web/src/app/validation/OnlyBAllowedCharacter';

const providers:any[] = [];
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
"bgsColor": "rgb(209, 76, 69)",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 80,
  "bgsType": "three-strings",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "rgb(209, 76, 69)",
  "fgsPosition": "center-center",
  "fgsSize": 100,
  "fgsType": "three-strings",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "rgb(209, 76, 69)",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Please Wait....",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 900
};
@NgModule({
  declarations: [
    AlertmessageComponent,
    DemographyLoadComponent,
    PageNotFoundComponent,
    DemographyHierarchyLoadComponent,
    DepartmentSubLoadComponent,
    RejectConfirmDialogComponent,
    DepartmentSearchComponent,
    FormatTimePipe,
    GisMapComponent,
    MapComponent,
    MeasureToolComponent,
    DemographyByMap,
    AddressSearchComponent,
    AllDemographyHierarchyLoadComponent,
    EregistryForm60Component,
    DeedInstrumentComponent,
    ChallanComponent,
    DeedContentHistoryComponent,
    AmountPipe,
    AmtToWords,
    IndianNumberFormatPipe,
    PanEkycComponent,
    OwmPrinterComponent,
    WalletWiseChallanComponent,
    CommonPaymentResponseComponent,
    WalletPaymentResponseComponent,
    TransactionHistoryChallanComponent,
    WebcamComponent,
    EAuthComponent,
    NewShowMaketValueComponent,
    LimitToPipe,
    EsignComponent,
    DeedTemplateComponent,
    ERegUserDetailComponent,
    EregDeedTemplateComponent,
    RegisteredUserComponent,         
    OnlyNumberDirective,
    TrimValueAccessorDirective,
    AlphaDirective,
    IntegerDirective,
    OnlyNumberDirective,
    CopyPastValidationDirective,
    ComparePasswordDirective,
    TrimValueAccessorDirective,
    PositiveNumbersOnlyDirectiveDirective,
    AlphaDirective,
    AlphaNumericSpaceDirective,
    NotificationTemplateTextAllowed,
    NotificationTemplateTextAllowedHi,
    OrderByPipe,
    ActiondeedComponentComponent,
    TwoDigitDecimalNumberDirective,
    RestrictedNumberValidatorDirective,
    SpecialCharacterDirective,
    //TwoDecimalPlacesDirective,
    SpecialCharacterDirectiveHi,
    CommanDemographyHierarchyLoadComponent,
    EregistryCommonPropertyIdentificationComponent,
    ViewRefundDetailsComponent,
    RefundViewEregiComponent,
    RefundViewEstampComponent,
    ServiceProviderDetailsComponent,
    ViewSearchOrderHistoryComponent,
    RefundRequestListComponent,
    ProtestChargesRequestComponent,
    CourtOrderDetailsComponent,
    CaveatRequestComponent,
    CaveatRequestManualComponent,
    ChatbotComponent,
    BankLoanPaymentDetailsComponent,
    RefundRequestDetailsComponent,
    IdentifactionComponent,
    ChangeDemography,
     CitizenFeedbackComponent,
    FeedbackQuestionComponent,
    TreeChecklistExample,
    LayerListComponent,
    BasemapComponent,
    SelectionInstrumentComponent,
    OnlyBAllowedCharacter
  ],
  imports: [
    FormsModule,
    //BrowserModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    MaterialModule,
    MatTreeModule,
    AutosizeModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
       TranslateModule.forRoot({
       loader: {
         provide: TranslateLoader,
        useFactory: httpTranslateLoader,
         deps: [HttpClient]
       },
       defaultLanguage: 'hi',
       isolate:false,
       extend:true
    }),
    
   // NgxPrintModule
  ],
  exports: [
    AlertmessageComponent,
    DemographyLoadComponent,
    DemographyHierarchyLoadComponent,
    DepartmentSubLoadComponent,
    RejectConfirmDialogComponent,
    DepartmentSearchComponent,
    GisMapComponent,
    MapComponent,
    MeasureToolComponent,
    DemographyByMap,
    AddressSearchComponent,
    AllDemographyHierarchyLoadComponent,
    ChallanComponent,
    DeedInstrumentComponent,
    DeedContentHistoryComponent,
    WalletWiseChallanComponent,
    TransactionHistoryChallanComponent,
    RegisteredUserComponent,
    TwoDigitDecimalNumberDirective,
   // SpinnerViewComponent,
    //export Module
    MaterialModule, 
    RestrictedNumberValidatorDirective,
    //export directive
    OnlyNumberDirective,
    TrimValueAccessorDirective,
    AlphaDirective,
    OnlyNumberDirective,
    IntegerDirective,
    OnlyNumberDirective,
    CopyPastValidationDirective,
    ComparePasswordDirective,
    TrimValueAccessorDirective,
    AlphaDirective,
    AlphaNumericSpaceDirective,
    NotificationTemplateTextAllowed,
    NotificationTemplateTextAllowedHi,
    PositiveNumbersOnlyDirectiveDirective,
    OnlyBAllowedCharacter,
    //export pipe
    FormatTimePipe,
    AmountPipe,
    LimitToPipe,
    AmtToWords,
    IndianNumberFormatPipe,
    OrderByPipe,
    TranslateModule,
    SpecialCharacterDirective,
    //TwoDecimalPlacesDirective,
    SpecialCharacterDirectiveHi,
    CommanDemographyHierarchyLoadComponent,
    EregistryCommonPropertyIdentificationComponent,
    ViewRefundDetailsComponent,
    RefundViewEstampComponent,
    ServiceProviderDetailsComponent,
    ViewSearchOrderHistoryComponent,
    RefundRequestListComponent,
     ProtestChargesRequestComponent,
      CourtOrderDetailsComponent,
      CaveatRequestComponent,
      CaveatRequestManualComponent,
      ChatbotComponent,
      BankLoanPaymentDetailsComponent,
      RefundRequestDetailsComponent,
      IdentifactionComponent,
      ChangeDemography,
       CitizenFeedbackComponent,
    FeedbackQuestionComponent,
    TreeChecklistExample,
    LayerListComponent,
    BasemapComponent,
    SelectionInstrumentComponent
 
      
  ] ,
  providers: [
    AddressSearchServices,
    AppSettingService,
    SpinnerService,
    UtilityService,
    AmtToWords
  ]
  
  
})

export class CommonLibModule { 
  constructor(private translateService :TranslateHEService,private  translate:TranslateService){
    this.translate.setDefaultLang('hi')
  }
} 
 export function httpTranslateLoader(http: HttpClient) {
   return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
 }
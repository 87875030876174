<header class="citizenHeader sticky-top1">
  <div class="d-nonee">

    <div class="logo-wrapper pb-2">
      <div class="container">
        <div class="row  align-items-center">
          <div class="col-lg-3 col-sm-3">
            <div class="logo-holder">
              <div class="logo">
                <a routerLink="/">
                  <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80" />
                </a>

              </div>
              <div class="logo-title d-none">
                <h1>{{'default-header-text-1' | translate}} </h1>
                <!-- <p>{{'default-header-text-2' | translate}}</p> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 text-center col-sm-4 p-0">
            <a href="https://play.google.com/store/apps/details?id=in.gov.mpigr" class="btn btn-app" target="_blank"
              rel="noopener noreferrer" title="Play Store">
              <img height="24" src="assets/images/playstore.png">
              <span class="d-lg-inline d-none">Download on Google Play </span>
              <span class="d-lg-none d-inline">Get Mobile App </span>

            </a>
            <a href="https://apps.apple.com/us/app/sampada-2-0/id6472699756" class="btn btn-app" target="_blank"
              rel="noopener noreferrer" title="App Store">
              <img height="24" src="assets/images/applestore.png">
              <span class="d-lg-inline d-none">Download on App Store </span>
              <span class="d-lg-none d-inline">Get Mobile App </span>
            </a>
          </div>
          <div class="col-lg-5  col-sm-5 text-right d-print-none">
            <a class="mobile_seting" (click)="mobileSetting()" *ngIf="addSetting">
              <mat-icon>settings</mat-icon>
            </a>
            <a class="mobile_seting" (click)="mobileSettingRemove()" *ngIf="removeSetting">
              <mat-icon>close</mat-icon>
            </a>

            <ul class="top-nav mr-auto mb-2" id="mobileShow">
              <li class="nav-item" *ngIf="env=='prod'">
                <button class="themeMenu" mat-button
                  [matMenuTriggerFor]="colorswitcher"><mat-icon>format_color_fill</mat-icon>
                </button>
                <mat-menu #colorswitcher="matMenu" class="colorswitcheroptions">
                  <div class="theme-options">
                    <div class="clrsoptbox">
                      <div class="clrsopt mr-2" id="theme-default"
                        [ngClass]="(themeColor === 'theme-default') ? 'active' :  ''"
                        (click)="setTheme('theme-default')"> </div>
                      Default Theme
                    </div>
                    <ng-container *ngIf="env!='prod'">

                      <div class="clrsoptbox">
                        <div class="clrsopt mr-2" id="theme-green"
                          [ngClass]="(themeColor === 'theme-green') ? 'active' :  ''" (click)="setTheme('theme-green')">
                        </div>
                        Pacific Blue Theme
                      </div>

                      <div class="clrsoptbox">
                        <div class="clrsopt mr-2" id="theme-blue"
                          [ngClass]="(themeColor === 'theme-blue') ? 'active' :  ''" (click)="setTheme('theme-blue')">
                        </div>
                        Blue Theme
                      </div>
                      <div class="clrsoptbox">
                        <div class="clrsopt mr-2" id="theme-lightGrenn"
                          [ngClass]="(themeColor === 'theme-lightGrenn') ? 'active' :  ''"
                          (click)="setTheme('theme-lightGrenn')">
                        </div>
                        Light Green Theme
                      </div>
                    </ng-container>
                    <ng-container *ngIf="env=='prod'">
                      <div class="clrsoptbox">
                        <div class="clrsopt mr-2" id="theme-maroon"
                          [ngClass]="(themeColor === 'theme-maroon') ? 'active' :  ''"
                          (click)="setTheme('theme-maroon')">
                        </div>
                        Maroon Theme
                      </div>
                      <div class="clrsoptbox">
                        <div class="clrsopt mr-2" id="theme-coffee"
                          [ngClass]="(themeColor === 'theme-coffee') ? 'active' :  ''"
                          (click)="setTheme('theme-coffee')">
                        </div>
                        coffee Theme
                      </div>
                      <div class="clrsoptbox">
                        <div class="clrsopt mr-2" id="theme-focalPurple"
                          [ngClass]="(themeColor === 'theme-focalPurple') ? 'active' :  ''"
                          (click)="setTheme('theme-focalPurple')">
                        </div>
                        Purple Theme
                      </div>
                    </ng-container>

                  </div>

                </mat-menu>
              </li>




              <li class="nav-item font-selection">
                <a id="minus-zoom" [ngClass]="(defaultZoom === 'minus-zoom') ? 'active' :  ''"
                  (click)="setZoom('minus-zoom')" class="sm js-font-decrease ml-2"
                  title="Increase font size">A<sup>-</sup> </a>&nbsp; | &nbsp;
                <a id="default-zoom" [ngClass]="(defaultZoom === 'default-zoom') ? 'active' :  ''"
                  (click)="setZoom('default-zoom')" class="md js-font-normal" title="Reset font size">A</a>&nbsp; |
                &nbsp;
                <a id="plus-zoom" [ngClass]="(defaultZoom === 'plus-zoom') ? 'active' :  ''"
                  (click)="setZoom('plus-zoom')" class="lg js-font-increase"
                  title="Decrease font size">A<sup>+</sup></a>&nbsp; | &nbsp;
              </li>


              <li class="nav-item">

                <div *ngIf="langOptionFlag==1">
                  <a (click)="changeLanguageToEnglish(0)" routerLinkActive="router-link-active" class="nav-link "
                    style="cursor: pointer; " placement="bottom" ngbTooltip="Change to Hindi">
                    <i class="fa fa-globe"></i> &nbsp;{{'selected-language' | translate}} </a>
                </div>
                <div *ngIf="langOptionFlag==0">
                  <a (click)="changeLanguageToHindi(1)" routerLinkActive="router-link-active" class="nav-link "
                    style="cursor: pointer; " placement="bottom" ngbTooltip="Change to English">
                    <i class="fa fa-globe"></i> &nbsp;{{'selected-language' | translate}} </a>
                </div>

              </li>
              <li class="nav-item" style="font-size: 15px;">
                <a href="tel:+0755-2780133" class="font-weight-bold filter_drop"> Help Desk
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span class="text-primary blink_text"> : 0755-2780133</span></a>
              </li>


            </ul>

            <span routerLink="/" matRipple class="homeIcon float-left" *ngIf="isUserLoggedIn==false">
              <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon></span>

            <div matRipple class="openNavMenu" *ngIf="isUserLoggedIn==false">
              <div id="toggle" class="on">
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
              </div>
              <span class="menuName">{{'Menu' | translate}}</span>
            </div>

            <a href="/" routerLink="/userManual" target="_blank" class="userManualBlank mr-2"
              *ngIf="isUserLoggedIn==false">

              <mat-icon>description</mat-icon> {{'userManual' | translate}}
            </a>
            <a (click)="open(loginOptions, 'Login Menu')" class="btn-login mr-2" *ngIf="isUserLoggedIn!=true">
              <i class="fa fa-lock"></i> &nbsp;{{'login' | translate}}
            </a>


            <a routerLink="/registration" class="btn-register btn-primary" *ngIf="isUserLoggedIn!=true">
              <i class="fa fa-user-plus"></i> &nbsp;{{'register' | translate}}
            </a>

            <div *ngIf="isUserLoggedIn==true">
              <div class="user-div">
                <a href="/" routerLink="/userManual" target="_blank" class="userManualBlank mr-2">

                  <mat-icon>description</mat-icon> {{'userManual' | translate}}
                </a>
                <span class="my-0 fw-normal date-time">
                  <mat-icon class="timdat">date_range</mat-icon> {{todayDate | date:'EEE, MMM d, y' }} |
                  <mat-icon class="timdat">av_timer</mat-icon> {{todayDate | date:'hh:mm:ss a' }} </span>

                <a class="btn-register profile-btn" [matMenuTriggerFor]="menu">
                  <i>
                    <mat-icon>
                      person
                    </mat-icon>
                  </i>

                  <span class="d-lg-flex flex-column gap-1 d-none">
                    <h5 class="my-0 login-name" *ngIf="userType=='admin'"> {{lan==0?LoginUserNameEn:LoginUserNameHi}}
                    </h5>
                    <h5 class="my-0 login-name" *ngIf="userType=='citizen'">{{lan==0?LoginUserNameEn:LoginUserNameHi}}
                    </h5>
                    <span class="tranc" *ngIf="userType=='admin'"> {{lan==0?DesignationEn:DesignationHi}}</span>
                    <span class="tranc" *ngIf="userType=='citizen'"> {{'citizen' | translate}}</span>
                  </span>
                  <mat-icon class="cart-icon">arrow_drop_down</mat-icon>
                </a>
              </div>

              <mat-menu class="profileMenuContent" #menu="matMenu" xPosition="before">
                <span class="arrow"></span>
                <div *ngIf="userType=='admin'">
                  <div class="profileDetail">
                    <span class="userImg"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                    <h4 class=""> {{lan==0?LoginUserNameEn:LoginUserNameHi}}</h4>
                    <span class="tranc">{{lan==0?DesignationEn:DesignationHi}}</span>
                  </div>
                  <button mat-menu-item routerLink="admin/get-user-profile">
                    <mat-icon>account_circle</mat-icon>
                    {{'profile' | translate}}
                  </button>
                  <button mat-menu-item routerLink="admin/changepassword">
                    <mat-icon>key</mat-icon>
                    {{'change-password' | translate}}
                  </button>
                </div>
                <div *ngIf="userType=='citizen'">
                  <div class="profileDetail">
                    <span class="userImg"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                    <h4 class=""> {{lan==0?LoginUserNameEn:LoginUserNameHi}}</h4>
                    <span class="text-white"> {{'citizen-user' | translate}} </span>
                  </div>
                  <button mat-menu-item routerLink="citizen/profile">
                    <mat-icon>account_circle</mat-icon>
                    {{'profile' | translate}}
                  </button>
                  <button mat-menu-item routerLink="citizen/changepassword">
                    <mat-icon>key</mat-icon>
                    {{'change-password' | translate}}
                  </button>
                </div>
                <button mat-menu-item (click)="userType=='citizen'?logoutCitizen():logoutAdmin()">
                  <mat-icon>exit_to_app</mat-icon>
                  {{'logout' | translate}}
                </button>
              </mat-menu>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- nav -->




  </div>
</header>


<div id="menu" class="sticky-top">

  <div class="mainNav clearfix " id=" ">


    <nav class="navbar navbar-expand-md bg-theme p-0">
      <div class="container">

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown" *ngIf="!isUserLoggedIn;else notLoggedIn">
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" routerLink="/">{{'home' | translate}} <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/property-valuation"> {{'property-valuation.property-valuation' |
                translate}} <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/duty-calculation">{{'homepage.Duty-Calcutation' | translate}} <span
                  class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/citizenSuggestion">{{'homepage.View-Guideline' | translate}} <span
                  class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/complaint-request">{{'department-onboarding-form' | translate}}<span
                  class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">

              <a class="nav-link" (click)="searchRedirect()">{{'document-Search-Certified' | translate}}<span
                  class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active" *ngIf="env=='prod'">
              <a class="nav-link" routerLink="/raiseTicket">{{'ticketView.raiseTickets' | translate}}<span
                  class="sr-only">(current)</span></a>
            </li>
          </ul>


        </div>
        <ng-template #notLoggedIn>
          <div class="collapse navbar-collapse" id="navbarNavDropdown" *ngIf="userType=='citizen'">
            <ul class="navbar-nav mr-auto">

              <ng-container *ngFor="let menu of featureList; let i = index">
                <li [routerLinkActive]="['active']" id="{{menu.featureUrl}}"
                  *ngIf="menu != null && menu.parentId == 0 && menu.featureType?.featureTypeNameEn == 'MENU' && menu.featureUrl != null">
                  <div
                    *ngIf="menu != null  && menu.featureCode != 'F-0144' && menu.featureCode != 'F-0179' else searchFeature">
                    <a class="nav-link mnu-itm" routerLink="citizen/{{menu.featureUrl}}">
                      {{lan==0?menu.featureNameEn:menu.featureNameHi}} <span class="sr-only">(current)</span></a>
                  </div>
                  <ng-template #searchFeature>
                    <a class="nav-link" role="button" (click)="getUserEncDataForSearchDoc()"
                      *ngIf="menu.featureCode == 'F-0144'">
                      {{lan==0?menu.featureNameEn:menu.featureNameHi }} <span class="sr-only">(current)</span>
                    </a>
                    <a class="nav-link" role="button" (click)="getUserCMSData()" *ngIf="menu.featureCode == 'F-0179'">
                      {{lan==0?menu.featureNameEn:menu.featureNameHi }} <span class="sr-only">(current)</span>
                    </a>
                  </ng-template>
                </li>
                <li class="nav-item dropdown"
                  *ngIf="menu != null && menu.featureUrl == null && menu.featureType?.featureTypeNameEn == 'MENU'">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                    data-toggle="dropdown" aria-expanded="false">
                    {{lan==0?menu.featureNameEn:menu.featureNameHi}}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <span *ngFor="let child of featureList">
                      <div
                        *ngIf="child != null  && child.featureCode != 'F-0143' && child.featureCode != 'F-0145' else lcncFeature">
                        <a class="nav-link" routerLink="citizen/{{child.featureUrl}}"
                          *ngIf="child != null && menu.id==child.parentId && child.featureType?.featureTypeNameEn == 'SUBMENU'">
                          {{lan==0?child.featureNameEn:child.featureNameHi }}
                        </a>
                      </div>
                      <ng-template #lcncFeature>
                        <a class="nav-link" role="button" (click)="getUserEncDataForMISReport() "
                          *ngIf="menu.id==child.parentId && child.featureCode == 'F-0143' && menu.id == 142">
                          {{lan==0?child.featureNameEn:child.featureNameHi }}
                        </a>
                      </ng-template>
                    </span>
                  </div>
                </li>
              </ng-container>

            </ul>
          </div>
          <div class="collapse navbar-collapse" id="navbarNavDropdown" *ngIf="userType=='admin'">
            <ul class="navbar-nav mr-auto">
              <ng-container *ngFor="let menu of featureList; let i = index">
                <li [routerLinkActive]="['active']" id="{{menu.featureUrl}}"
                  *ngIf="menu != null && menu.parentId == 0 && menu.featureType?.featureTypeNameEn == 'MENU' && menu.featureUrl != null">
                  <div
                    *ngIf="menu != null  && menu.featureCode != 'F-0144' && menu.featureCode != 'F-0179' else searchFeature">
                    <a class="nav-link mnu-itm" routerLink="admin/{{menu.featureUrl}}">
                      {{lan==0?menu.featureNameEn:menu.featureNameHi}} <span class="sr-only">(current)</span></a>
                  </div>
                  <ng-template #searchFeature>
                    <a class="nav-link" role="button" (click)="getUserEncDataForSearchDoc()"
                      *ngIf="menu.featureCode == 'F-0144'">
                      {{lan==0?menu.featureNameEn:menu.featureNameHi }} <span class="sr-only">(current)</span>
                    </a>
                    <a class="nav-link" role="button" (click)="getUserCMSData()" *ngIf="menu.featureCode == 'F-0179'">
                      {{lan==0?menu.featureNameEn:menu.featureNameHi }} <span class="sr-only">(current)</span>
                    </a>
                  </ng-template>
                </li>
                <li class="nav-item dropdown"
                  *ngIf="menu != null && menu.featureUrl == null && menu.featureType?.featureTypeNameEn == 'MENU'">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                    data-toggle="dropdown" aria-expanded="false">
                    {{lan==0?menu.featureNameEn:menu.featureNameHi}}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <span *ngFor="let child of featureList">
                      <div
                        *ngIf="child != null  && child.featureCode != 'F-0143' && child.featureCode != 'F-0145' else lcncFeature">
                        <a class="nav-link" routerLink="admin/{{child.featureUrl}}"
                          *ngIf="child != null && menu.id==child.parentId && child.featureType?.featureTypeNameEn == 'SUBMENU'">
                          {{lan==0?child.featureNameEn:child.featureNameHi }}
                        </a>
                      </div>
                      <ng-template #lcncFeature>
                        <a class="nav-link" role="button" (click)="getUserEncDataForMISReport() "
                          *ngIf="menu.id==child.parentId && child.featureCode == 'F-0143' && menu.id == 142">
                          {{lan==0?child.featureNameEn:child.featureNameHi }}
                        </a>
                      </ng-template>
                    </span>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
      </div>
    </nav>

  </div>


</div>

<ng-template #loginOptions let-modal>

  <div class="loginoptionsmodal">
    <div class="modal-header">

      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-5">

      <a [href]="baseCitizenUrl + '/#/clogin'" [queryParams]="{ type: paramType}" class="loginoptions mb-3 d-flex"
        (click)="modal.dismiss('Cross click')">
        <span class="loginoptimg"> <img src="assets/images/living-person.png" height="35"> </span>
        <p class="m-0">{{'login-citizen' | translate}}</p>
      </a>
      <a [href]="baseDepartUrl+ '/#/alogin'" [queryParams]="{ type: paramType}" class="loginoptions mb-3 d-flex"
        (click)="modal.dismiss('Cross click')">
        <span class="loginoptimg"><img src="assets/images/juristic-person.png" height="35"> </span>
        <p class="m-0">{{'login-department' | translate}}</p>
      </a>
      <a routerLink="/complaint-request" [queryParams]="{ type: paramType}" class="loginoptions mb-3 d-flex"
        (click)="modal.dismiss('Cross click')">
        <span class="loginoptimg"><img src="assets/images/building.png" height="35"> </span>
        <p class="m-0">{{'department-onboarding-form' | translate}} </p>
      </a>

    </div>
  </div>

</ng-template>
<!---------some notification----------->
<div class="test_Environment" *ngIf="env !='prod'">
  <h3 class="text-danger" *ngIf="lan==0">Please be aware that this is a test environment for Sampada 2.0, designed to
    train service providers, banks, and other
    designated government department users. Access by unauthorized users is strictly prohibited. The data generated here
    is
    meant exclusively for testing purposes and holds no legal validity.</h3>

  <h3 class="text-danger" *ngIf="lan==1">कृपया ध्यान दें कि यह Sampada 2.0 का परीक्षण वातावरण है, जिसे सेवा प्रदाताओं,
    बैंकों और अन्य संबंधित सरकारी विभागों के
    उपयोगकर्ताओं को प्रशिक्षित करने के लिए तैयार किया गया है। अन्य उपयोगकर्ताओं द्वारा इसका उपयोग सख्ती से मना है। यहां
    उत्पन्न डेटा केवल परीक्षण के लिए है और इसका कोई कानूनी मान्यता नहीं है।</h3>

</div>
L.Control.LayerListbtn =  L.Control.extend({  
    includes: L.version[0]==='1' ? L.Evented.prototype : L.Mixin.Events,    
    options: {
      position: 'topleft'
    },

    onAdd: function(map) {
      var container = L.DomUtil.create('form', 'leaflet-bar leaflet-control-layers-toggle leaflet-control-custom');
      container.style.backgroundColor = 'white';     
      //container.style.backgroundImage = "url(http://t1.gstatic.com/images?q=tbn:ANd9GcR6FCUMW5bPn8C4PbKak2BJQQsmC-K9-mbYBeFZm1ZM2w2GRy40Ew)";
      container.style.backgroundSize = "25px 25px";
      container.style.width = '35px';
      container.style.height = '35px';
      container.style.cursor= 'pointer';
      return container;
    }
});
L.control.layerListbtn = function (options) {
    return new L.Control.LayerListbtn(options);
}

L.Control.MeasureButton =  L.Control.extend({  
  includes: L.version[0]==='1' ? L.Evented.prototype : L.Mixin.Events,     
    options: {
      position: 'topleft'
    },

    onAdd: function(map) {
      var container = L.DomUtil.create('form', 'leaflet-bar leaflet-control-custom');
      container.title="Measure Tool";
      container.style.backgroundColor = 'white';     
      //container.style.backgroundImage = "url(assets/images/measure.png)";
      container.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rulers" viewBox="0 0 16 16">
        <path d="M1 0a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 0 0-1-1H1z"/>
      </svg>`;
      container.style.backgroundSize = "25px 25px";
      container.style.backgroundPosition= "center";
      container.style.backgroundRepeat = "no-repeat";
      container.style.width = '35px';
      container.style.height = '35px';
      container.style.cursor= 'pointer';
      container.style.padding = '7px';
      return container;

      var css = '.leaflet-control-custom:hover{ background-color: #00ff00 }';
      var style = document.createElement('style');
      document.getElementsByTagName('head')[0].appendChild(style);
    }
});

L.control.MeasureButton = function (options) {
    return new L.Control.MeasureButton(options);
}
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { IgrsSubClausePropertyMappingBean } from 'projects/common-lib/src/lib/models/IgrsSubClausePropertyMappingBean';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { MstDemographyBeanSearch } from 'projects/common-lib/src/lib/models/MstDemographyBeanSearch';
import { MstPropertyTypeBean } from 'projects/common-lib/src/lib/models/MstPropertyTypeBean';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { PropertyValuationRequestBeanMap } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBeanMap';
import { PropertyValuationResponseBean } from 'projects/common-lib/src/lib/models/PropertyValuationResponseBean';
import { UOMEnum } from 'projects/common-lib/src/lib/models/UOMEnum';
import { WebGisResponseBeanData } from 'projects/common-lib/src/lib/models/WebGisResponseData';
import { Observable } from 'rxjs';
import { PropertyMasterPropetyTypeAction, PropertyMasterUomAction } from 'src/app/store/action/property-valuation.action';
import { PropertyValuationState } from 'src/app/store/state/property-valuation.state';

import { PropertyValuationService } from '../property-valuation.service';
import { NewShowMaketValueComponent } from 'projects/common-lib/src/lib/component/new-show-maket-value/new-show-maket-value.component';
import { ImpoundData } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { CommunicationServiceService } from '../../duty-calculation/duty-service/communication-service.service';
import { LoggerService } from 'projects/common-lib/src/lib/service/logger.service';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';
import { DataService } from '../DataService';
import { AgriPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/agriPropertyValuationRequestBean';

@Component({
  selector: 'app-plot-valuation',
  templateUrl: './plot-valuation.component.html',
  styleUrls: ['./plot-valuation.component.scss']
})
export class PlotValuationComponent implements OnInit, AfterViewInit {
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @Input() selectDemographyData: any
  @Input() propertyValuationRequestBeanData: PropertyValuationRequestBean
  @Input() subClauseMasterBeanData: IgrsSubClausePropertyMappingBean[]
  @Input() webGisResponseData: WebGisResponseBeanData
  @Input() fromDutyModule: boolean;
  //@Input() fromEregModule: boolean;
  @Input() builderShareData: number;
  @Input() fromCMSModule: boolean;
  @Input() fromCheckerModule: boolean;
  @Input() isImpoundAddProperty:boolean;
  @ViewChild('TotalArea') totalAreaModelref: NgModel;
  @ViewChild('unit') unitModelRef: NgModel;
  @ViewChild('resi') residentialPlotAreaRef:NgModel;

  mstDemographyBeanList: MstDemographyBean[] = new Array();
  demographyBeanSearch: MstDemographyBeanSearch[] = new Array();
  MstDemographyBeanListHistory: MstDemographyBean[] = new Array();
  subClauseMasterBean: IgrsSubClausePropertyMappingBean[] = new Array();
  subClassId: IgrsSubClausePropertyMappingBean[] = new Array();
  subclassIdKhasra: IgrsSubClausePropertyMappingBean[] = new Array();
  mstPropertyTypeBean: MstPropertyTypeBean[] = new Array();
  propertyValuationResponseBean: PropertyValuationResponseBean = new PropertyValuationResponseBean();
  uomList: any[] = new Array();
  selectedMstDemographyBean: MstDemographyBean;
  propertyValuationRequestBean: PropertyValuationRequestBean = new PropertyValuationRequestBean();
  propertyValuationRequestBeanMap: PropertyValuationRequestBeanMap = new PropertyValuationRequestBeanMap();
  agriPropertyValuationRequestBean:AgriPropertyValuationRequestBean=new AgriPropertyValuationRequestBean();
  totalAreaInArgiLand:number
  partialTransactAreaInArgiLand:number
  overAllInAgriLand:number
  agriValLessCheck:any
  totalAreaMsg: boolean = false;
  usageType: string;
  totalAreaNotZero: boolean = false
  isContinueButton:boolean=false
  mainAreaType: number = 1;
  showMarketValueBtn: boolean = true;
  plotTotalArea:number
  lan: number=1
  plotPatrialArea: number;
  plotOverAllArea: number;
  constructor(private changeDetector: ChangeDetectorRef,
    public matDialogRef: MatDialogRef<NewShowMaketValueComponent>,
    public matDialogRefPlot: MatDialogRef<PlotValuationComponent>,
    private router: Router, private pvService: PropertyValuationService,
    private ngxService: NgxUiLoaderService,
    private dataService: DataService ,
     private translateService: TranslateHEService, 
     public utilityService:UtilityService,
     private translate: TranslateService, public dialog: MatDialog,private commServ : CommunicationServiceService,
     private store:Store,
     private logger:LoggerService,) {
 

      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })

  }
  @Select(PropertyValuationState.getPropertyTypeList) mstPropertyTypeBean$: Observable<MstPropertyTypeBean[]>
  @Select(PropertyValuationState.getUomList) uomList$: Observable<UOMEnum[]>
  @Select(PropertyValuationState.getPropertTypeLoaded) PropertTypeLoaded$:Observable<boolean>
  @Select(PropertyValuationState.getUomLoaded) uomListloaded$: Observable<boolean>
  ngOnInit(): void {
    this.getAllUOM();
    this.propertyValuationRequestBean = this.propertyValuationRequestBeanData
    this.propertyValuationRequestBean.unit="SQMT"
    this.plotTotalArea=this.propertyValuationRequestBean.totalArea
    this.plotPatrialArea=this.propertyValuationRequestBean.totalArea
    this.plotOverAllArea=this.propertyValuationRequestBean.totalArea
    this.isContinueButton=false
    this.subClauseMasterBean = this.subClauseMasterBeanData.filter(s => { return s.propertyTypeId == 1 })
    this.fromDutyModule = this.fromDutyModule;
    this.propertyValuationRequestBean.builderShare = this.builderShareData;
    this.getAllPropertyType();
  }
  ngAfterViewInit() {
    this.dataService.getDataplotDataAgri().subscribe((data:any)=>{
      if(data!=null &&Object.keys(data).length!=0){
        this.logger.log("agriculture data on plot",data)
      this.agriPropertyValuationRequestBean=data.agriculturalWithConstruction
      this.totalAreaInArgiLand=this.agriPropertyValuationRequestBean.totalArea
      this.partialTransactAreaInArgiLand=this.agriPropertyValuationRequestBean.partialTransactArea
      this.overAllInAgriLand=this.agriPropertyValuationRequestBean.overAllArea??this.agriPropertyValuationRequestBean.totalArea
      this.isPartialTransact=this.agriPropertyValuationRequestBean.isPartialTransact
      this.propertyValuationRequestBean.isPartialTransact=this.agriPropertyValuationRequestBean.isPartialTransact
      this.propertyValuationRequestBean.webGisResponseBeanDataArr=this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr
     this.propertyValuationRequestBean.unit="SQMT"
     this.isContinueButton=data.isContinueButton
     this.mstPropertyTypeBean?.filter(propertyTypeId => {
      if (propertyTypeId.id == 1) {
        this.propertyValuationRequestBean.propertyType = propertyTypeId
      }
      });
     
      }

    })
    this.changeDetector.detectChanges()
    this.subClauseMasterBean.filter((d) => {
      this.propertyValuationRequestBeanData.subClause.filter((s: any) => {
        if (d.id == s.id) {
          d.checked = true;
          d.disabled = true;
        }
      });
    });
    if(!this.isContinueButton){
    this.totalAreaModelref.valueChanges.subscribe(res=>{
      this.logger.log("Total Area plot ",res)
      this.showMarketValueBtn = true;
      this.propertyValuationRequestBean.commercialPlotArea=null
      this.propertyValuationRequestBean.residentialPlotArea=null
      this.propertyValuationRequestBean.othersPlotArea=null
      this.propertyValuationRequestBean.healthPlotArea=null
      this.propertyValuationRequestBean.eduPlotArea=null
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea=null
    })
  }
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 0, // Minimum number of fraction digits
      maximumFractionDigits: 10, 
      useGrouping: false // Maximum number of fraction digits
      };
    this.unitModelRef.valueChanges.subscribe(res=>{
     
      if(res=='SQMT'&&(this.agriPropertyValuationRequestBean.totalArea!=null||undefined)){
      
        this.propertyValuationRequestBean.overAllArea=Number(new Intl.NumberFormat('en-US', options).format(this.overAllInAgriLand*10000)).toFixed(2)
        this.propertyValuationRequestBean.totalArea=Number(new Intl.NumberFormat('en-US', options).format(this.totalAreaInArgiLand*10000)).toFixed(2)
        this.propertyValuationRequestBean.partialTransactArea=Number(new Intl.NumberFormat('en-US', options).format(this.partialTransactAreaInArgiLand*10000)).toFixed(2)
        this.agriValLessCheck=Number(new Intl.NumberFormat('en-US', options).format(this.totalAreaInArgiLand*10000)).toFixed(2)
        }
      else if(res=='SQFT'&&this.agriPropertyValuationRequestBean.totalArea!=null||undefined){
        this.propertyValuationRequestBean.overAllArea=Number(new Intl.NumberFormat('en-US', options).format(this.overAllInAgriLand*107639)).toFixed(2)
          this.propertyValuationRequestBean.totalArea=Number(new Intl.NumberFormat('en-US', options).format(this.totalAreaInArgiLand*107639)).toFixed(2)
          this.propertyValuationRequestBean.partialTransactArea=Number(new Intl.NumberFormat('en-US', options).format(this.partialTransactAreaInArgiLand*107639)).toFixed(2)
          this.agriValLessCheck=Number(new Intl.NumberFormat('en-US', options).format(this.totalAreaInArgiLand*107639)).toFixed(2)
        }
        // else if(res=='SQMT'&&(this.propertyValuationRequestBean.totalArea!=null||undefined)){
      
        //   this.propertyValuationRequestBean.overAllArea=(new Intl.NumberFormat('en-US', options).format(this.plotTotalArea*10000))
        //   this.propertyValuationRequestBean.totalArea=(new Intl.NumberFormat('en-US', options).format( this.plotTotalArea*10000))
        //   this.propertyValuationRequestBean.partialTransactArea=(new Intl.NumberFormat('en-US', options).format(this.plotTotalArea*10000))
          
        //   }
        // else if(res=='SQFT'&&this.propertyValuationRequestBean.totalArea!=null||undefined){
        //   this.propertyValuationRequestBean.overAllArea=(new Intl.NumberFormat('en-US', options).format( this.plotTotalArea*107639))
        //     this.propertyValuationRequestBean.totalArea=(new Intl.NumberFormat('en-US', options).format( this.plotTotalArea*107639))
        //     this.propertyValuationRequestBean.partialTransactArea=(new Intl.NumberFormat('en-US', options).format(this.plotTotalArea*107639))

        //   }
    })
  }


  getAllSubClause() {
    this.pvService.getAllSubClauses().subscribe(dmodata => {
      this.subClauseMasterBean = dmodata.responseData;
    });

  }

  getAllUOM() {
    this.uomListloaded$.subscribe(res=>{
      this.logger.log(res)
      if(!res){
        this.store.dispatch(new PropertyMasterUomAction())
      }
    })
 
    this.uomList$.subscribe(res=>{
      this.logger.log(res)
      this.uomList=res
      // const index = this.uomList.indexOf("HA");
      // if (index > -1) { // only splice array when item is found
      //   this.uomList.slice(index, 1); // 2nd parameter means remove one item only
      // }
    })
    // this.pvService.getAllUOM().subscribe(dmodata => {
    //   // this.MstDemographyBeanList=dmodata.responseData;
    //   this.uomList = dmodata.responseData;;

   
   
  }

  getAllPropertyType() {

    this.PropertTypeLoaded$.subscribe(res=>{
      if(!res){
        this.store.dispatch(new PropertyMasterPropetyTypeAction())
        
      }
      this.mstPropertyTypeBean$.subscribe(res=>{
        this.logger.log(res)
        this.mstPropertyTypeBean=res
        this.logger.log(this.mstPropertyTypeBean)
        this.mstPropertyTypeBean.filter(pdata => { // indrajeet
          //
          if (pdata.id == this.mainAreaType) {
            
            this.propertyValuationRequestBean.propertyType = pdata
            this.logger.log( this.propertyValuationRequestBean.propertyType)
          }
        });
      })
    })
    

   
   
  }
  convertedUOM(uom: any) {
    return this.utilityService.convertedUOM(uom,this.lan)
  }
  // userTypeEvent(event: any) {
  //   this.showMarketValueBtn = true;
  //   if (this.propertyValuationRequestBean.isResidentialCumCommercial) {
  //     this.propertyValuationRequestBean.residentialPlotArea = '';
  //     this.propertyValuationRequestBean.commercialPlotArea = '';
  //     this.propertyValuationRequestBean.industrialPlotArea = '';
  //     this.propertyValuationRequestBean.healthPlotArea = '';
  //     this.propertyValuationRequestBean.eduPlotArea = '';
  //     this.propertyValuationRequestBean.othersPlotArea = '';
  //     if (this.propertyValuationRequestBean.isResidentialCumCommercial) {

  //       this.propertyValuationRequestBean.isResidensial = false;
  //       this.propertyValuationRequestBean.isCommercial = false;
  //       this.propertyValuationRequestBean.isIndustrial = false;
  //       this.propertyValuationRequestBean.isHealth = false;
  //       this.propertyValuationRequestBean.isEduArea = false;
  //       this.propertyValuationRequestBean.isOthers = false;
  //       this.propertyValuationRequestBean.isResidentialCumCommercial = false;
  //     }
      
  //   } else {
  //     this.propertyValuationRequestBean.isResidentialCumCommercial=false
  //     this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '';
  //   }
  //   this.usageType = event;
  //   this.propertyValuationRequestBean.buildingAreaType = event==true?'RCumCom':"no";
  // }
  userTypeEvent(event: any) {
    console.log(event)
  
    if (this.propertyValuationRequestBean.isResidentialCumCommercial) {
      this.propertyValuationRequestBean.residentialPlotArea = '';
      this.propertyValuationRequestBean.commercialPlotArea = '';
      this.propertyValuationRequestBean.industrialPlotArea = '';
      this.propertyValuationRequestBean.healthPlotArea = '';
      this.propertyValuationRequestBean.eduPlotArea = '';
      this.propertyValuationRequestBean.othersPlotArea = '';
      this.propertyValuationRequestBean.isResidensial = false;
      this.propertyValuationRequestBean.isCommercial = false;
      this.propertyValuationRequestBean.isIndustrial = false;
      this.propertyValuationRequestBean.isHealth = false;
      this.propertyValuationRequestBean.isEduArea = false;
      this.propertyValuationRequestBean.isOthers = false;
   
     
    } else {
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '';
      this.propertyValuationRequestBean.isResidentialCumCommercial=false
    }
    this.usageType = event? "RCumCom":"no";
   this.propertyValuationRequestBean.buildingAreaType = event?"RCumCom":"no"
   this.propertyValuationRequestBean.plotAreaType=event?"RCumCom":"no"
  }

  errorObj:{}
  showMarketValueEvent() {
    if(!this.inputChangeEventValidation()){
      this.errorObj= { responseMessage:'Sorry, this form is invalid. Please provide correct information and try again',
          responseMessageEn:'Sorry, this form is invalid. Please provide correct information and try again',
          responseMessageHi:'माफ़ करें, यह फ़ॉर्म अमान्य है। कृपया सही जानकारी प्रदान करें और पुनः प्रयास करें'}
          this.alertmessage.errorAlertMessage(this.errorObj)
     return
    }

    if (this.showMarketValueBtn == true) {
      return;
    }

    if (this.propertyValuationRequestBean.totalArea == 0) {
      this.alertmessage.errorAlertMessage('Total_Area-Not-zero');
      this.showMarketValueBtn = true;
      return
    }

    if (((this.totalCount != undefined && this.totalCount != 0) && this.totalCount != Number(this.propertyValuationRequestBean.totalArea))) {
      this.logger.log("totalCount erase", this.totalCount)

      this.alertmessage.errorAlertMessage('The_sum_of_area-plot');
      this.showMarketValueBtn = true;
      return
    }

    if (this.propertyValuationRequestBean.totalArea != null && this.propertyValuationRequestBean.totalArea != undefined && this.propertyValuationRequestBean.totalArea != '') {
      this.totalAreaMsg = false;
    } else {
      this.totalAreaMsg = true;
      return;
    }
      if(this.propertyValuationRequestBean.buildingAreaType=="no"){
        this.propertyValuationRequestBean.isResidentialCumCommercial=false
      }
      else{
        this.propertyValuationRequestBean.isResidentialCumCommercial=true
      }
   
//   if (this.agriPropertyValuationRequestBean.isPlotOnAgriLand == true) {
     
//     this.propertyValuationRequestBean.demography=this.agriPropertyValuationRequestBean.demography
//     this.agriPropertyValuationRequestBean.agriculturalWithPlot=this.propertyValuationRequestBean
//     this.ngxService.startLoader('Plot-01');
//     this.pvService.propValuation(this.agriPropertyValuationRequestBean).subscribe(data => {
   
//     if (data.responseStatus == "true") {
//       this.propertyValuationResponseBean = data.responseData;
//       this.ngxService.stopLoader('Plot-01');
//       this.openDialog(this.propertyValuationResponseBean);
//     } else {
//       this.ngxService.stopLoader('Plot-01');
//       this.alertmessage.errorAlertMessage(data);
//     }
//    },(error:any)=>{
//     this.ngxService.stopLoader('builing-01');
    
//   }

//  );
  
//  }
 // else {
    
    this.propertyValuationRequestBean.demography=this.selectDemographyData
    this.ngxService.startLoader('Plot-01');
    this.pvService.propValuation(this.propertyValuationRequestBean).subscribe(data => {
  
      if (data.responseStatus == "true") {
        this.propertyValuationResponseBean = data.responseData;
       this.ngxService.stopLoader('Plot-01');
        this.openDialog(this.propertyValuationResponseBean);
      } else {
       this.ngxService.stopLoader('Plot-01');
        this.alertmessage.errorAlertMessage(data);
      }
    }
     );
  //}
    
 
  }

  openDialog(data: any) {
    this.propertyValuationRequestBean.isImpoundAddProperty = this.isImpoundAddProperty
    this.propertyValuationRequestBean.builderShare = this.builderShareData
    let obj = { searchPropType: this.propertyValuationRequestBean, propData: data, fromDutyModule: this.fromDutyModule,formPropModule:true,fromCMSModule:this.fromCMSModule,fromCheckerModule:this.fromCheckerModule  };
    this.matDialogRef = this.dialog.open(NewShowMaketValueComponent, {
      data: obj,
    });
    this.matDialogRef
      .afterClosed()
      .pipe()
      .subscribe(result => {
        this.logger.log(result)

        this.logger.log("  result ", result)

        if (result.isContinued) {
          // this.router.navigateByUrl('#/property-valuation', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['#/property-valuation']);
          //  });
          this.reloadComponent(true,'property-valuation')
        }else if(result.fromCMSClose || result.fromCheckerClose){
          this.emitDataToImpoundModule(result);
          this.matDialogRefPlot.close();
        }
        else{
          this.matDialogRef.close();
  }

      })
  }

  emitDataToImpoundModule(res:any){
    let impoundData:ImpoundData;
              impoundData = res.impoundData;   
      this.commServ.emitImpoundData(impoundData);         
  }

  subClassModel: any
  addSubclauseEvent(event: any, slrow: any) {

    if (event.target.checked == true) {
      this.logger.log('true con', event.target.checked, slrow)
      this.propertyValuationRequestBean.subClause.push(slrow);
    } else {
      this.logger.log('false con', event.target.checked, slrow)
      this.propertyValuationRequestBean.subClause.forEach((value, index) => {

        if (value.id == slrow.id) {
          this.propertyValuationRequestBean.subClause.splice(index, 1);
        } else {
          //this.propertyValuationRequestBean.subClause.push(slrow);
        }
      });
    }
  }

  allFormReset() {
    this.propertyValuationRequestBean.totalArea = '';
    let demog = this.propertyValuationRequestBean.demography;
   
    this.propertyValuationRequestBean = new PropertyValuationRequestBean();
    this.propertyValuationRequestBean.demography = demog;
  
    this.propertyValuationRequestBean.isResidensial = false;
    this.propertyValuationRequestBean.isCommercial = false;
    this.propertyValuationRequestBean.isIndustrial = false;
    this.propertyValuationRequestBean.isHealth = false;
    this.propertyValuationRequestBean.isEduArea = false;
    this.propertyValuationRequestBean.isOthers = false;
    this.propertyValuationRequestBean.isConstOnAgriLand = false;
    this.propertyValuationRequestBean.isPlotOnAgriLand = false;
    this.propertyValuationRequestBean.isResidentialCumCommercial = false;
    
  }

  totalCount: number
  inputChangeEventValidation():boolean {
  
  
    if (this.propertyValuationRequestBean.totalArea != undefined && this.propertyValuationRequestBean.totalArea != '') {
      this.totalAreaMsg = false;
      let totalCount: number = 0;
   
      if (this.propertyValuationRequestBean.plotAreaType != "RCumCom") {
        totalCount = 0;
        if (this.propertyValuationRequestBean.residentialPlotArea != undefined && this.propertyValuationRequestBean.residentialPlotArea!='') {
          totalCount = totalCount + Number(this.propertyValuationRequestBean.residentialPlotArea);
        }
        if (this.propertyValuationRequestBean.commercialPlotArea != undefined && this.propertyValuationRequestBean.commercialPlotArea != '') {
          totalCount = totalCount + Number(this.propertyValuationRequestBean.commercialPlotArea);
        }
        if (this.propertyValuationRequestBean.industrialPlotArea != undefined && this.propertyValuationRequestBean.industrialPlotArea != '') {
          totalCount = totalCount + Number(this.propertyValuationRequestBean.industrialPlotArea);
        }
        if (this.propertyValuationRequestBean.healthPlotArea != undefined && this.propertyValuationRequestBean.healthPlotArea != '') {
          totalCount = totalCount + Number(this.propertyValuationRequestBean.healthPlotArea);
        }
        if (this.propertyValuationRequestBean.eduPlotArea != undefined && this.propertyValuationRequestBean.eduPlotArea != '') {
          totalCount = totalCount + Number(this.propertyValuationRequestBean.eduPlotArea);
          if(this.propertyValuationRequestBean.eduType!=null||undefined){
          //  return false
          }
          else{
            return false
          }
        }
      } else {
        //residentialCumCommercialPlotArea
        totalCount = 0;
        totalCount = totalCount + Number(this.propertyValuationRequestBean.residentialCumCommercialPlotArea);
      }
      if (totalCount == Number(this.propertyValuationRequestBean.totalArea)) {
        this.totalCount = totalCount
        this.showMarketValueBtn = false;
        return true
      } else {
        this.totalCount = 0
        this.showMarketValueBtn = true;
        return false
      }
      return true

    } else {
      //total area null and undefine
      this.totalAreaMsg = true;
      this.showMarketValueBtn = true;
      return false
    }
  }


  usageTypeEventPlot(inputType: any) {
    let totalCount = 0;
    if (this.propertyValuationRequestBean.residentialPlotArea != undefined) {
      totalCount = totalCount + Number(this.propertyValuationRequestBean.residentialPlotArea);
    }
    if (this.propertyValuationRequestBean.commercialPlotArea != undefined) {
      totalCount = totalCount + Number(this.propertyValuationRequestBean.commercialPlotArea);
    }
    if (this.propertyValuationRequestBean.industrialPlotArea != undefined) {
      totalCount = totalCount + Number(this.propertyValuationRequestBean.industrialPlotArea);
    }
    if (this.propertyValuationRequestBean.healthPlotArea != undefined) {
      totalCount = totalCount + Number(this.propertyValuationRequestBean.healthPlotArea);
    }
    if (this.propertyValuationRequestBean.eduPlotArea != undefined) {
      totalCount = totalCount + Number(this.propertyValuationRequestBean.eduPlotArea);
     
    }
    if (totalCount == Number(this.propertyValuationRequestBean.totalSellableAreaDiverted)) {
      this.showMarketValueBtn = false;
    } else if (totalCount == Number(this.propertyValuationRequestBean.totalArea)) {
      this.showMarketValueBtn = false;
    } else if (totalCount > Number(this.propertyValuationRequestBean.totalArea)) {
       if(inputType=='residentialPlotArea'){
        this.propertyValuationRequestBean.residentialPlotArea =''
       }
       else if(inputType=='commercialPlotArea'){
        this.propertyValuationRequestBean.commercialPlotArea=''
       }
       else if(inputType=='industrialPlotArea'){
        this.propertyValuationRequestBean.industrialPlotArea =''
       }
       else if(inputType=='healthPlotArea'){
        this.propertyValuationRequestBean.healthPlotArea =''
       }
       else if(inputType=='eduPlotArea'){
        this.propertyValuationRequestBean.eduPlotArea =''
       }
      else{}
      this.showMarketValueBtn = true;
    } else {
   
      this.showMarketValueBtn = true;
    }
  }


  totalAreaInHectare:any
  validationLessThan(event:any){
    if(this.isContinueButton&&this.propertyValuationRequestBean.partialTransactArea){
      this.totalAreaInHectare=(Number(this.propertyValuationRequestBean.partialTransactArea)/10000).toFixed(4)
    }
    else{
      this.totalAreaInHectare=null
    }
  
    if(Number(this.propertyValuationRequestBean.partialTransactArea)>=Number(this.propertyValuationRequestBean.overAllArea)){
      this.propertyValuationRequestBean.partialTransactArea='';
     }
     else if(this.propertyValuationRequestBean.totalArea != undefined && this.propertyValuationRequestBean.totalArea != ''&&this.propertyValuationRequestBean.partialTransactArea!=''){
      this.propertyValuationRequestBean.totalArea=this.propertyValuationRequestBean.partialTransactArea
     }
    this.resetForm();
  }

  isPartialTransact:boolean=false
  onCheckDataPush(event: any) {
    this.logger.log("onCheckDataPush",event.target.checked)
    this.isPartialTransact=event.target.checked
    this.isPartialTransact?
      this.propertyValuationRequestBean.overAllArea=this.propertyValuationRequestBean.totalArea:this.propertyValuationRequestBean.totalArea=this.propertyValuationRequestBean.overAllArea
    
    this.resetForm();
    
  }


   

  resetForm(){
    this.propertyValuationRequestBean.residentialPlotArea = '';
    this.propertyValuationRequestBean.commercialPlotArea = '';
    this.propertyValuationRequestBean.industrialPlotArea = '';
    this.propertyValuationRequestBean.healthPlotArea = '';
    this.propertyValuationRequestBean.eduPlotArea = '';
    this.propertyValuationRequestBean.othersPlotArea = '';
   
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   this.logger.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       this.logger.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }
 

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { OfficeList } from 'projects/common-lib/src/lib/models/api.masterdata.model/office-list';
import { MasterOfficeModel } from 'projects/common-lib/src/lib/models/api.masterdata.model/office.model';
import { UserProfileModel } from 'projects/common-lib/src/lib/models/auth.userdetail.model/user.profile.model';
import { User } from 'projects/common-lib/src/lib/models/user.login.model/user.login.model';

@Component({
  selector: 'app-selectoffice',
  templateUrl: './selectoffice.component.html',
  styleUrls: ['./selectoffice.component.scss']
})
export class SelectofficeComponent implements OnInit {

  selectedOffice: number;
  officeList: OfficeList[] = [];
  credential: User = {};
  userProfileModel: UserProfileModel = new UserProfileModel();
  lan:number=1
  keyParam: string;

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  constructor(private ngxService: NgxUiLoaderService,
    private router: Router, private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private translateService:TranslateHEService) {
   
    this.userProfileModel = new UserProfileModel();
    this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
    //
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     }
    )

  }

  ngOnInit(): void {

    if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {
      if (sessionStorage.getItem("selectedOffice") == null || sessionStorage.getItem("selectedOffice") == "null" || sessionStorage.getItem("selectedOffice") === "undefined" || sessionStorage.getItem("selectedOffice") === undefined) {

        //this.router.navigate(['select-office']);
        this.changeUserName();
      } else {

        //this.authenticationService.getprofileUsingToken(sessionStorage.getItem("token"));
        this.router.navigate(['./admin/dashboard']);
        console.log('5');
      }
    } else {
      this.authenticationService.logout().subscribe(res => {
        res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
      });
      //this.getStartupCaptcha();
      //this.getSSOUrl();
    }
  }

  changeUserName() {
    this.ngxService.start();
    this.credential.userName = this.userProfileModel.userName || ""
    this.authenticationService.getOfficeListByUsername(this.credential).subscribe(result => {
      this.ngxService.stop();
      if (result.responseStatus == 'true') {
        this.selectedOffice = 0;
        if (result.responseData.length > 1) {
          this.officeList = result.responseData;
        } else {
          this.selectedOffice = result.responseData[0].id;
          this.onSubmitForLogin();
        }
        this.officeList = result.responseData;
      } else {
      }

    },
      err => {
        this.ngxService.stop()
        //  Swal.fire("Error", "TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !", "error");
        //this.alertmessage.errorAlertMessage("TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !");
      }
    );
  }

  onSubmitForLogin() {
    // 

    if (this.selectedOffice > 0) {
      this.ngxService.start();
      console.log('this.selectedOffice--', this.selectedOffice);
      this.authenticationService.loginWithOfficeSelect(this.selectedOffice).pipe().subscribe(
        data => {
          if (data.status == 'true') {
            this.ngxService.stop()
            if (sessionStorage.getItem('selectedOffice') != null && sessionStorage.getItem('selectedOffice') != '') {


              let selectedOffice: MasterOfficeModel = JSON.parse(sessionStorage.getItem('selectedOffice') || "");
              if (selectedOffice != undefined && selectedOffice != null) {
                if (this.userProfileModel?.mobileVerifyStr == null || this.userProfileModel?.mobileVerifyStr == undefined || this.userProfileModel?.mobileVerifyStr == "") {
                  sessionStorage.setItem('isUserLoggedIn', '');
                  this.router.navigate(['mobile-verify']);
                } else if (this.userProfileModel?.emailVerifyStr == null || this.userProfileModel?.emailVerifyStr == undefined || this.userProfileModel?.emailVerifyStr == "") {
                  if (selectedOffice.department != null && selectedOffice.department.isLdap == 1) {
                    sessionStorage.setItem('isUserLoggedIn', '');
                    this.router.navigate(['login-kyc']);
                  } else {
                    this.router.navigate(['./admin/dashboard']);
                    console.log('1');
                  }
                } else {
                  this.router.navigate(['./admin/dashboard']);
                  console.log('2');
                }
              }





              if (selectedOffice.department.isLdap == 1 && this.userProfileModel.emailVerifyStr != 'true') {
                this.router.navigate(['./login-kyc']);
              } else {
                this.router.navigate(['./admin/dashboard']);
                console.log('3');
              }
              console.log('4');
              this.keyParam = sessionStorage.getItem("keyParam")
              if (this.keyParam != null && this.keyParam != undefined)
                this.router.navigate(['./admin/' + this.keyParam]);
              else
                this.router.navigate(['./admin/dashboard']);

            } else {
              //this.router.navigate(['select-office']);
              this.alertmessage.errorAlertMessage("login-form-data.pl-select-office-name");
            }
          }
          else {
            this.ngxService.stop()
            // Swal.fire("Error", data.data.responseMessage, "error")
            this.alertmessage.errorAlertMessage(data.data);
          }
        },
        err => {
          this.ngxService.stop()
          // Swal.fire("Error", "TECHNICAL ERROR !", "error");
          this.alertmessage.errorAlertMessage("login-form-data.tech_error_msg");
        })
    } else {
      this.alertmessage.errorAlertMessage("login-form-data.pl-select-office-name");
    }
  }

  Cancel() {
    this.authenticationService.logout().subscribe(res => {
      res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
    });
  }

}

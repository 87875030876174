import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAlphaNumericSpace]'
})
export class AlphaNumericSpaceDirective {
  //private regex: RegExp = new RegExp(/^[a-zA-Z0-9-@._ ]+$/);
  private regex: RegExp = new RegExp(/^[a-zA-Z0-9._ ]+$/);
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    
    let current: string = this.el.nativeElement.value;
    //const position = this.el.nativeElement.selectionStart;
    //const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}

import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { MstDemographyBean } from '../models/MstDemographyBean';
import { AESEncryptDecryptService } from '../helper-lib/services/aesencrypt-decrypt.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class QueryLayerServices {

  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private _AESEncryptDecryptService: AESEncryptDecryptService, private http:HttpClient) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
   
  }

 getBoundryURLs(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson';
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
    }
    return url;
  }

  getBoundryURLsClipAndMerge(obj:any){
    let query:String;
    let url:string='';
    let D:any=obj.demographyType;
    switch(obj.demographyTypeId){
      case 2:
          url='';
        break;
      case 4:
          query = 'sr_off_cd='+obj.lgdCode;
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Asro_boundary&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 3:
          query='dist_cd='+obj.lgdCode;
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdistrict&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
        break;
      case 7:
          query="lgd_ulb_cd="+obj.lgdCode;
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
        break;
      case 8:
        query="ward_cd="+obj.lgdCode+" and status=1";
        url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
      case 12:
          query="teh_cd='"+obj.lgdCode+"'";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
        break;
      case 10:
          query="vill_cd="+obj.lgdCode+" and status=1";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
      case 14:
          query="col_id='"+obj.colonyId+"'and status=1";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
      case 15:
          query="col_id='"+obj.colonyId+"' and status=1";
          url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson'
        break;
    }
     return url;
  }

  getBoundryEncURLsClipAndMerge(obj:any){
    let query:String;
    let url:string='';
    let D:any=obj.demographyType;
    switch(obj.demographyTypeId){
      case 2:
          url='';
        break;
      case 4:
          query = 'sr_off_cd='+obj.lgdCode;
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Asro_boundary&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 3:
          query='dist_cd='+obj.lgdCode;
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdistrict&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 7:
          query="lgd_ulb_cd="+obj.lgdCode;
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 8:
        query="ward_cd="+obj.lgdCode+" and status=1";
        url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson')
        break;
      case 12:
          query="teh_cd='"+obj.lgdCode+"'";
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 10:
          query="vill_cd="+obj.lgdCode+" and status=1";
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson')
        break;
      case 14:
          query="col_id='"+obj.colonyId+"'and status=1";
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson')
        break;
      case 15:
          query="col_id='"+obj.colonyId+"' and status=1";
          url=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=500&outputFormat=application%2Fjson')
        break;
    }
     return url;
  }


  getBoundryURLsfinal(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson';
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson';
        break;
    }
    return url;
  }

  getBoundryURLsEncfinal(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson');
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
    }
    return url;
  }

  getBoundryURLsEnc(obj: any) {
    let query: string;
    let url: string = '';
    //let D: any = obj.demographyType;
    switch (obj.demographyTypeId) {
      case 2:
        query = 'div_cd=' + obj.lgdCode;
        //url=this.settings.geoServerWmsUrl+'adminbdy/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adminbdy%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=50&outputFormat=application%2Fjson';
        url = ""
        break;
      case 4:
        query = 'sr_off_cd='+obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Asro_boundary&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson');
        break;
      case 3:
        query = 'dist_cd=' + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Ampdistrict&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 7:
        query = "lgd_ulb_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 8:
        query = "ward_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Award_igrs&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 12:
        query = "teh_cd='" + obj.lgdCode + "'";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 10:
        query = "vil_cd=" + obj.lgdCode;
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER=' + query + '&outputFormat=application%2Fjson')
        break;
      case 14:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
      case 15:
        query = "col_id='" + obj.colonyId +"' and status=1";
        url = this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3Adraft_igrs_gis_gl&CQL_FILTER=' + query + '&maxFeatures=1&outputFormat=application%2Fjson')
        break;
    }
    return url;
  }

  getFinalGuidelineURL(query:string):string{
    return this.geturlwithQuery(query, "final_igrs_gis_gl");
  }

  getFinalGuidelineEncURL(query:string):string{
    return this.getEncurlwithQuery(query, "final_igrs_gis_gl");
  }


  geturlwithQuery(query:string, layerName:string){
    return this.settings.geoServerWmsUrl + this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER=' + query + '&maxFeatures=10&outputFormat=application%2Fjson';
  }

  getEncurlwithQuery(query:string, layerName:string, maxfeature:Number=1){
    return this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER=' + query + '&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson');
  }

  getEncurlwithQueryAndProperty(query:string, layerName:string, property:string="", maxfeature:Number=1){
    return this.settings.geoServerEncUrl + this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+ this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER=' + query + '&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson&property');
  }

  customgetRequest<T>(methodType:any, url:string, param:any, tocken:string):Observable<T>{
    let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json').set('Authorization', `Bearer ${tocken}`);
    return this.http.request<T | any>(methodType, url,{headers:httpHeaders, params:param}).pipe(
        map((data:any) => {
            if (!data) {
                throw { Message: data, Type: "Failed" };
            }
            return data;
        }),catchError(err=> throwError(this.handleError(err)))
      );
  }

  customgetDecRequest<T>(methodType:any, url:string, param:any, tocken:string):Observable<T>{
    let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json').set('Authorization', `Bearer ${tocken}`);
    return this.http.request<T | any>(methodType, url,{headers:httpHeaders, params:param}).pipe(
        map((data:any) => {
            if (!data) {
                throw { Message: data, Type: "Failed" };
            }
            let dd =  this._AESEncryptDecryptService.decryptInAngular(data.body);
            data.body = JSON.parse(dd);
            return data;
        }),catchError(err=> throwError(this.handleError(err)))
      );
  }

  handleError = (error: HttpErrorResponse) => {
   // console.log('Handle on Component', error);
  };
  //let url:string=this.settings.geoServerEncUrl+this.settings.workSpace+'/ows/'+this._AESEncryptDecryptService.encrypt('service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson')+'?ismobile=false';
    

}

<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>
                    
                    {{'detailsPages.slotReAvli' | translate}}
                </h2>

                <!-- <button mat-button="mat-button" class="btn btn-primary" id="sticky_Div">
                    {{'detailsPages.processForslotReAvli' | translate}}
                </button> -->
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What are Slots?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            स्लॉट क्या हैं?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            The Time Duration on a particular day, in which the Parties wish to do the
                                            registry are slots
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            किसी विशेष दिन की समयावधि, जिसमें पक्षकार रजिस्ट्री कराना चाहते हैं, वह
                                            स्लॉट हैं|
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            How to book the slot?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            स्लॉट कैसे बुक करें?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Registry Initiators can book the slots to do registry using web portal or
                                            mobile app after completing all the previous steps in Registry Initiation
                                        </p>


                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            रजिस्ट्री आरंभकर्ता रजिस्ट्री आरंभ में पिछले सभी चरणों को पूरा करने के बाद
                                            वेब पोर्टल या मोबाइल ऐप का उपयोग करके रजिस्ट्री करने के लिए स्लॉट बुक कर
                                            सकते हैं।
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What is rescheduling?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            पुनर्निर्धारण क्या है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            The process of changing the timings of a previously booked slot is called
                                            rescheduling</p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            पहले से बुक किए गए स्लॉट के समय को बदलने की प्रक्रिया को पुनर्निर्धारण कहा
                                            जाता है
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What are rescheduling charges?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            पुनर्निर्धारण शुल्क क्या हैं?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            If the user wants to reschedule a slot after it is expired or have generated
                                            a token for office-based registry but not performed registry or have booked
                                            an interactive vKYC registry but not joined the call and then wants to
                                            reschedule the slot, the system prompts them to pay certain rescheduling
                                            charges based on each scenario.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            यदि उपयोगकर्ता स्लॉट समाप्त होने के बाद उसे पुनर्निर्धारित करना चाहता है या
                                            कार्यालय-आधारित रजिस्ट्री के लिए टोकन जेनरेट किया है, लेकिन रजिस्ट्री नहीं
                                            की है या एक इंटरैक्टिव वीकेवाईसी रजिस्ट्री बुक की है, लेकिन कॉल में शामिल
                                            नहीं हुआ है और फिर स्लॉट को पुनर्निर्धारित करना चाहता है, तो सिस्टम उन्हें
                                            संकेत देता है कि उन्हें प्रत्येक परिदृश्य के आधार पर कुछ पुनर्निर्धारण शुल्क
                                            देना होगा |
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>


                            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What is premium slot?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            प्रीमियम स्लॉट क्या है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            For User’s convenience, department has decided to provide Premium Slot
                                            feature to them, so that they can book slots at their desired Date & Time by
                                            paying certain amount charged by the department, such slots are called
                                            Premium slots.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            उपयोगकर्ताओं की सुविधा के लिए, विभाग ने उन्हें प्रीमियम स्लॉट सुविधा प्रदान
                                            करने का निर्णय लिया है, ताकि वे विभाग द्वारा निर्धारित की गई निश्चित राशि का
                                            भुगतान करके अपनी वांछित तिथि और समय पर स्लॉट बुक कर सकें, ऐसे स्लॉट को
                                            प्रीमियम स्लॉट कहा जाता है।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Check slot availability.
                                        </div>
                                        <div *ngIf="lan == 1">
                                            स्लॉट की उपलब्धता जांचें

                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            User can check the Slot Availability For Office Based Registry or
                                            Interactive VKYC based registry by selecting SR Office (in case of Office
                                            based registry) and desired date & Time Block in “Slot booking” section
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            उपयोगकर्ताओं की सुविधा के लिए, विभाग ने उन्हें प्रीमियम स्लॉट सुविधा प्रदान
                                            करने का निर्णय लिया है, ताकि वे विभाग द्वारा निर्धारित की गई निश्चित राशि का
                                            भुगतान करके अपनी वांछित तिथि और समय पर स्लॉट बुक कर सकें, ऐसे स्लॉट को
                                            प्रीमियम स्लॉट कहा जाता है।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                    <!-- <div class="col-xl-6 col-lg-6 col-sm-12">
                        <div class="process_img">
                            <img src="assets/images/SPlicenseprocess.jpg" style="width: 100%"/>
                        </div>
                    </div> -->
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
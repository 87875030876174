import { addOfficeDetail } from "../addOffice.model/addOffice.model";
import { SubRole } from "../citizen.login.model/citizen.profile.model";

export class UserProfileModel{
    id?:number;
    userName?:string;
    password?:string;
    oldpassword?:string;
    confirmPassword?:string;
    email?:string;
    mobileNo?:string;
    name?:string;
    nameHi?:string;
    nameEn?:string;
    userStatus?:UserStatus;
    emailVerifyStr?:string;
    mobileVerifyStr?:string;
    status?:number;
    designation?:string;
    designationHi?:string;
    designationEn?:string;
    profilePic?:string;
    isServiceProvider:number;
    selectedOffice:addOfficeDetail;
    userId:number;
    certificate:string;
    subRoleList?:SubRole[];
    kycType:string;
    kycUniqueToken:string;
    kycTxnLogNo:string;
    aadharNumberMasked: string;
    nameOnPan: string;
    aadharRefKey:string;
    citizenType:number;
}

export class UserStatus{
    id?:number;
    name?:string;
    description?:string;
}

export class Department {
    userId?: number;
    userName?: string;
    password?: string;
    captchaId?: string;
    captchaStr?: string;
    captchaStr2?: string;
    otp?: string;
    mobileNo?: string;
    email?: string;
    citizenType?: number;
    userType?: string;
    status?: string;
    partyType?: string;
    tempEregId?: string;
    key?: string;
    profileBase64?: string;
    ownerType?: string;
    // LoginType?: string;
}



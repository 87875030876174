export enum DutyOtherFlagEnum {

M="M",
TRFP="TRFP",
RT="RT",
MT="MT",
R="R",
Y="Y",
MORE="MORE",
S="S",
Q="Q",
AMF="AMF",
STP="STP",
TSDP="TSDP",
FTP="FTP",
DR="DR"
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EstampDeedTemplateContent } from '../../models/estamp.model/estampDeedTemplateContent';
import { NextActionModel } from '../../models/NextActionModel';
import { CommentsModel } from '../../models/CommentsModel';
import { AddEstamp, EstampCommonBean } from '../../models/addEstamp.model/add-estamp.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationServiceService } from '../../service/communication-service.service';
import { TranslateHEService } from '../../helper-lib/services/common/translateHE.service';
import { EstampService } from 'projects/front-web/src/app/service/estamp.service';
import { CustomHttpClient } from '../../helper-lib/httpclient/custom.httpclient';
import { DutyService } from 'projects/front-web/src/app/modules/duty-calculation/duty-service/duty.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
 

@Component({
  selector: 'app-actiondeed-component',
  templateUrl: './actiondeed-component.component.html',
  styleUrls: ['./actiondeed-component.component.scss']
})
export class ActiondeedComponentComponent implements OnInit {
  
  @ViewChild('closeModalDeedPdf') closeModalDeed: ElementRef;
  @Input('partyId') partyId:any
  @Input('userId') userId : any;
  @Input('estampId') estampId:any
  @Input('partyStatus') partyStatus:any
  @Input('mobileNo') mobileNo:any
  @Input('partyType') partyType:number 
  @Input('partySubType') partySubType:number 
  @Input('estampContentStatus') estampContentStatus:number 
  @Input('natureOfInstrument') natureOfInstrument:string
  @Input('eregFlag') eregFlag:any
  @Input('isActionButtonShow') isActionButtonShow:any

  estampPartyContentModel:EstampDeedTemplateContent=new EstampDeedTemplateContent();
  public nextActionModelArray:NextActionModel[]=[];
  public commentsModelArray:CommentsModel[] = [];
  statusCheck:boolean=true
  partyresponseCheck:boolean=false;
  remarksCheck:boolean=false;
  isRemarkShow:boolean=false;
  onFormSubmit:boolean=false;
  estampCommonBean: EstampCommonBean = new EstampCommonBean();
  addEstamp:AddEstamp=new AddEstamp();
  lan: number;

  constructor(private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, 
    private translateServiceIns: TranslateService, private commServ: CommunicationServiceService,
    private customeHttpClient: CustomHttpClient,private estampService: EstampService,private dutyService:DutyService,private router: Router) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
    }

  ngOnInit(): void {
    console.log("actiondeed estampId::"+this.estampId +" partyStatus"+this.partyStatus+ " userId::"+this.userId+" partyId::"+this.partyId+" partyType::"+this.partyType+" partySubType::"+this.partySubType+" estampContentStatus::"+this.estampContentStatus)
    this.estampPartyContentModel.estampId=this.estampId;
    this.estampPartyContentModel.estampPartyId=this.partyId;
    this.estampPartyContentModel.status=null;
    if(this.partyStatus){
      this.estampPartyContentModel.status=this.partyStatus;
    }
    if(this.partyStatus==73){
      this.estampPartyContentModel.status=null;
    }
    if(this.partyStatus == null || this.partyStatus == undefined){
      this.partyStatus = false;
    }

    if(this.estampContentStatus===11 && this.partySubType===1 ){
      this.statusCheck=false
    }
    // else if(this.estampContentStatus===11 && (this.partySubType===2 || this.partySubType===4) && (this.natureOfInstrument=='Bilateral')){
    //   this.statusCheck=false
    // }
    // else if(this.estampContentStatus===11 && (this.partySubType===2  || this.partySubType===5 || this.partySubType===0) && (this.natureOfInstrument=='Bilateral' || this.natureOfInstrument=='Unilateral')){//change on 23-09-2023 according to manish sir
    //   this.statusCheck=false
    // }
    else if(this.estampContentStatus===11 && (this.partySubType===2  || this.partySubType===5) ){//change on 16-11-2023 according to Nitin sir case by Bilateral/Unilateral
      this.statusCheck=false
    }
    else if(this.estampContentStatus===11 && (this.partySubType===3 || this.partySubType===4 || this.partySubType===0) && this.eregFlag ===1){
        this.statusCheck=true
    }
    this.getStatusListByModuleType("ESTAMP_PARTY_CONTENT_STATUS")
    this.getRemarkHistoryByEstampId(this.estampId)
    this.getEstampDetailsByEstampId()
  }



  resetEstampAddPartyDetails(){
    this.estampPartyContentModel.status=null;
    this.estampPartyContentModel.remarks="";
  }

  partyResponseOnChange(partyResponseVal:any){
    if(partyResponseVal == 14){
      this.isRemarkShow = true;
      this.remarksCheck = true;
      this.partyresponseCheck = false
    }else{
      this.isRemarkShow = false;
      this.remarksCheck = false;
      this.partyresponseCheck = false
    }
  }

  onSubmitPartyResponse(form:NgForm){
  var partyResponseRemarks = form.control.get('partyResponse').value;

  if(partyResponseRemarks==null || partyResponseRemarks==undefined){
    this.partyresponseCheck = true; 
    if(form.invalid){
      return
    }
  }
  else{
    this.partyresponseCheck = false; 
  }

  if(this.remarksCheck){
    var partyremarks = form.control.get('remarks').value;

    if(partyremarks==null || partyremarks==undefined || partyremarks==""){
      this.remarksCheck = true; 
      if(form.invalid){
        return
      }
    }
    else{
      this.remarksCheck = false;
    }
  }
  
  console.log("partyResponseRemarks::"+partyResponseRemarks+" partyremarks::"+partyremarks)

  if(!this.partyresponseCheck && !this.remarksCheck){
    this.onFormSubmit = true;
    (document.getElementById("modalDeedPdfClose") as any).click();
    this.estampService.savePartyStatusRemark(this.estampPartyContentModel).subscribe(res=>{
      if(res.responseData!=undefined && res.responseData!=null){
                  
        // this.estampCommonBean =  res.responseData;
        console.log(res.responseData);
        this.ngxService.stop();
        // this.estampCommonBean.viewJasperDeed =false;
        // this.estampCommonBean.isDeedRequired = true;
        // if(this.estampCommonBean?.igrsEstampDetailDisplay!=null && this.estampCommonBean?.igrsEstampDetailDisplay!=undefined){
        //   this.estampService.getCitizenDetailsWithSp(this.estampCommonBean?.igrsEstampDetailDisplay?.createdBy).subscribe(templateData => {
        //     this.ngxService.stop();
        //     if (templateData.responseStatus == 'true') {
        //       this.estampCommonBean.igrsCitizenUserBean = templateData.responseData;
        //      this.generateJasper(this.estampCommonBean);
        //     } else {
        //       this.ngxService.stop();
        //     }
        //   });
        // }
        this.router.navigate(['estamp-list' ], {
          state:{
            data: this.mobileNo,
            eregFlag:this.eregFlag,
           // partyId:this.partyId,
            userId:this.userId
          }
        });
      }
      else{
        this.ngxService.stop();
        Swal.fire({
          icon: 'error',
          title: (this.lan==0)?'Error':'त्रुटि ',
          text: (this.lan==0)?'Server Error':' सर्वर त्रुटि ',
          confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
        });
      }
        
     
    });
  }
  }

  acceptDeed(){
    var partyremarks = (<HTMLInputElement>document.getElementById("remarks")).value;
    this.estampPartyContentModel.status=13;
    this.estampPartyContentModel.remarks=partyremarks;
    this.remarksCheck = false;

    if(this.addEstamp.contentStatus>10){
      if(this.addEstamp.finalSignFileName!=null){
        Swal.fire({
          //title: 'Are you sure?',
          title: (this.lan==0)?"Are you sure accept the deed?":"क्या आप निश्चित रूप से विलेख स्वीकार करते हैं?",
          //text: 'This process is irreversible.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: (this.lan==0)?"Yes, go ahead.":"हाँ आगे बढ़ो।",
          cancelButtonText: (this.lan==0)?"No, let me think":"नहीं, मुझे सोचने दो",
        }).then((result) => {
          if (result.value) {
          
            this.ngxService.start();
            (document.getElementById("modalDeedPdfClose") as any).click();
            this.estampService.savePartyStatusRemark(this.estampPartyContentModel).subscribe(res=>{
              this.ngxService.stop();
              if (res.responseStatus == 'true') {
              this.router.navigate(['estamp-list' ], {
                state:{
                  data: this.mobileNo,
                  eregFlag:this.eregFlag,
                 // partyId:this.partyId,
                  userId:this.userId
                }
              });
            }else{
              this.ngxService.stop();
            }
              
            },
            (err)  => {
              this.ngxService.stop();
          });
    
          } 
        });
  
  
      }else{
        Swal.fire({
          icon: 'warning',
          title: (this.lan==0)?'Deed Acceptance':'विलेख स्वीकृति',
          text: (this.lan==0)?'Please wait for few minutes as your deed generating':'कृपया डीड तैयार होने तक कुछ मिनट प्रतीक्षा करें',
          confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
        });
      }
    }
    
  }
  rejectDeed(){
    var partyremarks = (<HTMLInputElement>document.getElementById("remarks")).value;
    this.estampPartyContentModel.status=14;
    this.estampPartyContentModel.remarks=partyremarks;
    if(partyremarks == null || partyremarks == undefined || partyremarks == ""){
      this.remarksCheck = true;
      Swal.fire((this.lan==0)?"Please Enter Rejection Remarks":"कृपया अस्वीकृति टिप्पणियाँ दर्ज करें");
      return
    }else{
      this.remarksCheck = false;
    }

  if(this.addEstamp.contentStatus>10){
    if(this.addEstamp.finalSignFileName!=null){
      Swal.fire({
        //title: 'Are you sure?',
        title: (this.lan==0)?"Are you sure reject the deed?":"क्या आप निश्चित रूप से विलेख को अस्वीकार करते हैं?",
        //text: 'This process is irreversible.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: (this.lan==0)?"Yes, go ahead.":"हाँ आगे बढ़ो।",
        cancelButtonText: (this.lan==0)?"No, let me think":"नहीं, मुझे सोचने दो",
      }).then((result) => {
        if (result.value) {
        
          
          this.ngxService.start();
          (document.getElementById("modalDeedPdfClose") as any).click();
          this.estampService.savePartyStatusRemark(this.estampPartyContentModel).subscribe(res=>{
            this.ngxService.stop();
            if (res.responseStatus == 'true') {
            this.router.navigate(['estamp-list' ], {
              state:{
                data: this.mobileNo,
                eregFlag:this.eregFlag,
                //partyId:this.partyId,
                userId:this.userId
              }
            });
          }else{
            this.ngxService.stop();
          }
            
          },
          (err)  => {
            this.ngxService.stop();
        });
  
        } 
      });


    }else{
      Swal.fire({
        icon: 'warning',
        title: (this.lan==0)?'Deed Acceptance':'विलेख स्वीकृति',
        text: (this.lan==0)?'Please wait for few minutes as your deed generating':'कृपया डीड तैयार होने तक कुछ मिनट प्रतीक्षा करें',
        confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
      });
    }
  }
 
  }

 backToList(){
    this.router.navigate(['estamp-list' ], {
      state:{
        data: this.mobileNo,
        eregFlag:this.eregFlag
      }
    });
  }


  getStatusListByModuleType(moduleType:string){
  this.estampService.getStatusListByModuleType(moduleType).subscribe(res=>{
    this.nextActionModelArray=res.responseData
    let x=[13,14]
    this.nextActionModelArray=this.nextActionModelArray.filter((s,i)=>x.some(j=>j==s.id))
   })

  }

  getRemarkHistoryByEstampId(estampId:string){
    this.estampService.getRemarkHistoryByEstampId(estampId).subscribe(res=>{
      this.commentsModelArray=res.responseData
     })
  
    }

    getStatusNameById(id:string):string{
      let name='default'
      if(this.nextActionModelArray.length>0){
      name=this.nextActionModelArray.find(s=>s.id==Number(id)).nameEn
      return name
      }
      else{
        return name
      }
    }

    getEstampDetailsByEstampId(){
      this.ngxService.start();
      this.estampService.getEstampDetailsById(Number(this.estampId)).subscribe(templateData=>{
        this.ngxService.stop();
        if (templateData.responseStatus == 'true') {
         // this.ngxService.stop();
         //this.estampDetails.estampStatus

        if(templateData.responseData.contentStatus=="11") {
          //this.statusCheck=false
         // sessionStorage.setItem("statusCheck","false")
        this.addEstamp = templateData.responseData
// for ereg first party- 1 , second party -2 ,consenter-3, wittness - 4
         if(this.eregFlag != null && this.eregFlag != undefined && this.eregFlag ===1){
         if(this.estampContentStatus===11 && (this.partySubType===1 || this.partySubType===3) ){
          this.statusCheck=false
        }
        // else if(this.estampContentStatus===11 && (this.partySubType===2 || this.partySubType===3 || this.partySubType===4) && (this.natureOfInstrument=='Bilateral')){
        //   this.statusCheck=false
        // }
        // else if(this.estampContentStatus===11 && (this.partySubType===2 || this.partySubType===3 || this.partySubType===4 || this.partySubType===5 || this.partySubType===0) && (this.natureOfInstrument=='Unilateral' || this.natureOfInstrument=='Bilateral')){//change on 23-09-2023 according to manish sir
        //   this.statusCheck=false
        // }
        else if(this.estampContentStatus===11 && (this.partySubType===2 || this.partySubType===3 || this.partySubType===4 || this.partySubType===5 || this.partySubType===0) ){//change on 16-11-2023 according to Nitin sir bcz case is Unilateral/Bilateral
          this.statusCheck=false
        }
        // below condition use for check that witness will not eligible for accept & reject
        if(this.estampContentStatus===11 && (this.partySubType===4 || this.partySubType===3) && this.eregFlag ===1){
          this.statusCheck=true
          }
      }else{
        if(this.estampContentStatus===11 && this.partySubType===1 ){
          this.statusCheck=false
        }
        // else if(this.estampContentStatus===11 && (this.partySubType===2 || this.partySubType===4) && (this.natureOfInstrument=='Bilateral')){
        //   this.statusCheck=false
        // }
        else if(this.estampContentStatus===11 && (this.partySubType===2 || this.partySubType===3 || this.partySubType===4) ){//change on 23-09-2023 according to manish sir
          this.statusCheck=false
        }
        // below condition use for check that witness/consenter will not eligible for accept & reject
        if(this.estampContentStatus===11 && (this.partySubType===4 || this.partySubType===3)){
          this.statusCheck=true
          }
      }
        // alert(JSON.stringify( this.addEstamp));
         // for ereg setting
    
        }
        }else{
          this.ngxService.stop();
          
         
        }
         
  
      },
       (err)  => {
        
          this.ngxService.stop();
         
        
      });
    }


    generateJasper(estampCommonBean:EstampCommonBean){
      this.ngxService.start();
      estampCommonBean.viewJasperDeed=false;
      estampCommonBean.igrsEregEstampBean.finalSignFileName = null;
      this.estampService.commonFinalEstampDeedGenerate(estampCommonBean).subscribe(response => {
        this.ngxService.stop();
        if (response.responseStatus == "true") {
         console.log(response?.responseData?.filename);
         
        }else{
          this.ngxService.stop();

      }},(err)  => {
        this.ngxService.stop();
    });
  }

  
        
}

import { ApplicableSubClause } from "./ApplicableSubClause";
import { ApplicableSubClauseResponse } from "./ApplicableSubClauseResponse";
import { IgrsSubClausePropertyMappingBean } from "./IgrsSubClausePropertyMappingBean";
import { IndependentBuildingTypeBean } from "./IndependentBuildingTypeBean";
import { MstDemographyBean } from "./MstDemographyBean";
import { MstPropertyTypeBean } from "./MstPropertyTypeBean";
import { PnrdModel } from "./PnrdModel";
import { PropertyOwner } from "./PropertyOwner";
import { PropertyValuationRequestBean } from "./PropertyValuationRequestBean";
import { WebGisResponseBeanData } from "./WebGisResponseData";
import { MPenagarpalikaResponsModel } from "./enagarpalikaModel";

export class PropertyValuationResponseBean {

	//seller
	isCoOwner:boolean
    isMoreSellers:boolean
    isOwnerSameFamily:boolean
	noOfSellers:number
	//Buyer
    noOfBuyers:number
	buyersSameFamily:any
	isMoreBuyers:boolean

	propertyOwner: PropertyOwner
	mstDemographyBean: MstDemographyBean = new MstDemographyBean();
	//demography:MstDemographyBean=new MstDemographyBean();
	residentialPlot: number;
	residentialPlotRate: number;
	commercialPlot: number;
	commercialPlotRate: number;
	industrialPlot: number;
	industrialPlotRate: number;
	healthPlot: number;
	healthPlotRate: number;
	eduPlot: number;
	eduPlotRate: number;
	otherPlot:number
	unIrrigatedRate:number
	otherPlotRate:number
	residentialCumCommercialPlotRate:number
	residentialCumCommercialPlot:number
	agriculturalWithConstruction?: PropertyValuationRequestBean=new PropertyValuationRequestBean();
	applicableSubClause: ApplicableSubClause;
	partialTransact:boolean
	isPartialTransact:boolean
	applicableSubClauseList: ApplicableSubClauseResponse[] = new Array();
	partialTransactArea:number
	independentBuildTypeBeanList: IndependentBuildingTypeBean[] = new Array();
	constOnAgriLand:boolean
	nhRoad: string;
	nhRoadRate: string;
	divertedValue:any
	totalSellableAreaDiverted:string
	totalSellableAreaUndiverted:string
	
    address:string
	shRoad: string;
	shRoadRate: number;
	doubleCropArea:any
	cororPlot: string;
	cororPlotRate: number;
	doubleCropRate:number
	singleCropRate:number
    unit:string
	cororPlotFoundation: number;
	cororPlotFoundationRate: number;
	irrigatedRate:any
	irrigatedArea:any
	gandiBasti: string;
	gandiBastiRate: number;
    overAllArea:number;
	singleCropArea:number;
	
	
	ews: string;
	ewsRate: number;
	
	residentialPlotArea: number;
	commercialPlotArea: number;
	industrialPlotArea: number;
	healthPlotArea: number;
	eduPlotArea: number;
	othersPlotArea: number;
	residentialCumCommercialPlotArea: number;
	isResidentialCumCommercial: boolean;
	isHousingBoard: boolean;
	moreBuyers:any
	transactArea:any
	subClause: string;
	selectSubClause:IgrsSubClausePropertyMappingBean[] = new Array();
	usage: string; // Diverted / Un diverted agricultural land
	subType: string; // Irrigated / Un Irrigated agricultural	
	floorType: string; //building
	constrnType: string; // building
	constrnTime: string; // building 
	buildingSubType: string;//building 
	liftFacility: boolean;// building
	transactOpenTerrace: boolean;//building
	multistoryCommercialPopType: boolean;
	olderId: number;
	olderRebate: number;
	floorRebate: number;
	buildingType: string;//building multistory
	buitupArea: number;//building multistory 
	commonArea: number;//building multistory 
	conveyanceType: string;
	conveyanceArea: number;
	totalArea: number;
	totalPlotValue: number;
	totalConstCost: number;
	calculatedValue: number; //Market Value
	builderMarketValue: number;//Builder market value
	isPlotOnAgriLand:boolean=false
	propertyType: MstPropertyTypeBean = new MstPropertyTypeBean()
	
	valTxnId:number;
	propertyId: string[];
	webGisResponseBeanDataArr:WebGisResponseBeanData[]=[]
	mpenagarpalikaResponse:MPenagarpalikaResponsModel
	pNRDResponseData:PnrdModel
	pnrdresponseData:PnrdModel
}
import { Injectable } from '@angular/core';
import { environment } from 'projects/citizen-web/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(){
    if (!environment.production) {//!environment.production
    console.log("Logger service on Development Env")
    }
  }
  log(message?: any,obj?:any,obj2?:any): void {
    if (!environment.production) {
      if(obj==null&&obj==undefined){
        obj=""
      }
      if(obj2==null&&obj2==undefined){
        obj2=""
      }
      console.log(message,obj,obj2);
    }
  }
}
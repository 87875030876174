import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DutyOtherInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-input-fields';
import { MineralMstBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/mineral-mst-bean';
import { IgrsEregPropIdentificationBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { PropertyValuationResponseBean } from 'projects/common-lib/src/lib/models/PropertyValuationResponseBean';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService  implements OnDestroy {
// 1
deedInstrumentSelected: DeedInstrumentBean = new DeedInstrumentBean();
sendDutyObjAfterImpound:Subject<any>;
sendDutyObjAfterCOSImpound:Subject<any>;
pvEregInit:Subject<boolean>;
pvEregInitFromChecker:Subject<boolean>;
pvEregInitFromCheckerCMSModule:Subject<boolean>;
eregImpoundDetailsLoaded:Subject<boolean>;
fromDutyModule:Subject<boolean>;
fromCheckerModule:Subject<boolean>;
fromCMSModule:Subject<boolean>;
pvPopUpClose:Subject<boolean>;
instSearchOrDeedChange:Subject<boolean>;
fromDutyModuleBuilderShare:Subject<any>;
propertyValuationResponseBean: Subject<PropertyValuationResponseBean>;
propertyValuationRequestBean: Subject<PropertyValuationRequestBean>;
propertyValuationRequestBeanImpound: Subject<PropertyValuationRequestBean>;
propertyValuationRequestBeanCOSImpound: Subject<PropertyValuationRequestBean>;
propIdentificationObejct: Subject<IgrsEregPropIdentificationBean>;
selectedMineralListEmit : EventEmitter<MineralMstBean[]>;
selectedConsenterListEmit : EventEmitter<any>;
receivedFilter: EventEmitter<DeedInstrumentBean>;
clearDutyFields : EventEmitter<any>;
dutyOtherInputFieldsEmit : EventEmitter<DutyOtherInputFields[]>;

constructor() {
  console.log('CommunicationServiceService')
    this.receivedFilter = new EventEmitter<DeedInstrumentBean>();
    this.clearDutyFields = new EventEmitter<any>();
    this.pvEregInit =  new Subject<boolean>();
    this.pvEregInitFromChecker =  new Subject<boolean>();
    this.pvEregInitFromCheckerCMSModule =  new Subject<boolean>();
    this.eregImpoundDetailsLoaded=  new Subject<boolean>();
    this.fromDutyModule =  new Subject<boolean>();
    this.fromCheckerModule =  new Subject<boolean>();
    this.fromCMSModule =  new Subject<boolean>();
    this.pvPopUpClose =  new Subject<boolean>();
    this.instSearchOrDeedChange= new Subject<boolean>();
    this.fromDutyModuleBuilderShare =  new Subject<any>();
    this.propertyValuationResponseBean= new Subject<PropertyValuationResponseBean>();
    this.propertyValuationRequestBean= new Subject<PropertyValuationRequestBean>();
    this.propertyValuationRequestBeanImpound= new Subject<PropertyValuationRequestBean>();
    this.propertyValuationRequestBeanCOSImpound= new Subject<PropertyValuationRequestBean>();
    this.propIdentificationObejct= new Subject<IgrsEregPropIdentificationBean>();
    this.sendDutyObjAfterImpound= new Subject<any>();
    this.sendDutyObjAfterCOSImpound= new Subject<any>();
    this.selectedMineralListEmit = new EventEmitter<MineralMstBean[]>();
    this.dutyOtherInputFieldsEmit = new EventEmitter<DutyOtherInputFields[]>();
    this.selectedConsenterListEmit =new EventEmitter<any>();
}
// 2
raiseEvent(id: DeedInstrumentBean): void {
    this.deedInstrumentSelected = id;
    this.receivedFilter.emit(this.deedInstrumentSelected);
  }

 raiseClearEvent():void {
  this.clearDutyFields.emit();
 }

 emitSelectedMineral(selectedMineralList:MineralMstBean[] ): void{
  this.selectedMineralListEmit.emit(selectedMineralList);
 }

 getSelectedMineral(){
  return this.selectedMineralListEmit.asObservable()
 }

 emitSelectedConsenterDetails(selectedConsenterList:Array<any>): void{
  this.selectedConsenterListEmit.emit(selectedConsenterList);
 }

 emitDutyOtherInputFields(dutyOtherInputFields: DutyOtherInputFields[]): void{
  this.dutyOtherInputFieldsEmit.emit(dutyOtherInputFields);
 }
 ngOnDestroy(): void {
  console.log("CommunicationServiceService  OnDestroy")
    //this.propertyValuationResponseBean.unsubscribe();
   // this.propertyValuationRequestBean.unsubscribe();
    //this.propIdentificationObejct.unsubscribe();
    //this.propertyValuationRequestBeanImpound.unsubscribe();
    //this.propertyValuationRequestBeanCOSImpound.unsubscribe();
  }
}

import { MstDemographyTypeBean } from "./api.masterdata.model/MstDemographyTypeBean";
import { LocationBean } from "./LocationBean";

export class MstDemographyBean {
	id: number;
	demographyType: MstDemographyTypeBean;
	parentDemographyId: number;
	demographyName: string;
	demographyNameHi: string;
	demographyNameEn: string;
	latitude: string;
	longitude: string;
	colonyId: string;
	location: LocationBean;
	demographyTypeId: number
	lgdCode: string
	tagsEn: string
	tagsHi: string
	ulbType: number
	ulbTypeNmHi: string
	ulbTypeNmEn: string
	isPlanningArea: number
	isPlanningAreaNmHi: string
	isPlanningAreaNmEn: string
	priority: number
	patwariHalkaEn:string
	patwariHalkaHi:string
	isVishistGram:number
	scheduleArea: number
	upBandhDesc:string
	areaType:string
	parentDemoGraphyId:number
}
L.Control.PanelLayers = L.Control.Layers.extend({

	includes: L.version[0]==='1' ? L.Evented.prototype : L.Mixin.Events,

	options: {
        closeButton:true,
		compact: false,
		compactOffset: 0,
		collapsed: false,
		autoZIndex: true,
		collapsibleGroups: false,
		buildItem: null,				//function that return row item html node(or html string)
		title: '',						//title of panel
		className: '',					//additional class name for panel
		position: 'topright'
	},

	initialize: function (baseLayers, overlays, options) {
		L.setOptions(this, options);
		this._layers = [];
		this._layeroption = {};
		this._groups = {};
		this._items = {};
		this._layersActives = [];
		this._lastZIndex = 0;
		this._handlingClick = false;
	
		this.loddiv = document.createElement('div');
		this.loddiv.classList.add("lds-ring");
		this.loddiv.id="lds-ring-for-laye1";
		this.loddiv.innerHTML='<div></div><div></div><div></div><div></div>';
		this.loddiv.style.display="none";

		this.loaddinCount = 0;
		this.loadingtimeout;

		document.getElementsByClassName('leaflet-bottom leaflet-right')[0].appendChild(this.loddiv);

		this.className = 'leaflet-panel-layers';

		var i, n, isCollapsed;

		for (i in baseLayers) {
			if (baseLayers[i].group && baseLayers[i].layers) {
				isCollapsed = baseLayers[i].collapsed || false;
				for (n in baseLayers[i].layers)
					this._addLayer(baseLayers[i].layers[n], false, baseLayers[i].group, isCollapsed);
			}
			else
				this._addLayer(baseLayers[i], false);
		}

		for (i in overlays) {
			if (overlays[i].group && overlays[i].layers) {
				isCollapsed = overlays[i].collapsed || false;
				for (n in overlays[i].layers){
					this._addLayer(overlays[i].layers[n], true, overlays[i].group, isCollapsed);
				}
			}
			else{
				this._addLayer(overlays[i], true);
			}
		}
	},

	onAdd: function (map) {

		var self = this;

		for (var i in this._layersActives) {
			map.addLayer(this._layersActives[i]);
		}

		L.Control.Layers.prototype.onAdd.call(this, map);

		this._map.on('resize', function(e) {
			self._updateHeight(e.newSize.y);
		});

		this._map.on('zoomend ', function(e) {
            //console.log(self._map.getZoom())
			//console.log(self._layeroption);
            this.layerList_checkbox=document.getElementsByClassName("leaflet-panel-layers-selector");
            var checkboxCollection = [].slice.call(this.layerList_checkbox);
            checkboxCollection.forEach(element => {
				if(self._layeroption[element.id])
                	self._setLableStyle(self._layeroption[element.id].options, element.parentElement.getElementsByTagName('span')[0])
            });
       });

		return this._container;
	},

	//TODO addBaseLayerGroup
	//TODO addOverlayGroup

	addBaseLayer: function (layer, name, group) {
		layer.name = name || layer.name || '';
		this._addLayer(layer, false, group);
		this._update();
		return this;
	},

	addOverlay: function (layer, name, group) {
		layer.name = name || layer.name || '';
		this._addLayer(layer, true, group);
		this._update();
		return this;
	},

	removeLayer: function (layerDef) {
		var layer = layerDef.hasOwnProperty('layer') ? this._layerFromDef(layerDef) : layerDef;

		this._map.removeLayer(layer);

		L.Control.Layers.prototype.removeLayer.call(this, layer);
		return this;
	},

	clearLayers: function () {
		for (var i = 0; i < this._layers.length; i++) {
			this.removeLayer(this._layers[i]);
		}
	},

	_layerFromDef: function (layerDef) {
		for (var i = 0; i < this._layers.length; i++) {
			var id = L.stamp(this._layers[i].layer);
			//TODO add more conditions to comparing definitions
			if (this._getLayer(id).name === layerDef.name)
				return this._getLayer(id).layer;
		}
	},

	_update: function () {
		this._groups = {};
		this._items = {};
		L.Control.Layers.prototype._update.call(this);
	},

	_getLayer: function (id) {
		for (var i = 0; i < this._layers.length; i++) {
			if (this._layers[i] && this._layers[i].id == id) {
				return this._layers[i];
			}
		}
	},

	_addLayer: function (layerDef, overlay, group, isCollapsed) {

		if(!layerDef.layer)
			throw new Error('layer not defined in item: '+(layerDef.name||''));

		if (!(layerDef.layer instanceof L.Class) &&
			(layerDef.layer.type && layerDef.layer.args)) {
			layerDef.layer = this._getPath(L, layerDef.layer.type).apply(L, layerDef.layer.args);
		}

		if(!layerDef.hasOwnProperty('id'))
			layerDef.id = L.stamp(layerDef.layer);

		if(layerDef.active)
			this._layersActives.push(layerDef.layer);

		this._layers.push(L.Util.extend(layerDef, {
			collapsed: isCollapsed,
			overlay: overlay,
			group: group
		}));

		if (this.options.autoZIndex && layerDef.layer && layerDef.layer.setZIndex) {
			this._lastZIndex++;
			layerDef.layer.setZIndex(this._lastZIndex);
		}

	},

	_createItem: function (obj) {

		var self = this;

		var item, input, checked;

		item = L.DomUtil.create('div', this.className + '-item' + (obj.active ? ' active' : ''));

		checked = this._map.hasLayer(obj.layer);

		if (obj.overlay) {
			input = L.DomUtil.create('input', this.className + '-selector');
			input.type = 'checkbox';
			input.defaultChecked = checked;
			//console.log(obj);
			this._layeroption[obj.id]=obj.layer;
			//TODO name
			//console.log(e);
			if(obj.layerDetail.customStyle){
				
				
			}

			//-----------------------------------------------------------------------------------------------------------
		} else
			input = this._createRadioElement('leaflet-base-layers', checked, obj);

		input.value = obj.id;
		input.layerId = obj.id;
		input.id = obj.id;
		input._layer = obj;

		L.DomEvent.on(input, 'click', function (e) {

			self._onInputClick();

			if (e.target.checked) {
				self.fire('panel:selected', e.target._layer);
			} else {
				self.fire('panel:unselected', e.target._layer);
			}

		}, this);


		var label = L.DomUtil.create('label', this.className + '-title');
		item.appendChild(label);
		//TODO label.htmlFor = input.id;
		var title = L.DomUtil.create('span');
		title.innerHTML = obj.name || '';
		this._setLableStyle(obj.layer.options, title);

		if (obj.icon && !obj.layerDetail.iconPosition) {
			var icon = L.DomUtil.create('i', this.className + '-icon');

			if (typeof obj.icon === 'string')
				icon.innerHTML = obj.icon || '';
			else
				icon.appendChild(obj.icon);

			label.appendChild(icon);
		}else if(obj.icon && obj?.layerDetail?.iconPosition && obj.layerDetail.iconPosition == "bottom"){
			var bottomIcon = L.DomUtil.create('div', this.className + '-bottom-icon');
			if (typeof obj.icon === 'string')
				bottomIcon.innerHTML = obj.icon || '';
			else
				bottomIcon.appendChild(obj.icon);
			item.appendChild(bottomIcon);
		}

		label.appendChild(input);

		if (obj.overlay && obj.layerDetail.customStyle){
			var layerToolbtn = this._createToolsBtn('leaflet-base-layers', checked, obj);
				layerToolbtn.children[0]['data-target']="tools_"+obj.id;
				layerToolbtn.children[0].value="false";
			label.appendChild(layerToolbtn);

			layerToolbtn.children[0].addEventListener('click', (e)=>{
				if(e.target.value=="false"){
					this.showTools(e.target['data-target']);
					e.target.value="true";
				}else{
					this.hideTools(e.target['data-target']);
					e.target.value="false";
				}
			});
		}
		label.appendChild(title);
		if (obj.overlay && obj.layerDetail.customStyle){
			var layerTools= this._createTools('leaflet-base-layers', checked, obj);
			
			layerTools.addEventListener('click', (e)=>{
				if(e.target.tagName !=='INPUT')
					e.preventDefault();
			});
				

			label.appendChild(layerTools);

			//console.log(layerTools)
			if(obj.layerDetail.customStyle.transparent){
				var rangeinput = layerTools.querySelector("#range_"+obj.id);
				rangeinput.value = obj.id;
				rangeinput.layerId = obj.id;
				rangeinput._layer = obj;

				rangeinput.oninput= (e)=> {
					e.target._layer.layer.setOpacity(e.target.value);
				}
			}

			//Chnage Color Button
			if(obj.layerDetail.customStyle.colorpicker){
				var colorInput = layerTools.querySelector("#layer_inputColor_"+obj.id);
				colorInput.layerId = obj.id;
				//colorInput._layer = obj;				

				var colorInputReset = layerTools.querySelector("#layer_resetStyle_"+obj.id);
				colorInputReset.layerId = obj.id;
				colorInputReset._layer = obj;

				var fillcolorInput = layerTools.querySelector("#layer_inputColor_toFill_"+obj.id);
				fillcolorInput.layerId = obj.id;
				//fillcolorInput._layer = obj;

				var layer_submitStyle =  layerTools.querySelector("#layer_submitStyle_"+obj.id);
				layer_submitStyle.layerId = obj.id;
				layer_submitStyle._layer = obj;

				colorInput.addEventListener('input', (e)=>{
					document.getElementById("color-picker-wrapper_"+obj.id).style.backgroundColor=e.target.value;
					//this._setLayerStyle(e);
				});

				fillcolorInput.addEventListener('input', (e)=>{
					document.getElementById("color-picker-wrapper_toFill_"+obj.id).style.backgroundColor=e.target.value;
					//this._setLayerStyle(e);
				});

				layer_submitStyle.addEventListener('click', (e)=>{
					var obj={};
					obj.layer = e.target._layer.layer;
					obj.borderColor = document.getElementById("layer_inputColor_"+e.target._layer.id).value;
					obj.borderWidth = document.getElementById("geomLineWidth_"+e.target._layer.id).value;
					obj.fillColor = document.getElementById("layer_inputColor_toFill_"+e.target._layer.id).value;
					obj.fillOpacity = document.getElementById("geomFillOpacity_"+e.target._layer.id).value;
					obj.styleType=e.target._layer.layerDetail.customStyle.styleType;
					this._setLayerStyle(obj);
					//console.log(obj);
				});

				colorInputReset.addEventListener('click', (e)=>{
					delete e.target._layer.layer.wmsParams.SLD_BODY;
					e.target._layer.layer.setParams({});
					document.getElementById("layer_inputColor_"+e.target._layer.id).value="#ffffff";
					document.getElementById("geomLineWidth_"+e.target._layer.id).value="0";
					document.getElementById("layer_inputColor_toFill_"+e.target._layer.id).value="#ffffff";
					document.getElementById("geomFillOpacity_"+e.target._layer.id).value="0";
					document.getElementById("color-picker-wrapper_"+obj.id).style.backgroundColor="#ffffff";
					document.getElementById("color-picker-wrapper_toFill_"+obj.id).style.backgroundColor="#ffffff";
				});
			}
		}

		if (this.options.buildItem) {
			var node = this.options.buildItem.call(this, obj); //custom node node or html string
			if (typeof node === 'string') {
				var tmp = L.DomUtil.create('div');
				tmp.innerHTML = node;
				item.appendChild(tmp.firstChild);
			}
			else
				item.appendChild(node);
		}

		this._items[input.value] = item;

		
		obj.layer.on('loading',  (event)=> {
			this.loaddinCount++;
			this.loddiv.style.display="block";			
		});
		
		obj.layer.on('load',  (event)=> {
			if(this.loaddinCount>0)
				this.loaddinCount--;
			if(this.loaddinCount <= 0){
				this.loddiv.style.display="none";
			}
			if(this.loadingtimeout !== undefined && this.loadingtimeout !== 0 && this.loaddinCount>0){
				clearTimeout(this.loadingtimeout);
			}
			if(this.loaddinCount>0)
				this.loadingtimeout = setTimeout(()=>{this.loddiv.style.display="none";this.loaddinCount=0;},5000);

			//console.log(this.loadingtimeout);
		});

		return item;
	},

	// IE7 bugs out if you create a radio dynamically, so you have to do it this hacky way (see http://bit.ly/PqYLBe)
	_createRadioElement: function (name, checked, obj) {

		var radioHtml = '<input type="radio" class="' + this.className + '-selector" name="' + name + '" id="' + obj.id + '"';
		if (checked) {
			radioHtml += ' checked="checked"';
		}
		radioHtml += ' />';

		var radioFragment = document.createElement('div');
		radioFragment.innerHTML = radioHtml;

		return radioFragment.firstChild;
	},

	_createToolsBtn: function (name, checked, obj) {
		var rangeHtml = `<div class="layerTool-btn-group">
		<button class="btn btn-sm layerTool-btn fa fa-ellipsis-v" type="button" id="toolsBtn_`+obj.id+`"/>
		
	  	</div>`
		// if (checked) {
		// 	radioHtml += ' checked="checked"';
		// }
		//rangeHtml += ' /><div class="colorBox"><input type="color"  class="form-control form-control-color" name="favcolor" value="#ffffff"></div>';

		var rangeFragment = document.createElement('div');
		rangeFragment.innerHTML = rangeHtml;

		return rangeFragment.firstChild;
	},

	_createTools: function (name, checked, obj) {
		var toolsHtml = `<div class="dropdown-menu layerTools-menu" id="tools_`+obj.id+`">`;

		if(obj.layerDetail.customStyle.transparent){
			toolsHtml=toolsHtml+`<div class="Layertools">
				<span>Set Opacity</span>
				<input type="range" min="0" max="1" step="0.1" value="1" class="range_` + this.className + `-selector" name="range_` + name + `" id="range_` + obj.id + `"/>
			</div>`;
		}
		if(obj.layerDetail.customStyle.colorpicker){
			toolsHtml=toolsHtml+`<div class="Layertools">
				<span>Set Color</span>
				<div class="Layertools-inline">
					<div id="color-picker-wrapper_`+obj.id+`" class="color-picker-wrapper" style="background-color:#ffffff;" title="Set Border Color">
						<input type="color"  class="form-control form-control-color"  value="#ffffff" id="layer_inputColor_`+obj.id+`">
					</div>
					
					<select id="geomLineWidth_`+obj.id+`" class="color-picker-values color-picker-margin-r-5" value=1>
						<option value=0>0</option>
						<option value=1>1</option>
						<option value=2>2</option>
						<option value=3>3</option>
					</select>
					
					
					<div id="color-picker-wrapper_toFill_`+obj.id+`" class="color-picker-wrapper" style="background-color:#ffffff;" title="Set Fill Color">
						<input type="color"  class="form-control form-control-color"  value="#ffffff" id="layer_inputColor_toFill_`+obj.id+`">
					</div>

					
					<select id="geomFillOpacity_`+obj.id+`" class="color-picker-values color-picker-margin-r-5" value='00'>
						<option value='0'>0</option>
						<option value='0.1'>1</option>
						<option value='0.2'>2</option>
						<option value='0.3'>3</option>
						<option value='0.4'>4</option>
						<option value='0.5'>5</option>
						<option value='0.6'>6</option>
						<option value='0.7'>7</option>
						<option value='0.8'>8</option>
						<option value='0.9'>9</option>
						<option value=''>10</option>
					</select>
					<div class="btn-group" role="group" aria-label="Basic example">
						<button class="btn btn-secondary btn-sm btn-layer-reset fa fa-arrow-right" type="button" id="layer_submitStyle_`+obj.id+`" title="Set Layer Style"></button>
						<button class="btn btn-primary btn-sm btn-layer-reset fa fa-refresh" type="button" title="Reset Layer Style" id="layer_resetStyle_`+obj.id+`"></button>
					</div>
				</div>
			</div>`;
		}
		toolsHtml+toolsHtml+`</div>`;
			
	  	
		// if (checked) {
		// 	radioHtml += ' checked="checked"';
		// }
		//rangeHtml += ' /><div class="colorBox"><input type="color"  class="form-control form-control-color" name="favcolor" value="#ffffff"></div>';

		var toolsFragment = document.createElement('div');
		toolsFragment.innerHTML = toolsHtml;

		return toolsFragment.firstChild;
	},

	_addItem: function (obj) {
		var self = this,
			label, input, icon, checked;

		var list = obj.overlay ? this._overlaysList : this._baseLayersList;

		if (obj.group) {
			if (!obj.group.hasOwnProperty('name'))
				obj.group = {name: obj.group};

			if (!this._groups[obj.group.name]) {
				var collapsed = false;
				if (obj.collapsed === true)
					collapsed = true;
				this._groups[obj.group.name] = this._createGroup(obj.group, collapsed);
			}

			list.appendChild(this._groups[obj.group.name]);
			list = this._groups[obj.group.name];
		}

		label = this._createItem(obj);

		list.appendChild(label);

		return label;
	},

	_createGroup: function (groupdata, isCollapsed) {

		var self = this,
			groupdiv = L.DomUtil.create('div', this.className + '-group'),
			grouplabel, grouptit, groupexp;

		grouplabel = L.DomUtil.create('label', this.className + '-grouplabel', groupdiv);

		if (this.options.collapsibleGroups) {

			L.DomUtil.addClass(groupdiv, 'collapsible');

			groupexp = L.DomUtil.create('i', this.className + '-icon', grouplabel);
			if (isCollapsed === true)
				groupexp.innerHTML = ' + ';
			else
				groupexp.innerHTML = ' - ';

			L.DomEvent.on(grouplabel, 'click', function () {
				if (L.DomUtil.hasClass(groupdiv, 'expanded')) {
					L.DomUtil.removeClass(groupdiv, 'expanded');
					groupexp.innerHTML = ' + ';
				} else {
					L.DomUtil.addClass(groupdiv, 'expanded');
					groupexp.innerHTML = ' - ';
				}
				self._updateHeight();
			});

			if (isCollapsed === false)
				L.DomUtil.addClass(groupdiv, 'expanded');
		}

		grouptit = L.DomUtil.create('span', this.className + '-title', grouplabel);
		grouptit.innerHTML = groupdata.name;

		return groupdiv;
	},

	_onInputClick: function () {
		var i, input, obj,
			inputs = this._form.getElementsByClassName(this.className + '-selector'),
			inputsLen = inputs.length;

		this._handlingClick = true;

		for (i = 0; i < inputsLen; i++) {

			input = inputs[i];

			obj = this._getLayer(input.value);

			if (input.checked && !this._map.hasLayer(obj.layer)) {
				L.DomUtil.addClass(input.parentNode.parentNode, 'active');
				this._map.addLayer(obj.layer);

			} else if (!input.checked && this._map.hasLayer(obj.layer)) {
				L.DomUtil.removeClass(input.parentNode.parentNode, 'active');
				this._map.removeLayer(obj.layer);
			}
		}

		this._handlingClick = false;

		this._refocusOnMap();
	},


	_initLayout: function () {
		var container = this._container = L.DomUtil.create('div', this.className);

		if(this.options.compact)
			L.DomUtil.addClass(container, 'compact');

		//Makes this work on IE10 Touch devices by stopping it from firing a mouseout event when the touch is released
		container.setAttribute('aria-haspopup', true);

        // Create close button and attach it if configured
        if (this.options.closeButton) {
            var close = this._closeButton =
                L.DomUtil.create('a', 'close', container);
            close.innerHTML = '&times;';

            L.DomEvent.on(close, 'click', this._collapse, this);
        }

		L.DomEvent
			.disableClickPropagation(container)
			.disableScrollPropagation(container);

		if (this.options.className)
			L.DomUtil.addClass(container, this.options.className);

		this._section = this._form = L.DomUtil.create('form', this.className + '-list');

		this._updateHeight();

		if (this.options.collapsed) {

			if (L.Browser.android)
				L.DomEvent
					.on(container, 'click', this._expand, this);
			else {
				// L.DomEvent
				// 	.on(container, 'mouseenter', this._expand, this)
				// 	.on(container, 'mouseleave', this._collapse, this);
			}

			//this._map.on('click', this._collapse, this);

		} else {
			this._expand();
		}

		this._baseLayersList = L.DomUtil.create('div', this.className + '-base', this._form);
		this._separator = L.DomUtil.create('div', this.className + '-separator', this._form);
		this._overlaysList = L.DomUtil.create('div', this.className + '-overlays', this._form);

		/* maybe useless
		if (!this.options.compact)
			L.DomUtil.create('div', this.className + '-margin', this._form);*/

		if (this.options.title) {
			var titlabel = L.DomUtil.create('label', this.className + '-title');
			titlabel.innerHTML = '<span>' + this.options.title + '</span>';
			container.appendChild(titlabel);
		}

		container.appendChild(this._form);
	},

	_updateHeight: function (h) {
		h = h || this._map.getSize().y;

		if (this.options.compact)
			this._form.style.maxHeight = (h - this.options.compactOffset) + 'px';
		else
			this._form.style.height = 'auto'//h + 'px';
	},

	_expand: function () {
		L.DomUtil.addClass(this._container, 'expanded');
        this._closeButton.style.display='block';
		document.querySelectorAll('.layerTool-btn-group').forEach(function(el) {
			el.style.display = 'block';
		});
	},

	_collapse: function () {
		this._container.className = this._container.className.replace('expanded', '');
        this._closeButton.style.display='none';
		document.querySelectorAll('.layerTool-btn-group').forEach(function(el) {
			el.style.display = 'none';
		});
	},

	showTools:function(domid){
		document.getElementById(domid).style.display="block";
	},
	hideTools:function(domid){
		document.getElementById(domid).style.display="none";
	},

	_getPath: function (obj, prop) {
		var parts = prop.split('.'),
			last = parts.pop(),
			len = parts.length,
			cur = parts[0],
			i = 1;

		if (len > 0)
			while ((obj = obj[cur]) && i < len)
				cur = parts[i++];

		if (obj)
			return obj[last];
	},


	_setLableStyle:function(options, title){
        var min = (options.minZoom)?options.minZoom:0
        var max = (options.maxZoom)?options.maxZoom:28
        //console.log(options);
        if(min <= this._map.getZoom() && this._map.getZoom() <= max){
            title.style.color="black"
        }else{
            title.style.color="gray"
        }
        //title.style.color="black";
    },

	_setLayerStyle:function(obj){
		var SLD_BODY = `<StyledLayerDescriptor version="1.0.0" 
				xsi:schemaLocation="http://www.opengis.net/sld StyledLayerDescriptor.xsd" 
				xmlns="http://www.opengis.net/sld" 
				xmlns:ogc="http://www.opengis.net/ogc" 
				xmlns:xlink="http://www.w3.org/1999/xlink" 
				xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
				<NamedLayer>
					<Name>`+obj.layer.options.layers+`</Name>
					<UserStyle>
						<Title>polygon and line style</Title>
						<FeatureTypeStyle>
						
						<Rule>
						<Name>rule1</Name>
						<Title>Gray Polygon with Black Outline</Title>
						<Abstract>A polygon with a gray fill and a 1 pixel black outline</Abstract>`
						if(obj.styleType=="polygon"){
							SLD_BODY=SLD_BODY+`<PolygonSymbolizer>
							<Fill>
						    	<CssParameter name="fill">`+obj.fillColor+`</CssParameter>
								<CssParameter name="fill-opacity">`+obj.fillOpacity+`</CssParameter>
						    </Fill>
							<Stroke>
								<CssParameter name="stroke">`+obj.borderColor+`</CssParameter>
								<CssParameter name="stroke-width">`+obj.borderWidth+`</CssParameter>
								<CssParameter name="stroke-linejoin">bevel</CssParameter>
							</Stroke>
						</PolygonSymbolizer>`
						}else if(obj.styleType=="line"){
							SLD_BODY=SLD_BODY+`<LineSymbolizer>
							<Stroke>
							  <CssParameter name="stroke">`+obj.borderColor+`</CssParameter>
							  <CssParameter name="stroke-width">`+obj.borderWidth+`</CssParameter>
							</Stroke>
						  </LineSymbolizer>`;
						}
						
					SLD_BODY=SLD_BODY+`</Rule>
					</FeatureTypeStyle>
					</UserStyle>
					</NamedLayer>
				</StyledLayerDescriptor>`;

				obj.layer.setParams({"SLD_BODY":SLD_BODY})
	}
});

L.control.panelLayers = function (baseLayers, overlays, options) {
	return new L.Control.PanelLayers(baseLayers, overlays, options);
};
import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { MstPropertyTypeBean } from "projects/common-lib/src/lib/models/MstPropertyTypeBean";
import { UOMEnum } from "projects/common-lib/src/lib/models/UOMEnum";
import { tap } from "rxjs/operators";
//import { PropertyValuationService } from "src/app/front-end/modules/property-valuation/property-valuation.service";
import { PropertyMasterPropetyTypeAction, PropertyMasterUomAction } from "../action/property-valuation.action";
import { PropertyValuationService } from "projects/front-web/src/app/modules/property-valuation/property-valuation.service";

 
 

export class PropertyValuationStateModel{
  propertyType:MstPropertyTypeBean[]
  uomList:UOMEnum[]
  propertyTypeload:boolean
  uomLoaded:boolean
}
export class PropertyValuationStateUomModel{
    
    uomList:UOMEnum[]
  }

@State<PropertyValuationStateModel>({
    name:'PropertyState',
    defaults:{
        propertyType:[],
        uomList:[],
        propertyTypeload:false,
        uomLoaded:false
    }
 })
@Injectable({
  providedIn: 'any'
})
 export class PropertyValuationState{

   constructor(private pvService: PropertyValuationService){}

    @Selector()
    static getPropertyTypeList(state:PropertyValuationStateModel){
        return state.propertyType
    }
    @Selector()
    static getUomList(state:PropertyValuationStateModel){
        return state.uomList
    }
    @Selector()
    static getPropertTypeLoaded(state:PropertyValuationStateModel){
        return state.propertyTypeload
    }
    @Selector()
    static getUomLoaded(state:PropertyValuationStateModel){
        return state.uomLoaded
    }

    @Action(PropertyMasterPropetyTypeAction)
    getPropertyType({getState,setState}:StateContext<PropertyValuationStateModel>){
        console.log("state action")
       return  this.pvService.getAllPropertyType().pipe(tap(res=>{
        const state=getState()
            setState({
             ...state,
                propertyType:res.responseData,
                propertyTypeload:true
           })
        }))
    // return this.pvService.getAllPropertyType().subscribe(res=>{
    //     console.log("state action",res)

    //     const state=getState()
    //     setState({
    //         ...state,
    //         propertyType:res
    //     })
    // })
    }


    
    @Action(PropertyMasterUomAction)
    getUomList({getState,setState}:StateContext<PropertyValuationStateModel>){
       
       return  this.pvService.getAllUOM().pipe(tap(res=>{
        console.log("state action uom",res)
        const state=getState()
            setState({
             ...state,
                uomList:res.responseData,
                uomLoaded:true
           })
        }))
    
    }
 }
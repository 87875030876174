<!-- <div class="allcontent {{name}}">{{name}} 3143 </div> -->

<!-- <div class="allcontent  nhkvgnkl {{themeColor}}"></div> -->

<div class="main">
<div class="main-content">
<front-header (parentFunction)="parentFunction($event)" *ngIf="!hideHeaderAndFooter"></front-header>
<front-header-new *ngIf="hideHeaderAndFooter"></front-header-new>
<router-outlet></router-outlet>
</div>
<front-footer></front-footer>
</div>
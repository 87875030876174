import { DutyInputFields } from "./duty-input-fields";
import { DutyOtherSlab } from "./duty-other-slab";

export class DutyOtherInputFields {

    popUpId:number;
    parentId: number;
    calculatedPremium: number;
    calculatedRoyalty: number;
    calculatedRent: number;
    dutyOtherSlab: DutyOtherSlab[] = new Array();
    identificationId:number;
   // dutyOtherInputFieldList: DutyInputFields[]= new Array();
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-feedback-question',
  templateUrl: './feedback-question.component.html',
  styleUrls: ['./feedback-question.component.scss']
})
export class FeedbackQuestionComponent implements OnInit {
  lan: number;
  @Input() question: { id: number, quesEn: string, quesHi: string };
  @Input() stars: number[];
  @Input() selectedValue: number;
  @Output() valueChange = new EventEmitter<{ questionNo: number; selectedValue: number }>();
  // stars: number[] = [1, 2, 3, 4, 5];
  // selectedValue: any = 'Your rating';
  // count = 6;

  constructor(
    private translate: TranslateService,
    private translateService: TranslateHEService,
    private ngxService: NgxUiLoaderService
  ) {
    try {
      console.log(
        "selected Language->" + sessionStorage.getItem("selectedLanguage")
      );
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (
        sessionStorage.getItem("selectedLanguage") == null ||
        sessionStorage.getItem("selectedLanguage") == "" ||
        sessionStorage.getItem("selectedLanguage") == undefined
      ) {
        this.lan = 1;
        selectedLanguage = "hi";
      }
      this.translateService.currentApprovalStageMessage.subscribe((msg) => {
        this.lan = msg;
        this.translate.use(msg == 1 ? "hi" : "en");
      });
    } catch (err) {
      console.log(err);
    }
  }
  ngOnInit(): void {
  }
  countStar(star: number) {
    this.selectedValue = star;
    this.valueChange.emit({ questionNo: this.question.id, selectedValue: this.selectedValue });
  }

}

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateHEService } from "projects/common-lib/src/lib/helper-lib/services/common/translateHE.service";
import { EregistryService } from "projects/front-web/src/app/service/eregistry.service";
import { IgrsEregTxnDetlsBean } from "../../models/e_registry/e-registry.model";
import { CommonService } from "../../helper-lib/services/common.service";
import { addOfficeDetail } from "../../models/addOffice.model/addOffice.model";
import { ActivatedRoute } from "@angular/router";
import { AESEncryptDecryptService } from "../../helper-lib/services/aesencrypt-decrypt.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-citizen-feedback",
  templateUrl: "./citizen-feedback.component.html",
  styleUrls: ["./citizen-feedback.component.scss"],
})
export class CitizenFeedbackComponent implements OnInit {
  lan: number;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: any = 'Your rating';
  count = 5;
  @ViewChild('namebutton') namebutton: ElementRef;
  selectedOption = '';
  srOfficeList: addOfficeDetail[];
  igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
  srOfficeNameEn: string;
  srOfficeNameHi: string;
  eregId: any;
  partyId: any;
  additionalAmountFlag: number = 0;
  additionalAmount: number;
  remarks: string = "";
  selectedValues: { questionNo: number; selectedValue: number }[] = [];
  eregFeedbackQuesList: any[] = [];
  ratingEregFeedbackQuesList: any[] = [];
  noRatingEregFeedbackQuesList: any[] = [];
  igrsEregFeedbackModelList: IgrsEregFeedbackModel[] = [];
  additionalAmountDiv: boolean = false;
  isSubmit: boolean=false;
  constructor(
    private translate: TranslateService,
    private translateService: TranslateHEService, private common: CommonService,
    private ngxService: NgxUiLoaderService, private eRegService: EregistryService, private activatedRoute: ActivatedRoute
    , private _AESEncryptDecryptService: AESEncryptDecryptService
  ) {
    try {
      console.log(
        "selected Language->" + sessionStorage.getItem("selectedLanguage")
      );
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (
        sessionStorage.getItem("selectedLanguage") == null ||
        sessionStorage.getItem("selectedLanguage") == "" ||
        sessionStorage.getItem("selectedLanguage") == undefined
      ) {
        this.lan = 1;
        selectedLanguage = "hi";
      }
      this.translateService.currentApprovalStageMessage.subscribe((msg) => {
        this.lan = msg;
        this.translate.use(msg == 1 ? "hi" : "en");
      });
    } catch (err) {
      console.log(err);
    }
    this.activatedRoute.params.subscribe(params => {
      if (typeof params['eregId'] != 'undefined') {
        this.eregId = params['eregId'];
        //this.eregId = this.eregId.replace(/~!~/g, '/');
      }
      if (typeof params['partyId'] != 'undefined') {
        this.partyId = params['partyId'];
        //this.partyId = this.partyId.replace(/~!~/g, '/');
      }

    })

  }

  ngOnInit(): void {
    this.getEregDetailForFeedbackByEregId(this.eregId);
    this.getEregFeedBackQuesList();
  }
  getEregDetailForFeedbackByEregId(eregId: any) {
    if (eregId != null && eregId != undefined) {
      let encrtid = this._AESEncryptDecryptService.encrypt("" + eregId);
      let encrtPartyId = this._AESEncryptDecryptService.encrypt("" + this.partyId);
      this.ngxService.start();
      this.eRegService.getEregDetailForFeedbackByEregId(this.eregId, this.partyId).subscribe((res: any) => {
        if (res.responseStatus == "true") {
          this.ngxService.stop();
          this.igrsEregTxnDetlsBean = res.responseData;
          this.getSROfficeList(this.igrsEregTxnDetlsBean.districtId)

        }
        else {
          this.ngxService.stop();
        }
      });
    }

  }
  getSROfficeList(districtId: any) {
    let param = {
      districtId: districtId,
    };
    this.ngxService.start();
    this.common.getSROfficeList(param).subscribe(dcdata => {
      this.ngxService.stop();
      if (dcdata.responseData != null) {
        this.srOfficeList = dcdata.responseData;
        this.srOfficeNameEn = this.srOfficeList.find(o => o.id == this.igrsEregTxnDetlsBean.srOfficeId).officeNameEn;
        this.srOfficeNameHi = this.srOfficeList.find(o => o.id == this.igrsEregTxnDetlsBean.srOfficeId).officeNameHi
      }
    });
  }
  getEregFeedBackQuesList() {
    this.ngxService.start();
    this.eRegService.getEregFeedBackQuesList().subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.ngxService.stop();
        this.eregFeedbackQuesList = res.responseData;
        this.ratingEregFeedbackQuesList = this.eregFeedbackQuesList.filter(x => x.ratingRequired == 1);
        this.noRatingEregFeedbackQuesList = this.eregFeedbackQuesList.filter(x => x.ratingRequired == 0)
      }
      else {
        this.ngxService.stop();
      }
    });

  }
  onSubmitFeedbackForm() {
    //  if (this.igrsEregTxnDetlsBean.regId == null || this.igrsEregTxnDetlsBean.regId == undefined || this.igrsEregTxnDetlsBean.regId == "")
    if (this.igrsEregTxnDetlsBean.statusId != 72)
      return;
    if (this.selectedValues.length != 7) {
      Swal.fire({
        title: `${this.lan == 0 ? "Please Give the Rating for All the Questions" : "कृपया सभी प्रश्नों के लिए रेटिंग दें"}`,
        icon: 'error',
        allowOutsideClick: false
      })
      return;
    }
    for (let i = 0; i < this.selectedValues.length; i++) {

      if (!this.igrsEregFeedbackModelList[i]) {
        this.igrsEregFeedbackModelList[i] = new IgrsEregFeedbackModel();
      }
      this.igrsEregFeedbackModelList[i].queId = this.selectedValues[i].questionNo;
      this.igrsEregFeedbackModelList[i].stars = this.selectedValues[i].selectedValue;
    }
    if (this.selectedOption != null && this.selectedOption != undefined && this.selectedOption != '') {
      let igrsEregFeedbackModel: IgrsEregFeedbackModel = new IgrsEregFeedbackModel();
      igrsEregFeedbackModel.queId = this.noRatingEregFeedbackQuesList[0].id;
      if (this.selectedOption == 'Yes') {
        igrsEregFeedbackModel.additionalAmountFlag = 1;
        igrsEregFeedbackModel.additionalAmount = this.additionalAmount;
        if (this.additionalAmount == null || this.additionalAmount == undefined || this.additionalAmount == Number("")) {
          this.additionalAmountDiv = true;
          return;
        }
      }
      else {
        this.additionalAmountDiv = false;
        igrsEregFeedbackModel.additionalAmountFlag = 0;
        igrsEregFeedbackModel.additionalAmount = undefined;
      }
      this.igrsEregFeedbackModelList.push(igrsEregFeedbackModel);
    }
    if (this.remarks != null && this.remarks != undefined && this.remarks != "") {
      let igrsEregFeedbackModel1: IgrsEregFeedbackModel = new IgrsEregFeedbackModel();
      igrsEregFeedbackModel1.queId = this.noRatingEregFeedbackQuesList[1].id
      igrsEregFeedbackModel1.remarks = this.remarks;
      this.igrsEregFeedbackModelList.push(igrsEregFeedbackModel1);
    }
    //this.eregId = this.eregId.replace(/~!~/g, '/');
    this.igrsEregFeedbackModelList[0].decryptEregId = this.eregId;
  //  this.partyId = this.partyId.replace(/~!~/g, '/');
    this.igrsEregFeedbackModelList[0].decryptPartyId = this.partyId;

    this.ngxService.start();
    this.eRegService.saveEregFeedback(this.igrsEregFeedbackModelList).subscribe((res: any) => {
      if (res.responseStatus == "true") {
        this.isSubmit=true;
        this.ngxService.stop();
        Swal.fire({
          title: `${this.lan == 0 ? "Feedback Successfully Submitted" : "फीडबैक सफलतापूर्वक सबमिट किया गया"}`,
          icon: 'success',
          allowOutsideClick: false
        })
      }
      else {
        Swal.fire({
          title: `${this.lan == 0 ? "You have already Submitted the Feedback" : "आपने पहले ही फीडबैक सबमिट कर दिया है"}`,
          allowOutsideClick: false
        })
        this.ngxService.stop();
      }
    });
  }

  onValueChange(event: { questionNo: number; selectedValue: number }) {
    const existing = this.selectedValues.find(q => q.questionNo === event.questionNo);
    if (existing) {
      existing.selectedValue = event.selectedValue;
    } else {
      this.selectedValues.push(event);
    }
  }
  onInputRadio() {

    this.additionalAmountDiv = false;
  }

  questions = [
    { order: 1, textEn: "What was the expertise level of the Officials while using the Sampada 2.0 application?", textHi: "संपदा 2.0 एप्लीकेशन का उपयोग करते समय अधिकारियों की विशेषज्ञता का स्तर क्या था?" },
    { order: 2, textEn: "How was the level of information provided on the Payments to be made?", textHi: "किए जाने वाले भुगतानों के संबंध में दी गई जानकारी का स्तर कैसा था?" },
    { order: 3, textEn: "How was the co-operativion and information provided by the office staff?", textHi: "कार्यालय स्टाफ द्वारा सहयोग एवं जानकारी किस प्रकार प्रदान की गई?" },
    { order: 4, textEn: "How was the overall technical performance of the application?", textHi: "एप्लिकेशन का समग्र तकनीकी प्रदर्शन कैसा था?" },
    { order: 5, textEn: "What is your level of clarity on all the steps performed during E-Registry?", textHi: "ई-रजिस्ट्री के दौरान किए गए सभी चरणों के बारे में आपकी स्पष्टता का स्तर क्या है?" },
    { order: 6, textEn: "Are you satisfied with the current Registration process using Sampada 2.0 application?", textHi: "क्या आप सम्पदा 2.0 एप्लीकेशन का उपयोग करके वर्तमान पंजीकरण प्रक्रिया से संतुष्ट हैं?" },
    { order: 7, textEn: "How was your overall experience in performing E-Registry using Sampada 2.0 application?", textHi: "संपदा 2.0 एप्लीकेशन का उपयोग करके ई-रजिस्ट्री करने का आपका समग्र अनुभव कैसा रहा?" },
    { order: 8, textEn: "How was your overall experience in performing E-Registry using Sampada 2.0 application?", textHi: "संपदा 2.0 एप्लीकेशन का उपयोग करके ई-रजिस्ट्री करने का आपका समग्र अनुभव कैसा रहा?" },
    { order: 9, textEn: "How was your overall experience in performing E-Registry using Sampada 2.0 application?", textHi: "संपदा 2.0 एप्लीकेशन का उपयोग करके ई-रजिस्ट्री करने का आपका समग्र अनुभव कैसा रहा?" },
  ];

}

export class IgrsEregFeedbackModel {
  id: number;
  eregId: number;
  partyId: number;
  queId: number;
  stars: number;
  remarks: string;
  additionalAmountFlag: number;
  additionalAmount: number;
  decryptEregId: string;
  decryptPartyId: string;
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { mstDemographyDepartmentHierarchy } from 'projects/common-lib/src/lib/models/api.masterdata.model/mstDemographyDepartmentHierarchy';
import { Demography } from 'projects/common-lib/src/lib/models/demography.list.model/demography';
import { DemographyManagementServive } from './services/demography.services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'citizen-demography-hierarchy-load',
  templateUrl: './citizen-demography-hierarchy-load.component.html',
  styleUrls: ['./citizen-demography-hierarchy-load.component.css']
})
export class CitizenDemographyHierarchyLoadComponent implements OnInit {

  demographyList: Demography[];

  demographyHierarchy?: mstDemographyDepartmentHierarchy;
  stateList: Demography[];
  isState: boolean = false;
  divisionList: Demography[];
  isDivision: boolean = false;
  districtList: Demography[]
  isDistrict: boolean = false;
  zoneList: Demography[];
  isZoneList: boolean = false;

  isSrOffice: boolean = false;
  srOfficeList :Demography[];

  localBodyList: Demography[];
  isLocalBody: boolean = false;
  wardList: Demography[];
  isWardList: boolean = false;
  wardColonyList: Demography[];
  isWardColonyList: boolean = false;
  tehsilList: Demography[];
  isTehsilList: boolean = false;
  villageList: Demography[];
  isVillageList: boolean = false;
  villageCollony: Demography[];
  isVillageCollony: boolean = false;
  isEnableDistrictreqred: boolean = false;
  selectedSroOffice:number;
  selecteddistictid: any;
  distictid: any;
  srofficeid: any;
  userDemographyType:any="";
  demographyHierarchyDistrict?: mstDemographyDepartmentHierarchy;

  department_id: any;
  demographyid: any;
  hierarchyLevel: any;
  oneLeveloldDemographyid: any;
  showAreaType: any;
  areaType: any = "";//R
  demographynofound: boolean = false;

  demographyTypeid: any;
  demographyTypeidDist: any;
  oneStepOldDemographyTypeid: any;

  zoneNgselect:any;
  localBody:any;
  wardNgselect:any;
  wardColonyNgselect:any;
  tehsilNgselect:any;
  villageNgselect:any;
  villageColonyNgselect:any;

  isdisableLocalBody: boolean = false;
  isdisableWard: boolean = false;
  isdisableTahsil:boolean = false;
  isdisableVillage:boolean =false;
  isdisableAreaType:boolean =false;
  isdisableWardColony:boolean=false;
  isdisableVillageColony:boolean=false;
  @Output() loadgldata = new EventEmitter<string>();
  @Input("selectAreaTypeErrormsg") selectAreaTypeErrormsg: any;
  @Input() componentFlag: string;//columnwise,rowwise
  lan:number;
  @Input() type_id:number=2;
  @Input() parent_id:number=1;
  @Input() lastdemograpgy_id:number=15;
  nextDemographyCodes:any={
    "State":2,
    "Zone":3,
    "District":4,
    "SRO Office":[7,12],
    "LocalBody":8,
    "Ward":14,
    "Tehsil":10,
    "Village/Patwari/Halka":15,
  }

  constructor(private translateService: TranslateHEService,
    private demographyService: DemographyManagementServive, 
    private ngxService: NgxUiLoaderService,
    private common: CommonService,
    private translate:TranslateService
    ) {
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       });

  }

  ngOnInit(): void {
    this.areaType='';
    if(this.type_id==4 ){
      this.selectedSroOffice = this.parent_id;
      this.areaType='';
      this.showAreaType=true;
    }else{
      this.loadDemoGraphyNextLevel(this.type_id, this.parent_id, this.areaType);
    }
  }

  onChange(event: any, changeDemography?:string) {
    if(changeDemography == "isZoneList"){
      this.zoneClick();
    }else if(changeDemography == "isDistrict"){
      this.districtClick();
    }else if(changeDemography == "isSrOffice"){
      this.srOfficeClick();
    }
    if (event!="" && event!=undefined) {
      this.type_id = this.nextDemographyCodes[event.demographyType.demographyTypeName];
      let check_id=Array.isArray(this.type_id)?this.type_id[0]:this.type_id;
      if(check_id <= this.lastdemograpgy_id) {;
        this.parent_id = event.id;
        //this.oneLeveloldDemographyid=this.demographyid;
        this.selectAreaTypeErrormsg = false;
        if(event.id!=1){  // not call top level
          if(event.demographyTypeId==4 ){
            this.selectedSroOffice = event.id;
            this.areaType='';
            this.showAreaType=true;
          }else if(event.demographyTypeId<14 && event.demographyTypeId<15){
            this.loadDemoGraphyNextLevel(this.type_id, this.parent_id, this.areaType);
          }  
        }
      }
    } else {
      
      //this.demographyid=this.oneLeveloldDemographyid;
    }
    this.loadgldata.emit(event);
  }

  onItemChange(event: any) {
    // console.log("this.demo-------------------- "+ this.demographyid)
    // this.selectAreaTypeErrormsg = true;
    this.areaType = event.value;
    if (this.areaType == "R") {
      this.isLocalBody = false;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;
      this.type_id=this.nextDemographyCodes['SRO Office'][1];
    }
    else if (this.areaType == "U") {
      this.isLocalBody = false;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;
      this.type_id = this.nextDemographyCodes['SRO Office'][0];
    }
    this.loadDemoGraphyNextLevel(this.type_id, this.selectedSroOffice, this.areaType);
  }

  loadDemoGraphyByTypeNadParent(demographyId:any, demographyTypeid:any,departmentId: any, areaType: any){

        this.showAreaType=false;
        let childDemographyTypeId=0;
        let parentDemographyId=0;
        let newDemographyTypeId=0;

        if(demographyId!="")
            parentDemographyId=demographyId;
        else
            parentDemographyId=this.demographyid;

  
       if(this.demographyTypeid==12)
            childDemographyTypeId=10;
        else if(this.demographyTypeid==10)
            childDemographyTypeId=15;
        else if(this.demographyTypeid==7)
            childDemographyTypeId=8;
        else if(this.demographyTypeid==8)
            childDemographyTypeId=14;
        else if(this.demographyTypeid==14 || this.demographyTypeid==15)  {
          if(7>this.userDemographyType)
          this.showAreaType=true;
        } 
            

  this.common.getdraftDemographyByParentAndtype(childDemographyTypeId,parentDemographyId).subscribe(data1=>{
          this.demographyList = data1.responseData;
          //this.demographyHierarchy = data.responseData.mstDemographyDepartmentHierarchy;
          //demographyTypeId= this.demographyHierarchy?.demographyType.id;
          this.demographyTypeid = childDemographyTypeId;
          newDemographyTypeId = this.demographyList[0]?.demographyType.id;
          this.oneStepOldDemographyTypeid=this.demographyTypeid;
         
        
        if (this.demographyList.length != 0) {
        
          if (childDemographyTypeId == 10) {
            if(7>this.userDemographyType)
            this.showAreaType=true;
            this.villageNgselect="";
            this.isVillageList=true;
            this.villageList = this.demographyList;
            this.isWardColonyList=false;
            this.isVillageCollony=false;
          }else if (childDemographyTypeId == 8) {
            if(7>this.userDemographyType)
            this.showAreaType=true;
            this.isWardList=true;
            this.wardNgselect="";
            this.wardList = this.demographyList;
            this.isVillageList=false;
            this.isWardColonyList=false;
            this.isVillageCollony=false;
          }else if (childDemographyTypeId == 14) { 
            if(7>this.userDemographyType)
            this.showAreaType=true;
                this.isWardColonyList=true;
                this.wardColonyNgselect="";
                this.wardColonyList = this.demographyList;
                this.isVillageList = false;
                this.isVillageCollony=false; 
            }else if(childDemographyTypeId == 15){
              if(7>this.userDemographyType)
              this.showAreaType=true;
              this.isVillageCollony = true;
              this.villageColonyNgselect="";
              this.villageCollony = this.demographyList;
              this.isWardList=false;
              this.isWardColonyList=false;
            }
        }
       });


  }
  onchangeClose(demograybhyNo:any){
    
    if (demograybhyNo == 1) {
    
      this.isState = true;
      this.isZoneList = false;
      this.isDistrict = false;
      this.isSrOffice = false;
      this.isLocalBody = false;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;

    } else if (demograybhyNo == 2) {
      this.isZoneList = true;
      this.isDistrict = false;
      this.isSrOffice = false;
      this.isLocalBody = false;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;

    
    }else if (demograybhyNo == 3) {
      this.isDistrict = true;
      this.isSrOffice = false;
      this.isLocalBody = false;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;  
    }else if (demograybhyNo == 4) {
      this.isSrOffice = true;
      this.isLocalBody = false;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;
    } else if (demograybhyNo == 7) {
      this.isLocalBody = true;
      this.isTehsilList = false;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;
    }else if (demograybhyNo == 12) {
      this.isLocalBody = false;
      this.isTehsilList = true;
      this.isWardList=false;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;
   }else if (demograybhyNo == 8) {
      this.isWardList=true;
      this.isVillageList=false;
      this.isWardColonyList=false;
      this.isVillageCollony=false;

    }else if (demograybhyNo == 10) {
      this.isVillageList=true;
      this.isWardColonyList=false;
      this.isVillageCollony=false;
      this.isWardList=false;

    }else if (demograybhyNo == 14) {
      this.isWardColonyList=true;
      this.isVillageCollony=false;
   }else if (demograybhyNo == 15) {
      this.isVillageCollony=true;
 }
  }

  loadDemoGraphyNextLevel(demographyId: any, departmentId: any, areaType: any) {
    // this. hideShowAllSelected();
    this.ngxService.start();
    this.demographyService.getFetchDemoGraphyNextLevel(demographyId, departmentId, areaType).subscribe(data => {
      // console.log("-----------data------------------- ",data);
      this.demographyList = data.responseData;
      // this.showAreaType=false;
      if (this.demographyList.length != 0) {
        //this.demographyHierarchy = data.responseData?.mstDemographyDepartmentHierarchy;
        let demographyTypeId = this.demographyList[0]?.demographyType.id;
        this.type_id = demographyTypeId;
        let id = this.demographyList[0]?.id;
        if(demographyTypeId==3)
          this.demographyTypeidDist=demographyTypeId;
        if (demographyTypeId == 1) {
          //this.selecteddistictid="";
          this.showAreaType=false;
          this.stateList = this.demographyList;
          this.isState = true;
          this.isZoneList = false;
          this.isDistrict = false;
          this.isSrOffice =false;
          this.isLocalBody = false;
          this.isTehsilList = false;
        } else if (demographyTypeId == 2) {
          this.showAreaType=false;
          this.zoneNgselect="";
          this.zoneList = this.demographyList;
          this.isZoneList = true;
          this.isDistrict = false;
          this.isSrOffice =false;
          this.isLocalBody = false;
          this.isTehsilList = false;
        }else if (demographyTypeId == 3) {
          this.distictid="";
          //this.showAreaType=false;
          this.districtList = this.demographyList;
          this.isDistrict = true;
          this.isSrOffice =false;
          this.isLocalBody = false;
          this.isTehsilList = false;
        }else if (demographyTypeId == 4) {
          this.srofficeid="";
          this.isSrOffice =true;
          this.srOfficeList = this.demographyList;
          //this.showAreaType=true;
          this.isLocalBody = false;
          this.isTehsilList = false;
        }else if (demographyTypeId == 7) {
          this.localBodyList = this.demographyList;
          this.localBody="";
          this.isLocalBody = true;
          this.isTehsilList = false;
        }else if (demographyTypeId == 8) {
          this.wardList = this.demographyList;
          this.wardNgselect = "";
          this.isWardList = true;
          this.isWardColonyList = false;
        }else if (demographyTypeId == 10) {
          this.villageList = this.demographyList;
          this.villageNgselect = "";
          this.isVillageList = true;
          this.isVillageCollony = false;
        }else if (demographyTypeId == 14) {
          this.wardColonyList = this.demographyList;
          this.wardColonyNgselect = "";
          this.isWardColonyList = true;
        }else if (demographyTypeId == 15) {
          this.villageCollony = this.demographyList;
          this.villageColonyNgselect = "";
          this.isVillageCollony = true;
        }else if (demographyTypeId == 12) {
          this.tehsilList = this.demographyList;
          this.isLocalBody = false;
          this.tehsilNgselect = "";
          this.isTehsilList = true;
        }
      } else {
        // this.demographyList=new Array();
        // this.tehsilList = this.demographyList;
        // this.localBodyList = this.demographyList;
        // if(this.oneStepOldDemographyTypeid==7||this.oneStepOldDemographyTypeid==12){
        //   if(7>this.userDemographyType)
        //   this.showAreaType=true;
        // }else if(this.demographyTypeid==7||this.demographyTypeid==12){
        //   this.showAreaType=false;
        // }else{
        //   this.isLocalBody = false;
        //   this.isTehsilList = false;
        // }
      }
      if(this.userDemographyType==4){
         this.showAreaType=true;
         this.demographyid="";
      }
      this.oneStepOldDemographyTypeid=this.demographyTypeid;
    });
    this.ngxService.stop();
  }

  districtClick(){
    this.oneStepOldDemographyTypeid=3;
    this.demographyTypeid=3;
    this.isSrOffice = false;
    this.showAreaType=false;
    this.isLocalBody = false;
    this.isTehsilList = false;
    this.isWardList=false;
    this.isVillageList=false;
    this.isWardColonyList=false;
    this.isVillageCollony=false;
  }

  srOfficeClick(){
    this.oneStepOldDemographyTypeid=4;
    this.demographyTypeid=4;
    this.showAreaType=false;
    this.isLocalBody = false;
    this.isTehsilList = false;
    this.isWardList=false;
    this.isVillageList=false;
    this.isWardColonyList=false;
    this.isVillageCollony=false;
  }

  zoneClick(){
    this.oneStepOldDemographyTypeid=2;
    this.demographyTypeid=2;
    this.isDistrict = false;
    this.isSrOffice = false;
    this.showAreaType=false;
    this.isLocalBody = false;
    this.isTehsilList = false;
    this.isWardList=false;
    this.isVillageList=false;
    this.isWardColonyList=false;
    this.isVillageCollony=false;
  }
  tehesilClick(){
    this.oneStepOldDemographyTypeid=12;
    this.demographyTypeid=12;
    this.isLocalBody = false;
    this.isWardList=false;
    this.isVillageList=false;
    this.isWardColonyList=false;
    this.isVillageCollony=false;

  }

  localBodyClick(){
    this.oneStepOldDemographyTypeid=7;
    this.demographyTypeid=7;
    this.isTehsilList = false;
    this.isWardList=false;
    this.isVillageList=false;
    this.isWardColonyList=false;
    this.isVillageCollony=false;
  }
  wardClick(){
    this.oneStepOldDemographyTypeid=8;
    this.demographyTypeid=8;
    this.isWardColonyList=false;
   }

  wardClonyClick(){
    this.oneStepOldDemographyTypeid=14;
    this.demographyTypeid=14;
  }

  villageClick(){
    this.oneStepOldDemographyTypeid=10;
    this.demographyTypeid=10;
    this.isVillageCollony=false;
  }

  villageClonyClick(){
    this.oneStepOldDemographyTypeid=15;
    this.demographyTypeid=15;
    if(7>this.userDemographyType)
        this.showAreaType=true;
   }

  resetDemography() {
    this.hideShowAllSelected();
    this.type_id = 2;
    this.parent_id = 1
    this.loadDemoGraphyNextLevel(this.type_id, this.parent_id, this.areaType);
  }

  hideShowAllSelected() {
    this.isState = false;
    this.isDivision = false;
    this.isZoneList = false;
    this.isDistrict = false;
    this.isSrOffice = false;
    this.isLocalBody = false;
    this.isWardList = false;
    this.isVillageList = false;
    this.isTehsilList = false;
    this.isWardColonyList = false;
    this.isVillageCollony = false;
  }
}

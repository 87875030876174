export enum DutyConstants {

    popup_premium=1,
    popup_royalty=2,
    popup_mineral=3,
    popup_rent=4,
    major_min="MAJOR",
    minor_min="MINOR",

    popup_royalty_En ="Calculate Royalty",
    popup_rent_En="Calculate Annual Average Rent",
    popup_premium_En = "Calculate Premium",

    popup_royalty_Hi="रॉयल्टी की गणना करें",
    popup_rent_Hi="औसत किराए की गणना",
    popup_premium_Hi="प्रीमियम की गणना",

    property_requiredField_En = "Please enter Property valuation required fields ",
    property_requiredField_Hi = "कृपया संपत्ति मूल्यांकन आवश्यक फ़ील्ड दर्ज करें",

    propValId_required_En = "Property valuation ID is required",
    propValId_required_Hi = "संपत्ति मूल्यांकन आईडी आवश्यक है",

    propValId_exist_En = "Property valuation ID already exist",
    propValId_exist_Hi = "संProperty valuation ID already exist",

    //property_required_En = "Property valuation is required",
    //property_required_Hi = "संपत्ति का मूल्यांकन आवश्यक है",

    property_required_En = "Do You Have Property Valuation Id? ",
    property_required_Hi = "क्या आपके पास संपत्ति मूल्यांकन आईडी है?",

    property_req_FTP_En = "Property Valuation For First Transacting Party ",
    property_req_FTP_Hi = "पहले लेन-देन करने वाला पक्षकार के लिए संपत्ति का मूल्यांकन आवश्यक है",

    property_req_STP_En = "Property Valuation For Second Transacting Party ",
    property_req_STP_Hi = "दूसरी लेन-देन करने वाला पक्षकार के लिए संपत्ति का मूल्यांकन",

    
    property_req_BOTH_En = "Property Valuation For First and Second Transacting Parties",
    property_req_BOTH_Hi = "प्रथम और द्वितीय लेन-देन करने वाले पक्षकारों के लिए संपत्ति का मूल्यांकन",

    instID_8_En = "Sum of builder share and Party Owner cannot exceed 100",
    instID_8_Hi = "बिल्डर शेयर और पक्षकार के मालिक का योग 100 से अधिक नहीं हो सकता",

    instID_9_En = "No of Separated Parts must be equal to no of Properties",
    instID_9_Hi = "अलग-अलग हिस्सों की संख्या संपत्तियों की संख्या के बराबर होनी चाहिए",

    instID_108_En = "Minimum two Land Revenue required",
    instID_108_Hi = "न्यूनतम दो भूमि राजस्व की आवश्यकता",

    mvPropErr1_En = "All fields required",
    mvPropErr1_Hi = "सभी फ़ील्ड आवश्यक हैं",

    mvPropErr2_En = "Details of Movable Property is required",
    mvPropErr2_Hi = "चल संपत्ति का विवरण आवश्यक है",

    mvPropErr3_En = "Consideration Amount of Movable property is required",
    mvPropErr3_Hi = "चल संपत्ति की कंसीडरेशन राशि आवश्यक है",

    mvPropErr4_En = "Please upload proof of Movable Property",
    mvPropErr4_Hi = "कृपया चल संपत्ति का प्रमाण अपलोड करें",

    constDetlsErr1_En = "All fields required",
    constDetlsErr1_Hi = "सभी फ़ील्ड आवश्यक हैं",

    constDetlsErr2_En = "Please enter total no of consenters",
    constDetlsErr2_Hi = "कृपया सहमति देने वालों की कुल संख्या दर्ज करें",

    constDetlsErr3_En = "Consenter with consideration required",
    constDetlsErr3_Hi = "विचार सहित कंसीडरेशन आवश्यक",

    constDetlsErr4_En = "Consideration Amount required",
    constDetlsErr4_Hi = "कंसीडरेशन राशि की आवश्यकता है",

    constDetlsErr5_En = "Consenter Details required for all properties",
    constDetlsErr5_Hi = "सभी संपत्तियों के लिए आवश्यक सहमति विवरण",

    impoundNoChange_En = "There is no change in valuation/add property",
    impoundNoChange_Hi = "वैल्यूएशन/ऐड प्रॉपर्टी में कोई बदलाव नहीं हुआ है",

    impoundError_En = "There is some technical error!",
    impoundError_Hi = "कुछ तकनीकी त्रुटि है!",

    impoundErrorSaving_En = "There is some error in saving data!",
    impoundErrorSaving_Hi = "डेटा सेव करने में कुछ त्रुटि है!",

    impoundSectionHead_En = "Please select Head and Section type!",
    impoundSectionHead_Hi = "कृपया शीर्ष और अनुभाग प्रकार का चयन करें!",

    impoundCOSError_En = "Document already Impounded by COS",
    impoundCOSError_Hi = "सीओएस द्वारा पहले ही जब्त किए गए दस्तावेज",

    impoundDutyError_En = "Duty is less than calculated by SP or No change in Total Duty!",
    impoundDutyError_Hi = "शुल्क एसपी द्वारा गणना से कम है या कुल शुल्क में कोई बदलाव नहीं है!",


    impoundConsdAmtError_En = "Consideration amount cannot be less than provided by SP!",
    impoundConsdAmtError_Hi = "कंसीडरेशन राशि एसपी द्वारा प्रदान की गई राशि से कम नहीं हो सकती!",

    impoundDocNameDesc_En = "Document Name and Desc is mandatory!!",
    impoundDocNameDesc_Hi = "दस्तावेज़ का नाम और विवरण अनिवार्य है!!",

    impoundPropNotAddError_En = "Please add atleast one property to proceed",
    impoundPropNotAddError_Hi = "कृपया आगे बढ़ने के लिए कम से कम एक संपत्ति जोड़ें",

    impoundFileNotSave_En = "Please save the additional file uploaded",
    impoundFileNotSave_Hi = "कृपया अपलोड की गई अतिरिक्त फ़ाइल को सुरक्षित रखें",

    impoundFileSelect_En = "Please delete the additional file uploaded or change the file selection",
    impoundFileSelect_Hi = "कृपया अपलोड की गई अतिरिक्त फ़ाइल को डिलीट करें या फ़ाइल चयन बदलें",

    impoundPropDelete_En = "Selected property cannot be deleted!",
    impoundPropDelete_Hi = "चयनित संपत्ति को डिलीट नहीं किया जा सकता!",

    impoundMISDeedChange_En = "Please select different deed instrument!",
    impoundMISDeedChange_Hi = "कृपया अलग विलेख लिखत का चयन करें!",

    impoundDateNUll_En = "Date of execution or admission is not present!",
    impoundDateNUll_Hi = "एक्सक्यूशन या प्रवेश की तिथि मौजूद नहीं है!",

    partitonDeedAreaNUll_En = "Area cannot be null or blank",
    partitonDeedAreaNUll_Hi = "Area cannot be null or blank",

    partitonDeedAreaNotEqual_En = "Sum of Area of all parties should be equal to transacting area",
    partitonDeedAreaNotEqual_Hi = "Sum of Area of all parties should be equal to transacting area",

    partitonDeedPartyNotSaved_En = "Please save area of all parties",
    partitonDeedPartyNotSaved_Hi = "Please save area of all parties",
}

import { Injectable, ViewChild } from '@angular/core';
import  'leaflet-simple-map-screenshoter';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrintMap {
    simpleMapScreenshoter:any;
    map:any;
    lan:number=1;
    options:any;
    browserPrintbtn: any;
    title:string = "GUIDELINE";
    year:string="2024-2025";
    department:string="Registration and Stamps, Commercial Taxes Department, Madhya Pradesh";
    description:any;
    fontData:any="";
    efectingDate:string=""
    rupee:any;
    constructor(private translateService: TranslateHEService, private translate: TranslateService, private ngxService: NgxUiLoaderService, private http:HttpClient){
        this.translateService.currentApprovalStageMessage.subscribe((msg:number)=> {
            this.lan = msg
            this.translate.use(msg==1?'hi':'en')
        });   
        // this.http.get('assets/fonts/hindifontString.txt',{responseType: 'text'}).subscribe((binary:any)=>{
        //   this.fontData=binary;            
        // });  
        
        this.rupee = new Intl.NumberFormat('en-IN');
    }

    addMap(map:L.Map, financialYear:any=null){
        this.map=map;
        if(financialYear != null)
        this.year =financialYear.finYear;
        this.title ='GUIDELINE ( '+this.year+' )';
        this.efectingDate = financialYear?.finYearStartDate;
        this.simpleMapScreenshoter = L.simpleMapScreenshoter({hidden: true}).addTo(this.map);
    }

    printstart(guidelineObj:any, selectedMstDemographyBean:any){
        //this.map.selectedbaseLayer.name!="googleImagery" && this.map.selectedbaseLayer.name!="googleStreetMap"
        if(false){
          var formValues:any  = Swal.fire({
            title: this.lan==1?'प्रिंट मैप':'Print Map',
            html:
              '<label style="width:100%; text-align:left;">'+ (this.lan==1?'मैप शीर्षक':'Map Title')+'<span style="color:red">*</span> : </label>'+
              '<input id="swal-input1" class="form-control mb-2" required="required" placeholder="'+(this.lan==1?'अधिकतम 50 वर्णों की अनुमति है':'Maximum 50 characters allowed')+'" maxlength="50">' +
              '<label style="width:100%; text-align:left;">'+(this.lan==1?'मैप विवरण':'Map Description')+' : </label>'+
              '<textarea id="swal-input2" class="form-control" required="required" placeholder="'+(this.lan==1?'अधिकतम 500 वर्णों की अनुमति है':'Maximum 500 characters allowed')+'" maxlength="500"></textarea>'+
              '<span id="alertprint" style="color:red; display:none">'+ (this.lan==1?'कृपया मैप शीर्षक दर्ज करें':'Please Enter Map Title')+'</span>',
              
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.lan==1?'प्रिंट':'Print',
            cancelButtonText: this.lan==1?'कैंसिल':'Cancel',
            focusConfirm: false, 
            preConfirm: () => {
              if((document.getElementById('swal-input1') as HTMLInputElement).value == ""){
                (document.getElementById('alertprint') as HTMLInputElement).style.display='block';
                return false;
              }else{
                return [
                  (document.getElementById('swal-input1') as HTMLInputElement).value,
                  (document.getElementById('swal-input2') as HTMLInputElement).value
                ];
              }
            }
          }).then( (result) => {
            if(result.isConfirmed==true){
                this.ngxService.start();
                this.title=result.value?result.value[0]:'';
                this.description=result.value?result.value[1]:'';
                let format = 'image' // 'image' - return base64, 'canvas' - return canvas
                let overridedPluginOptions = 
                {
                mimeType: 'image/jpeg'
                }
          
                this.simpleMapScreenshoter.takeScreen(format, overridedPluginOptions).then((Blob: any)=> 
                {
                  //this.downloadpdf(Blob);
                }).catch((any: any) => 
                {
                  this.ngxService.start();
                  console.error("Something went wrong");
                })
    
            }
          })
        }else{
        //   Swal.fire({
        //     text: this.lan==1?"गूगल बेस मैप पर प्रिंट सुविधा उपलब्ध नहीं है, कृपया अन्य बेस मैप में बदलें":"Print Facility is not Available on Google Base Map, Please Change to Other Base Map",
        //     confirmButtonText: this.lan==1?'ओके':'OK'
        //   });
            this.ngxService.start();
            let format = 'image' // 'image' - return base64, 'canvas' - return canvas
            let overridedPluginOptions = 
            {
            mimeType: 'image/jpeg'
            }
    
            this.simpleMapScreenshoter.takeScreen(format, overridedPluginOptions).then((Blob: any)=> 
            {
              let scale:any = document.getElementsByClassName('leaflet-control-scale leaflet-control')[0];
              html2canvas(scale).then((canvas:any) => {
                let scaleImg:any = canvas.toDataURL('image/png');
                this.downloadpdf(Blob, guidelineObj, selectedMstDemographyBean, scaleImg);
              })
              
            }).catch((any: any) => 
            {
              this.ngxService.stop();
              console.error("Something went wrong");
            })
        }
    }

    downloadpdf(anycanvas:any, guidelineObj:any, selectedMstDemographyBean:any, scaleImg:any=null){
      try{
        let doc = new jsPDF('l', 'mm', 'a4');
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        var lines =doc.splitTextToSize(this.description, 225);
        //let checkedCheckbox=this.map.allLayers;

        var legends="";
        var plegend:number=23;
        //doc.addFileToVFS('hindi.ttf', this.fontData);
        //doc.addFont("hindi.ttf", "hindi", "normal");
        // Load the Hindi font
        // Use the loaded Hindi font
        
        doc.addImage('assets/images/mplogo.png', "PNG", 5,3, 25, 25);
        doc.setFont("Calibri ", "bold");
        doc.setFontSize(18);
        doc.text(this.title,pageWidth/2,10,{align: 'center'});
        doc.setFontSize(13);
        doc.setTextColor("#DAA520");
        //doc.text(this.year,pageWidth/2,15,{align: 'center'});
        doc.text(this.department,pageWidth/2,17,{align: 'center'});
        doc.addImage('assets/images/logo-1.png', "PNG", 270,3, 25, 25);
        doc.addImage(anycanvas, "PNG", 2, 30, 244, 130);
        if(scaleImg != null){
          doc.addImage(scaleImg, "PNG", 225, 145, 18, 7);
        }
        //doc.rect(2,2,294,13);
        doc.rect(2,30,244,130);
        doc.rect(246,30,50,130);
        //doc.rect(2,160,244,60);
        doc.setTextColor("black");
        doc.setFontSize(10);
        // Set font
        if(this.lan==0){
          doc.setFont("Calibri ", "bold");
        }else{
          //doc.setFont('hindi',"normal");
          doc.setFont("Calibri ", "bold");
        }
        //doc.setFont("NotoSansHindi");
        //doc.rect(246,32,50,20);
        doc.text('District'+':',248,120,{align: 'left'});
        //doc.rect(246,52,50,20);
        doc.text('SRO Office'+':',248,106,{align: 'left'});
        //doc.rect(246,72,50,20);
        doc.text('Area Type'+':',248,82,{align: 'left'});
        //doc.rect(246,92,50,20);
        doc.text('Local Body/Tehsil'+':',248,92,{align: 'left'});
        //doc.rect(246,112,50,20);
        doc.text('Ward/Village'+':',248,60,{align: 'left'});
        //doc.rect(246,132,50,20);
        doc.text('Guideline Location'+':',248,38,{align: 'left'});


        doc.setFontSize(9);
        if(this.lan==0){
          doc.setFont("Calibri ", "normal");
        }else{
          doc.setFont("Calibri ", "normal");
        }
        doc.text((this.lan==0)?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameEn,248,123,{align: 'left'});
        let srOffice=doc.splitTextToSize((this.lan==0)?selectedMstDemographyBean?.location?.sroffice?.demographyNameEn:selectedMstDemographyBean?.location?.sroffice?.demographyNameEn,46);
        doc.text(srOffice,248,110,{align: 'left'});
        if(selectedMstDemographyBean?.demographyType.demographyAreaType =="R"){
            doc.text((this.lan==0)?"Rural":"Rural",248,86,{align: 'left'});
            doc.text((this.lan==0)?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameEn,248,95,{align: 'left'});

            let vill = doc.splitTextToSize((this.lan==0)?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameEn,46);
            doc.text(vill,248,64,{align: 'left'});
            let vill_colony = doc.splitTextToSize((this.lan==0)?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameEn,46)
            doc.text(vill_colony,248,42,{align: 'left'});

        }else{
            doc.text((this.lan==0)?"Urban":"Urban",248,86,{align: 'left'});
            doc.text((this.lan==0)?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameEn,248,95,{align: 'left'});

            let ward = doc.splitTextToSize((this.lan==0)?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameEn,46);
            doc.text(ward,248,64,{align: 'left'});
            let ward_colony = doc.splitTextToSize((this.lan==0)?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn,46);
            doc.text(ward_colony,248,42,{align: 'left'});
        }
        doc.setFontSize(9);
        if(this.lan==0){
          doc.setFont("Calibri ", "normal");
        }else{
          doc.setFont("Calibri ", "normal");
        }
        doc.rect(2,160,45,10);
        doc.text('PLOT (SQM)',2+22,166,{align: 'center'});
        doc.rect(47,160,55,10);
        doc.text('BUILDING RESIDENTIAL (SQM)',47+(55/2),166,{align: 'center'});
        doc.rect(102,160,55,10);
        doc.text('BUILDING COMMERCIAL (SQM)',102+(55/2),166,{align: 'center'});
        doc.rect(157,160,45,10);
        doc.text('BUILDING MULTI (SQM)',157+(45/2),166,{align: 'center'});
        doc.rect(202,160,48,10);
        doc.text('AGRICULTURAL LAND (HA)',202+(48/2),166,{align: 'center'});
        //doc.text('(HECTARE)',202+(50/2),170,{align: 'center'});
        doc.rect(250,160,46,10);
        doc.text('AGRICULTURAL PLOT (SQM)',250+(46/2),166,{align: 'center'});
        //doc.text('(SQM)',252+(48/2),170,{align: 'center'});


        if(this.lan==0){
          doc.setFont("Calibri ", "normal");
        }else{
          doc.setFont("Calibri ", "normal");
        }
        doc.setFontSize(8);
        doc.rect(2,170,15,10);
        doc.text('Residential',2+(15/2),175,{align: 'center'});
        doc.rect(17,170,16,10);
        doc.text('Commercial',17+(16/2),175,{align: 'center'});
        doc.rect(33,170,14,10);
        doc.text('Industrial',33+(14/2),175,{align: 'center'});

        doc.rect(47,170,13,10);
        doc.text('RCC',47+(13/2),175,{align: 'center'});
        doc.rect(60,170,13,10);
        doc.text('RBC',60+(13/2),175,{align: 'center'});
        doc.rect(73,170,14,10);
        doc.text('Tin Shade',73+(14/2),175,{align: 'center'});
        doc.rect(87,170,15,10);
        doc.text('Kaccha',87+(15/2),175,{align: 'center'});
        doc.text('Kabelu',87+(15/2),178,{align: 'center'});

        doc.rect(102,170,18,10);
        doc.text('Shop',102+(18/2),175,{align: 'center'});
        doc.rect(120,170,18,10);
        doc.text('Office',120+(18/2),175,{align: 'center'});
        doc.rect(138,170,19,10);
        doc.text('Godown',138+(19/2),175,{align: 'center'});

        doc.rect(157,170,22.5,10);
        doc.text('Residential',157+(23/2),175,{align: 'center'});
        doc.rect(179.5,170,22.5,10);
        doc.text('Commercial',179.5+(23/2),175,{align: 'center'});

        doc.rect(202,170,24,10);
        doc.text('Irrigated',202+(24/2),175,{align: 'center'});
        doc.rect(226,170,24,10);
        doc.text('Un Irrigated',226+(24/2),175,{align: 'center'});

        doc.rect(250,170,23,10);
        doc.text('Sub Clause wise',250+(23/2),175,{align: 'center'});
        doc.text('Residential',250+(23/2),178,{align: 'center'});
        doc.rect(273,170,23,10);
        doc.text('Sub Clause wise',273+(23/2),175,{align: 'center'});
        doc.text('Commercial',273+(23/2),178,{align: 'center'});
        
        //Rate--------------------------------------------------------------------------------------
        doc.rect(2,180,15,10);
        //doc.rect(6,185,4,4);
        doc.text(this.rupee.format(guidelineObj?.ratePlotResidential).toString(),2+(15/2),185,{align: 'center'});
        doc.rect(17,180,16,10);
        doc.text(this.rupee.format(guidelineObj?.ratePlotCommercial.toString()),17+(16/2),185,{align: 'center'});
        doc.rect(33,180,14,10);
        doc.text(this.rupee.format(guidelineObj?.ratePlotIndustrial.toString()),33+(14/2),185,{align: 'center'});

        doc.rect(47,180,13,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingRcc.toString()),47+(13/2),185,{align: 'center'});
        doc.rect(60,180,13,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingRbc.toString()),60+(13/2),185,{align: 'center'});
        doc.rect(73,180,14,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingTShade.toString()),73+(14/2),185,{align: 'center'});
        doc.rect(87,180,15,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingKabelu.toString()),87+(15/2),185,{align: 'center'});

        doc.rect(102,180,18,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingShop.toString()),102+(18/2),185,{align: 'center'});
        doc.rect(120,180,18,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingOffice.toString()),120+(18/2),185,{align: 'center'});
        doc.rect(138,180,19,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingGodown.toString()),138+(19/2),185,{align: 'center'});

        doc.rect(157,180,22.5,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingMultiResi.toString()),157+(23/2),185,{align: 'center'});
        doc.rect(179.5,180,22.5,10);
        doc.text(this.rupee.format(guidelineObj?.rateBuildingMultiComm.toString()),179.5+(23/2),185,{align: 'center'});

        doc.rect(202,180,24,10);
        doc.text(this.rupee.format(guidelineObj?.rateAgriIrregated.toString()),202+(24/2),185,{align: 'center'});
        doc.rect(226,180,24,10);
        doc.text(this.rupee.format(guidelineObj?.rateAgriUnirregated.toString()),226+(24/2),185,{align: 'center'});

        doc.rect(250,180,23,10);
        doc.text(this.rupee.format(guidelineObj?.rateLandUptoResi.toString()),250+(23/2),185,{align: 'center'});
        doc.rect(273,180,23,10);
        doc.text(this.rupee.format(guidelineObj?.rateLandUptoComm.toString()),273+(23/2),185,{align: 'center'});
      

        // Set fill color (in this case, red)
        doc.setFillColor(248,248,248); // Red color
        doc.rect(2,192,294,6, 'F');
        doc.text('Effective Date: '+this.efectingDate,4,196);
        doc.setFillColor(255,255,255);
        doc.setFontSize(9);
        doc.text('Copyright@IGRS',4,205);
        doc.setFont("Calibri ", "bold");
        doc.setFontSize(16);
        doc.text('Legends :',247,245);
        doc.setFont("Calibri ", "bold");
        doc.setFontSize(14);
        //doc.text('Guideline Rate :',5,165);
        doc.setFont("times", "normal");
        doc.setFontSize(10);
        //doc.text(lines, 5, 155);
        doc.addImage('assets/images/northArrow1.png', "PNG", 217,35, 25, 25);
        doc.setFont("Calibri ", "bold");
        //doc.setFontSize(15);
        doc.text('Designed by :',227,205);
        doc.addImage('assets/images/MPSeDC.png', "PNG", 278,195, 17, 15);
        doc.setFont("Calibri ", "bold");
        doc.setFontSize(9);
        doc.setTextColor('brown');
        doc.text('MPSSDI, MPSeDC',247,205);
        doc.save('GuideLine.pdf');
        this.ngxService.stop();
      }catch(Ex:any){
        this.ngxService.stop();
        //this.alertmessage.errorAlertMessage({});
      }
    }
    
    // Call the function to read the TTF file
   
}



import { CommercialConstructionType } from "./CommercialConstructionType";
import { ResidentialConstructionType } from "./ResidentialConstructionType";
import { ResidentialCumCommercialConstructionType } from "./ResidentialCumCommercialConstructionType";

export class PropertyValuationRequestBeanMap {
    floorTypeResidensialList=new Map<number,ResidentialConstructionType>();
	floorTypeIndustrialList=new Map<number,ResidentialConstructionType>();
	floorTypeHealthList=new Map<number,ResidentialConstructionType>();
	floorTypeEducationList=new Map<number,ResidentialConstructionType>();
	floorTypeOtherList=new Map<number,ResidentialConstructionType>();
	floorTypeCommercialList=new Map<number,CommercialConstructionType>();
    floorResidentialCumCommercialList=new Map<number,ResidentialCumCommercialConstructionType>();
}
import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { DefaultServiceService } from '../../default/default-service.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
// import { Demography } from 'projects/common-lib/src/lib/models/demography.list.model/demography';
import { error } from 'console';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class HomeComponent {
  private settings!: AppSetting;

  active = 1;
  images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
 
  //districtId:number=null;
  //districts:Array<Demography>=[];
  // map:L.Map;
  // markers:Array<L.Marker>=[];
  //selectOffice:string="sr";
  //redIcon:L.Icon;
  //popup:any = L.popup({offset: [1, -35]});
  // popupConfig:any=[
  //   {"name":"contactNo", "lable_hi":"संपर्क नंबर","lable_en":"Contact No"},
  //   {"name":"officeName", "lable_hi":"कार्यालय का पता","lable_en":"Office Address", "data_en":"officeNameEn","data_hi":"officeNameHi"}
  // ];
  public lan: number = 0;

  estampRegNo: any;
  errMsgEstampRegNo: boolean = false;
  eregRegNo: any;
  errMsgEregRegNo: boolean = false;
  registrationNumber: any;
  errMsgregistrationNumber: boolean = false;


  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  env: string;
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  bannerSlider = {
    "slidesToShow": 1, "slidesToScroll": 1,
    //  autoplay: true, 
    autoplayspeed: 2000, nav: true, loop: true,
    // responsive: [
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 4,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true
    //     }
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 1
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1, 
    //       slidesToScroll: 1
    //     }
    //   }
    //   // You can unslick at a given breakpoint now by adding:
    //   // settings: "unslick"
    //   // instead of a settings object
    // ]

  };

  slideConfig = {
    "slidesToShow": 7, "slidesToScroll": 1, autoplay: true, autoplayspeed: 2000, nav: true, loop: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]

  };
  // slideConfigMinister = {"slidesToShow": 1, "slidesToScroll": 1, autoplay: true, autoplayspeed: 2000, nav: true, loop:true};


  slideConfigPartners = {
    "slidesToShow": 7, "slidesToScroll": 1, "autoplay": true, "infinite": true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };


  slideConfigNews = {
    "slidesToShow": 6, "slidesToScroll": 1, "autoplay": true, "infinite": true, "vertical": true, "autoplaySpeed": 1000, "arrows": false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: false,
          autoplay: true,
          arrows: false,
          prevArrow: false,
          nextArrow: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          autoplay: true,
          arrows: false,
          prevArrow: false,
          nextArrow: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          autoplay: true,
          arrows: false,
          prevArrow: false,
          nextArrow: false,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };




  slickInit(e: any) {

  }


  constructor(private defaultServiceService: DefaultServiceService, private router: Router, private translateService: TranslateHEService, private appSettingsService: AppSettingService,
    @Inject(DOCUMENT) private _document: Document, private common: CommonService, private translate: TranslateService, public commonService: CommonService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg;
      this.translate.use(msg == 1 ? 'hi' : 'en')
    });

  }

  ngOnInit(): void {

    this.defaultServiceService.setDefaultType('home');
    //sessionStorage.setItem("selectedLanguage","hi")
    //if(sessionStorage.getItem("token")!=null || sessionStorage.getItem("token")!="")
    //{
    //this.authService.logout();
    // }
    // this.redIcon = L.icon({
    //   iconUrl: './assets/images/marker-icon-red.png',
    //   shadowUrl: './assets/images/marker-shadow.png',
    // });
     this.settings = this.commonService.getAppSetting();
  this.env = this.settings.env;

  }

  searchRedirect() {
    this.router.navigate([]).then(result => {
      window.open(this.settings.searchBaseUrl, '_blank');
    });
  }

  searchRedirectVerifyCertified() {
    this.router.navigate([]).then(result => {
      window.open(this.settings.searchBaseUrl, +'VerifyCertifiedCopy' + '_blank');
    });
  }

  searchRedirectVerifyEstamp() {
    if (this.estampRegNo != undefined && this.estampRegNo != '') {
      this.errMsgEstampRegNo = false;
      this.router.navigate([]).then(result => {
        window.open(this.settings.searchBaseUrl + '/#/VerifyEstamp?type=' + this.estampRegNo, '_blank');
      });
    } else {
      this.errMsgEstampRegNo = true;
      this.router.navigate([]).then(result => {
        window.open(this.settings.searchBaseUrl + '/#/VerifyEstamp', '_blank');
      });
    }

  }


  searchRedirectVerifyEregistry() {
    if (this.eregRegNo != undefined && this.eregRegNo != '') {
      this.errMsgEregRegNo = false;
      this.router.navigate([]).then(result => {
        window.open(this.settings.searchBaseUrl + '/#/VerifyRegdocument?type=' + this.eregRegNo, '_blank');
      });
    } else {
      this.errMsgEregRegNo = true;
      this.router.navigate([]).then(result => {
        window.open(this.settings.searchBaseUrl + '/#/VerifyRegdocument', '_blank');
      });
    }
  }

  
  
  searchRedirectVerifyCertifiedEregistryNo() {
    if (this.registrationNumber != undefined && this.registrationNumber != '') {
      this.errMsgregistrationNumber = false;
      this.router.navigate([]).then(result => {
        window.open(this.settings.searchBaseUrl + '/#/VerifyCertifiedCopy?type=' + this.registrationNumber, '_blank');
      });
    } else {
      this.errMsgregistrationNumber = true;
      this.router.navigate([]).then(result => {
        window.open(this.settings.searchBaseUrl + '/#/VerifyCertifiedCopy', '_blank');
      });
    }

  }

srOfficeDis(){
  
   window.open(this.settings.baseUrl + '/#/SROfficeDisplay', '_blank');
}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { DutyCalculationRequest } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-calculation-request';
import { map } from 'rxjs/operators';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class DutyService {

  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient,private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }


  getAllDeedCategory() {
    //console.log(" calling getAllDeedCategory service ");
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/duty/allDeedCategory"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  getAllDeedTypeByCategoryId(categoryId:any){
    
    let encVal = this._AESEncryptDecryptService.encrypt(categoryId);
    return this.customeHttpClient
    .Get<ResultModel<any>>(
     // this.settings.igrs_services_duty_url+"common/duty/getAllDeedTypeByCategoryId?deedCategoryId="+categoryId
      this.settings.igrs_services_duty_url+"common/duty/getAllDeedTypeByCategoryId?deedCategoryId="+encVal
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  } 

  getAllDeedInstrumentsByDeedTypeId(deedTypeId:any){

    let encVal = this._AESEncryptDecryptService.encrypt(deedTypeId);
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/deedInstruments?deedTypeId="+encVal
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
  /*
  @owner - Abhay Rai @date - 06-10-2022
  @desc - This function write for get all duty instrument mapping field by instrument id
  */
  getAllInstrumentMappingFieldsByInstrumentId(instrumentId:any){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
     // this.settings.igrs_services_duty_url+"common/duty/dutyInstrumentFieldMapping/"+instrumentId
     this.settings.igrs_services_duty_url+"common/duty/getAllInstMappingById/"+instrumentId
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
/*
  @owner - Abhay Rai @date - 10-10-2022
  @desc - This function write for get all duty instrument mapping field by instrument id
  * saveDutyInstFieldMappingDetail
  */
  saveDutyInstFieldMappingDetails(dutyInstFieldMappingDetailsList: any) {
    console.error("we are inside for saving inst field mapping");
    return this.customeHttpClient
        .Post<ResultModel<any>>(
            this.settings.igrs_services_duty_url + "common/duty/saveDutyInstFieldMappingDetail",
            dutyInstFieldMappingDetailsList
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {
  
                    return error;
                }
            )
        );
  }
  /*
  @owner - Abhay Rai @date - 12-10-2022
  @desc - This function write for get all duty instrument mapping field by instrument id
  * saveDutyInstFieldMappingDetail
  */
  deleteDutyInstFieldMappingDetails(dutyInstFieldMappingModel: any) {
    console.log("we are inside for delete inst field mapping= "+dutyInstFieldMappingModel.id);
    return this.customeHttpClient
        .Post<ResultModel<any>>(
            this.settings.igrs_services_duty_url + "common/duty/deleteDutyInstFieldMappingDetails",
            dutyInstFieldMappingModel
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {
  
                    return error;
                }
            )
        );
  }
  getAllInstMappingById(instrumentId:number){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/getAllInstMappingById/"+instrumentId
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
  
  getExemptionMappingByInstId(instrumentId:number){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/getExemptionMappingByInstId/"+instrumentId
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  getInstrumentDetailsByText(lang:any,text:any){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/searchInstrumentByText?lang="+lang+"&text="+text
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  getInstrumentDetailsById(instrumentId:any){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/searchInstrumentById/"+instrumentId
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  

  getCalculationDataByInstId(instrumentId:number){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/getCalculationDataByInstId/"+instrumentId
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  getAllMinerals() {
    //console.log(" calling getAllDeedCategory service ");
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/duty/getAllMinerals"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  

  calculateDuty(dutyObj:DutyCalculationRequest){
    console.log(dutyObj);
    return this.customeHttpClient
    .Post<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/dutyCalculation",dutyObj
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  } 


  calculateDutyInitiation(dutyObj:DutyCalculationRequest){
    console.log(dutyObj);
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"citizen/duty/dutyCalculation",dutyObj
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  } 




  validatePropertyValId(valId:number){
        console.log("valId: ",valId);
        return this.customeHttpClient
        .Get<ResultModel<any>>(
          this.settings.igrs_services_pv_url+"common/pv/validatePropertyValId?valId="+valId
        ).pipe(
          map((result:any)=>{
            return result;
          })
        )

  }

  // Created By Abhay Rai  Date :: 13-10-2022
  getAllDeedInstrumentDetails(pageno: any,iDisplayLength:any,active:any,direction:any,search:any,deedCategoryId:any,deedTypeId:any) {
    // let param = {
    //   "pageno": pageno,
    //   "iDisplayLength": iDisplayLength,
    //   "active": active,
    //   "direction": direction
    // }
    //console.log(" calling getAllDeedInstrumentDetails service ");
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/duty/getAllDeedInstrumentDetails?pageno="+pageno+"&active="+active+"&direction="+direction+"&search="+search+"&deedCategoryId="+deedCategoryId+"&deedTypeId="+deedTypeId+"&iDisplayLength="+iDisplayLength
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  postMovPropFile(fileToUpload: File) {
   
    const formData: FormData = new FormData();
    formData.append('movPropImage', fileToUpload, fileToUpload.name);
    return this.customeHttpClient
        .Post<ResultModel<any>>(
            this.settings.igrs_services_duty_url + "common/duty/saveMovPropFile",
            formData
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {
                  console.error("error saving Mov Prop File");
                    return error;
                }
            )
        );
  }

  //s
  exemptionDetails() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/getallexemption/",''
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
      }
  updateExemptionById(exemption:any){
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url +`department/master/exemption/`,exemption).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  newExemption(exemption:any){
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+'department/master/savenewexemption',exemption
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  getDutyDetlsByDutyId(dutyId:number){
    
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_duty_url+"common/duty/getDutyDetlsByDutyId/"+dutyId
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
  getAllMineralList(pageNo:number,pageSize:number,param:any) {
  
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/master/fetchAllMinerals/"+pageNo+"/"+pageSize,param
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
      
  }
  addMineral(mineral:any){
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+'department/master/addMineral',mineral
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
  getMineralByid(id:any) {
    let param={id:id}
     return this.customeHttpClient
      .GetApi<ResultModel<any>>(
          this.settings.igrs_services_url + "department/master/getMineralByid",param
      ).pipe(
          map((result: any) => {
  
                  return result;
  
              })
          );
  }
  updateMineralById(mineral:any){
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+'department/master/updateMineral',mineral
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
}

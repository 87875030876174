import { Injectable, OnInit } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { AppSetting, AppSettingService } from '../app.setting';
@Injectable({
  providedIn: 'any'
})
export class AESEncryptDecryptService {

  private settings!: AppSetting;

  passPhrase:any;
  keySize:number=128;
  iterationCount:number=1000;
  iv:any;
  salt:any;
  key:any;
  Key_IV:any;
  PASSWORD:any;
  SALT:any;
   
  constructor(private appSettingsService: AppSettingService) { 

    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
   
      this.settings = settings;
    });

    this.passPhrase=this.settings.AESEncryptDecrypt.passPhrase;
    this.Key_IV=this.settings.AESEncryptDecrypt.Key_IV;
    this.PASSWORD=this.settings.AESEncryptDecrypt.PASSWORD;
    this.SALT=this.settings.AESEncryptDecrypt.SALT;

    this.iv = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
    this.salt = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex);
    this.keySize = this.keySize / 32;
    this.iterationCount = this.iterationCount;
    this.key = CryptoJS.PBKDF2(this.passPhrase,  CryptoJS.enc.Hex.parse(this.salt),
      { keySize: this.keySize, iterations: this.iterationCount });
  }

  encrypt(value : string) : string{
    value=""+value;
      let encrypted = CryptoJS.AES.encrypt(
        value,
          this.key,
          { iv: CryptoJS.enc.Hex.parse(this.iv) });
       encrypted.ciphertext.toString(CryptoJS.enc.Base64);

    let aesString = (this.iv + "::" + this.salt + "::" + encrypted);
    let encryptedString = btoa(aesString);
    
	return encryptedString;
  }
 
  decrypt(textToDecrypt : string):any{
    //return atob(CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8));
    return null;
  }


  encoder(str:any) {
    let encoder = new TextEncoder();
    let byteArray = encoder.encode(str)
    return CryptoJS.enc.Utf8.parse(str)
  }
  
  toWordArray(str:any) {
    return CryptoJS.enc.Utf8.parse(str);
  }

  encryptInAngular(value:any) {
    var key = CryptoJS.PBKDF2(this.PASSWORD, this.SALT, {
      keySize: 256 / 32,
      iterations: 65536
    })
    var encrypted = CryptoJS.AES.encrypt(this.encoder(value), key, {
      iv: this.toWordArray(this.Key_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  decryptInAngular(value:any) {
    var key = CryptoJS.PBKDF2(this.PASSWORD, this.SALT, {
      keySize: 256 / 32,
      iterations: 65536
    })
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: this.toWordArray(this.Key_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  
  // AesUtil.prototype.decrypt = function(salt, iv, passPhrase, cipherText) {
  //   var key = this.generateKey(salt, passPhrase);
  //   var cipherParams = CryptoJS.lib.CipherParams.create({
  //     ciphertext: CryptoJS.enc.Base64.parse(cipherText)
  //   });
  //   var decrypted = CryptoJS.AES.decrypt(
  //       cipherParams,
  //       key,
  //       { iv: CryptoJS.enc.Hex.parse(iv) });
  //   return decrypted.toString(CryptoJS.enc.Utf8);
  // }
}


import { IgrsSubClausePropertyMappingBean } from "./IgrsSubClausePropertyMappingBean";
import { IndependentBuildingTypeBean } from "./IndependentBuildingTypeBean";
import { MstDemographyBean } from "./MstDemographyBean";
import { MstPropertyTypeBean } from "./MstPropertyTypeBean";
import { PnrdModel } from "./PnrdModel";
import { PropertyOwner } from "./PropertyOwner";
import { PropertyValuationRequestBean } from "./PropertyValuationRequestBean";
import { MPenagarpalikaResponsModel } from "./enagarpalikaModel";


export class BuildingPropertyValuationRequestBean {
	id: number;
	areaType: number;
	totalArea: any;
	isHousingBoard?:boolean=false;
	subClassId:any
	propertyId:[];
	buildingAreaType:string="no";
	isResidensial:boolean=false;
	isCommercial:boolean=false;
	isIndustrial:boolean=false;
	isHealth:boolean=false;
	isEduArea:boolean=false;
	isOthers:boolean=false;
	isResidentialCumCommercial:boolean=false;
    overAllArea:number

	residentialPlotArea:number|string;
	commercialPlotArea:number|string;
	industrialPlotArea:number|string;
	healthPlotArea:number|string;
	eduPlotArea:number|string;
	othersPlotArea:number|string;
	residentialCumCommercialPlotArea:number|string;
	houseFlatNo?:string
	floorTypeResidensialList=new Array();
	floorTypeIndustrialList=new Array();
	floorTypeHealthList=new Array();
	floorTypeEducationList=new  Array();
	floorTypeOtherList=new  Array();
	floorTypeCommercialList=new  Array();
	independentBuildBean:IndependentBuildingTypeBean[]=new Array();
	floorResidentialCumCommercialList:any[]= new Array();
	eduType: string="school";
    propertySource?:string
	subClause: IgrsSubClausePropertyMappingBean[] = new Array();
	demography: MstDemographyBean = new MstDemographyBean();
	propertyType: MstPropertyTypeBean = new MstPropertyTypeBean();
	propId:string;
	unit:string;
	isIndustrialDevAuth?:any
	isSuperSturct?:any
	//usage: string='Diverted'// Diverted / Un diverted agricultural land
	subType: string; // Irrigated / Un Irrigated agricultural	
	floorType?: number; //building
	constrnType?: string; // building
	constrnTime?: number=null; // building 
	buildingSubType:string;//building 
	liftFacility: boolean;// building
	conveyanceType?: string;//building
	conveyanceArea?: string;//building
	buitupArea?: number;//building multistory 
	commonArea?: number;//building multistory 
	transactOpenTerrace?: boolean;//building
	buildingType: string='Residential';//building multistory
	//Is there any construction done on the Agriculture land
	//agriculturalWithConstruction: AgriPropertyValuationRequestBean
	//building multistory Commercial property Type
	multistoryCommercialPopType: string="Shop";
	//unIrrigatedArea:string|number
	//irrigatedArea:string|number
    //singleCropArea:string|number  
    //doubleCropArea:string|number
    plotAreaType:string=''
    // isFullyTransact:boolean;
    isPartialTransact:boolean=false;
	latitude?:any
	longitude?:any
	northboundary?:string
	southboundary?:string
	westboundary?:string
	eastboundary?:string
    isConstOnAgriLand:boolean=false;
	isPlotOnAgriLand:boolean=false
	partialTransactArea:any
	eregId:number
	ULPin:string
	gisSearchTxt:string
	custodiomDeparementIdArr?=new Array()
	propertyOwner:PropertyOwner[]=new Array();
	builderShare:number;

	
	agriculturalWithConstruction?:BuildingPropertyValuationRequestBean
	mPenagarpalikaResponseData?  :MPenagarpalikaResponsModel
	pNRDResponseData?:PnrdModel
	
	address?:string
	valTxnId:number;
	marketValue:number;
	//isShowValButton:boolean=false
	calculatedValue:number
	identifactionId:number;
	identifiedAddress:string;
	makerRemarksVal:string;
	checkerRemarksVal:string;
	makerRemarksPropAddress:string;
	checkerRemarksPropAddress:string;
	isImpoundAddProperty:boolean;
	isCOSImpoundAddProperty:boolean;
	fromCheckerModule :boolean;
	fromCMSModule :boolean;
	impoundId:number;
	constructor(){
		//alert('cons')
	this.id=null
	this.areaType=null
	this.totalArea=null
	this.isHousingBoard=false;
	this.subClassId=null
	this.plotAreaType=null
	this.buildingAreaType="no";
	this.isResidensial=false
	this.isCommercial=false
	this.isIndustrial=false
	this.isHealth=false
	this.isEduArea=false
	this.isOthers=false
	this.isResidentialCumCommercial=false;
	this.residentialPlotArea=null
	this.commercialPlotArea=null
	this.industrialPlotArea=null
	this.healthPlotArea=null
	this.eduPlotArea=null
	this.othersPlotArea=null
	this.residentialCumCommercialPlotArea=null
	this.floorTypeResidensialList=new Array();
	this.floorTypeIndustrialList=new Array();
	this.floorTypeHealthList=new Array();
	this.floorTypeEducationList=new  Array();
	this.floorTypeOtherList=new  Array();
	this.floorTypeCommercialList=new  Array();
	this.independentBuildBean=new Array();
	this.floorResidentialCumCommercialList= new Array();
	this.eduType=null
	this.subClause = new Array();
	this.demography = new MstDemographyBean();
	this.propertyType = new MstPropertyTypeBean();
	this.unit="SQMT"
	//this.usage='Diverted'
	this.subType=null // Irrigated / Un Irrigated agricultural	
	this.floorType=null //building
	this.constrnType=null // building
	this.constrnTime=null // building 
	this.buildingSubType='Independent Building'//building 
	this.liftFacility// building
	this.conveyanceType//building
	this.conveyanceArea//building
	this.buitupArea//building multistory 
	this.commonArea;//building multistory 
	this.transactOpenTerrace//building
	this.buildingType='Residential';//building multistory
	//Is there any construction done on the Agriculture land
	agriculturalWithConstruction: PropertyValuationRequestBean;
	//building multistory Commercial property Type
	this.multistoryCommercialPopType="Shop";

    this.isPartialTransact=false;
    this.isConstOnAgriLand=false;
  
	this.partialTransactArea=null
    
   
	this.ULPin=null
	this.gisSearchTxt=null
	this.houseFlatNo=null
	this.propertyOwner=null
	this.mPenagarpalikaResponseData=null

	this.isImpoundAddProperty = false;
	this.fromCheckerModule = false;
	this.fromCMSModule = false
	}

}
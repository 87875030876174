import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fingerprint-scanner',
  templateUrl: './fingerprint-scanner.component.html',
  styleUrls: ['./fingerprint-scanner.component.scss']
})
export class FingerprintScannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="row noprint">
    <div class="col-md-12">
        <div *ngIf="errorMsg != ''"
            class="alert alert-danger alert-dismissible  fade show pr-5" role="alert">
            <!-- <strong>{{'error' | translate}}</strong> -->
             {{lan==0?errorMsgEn:errorMsgHi}}
             <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
    </div>
    <div class="col-md-12">
        <div *ngIf="successMsg != ''"
        class="alert alert-success alert-dismissible fade show pr-5" role="alert">
            <!-- <strong>{{'success' | translate}}</strong> -->
            {{lan==0?successMsgEn:successMsgHi}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
    </div>
    <div class="col-md-12">
        <div *ngIf="warnMsg != ''"
        class="alert alert-dismissible fade show pr-5" role="alert" style = "    background-color: #ffff00c9;color: black !important;font-weight: 600;">
            <!-- <strong>{{'success' | translate}}</strong> -->
            {{lan==0?warnMsgEn:warnMsgHi}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
        </div>
    </div>
</div>

 
import { Component, EventEmitter, OnInit, Output, ViewChild ,Input} from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DeedCategoryBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-category-bean';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DeedTypeBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-type-bean';
import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';

import {CategoryLoadComponent} from 'projects/common-lib/src/lib/component/category-load/category-load.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { DatePipe } from '@angular/common';
import { AddEstamp } from '../../models/addEstamp.model/add-estamp.model';
import { json } from '@rxweb/reactive-form-validators';
import { DutyService } from 'projects/front-web/src/app/modules/duty-calculation/duty-service/duty.service';
import { CommunicationServiceService } from 'projects/front-web/src/app/modules/duty-calculation/duty-service/communication-service.service';

@Component({
  selector: 'lib-deed-instrument',
  templateUrl: './deed-instrument.component.html',
  styleUrls: ['./deed-instrument.component.css']
})
export class DeedInstrumentComponent implements OnInit {
  @Input() categoryLoadComponent?: CategoryLoadComponent;
  
  @Output() instMappingEvent = new EventEmitter<any>();
  @Output() instrumentIdEmit = new EventEmitter<any>();
  @Output() showDeedEvent = new EventEmitter<boolean>();
  instrumentId:number;
  @Input() pInstrumentId: string;
  @Input() pDeedCategoryId: string | number;
  @Input() pDeedTypeId: string | number;
  
  @ViewChild(AlertmessageComponent, {static: false}) alertmessage: AlertmessageComponent;
  constructor(private dcService: DutyService, private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, 
    private translateServiceIns: TranslateService, public dialog: MatDialog,private commServ: CommunicationServiceService,
    private router: Router,private _AESEncryptDecryptService: AESEncryptDecryptService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe) {
    this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
  }
  lan: any;
  estampSearch:AddEstamp=new AddEstamp();
  onChangeofOptions(newGov: any) {
    console.log(newGov);
 }
clearSelection() {
 
}

  ngOnInit(): void {
    this.getAllDeedCategoryList();
    this.getAllDeedTypeByCategoryId(this.pDeedCategoryId);
    this.getAllDeedInstrumentsByDeedTypeId(this.pDeedTypeId);
    this.estampSearch.transactionBehalf=false;
    console.log("pInstrumentId-----------------------"+this.pInstrumentId);
    console.log("pDeedCategoryId-----------------------"+this.pDeedCategoryId);
    console.log("pDeedTypeId-----------------------"+this.pDeedTypeId);
    this.getAllInstrumentMappingFieldsByInstrumentId(this.pInstrumentId);
   
   
  }
  deedCategoryBeanList: DeedCategoryBean[] = new Array();
  deedTypeCategoryBeanList: DeedTypeBean[] = new Array();
  deedInstrumentBeanList: DeedInstrumentBean[] = new Array();
  dutyInstFieldMappingBeanList: InstMappingBean[] = new Array();

  getAllDeedCategoryList(){
    console.log("Inside getAllDeedCategoryList");
    this.ngxService.start();
    this.dcService.getAllDeedCategory().subscribe(dcdata => {

      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        console.log("getAllDeedCategory -subscribe..... ");
        this.deedCategoryBeanList = dcdata.responseData;
      }else{
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(dcdata);
		}
     
    },
    (err) => {
    
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage('tech_error_msg');
    });

  }
  getAllDeedTypeByCategoryId(deedCategoryIdSelcted:any){
    this.ngxService.start();
    console.log('deedCategoryIdSelcted',deedCategoryIdSelcted);
    this.dcService.getAllDeedTypeByCategoryId(deedCategoryIdSelcted).subscribe(dcdata => {
      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        console.log("getAllDeedTypeByCategoryId -subscribe..... "+JSON.stringify(dcdata.responseData));
        this.deedTypeCategoryBeanList = dcdata.responseData;
      }else{
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(dcdata);
		}
     
    },
    (err) => {
    
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage('tech_error_msg');
      
    });

  }
  getAllDeedInstrumentsByDeedTypeId(deedTypeIdSelected:any){
    console.log("deedTypeIdSelected ::"+deedTypeIdSelected);
    this.ngxService.start();
    console.log(deedTypeIdSelected);
    this.dcService.getAllDeedInstrumentsByDeedTypeId(deedTypeIdSelected).subscribe(dcdata => {
      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        console.log("getAllDeedInstrumentsByDeedTypeId -subscribe..... ");
        this.deedInstrumentBeanList = dcdata.responseData;
      }else{
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(dcdata);
		}
     
    },
    (err) => {
    
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage('tech_error_msg');
    
    });
  }

  getAllInstrumentMappingFieldsByInstrumentId(instrumentIdSelected:any){
    this.instrumentId = instrumentIdSelected;
    this.ngxService.start();
    console.log(instrumentIdSelected);
    this.dcService.getAllInstrumentMappingFieldsByInstrumentId(instrumentIdSelected).subscribe(dcdata => {
      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        console.log("getAllInstrumentMappingFieldsByInstrumentId -subscribe..... ");
        this.dutyInstFieldMappingBeanList = dcdata.responseData;
        this.instMappingEvent.emit(this.dutyInstFieldMappingBeanList);
        this.instrumentIdEmit.emit(this.instrumentId);
      
      }else{
        this.ngxService.stop();
		}
     
    },
    (err) => {
    
      this.ngxService.stop();
     
    
    });
  }

 
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { MasterOfficeModel } from 'projects/common-lib/src/lib/models/api.masterdata.model/office.model';
import { UserProfileModel } from 'projects/common-lib/src/lib/models/auth.userdetail.model/user.profile.model';
import { Subscription, timer } from 'rxjs';
//import { UserManagementServive } from '../../../../../projects/admin-web/src/app/services/user.services';
import { CitizenForgot } from 'projects/common-lib/src/lib/models';
import { UserManagementServive } from '../../service/UserManagementServive';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';


@Component({
  selector: 'app-mobile-verify',
  templateUrl: './mobile-verify.component.html',
  styleUrls: ['./mobile-verify.component.scss']
})
export class MobileVerifyComponent implements OnInit {
  otpOption:boolean=true;
  resendbtn:boolean=false;
  verifybtn:boolean=true;
  userProfileModel: UserProfileModel=new UserProfileModel();
  countDown:Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec
  verifyMobileForm: FormGroup = new FormGroup({});
  submitted = false;
  citizenForgot: CitizenForgot = new CitizenForgot();
  @ViewChild(AlertmessageComponent, {static: false}) alertmessage: AlertmessageComponent;

  constructor(private userService: UserManagementServive,private formBuilder: FormBuilder,private _AESEncryptDecryptService: AESEncryptDecryptService, private router: Router, private authenticationService: AuthenticationService) { 
    this.verifyMobileForm = this.formBuilder.group({
      otp: ['', [Validators.required]],
    });

  }

  ngOnInit(): void {
    this.userProfileModel =new UserProfileModel();
   
    this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
    if (!sessionStorage.getItem('otpsend')) { 
      let email=this.userProfileModel.email;
      
      sessionStorage.setItem('otpsend', ""+email); 
      
      this.sendOTP(this.userProfileModel.email);
    } else {
      
      if(sessionStorage.getItem('otpsend')!=this.userProfileModel.email){
        this.sendOTP(this.userProfileModel.email);
      }
     
    }
  

  
  }
    // convenience getter for easy access to form fields
    get f() { return this.verifyMobileForm.controls; }

  sendOTP(username:any){
    this.citizenForgot.userName = username;
    this.citizenForgot.userType = 'department';
    this.userService.generateOtpForUser(this.citizenForgot).subscribe(userdata=>{
      if (userdata.responseStatus == 'true') {
        this.verifyMobileForm.controls['otp'].setValue('');
        this.submitted = false;
        this.counter = 30; // 3 min 3*60
        this.countDown = timer(0, this.tick).subscribe(() => {
          if (this.counter > 0) {
            --this.counter;
          }
        })
      this.alertmessage.successAlertMessage(userdata);
      }    
      else {
        this.alertmessage.errorAlertMessage(userdata);
        if(userdata.responseMessageEn=="User details not found"){
           this.clearsessionStorage();
        }
        // this.clearsessionStorage();
      }
    });
  }

  onSubmitUserNameForOtp() { 

    this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
    this.sendOTP(this.userProfileModel.email);
  
  }

  backBtnClick() {
    this.authenticationService.logout().subscribe(res => {
      res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
    });
  }
  otpEnter(){
    this.verifybtn=false;
  }

  onSubmit(){
    this.submitted=true;
    
    if (this.verifyMobileForm.invalid) {

      return;
    }
    this.resendbtn=true;
    this.verifybtn=true;
    let uid=this.userProfileModel.id;
    let userid=this._AESEncryptDecryptService.encrypt(""+uid);
     let param={otp:this.verifyMobileForm.get("otp")?.value,user_id:userid,status:"true"};
     this.userService.verifyFirstTimeOpt(param).subscribe(data=>{
      if (data.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(data);
        this.userProfileModel.mobileVerifyStr='true';
        sessionStorage.setItem("authData", JSON.stringify(this.userProfileModel));
        // sessionStorage.setItem('isUserLoggedIn','true');
        let selectedOffice: MasterOfficeModel = JSON.parse(sessionStorage.getItem('selectedOffice') || "");
        if (selectedOffice != undefined && selectedOffice != null) {
          // console.log('selectedOffice: ', selectedOffice);
          if(selectedOffice.department.isLdap==1 && this.userProfileModel.emailVerifyStr!='true'){
            this.router.navigate(['./login-kyc']);
          } else {
            this.router.navigate(['./admin/dashboard']);
          }
        } else {
          // console.log('sessionStorage.selectedOffice: ', sessionStorage.getItem('selectedOffice'));
        }
        
        
        }    
        else {
          this.resendbtn=false;
          this.verifybtn=false;
          this.alertmessage.errorAlertMessage(data);
        }
     })
  }

  clearsessionStorage() {
    //
 
    if (sessionStorage.length > 0) {
      sessionStorage.removeItem('isUserLoggedIn');
      sessionStorage.removeItem("loginType");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("token_data");
      sessionStorage.removeItem("expires_at");
      sessionStorage.removeItem("ud");
      sessionStorage.removeItem("featureList");
      sessionStorage.removeItem("officeList");
      sessionStorage.removeItem("selectedOffice");
      sessionStorage.removeItem('authData');
    }
    if (window.parent.sessionStorage.length > 0) {
      window.parent.sessionStorage.removeItem('isUserLoggedIn');
      sessionStorage.removeItem("loginType");
      window.parent.sessionStorage.removeItem("token");
      window.parent.sessionStorage.removeItem("token_data");
      window.parent.sessionStorage.removeItem("expires_at");
      window.parent.sessionStorage.removeItem("ud");
      window.parent.sessionStorage.removeItem("featureList");
      window.parent.sessionStorage.removeItem("officeList");
      window.parent.sessionStorage.removeItem("selectedOffice");
      window.parent.sessionStorage.removeItem("authData");
    }
    //this.currentUserSubject.next(null as any);
    this.router.navigate(['/login']);



  }
}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PropertyValuationService } from '../property-valuation/property-valuation.service';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogData } from 'projects/common-lib/src/lib/component/new-show-maket-value/new-show-maket-value.component';
import { MannualBuildingComponent } from './enagarpalika/mannual.building.component';
import { MannualPlotComponent } from './pnrd/mannual.plot.component';
@Component({
  selector: 'app-mannual-process-property-identifation',
  templateUrl: './mannual-process-property-identifation.component.html',
  styleUrls: ['./mannual-process-property-identifation.component.scss']
})
export class MannualProcessPropertyIdentifationComponent implements OnInit,AfterViewInit {
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild(MannualBuildingComponent,{ static: false }) mannualBuildingComponent:MannualBuildingComponent
  @ViewChild(MannualPlotComponent,{ static: false }) mannualPlotComponent:MannualPlotComponent
  guildeLineObj: any;
  lan:number=1

  private settings!: AppSetting;
  selectedMstDemographyBean: any;
  constructor(public matDialogRef: MatDialogRef<MannualProcessPropertyIdentifationComponent>,
     private translateServiceIns: TranslateService,
    private translateService: TranslateHEService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private appSettingsService: AppSettingService,
    private pvService: PropertyValuationService,
    private ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public responseData: DialogData,) { 
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })

    }
  ngAfterViewInit(): void {
    console.log("Service data",this.responseData)
    this.mannualBuildingComponent.setPropertyObectdata(this.responseData)
    this.mannualPlotComponent.setPropertyObectdata(this.responseData)
  }

  ngOnInit(): void {

  }
 
  emitTOMannualProcessComponent(PropertyObject:any,propertTypeId:number){
    this.mannualBuildingComponent.changePropertyType(propertTypeId)
    this.mannualPlotComponent.changePropertyType(propertTypeId)
    this.matDialogRef.close(PropertyObject)
  }

  
  
  onSubmit(){
    this.mannualBuildingComponent.onSubmit()
    this.mannualPlotComponent.onSubmit()
  }
    
  close(){
    this.matDialogRef.close()
   }
  
}

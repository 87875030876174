<a class="margright-50 float-right clbtn" value="Cancel" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
</a>
<!DOCTYPE html>
<html lang="en">

<head>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="">

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Ubuntu:wght@400;500&display=swap"
        rel="stylesheet">


    <title>Registration and Stamp Department</title>
    <lib-alertmessage #alertMessage></lib-alertmessage>

    <!--    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css" integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossorigin="anonymous">-->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Roboto:wght@300;400;500&display=swap"
        rel="stylesheet">
    <style>
        .td-col:nth-child(1) {
            display: none;
        }

        .td-col:nth-child(2) {
            display: none;
        }

        .td-col:nth-child(4),
        .td-col:nth-child(5) {
            display: none;
        }

        * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        table {
            border-collapse: collapse;
        }

        body {
            /*        font-family: 'Open Sans', sans-serif;*/
            font-size: 16px;
            padding: 5px;
            font-family: "Open Sans", sans-serif;
            font-family: "Roboto", sans-serif;
            color: #767676;

            /* filter: grayscale(100%); */
            background-size: 10%;
            border: none;
            font-weight: 500;
            /* width: 857px !important; */
        }

        b,
        strong {
            font-weight: 500;
        }

        th {
            background: rgba(0, 0, 0, 0.3);
        }

        tfoot {
            background: #cdcdcd;
            color: #000;
        }

        table tr th,
        table tr td {
            border-collapse: collapse;
            /* border: 1px solid #CCC; */
            padding: 4px;
            font-weight: 500;
            word-break: break-all;
        }

        .col-md-4 {
            width: 33.3%;
        }

        .card-title {
            font-size: 1.2rem !important;
            color: #4682b4 !important;
            font-weight: 500 !important;
            border-left: 3px solid #ff9800;
            padding-left: 10px;
        }

        .form-control {
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: var(--bs-gray-900);
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #335e89;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .form-control:disabled,
        .form-control[readonly] {
            background-color: #e9ecef;
            opacity: 1;
        }

        label {
            display: inline-block;
            margin-bottom: 0.5rem;
        }

        /* .fid-ht {
            height: 400px;
            overflow: scroll;
        } */

        .d-flex {
            display: flex;
        }

        .new-footer td {
            border: 0px !important;
        }

        .input-group-append {
            right: 15px;
        }

        @media print {
            .container {
                max-width: 100%;
                padding: 0 1rem;
            }

            /* body {
                background-image: url(./assets/images/sampada-logo11.png);
                filter: grayscale(100%);
                background-size: 10%;
            } */

            table,
            td,
            th {
                border: 1px solid #ddd;
                text-align: left;
            }

            table {
                border-collapse: collapse;
                width: 100%;
            }

            th,
            td {
                font-size: 14px;
                padding: 15px;
            }

            .row {
                display: flex;

                margin-right: -15px;
                margin-left: -15px;
            }

            .col-xl-3 {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .d-flex {
                display: flex !important;
            }

            .col-xl-3 {
                position: relative;

                padding-right: 15px;
                padding-left: 15px;
            }

            .form-group {
                margin-bottom: 1rem;
            }

            fieldset {
                margin-bottom: 10px;
            }

            .cardprint:last-child {
                page-break-after: auto;
            }

            .name {
                font-size: 20px;
            }

            .font-weight-bold {
                font-weight: 600;
            }
        }

        @media screen,
        print {

            table,
            td,
            th {
                border: 1px solid #252525;
                text-align: left;
            }

            table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
            }

            th,
            td {
                padding: 15px;
            }

            .row {
                display: flex;

                margin-right: -15px;
                margin-left: -15px;
            }

            .col-xl-3 {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .d-flex {
                display: flex !important;
            }

            .col-xl-3 {
                position: relative;

                padding-right: 15px;
                padding-left: 15px;
            }

            .form-group {
                margin-bottom: 1rem;
            }

            fieldset {
                margin-bottom: 10px;
            }

            .cardprint:last-child {
                page-break-after: auto;
            }

            .bg-grey {
                background: #eee;
            }

            .fid-ht {
                margin-top: -20px;
            }

            /* .bg_new {
                background-image: url(/assets/images/sampada-logo11.png);
            } */
        }
        .new-clsapp{ padding: 0px 30px; margin-top: 10px;}
        *,
            *::before,
            *::after {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }

            body {
                height: 100%;
                overflow-x: hidden;
                z-index: 0;
                position: relative;
                margin: 0;
            }

            body,
            html {
                font-family: "Lora", serif;
            }

            .container {
                width: 1280px;
                margin: 0 auto;
            }

            .p-right {
                width: 271px;
                float: right;
                display: block;
                clear: both;
            }

            td,
            th {
                padding: 4px 10px 5px;
            }

            h3 {
                font-weight: 500;
                margin: 0;
                font-size: 1.2em;
            }

            @page {
                size: 21cm 29.7cm;
                margin: 50px;
            }

            p {
                line-height: 1.3;
                text-align: justify;
                background: transparent;
                margin: 0;
            }

            @media print {
                page {
                    padding: 15px;
                }

                body {
                    font-size: 15px;
                    margin: 15px;
                    padding: 15px;
                }

                .container {
                    width: 100%;
                    margin: 0 auto;
                }

                td,
                th {
                    font-size: 15px;
                    line-height: normal;
                    padding: 4px 8px 5px !important;
                }

                h3 {
                    font-weight: 500;
                    margin: 0;
                }

                .logo {
                    width: 85px !important;
                }

                p {
                    line-height: 1.3;
                    text-align: justify;
                    background: transparent;
                    margin: 0;
                }

                h3 {
                    font-weight: 500;
                    margin: 0;
                    font-size: 1.2em;
                }

                body,
                html {
                    padding: 12px;
                }
            }
    </style>
</head>

<body style="padding:0px; ">
    <!-- <img title="Print TS" style="cursor: pointer;" alt="Print" onclick="window.print();"  th:src="@{/img/print.png}"/> -->
    <!-- <button class="btn btn-primary mr-3 m-1" type="button" id="print-card" onclick="onBtnPrintClick()"
                [mat-dialog-close]="false">
                {{'property-valuation.Print' | translate}}</button> -->
    <!-- <div class="card cardprint"> -->
  <div>
    <div style="text-align: left">

    </div>
    <!-- <div class="printPdf" id="printSPLicencePdf">  -->
    

    <div class="modal-body">
      <iframe id="deedEsignViewer" style="height: 100vh;width: 100%;" frameborder="0"></iframe>
    </div>


  </div>
    <div class="col-md-12 float-right p-0">
        <div class="align-items-right float-right input-group-append m-0 mb-1 text-right ">
            <button (click)="onBtnPrintClick()" target="_blank" class="btn btn-primary m-0 d-print-none mr-2 mt-1 ml-1">
                {{'print' | translate}}
            </button>

        </div>
    </div>

</body>

</html>

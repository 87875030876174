import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'amount'
})
export class AmountPipe implements PipeTransform {
    transform(input: number) {
        if (input) {

            let inputStr = input?.toString();
            let containsMinus = false;
            var currencySymbol = '₹';
            //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!
            if(inputStr.charAt(0)=='-') {
                inputStr = inputStr.substring(1);
                containsMinus = true
            }          
            var result = inputStr.split('.');
            if (result != undefined) {
                var lastThree = result[0]?.substring(result[0].length - 3);
                var otherNumbers = result[0]?.substring(0, result[0].length - 3);

                if (otherNumbers != '')
                    lastThree = ',' + lastThree;

                var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

                if (result.length > 1) {
                    output += "." + result[1];
                }
            }
            return currencySymbol + (containsMinus ? '-'+output : output);
        }
        else {
            return output ?? 0
        }
    }
}
export class MstPropertyTypeBean{
    id:number
	nameEn:string;
	nameHi:string;
	parentPropertType?:MstPropertyTypeBean;
	parentId?:number;
	refNo?:string
	status?:number
	modifiedDate?:string
	createdDate?:string
	
}
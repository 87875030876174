import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { EstampService } from '../e-stamp/e-stamp-service/estamp.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';


import { AlertmessageComponent } from '../alertmessage/alertmessage.component';

import { IgrsEregTxnDetlsBean } from '../../models/e_registry/e-registry.model';

import { EstampDeedContent } from '../../models/estamp.model/estamp-deed-content';
import { AddEstamp, EstampCommonBean } from '../../models/addEstamp.model/add-estamp.model';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { EregistryService } from 'projects/front-web/src/app/service/eregistry.service';
import { EstampService } from 'projects/front-web/src/app/service/estamp.service';
import { DeedTemplateComponent } from '../../page/deed-template/deed-template.component';
import { EregDeedTemplateComponent } from '../../page/ereg-deed-template/ereg-deed-template.component';
import { MstStatusBean } from '../../models/serviceprovider/MstStatusBean.model';
import { TranslateHEService } from '../../helper-lib/services/common/translateHE.service';
import { AppSettingService } from '../../helper-lib/app.setting';

export interface DeedContentHistory {
  id: number;
  deedContent: string;
  estampId: number;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  contentStatus: number;
  eregFlag: number;
  deedFileName: string;
  month: string;
  year: string;
  igrsEstampPartyStatusDetailsList?: PartyActionHistory[] | MatTableDataSource<PartyActionHistory>;
}

export interface PartyActionHistory {
  id: number;
  estampId: string;
  estampPartyId: string;
  status: number;
  createdBy: number;
  createdDate: string;
  modifiedBy: number;
  modifiedDate: string;
  remarks: string;
  partyName: string;
  firstNameEn: string;
  firstNameHi: string;
  middleNameEn: string;
  middleNameHi: string;
  lastNameEn: string;
  lastNameHi: string;
  eregFlag: number;
  statusNameEn: string;
  statusNameHi: string;

}



@Component({
  selector: 'deed-content-history',
  styleUrls: ['./deed-content-history.component.css'],
  templateUrl: './deed-content-history.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class DeedContentHistoryComponent {
  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<PartyActionHistory>>;
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;

  dataSource: MatTableDataSource<DeedContentHistory>;

  igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
  deedContentHistoryData: DeedContentHistory[] = [];
  columnsToDisplay = [
    'sno',
    'createdDate',
    'contentStatus',
    'contentStatusRemarks',
    'estampId',
    'id',
    'deedContent',
    'actions'

  ];
  innerDisplayedColumns = ['sNo', 'partyName', 'createdDate', 'status', 'remarks'];
  expandedElement: DeedContentHistory | null;
  estampId: string;
  deedContent: string;
  deedContentId: number;
  estampIdForTemplate: number;
  estampDeedContent: EstampDeedContent = new EstampDeedContent();
  estampSearch: AddEstamp;
  base64DeedPdf: any;
  lan: any;
  isActionButtonShow: boolean = false;

  // eregFlag:number;
  constructor(
    private sanitizer: DomSanitizer, private modalService: NgbModal,
    private cd: ChangeDetectorRef, private ngxService: NgxUiLoaderService, private estampService: EstampService,
    private router: Router, public dialog: MatDialog, private eRegService: EregistryService, private translateServiceIns: TranslateService
    , config: NgbModalConfig, private translate: TranslateService,

    private translateService: TranslateHEService, private appSettingsService: AppSettingService
  ) {
    try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }

      // translate.use(selectedLanguage).toPromise();
      // this.lan = selectedLanguage == 'hi' ? 1 : 0;

      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg == 1 ? 'hi' : 'en')
      }
      );
    } catch (err) {
      console.log(err);
    }

    config.backdrop = 'static';
    config.keyboard = false;

  }

  @Input() estampIdInput: string;
  @Input() eregFlag: any;
  @Input() isDeedLinkInput: any;

  @Input('partyId') partyId: any
  @Input('userId') userId: any;
  // @Input('estampId') estampId:any
  @Input('partyStatus') partyStatus: any
  @Input('mobileNo') mobileNo: any
  @Input('partyType') partyType: number
  @Input('partySubType') partySubType: number
  @Input('estampContentStatus') estampContentStatus: number
  @Input('natureOfInstrument') natureOfInstrument: string
  @Input('deedAcceptLink') deedAcceptLink: string

  inputPartyId: any;

  statusCheck: boolean = true

  ngOnInit() {
    this.inputPartyId = this.partyId;
    //console.log("deed-content-history :: partyId:-"+this.partyId)
    if (this.estampContentStatus === 11 && this.partySubType === 1) {
      this.statusCheck = false
    } else if (this.estampContentStatus === 11 && (this.partySubType === 2 || this.partySubType === 5)) {//change on 16-11-2023 according to Nitin sir case by Bilateral/Unilateral
      this.statusCheck = false
    }
    else if (this.estampContentStatus === 11 && (this.partySubType === 3 || this.partySubType === 4 || this.partySubType === 0) && this.eregFlag === 1) {
      this.statusCheck = true
    }
    this.getDeedStatus();
    this.getpartyContentStatus();
    this.estampId = this.estampIdInput
    this.getAllDeedById();
    this.getEstampDetailsById();

    // if(this.eregFlag==undefined){
    //   this.eregFlag=0;
    // }
  }



  toggleRow(element: DeedContentHistory) {
    element.igrsEstampPartyStatusDetailsList &&
      (element.igrsEstampPartyStatusDetailsList as MatTableDataSource<PartyActionHistory>).data.length
      ? (this.expandedElement =
        this.expandedElement === element ? null : element)
      : null;
    this.cd.detectChanges();
    this.innerTables.forEach(
      (table, index) =>
      ((table.dataSource as MatTableDataSource<PartyActionHistory>).sort =
        this.innerSort.toArray()[index])
    );
  }

  getAllDeedById() {

    this.ngxService.start();
    this.estampService.getDeedAndPartyHistoryByEstampId(Number(this.estampId)).subscribe(res => {
      if (res.responseStatus == 'true') {
        this.ngxService.stop();

        res.responseData.forEach((deedContentHistory: DeedContentHistory) => {
          if (
            deedContentHistory.igrsEstampPartyStatusDetailsList &&
            Array.isArray(deedContentHistory.igrsEstampPartyStatusDetailsList) &&
            deedContentHistory.igrsEstampPartyStatusDetailsList.length
          ) {
            //console.log("Inside if");
            this.deedContentHistoryData = [
              ...this.deedContentHistoryData,
              {
                ...deedContentHistory,
                igrsEstampPartyStatusDetailsList: new MatTableDataSource(
                  deedContentHistory.igrsEstampPartyStatusDetailsList
                ),
              },
            ];
          } else {
            this.deedContentHistoryData = [...this.deedContentHistoryData, deedContentHistory];
          }
        });
        this.dataSource = new MatTableDataSource(this.deedContentHistoryData);
        this.dataSource.sort = this.sort;
      }
      else {
        // this.dataSource = new MatTableDataSource<any>([]);
        this.ngxService.stop();
      }
    });
  }
  mstDeedStatusBean: MstStatusBean[] = new Array();
  mstpartyContentStatusBean: MstStatusBean[] = new Array();

  getDeedStatus() {
    this.ngxService.start();
    this.estampService.getMstStatusByModuleType("ESTAMP_CONTENT_STATUS").subscribe(mstStatusObjectList => {
      if (mstStatusObjectList.responseStatus == 'true') {
        this.ngxService.stop();
        this.mstDeedStatusBean = mstStatusObjectList.responseData;
      }
      else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(mstStatusObjectList);
      }
    },
      (err) => {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      }
    );
  }

  getpartyContentStatus() {
    this.ngxService.start();
    this.estampService.getMstStatusByModuleType("ESTAMP_PARTY_CONTENT_STATUS").subscribe(mstStatusObjectList => {
      if (mstStatusObjectList.responseStatus == 'true') {
        this.ngxService.stop();
        this.mstpartyContentStatusBean = mstStatusObjectList.responseData;
      }
      else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(mstStatusObjectList);
      }
    },
      (err) => {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      }
    );
  }


  getContentStatus(element: any) {
    var obj = this.mstDeedStatusBean?.find(obj => {
      return obj.id === element
    })
    if (null != obj && obj != undefined) {
      if (this.lan == 0)
        return obj.nameEn
      else
        return obj.nameHi
    } else {
      return '';
    }
  }

  getPartyContentStatus(element: any) {

    var obj = this.mstpartyContentStatusBean?.find(obj => {
      return obj.id === element
    })
    if (null != obj && obj != undefined) {
      if (this.lan == 0)
        return obj.nameEn
      else
        return obj.nameHi
    } else {
      return '';
    }
  }


  estampCommonBean: EstampCommonBean = new EstampCommonBean();
  viewDeed(estampId: number, deedContentId: number, deedContent: string) {


    this.estampIdForTemplate = estampId
    this.deedContent = deedContent
    this.deedContentId = deedContentId
    this.ngxService.start();
    this.estampCommonBean = new EstampCommonBean();
    this.estampService.getEstampDetailsById(this.estampIdForTemplate).subscribe(templateData => {
      if (templateData.responseStatus == 'true') {
        this.ngxService.stop();
        let templateId = templateData.responseData.deedTemplateId
        this.estampCommonBean.igrsEregEstampBean = templateData.responseData;
        this.estampCommonBean.igrsEregEstampBean.finalContent = templateData.responseData.finalContent;
        this.estampCommonBean.viewJasperDeed = true;
        if (templateId != undefined) {
          this.getTemplateDetails(templateId)
        } else {

          this.dialog.open(DeedTemplateComponent, {
            // height: '50%',
            width: '80%',
            data: {
              estampId: this.estampId,
              deedContentId: '0', // For latest content on Deed Content
              deedContent: this.deedContent,
              estampCommonbean: this.estampCommonBean

            }
          });
        }



      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(templateData);

      }


    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');

      });
    //this.router.navigate(['/citizen/deedTemplate']);



  }


  getTemplateDetails(templateId: number) {

    this.ngxService.start();
    this.estampService.getDeedTemplateByTemplateId(templateId).subscribe(templateData => {
      if (templateData.responseStatus == 'true') {
        this.ngxService.stop();
        let designTemplateEn;
        //console.log("templateData getTemplateDetails",templateData.responseData)
        if (templateData.responseData.length > 0) {
          designTemplateEn = templateData.responseData[0].designTemplateEn
        }
        if (templateId != null) {
          sessionStorage.setItem("templateId", templateId.toString())
        }

        //console.log("templateData designTemplateEn",designTemplateEn)
        // this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
        //   this.deedTemplateContent
        //   //"<button class='btn btn-primary mr-3' type='button' id='button-addon2' (click)='closeDeed()' [mat-dialog-close]='true'>Close</button>"
        // )

        this.dialog.open(DeedTemplateComponent, {
          // height: '50%',
          width: '80%',
          data: {
            estampId: this.estampIdForTemplate,
            deedContentId: this.deedContentId, // For latest content on Deed Content
            deedContent: this.deedContent,
            deedTemplateContent: designTemplateEn,
            estampCommonbean: this.estampCommonBean
          }
        });
        //console.log("getTemplateDetails", templateData.responseData)
      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(templateData);

      }


    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');

      });

  }


  applyFilter(filterValue: string) {
    this.innerTables.forEach(
      (table, index) =>
      ((table.dataSource as MatTableDataSource<PartyActionHistory>).filter = filterValue
        .trim()
        .toLowerCase())
    );
  }

  loadEregistryDataByEstampId(estampId: any) {
    // alert(estampId)
    this.ngxService.start();
    // const param = {
    //   estampId:this.estampIdTemp
    // }
    this.eRegService.loadEregistryDataByEstampId(estampId).subscribe(res => {
      if (res.responseStatus == "true") {
        this.igrsEregTxnDetlsBean = res.responseData;
        // alert(JSON.stringify(this.data.igrsEregTxnDetlsBean));
      }
      this.ngxService.stop();
    });
  }

  viewEregDeed(estampId: any) {
    // this.loadEregistryDataByEregId(estampId);


    this.eRegService.loadEregistryDataByEstampId(estampId).subscribe(res => {
      if (res.responseStatus == "true") {
        this.igrsEregTxnDetlsBean = res.responseData;
        // alert(JSON.stringify(this.data.igrsEregTxnDetlsBean));


        this.ngxService.start();
        this.igrsEregTxnDetlsBean.estampId = estampId;
        this.igrsEregTxnDetlsBean.deedTemplateId = this.igrsEregTxnDetlsBean.igrsEregEstampBean.deedTemplateId;
        this.estampService.getEstampDetailsById(this.igrsEregTxnDetlsBean.estampId).subscribe(templateData => {
          if (templateData.responseStatus == 'true') {
            this.ngxService.stop();
            this.estampSearch = templateData.responseData;

            let templateId = templateData.responseData.deedTemplateId
            //let templateId=62;
            //this call only testing perpose
            //this.getTemplateDetails(5);
            if (this.estampSearch != undefined && this.estampSearch.finalContent != undefined) {
              this.estampDeedContent.deedContent = this.estampSearch.finalContent;
            }
            if (templateId != undefined) {
              this.ngxService.start();
              this.estampService.getDeedTemplateByTemplateId(templateId).subscribe(templateData => {
                if (templateData.responseStatus == 'true') {
                  this.ngxService.stop();
                  let designTemplateEn;
                  //console.log("templateData getTemplateDetails", templateData.responseData)
                  if (templateData.responseData.length > 0) {
                    designTemplateEn = templateData.responseData[0].designTemplateEn
                  }
                  if (templateId != null) {
                    sessionStorage.setItem("templateId", templateId.toString())
                  }
                  this.dialog.open(DeedTemplateComponent, {
                    // height: '50%',
                    width: '80%',
                    data: {
                      estampId: this.igrsEregTxnDetlsBean.estampId,
                      templateId: templateId,
                      deedContentId: '0', // For latest content on Deed Content
                      deedContent: this.estampDeedContent.deedContent,
                      deedTemplateContent: designTemplateEn,
                      igrsEregTxnDetlsBean: this.igrsEregTxnDetlsBean

                    }
                  });
                  //console.log("getTemplateDetails", templateData.responseData)
                } else {
                  this.ngxService.stop();
                  this.alertmessage.errorAlertMessage(templateData);
                }
              },
                (err) => {
                  this.ngxService.stop();
                  this.alertmessage.errorAlertMessage('tech_error_msg');
                });
            } else {
              this.dialog.open(EregDeedTemplateComponent, {
                // height: '50%',
                width: '80%',
                data: {
                  estampId: this.estampId,
                  deedContentId: '0', // For latest content on Deed Content
                  deedContent: this.estampDeedContent.deedContent,
                  igrsEregTxnDetlsBean: this.igrsEregTxnDetlsBean
                }
              });
            }
          } else {
            this.ngxService.stop();
            this.alertmessage.errorAlertMessage(templateData);

          }
        },

          (err) => {

            this.ngxService.stop();
            this.alertmessage.errorAlertMessage('tech_error_msg');
          });
      }
      this.ngxService.stop();
    });
  }

  deedAcceptLinkSendToParty(estampId: any) {
    Swal.fire({
      //title: 'Are you sure?',
      title: (this.lan == 0) ? "Deed link resend to party(s) ?" : "डीड लिंक पक्षकार को पुनः भेजें ?",
      //text: 'This process is irreversible.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: (this.lan == 0) ? "Yes" : "हाँ",
      cancelButtonText: (this.lan == 0) ? "No" : "नहीं",
    }).then((result) => {
      if (result.value) {

        //this.ngxService.startLoader('deed-content-history');
        this.eRegService.deedAcceptLinkSendToParty(estampId).subscribe(res => {
          // this.ngxService.stopLoader('deed-content-history');
          if (res.responseStatus == "true") {
            Swal.fire((this.lan == 0) ? "Deed accept/reject link sent to all party(s) successfully." : "विलेख स्वीकार/अस्वीकार का लिंक सभी पक्षकारों को सफलतापूर्वक भेज दिया गया।")
          }
        });

      }
    });


  }

  viewEregDeedJrxmlToHtmlDeedHistory(estampId: any, deedHistroyBean: DeedContentHistory, modalView: any, isActionButtonShow: any) {
    this.isActionButtonShow = isActionButtonShow;

    this.getEregEstampFileName(estampId, deedHistroyBean, modalView);

  }

  viewEstampDeedJrxmlToHtml(estampId: any, fileName: any, modalView: any, isActionButtonShow: any) {
    this.isActionButtonShow = isActionButtonShow;

    this.getEstampFileName(estampId, fileName, modalView);

  }
  openEregDeedPdf(deedContentHistory: DeedContentHistory) {

    //console.log("deedhistory", deedContentHistory);

    const param = {
      'month': deedContentHistory.month,
      'year': deedContentHistory.year,
      'filename': deedContentHistory.deedFileName,
    }

    // this.ngxService.startLoader('deed-content-history');
    this.eRegService.viewEregDeedJrxmlToHtmlCommonForDeedHistory(param).subscribe(result => {

      if (result.responseStatus == "true") {
        //console.log("viewEregDeedJrxmlToHtml :: ",result.responseData);
        this.ngxService.stopLoader('deed-content-history');
        const byteArray = Uint8Array.from(atob(result.responseData.pdfBase64), c => c.charCodeAt(0));
        //const byteArray = result.responseData.encodedBytes
        let blobMB = new Blob([byteArray], { type: 'application/pdf' });
        //const viewer =iframe.contentWindow.document.getElementsByTagName("pdfviewer")
        const viewer = document.getElementById('viewer');
        this.base64DeedPdf = URL.createObjectURL(blobMB);
        viewer.setAttribute('src', this.base64DeedPdf);
        //viewer.onload = () => URL.revokeObjectURL(this.base64DeedPdf);
        //console.log('base64DeedPdf::'+this.base64DeedPdf)

        this.modalService.open({

          size: 'xl',

        });
        // window.open(data);
      } else {
        this.ngxService.stopLoader('deed-content-history');
        this.alertmessage.errorAlertMessage(result.responseMessage);
      }
    },
      (err) => {
        this.ngxService.stopLoader('deed-content-history');
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }


  openEstampDeedPdf() {
    this.estampCommonBean.viewJasperDeed = true;
    //this.ngxService.startLoader('deed-content-history');
    this.estampService.viewCommonEstampDeedJrxmlToHtml(this.estampCommonBean).subscribe(result => {

      if (result.responseStatus == "true") {
        //console.log("viewEregDeedJrxmlToHtml :: ", result.responseData);
        this.ngxService.stopLoader('deed-content-history');
        const byteArray = Uint8Array.from(atob(result.responseData.pdfBase64), c => c.charCodeAt(0));
        let blobMB = new Blob([byteArray], { type: 'application/pdf' });
        //const viewer =iframe.contentWindow.document.getElementsByTagName("pdfviewer")
        const viewer = document.getElementById('viewer');
        this.base64DeedPdf = URL.createObjectURL(blobMB);
        viewer.setAttribute('src', this.base64DeedPdf);
        //viewer.onload = () => URL.revokeObjectURL(this.base64DeedPdf);
        //console.log('base64DeedPdf::' + this.base64DeedPdf)

        this.modalService.open({
          // centered: true,
          size: 'xl',

        });
        // window.open(data);
      } else {
        this.ngxService.stopLoader('deed-content-history');
        this.alertmessage.errorAlertMessage(result.responseMessage);
      }
    },
      (err) => {
        this.ngxService.stopLoader('deed-content-history');
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }

  getEstampDetailsById() {

    this.ngxService.startLoader('ereg-deed-drafting');
    this.estampService.getEstampDetailsById(Number(this.estampId)).subscribe(templateData => {
      this.ngxService.stopLoader('ereg-deed-drafting');
      if (templateData.responseStatus == 'true') {

        this.estampSearch = templateData.responseData;

      } else {
        this.ngxService.stopLoader('ereg-deed-drafting');
        this.alertmessage.errorAlertMessage(templateData);
      }
    },
      (err) => {
        this.ngxService.stopLoader('ereg-deed-drafting');
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }

  getEstampFileName(estampId: any, fileName: string, content: any) {

    this.ngxService.startLoader('deed-content-history');
    this.estampService.getEstampDetailsById(Number(estampId)).subscribe(templateData => {
      //this.ngxService.stopLoader('deed-content-history');
      if (templateData.responseStatus == 'true') {

        this.estampSearch = templateData.responseData;
        this.estampCommonBean.igrsEregEstampBean = templateData.responseData;

        if (fileName != null) {
          this.estampCommonBean.igrsEregEstampBean.finalSignFileName = fileName;
          this.openEstampDeedPdf();
          this.modalService.open(content, { ariaLabelledBy: 'modal-secondary-title', size: 'xl' });

        } else {
          this.ngxService.stopLoader('deed-content-history');
          this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
          Swal.fire({
            icon: 'warning',
            title: (this.lan == 0) ? 'Deed Acceptance' : 'विलेख स्वीकृति',
            text: (this.lan == 0) ? 'Please wait for few seconds as your deed is being generate' : 'कृपया डीड तैयार होने तक कुछ सेकंड प्रतीक्षा करें',
            confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
          });
        }

      } else {
        this.ngxService.stopLoader('ereg-deed-drafting');
        this.alertmessage.errorAlertMessage(templateData);
      }
    },
      (err) => {
        this.ngxService.stopLoader('ereg-deed-drafting');
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }



  getEregEstampFileName(estampId: any, deedHistroyBean: DeedContentHistory, content: any) {

    this.ngxService.startLoader('deed-content-history');
    this.estampService.getEstampDetailsById(Number(estampId)).subscribe(templateData => {
      //this.ngxService.stopLoader('deed-content-history');
      if (templateData.responseStatus == 'true') {

        this.estampSearch = templateData.responseData;
        this.igrsEregTxnDetlsBean.igrsEregEstampBean = templateData.responseData;
        this.igrsEregTxnDetlsBean.estampId = estampId;
        //console.log("inside getEstampDetailsById of deed-content-history", this.estampSearch)
        if (deedHistroyBean.deedFileName != null && deedHistroyBean.month != null && deedHistroyBean.year != null) {

          this.openEregDeedPdf(deedHistroyBean);
          this.modalService.open(content, { ariaLabelledBy: 'modal-secondary-title', size: 'xl' });

        }
        else if (this.igrsEregTxnDetlsBean.igrsEregEstampBean.finalSignFileName != null) {
          deedHistroyBean.month = this.igrsEregTxnDetlsBean.igrsEregEstampBean.month;
          deedHistroyBean.year = this.igrsEregTxnDetlsBean.igrsEregEstampBean.year;
          deedHistroyBean.deedFileName = this.igrsEregTxnDetlsBean.igrsEregEstampBean.finalSignFileName;
          this.openEregDeedPdf(deedHistroyBean);
          this.modalService.open(content, { ariaLabelledBy: 'modal-secondary-title', size: 'xl' });
        }
        else {
          this.ngxService.stopLoader('deed-content-history');
          this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
          Swal.fire({
            icon: 'warning',
            title: (this.lan == 0) ? 'Deed Acceptance' : 'विलेख स्वीकृति',
            text: (this.lan == 0) ? 'Please wait for few seconds as your deed is being generate' : 'कृपया डीड तैयार होने तक कुछ सेकंड प्रतीक्षा करें',
            confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
          });
        }

      } else {
        this.ngxService.stopLoader('ereg-deed-drafting');
        this.alertmessage.errorAlertMessage(templateData);
      }
    },
      (err) => {
        this.ngxService.stopLoader('ereg-deed-drafting');
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }

  currentClasses: {};
  someOtherToggled = false;
  zoomDeedViewIf: boolean = true;
  exitZoomDeedViewIf: boolean = false
  //fullscreen
  zoomDeedView() {
    this.zoomDeedViewIf = false;
    this.exitZoomDeedViewIf = true
    let modalClass = document.getElementById('modalDeedPdf')
    this.currentClasses = {
      //'minWidth': false,
      'fullScreenDeed': true,
    }
  }
  noZoomDeedView() {
    this.zoomDeedViewIf = true;
    this.exitZoomDeedViewIf = false
    this.currentClasses = {
      //'minWidth': false,
      'fullScreenDeed': false,
    }
  }








}

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ChangeDetectionStrategy, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
  styleUrls: ['./faq-details.component.scss'],
  
    animations:[ 
    trigger('fade',
    [ 
      state('void', style({ opacity : 0})),
      transition(':enter',[ animate(300)]),
      transition(':leave',[ animate(500)]),
    ]
)],

changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqDetailsComponent implements OnInit {

 lan: number;
step = 0;
   constructor( 
 
    private ngxLoader: NgxUiLoaderService,
    
    private translate: TranslateService,
    private translateServiceIns: TranslateService,
    private translateService: TranslateHEService,
 ) {
    
    try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg == 1 ? 'hi' : 'en')
      }
      );
    } catch (err) {
      console.log(err);
    }

    
  }

  ngOnInit(): void {
  }
  setStep(index: number) {
    this.step = index;
  }

}

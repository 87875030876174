<div class="chat-container" [ngClass]="{'closed': !isOpen, 'open': isOpen}">
  <div class="chat-header bg-primary" *ngIf="isOpen">
    <img src="assets/images/bot.png" alt="Bot" class="header-logo">
    <div class="header-content">
      <span class="chatbot-name">{{ currentLanguage === 'en' ? 'Saathi' : 'साथी' }}</span>
      <span class="chatbot-message">{{ currentLanguage === 'en' ? 'Virtual Assistant for SAMPADA' : 'संपदा के लिए
        वर्चुअल असिस्टेंट' }}</span>
    </div>
    <button class="close-btn" (click)="toggleChat()">×</button>
  </div>
  <div class="chat-box" #chatBox *ngIf="isOpen">
    <div class="message" *ngFor="let msg of messages">
      <div class="message-container"
        [ngClass]="{'user-message': msg.sender === 'user', 'bot-message': msg.sender === 'bot'}">
        <img *ngIf="msg.sender === 'bot'" class="bot-logo" src="assets/images/bot.png" alt="Bot">
        <div>
          <div class="message-content" [ngClass]="{'bg-primary text-white': msg.sender === 'bot'}">{{ msg.content }}
          </div>
          <div class="timestamp"
            [ngClass]="{'user-timestamp': msg.sender === 'user', 'bot-timestamp': msg.sender === 'bot'}">
            {{ msg.time }} <span *ngIf="msg.sender === 'user'" class="tick-mark">✔</span>
          </div>
        </div>
      </div>

    </div>
    <div class="typing-indicator-container" *ngIf="isTyping">
      <div class="typing-indicator">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <div class="menu-container" *ngIf="showContextMenu">
      <div *ngIf="!selectedModule && !selectedSubModule">
        <button class="context-button" *ngFor="let module of modules" (click)="selectModule(module)">{{ module.name
          }}</button>
      </div>
      <div *ngIf="selectedModule && !selectedSubModule && !selectedModule.subModules">
        <button class="context-button back-button" (click)="resetMenu()">{{ currentLanguage === 'en' ? 'Main Menu' :
          'मुख्य मेनू' }}</button>
        <div *ngFor="let question of selectedModule.questions">
          <button class="context-button" (click)="askQuestion(question)">{{ question }}</button>
        </div>
      </div>
      <div *ngIf="selectedModule && selectedModule.subModules && !selectedSubModule">
        <button class="context-button back-button" (click)="resetMenu()">{{ currentLanguage === 'en' ? 'Main Menu' :
          'मुख्य मेनू' }}</button>
        <div *ngFor="let subModule of selectedModule.subModules">
          <button class="context-button" (click)="selectSubModule(subModule)">{{ subModule.name }}</button>
        </div>
      </div>
      <div *ngIf="selectedSubModule">
        <button class="context-button back-button" (click)="resetMenu()">{{ currentLanguage === 'en' ? 'Main Menu' :
          'मुख्य मेनू' }}</button>
        <div *ngFor="let question of selectedSubModule.questions">
          <button class="context-button" (click)="askQuestion(question)">{{ question }}</button>
        </div>
      </div>
    </div>
    <div>

    </div>
  </div>
  <div class="input-box" *ngIf="isOpen">
    <div class="suggestions" *ngIf="suggestions.length">
      <div class="suggestion-item" *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)">
        {{ suggestion }}
      </div>
    </div>
    <div class="input-container">
      <!-- <input [(ngModel)]="userMessage" (keyup)="onUserTyping()" (keydown.enter)="sendMessage()"
        [placeholder]="currentLanguage === 'en' ? 'Type your message...' : 'संदेश टाइप करें...'"> -->

      <!-- <input #messageInput [(ngModel)]="userMessage" (keyup)="onUserTyping()" (keydown.enter)="sendMessage()"
        [placeholder]="currentLanguage === 'en' ? 'Type your message...' : 'संदेश टाइप करें...'"> -->
      <textarea #messageInput [(ngModel)]="userMessage" (keyup)="onUserTyping()" (keydown.enter)="sendMessage()"
        [placeholder]="currentLanguage === 'en' ? 'Type your message...' : 'संदेश टाइप करें...'"
        (input)="adjustTextareaHeight()"></textarea>



      <button (click)="sendMessage()" class="send-btn btn-primary">{{ currentLanguage === 'en' ? 'Send' : 'भेजें'
        }}</button>
      <button (click)="resetChat(true)" class="home-btn btn-primary text-white ">
        <mat-icon class="text-white">home</mat-icon>
      </button>

    </div>
  </div>
  <div class="bot-profile-container" *ngIf="!isOpen" (click)="toggleChat()">
    <img src="assets/images/bot.png" alt="Bot" class="bot-profile">
    <div class="profile-hover" [ngClass]="{'show-hover': showHover}">
      <div class="profile-message-bold">{{ currentLanguage === 'en' ? 'Saathi' : 'साथी' }}</div>
      <div class="profile-message">{{ currentLanguage === 'en' ? 'Hi my name is Saathi' : 'मेरा नाम साथी है' }}</div>
      <div class="profile-message">{{ currentLanguage === 'en' ? 'I\'m here to assist you with your queries' : 'मैं आपकी
        सहायता के लिए यहाँ हूँ' }}</div>
    </div>
  </div>
</div>
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[TwoDigitDecimalNumber]'
})
export class TwoDigitDecimalNumberDirective {

  @Input() lenght: Number=2;

  private regex2: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private regex4: RegExp = new RegExp(/^\d*\.?\d{0,4}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  constructor(private el: ElementRef) {
   // console.log(el)
  }
   @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (this.lenght==2) {
      if (next && !String(next).match(this.regex2)) {
        event.preventDefault();
       this.el.nativeElement.value=''
      }
    }else{
      if (next && !String(next).match(this.regex4)) {
        event.preventDefault();
       this.el.nativeElement.value=''
      }
    }
    
  }
 
}

<div class="fid-header">
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
               <button style="height: 34px;" class="ml-1 mr-2 text-white" mat-raised-button color="primary"
                    (click)="printPayDetails()">
                    <i class="fa fa-print" aria-hidden="true"></i>
                </button>

            </div>
        </div>
    </div>
</div>

<div class="container mat-top">
    <div class="card mb-1">
        <div class="card-body">
            <div class="text-right col-lg-12 text-right mb-2">
            </div>
            <div id="pdfDivId" #pdfDivId class="guitable px-2 page page2 print-paage2">
                <style>
                     @page { size: a4 landscape; }
                    @media print {
                        table {
                            width: 100%;
                        }

                        table,
                        table td,
                        table th {
                            border: 1px solid #dee2e6;
                        }
                        .divfooter1 {
                        position: fixed;
                        bottom: 0;
                    }
                    tfoot.report-footer {
    display:table-footer-group;
}

                    }
                </style>
                <div class="table-responsive shadow-none">
                <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border: none">
                    <tr>
                        <td style="border: none; text-align: center;  vertical-align: middle;">
                            <img src="assets/images/mplogo.png" width="100" height="100" align="Madhya Pradesh"
                                style="margin: 20px 0px" />
                        </td>
                        <td style="border: none; text-align: center; vertical-align: middle; ">
                            <div>
                                <h3 style="font-size: 28px; font-weight: 600">
                                    {{ "guideline.guideline-title" | translate }}
                                </h3>
                                <p class="headertitle2" style="    color: #6a6a35;
                                font-size: 20px;
                                line-height: 1.6;
                                text-shadow: 0 0 2px #f2ebdf;
                                font-weight: 500;">
                                    {{financialYear}}<br />
                                    {{ "guideline.mp-gov-title" | translate }}
                                </p>
                            </div>
                        </td>
                        <td style="border: none; text-align: center; vertical-align: middle;">
                            <img src="assets/images/logo-1.png" width="100" height="100" align="Sampada2.0"
                                style="margin: 20px 0px" />
                        </td>
                    </tr>
                </table>
                <table class="table table-bordered" width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr>
                        <td style="padding: 0" colspan="2">
                            <table class="table" width="100%" border="1" cellpadding="0" cellspacing="0"
                                bordercolor="#000000">
                                <thead style="font-size: 13px; font-weight: 600">
                                    <tr>
                                        <th rowspan="2" style="text-align: center; vertical-align: middle;width: 2%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.no" | translate }}
                                            </p>
                                        </th>
                                        <th rowspan="2"
                                            style="text-align: center; vertical-align: middle; width:20%;white-space: nowrap;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.ward/village-colony" | translate }}
                                            </p>
                                        </th>
                                        <th colspan="3" style="text-align: center; vertical-align: middle;width: 15%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.plot" | translate
                                                }}{{ "guideline.sqm" | translate }}
                                            </p>
                                        </th>
                                        <th colspan="4" style="text-align: center; vertical-align: middle;width: 15%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.buil-resi" | translate
                                                }}{{ "guideline.sqm" | translate }}
                                            </p>
                                        </th>
                                        <th colspan="3" style="text-align: center; vertical-align: middle;width: 10%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.buil-comm" | translate
                                                }}{{ "guideline.sqm" | translate }}
                                            </p>
                                        </th>
                                        <th colspan="2" style="text-align: center; vertical-align: middle;width: 10%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.buil-multi" | translate
                                                }}{{ "guideline.sqm" | translate }}
                                            </p>
                                        </th>
                                        <th colspan="2" style="text-align: center; vertical-align: middle;width: 10%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.agri-land" | translate
                                                }}{{ "guideline.hectare" | translate }}
                                            </p>
                                        </th>
                                        <th colspan="2" style="text-align: center; vertical-align: middle;width: 13%;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.agri-plot" | translate
                                                }}{{ "guideline.sqm" | translate }}
                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="text-align: center; vertical-align: middle;">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.resi" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.comm" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.ind" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.rcc" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.rbc" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.tin_shade" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.kaccha_abelu" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.Shop" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.office" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.godown" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.resi" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.comm" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.irgd" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.unirgd" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.sub-clause-resi" | translate }}
                                            </p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle">
                                            <p class="headertitle" style="margin: 0;">
                                                {{ "guideline.sub-clause-comm" | translate }}
                                            </p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(1)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(2)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(3)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(4)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(5)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(6)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(7)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(8)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(9)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(10)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(11)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(12)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(13)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(14)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(15)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(16)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(17)</p>
                                        </th>
                                        <th style="text-align: center; vertical-align: middle; padding: 0.1rem;">
                                            <p class="headertitle" style="margin: 0;">(18)</p>
                                        </th>
                                    </tr>
                                </thead>
                            <tbody *ngFor="let groupByData of responseData; ">





<tr>
<td colspan="18" style="text-align: center;padding: 13px;">
    <span >
        <strong>
                <span>
                    {{lan==0?groupByData.groupByNameEn:groupByData.groupByNameHi}}
                </span>


            </strong>
    </span>
</td>
</tr>
<tr *ngFor="let item of groupByData.responseData;" >
    <td
    style="text-align: center;vertical-align: middle;font-size: 12px;
    line-height: 1.2;  height: 70px; overflow: hidden;">
    {{SetSeqNo()}}
</td>
<td  style="text-align: center;vertical-align: middle;font-size: 12px; line-height: 1.2;  height: 70px; overflow: hidden;">
   <span >
    {{lan==0?item?.colony?.demographyNameEn:item?.colony?.demographyNameHi}}
   </span>
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.plotresi}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.plotcomm}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.plotindus}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildrcc}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildrbc}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildtinshade}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildkabelu}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildshop}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildoffice}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildgodown}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildmultiresi}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.buildmulticomm}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.agriirrg}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.agriunirrg}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.landuptoresi}}
</td>
<td style="text-align: center; vertical-align: middle">
    {{item.landuptocomm}}
</td>
</tr>





                                   <!--
                                        <tr *ngFor="let item of responseData">
                                            <td
                                                style="text-align: center;vertical-align: middle;font-size: 12px;
                                                line-height: 1.2;  height: 70px; overflow: hidden;"> {{item.index}}
                                            </td>
                                            <td  style="text-align: center;vertical-align: middle;font-size: 12px; line-height: 1.2;  height: 70px; overflow: hidden;">
                                               <span >

                                               </span>
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.plotresi}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.plotcomm}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.plotindus}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildrcc}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildrbc}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildtinshade}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildkabelu}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildshop}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildoffice}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildgodown}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildmultiresi}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.buildmulticomm}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.agriirrg}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.agriunirrg}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.landuptoresi}}
                                            </td>
                                            <td style="text-align: center; vertical-align: middle">
                                                {{item.landuptocomm}}
                                            </td>
                                        </tr>

                                       -->


                                </tbody>

                            </table>
                        </td>
                    </tr>

                    <tfoot class="report-footer">
                    <tr class="divfooter1" style="background: #f2ebdf;">
                        <td class="divFooter" style="border-right: 0;">
                            <span class="headertitle line-height30">
                                Financial Year:  {{financialYear}} Name of District: {{lan==0?demographyNameEn:demographyNameHi}}
                               </span>
                        </td>
                        <td style="border: 0; text-align: right;">Page 1 of 151</td>
                    </tr>
                </tfoot>
                </table>
            </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
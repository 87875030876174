import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { CitizenService } from '../../../service/citizen.service';

@Component({
  selector: 'app-complaint-status',
  templateUrl: './complaint-status.component.html',
  styleUrls: ['./complaint-status.component.scss']
})
export class ComplaintStatusComponent implements OnInit {

  @ViewChild(AlertmessageComponent, {static: false}) alertmessage: AlertmessageComponent;
  submitted = false;

  lan: number;
  checkedVal: boolean;

  registrationNumber:any;
  requestTypeId:any=null;
  remark:any;
  statusBean:any;
  requestData:any;
  requestDate:any;
  complaintReqList: any;

  @ViewChild('complaintStatusFormHtml') form: any;
  
  constructor(private translateService:TranslateHEService
    , private authenticationService:AuthenticationService
    , private translate:TranslateService
    , private titleService: Title
    , private citizenService:CitizenService) { 
      //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.translateService.updateApprovalMessage(this.lan);
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })

  }


  ngOnInit(): void {
    this.getComplaintReqTypes();
  }

  getComplaintReqTypes() {
    this.citizenService.getComplaintReqList().subscribe((result) => {
      this.complaintReqList = result.responseData;
    });

  }

  changeLan(ev: any) {
    //
    
    if (ev.value == 1) {
      this.lan = 0;
      sessionStorage.setItem("selectedLanguage", 'en');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      this.translate.use(sessionStorage.getItem("selectedLanguage") || "en")
      this.translateService.updateApprovalMessage(this.lan);
    } else {
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage", 'hi');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi")
      this.translateService.updateApprovalMessage(this.lan);
    }
    // window.location.reload();
    this.translate.get("legal-person-title").subscribe(name=>{
      this.titleService.setTitle(name);
    });



  }

  onSubmit() {
    if(this.registrationNumber != null && this.registrationNumber != ''){
      this.authenticationService.submitComplaintStatus(this.registrationNumber, this.requestTypeId).subscribe(res =>{
        
        if(res!=null){
          if (res.responseStatus == "true") {
            this.requestData=JSON.parse(res.responseData.requestData);
            this.requestDate=res.responseData.requestDate;
            this.remark=res.responseData.remark;
            this.statusBean=res.responseData.statusBean;
            //
            //this.alertmessage.successAlertMessage(res);
          } else {
            this.alertmessage.errorAlertMessage(res);
          }
        }
      });
    }else{

    }
  }

  resetData(){
    this.requestData=null;
    this.requestDate=null;
    this.remark=null;
    this.statusBean=null;
    this.form.reset();
  }

}

<!-- <div *ngIf="kycDetails">
    <app-kyc-details (verifyAdharOtpOut)="verifyAdharOtpOut($event)" (verifyPanOut)="verifyPanOut($event)"
        [kycSource]="kycSource" [kycObject]="kycObject" [citizenId]="citizenId">
    </app-kyc-details>
</div> -->
<div class="main" style="background-color: #F1F1F1;">
    <div class="main-content"> 
<front-header></front-header>
<div class="auth-main">


    <lib-alertmessage></lib-alertmessage>
    <div class="container"> <!--  *ngIf="citizenLogin" -->
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-10">
                <div class="row  justify-content-md-center">
                    <div class="col-lg-7 col-md-8 col-xs-12 col-sm-12 logInForms bg-white p-4">
                        <h2 class="loginTitle text-primary mt-0">
                            {{'citizen-profile-details.login-detail-profile.sign-in-as' | translate}}</h2>

                        <div class="d-none justify-content-center">
                            <h5 class="login-title d-block ">{{'citizen-login-lable' | translate}}</h5>
                        </div>
                        <lib-alertmessage></lib-alertmessage>

                        <div class=" " *ngIf="login">
                            <form name="form" (ngSubmit)="citizenLoginFormHtml.form.valid && onSubmitForLogin()"
                                #citizenLoginFormHtml="ngForm" novalidate>
                                <div class="input-group mb-3">
                                    <label class="w-100">{{'citizen-profile-details.login-detail-profile.username' |
                                        translate}} <span style="color: red;">*</span></label>
                                    <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                class="fa fa-user" style="width:16px; height:16px"></i></span> </div>
                                    <input type="text" name="userName" id="userName" #userName="ngModel"
                                        (contextmenu)="false;" autocomplete="off" appAlphaNumericDotUndsDashA
                                        [appCopyPastValidation]="true" maxlength="60" (keyup)="resetPasswordField()"
                                        [ngClass]="{ 'is-invalid': citizenLoginFormHtml.submitted && userName.invalid }"
                                        required [(ngModel)]="credential.userName" class="form-control input_user"
                                        placeholder="{{'citizen-profile-details.login-detail-profile.username' | translate}}"
                                        id="username">
                                    <div *ngIf="citizenLoginFormHtml.submitted && userName.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="userName.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                                <div class="input-group mb-4">
                                    <label class="w-100">{{'citizen-profile-details.login-detail-profile.password' |
                                        translate}} <span style="color: red;">*</span></label>
                                    <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                class="fa fa-key"></i></span> </div>
                                    <input [type]="passhide ? 'password' : 'text'" name="password" autocomplete="off"
                                        [appCopyPastValidation]="true" [(ngModel)]="credential.password"
                                        #password="ngModel" (contextmenu)="false;" id="password"
                                        [ngClass]="{ 'is-invalid': citizenLoginFormHtml.submitted && password.invalid }"
                                        placeholder="{{'citizen-profile-details.login-detail-profile.password' | translate}}"
                                        required maxlength="60" class="form-control input_pass">
                                    <div *ngIf="citizenLoginFormHtml.submitted && password.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="password.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                    <div class="input-group-prepend showPassword"> <button type="button" class=" "
                                            (click)="passhide = !passhide" [attr.aria-label]="'Hide password'"
                                            [attr.aria-pressed]="passhide"><mat-icon>{{passhide ? 'visibility_off' :
                                                'visibility'}}</mat-icon></button> </div>
                                    <div class="d-block w-100">
                                        <div class="text-right">

                                            <a class="forgetpasswrd" routerLink="/citizen/forgot-password"
                                                [queryParams]="{ source: 'forgot-password'}"><i class="fa fa-key"></i>
                                                {{'forgot-password' | translate}}</a>

                                        </div>
                                    </div>

                                </div>

                                <div class="row align-items-center mb-2 ">
                                    <div class="col-5">
                                        <div class="input-group  captchaImage_box" style="align-content: center;">
                                            <img src="{{captchaImageBase64Img}}" height="40" />
                                            <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                        </div>
                                    </div>
                                    <div class="col-2 text-center">
                                        <img src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                            height="26px" />
                                    </div>
                                    <div class="col-5">
                                        <div class="input-group ">
                                            <input type="text" name="captchaStr" #captchaStr="ngModel" minlength="4"
                                                maxlength="4"  [AlphaNumeric]="true" 
                                                [ngClass]="{ 'is-invalid': citizenLoginFormHtml.submitted && captchaStr.invalid }"
                                                required [(ngModel)]="credential.captchaStr"
                                                class="form-control input_user" (contextmenu)="false;"
                                                [appCopyPastValidation]="true"
                                                placeholder="{{'enter-captcha' | translate}}" id="captchaStr">
                                            <div *ngIf="citizenLoginFormHtml.submitted && captchaStr.invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="captchaStr.errors?.required">{{'required' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-lg-6">
                                        <button type="submit" mat-button
                                            class="mb-1 btn btn-primary btn-block">{{'citizen-profile-details.login-detail-profile.login'
                                            | translate}}</button>
                                    </div>
                                    <div class="col-lg-6">
                                        <button type="button" mat-button
                                            class="mb-1 btn btn-secondary btn-block let_spacing"
                                            (click)="LoginWithMobile();">{{'citizen-profile-details.login-detail-profile.login-mobile'
                                            | translate}}</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div class=" " *ngIf="loginwithmobile">

                            <form name="form"
                                (ngSubmit)="citizenLoginWithMobileFormHtml.form.valid && onSubmitForLoginMobile()"
                                #citizenLoginWithMobileFormHtml="ngForm" novalidate>
                                <div class="input-group mb-3">
                                    <label
                                        class="w-100">{{'citizen-profile-details.citizen-contact-details.mobile-number'
                                        | translate}} <span style="color: red;">*</span></label>
                                    <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                class="fa fa-user" style="width:16px;height:16px ;"></i></span> </div>
                                    <input type="text" name="mobile1" #mobile1="ngModel" (contextmenu)="false;" required
                                        [ngClass]="{ 'is-invalid': citizenLoginWithMobileFormHtml.submitted && mobile1.invalid }"
                                        [(ngModel)]="mobile" class="form-control input_user" [OnlyNumber]="true"
                                        maxlength="10" minlength="10" [appCopyPastValidation]="true"
                                        placeholder="{{'citizen-profile-details.citizen-contact-details.mobile-number' | translate}}"
                                        pattern="^[6-9]\d{9}$" id="mobile1" (change)="changeContactNo()">
                                    <div *ngIf="citizenLoginWithMobileFormHtml.submitted && mobile1.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="mobile1.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                    <div *ngIf="mobile1.touched && mobile1.invalid">
                                        <!-- <div *ngIf="mobile1.errors?.minlength" class="invalid-feedback">Enter 10 digit mobile number!</div>
                                        <div *ngIf="mobile1.errors?.maxlength" class="invalid-feedback">Enter 10 digit mobile number!</div> -->
                                        <div *ngIf="mobile1.errors?.pattern" class="invalid-feedback">
                                            {{'citizen-profile-details.login-detail-profile.mobile-validate' |
                                            translate}}</div>
                                    </div>
                                    <!-- <pre *ngIf="mobile1.errors">{{ mobile1.errors | json }}</pre> -->

                                    <div class="input-group mb-3 mt-3" *ngIf="isUser">
                                        <label class="w-100">{{'select-user' | translate}}
                                            <span style="color: red;">*</span></label>
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                    class="fa fa-user"></i></span> </div>

                                        <ng-select required class="form-control p-0 pl-4" name="selectedUser"
                                            [(ngModel)]="selectedUser" (change)="selectUser()"
                                            placeholder="{{'select-user' | translate}}">
                                            <ng-option value="">{{'select-user'|translate}}</ng-option>
                                            <ng-option *ngFor="let user of userList" [value]="user.id">
                                                <!-- {{user.userName}} -->
                                                {{ lan == 0 ? 
                                                    user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                                    user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                                        </ng-select>

                                    </div>
                                    <div *ngIf="citizenLoginWithMobileFormHtml.submitted && selectedUser?.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="lan==0 &&(selectedUser.errors?.required)">This Field is Mandatory
                                        </div>
                                        <div *ngIf="lan==1 &&(selectedUser.errors?.required)">{{'required' |
                                            translate}}</div>
                                    </div>
                                </div>
                               

                                <div class="captcha">
                                    <div class="row align-items-center mb-2">
                                        <div class="col-5">
                                            <div class="input-group " style="align-content: center;">
                                                <img src="{{captchaImageBase64Img}}" height="40px"/>
                                                <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                            </div>
                                        </div>
                                        <div class="col-2 text-center">
                                            <img src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                                    height="26px" />
                                        </div>
                                        <div class="col-5">
                                            <div class="input-group ">
                                                <input type="text" name="captchaStr2" #captchaStr2="ngModel" minlength="4"
                                                    maxlength="4"
                                                    [ngClass]="{ 'is-invalid': citizenLoginWithMobileFormHtml.submitted && captchaStr2.invalid }"
                                                    required [(ngModel)]="credential.captchaStr2"
                                                    class="form-control input_user" (contextmenu)="false;"
                                                    [appCopyPastValidation]="true"
                                                    placeholder="{{'enter-captcha' | translate}}" id="captchaStr2">
                                                <div *ngIf="citizenLoginWithMobileFormHtml.submitted && captchaStr2.invalid"
                                                    class="invalid-feedback">
                                                    <div *ngIf="captchaStr2.errors?.required">{{'required' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>





                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <button type="submit" mat-button
                                            class="btn btn-primary btn-block mb-1">{{'citizen-profile-details.login-detail-profile.get-otp'
                                            | translate}}</button>
                                    </div>
                                    <div class="col-lg-6 col-sm-6">
                                        <button type="button" mat-button class="btn-block btn btn-secondary mb-1"
                                            (click)="LoginWithPassword();">{{'citizen-profile-details.login-detail-profile.login-password'
                                            | translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class=" " *ngIf="loginwithmobileotp">

                            <form name="form"
                                (ngSubmit)="citizenLoginWithMobileOtpFormHtml.form.valid && onSubmitForLoginMobileOtp()"
                                #citizenLoginWithMobileOtpFormHtml="ngForm" novalidate>
                                <div class="input-group mb-3">
                                    <label
                                        class="w-100">{{'citizen-profile-details.citizen-contact-details.mobile-number'
                                        | translate}} <span style="color: red;">*</span></label>
                                    <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                class="fa fa-user"></i></span> </div>
                                    <input type="text" ngModel required (contextmenu)="false;" [(ngModel)]="mobile"
                                        class="form-control input_user" disabled>
                                </div>
                                <div class="input-group mb-3 mt-3" *ngIf="isUser">
                                    <label class="w-100">{{'select-user' | translate}}
                                        <span style="color: red;">*</span></label>
        
                                    <ng-select disabled class="form-control p-0" name="selectedUser" [(ngModel)]="selectedUser"
                                        (change)="selectUser()" placeholder="{{'select-user' | translate}}">
                                        <ng-option value="">{{'select-user'|translate}}</ng-option>
                                        <ng-option *ngFor="let user of userList" [value]="user.id">
                                            <!-- {{user.userName}} -->
                                            {{ lan == 0 ? 
                                                user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                                user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="input-group mb-3">
                                    <label class="w-100">{{'otp' | translate}} <span
                                            style="color: red;">*</span></label>
                                    <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                class="fa fa-key"></i></span> </div>
                                    <input type="text" name="otp1" #otp1="ngModel" minlength="6" maxlength="6"
                                        (contextmenu)="false;" required [appCopyPastValidation]="true" [(ngModel)]="otp"
                                        [ngClass]="{ 'is-invalid': citizenLoginWithMobileOtpFormHtml.submitted && otp1.invalid }"
                                        class="form-control input_user" placeholder="{{'enter-otp' | translate}}">
                                    <div class="d-block w-100 text-right mt-1">
                                        <a class="forgetpasswrd" (click)="onSubmitForLoginMobile();"
                                            style="float: right;"><span *ngIf="counter != 0">{{counter |
                                                formatTime}}</span> <span *ngIf="counter == 0">{{'resend-otp' |
                                                translate}}</span></a>
                                    </div>
                                    <div *ngIf="citizenLoginWithMobileOtpFormHtml.submitted && otp1.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="otp1.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                                <div class="row align-items-center mb-2">
                                    <div class="col-5">
                                        <div class="input-group" style="align-content: center;">
                                            <img src="{{captchaImageBase64Img}}"  height="40px" />
                                           
                                            <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                        </div>
                                    </div>
                                    <div class="col-2 text-center">
                                         <img
                                                src="assets/images/refresh_image.png" (click)="getStartupCaptcha()"
                                                height="26px" />
                                    </div>
                                    <div class="col-5">
                                        <div class="input-group ">
                                            <input type="text" name="captchaStr1" #captchaStr1="ngModel" minlength="4"
                                                maxlength="4"
                                                [ngClass]="{ 'is-invalid': citizenLoginWithMobileOtpFormHtml.submitted && captchaStr1.invalid }"
                                                required [(ngModel)]="credential.captchaStr"
                                                class="form-control input_user" (contextmenu)="false;"
                                                [appCopyPastValidation]="true"
                                                placeholder="{{'enter-captcha' | translate}}" id="captchaStr1">
                                            <div *ngIf="citizenLoginWithMobileOtpFormHtml.submitted && captchaStr1.invalid"
                                                class="invalid-feedback">
                                                <div *ngIf="captchaStr1.errors?.required">{{'required' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <button type="submit" mat-button class="btn btn-primary btn-block">{{'submit' |
                                            translate}}</button>
                                    </div>
                                    <div class="col-8">
                                        <button type="button" mat-button class="btn btn-secondary btn-block"
                                            (click)="LoginWithPassword();"
                                            style="float: right;">{{'citizen-profile-details.login-detail-profile.login-password'
                                            | translate}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div id="or">OR</div>


                        <div class="row justify-content-center mt-2">
                            <div class="col-lg-12 text-center ">
                                <!-- <h3 style="color: #074476;font-size: 16px;margin-bottom: 5px;">{{'dont-have' | translate}}  {{'account' | translate}}</h3> -->

                                <div class="mt-1 w-100 d-flex justify-content-between">
                                    <div class="links text-center">
                                            <span *ngIf="lan==0">{{'dont-have' | translate}} {{'account' | translate}}</span>
                                        <span *ngIf="lan==1"> {{'account' | translate}} {{'dont-have' | translate}}</span> <a mat-button class=" "
                                            routerLink="/registration"><i class="fa fa-user-plus"></i>
                                            {{'citizen-registration' | translate}}</a>
                                        <!-- <a mat-button class=" " routerLink="/quick-reg-demographic"><i
                                                class="fa fa-user-plus"></i> {{'citizen-registration-search-doc' |
                                            translate}}</a> -->

                                        <!-- <a class="d-inline float-right" routerLink="/"> <i class="fa fa-home"></i>  Home  </a>  -->
                                    </div>

                                    <div class="links">
                                        <a mat-button [queryParams]="{ source: 'activate-user'}"
                                            routerLink="/citizen/forgot-password"><i class="fa fa-user"></i>
                                            {{'activate-user' | translate}}</a>
                                    </div>


                                </div>


                                <div class="user_card d-none">
                                    <div class="d-none justify-content-center">
                                        <div class="login_logo_container"> <img src="assets/images/logo-1.png"
                                                class="login_logo" alt="Logo"> </div>
                                    </div>

                                    <!-- <div class="mt-4">
                                    <div class="d-flex justify-content-center links"><a routerLink="/citizen/forgot-password">Forgot your password?</a> </div>
                                </div> -->

                                    <!-- <div class="mt-2">
                                    <div class="d-flex justify-content-center links"><a class="backtohome"><i class="fa fa-home"></i> </a> </div>
                                </div> -->




                                    <hr id="formLine" class="d-none">
                                </div>



                            </div>

                        </div>

                    </div>

                    <div class="col-md-4 bg-light d-none d-md-block">
                        <div class="d-flex h-100 align-items-center twxt-center">
                            <img src="assets/images/forgot-password.png" class="w-100" alt="Forget Password">
                        </div>
                    </div>

                    <!-- <div class="col-lg-5 col-md-5 col-xs-12 col-sm-12 hidden-xs col-lg-pull-7 pl-5 pr-5 d-none">
                        <div class="loginimgBox">
                        <div class="d-flex text-center" style="align-items: center;">

                            <img class="mr-2" src="assets/images/logo-1.png" width="70" style="position: relative;"> <h2 class="m-0" style="font-size: 36px;    font-weight: 600;    color: #034377;">  SAMPADA 2</h2>
                        </div>


                            <img src="assets/images/loginimg.png" class="w-100">
                            <img src="assets/images/loginimg2.png" class="w-100 slideanimation">
                            <img src="assets/images/loginimg3.png" class="w-100 slideanimation">
                            <img src="assets/images/loginimg4.png" class="w-100 slideanimation2">
                            <img src="assets/images/loginimg5.png" class="w-100 slideanimation2">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>




    </div>

</div>
</div>

<!-- <a  (click)="openModal(afterloign)" class="btn-login mr-2"  >
    <i class="fa fa-lock"></i> &nbsp;hi
</a> -->

<!-- <ng-template #afterloign let-modal>
    <div class="loginoptionsmodal">
        <div class="modal-body pt-3 pb-3 text-center">
            <fieldset style="    border: 1px solid #bec5ed;
            box-shadow: 0 0 1px #185ee026, 0 6px 12px #185ee026;
            padding: 34px 10px 13px;">

                <h3 style="font-size: 20px;">
                    For Document Search and Download Certified Copy <a role="button"
                        (click)="getUserEncDataForSearchDoc()" #searchandcopy color="primary"
                        style="color: rgb(12, 100, 151);font-size: 17px;"> Click Here</a>

                </h3>
                <h3 style="font-size: 20px;">
                    To explore SAMPADA 2.0 other services <a role="button" (click)="gotoSampadaServices()" #sampada
                        color="primary" style="color: rgb(12, 100, 151);font-size: 17px;"> Click Here</a>

                </h3>
            </fieldset>
        </div>
    </div>

</ng-template> -->


<front-footer></front-footer>
</div>
<ngx-ui-loader [loaderId]="'loader-citizen-login'"></ngx-ui-loader>

<h2 mat-dialog-title class="Consenterdialog-title">{{'duty-calculation.constDetls' | translate}}</h2>

    <button mat-button mat-dialog-close class="colsebtn"> <em class="fa fa-times"></em></button>


<mat-dialog-content class="dialog-content Consenterdialog">
    <lib-alertmessage></lib-alertmessage>
    <div class="row pb-3">
        <div class="col-md-12">
            <div class="form-group row">
                <label class="col-md-4">{{'duty-calculation.propValId' | translate}}</label>
                <div class="col-md-8">
                    <input type="number" class="form-control" value="{{propertyValId}}" name="" readonly />
                </div>
            </div>
        </div>
        <div class="col-md-12">

            <div class="form-group row">
                <label class="col-md-4">{{'duty-calculation.propId' | translate}}</label>
                <div class="col-md-8">
                    <ng-select #propSelect name="propSelect" placeholder="{{ 'duty-calculation.select' | translate }}"
                         [clearable]="true" [(ngModel)]="propIdSelect"
                        (ngModelChange)="onPropertySelect()">
                            <ng-option [value]="item" *ngFor="let item of propertylist">
                                <div > {{item}}</div>
                            </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group row">
                <label class="col-md-4">{{'duty-calculation.totalConsenter' | translate}}</label>
                <div class="col-md-8">
                    <input type="number" class="form-control" value="" name="totalConsenter" [(ngModel)]="consDetlsAttribute.totalConsenter"/>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group row">
                <label class="col-md-4">{{'duty-calculation.withConsideration' | translate}}</label>
                <div class="col-md-8">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="Considerationself" (click)="checkBtnConsideration('self_tab')" />
                        <label class="form-check-label" for="inlineRadio1">{{'duty-calculation.Yes' | translate}}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" value="!Considerationself"  (click)="checkBtnConsideration('other_tab')"
                        type="radio"
                            name="inlineRadioOptions" id="inlineRadio2"  [checked]="!Considerationself"/>
                        <label class="form-check-label" for="inlineRadio2"> {{'duty-calculation.No' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12" id="self_tab" [hidden]="!Considerationself">
            <div class="form-group row">
                <label class="col-md-4">{{'duty-calculation.enterConsdAmt' | translate}}</label>
                <div class="col-md-8">
                    <input type="number" class="form-control" value="" name="considerationAmount" [(ngModel)]="consDetlsAttribute.considerationAmount"/>
                </div>
            </div>
        </div>

        <div class="col-md-12 mb-2">
            <div class="form-group row">
                <label class="col-md-4"> &nbsp; </label>
                <div class="col-md-8">
             <button mat-raised-button color="primary" (click)="addConsenterDetls()">{{'duty-calculation.add' | translate}}</button>
       </div>
       </div>
            </div>

        <div class="col-md-12 mt-1 mb-3"   *ngIf="consenterDetailsList!=null">
            <div *ngIf="consenterDetailsList.length>0" class="table-responsive" style="box-shadow: none;">


                  <table class="table table-bordered">
                     <thead class="thead-dark">
                       <tr>
                         <th>{{'duty-calculation.SNO' | translate}}</th>
                         <th>{{'duty-calculation.totalConsenter' | translate}}</th>
                         <th>{{'duty-calculation.withConsideration' | translate}}</th>
                         <th>{{'duty-calculation.consdAmt' | translate}}</th>
                        <th>{{'duty-calculation.action' | translate}}</th>

                       </tr>
                     </thead>
                     <tbody>
                       <tr *ngFor="let field of consenterDetailsList; let i = index">
                         <td>{{i+1}}</td>
                         <td>
                           <!-- <input [(ngModel)]="field.totalConsenter" readonly class="form-control" type="text" name="{{field.totalConsenter}}" /> -->
                          {{field.totalConsenter}}
                         </td>
                        <td>
                           <!-- <input [(ngModel)]="field.withConsideration" readonly class="form-control" type="text" name="{{field.withConsideration}}" /> -->
                           {{field.withConsideration == true?('duty-calculation.Yes' | translate):('duty-calculation.No' | translate) }}
                        </td>
                         <td>
                            {{field.considerationAmount}}
                         </td>
                        <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
                           <mat-icon title="delete" (click)="deleteConsenterDetls(i,field)">delete</mat-icon>
                        </button>
                       </tr>

                     </tbody>
                   </table>

            </div>
         </div>

        <div class="col-md-12 text-right">
            <button mat-raised-button color="primary" (click)="saveConsenterDetls()">{{'duty-calculation.save' | translate}}</button>
        </div>
    </div>
    </mat-dialog-content>

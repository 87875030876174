import { Component, Input, OnInit, Output, Pipe, PipeTransform, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
// import { User } from 'projects/common-lib/src/lib/models/user.login.model/user.login.model';
import { Citizen, CitizenForgot } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.login.model';
import { Captcha, IUserDetailModel } from 'projects/common-lib/src/lib/models';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, Subscription, fromEvent, timer } from 'rxjs';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Title } from '@angular/platform-browser';

import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';

import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';

import { takeUntil } from 'rxjs/operators';
import { CitizenService } from 'projects/front-web/src/app/service/citizen.service';
import { DefaultServiceService } from 'projects/front-web/src/app/default/default-service.service';

@Component({
  selector: 'citizen-app-login',
  templateUrl: './citizen-login.component.html',
  styleUrls: ['./citizen-login.component.scss'],
  //encapsulation:ViewEncapsulation.None
})
export class CitizenLoginComponent implements OnInit {

  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  passhide = true;
  show = false;
  autohide = true;
  credential: Citizen = {};
  submitted = false;
  public forgotFlag: number = 0;
  captchaHandler?: Captcha = {};
  captchaImageBase64Img?: string = "";
  login: boolean = true;
  loginwithmobile: boolean = false;
  loginwithmobileotp: boolean = false;
  mobile: string;
  otp: string;
  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec
  lan: number = 1;
  lanVal: string = sessionStorage.getItem("selectedLanguage") || "hi";
  checkedVal: boolean;
  paramType: string;
  responseData: any;
  //afterloign: any;
  kycDetails: boolean = false;
  citizenLogin: boolean = true;
  kycSource: string;
  kycObject: any;
  citizenId: number;
  private unsubscriber: Subject<void> = new Subject<void>();
  uid: string;
  keyParam: string;

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;

  @ViewChild('afterloign') afterloign: TemplateRef<any>;
  private settings!: AppSetting;
  private currentUserSubject: BehaviorSubject<IUserDetailModel>;
  public ud!: IUserDetailModel;
  citizenForgot: CitizenForgot = new CitizenForgot();

  userList: CitizenUserProfileDetails[] = [];
  isUser: boolean = false;
  userNotFound: boolean = true;
  selectedUser: number;

  constructor(private translateService: TranslateHEService,
    private appSettingsService: AppSettingService,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private titleService: Title,
    private citizenAuthenticationService: CitizenAuthenticationService,
    private userService: CitizenService,
    private defaultServiceService: DefaultServiceService,
    private translate: TranslateService,
    private route: ActivatedRoute) {

    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });

    this.currentUserSubject = new BehaviorSubject<IUserDetailModel>(null as any);

    //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    //this.translateService.updateApprovalMessage(this.lan);
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg == 1 ? 'hi' : 'en')
      //this.titleService.setTitle(msg==1?'Public User,Registration and Stamp, Commercial Tax Department, Government of Madhya Pradesh':'सार्वजनिक लॉगिन,पंजीयन एवं मुद्रांक विभाग (वाणिज्यिक कर) मध्य प्रदेश')
    })
  }

  public get currentUserValue(): IUserDetailModel {
    return this.currentUserSubject.value;
  }

  @ViewChild('citizenLoginFormHtml') form: any;
  ngOnInit(): void {
    history.pushState(null, '');
    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        history.pushState(null, '');
        //this.showError = true;
      });
    //
    this.defaultServiceService.setDefaultType('citizen_login');
    //
    this.route.queryParams.subscribe(param => {
      //if (param['status'] != undefined && param['status'] == "1")
      //console.log("param['type']", param.type);
      this.paramType = param.type;
      this.uid = param['uid'];
      this.keyParam = param['keyParam'];
      if (this.uid != undefined && this.uid != 'undefined' && this.uid != '' && this.uid != null && this.keyParam != undefined && this.keyParam != 'undefined' && this.keyParam != '' && this.keyParam != null) {
        //
        //console.log("-----------IGRS_SEARC_CITIZEN-------------", this.uid)
        this.loginSearchModule(this.uid, this.keyParam);
      }
    })

    if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {
      //this.citizenAuthenticationService.getprofileUsingToken(sessionStorage.getItem("token"));
      this.router.navigate(['./citizen/profile']);
    } else {
      // if (this.paramType == undefined || this.paramType == "")
      //this.citizenAuthenticationService.logout();
      this.citizenAuthenticationService.logout().subscribe(res => {
        res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
      });
      this.getStartupCaptcha();
    }

  }

  // ngAfterViewInit() {
  //   if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {
  //     this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "")
  //     this.citizenId = this.userProfileModel.id;
  //     var fullProfileValue = JSON.parse(sessionStorage.getItem('authData')).isFullProfile;
  //     if (fullProfileValue != null && fullProfileValue == '1') {
  //       if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {
  //         //this.citizenAuthenticationService.getprofileUsingToken(sessionStorage.getItem("token"));
  //         this.router.navigate(['./citizen/profile']);
  //       } else {
  //         if (this.paramType == undefined || this.paramType == "")
  //           this.citizenAuthenticationService.logout().subscribe(res => {
  //             res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
  //           });
  //         this.getStartupCaptcha();
  //       }
  //     } else if (fullProfileValue != null && fullProfileValue == '0') {
  //       this.router.navigate(['./citizen/profile']);
  //       //this.openModal(this.afterloign);
  //     }
  //   } else {
  //     if (this.paramType == undefined || this.paramType == "")
  //       this.citizenAuthenticationService.logout().subscribe(res => {
  //         res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
  //       });
  //     this.getStartupCaptcha();
  //   }
  // }

  resetPasswordField() {
    //const username = (<HTMLInputElement>document.getElementById('userName')).value;
    let username = this.credential.userName;
    if (username == "") {
      this.credential.password = undefined;
      this.credential.captchaId = undefined;
      this.credential.captchaStr = undefined;
      this.getStartupCaptcha();
    }
  }

  changeContactNo() {
    this.userList = [];
    this.isUser = false;
    if (this.mobile != null || this.mobile != undefined || this.mobile?.trim() != '') {
      this.ngxService.start();
      this.userNotFound = false;
      let param = { mobileNo: this.mobile };
      this.citizenAuthenticationService.getUserListByContactNo(param).subscribe(result => {
        this.ngxService.stop();
        if (result.responseStatus == 'true') {
          this.selectedUser = null;
          if (result.responseData.length > 1) {
            this.isUser = true;
            this.userNotFound = false;
            this.userList = result.responseData;
          } else if (result.responseData.length == 0) {
            this.isUser = false;
            this.userNotFound = false;
            this.alertmessage.errorAlertMessage('office-not-assign');
          } else {
            this.isUser = false;
            this.userNotFound = false;
            this.selectedUser = result.responseData[0].id;
            this.userList = result.responseData;
          }

        } else {
          this.isUser = false;
          this.userNotFound = true;
          this.alertmessage.errorAlertMessage(result);
        }

      },
        err => {
          this.isUser = false;
          this.ngxService.stop()
          //  Swal.fire("Error", "TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !", "error");
          this.alertmessage.errorAlertMessage('tech_error_msg');
        }
      );
    }
  }

  selectUser() {
    console.log("Select User---------", this.selectedUser)
  }

  loginSearchModule(id: string, keyParam: string) {
    this.ngxService.start();
    this.citizenAuthenticationService.loginSearchModule(id).pipe().subscribe(
      data => {
        if (data.status == 'true') {
          this.ngxService.stop()
          this.userProfileModel = data.data.responseData;

          //  console.log(this.userProfileModel);

          //if(this.userProfileModel.userName)
          //if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {
          //var fullProfileValue = JSON.parse(sessionStorage.getItem('authData')).isFullProfile;
          //  if (fullProfileValue != null && fullProfileValue == '1') {
          //if (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) {

          //this.citizenAuthenticationService.getprofileUsingToken(sessionStorage.getItem("token"));

          this.router.navigate(['./citizen/' + keyParam]);
         // console.log('122');
          // if ( === 'W')
          //   this.router.navigate(['./citizen/viewWallet']);
          // else
          //   this.router.navigate(['./citizen/viewDetailedStatements']);


          // } else {
          //   if (this.paramType == undefined || this.paramType == "")
          //   this.citizenAuthenticationService.logout().subscribe(res => {
          //     res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
          //   });
          //   this.getStartupCaptcha();
          // }
          // } else if (fullProfileValue != null && fullProfileValue == '0') {
          //   this.openModal(this.afterloign);
          // }
          //}
          //
        }
        else {
          //this.form.reset();
          this.ngxService.stop()
          // Swal.fire("Error", data.data.responseMessage, "error")
          this.alertmessage.errorAlertMessage(data.data);
          this.getStartupCaptcha()
        }
      },
      err => {
        this.ngxService.stop()
        // Swal.fire("Error", "TECHNICAL ERROR !", "error");
        this.alertmessage.errorAlertMessage('tech_error_msg');
      })
  }


  reloadCurrentPage() {
    let currentUrl = this.router.url;
    console.log("currentUrl", currentUrl);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getStartupCaptcha() {

    this.ngxService.startLoader("loader-citizen-login");
    this.citizenAuthenticationService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stopLoader("loader-citizen-login");
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.credential.captchaId = this.captchaHandler.responseData?.id;
      // 
    },
      err => {
        this.ngxService.stopLoader("loader-citizen-login");
        this.alertmessage.errorAlertMessage("TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !");
      }
    );
  }

  onSubmitForLogin() {
    this.submitted = true;
    // 
    //
    this.ngxService.startLoader("loader-citizen-login");
    this.citizenAuthenticationService.login(this.credential, this.translate.currentLang).pipe().subscribe(
      data => {
        if (data.status == 'true') {

          //Call API For wallet creation
          this.userService.generateWalletForNewUser().pipe().subscribe(
            data => {
              if (data.status == 'true') { }
              else { }
            })

          this.ngxService.stopLoader("loader-citizen-login");
          this.router.navigate(['citizen/dashboard/']);
        }
        else {
          this.form.reset();
          this.ngxService.stopLoader("loader-citizen-login");
          this.alertmessage.errorAlertMessage(data.data);
          this.getStartupCaptcha()
        }
      },
      err => {
        this.ngxService.stopLoader("loader-citizen-login");
        this.alertmessage.errorAlertMessage("TECHNICAL ERROR !");
      })
  }

  LoginWithPassword() {
    this.login = true;
    this.loginwithmobile = false;
    this.loginwithmobileotp = false;
    this.getStartupCaptcha();
    this.mobile = "";
    this.otp = "";
    this.credential = {};
  }

  LoginWithMobile() {
    this.login = false;
    this.loginwithmobile = true;
    this.loginwithmobileotp = false;
    this.mobile = "";
    this.otp = "";
    this.credential = {};
    this.getStartupCaptcha();
  }

  onSubmitForLoginMobile() {
    this.ngxService.start();
    // this.citizenForgot.userId = this.selectedUser
    // this.citizenForgot.mobileNo = this.mobile;
    // this.citizenForgot.userType = 'citizen';
    this.credential.userId = this.selectedUser
    this.credential.mobileNo = this.mobile;
    this.credential.userType = 'citizen';
    if (!this.userNotFound) {
      this.citizenAuthenticationService.generateOtpForUser(this.credential).pipe().subscribe(
        data => {
          if (data.responseStatus == 'true') {
            this.getStartupCaptcha();
            this.counter = 30; // 3 min 3*60
            this.login = false;
            this.loginwithmobile = false;
            this.loginwithmobileotp = true;
            this.countDown = timer(0, this.tick).subscribe(() => {
              if (this.counter > 0) {
                --this.counter;
              }
            })

            this.alertmessage.successAlertMessage(data);
            this.ngxService.stop()
            //this.router.navigate(['citizen/dashboard/']);
          }
          else {
            this.ngxService.stop()
            this.login = false;
            this.loginwithmobile = true;
            this.loginwithmobileotp = false;
            this.alertmessage.errorAlertMessage(data);
            this.getStartupCaptcha()
          }
        },
        err => {
          this.ngxService.stop()
          this.login = false;
          this.loginwithmobile = true;
          this.loginwithmobileotp = false;
          this.alertmessage.errorAlertMessage("TECHNICAL ERROR !");
        })
    }

  }

  onSubmitForLoginMobileOtp() {
    this.ngxService.start();
    this.credential.userId = this.selectedUser;
    this.credential.mobileNo = this.mobile;
    this.credential.otp = this.otp;
    this.citizenAuthenticationService.loginMobileWithOTP(this.credential, this.translate.currentLang).pipe().subscribe(
      data => {
        if (data.status == 'true') {
          this.responseData = data;
          let responseSTR = JSON.stringify(data);
          let responseJSON = JSON.parse(responseSTR);
          //console.log("sdsadasdsad", responseJSON.data.responseData.isFullProfile);

          //Call API For wallet creation
          this.userService.generateWalletForNewUser().pipe().subscribe(
            data => {
              if (data.status == 'true') { }
              else { }
            })

          this.login = true;
          this.loginwithmobile = false;
          this.loginwithmobileotp = false;

          this.ngxService.stop()
          this.mobile = "";
          this.otp = "";

          // if (responseJSON.data.responseData.isFullProfile == 0) {
          //   //this.router.navigate(['clogin/']);
          //   this.openModal(this.afterloign);
          //   //this.ngxService.stop()
          // } else {
          //   //this.setSession(this.responseData);
          //   this.router.navigate(['citizen/dashboard/']);
          // }
          this.router.navigate(['./citizen/profile']);
        }
        else {
          this.ngxService.stop()
          this.login = false;
          this.loginwithmobile = false;
          this.loginwithmobileotp = true;
          this.otp = "";
          this.credential = {};
          this.alertmessage.errorAlertMessage(data.data);
          this.getStartupCaptcha()
        }
      },
      err => {
        this.ngxService.stop()
        this.login = false;
        this.loginwithmobile = false;
        this.loginwithmobileotp = true;
        this.otp = "";
        this.credential = {};
        this.alertmessage.errorAlertMessage("TECHNICAL ERROR !");
      })

  }

  // gotoSampadaServices() {
  //   if (this.userProfileModel.kycType == null) {
  //     Swal.fire({
  //       title: "Mandatory to perform eKYC",
  //       text: "It is mandatory to perform eKYC before To explore SAMPADA 2.0 other services. Are you want to perform eKYC?",
  //       icon: "error",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No, keep it",
  //       allowOutsideClick: false,
  //       customClass: {
  //         actions: 'my-actions',
  //         cancelButton: 'order-1 right-gap',
  //         confirmButton: 'order-2',
  //         denyButton: 'order-3',
  //       }
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         this.kycDetails = true;
  //         this.citizenLogin = false;

  //         this.login = true;
  //         this.loginwithmobile = false;
  //         this.loginwithmobileotp = false;

  //         this.ngxService.stop()
  //         this.mobile = "";
  //         this.otp = "";
  //         //this.setSession(this.responseData);
  //           //this.router.navigate(['clogin']);
  //         //this.router.navigate(['kyc-details']);
  //         this.modalService.dismissAll();

  //       }
  //       else if (result.isDenied) { }
  //       else if (result.isDismissed) {
  //         //this.router.navigate(['citizen/dashboard/']);
  //       }

  //       return false;
  //     });
  //     //return false;
  //   }
  // }


  // getUserEncDataForSearchDoc() {
  //   const param = {};

  //   //this.setSession(this.responseData);

  //   this.lcncService.getCitizenEncData(param).subscribe(responseData => {
  //     if (responseData.responseStatus) {
  //       responseData.responseData;
  //       let menuName: any = 'SearchDocument';
  //       this.redirectLCNCRequest(responseData, menuName);
  //     }
  //   });
  // }

  // redirectLCNCRequest(param: any, menuName: any) {
  //   let f = document.createElement("form");
  //   f.setAttribute("method", "post");
  //   f.setAttribute(
  //     "action",
  //     //"http://10.115.96.181:8080/ords/r/igrs/sam21/slot-statitics-report"
  //     this.settings.lcnc_redirect_url

  //     // "http://140.238.255.245:8080/ords/mp_tribal/getHomePage/getParameters"
  //   ); //Your action URL
  //   //f.setAttribute("target", "_blank");
  //   //create hidden input elements

  //   // let i = document.createElement("input");
  //   // i.type = "hidden";
  //   // i.name = "encData";
  //   // i.value = param.responseData1;
  //   // f.appendChild(i); //Add it to the form

  //   let j = document.createElement("input");
  //   j.type = "hidden";
  //   j.name = "token";
  //   j.value = param.responseData.token;
  //   f.appendChild(j); //Add it to the form

  //   let k = document.createElement("input");
  //   k.type = "hidden";
  //   k.name = "userType";
  //   k.value = "citizen";
  //   f.appendChild(k); //Add it to the form

  //   let l = document.createElement("input");
  //   l.type = "hidden";
  //   l.name = "redirectionmodule";
  //   l.value = param.responseData.redirectionmodule;
  //   f.appendChild(l); //Add it to the form

  //   document.body.appendChild(f);//Add the form to the body
  //   f.submit(); //Submit it immediately

  // }

  changeLan(ev: any) {
    //

    if (ev.value == 1) {
      this.lan = 0;
      sessionStorage.setItem("selectedLanguage", 'en');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.translate.use(sessionStorage.getItem("selectedLanguage") || "en");
      this.translateService.updateApprovalMessage(this.lan);
    } else {
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage", 'hi');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //  this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi");
      this.translateService.updateApprovalMessage(this.lan);
    }
    //window.location.reload();
    this.translate.get("citizen-title").subscribe(name => {
      this.titleService.setTitle(name);

    });
  }

  openModal(afterloign: any) {
    //console.log("sdsadasdsad",event.srcElement.firstChild.data);
    this.modalService.open(afterloign, { centered: true, windowClass: "myCustomModalClass" }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // verifyAdharOtpOut(event: any) {

  //   if (event != null && event.ret == 1) {
  //     this.kycObject = event;
  //     this.kycSource = 'Aadhaar';

  //     const param = {
  //       "kyc_unique_token": this.kycObject.residentDetails.uidToken
  //     }
  //     this.citizenAuthenticationService.checkAadhaarAlreadyExist(param).subscribe(res => {
  //       // 
  //       if (res && res.responseStatus == 'true') {
  //         this.modalService.dismissAll();
  //         this.updateCitizenProfileDetail();
  //         this.alertmessage.successAlertMessage(res);
  //       } else {
  //         this.openModal(this.afterloign);
  //         this.alertmessage.errorAlertMessage(res);
  //       }
  //     });

  //   } else {
  //     this.alertmessage.errorAlertMessage('Aadhaar Kyc Failed!!! ');
  //   }
  // }

  // verifyPanOut(event: any) {
  //   // 
  //   if (event != null && event.skip == 1) {
  //     this.kycObject = null;
  //     this.kycSource = '';
  //   } else if (event != null && event.status == 1) {
  //     this.kycObject = event;
  //     this.kycSource = 'Pan';

  //     this.citizenAuthenticationService.checkPanAlreadyExist(this.kycObject.pan, '').subscribe(res => {
  //       // 
  //       if (res && res.responseStatus == 'true') {
  //         this.modalService.dismissAll();
  //         this.updateCitizenProfileDetail();
  //         this.alertmessage.successAlertMessage(res);
  //       } else {
  //         this.openModal(this.afterloign);
  //         this.alertmessage.errorAlertMessage(res);
  //       }
  //     });
  //   } else {
  //     this.alertmessage.errorAlertMessage('PAN Kyc Failed!!! ');
  //   }
  // }

  //   updateCitizenProfileDetail() {

  //     this.userProfileModel.kycType = this.kycSource
  //     let counter = this.userProfileModel.kycType;
  //     console.log("---------------save-------------------- ", counter);

  //     if (this.userProfileModel.kycType == 'Aadhaar') {
  //       this.userProfileModel.kycTxnLogNo = this.kycObject.aadharTxnLogNo;
  //       this.userProfileModel.kycUniqueToken = this.kycObject.residentDetails.uidToken;
  //     } else if (this.userProfileModel.kycType == 'Pan') {
  //       this.userProfileModel.kycTxnLogNo = this.kycObject.txno;
  //       this.userProfileModel.kycUniqueToken = this.kycObject.pan;
  //     }

  //     this.userService.updateCitizenProfile(this.userProfileModel).subscribe(res => {
  //       this.ngxService.stop();

  //       if (res.responseStatus == "true") {
  //         this.alertmessage.successAlertMessage(res);
  //         this.kycDetails = false;
  //         //this.applyForSPLicense = true;

  //         setTimeout(() => {
  //           this.router.navigate(['citizen/profile']).then(() => {
  //             window.location.reload();
  //           });
  //         }, 2500);
  //       } else {
  //         this.alertmessage.errorAlertMessage(res);
  //       }
  //     })
  //   }

}



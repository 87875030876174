import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appCopyPastValidation]'
})
export class CopyPastValidationDirective {

  constructor(private el: ElementRef) { }

  @Input() appCopyPastValidation: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event:any) {
    let e = <KeyboardEvent> event;
    if (this.appCopyPastValidation) {
        // Ensure that it is a number and stop the keypress
        if (e.keyCode == 17 || e.ctrlKey === true) {
            e.preventDefault();
        }
      }
  }

}

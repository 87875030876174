import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'front-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  lan: number;



 
  constructor(
    private translate: TranslateService,
    private translateServiceIns: TranslateService,
    private translateService: TranslateHEService,
  ) {
       try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }

     // translate.use(selectedLanguage).toPromise();
     // this.lan = selectedLanguage == 'hi' ? 1 : 0;
    
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
       this.lan = msg
       this.translate.use(msg==1?'hi':'en')
      }
        );
    } catch (err) {
      console.log(err);
    }

   }

  ngOnInit(): void {
  }

}

import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appRestrictedNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: RestrictedNumberValidatorDirective, multi: true }]
})
export class RestrictedNumberValidatorDirective implements Validator {
  @Input('appRestrictedNumber') totalArea: number;

  validate(control: AbstractControl): { [key: string]: any } | null {
    return restrictedNumberValidator(this.totalArea)(control);
  }
}

export function restrictedNumberValidator(totalArea: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value < totalArea * 0.96 || value > totalArea * 1.04) {
      return { restrictedNumber: true };
    }
    return null;
  };
}
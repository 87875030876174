<div *ngIf="eregFlag==null || eregFlag == undefined || eregFlag==0">
    <form (ngSubmit)="onSubmitPartyResponse(f)" #f="ngForm">

        <fieldset class="legend-section">
            <legend class="sidebar__widget-title" *ngIf="!statusCheck && !partyStatus && isRemarkShow && isActionButtonShow">
                {{'e-stamp.Please-enter-remark' | translate}} <span class="text-danger">*</span>
            </legend>
            <div class="row">
                <div class="col-md-4">

                    <div class="form-group" *ngIf="!statusCheck && !partyStatus && isActionButtonShow">
                        <select class="form-control"  name="partyResponse" [(ngModel)]="estampPartyContentModel.status" #item (change)="partyResponseOnChange(item.value)"
                        #partyResponse="ngModel" required placeholder="{{'userDeedTemplate.select-placeholder' |translate}}">
                            <option  [disabled]="true" [value]="null">{{'userDeedTemplate.select-placeholder' |translate}}</option>
                            <option *ngFor="let nextAction of nextActionModelArray" [value]=nextAction.id >{{lan==0?nextAction.nameEn:nextAction.nameHi}}</option>
                         </select>
                         <div *ngIf="partyresponseCheck">
                            <div class="alert alert-danger error_message p-1 float-right mb-0">{{'e-stamp.Your-response-required' | translate}}</div>
                        </div>
                    </div>

                </div>

                <div class="col-md-8" *ngIf="!statusCheck && !partyStatus && isRemarkShow && isActionButtonShow">

                    <div class="form-group">
                         <!-- <img class="rounded-circle" src="https://i.imgur.com/RpzrMR2.jpg" width="40"> -->
                         <textarea name="remarks" [(ngModel)]="estampPartyContentModel.remarks" #remarks="ngModel"
                         placeholder="{{'RenameGuidelineLocation.EnterYourRemarks' |translate}}" rows ="2"  class="form-control" required></textarea>
                         <div *ngIf="remarksCheck" class="d-block">
                             <div class="alert alert-danger error_message p-1 float-right mb-0">{{'e-stamp.party-remark-reqrired' | translate}}</div>
                         </div>
                    </div>
                </div>
            </div>
               <!-- comment History -->


        </fieldset>


        <div class="row" >
            <div class="col-md-2" *ngIf="!statusCheck && !partyStatus && isActionButtonShow">
                <div mat-raised-button (click)="resetEstampAddPartyDetails()"  name="reset"
                class="mr-2 w-100  float-right btn btn-secondary">{{'reset' | translate}}</div>

            </div>
            <div class="col-md-2 " *ngIf="!statusCheck && !partyStatus && isActionButtonShow">
                <button type="submit" mat-raised-button  color="primary" name="addRemark"  id="addRemark"
    class="mr-2 w-100 float-right"  [disabled]="onFormSubmit">{{'SUBMIT-RESPONSE' | translate}}</button>
            </div>
    <!-- <div class="col-md-2" >
                <button mat-raised-button (click)="backToList()"  name="reset"
                class="mr-2 w-100  float-right btn btn-secondary">{{'e-stamp.back' | translate}}</button>
            </div> -->
        </div>






    </form>
    </div>
 <!-- for eregEstamp -->
<div *ngIf="eregFlag==1">
 <form (ngSubmit)="onSubmitPartyResponse(f2)" #f2="ngForm">

    <fieldset class="legend-section" *ngIf="!statusCheck && !partyStatus && isActionButtonShow">
        <legend class="sidebar__widget-title">
            {{'e-stamp.Please-enter-remark' | translate}}
        </legend>
        <div class="row">
            <div class="col-md-12">

                <div class="form-group">
                     <!-- <img class="rounded-circle" src="https://i.imgur.com/RpzrMR2.jpg" width="40"> -->
                     <textarea name="remarks" id="remarks" [(ngModel)]="estampPartyContentModel.remarks" #remarks="ngModel"
                     placeholder="Enter your remarks" rows ="2"  class="form-control" required></textarea>
                     <div *ngIf="remarksCheck" class="d-block">
                         <div class="alert alert-danger error_message p-1 float-right mb-0">{{'e-stamp.party-remark-reqrired' | translate}}</div>
                     </div>
                </div>
            </div>
        </div>
    </fieldset>

    <div class="d-flex justify-content-end" *ngIf="!statusCheck && !partyStatus && isActionButtonShow">
        <!-- <button type="button" class="btn btn-secondary" (click)="backToList()">{{'goback' | translate}}</button> -->


 
            <button type="button" class="btn btn-primary mr-1" (click)="rejectDeed()">{{'proposalSection.SendDeedforAmendment' | translate}}</button>
            <button type="button"  class="btn btn-primary" (click)="acceptDeed()">{{'proposalSection.AcceptDeed' | translate}}</button>
 
    </div>



     

</form>
</div>
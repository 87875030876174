import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { IgrsSubClausePropertyMappingBean } from 'projects/common-lib/src/lib/models/IgrsSubClausePropertyMappingBean';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { PropertyValuationRequestBeanMap } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBeanMap';
import { PropertyValuationService } from '../property-valuation.service';
import { MstPropertyTypeBean } from 'projects/common-lib/src/lib/models/MstPropertyTypeBean';
import { PropertyValuationResponseBean } from 'projects/common-lib/src/lib/models/PropertyValuationResponseBean';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { Router } from '@angular/router';
import { DataService } from '../DataService';
import { AgriPropertyValuationRequestBean, MultipleFamilyBean } from 'projects/common-lib/src/lib/models/agriPropertyValuationRequestBean';
import { WebGisResponseBeanData } from 'projects/common-lib/src/lib/models/WebGisResponseData';
import { PropertyValuationState } from 'src/app/store/state/property-valuation.state';
import { Observable } from 'rxjs';
import { UOMEnum } from 'projects/common-lib/src/lib/models/UOMEnum';
import { Select, Store } from '@ngxs/store';
import { PropertyMasterPropetyTypeAction, PropertyMasterUomAction } from 'src/app/store/action/property-valuation.action';
import { NgForm, NgModel } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewShowMaketValueComponent } from 'projects/common-lib/src/lib/component/new-show-maket-value/new-show-maket-value.component';
import { BuildingPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/buildingPropertyValuationRequestBean';
import { ImpoundData } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { CommunicationServiceService } from '../../duty-calculation/duty-service/communication-service.service';
import { LoggerService } from 'projects/common-lib/src/lib/service/logger.service';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';

@Component({
  selector: 'app-agri-valuation',
  templateUrl: './agri-valuation.component.html',
  styleUrls: ['./agri-valuation.component.scss'],
})
export class AgriValuationComponent implements OnInit ,AfterViewInit{
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @Input() selectDemographyData:any
  @Input() propertyValuationRequestBeanData:AgriPropertyValuationRequestBean
  @Input() subClauseMasterBeanData:IgrsSubClausePropertyMappingBean[]
  @Input() uomListData:any
  @Input() webGisResponseData:WebGisResponseBeanData
  @Input() fromDutyModule:boolean = false;
  //@Input() fromEregModule:boolean = false;
  @Input() fromCMSModule:boolean = false;
  @Input() fromCheckerModule: boolean;
  @Input() isPartialTransactFlag: boolean;
  @Input() propertyTypeData:MstPropertyTypeBean[]
  @Input() builderShareData:number;
  @Input() isImpoundAddProperty:boolean;
  @Select(PropertyValuationState.getPropertyTypeList) mstPropertyTypeBean$: Observable<MstPropertyTypeBean[]>
  @Select(PropertyValuationState.getUomList) uomListAgri$: Observable<UOMEnum[]>
  @Select(PropertyValuationState.getPropertTypeLoaded) PropertTypeLoaded$:Observable<boolean>
  @Select(PropertyValuationState.getUomLoaded) uomListloaded$: Observable<boolean>
  @ViewChild('TotalArea') totalAreaModelref: NgModel;
  @ViewChild('undiverted') undiverted:ElementRef;
  @ViewChild('both') both:ElementRef;
  @ViewChild('holdsuccessfullyMessage') holdsuccessfullyMessage: TemplateRef<any>;
  @ViewChild('Adjacent') Adjacent: TemplateRef<any>;
  @ViewChild('myCheckbox') myCheckbox: ElementRef;
  @ViewChild('myCheckboxB') myCheckboxB: ElementRef;
  noOfBuyers:string="1"
  lan: any;
  mainAreaType: number = 3;
  
  totalAreaMsg: boolean = false;
  //showMarketValueBtn: boolean = true;
  conveyanceTypeMsg: boolean = false;
  commonAreaMsg: boolean = false;
  divertedErrMsg: boolean = false;
  uomList: any[] = new Array();
  selectedFloors: any = new Map();
  usageType: string;
  
  usage: string = "Diverted";
  isPartialTransact:boolean=false
  isResidential:boolean=false
  selectedMstDemographyBean: MstDemographyBean;
  mstPropertyTypeBean: MstPropertyTypeBean[] = new Array();
  subClassId:IgrsSubClausePropertyMappingBean[] = new Array();
  subClauseMasterBean: IgrsSubClausePropertyMappingBean[] = new Array();
  multipleFamilyBean:MultipleFamilyBean[]=new Array()
  multipleFamilyBeanBuyer:MultipleFamilyBean[]=new Array()
  propertyValuationResponseBean: PropertyValuationResponseBean = new PropertyValuationResponseBean();
  isCoowner:boolean=false
  agriOld: AgriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
 
  agriPropertyValuationRequestBean: AgriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
  propertyValuationRequestBean:PropertyValuationRequestBean=new PropertyValuationRequestBean();
  propertyValuationRequestBeanBuild:BuildingPropertyValuationRequestBean
  propertyValuationRequestBeanMap: PropertyValuationRequestBeanMap = new PropertyValuationRequestBeanMap();

  constructor(public matDialogRef: MatDialogRef<NewShowMaketValueComponent>,
    public matDialogRefAgri: MatDialogRef<AgriValuationComponent>,
    private dataService: DataService,
    private router: Router,
    private pvService: PropertyValuationService,
    private ngxService: NgxUiLoaderService,
    private translateService: TranslateHEService,
    private translate: TranslateService,
    private modalService: NgbModal,
    public utilityService:UtilityService,
    public dialog: MatDialog,
    public cdr :ChangeDetectorRef,
    private commServ : CommunicationServiceService,
    private store:Store,
    private logger:LoggerService,) {
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
  }
  
  ngOnInit(): void {
    this.isPartialTransact=this.isPartialTransactFlag
    this.uomList=new Array()
    this.getAllUOM();
    console.log("this.propertyValuationRequestBeanData",this.propertyValuationRequestBeanData)
    this.logger.log("this.propertyValuationRequestBeanData",this.propertyValuationRequestBeanData.address)
    if (this.propertyValuationRequestBeanData !== null&&this.propertyValuationRequestBeanData !== undefined)
    this.agriPropertyValuationRequestBean=this.propertyValuationRequestBeanData;
    this.subClauseMasterBean=this.subClauseMasterBeanData.filter(s=>{return s.propertyTypeId==3})

    this.subClauseMasterBean.filter((d) => {
      this.agriPropertyValuationRequestBean.subClause.filter((s:any) => {
          if (d.id == s.id) {
            d.checked = true;
           
          }
        });
      });

    this.mstPropertyTypeBean=this.propertyTypeData
    this.agriPropertyValuationRequestBean.builderShare = this.builderShareData;

   
  }

  ngAfterViewInit(){
    let errorSpan = document.getElementById('error');
    if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length>1 &&this.webGisResponseData?.isAdjacentKhashra!=undefined && this.webGisResponseData?.isAdjacentKhashra==false){
      this.openPrimary(this.holdsuccessfullyMessage);
    }

   
    if(this.agriPropertyValuationRequestBean) {

        this.totalAreaModelref?.valueChanges.subscribe(res=>{
          console.log(res)
          if(res!=0&&res!=null){
            if (!/^[0-9]+(\.[0-9]{1,4})?$/.test(res)) {
              errorSpan.textContent = this.lan==0?'Please enter a number with up to 4 digits after the decimal point.':'कृपया दशमलव बिंदु के बाद अधिकतम 4 अंकों वाली संख्या दर्ज करें।';
            } else {
              errorSpan.textContent = '';
            }
          this.unDivertedSubAreaEvent();
       
          this.usageEvent(this.agriPropertyValuationRequestBean.usage)
          if(this.agriPropertyValuationRequestBean.usage=='diverted'){
            this.undiverted.nativeElement.disabled = true;
            this.both.nativeElement.disabled = true;
            if(this.agriPropertyValuationRequestBean.commercialPlotArea!=null&&this.agriPropertyValuationRequestBean.commercialPlotArea!='0'){
              this.isResidential=true
            }
            }
          }
        });
        this.inputChangeEventValidation()

  }
  
  this.dataService.getDataBuildingDataAgri().subscribe(res=>{
    console.log("Building data",res)
    if(res!=null &&Object.keys(res).length!=0){
      console.log("Building data if",res)
      this.agriPropertyValuationRequestBean=res
    }
   
  })
 
  

  
  }

  onCheckDataPush(event:any){

    this.isPartialTransact=event.target.checked

  if(this.isPartialTransact){
    if(this.agriPropertyValuationRequestBean.overAllArea==undefined&&this.agriPropertyValuationRequestBean.totalArea!='0'){
      this.agriPropertyValuationRequestBean.overAllArea=this.agriPropertyValuationRequestBean.totalArea
    }

  }
  else{
    this.agriPropertyValuationRequestBean.totalArea=this.agriPropertyValuationRequestBean.overAllArea
    this.agriPropertyValuationRequestBean.partialTransactArea= this.agriPropertyValuationRequestBean.overAllArea
  }

    this.logger.log(event.target.checked)
    if(!event.target.checked){
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach(
      s=>s.partialTransactArea=s.totalArea
    )}


    this.agriPropertyValuationRequestBean.residentialPlotArea = '';
    this.agriPropertyValuationRequestBean.commercialPlotArea = '';
    this.agriPropertyValuationRequestBean.industrialPlotArea = '';
    this.agriPropertyValuationRequestBean.healthPlotArea = '';
    this.agriPropertyValuationRequestBean.eduPlotArea = '';
    this.agriPropertyValuationRequestBean.othersPlotArea = '';
    this.agriPropertyValuationRequestBean.irrigatedArea = '';
    this.agriPropertyValuationRequestBean.singleCropArea = '';
    this.agriPropertyValuationRequestBean.doubleCropArea = '';
  }

  getAllSubClause() {
    this.pvService.getAllSubClauses().subscribe(dmodata => {
      this.subClauseMasterBean = dmodata.responseData;
      this.logger.log(this.subClauseMasterBean)
    });

  }

  getAllUOM() {
    // this.uomListAgri$.subscribe(res=>{
    //   this.logger.log(res)
    //   this.uomList=res
    //   this.uomList=this.uomList.slice(2)
    // })
    this.pvService.getAllUOM().subscribe(dmodata => {
      // this.MstDemographyBeanList=dmodata.responseData;
      this.uomList = dmodata.responseData;;
      this.uomList=this.uomList.slice(2)
    });
  }

  getAllPropertyType() {

    this.PropertTypeLoaded$.subscribe(res=>{
      if(!res){
        this.store.dispatch(new PropertyMasterPropetyTypeAction())
        this.mstPropertyTypeBean$.subscribe(res=>{
          this.mstPropertyTypeBean=res
        })
      }
    })
    this.mstPropertyTypeBean.filter(pdata => { // indrajeet
      if (pdata.id == 3) {
        this.propertyValuationRequestBean.propertyType = pdata
      }
    });
  }
  convertedUOM(uom:any){
    return this.utilityService.convertedUOM(uom,this.lan)
  }

inputChangeEventValidation() {
    this.isActive=false
    if (this.agriPropertyValuationRequestBean.totalArea != undefined && this.agriPropertyValuationRequestBean.totalArea != '') {
      
      this.totalAreaMsg = false;
      if(!this.agriPropertyValuationRequestBean.isPartialTransact){
          this.agriPropertyValuationRequestBean.partialTransactArea =this.agriPropertyValuationRequestBean.totalArea
      }
        this.usageEvent(this.agriPropertyValuationRequestBean.usage);
        if (this.agriPropertyValuationRequestBean.usage == 'both') {
          if(this.agriPropertyValuationRequestBean.isPartialTransact){
            if (Number(this.agriPropertyValuationRequestBean.partialTransactArea) >= Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted)) {
              let substValue= (Number(this.agriPropertyValuationRequestBean.partialTransactArea) - Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted));

              this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted =substValue.toFixed(4);
             } else {
               this.divertedErrMsg = true;
               this.agriPropertyValuationRequestBean.totalSellableAreaDiverted = "";
               this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted = "";
             }
        }
          else{
            if (Number(this.agriPropertyValuationRequestBean.totalArea) >= Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted)) {
              let substValue= (Number(this.agriPropertyValuationRequestBean.totalArea) - Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted));

              this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted =substValue.toFixed(4);
             } else {
               this.divertedErrMsg = true;
               this.agriPropertyValuationRequestBean.totalSellableAreaDiverted = "";
               this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted = "";
             }
          }

        }
        else if (this.agriPropertyValuationRequestBean.usage == 'diverted' || this.agriPropertyValuationRequestBean.usage == 'both') {
          if(this.agriPropertyValuationRequestBean.plotAreaType=='RCumCom'){
            if(this.agriPropertyValuationRequestBean.isPartialTransact){
              if(this.agriPropertyValuationRequestBean.partialTransactArea==this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea){
                
                  }
            }
            else{
              if(this.agriPropertyValuationRequestBean.totalArea==this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea){
              
                  }
            }

          }


        } else if (this.agriPropertyValuationRequestBean.usage == 'undiverted') {

        }
      //}
    } else {
      this.totalAreaMsg = true;
      this.agriPropertyValuationRequestBean.partialTransactArea=''
    }
  }

  usageEvent(type: any) {
   this.errorObj={}
   this.isActive=false
    if (type == 'diverted') {
      this.resetValueOnChange(type)
      this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted = '';
      if(this.agriPropertyValuationRequestBean.isPartialTransact){
        this.agriPropertyValuationRequestBean.totalSellableAreaDiverted = this.agriPropertyValuationRequestBean.partialTransactArea;
      }
      else{
        this.agriPropertyValuationRequestBean.totalSellableAreaDiverted = this.agriPropertyValuationRequestBean.totalArea;
      }

    } else if (type == 'undiverted') {
      this.resetValueOnChange(type)
      this.agriPropertyValuationRequestBean.totalSellableAreaDiverted = '';
      this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea = '';
      if(this.agriPropertyValuationRequestBean.isPartialTransact){

        this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted = this.agriPropertyValuationRequestBean.partialTransactArea;

      }
      else{

        this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted = this.agriPropertyValuationRequestBean.totalArea;
      }

    } else if (type == 'both') {
      this.resetValueOnChange(type)
      if(this.agriPropertyValuationRequestBean.isPartialTransact){
        this.agriPropertyValuationRequestBean.totalSellableArea = this.agriPropertyValuationRequestBean.partialTransactArea;
        this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted=this.agriPropertyValuationRequestBean.partialTransactArea
      }
     else{
      this.agriPropertyValuationRequestBean.totalSellableArea = this.agriPropertyValuationRequestBean.totalArea;
      this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted=this.agriPropertyValuationRequestBean.totalArea
     }

    }
  }

  resetValueOnChange(type:any){
      
    if (type == 'diverted') {
      this.agriPropertyValuationRequestBean.irrigatedArea = '';
      this.agriPropertyValuationRequestBean.singleCropArea = '';
      this.agriPropertyValuationRequestBean.doubleCropArea = '';
      this.agriPropertyValuationRequestBean.totalSellableAreaDiverted='';
      this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted=''
      if(this.agriPropertyValuationRequestBean.plotAreaType=='RCumCom'){
        this.agriPropertyValuationRequestBean.residentialPlotArea = '';
        this.agriPropertyValuationRequestBean.commercialPlotArea = '';
        this.agriPropertyValuationRequestBean.industrialPlotArea = '';
        this.agriPropertyValuationRequestBean.healthPlotArea = '';
        this.agriPropertyValuationRequestBean.eduPlotArea = '';
        this.agriPropertyValuationRequestBean.othersPlotArea = '';
        }
        else{
          this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea=''
        }
    }
    else if(type == 'undiverted'){
      this.agriPropertyValuationRequestBean.residentialPlotArea = '';
      this.agriPropertyValuationRequestBean.commercialPlotArea = '';
      this.agriPropertyValuationRequestBean.industrialPlotArea = '';
      this.agriPropertyValuationRequestBean.healthPlotArea = '';
      this.agriPropertyValuationRequestBean.eduPlotArea = '';
      this.agriPropertyValuationRequestBean.othersPlotArea = '';
      this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea=''
      this.agriPropertyValuationRequestBean.totalSellableAreaDiverted='';
      this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted=''
    }
    else if(type == 'both'){
      this.agriPropertyValuationRequestBean.irrigatedArea = '';
      this.agriPropertyValuationRequestBean.singleCropArea = '';
      this.agriPropertyValuationRequestBean.doubleCropArea = '';
      this.agriPropertyValuationRequestBean.residentialPlotArea = '';
      this.agriPropertyValuationRequestBean.commercialPlotArea = '';
      this.agriPropertyValuationRequestBean.industrialPlotArea = '';
      this.agriPropertyValuationRequestBean.healthPlotArea = '';
      this.agriPropertyValuationRequestBean.eduPlotArea = '';
      this.agriPropertyValuationRequestBean.othersPlotArea = '';

      if(this.agriPropertyValuationRequestBean.plotAreaType=='RCumCom'){
      this.agriPropertyValuationRequestBean.residentialPlotArea = '';
      this.agriPropertyValuationRequestBean.commercialPlotArea = '';
      this.agriPropertyValuationRequestBean.industrialPlotArea = '';
      this.agriPropertyValuationRequestBean.healthPlotArea = '';
      this.agriPropertyValuationRequestBean.eduPlotArea = '';
      this.agriPropertyValuationRequestBean.othersPlotArea = '';
      }
      else{
        this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea=''
      }
    }

  }

  userTypeEvent(event:any ) {

    this.usageType = event.target.value;
    this.agriPropertyValuationRequestBean.buildingAreaType = event.target.value;
     if (event.target.value == 'RCumCom' || this.agriPropertyValuationRequestBean.isResidentialCumCommercial) {

      this.agriPropertyValuationRequestBean.residentialPlotArea = '';
      this.agriPropertyValuationRequestBean.commercialPlotArea = '';
      this.agriPropertyValuationRequestBean.industrialPlotArea = '';
      this.agriPropertyValuationRequestBean.healthPlotArea = '';
      this.agriPropertyValuationRequestBean.eduPlotArea = '';
      this.agriPropertyValuationRequestBean.othersPlotArea = '';


    } else {

      this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea = '';
    }

  }
  usageTypeEventPlot_Agri(inputType?: any) {

    let totalCount = 0;
    if(this.agriPropertyValuationRequestBean.plotAreaType!='RCumCom'){
    if (this.agriPropertyValuationRequestBean.residentialPlotArea != undefined) {
      totalCount = totalCount + Number(this.agriPropertyValuationRequestBean.residentialPlotArea);
    }
    if (this.agriPropertyValuationRequestBean.commercialPlotArea != undefined) {
      totalCount = totalCount + Number(this.agriPropertyValuationRequestBean.commercialPlotArea);
    }
    if (this.agriPropertyValuationRequestBean.industrialPlotArea != undefined) {
      totalCount = totalCount + Number(this.agriPropertyValuationRequestBean.industrialPlotArea);
    }
    if (this.agriPropertyValuationRequestBean.healthPlotArea != undefined) {
      totalCount = totalCount + Number(this.agriPropertyValuationRequestBean.healthPlotArea);
    }
    if (this.agriPropertyValuationRequestBean.eduPlotArea != undefined) {
      totalCount = totalCount + Number(this.agriPropertyValuationRequestBean.eduPlotArea);
    }
    if (totalCount == Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted)) {
     // this.showMarketValueBtn = false;
    } else if (totalCount > Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted)) {
      this.resetEventValueAgri(inputType);
      
      //this.showMarketValueBtn = true;
    }

  }
    else {

      if(this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea>this.agriPropertyValuationRequestBean.totalSellableAreaDiverted){
        this.resetEventValueAgri(inputType);
      }
     else if(this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea==this.agriPropertyValuationRequestBean.totalSellableAreaDiverted){
     // this.showMarketValueBtn=false
     }

    }
  }
  resetEventValueAgri(inputType: any) {
    if (inputType == 'residentialPlotArea') {
      this.agriPropertyValuationRequestBean.residentialPlotArea = '';
    } else if (inputType == 'commercialPlotArea') {
      this.agriPropertyValuationRequestBean.commercialPlotArea = '';
    } else if (inputType == 'industrialPlotArea') {
      this.agriPropertyValuationRequestBean.industrialPlotArea = '';
    } else if (inputType == 'healthPlotArea') {
      this.agriPropertyValuationRequestBean.healthPlotArea = '';
    } else if (inputType == 'othersPlotArea') {
      this.agriPropertyValuationRequestBean.othersPlotArea = '';
    } else if (inputType == 'eduPlotArea') {
      this.agriPropertyValuationRequestBean.eduPlotArea = '';
    } else if (inputType == 'residentialCumCommercialPlotArea') {
      this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea = '';
    }
  }

  isSellerCheckEvent():boolean {
  
      if(this.agriPropertyValuationRequestBean.isMoreSellers==true){
       
         if(this.agriPropertyValuationRequestBean.noOfSellers!=''&&Number(this.agriPropertyValuationRequestBean.noOfSellers)>1){
         return true
         }else{
         this.errorObj={ responseMessage:' Number of Sellers must be more than 1',
         responseMessageEn:'Number of Sellers must be more than 1',
         responseMessageHi:'विक्रेता  की संख्या 1 से अधिक होनी चाहिए'}
         return false
         }
      }else{
        this.agriPropertyValuationRequestBean.noOfSellers="1"
         return true
     
    }
  }
  isBuyerCheckEvent(){
    if(this.agriPropertyValuationRequestBean.isMoreBuyers==true){
       
      if(this.agriPropertyValuationRequestBean.noOfBuyers!=''&&Number(this.agriPropertyValuationRequestBean.noOfBuyers)>1){
      return true
      }else{
      this.errorObj={ responseMessage:' Number of buyers must be more than 1',
      responseMessageEn:'Number of buyers must be more than 1',
      responseMessageHi:'क्रेता  की संख्या 1 से अधिक होनी चाहिए'}
      return false
      }
   }else{
      this.agriPropertyValuationRequestBean.noOfBuyers="1"
      return true
  
 }
  }
  addSubclauseEvent(event: any, slrow: any) {
    if (event.target.checked == true) {
      this.agriPropertyValuationRequestBean.subClause.push(slrow);
    } else {
      this.agriPropertyValuationRequestBean.subClause.forEach((value, index) => {
        if (value.id == slrow.id) {
          this.agriPropertyValuationRequestBean.subClause.splice(index, 1);
        }
      });
    }
  }
   //Buiding
  isThereCostructionBuildingEvent(event:any) {
    if( this.agriPropertyValuationRequestBean.totalArea == null ||
      this.agriPropertyValuationRequestBean.totalArea == undefined ||
      this.agriPropertyValuationRequestBean.totalArea == 0){
        this.agriPropertyValuationRequestBean.isPlotOnAgriLand=event
        this.totalAreaMsg=true
        this.myCheckboxB.nativeElement.checked = !this.myCheckboxB.nativeElement.checked;
        return false
      }
      if( this.agriPropertyValuationRequestBean.partialTransactArea == null ||
        this.agriPropertyValuationRequestBean.partialTransactArea == undefined ||
        this.agriPropertyValuationRequestBean.partialTransactArea == 0){
          this.totalAreaMsg=true
          this.myCheckboxB.nativeElement.checked = !this.myCheckboxB.nativeElement.checked;
          return false
        }
 
    if(!this.isSellerCheckEvent()){
      this.agriPropertyValuationRequestBean.isConstOnAgriLand=event.target.checked
      this.alertmessage.errorAlertMessage(this.errorObj)
      return 
    }
    if(!this.isBuyerCheckEvent()){
      this.agriPropertyValuationRequestBean.isConstOnAgriLand=false
      this.alertmessage.errorAlertMessage(this.errorObj)
      return 
    }
     if(!this.agriSubmitFormValidation()){
        this.alertmessage.errorAlertMessage(this.errorObj)
        return 
      }
      if(this.agriPropertyValuationRequestBean.isPartialTransact){
        this.agriPropertyValuationRequestBean.totalArea=this.agriPropertyValuationRequestBean.partialTransactArea
      }
      if(event){
      this.agriPropertyValuationRequestBean.demography=this.selectDemographyData
      this.agriPropertyValuationRequestBean.agriculturalWithConstruction=new BuildingPropertyValuationRequestBean();
      this.dataService.setAgriculturalWithConstruction({agriculturalWithConstruction:this.agriPropertyValuationRequestBean,isContinueButton:true})
      this.dataService.setchangeMainAreaType({id:2,isPartialTransact:this.isPartialTransact})
      return event
    }
    else{
      this.agriPropertyValuationRequestBean.isConstOnAgriLand=event
      return event
    }
 
  }
  //plot
  isThereCostructionPlotEvent(event:any){

    if( this.agriPropertyValuationRequestBean.totalArea == null ||
    this.agriPropertyValuationRequestBean.totalArea == undefined ||
    this.agriPropertyValuationRequestBean.totalArea == 0){
      this.agriPropertyValuationRequestBean.isPlotOnAgriLand=event
      this.totalAreaMsg=true
      this.myCheckbox.nativeElement.checked = !this.myCheckbox.nativeElement.checked;
      return false
    }
    if( this.agriPropertyValuationRequestBean.partialTransactArea == null ||
      this.agriPropertyValuationRequestBean.partialTransactArea == undefined ||
      this.agriPropertyValuationRequestBean.partialTransactArea == 0){
        this.totalAreaMsg=true
        this.myCheckbox.nativeElement.checked = !this.myCheckbox.nativeElement.checked;
        return false
      }

   
      if(this.agriPropertyValuationRequestBean.isPartialTransact){
        this.agriPropertyValuationRequestBean.totalArea=this.agriPropertyValuationRequestBean.partialTransactArea
      }
  
      if(event){
      this.agriPropertyValuationRequestBean.demography=this.selectDemographyData
      this.agriPropertyValuationRequestBean.agriculturalWithPlot=new PropertyValuationRequestBean();
      this.dataService.setDataplotDataAgri({agriculturalWithConstruction:this.agriPropertyValuationRequestBean,isContinueButton:true})
      this.dataService.setchangeMainAreaType({id:1,isPartialTransact:this.isPartialTransact,isContinueButton:true})
     
      return event
    }
    else{
      this.agriPropertyValuationRequestBean.isPlotOnAgriLand=event
      return event
    }
  }


  partialTransactAreaAlwaysLessThanTotalArea(value:any) {
 
   let totalWebGisTransactionArea:number=0
   if(Number(this.agriPropertyValuationRequestBean.partialTransactArea)>Number(this.agriPropertyValuationRequestBean.totalArea)){
    this.agriPropertyValuationRequestBean.partialTransactArea=''
   }
   else if(this.agriPropertyValuationRequestBean.totalArea==''){
    this.agriPropertyValuationRequestBean.partialTransactArea=''
   }
   if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length==1){
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach(e=>{
     
    totalWebGisTransactionArea=e.partialTransactArea
    })
   }
   else if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length>1){
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach(e=>{
      
      totalWebGisTransactionArea+=e.partialTransactArea
    })
   }
   if(Number(this.agriPropertyValuationRequestBean.partialTransactArea)<totalWebGisTransactionArea){
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach(e=>{
      e.partialTransactArea=''
    })
   }
   
  }

  addPatrialTypeArea(value:any){
    if(value==0){
     //this.landtranAreaValue=true
    }
    else{
   //   this.landtranAreaValue=false
    }
   let totalparicalArea:number=0
   this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach(s=>{
    if(s.partialTransactArea==''){
      this.errorObj= { responseMessage:' Please Enter Transact Area Again Land Parcel Id: '+s.landParcelId,
      responseMessageEn:'Please Enter Transact Area Again Land Parcel Id:'+s.landParcelId,
      responseMessageHi:'कृपया भूमि पार्सल आईडी के लिए संव्यवहार क्षेत्र दर्ज करें:'+s.landParcelId}
     this.alertmessage.errorAlertMessage(this.errorObj)
    }
    if(s.totalArea<s.partialTransactArea){
      s.partialTransactArea=''
    }else{
      totalparicalArea=Number(totalparicalArea)+Number(s.partialTransactArea)
      this.agriPropertyValuationRequestBean.partialTransactArea=Number(totalparicalArea.toFixed(4))
    }

    this.inputChangeEventValidation()
   })

  }
  errorObj:{}
  isActive:boolean=false
  agriSubmitFormValidation():boolean {
   
    let totalCount = 0;
    if (this.agriPropertyValuationRequestBean.plotAreaType !== 'RCumCom') {
      const plotAreas = [
        Number(this.agriPropertyValuationRequestBean.residentialPlotArea),
        Number(this.agriPropertyValuationRequestBean.commercialPlotArea),
        Number(this.agriPropertyValuationRequestBean.industrialPlotArea),
        Number(this.agriPropertyValuationRequestBean.healthPlotArea),
        Number(this.agriPropertyValuationRequestBean.eduPlotArea)
      ];
    
      totalCount = plotAreas.reduce((total, plotArea) => {
        return total + Number(plotArea ?? 0);
      }, 0);
    }
  else{
    totalCount=totalCount + Number(this.agriPropertyValuationRequestBean.residentialCumCommercialPlotArea);
  }
     //both
    if (this.agriPropertyValuationRequestBean.usage == 'both') {
      let totaldArea = Number(this.agriPropertyValuationRequestBean?.residentialPlotArea) + Number(this.agriPropertyValuationRequestBean?.commercialPlotArea) + Number(this.agriPropertyValuationRequestBean?.industrialPlotArea)+Number(this.agriPropertyValuationRequestBean?.healthPlotArea)+Number(this.agriPropertyValuationRequestBean?.eduPlotArea);
      if ((this.agriPropertyValuationRequestBean.totalSellableArea!=''||totaldArea != 0)&& Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted) != Number(totaldArea)) {
        this.isActive=true
        return false;
      } 
      let totalUdArea = Number(this.agriPropertyValuationRequestBean?.irrigatedArea) + Number(this.agriPropertyValuationRequestBean?.singleCropArea) + Number(this.agriPropertyValuationRequestBean?.doubleCropArea);
      if ((this.agriPropertyValuationRequestBean.totalSellableArea!=''||totalUdArea != 0)&& Number(this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted) != Number(totalUdArea)) {
        this.isActive=true
        return false;
      }
    }
     // Diverted
    else if (this.agriPropertyValuationRequestBean.usage == 'diverted') {
      if (totalCount == Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted)) {
       return true
      }
      else if ((this.agriPropertyValuationRequestBean.totalSellableArea!=''||totalCount != 0) && (totalCount < Number(this.agriPropertyValuationRequestBean.totalSellableAreaDiverted))) {
        this.isActive=true
        this.errorObj= { responseMessage:' The Area of commercial and residential and industrial and health and other is not equal to total area',
        responseMessageEn:'The Area of commercial and residential and industrial and health and other is not equal to total area',
        responseMessageHi:'व्यावसायिक क्षेत्र और निवास क्षेत्र और औद्योगिक क्षेत्र और स्वास्थ्य क्षेत्र और अन्य क्षेत्रफल कुल क्षेत्रफल के बराबर नहीं है'}
        return false;
      }
    }
    // Undiverted
     else if (this.agriPropertyValuationRequestBean.usage == 'undiverted') {
      let totalUdArea = Number(this.agriPropertyValuationRequestBean?.irrigatedArea) + Number(this.agriPropertyValuationRequestBean?.singleCropArea)
       + Number(this.agriPropertyValuationRequestBean?.doubleCropArea);
      if ((this.agriPropertyValuationRequestBean.totalArea!=''||totalUdArea != 0)&&Number(this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted) != Number(totalUdArea.toFixed(4))) {
        this.isActive=true
        this.errorObj= { responseMessage:' Area of ​​irrigated area or single crop area or double crop area is not equal to total area',responseMessageEn:'Area of ​​irrigated area or single crop area or double crop area is not equal to total area',responseMessageHi:'सिंचित क्षेत्र तथा एकल फसल क्षेत्र तथा दोहरी फसल क्षेत्र का क्षेत्रफल कुल क्षेत्रफल के बराबर नहीं है'}
        return false;
      }

    }
    if(this.agriPropertyValuationRequestBean.noOfBuyers==null||undefined){
      this.errorObj= { responseMessage:'',responseMessageEn:'Area of ​​irrigated area or single crop area or double crop area is not equal to total area',responseMessageHi:'सिंचित क्षेत्र तथा एकल फसल क्षेत्र तथा दोहरी फसल क्षेत्र का क्षेत्रफल कुल क्षेत्रफल के बराबर नहीं है'}
    }

    if (this.agriPropertyValuationRequestBean.isMoreBuyers == true &&
      this.agriPropertyValuationRequestBean.noOfBuyers == '' && this.agriPropertyValuationRequestBean.isBuyerSameFamily != true) {
      return false;
    }
    return true
  }

  
  brkFlow:boolean=false
  showMarketValueEvent(form:NgForm) {
    console.log(form)
    if(form.invalid){
     return
    }

    this.brkFlow=false
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach(s=>{
      if(s.partialTransactArea==''){
        this.errorObj= { responseMessage:' Please Enter Transact Area Again Land Parcel Id: '+s.landParcelId,
        responseMessageEn:'Please Enter Transact Area Again Land Parcel Id:'+s.landParcelId,
        responseMessageHi:''}
       this.alertmessage.errorAlertMessage(this.errorObj)
        this.brkFlow=true
      }
      
    })
    if(this.brkFlow){
      return
    }

    if(this.agriPropertyValuationRequestBean.partialTransactArea==0){
      this.errorObj= { responseMessage:' Please Enter Transact Area ',
        responseMessageEn:'Please Enter Transact Area',
        responseMessageHi:'कृपया लेनदेन क्षेत्र दर्ज करें'}
      this.alertmessage.errorAlertMessage(this.errorObj)
      return 
    }

    if(!this.isSellerCheckEvent()){
      this.alertmessage.errorAlertMessage(this.errorObj)
   
      return
    }
    if(!this.isBuyerCheckEvent()){
      this.alertmessage.errorAlertMessage(this.errorObj)
     
      return
    }
    

   if(!this.agriSubmitFormValidation()){
    this.agriPropertyValuationRequestBean.isConstOnAgriLand=false
        this.alertmessage.errorAlertMessage(this.errorObj)
      
        return
      }

    // if (this.showMarketValueBtn == true) {
    //   return;
    // }
    if(this.agriPropertyValuationRequestBean.isPartialTransact){
      this.agriPropertyValuationRequestBean.totalArea=this.agriPropertyValuationRequestBean.partialTransactArea
    }

    if (this.agriPropertyValuationRequestBean.isConstOnAgriLand == true) {
     // this.agriPropertyValuationRequestBean.agriculturalWithConstruction=this.propertyValuationRequestBeanBuild
      if(this.agriSubmitFormValidation()){
       
      }
    }
    if(this.agriPropertyValuationRequestBean.isConstOnAgriLand==false){
      this.agriPropertyValuationRequestBean.agriculturalWithConstruction=null
    }
    if(this.agriPropertyValuationRequestBean.isPlotOnAgriLand==false){
      this.agriPropertyValuationRequestBean.agriculturalWithPlot=null
    }
    if (this.agriPropertyValuationRequestBean.totalArea != undefined && this.agriPropertyValuationRequestBean.totalArea != '' ) {
      this.totalAreaMsg = false;
      if(this.agriPropertyValuationRequestBean.totalArea == '0' || this.agriPropertyValuationRequestBean.totalArea ==0){
        this.agriPropertyValuationRequestBean.totalArea=''
        this.totalAreaMsg = true;
        return
      }
    }

    else{
      this.totalAreaMsg = true;
    }
    this.ngxService.startLoader('AgriCulture-01');
    this.agriPropertyValuationRequestBean.demography=this.selectDemographyData
    this.pvService.propValuation(this.agriPropertyValuationRequestBean).subscribe(data => {
     
      this.ngxService.stopLoader('AgriCulture-01');
      if (data.responseStatus == "true") {
        this.propertyValuationResponseBean = data.responseData;

   
        this.openDialog(this.propertyValuationResponseBean);
      } else {
        this.ngxService.stopLoader('AgriCulture-01');
        this.alertmessage.errorAlertMessage(data);
      }
    },(error:any)=>{
      this.ngxService.stopLoader('AgriCulture-01');
      this.ngxService.stopLoader('builing-01');
      
    }
    
    );
  }
  openDialog(propertyValuationResponseBean: PropertyValuationResponseBean) {
    this.agriPropertyValuationRequestBean.isImpoundAddProperty = this.isImpoundAddProperty
    this.agriPropertyValuationRequestBean.builderShare = this.builderShareData
    let obj = {searchPropType: this.agriPropertyValuationRequestBean, propData: propertyValuationResponseBean , fromDutyModule:this.fromDutyModule,propertyOwner:this.agriPropertyValuationRequestBean.propertyOwner,formPropModule:true,fromCMSModule:this.fromCMSModule,fromCheckerModule:this.fromCheckerModule};
    this.matDialogRef= this.dialog.open(NewShowMaketValueComponent, {
      data: obj,
      panelClass:'NewShowMaketValue',
      disableClose:true
    });
    this.matDialogRef
    .afterClosed()
    .pipe()
    .subscribe((result=>{
    this.logger.log(result)
    this.logger.log(" result:-",result)

     if(result.isContinued ){
      this.reloadComponent(true,'property-valuation')
    }else if(result.fromCMSClose || result.fromCheckerClose){
      this.emitDataToImpoundModule(result);
      this.matDialogRefAgri.close();
    }
    else{
      this.agriPropertyValuationRequestBean.totalArea=this.propertyValuationResponseBean.overAllArea
            this.matDialogRef.close();
    }

 }))
  }
  resetConsrt(){
    this.agriPropertyValuationRequestBean.isConstOnAgriLand=false
  }


  emitDataToImpoundModule(res:any){
    let impoundData:ImpoundData;
              impoundData = res.impoundData;   
      this.commServ.emitImpoundData(impoundData);         
  }

  
  IsSameFamilly(){
    if(this.agriPropertyValuationRequestBean.isCoOwner==true){
      this.agriPropertyValuationRequestBean.isOwnerSameFamily=undefined
    }
  }

  unDivertedSubAreaEvent(type?: any) {
   
    let totalUdArea = Number(this.agriPropertyValuationRequestBean.irrigatedArea==undefined?0:this.agriPropertyValuationRequestBean.irrigatedArea)
    + Number(this.agriPropertyValuationRequestBean?.singleCropArea==undefined? 0:this.agriPropertyValuationRequestBean.singleCropArea) + Number(this.agriPropertyValuationRequestBean?.doubleCropArea==undefined?0:this.agriPropertyValuationRequestBean?.doubleCropArea);
    this.logger.log(totalUdArea)
    if (Number(this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted) == Number(totalUdArea.toFixed(4))) {
    
    } else if (Number(this.agriPropertyValuationRequestBean.totalSellableAreaUndiverted) < totalUdArea) {
      if (type == 'irrigatedArea') {
        this.agriPropertyValuationRequestBean.irrigatedArea = '';
      } else if (type == 'unirrigatedSArea') {
        this.agriPropertyValuationRequestBean.singleCropArea = '';
      } else if (type == 'unirrigatedDArea') {
        this.agriPropertyValuationRequestBean.doubleCropArea = '';
      }
    
    } else {
     
    }
  }

  trackByFn(index:any ,item:any){
   return item
  }

  public indexBuyer = 0;
  AddDifferentBuyer(){
    this.indexBuyer++;
    let el : MultipleFamilyBean= {index: this.indexBuyer, familyMembersCount: 0,familyShare: 0};
    this.multipleFamilyBeanBuyer.push(el)

  }
  public index = 0;
  AddDifferentSeller(){
    this.index++;
    let el : MultipleFamilyBean= {index: this.index, familyMembersCount: 0,familyShare: 0};
    this.multipleFamilyBean.push(el);

  }
  removeDifferentSeller(i:number) {
    this.multipleFamilyBean.splice(i, 1);

  }
  removeDifferentBuyer(i:number) {
    this.multipleFamilyBeanBuyer.splice(i, 1);

  }
  openPrimary(content:any) {
    this.modalService.open(content, {
      windowClass:"modalNew",
      size: 'auto',
      centered: true
    });
  }


showAlert(){
  try {
    this.modalService.dismissAll();
  } catch (e) {
    console.error('Storage upload error', e);
  }

   if(this.agriPropertyValuationRequestBean.isCoOwner==false &&this.agriPropertyValuationRequestBean.isOwnerSameFamily==false){

      this.modalService.open(this.Adjacent, {
        windowClass:"modalNew",
        size: 'sm',
        centered: true
      });
}
  }
closeAlert(){
  try {
    this.modalService.dismissAll();
    this.reloadComponent(true,'property-valuation')
  } catch (e) {
    console.error('Storage upload error', e);
  }
}

reloadComponent(self:boolean,urlToNavigateTo ?:string){
  //skipLocationChange:true means dont update the url to / when navigating
 this.logger.log("Current route I am on:",this.router.url);
 const url=self ? this.router.url :urlToNavigateTo;
 this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
   this.router.navigate([`/${url}`]).then(()=>{
     this.logger.log(`After navigation I am on:${this.router.url}`)
   })
 })
}
}

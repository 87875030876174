import { Component, OnInit } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
@Component({
  selector: 'app-softw-download',
  templateUrl: './softw-download.component.html',
  styleUrls: ['./softw-download.component.scss']
})
export class SoftwDownloadComponent implements OnInit {
  private settings!: AppSetting;
  isExpanded = true;

  panelOpenState = true;
  constructor(private appSettingsService: AppSettingService ) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
   }

  ngOnInit(): void {
  }
  downloadZip(content:any): void {

    var url = this.settings.igrs_gateway_url + "common/dscsoftware/"+  content + ".zip"

    window.open(url, '_blank');

  }

}

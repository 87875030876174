
<mat-card class="example-card">
    <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">



<fieldset class="legend-section">
    <lib-alertmessage #alertMessage></lib-alertmessage>
    <form novalidate #formRef="ngForm" (ngSubmit)="onSubmit(formRef)">
        <div class="row" [hidden]="formRef.submitted">
            <!-- <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>Email ID</label>
                    <input type="email" readonly class="form-control" name="email" value={{userEmail}} required/>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>User Name</label>
                    <input type="text" readonly class="form-control" name="name" value={{userName}} />
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>Application Name</label>

                    <select class="form-control" name="applicationId" [(ngModel)]="ticketModel.applicationId" ngModel #applicationNameRef="ngModel" required
                        [class.ctrl-error]="applicationNameRef.touched && applicationNameRef?.errors?.required">
                        <option [value]="item.application_id" *ngFor="let item of applicationList" selected>
                           {{item.application_name_e}}
                        </option>
                    </select>
                    <div *ngIf="applicationNameRef.touched && applicationNameRef?.errors?.required"
                        class="error-message">
                        The Application Name is required.
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>Department Name</label>

                    <select class="form-control" name="deptId" [(ngModel)]="ticketModel.deptId" ngModel #departmentNameRef="ngModel" required
                        [class.ctrl-error]="departmentNameRef.touched && departmentNameRef?.errors?.required">
                        <option [value]="item.id" *ngFor="let item of departmentModelList" selected>
                           {{item.name}}
                        </option>
                    </select>
                    <div *ngIf="departmentNameRef.touched && departmentNameRef?.errors?.required"
                        class="error-message">
                        The Department Name is required.
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>Office Name</label>

                    <select class="form-control" name="officeId" [(ngModel)]="ticketModel.officeId" ngModel="" #officeNameRef="ngModel" required
                        [class.ctrl-error]="officeNameRef.touched && officeNameRef?.errors?.required">
                        <option [value]="item.id" *ngFor="let item of officeModelList" selected>
                           {{item.name}}
                        </option>
                    </select>
                    <div *ngIf="officeNameRef.touched && officeNameRef?.errors?.required" class="error-message">
                        The Office Name is required.
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>{{'email' | translate}} <span class="abstick">*</span></label>
                    <input type="email"   class="form-control" name="email"  #emailRef="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                     [(ngModel)]="ticketModel.email" placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-email' | translate}}"
                    [class.ctrl-error]="emailRef.touched && emailRef?.errors?.required" required/>
                    <div *ngIf="emailRef.invalid && (emailRef.dirty || emailRef.touched)" class="text-danger">
                        <div *ngIf="emailRef.errors.required">
                            {{'admin-profile-page-data.center-personal-detail-useronboard.reqrired-email' | translate}}.
                        </div>
                        <div *ngIf="emailRef.errors?.pattern">
                            This is not a valid Email!!!.
                        </div>
                    </div>
                    <!-- <div *ngIf="emailRef?.errors?.required" class="error-message">
                        {{'admin-profile-page-data.center-personal-detail-useronboard.reqrired-email' | translate}}.
                    </div> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>{{'admin-profile-page-data.center-personal-detail-useronboard.user-name' | translate}} <span class="abstick">*</span></label>
                    <input type="text"   class="form-control" name="name" #nameRef="ngModel" pattern="^[a-zA-Z\s\/\-]{3,30}$"
                    [(ngModel)]="ticketModel.name" placeholder="{{'admin-profile-page-data.center-personal-detail-useronboard.enter-candidate-name' | translate}}"
                    [class.ctrl-error]="nameRef.touched && nameRef?.errors?.required" required/>
                    <!-- <div *ngIf="nameRef?.errors?.required" class="error-message">
                        {{'admin-profile-page-data.center-personal-detail-useronboard.reqrired-candidate-name' | translate}}.
                    </div> -->
                    <div *ngIf="nameRef.invalid && (nameRef.dirty || nameRef.touched)" class="text-danger">
                        <div *ngIf="nameRef.errors.required">
                            {{'admin-profile-page-data.center-personal-detail-useronboard.reqrired-candidate-name' | translate}}.
                        </div>
                        <div *ngIf="nameRef.errors?.pattern">
                            Only alphabets is required!!!.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>{{'ticket-title.District' | translate}}<span class="abstick">*</span></label>

                    <select class="form-control" name="distId" [(ngModel)]="ticketModel.distId" ngModel="" #districtNameRef="ngModel" required placeholder="Select District"
                        [class.ctrl-error]="districtNameRef.touched && districtNameRef?.errors?.required">
                        <option [disabled]="true" [value]="null" selected>{{'legacyDocumentRequest.selectDistrict' | translate}}</option>
                        <option [value]="item.id" *ngFor="let item of districtModelList">
                            {{ lan==0?item.name:item.district_name_hindi }}
                        </option>
                    </select>
                    <div *ngIf="districtNameRef.errors && districtNameRef.touched" class="text-danger">
                        <div *ngIf="districtNameRef.errors.required">{{'legacyDocumentRequest.TheDistrictNameisrequired' | translate}}.</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>{{'ticket-title.IssueCategory' | translate}} <span class="abstick">*</span></label>

                    <select class="form-control" #helptopicId id="helptopicId" name="helptopicId" [(ngModel)]="ticketModel.helptopicId" ngModel=""
                    #helptopicNameRef="ngModel" (change)="getSubHelpTopicList(helptopicId.value)" required
                        [class.ctrl-error]="helptopicNameRef.touched && helptopicNameRef?.errors?.required">
                        <option [disabled]="true" [value]="null" selected>{{'legacyDocumentRequest.SelectIssueCategory' | translate}}</option>
                        <option [value]="item.helptopic_id" *ngFor="let item of helptopicList">
                            {{ lan==0?item.helptopic_name_e:item.helptopic_name_h }}
                        </option>
                    </select>
                    <div *ngIf="helptopicNameRef.errors && helptopicNameRef.touched" class="text-danger">
                        <div *ngIf="helptopicNameRef.errors.required">{{'legacyDocumentRequest.TheIssueCategoryisrequired' | translate}}.</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>{{'ticket-title.IssueSubCategory' | translate}} <span class="abstick">*</span></label>

                    <select class="form-control" #subHelptopicId id="subHelptopicId" name="subHelptopicId" [(ngModel)]="ticketModel.subHelptopicId" ngModel=""
                    #subHelptopicNameRef="ngModel" (change)="getAllTitleFieldByTitleId(subHelptopicId.value)" required
                        [class.ctrl-error]="subHelptopicNameRef.touched && subHelptopicNameRef?.errors?.required">
                        <option [disabled]="true" [value]="null" selected>{{'legacyDocumentRequest.SelectIssueSubCategory' | translate}}</option>
                        <option [value]="item.helptopic_id" *ngFor="let item of subHelptopicList">
                            {{ lan==0?item.helptopic_name_e:item.helptopic_name_h }}
                        </option>
                    </select>
                    <div *ngIf="subHelptopicNameRef.errors && subHelptopicNameRef.touched" class="text-danger">
                        <div *ngIf="subHelptopicNameRef.errors.required">{{'legacyDocumentRequest.TheIssueSubCategoryisrequired' | translate}}.</div>
                    </div>
                </div>
            </div>


            <div class="col-lg-12 col-sm-6" *ngIf="showTicketIssueCatDiv">
                <div class=" form-group">
                    <label>{{'ticket-title.IssueRelatedTo' | translate}} :</label>
                    <span>{{ticketModel.subject}}</span>
                    <!-- <input type="text" class="form-control" name="subject" [(ngModel)]="ticketModel.subject" ngModel="" #subjectRef="ngModel" required
                    readonly   [class.ctrl-error]="subjectRef.touched && subjectRef?.errors?.required" />
                    <div *ngIf="subjectRef.touched && subjectRef?.errors?.required" class="error-message">
                        The Subject is required.
                    </div> -->
                </div>
            </div>
            <!--Ticket Issue Category Started-->
            <div class="col-md-12" *ngIf="showTicketIssueCatDiv">

                    <!-- <legend class="sidebar__widget-title">
                        Ticket Issue Subject
                    </legend> -->
                    <div  class="row">
                        <div class="col-lg-4" *ngFor="let obj of titleFieldMappingList ; let i= index">
                            <div class=" form-group">
                                <label>{{ lan==0?obj.titleFieldIds.fieldName:obj.titleFieldIds.fieldNameHi }} <span class="abstick" *ngIf="obj.optionalStatus == 0">*</span></label>
                                <input type="text" class="form-control" id="id_{{obj.titleFieldIds.id}}" name="{{obj.titleFieldIds.fieldName}}"  ngModel="" #subjectRef="ngModel" [required]="obj.optionalStatus == 0 ? 'required' : null"
                                (change)="onChangeField($event,obj.titleFieldIds.fieldName)"  placeholder="{{ lan==0?obj.titleFieldIds.fieldName:obj.titleFieldIds.fieldNameHi }}"  [class.ctrl-error]="obj.optionalStatus == 0 && subjectRef?.errors?.required" />
                                <span *ngIf="obj.optionalStatus == 0">
                                    <div *ngIf="subjectRef.invalid && (subjectRef.dirty || subjectRef.touched)"
                                    class="text-danger">
                                        <div *ngIf="subjectRef.errors.required">
                                            {{ lan==0?obj.titleFieldIds.fieldName:obj.titleFieldIds.fieldNameHi }} {{'duty-calculation.is_required' | translate}}.
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="alert alert-info" role="alert">
                               Note :- Subject will be auto populated.It is concatenation of SubCategory,fieldName & fieldValue <br>
                                eg :- slot view : (1). Application Number :- A-1001
                              </div>
                        </div> -->
                    </div>

            </div>

            <!--Ticket Issue Category Ended-->
            <div class="col-lg-4 col-sm-6">
                <div class=" form-group">
                    <label>{{'ticket-title.UploadFile' | translate}} </label>
                    <input type="file" class="form-control p-0" name="file_id" [(ngModel)]="ticketModel.fileData" ngModel="" #uploadFileRef="ngModel" (change)="onFileSelected($event)"/>
                    <!-- <div *ngIf="uploadFileRef.touched && uploadFileRef?.errors?.required" class="error-message">
                        The Upload File is required.
                    </div> -->
                    <div *ngIf="!uploadfileErrorMsg" class="upload-invalid-feedback">
                        <div>{{'ticket-file-type-error' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-sm-6">
                <div class=" form-group">
                    <label>{{'ticket-title.Description' | translate}} <span class="abstick">*</span></label>
                    <textarea class="form-control" rows="2" name="description" [(ngModel)]="ticketModel.description" ngModel #descriptionRef="ngModel" required
                        [class.ctrl-error]="descriptionRef.touched && descriptionRef?.errors?.required" maxlength="500"></textarea>
                        <div *ngIf="descriptionRef.invalid && (descriptionRef.dirty || descriptionRef.touched)"
                        class="text-danger">
                            <div *ngIf="descriptionRef.errors.required">
                                {{'legacyDocumentRequest.TheDescriptionisrequired' | translate}}.
                            </div>
                   </div>
                </div>
            </div>

            <div class="col-md-12 text-right">
                <button type="submit" class="btn btn-primary mr-1" [disabled]="formRef.invalid">
                    {{'ticket-title.Submit' | translate}}
                </button>
                <button type="button" class="btn btn-secondary" (click)="formRef.resetForm({})">Reset</button>
            </div>

            </div>



    </form>

</fieldset>
<!--Search Filter-->
<fieldset class="legend-section">
<legend class="sidebar__widget-title">{{'ticket-title.SearchTicket' | translate}}</legend>
<div class="searchtop">
    <form class="row">
      <div class="col-lg-12 ">
        <label class="sr-only">{{'ticket-title.SearchTicket' | translate}}</label>

      </div>
      <div class="col-lg-3">
        <div class="form-group">
            <input type="text" id="userEmail1" #userEmail1  name="userEmail1" [(ngModel)]="userEmail"  class="form-control"
            placeholder="Enter User Email">
        </div>


      </div>
      <div class="col-lg-3">
        <div class="form-group">
            <input type="text" id="search" #search  name="search" [(ngModel)]="searchTicketNo" class="form-control"
            placeholder="Search By Ticket Number">
        </div>
        </div>
      <div class="col-lg-6">

        <button type="submit" mat-raised-button color="primary" (click)="applyFilter(search.value,userEmail1.value)" class="mb-2">
          <mat-icon>search</mat-icon>{{'searchbtn' |
          translate}}
        </button>

        <button mat-raised-button color="primary" class="mb-2 ml-2" (click)="toggleStatus()">
          <mat-icon>filter_list</mat-icon> {{'advancefilter' | translate}}
        </button>
        <button mat-raised-button color="primary" class="mb-2 ml-2" (click)="reloadTabledata()">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>

    </form>
    <!--Advance Filter Popup start-->
    <div class="advanceFilterOption w-100 d-block {{hiddenvalue}}" *ngIf="resetDemo" cdkDrag>

        <button class="filterClosebtn" (click)="close()">X</button>
        <div class="p-4">
          <form>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                    <label for="">{{'ticket-title.ticketStatus' | translate}}</label>
                    <select class="form-control form-control-sm" id="searchByStatus" name="searchByStatus"
                    [(ngModel)]="advnc_Status" (change)="onItemChange($event)">
                          <option value=null>{{'legacyDocumentRequest.SearchByStatus' | translate}}</option>
                          <option value="0">Open</option>
                          <option value="2">Overdue</option>
                          <option value="3">Reopen</option>
                          <option value="1">Close</option>
                    </select>
                  </div>
              </div>
            </div>
          </form>
        </div>
        <div class="advanceFilterOptionfooter p-2 text-right">
          <button class="ml-1" mat-raised-button color="primary" (click)="advanceFilter()">{{'submit' |
            translate}}</button>
          <button class="ml-1" mat-raised-button (click)="reset_advanceFilter()">{{'reset' | translate}}</button>
        </div>
      </div>
    <!--Advance Filter Popup end-->
</div>

</fieldset>


<!-- Ereg Property Linking Table Listing Start  -->
<fieldset class="legend-section">
<div class="shadow-none">
    <div class="table-responsive shadow-none">
        <table  mat-table matTableExporter [dataSource]="dataSource" matSort class="table table-bordered">
            <ng-container matColumnDef="sNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 5%;"> {{'ticket-title.S.N' | translate}} </th>
                <td mat-cell *matCellDef="let element; let i = index">{{pageSize*currentPage+(i+1)}}</td>
            </ng-container>
            <ng-container matColumnDef="ticketNumber">
                <th mat-header-cell *matHeaderCellDef style="width: 10%;"> {{'ticket-title.Ticket-no' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.ticketNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{'ticket-title.subject' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.subject}} </td>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{'ticket-title.CreatedDate' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.created_date | date: 'dd/MM/yyyy hh:mm a'}} </td>
            </ng-container>
            <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;"> {{'ticket-title.DueDate' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.dueDate | date: 'dd/MM/yyyy'}} </td>
            </ng-container>
            <!-- <ng-container matColumnDef="desc">
                <th mat-header-cell *matHeaderCellDef style="width:40%;"> Description </th>
                <td mat-cell *matCellDef="let element"> <div class="discr">{{element.description}}</div> </td>
            </ng-container> -->
            <ng-container matColumnDef="ticketstatus">
                <th mat-header-cell *matHeaderCellDef style="width: 8%;">{{'ticket-title.ticketStatus' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <select class="customSelect form-control" (change)="changeStatus_drop($event,element)" #statusid>
                        <option value="{{element.status}}" [selected]=true>{{element.ticketstatus}}</option>
                        <option value="1" *ngIf="element.status != '1'">Self Close</option>
                        <option value="3" *ngIf="element.status == '1'">Re-Open</option>
                    </select>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="width: 5%;"> {{'e-stamp.Action' | translate}} </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button [matMenuTriggerFor]="action"
                    aria-label="Example icon-button with a menu" class="">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #action="matMenu" xPosition="before">
                    <!-- <button  *ngIf="offlist.featureCode=='F-0005'" mat-raised-button color="primary" routerLink="/admin/adddepartmentusers" class="mb-2 text-right"><i class="fa fa-user-plus"></i> {{'admin-profile-page-data.center-personal-detail-useronboard.add-departmet-user' | translate}}</button> -->
                        <button [value]="row" #editidot
                            class="h-auto list-unstyled" mat-menu-item>
                            <!-- routerLink="/admin/editepartmentusers/{{row.id}}" -->
                            <li (click)="editClick(row)"><!--(click)="onClick('some text')" -->
                                <mat-icon id="edit">rate_review</mat-icon>
                                <span>{{'view' | translate}}</span>
                            </li>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="pageChangedSrvPrvd($event)" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>

</fieldset>

</mat-card-content>
</mat-card>

<!---Issue Category Dialog Start-->

<ng-template #content let-modal>
<div class="modal-body declaration-info">
    <form novalidate #catDialogformRef="ngForm" (ngSubmit)="onSubmitIssueCatDialogForm()">
        <!-- <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
                Ticket Issue Category
            </legend>
            <div *ngFor="let obj of titleFieldMappingList ; let i= index">
                <div class="col-lg-12">
                    <div class=" form-group">
                        <label>{{obj.titleFieldIds.fieldName}}</label>
                        <input type="text" class="form-control" id="id_{{obj.titleFieldIds.id}}" name="id_{{obj.titleFieldIds.id}}"  ngModel="" #subjectRef="ngModel" [required]="obj.optionalStatus == 0 ? 'required' : null"
                          placeholder="{{obj.titleFieldIds.fieldName}}"  [class.ctrl-error]="obj.optionalStatus == 0 && subjectRef?.errors?.required" />
                        <span *ngIf="obj.optionalStatus == 0">
                          <div *ngIf="subjectRef?.errors?.required" class="error-message">
                            {{obj.titleFieldIds.fieldName}} is required.
                          </div>
                        </span>
                    </div>
                </div>
            </div>
        </fieldset> -->
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="catDialogformRef.invalid" (click)="modal.close('Save click')"> {{'ticket-title.Submit' | translate}} </button>
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">{{'ticket-title.cancel' | translate}}</button>
        </div>
    </form>
</div>

</ng-template>

<!---Issue Category Dialog End-->

<!---Ticket Self Close Dialog Start-->

<ng-template #selfCloseContent let-modal>
<div class="modal-body declaration-info">
    <form novalidate #catDialogformRef="ngForm" (ngSubmit)="onSubmitTicketSelfClose()">
        <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
                {{'ticket-title.TicketSelfClose' | translate}}
            </legend>
                <div class="col-lg-12">
                    <div class=" form-group">
                        <!-- <label>{{'legacyDocumentRequest.AreYouSureYouWantto' | translate}} </label> -->
                        <textarea type="text" maxlength="50" class="form-control" id="remark" name="remark"  ngModel="" #remarkRef="ngModel" required
                          placeholder="{{'qms.remark-placeholder' | translate}}"  [class.ctrl-error]="remarkRef?.errors?.required"></textarea>
                          <div *ngIf="remarkRef?.errors?.required" class="error-message">
                            {{'ticket-title.Remarkisrequired' | translate}}.
                          </div>
                    </div>
                </div>
        </fieldset>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="catDialogformRef.invalid" (click)="modal.close('Save click')"> {{'ticket-title.Submit' | translate}} </button>
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">{{'ticket-title.cancel' | translate}}</button>
        </div>
    </form>
</div>

</ng-template>

<ng-template #reOpenContent let-modal>
<div class="modal-body declaration-info">
    <form novalidate #catDialogformRef="ngForm" (ngSubmit)="onSubmitTicketReOpen()">
        <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
                {{'ticket-title.TicketReOpen' | translate}}
            </legend>
                <div class="col-lg-12">
                    <div class=" form-group">
                        <!-- <label>{{'legacyDocumentRequest.AreYouSureYouWantto' | translate}} </label> -->
                        <textarea type="text" maxlength="50" class="form-control" id="remark" name="remark"  ngModel="" #remarkRef="ngModel" required
                          placeholder="{{'qms.remark-placeholder' | translate}}"  [class.ctrl-error]="remarkRef?.errors?.required"></textarea>
                          <div *ngIf="remarkRef?.errors?.required" class="error-message">
                            {{'ticket-title.Remarkisrequired' | translate}}.
                          </div>
                    </div>
                </div>
        </fieldset>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" [disabled]="catDialogformRef.invalid" (click)="modal.close('Save click')"> {{'ticket-title.Submit' | translate}} </button>
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">{{'ticket-title.cancel' | translate}}</button>
        </div>
    </form>
</div>

</ng-template>

<!---Ticket Self Close Dialog End-->

<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>
                   {{'detailsPages.sPlDetails' | translate}}
                </h2>

                <!-- <button mat-button="mat-button" class="btn btn-primary" id="sticky_Div">
                  
                    {{ "guideline.processForsPlDetails" | translate }}
                </button> -->
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-sm-12">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Who can apply for Service Provider License ?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            सर्विस प्रोवाइडर लाइसेंस के लिए कौन आवेदन कर सकता है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            All the SAMPADA registered user with Aadhar EKYC can apply for the service
                                            provider license.
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            आधार ईकेवाईसी के साथ संपदा पंजीकृत सभी उपयोगकर्ता सर्विस प्रोवाइडर लाइसेंस
                                            के
                                            लिए आवेदन कर सकते हैं।
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What services provide by Service Provider ?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            सर्विस प्रोवाइडर द्वारा कौन सी सेवाएँ प्रदान की जाती हैं?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            The Service Provider shall be able to provide following services:
                                        </p>
                                        <ul>
                                            <li>Selling e-stamps</li>
                                            <li>Initiating Registration process</li>
                                            <li>Search of documents</li>
                                            <li>Initiating application for Issuance copy of registered document
                                                (Certified copy)</li>
                                            <li>Property Valuation</li>
                                            <li>Duty Calculation</li>
                                            <li>Deed Drafting</li>
                                            <li>Payment</li>
                                        </ul>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            सर्विस प्रोवाइडर निम्नलिखित सेवाएँ प्रदान करने में सक्षम होगा:
                                        </p>
                                        <ul>
                                            <li>ई-स्टांप बेचना</li>
                                            <li>पंजीकरण प्रक्रिया शुरू करना</li>
                                            <li>दस्तावेजों की खोज</li>
                                            <li>पंजीकृत दस्तावेज़ की प्रति जारी करने के लिए आवेदन शुरू करना (प्रमाणित
                                                प्रति)</li>
                                            <li>संपत्ति मूल्यांकन</li>
                                            <li>शुल्क गणना</li>
                                            <li>डीड ड्राफ्टिंग</li>
                                            <li>भुगतान</li>
                                        </ul>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What is benefit of Service Provider License ?
                                        </div>
                                        <div *ngIf="lan == 1">
                                            सर्विस प्रोवाइडर लाइसेंस का लाभ क्या है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            User will get commision on each stamp sell and get income from document
                                            writing
                                            fees.By avaling the service provider license he can serve the services to
                                            form a
                                            good governace. Once you learn you can earn.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            उपयोगकर्ता को प्रत्येक स्टांप बिक्री पर कमीशन मिलेगा और दस्तावेज़ लेखन शुल्क
                                            से
                                            आय प्राप्त होगी। सर्विस प्रोवाइडर लाइसेंस प्राप्त करके वह एक अच्छा शासन
                                            बनाने के
                                            लिए सेवाएं प्रदान कर सकता है। एक बार सीख लेने के बाद आप कमा सकते हैं।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle="hideToggle">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Apply for Service Provider License
                                        </div>
                                        <div *ngIf="lan == 1">
                                            सर्विस प्रोवाइडर लाइसेंस के लिए आवेदन करें
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            User can login into the SAMPADA portal and follow the blow steps-
                                        </p>
                                        <ol>
                                            <li>Click on apply for service provider license from Menu</li>
                                            <li>Select education level</li>
                                            <li>Work location details.</li>
                                            <li>Consent for required document.
                                                <ul>
                                                    <li>Affidavit declaring all essential requirement and stating person
                                                        is not
                                                        involved in criminal activities</li>
                                                    <li>Character Certificate from a gazetted officer or police Officer
                                                    </li>
                                                    <li>Ownership / Rent Details of the work Place</li>
                                                    <li>Higher Secondary Certificate</li>

                                                </ul>
                                            </li>
                                            <li>Submit for approval.</li>
                                        </ol>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            उपयोगकर्ता SAMPADA पोर्टल में लॉग इन कर सकता है और निम्न चरणों का पालन कर
                                            सकता
                                            है-
                                        </p>
                                        <ol>
                                            <li>मेनू से सेवा प्रदाता लाइसेंस के लिए आवेदन पर क्लिक करें</li>
                                            <li>शिक्षा स्तर का चयन करें</li>
                                            <li>कार्य स्थान का विवरण।</li>
                                            <li>आवश्यक दस्तावेज़ के लिए सहमति.
                                                <ul>
                                                    <li>शपथपत्र जिसमें सभी आवश्यक आवश्यकताओं की घोषणा की गई हो और यह
                                                        बताया गया हो कि
                                                        व्यक्ति आपराधिक गतिविधियों में शामिल नहीं है</li>
                                                    <li>किसी राजपत्रित अधिकारी या पुलिस अधिकारी से चरित्र प्रमाण पत्र
                                                    </li>
                                                    <li>कार्यस्थल का स्वामित्व/किराया विवरण</li>
                                                    <li>उच्च माध्यमिक प्रमाणपत्र</li>
                                                </ul>
                                            </li>
                                            <li>
                                                अनुमोदन के लिए सबमिट करें.</li>
                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-sm-12">
                        <div class="process_img">
                            <img src="assets/images/SPlicenseprocess.jpg" style="width: 100%" />
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";

import { Injectable, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable, throwError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { CitizenAuthenticationService } from "../services/auth.service/citizen-authentication.service";
import { AuthenticationService } from "../services/auth.service/authentication.service";
//import {  HttpXsrfTokenExtractor } from '@angular/common/http';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: "body";
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
  body?: any;
}

export interface RDIRequestOptions {
  headers?: HttpHeaders;
  observe?: "body";
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: "text";
  withCredentials?: boolean;
  body?: any;

}

// export function customHttpClientCreator(http: HttpClient,authenticationService:AuthenticationService) {
//   return new CustomHttpClient(http,authenticationService);
// }

@Injectable({ providedIn: "root" })
export class CustomHttpClient {

  //@ViewChild(CitizenAuthenticationService, { static: false }) citizenAuthenticationService:CitizenAuthenticationService;
  // @ViewChild(AuthenticationService, { static: false }) authenticationService:AuthenticationService;
  GET<T>(arg0: string) {
    throw new Error("Method not implemented.");
  }
  public options!: IRequestOptions;
  public optionsRD!: RDIRequestOptions;



  // Extending the HttpClient through the Angular DI.
  public constructor(public http: HttpClient, private route: Router, private translateService: TranslateService) {
    // If you don't want to use the extended versions in some cases you can access the public property and use the original one.
    // for ex. this.httpClient.http.get(...)
    // let headers = new HttpHeaders({
    //   Authorization: `Bearer ${this.authService.token}`,
    //   "Access-Control-Allow-Origin": "*",
    // });
    // headers.append("Access-Control-Allow-Origin", "*");
    // this.options = { headers: headers };
  }

  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */


  public GetRdSerice(url: any, methodtype: any) {
    let httpHeaders = new HttpHeaders()
    httpHeaders.set("type", 'RDSERVICE');
    httpHeaders.set('Access-Control-Allow-Origin', '*');

    let param = {};
    return this.http
      .request(
        methodtype,
        url,
        {
          responseType: "text",
          // param,


        }).pipe(
          map((n) => {
            return n;
          }),

        );
  }

  public captureRdSerice(url: any, methodtype: any, rebody: any) {

    let options: RDIRequestOptions = { body: rebody, responseType: "text" }
    return this.http
      .request(
        methodtype,
        url,
        options
      ).pipe(
        map((n) => {
          return n;
        },
          // (error: any) => {
          //   return error;
          // }
        )//,
        //catchError(this.handleError)

      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {

    // const headerNameXSRF = 'X-XSRF-TOKEN';
    // let tokenXSRF = this.tokenExtractor.getToken() as string;

    let httpHeaders = new HttpHeaders()
      //  .set(headerNameXSRF, tokenXSRF)
      .set('Accept-Language', sessionStorage.getItem("selectedLanguage") || "");
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    return this.http.get<T | any>(endPoint, { headers: httpHeaders }).pipe(
      map((n) => {

        if (!n.responseStatus) {

          throw { Message: n.responseMessage, Type: "Failed" };
        }
        return n;
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))
    );
  }


  getGISMap(url: any) {
    return this.http.get(url)
      .pipe(
        map((n) => {

          return n;
        }),
        catchError(err => throwError(

          this.clearsessionStorage(err)
        ))
      );
  }
  public GetApi<T>(endPoint: string, param: any): Observable<T> {


    let httpHeaders = new HttpHeaders().set('Authorization', sessionStorage.getItem("token") || "")
      .set('Accept-Language', sessionStorage.getItem("selectedLanguage") || "");
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    return this.http.get<T | any>(endPoint, { headers: httpHeaders, params: param }).pipe(
      map((n) => {

        if (!n.responseStatus) {

          // this.authenticationService.tokenExpire()
          throw { Message: n.responseMessage, Type: "Failed" };
        }
        return n;
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))
    );
  }


  public GetApiForRefreshTokenOnly<T>(endPoint: string, param: any): Observable<T> {

    let httpHeaders = new HttpHeaders().set('Authorization', sessionStorage.getItem("token") || "")
      .set('Accept-Language', sessionStorage.getItem("selectedLanguage") || "");
    httpHeaders.append("Access-Control-Allow-Origin", "*");
    return this.http.get<T | any>(endPoint, { headers: httpHeaders, params: param }).pipe(
      map((n) => {

        if (!n.status) {

          // this.authenticationService.tokenExpire()
          throw { Message: n.responseMessage, Type: "Failed" };
        }
        return n;
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))
    );
  }

  clearsessionStorage(err: any) {
    //
    //console.log("err", err)
   // this.ngxService.stop();
    const status = err.status;
    //const status=401;
    //

    if (status == 401) {

      let msg = this.translateService.instant('session_expired_msg');
      alert(msg);
      let routeUrl = '';
      if (sessionStorage.getItem('loginType') != undefined && sessionStorage.getItem('loginType') != null && sessionStorage.getItem('loginType') != "citizen") {

        routeUrl = '/alogin'
        // this.clearsessionStorage();


      } else {
        routeUrl = '/clogin';
        // this.clearsessionStorage();
      }
      let flag = (sessionStorage.getItem("token") != null && sessionStorage.getItem("authData") != null) ? 1 : 0;


      if (sessionStorage.length > 0) {
        sessionStorage.removeItem("isUserLoggedIn");
        // sessionStorage.removeItem("loginType");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("token_data");
        sessionStorage.removeItem("expires_at");
        sessionStorage.removeItem("ud");
        sessionStorage.removeItem("featureList");
        sessionStorage.removeItem("officeList");
        sessionStorage.removeItem("selectedOffice");
        sessionStorage.removeItem("authData");
      }
      if (window.parent.sessionStorage.length > 0) {
        sessionStorage.removeItem("isUserLoggedIn");
        // sessionStorage.removeItem("loginType");
        window.parent.sessionStorage.removeItem("token");
        window.parent.sessionStorage.removeItem("token_data");
        window.parent.sessionStorage.removeItem("expires_at");
        window.parent.sessionStorage.removeItem("ud");
        window.parent.sessionStorage.removeItem("featureList");
        window.parent.sessionStorage.removeItem("officeList");
        window.parent.sessionStorage.removeItem("selectedOffice");
        window.parent.sessionStorage.removeItem("authData");
      }
      //this.currentUserSubject.next(null as any);
      if (flag) {
        this.route.navigate([routeUrl]);
      }
    } else {
      let msg = this.translateService.instant('tech_error_msg');
      //alert(msg);
    }


  }


  public PostApiToken<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {


    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || "",
      "Authorization": sessionStorage.getItem("token") || ""
    });
    //httpHeaders.append("Access-Control-Allow-Origin", "*");



    return this.http.post<T | any>(endPoint, params, { headers: httpHeaders }).pipe(
      map((res) => {

        /* if (!res.responseStatus) {
           throw { Message: res.responseMessage, Type: "failed" };
         } else if (res.responseStatus && !res.data) {
           return { Message: res.responseMessage, Type: "no-data" };
         }
         return { ...res, Type: "success" }; */
        return res;
      },
        (error: any) => {


          return error.responseMessage;
        }
      ),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))

    );
  }

  public PostApiTokenForText<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {


    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || "",
      "Authorization": sessionStorage.getItem("token") || ""
    });
    //httpHeaders.append("Access-Control-Allow-Origin", "*");



    return this.http.post<T | any>(endPoint, params, { headers: httpHeaders, responseType: 'text' as 'json' }).pipe(
      map((res) => {

        /* if (!res.responseStatus) {
           throw { Message: res.responseMessage, Type: "failed" };
         } else if (res.responseStatus && !res.data) {
           return { Message: res.responseMessage, Type: "no-data" };
         }
         return { ...res, Type: "success" }; */
        return res;
      },
        (error: any) => {


          return error.responseMessage;
        }
      ),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))

    );
  }

  public PostFormApiToken<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {


    let httpHeaders = new HttpHeaders({
      //"Content-Type":"application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || "",
      "Authorization": sessionStorage.getItem("token") || ""
    });
    //httpHeaders.append("Access-Control-Allow-Origin", "*");



    return this.http.post<T | any>(endPoint, params, { headers: httpHeaders }).pipe(
      map((res) => {

        /* if (!res.responseStatus) {
           throw { Message: res.responseMessage, Type: "failed" };
         } else if (res.responseStatus && !res.data) {
           return { Message: res.responseMessage, Type: "no-data" };
         }
         return { ...res, Type: "success" }; */
        return res;
      },
        (error: any) => {


          return error.responseMessage;
        }
      ),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))

    );
  }

  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Post<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {

    let httpHeaders = new HttpHeaders({
      "Access-Control-Allow-Methods": "GET, POST, PUT ,OPTION, DELETE",
      "Access-Control-Allow-Headers":
      "Content-Type,X-Requested-With,Accept,Access-Control-Allow-Origin,Access-Control-Request-Methods,Access-Control-Request-Headers",
      "Access-Control-Max-Age": "3600",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
      //"Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });
    return this.http
      .post<T | any>(endPoint, params, options ?? { headers: httpHeaders })
      .pipe(
        map((n) => {
          return n;
        }),
        catchError(err => throwError(
          this.clearsessionStorage(err)
        ))
      );

  }



  /**
     * POST request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
  public PostLCNC<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ) {
    return this.http
      .post('sam21/slot-statitics-report', params.responseData1, {
        headers: new HttpHeaders({
          'Authorization': params.responseData,
          'Content-type': 'application/json'
        })
      }).subscribe(() => window.location.href = 'http://10.115.96.181:8080/ords/r/igrs/');

  }



  public DownloadFile<T>(
    endPoint: string,
    params: any,
    options?: IRequestOptions
  ): Observable<T> {
    return this.http.get<any>(endPoint, options ?? this.options);
  }

  public PostGISApi<T>(
    endPoint: string,
    params: any,
  ): Observable<T> {
    let headers = new HttpHeaders({
      //"Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT",
      "Access-Control-Allow-Headers":
        "Content-Type,X-Requested-With,Accept,Access-Control-Allow-Origin,Access-Control-Request-Methods,Access-Control-Request-Headers",
      "Access-Control-Max-Age": "3600",

    });




    let options1 = { headers: headers };

    return this.http.post<T | any>(endPoint, params, options1).pipe(
      map((n) => {
        console.log('postGISAPI call res: ', n);
        if (!n.status) {
          throw { Message: n.message, Type: "failed" };
        } else if (n.status && !n.data) {
          return { Message: n.message, Type: "no-data" };
        }
        return { ...n, Type: "success" };
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))
    );
  }


  public PostApi<T>(
    endPoint: string,
    params: any,
    langType: string,
    options?: IRequestOptions
  ): Observable<T> {
    let headers = new HttpHeaders({
      // "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT ,OPTION, DELETE",
      "Access-Control-Allow-Headers":
        "Content-Type,X-Requested-With,Accept,Access-Control-Allow-Origin,Access-Control-Request-Methods,Access-Control-Request-Headers",
      "Access-Control-Max-Age": "3600",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
    });




    let options1 = { headers: headers };

    return this.http.post<T | any>(endPoint, params, options1).pipe(
      map((n) => {
        if (!n.status) {
          throw { Message: n.message, Type: "failed" };
        } else if (n.status && !n.data) {
          return { Message: n.message, Type: "no-data" };
        }
        return { ...n, Type: "success" };
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err)
      ))
    );
  }
  public ValidateToken<T>(
    endPoint: string,
    params: any,
    token: string
  ): Observable<T> {
    ;
    let headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      // "Access-Control-Allow-Origin": "*"
    });
    // headers.append("Access-Control-Allow-Origin", "*");
    let options = { headers: headers };
    return this.http.post<T | any>(endPoint, params, options);
  }
  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Put<T>(
    endPoint: string,
    params: Object,
    options?: IRequestOptions
  ): Observable<T> {
    return this.http.put<T>(endPoint, params, options);
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(endPoint, options);
  }

  public PutWithToken<T>(
    endPoint: string,
    params: Object,
    options?: IRequestOptions
  ): Observable<T> {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || "",
      "Authorization": sessionStorage.getItem("token") || "" // Add token to headers
    });
  
    // Merge custom options if provided
    let mergedOptions = options ? { ...options, headers: httpHeaders } : { headers: httpHeaders };
  
    return this.http.put<T>(endPoint, params, mergedOptions).pipe(
      map((response) => {
        // Handle successful response
        return response;
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err) // Handle errors
      ))
    );
  }
  
  public DeleteWithToken<T>(
    endPoint: string,
    options?: IRequestOptions
  ): Observable<T> {
    let httpHeaders = new HttpHeaders({
      "Accept-Language": sessionStorage.getItem("selectedLanguage") || "",
      "Authorization": sessionStorage.getItem("token") || "" // Add token to headers
    });
  
    // Merge custom options if provided
    let mergedOptions = options ? { ...options, headers: httpHeaders } : { headers: httpHeaders };
  
    return this.http.delete<T>(endPoint, mergedOptions).pipe(
      map((response) => {
        // Handle successful response
        return response;
      }),
      catchError(err => throwError(
        this.clearsessionStorage(err) // Handle errors
      ))
    );
  }
  
}
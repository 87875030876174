import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';


import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Department } from 'projects/common-lib/src/lib/models/api.masterdata.model/department';
import { DefaultServiceService } from '../../default/default-service.service';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementServive } from '../../service/UserManagementServive';
import { CitizenService } from '../../service/citizen.service';

@Component({
  selector: 'app-complaint-request',
  templateUrl: './complaint-request.component.html',
  styleUrls: ['./complaint-request.component.scss']
})
export class ComplaintRequestComponent implements OnInit {

  @ViewChild(AlertmessageComponent, {static: false}) alertmessage: AlertmessageComponent;
  registerForm: FormGroup = new FormGroup({});
  submitted = false;

  oldEmail ='';
  emailtaken = false;

  lan: number;
  checkedVal: boolean;
  ldap:boolean=false;

  authorityDocFile:any;
  fileUploadError:any=false;
  complaintNo:any;
  complaintReqList: any;
  formCode: any = 0;
  enablesubdepartment:boolean=false;
  enabledeptname:boolean=false;

  allDepartmentList: Department[] = new Array();
  departmentList: Department[] = new Array();
  subDepartmentList: Department[] = new Array();

  constructor(private formBuilder: FormBuilder
    , private translateService:TranslateHEService
    , private authenticationService:AuthenticationService
    , private translate:TranslateService
    , private titleService: Title
    , private citizenService:CitizenService
    , private defaultServiceService:DefaultServiceService
    , private userService: UserManagementServive) { 
      //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.translateService.updateApprovalMessage(this.lan);
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
     
  }


  ngOnInit(): void {
    this.defaultServiceService.setDefaultType('complaint-request');
    this.registerForm = this.formBuilder.group({
      requestType:[null, Validators.required],
      nameEn: ['', Validators.required],
      nameHi: ['', [Validators.required, Validators.pattern("[\u0900-\u097F. ]+")]],
      departmentId: ['', Validators.required],
      subDepartmentId: [''],
      departmentNameEn: ['', Validators.required],
      departmentNameHi: ['', [Validators.required, Validators.pattern("[\u0900-\u097F. ]+")]],
      designationEn: ['', Validators.required],
      designationHi: ['', [Validators.required, Validators.pattern("[\u0900-\u097F. ]+")]],
      email: ['', [Validators.required, Validators.email]],
      confirmEmail: ['', [Validators.required, Validators.email]],
      mobileNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      confirmMobileNo: ['', [Validators.required]],
      authorityDoc: ['', [Validators.required]],
      authorityDocFile:['', [Validators.required]],
      isLdap:['', Validators.required]
    }

    );
    this.getComplaintReqTypes();

    
  }

  setLdap(data:any){
    // console.log('isLdap: ', data);
    if(data.value==1){
      this.ldap=true;
    }else{
      this.ldap=false; 
    }
    // console.log('Ldap: ', this.ldap);
  }

  getDepartment() {
    this.userService.get_all_departmets().subscribe((result) => {
      this.allDepartmentList = result.responseData;
      this.departmentList = this.allDepartmentList.filter(x=>x.parentId=='0');

      // let dept:Department = new Department();
      // dept.id=99998;
      // dept.departmentNameEn='Other Department';
      // dept.departmentNameHi='अन्य विभाग';
      // this.departmentList.push(dept);
    });
    // console.log('departmentList: ', this.departmentList);
  }
  getSubDepartment(data:any) {
    this.subDepartmentList = this.allDepartmentList.filter(x=>x.parentId==data.value);

    // let dept:Department = new Department();
    // dept.id=99999;
    // dept.departmentNameEn='Other Sub Department';
    // dept.departmentNameHi='अन्य उप विभाग';
    // this.subDepartmentList.push(dept);
    // console.log('subDepartmentList: ', this.subDepartmentList);

    let depart = this.departmentList.find(x=>x.id==data.value);
    this.registerForm.controls['departmentNameEn'].setValue(depart?.departmentNameEn);
    this.registerForm.controls['departmentNameHi'].setValue(depart?.departmentNameHi);

    if(data.value==99998){
      this.enablesubdepartment = false;
      this.enabledeptname = true;
    }else{
      this.enablesubdepartment = true;
      this.enabledeptname = false;
    }
  }
  setSubDepartment(data:any) {
    // console.log('subdepartment: ', data);
    let depart = this.subDepartmentList.find(x=>x.id==data.value);
    this.registerForm.controls['departmentNameEn'].setValue(depart?.departmentNameEn);
    this.registerForm.controls['departmentNameHi'].setValue(depart?.departmentNameHi);
    if(data.value==99999){
      this.enabledeptname = true;
    }else{
      this.enabledeptname = false;
    }
  }

  getComplaintReqTypes() {
    this.citizenService.getComplaintReqList().subscribe((result) => {
      this.complaintReqList = result.responseData;
      // console.log('this.complaintReqList: ', this.complaintReqList);
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm?.controls; }

  changeLan(ev: any) {
    //console.log(this.lan);
    // console.log(ev.value);
    if (ev.value == 1) {
      this.lan = 0;
      sessionStorage.setItem("selectedLanguage", 'en');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //this.translate.use(sessionStorage.getItem("selectedLanguage") || "en")
      this.translateService.updateApprovalMessage(this.lan);
    } else {
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage", 'hi');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
     // this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi")
      this.translateService.updateApprovalMessage(this.lan);
    }
    // window.location.reload();
    this.translate.get("legal-person-title").subscribe(name=>{
      this.titleService.setTitle(name);
    });
  }


  ProfileChangeEvent(pfileInput: any): any {
    // this.profileImageError = null;
    if (pfileInput.target.files && pfileInput.target.files[0]) {
      // Size Filter Bytes
      const pmax_size = 2109 * 1000; // 2MB
      const pallowed_types = ["application/pdf"];
      const pmax_height = 15200;
      const pmax_width = 25600;

      if (pfileInput.target.files[0].size > pmax_size) {
        window.scrollTo(0, 0);
        this.registerForm.controls['authorityDoc'].setValue('');
        //this.registerForm.controls['authorityDocFile'].setValue('');
        this.authorityDocFile=null;
        this.fileUploadError = this.translate.instant('file-size-error'); //+ pmax_size / 1000 + "Kb"
        setTimeout(() => { this.fileUploadError = ''; }, 5000);
        return false;
      }

      if (!pallowed_types.includes(pfileInput.target.files[0].type, 0)) {
        this.registerForm.controls['authorityDoc'].setValue('');
        //this.registerForm.controls['authorityDocFile'].setValue('');
        this.authorityDocFile=null;
        this.fileUploadError = this.translate.instant('file-type-error');
        setTimeout(() => { this.fileUploadError = ''; }, 5000);
        return false;
      }
      const preader = new FileReader();
      preader.onload = (pe: any) => {
        const pimage = new Image();
        pimage.src = pe.target.result;
        this.authorityDocFile=pe.target.result.split(',')[1];
        this.registerForm.controls['authorityDoc'].setValue(pfileInput.target.files[0].name);
        //this.registerForm.controls['authorityDocFile'].setValue(pfileInput.target.files[0].name);
      };
      preader.readAsDataURL(pfileInput.target.files[0]);
    }
  }


  onSubmit() {
    this.submitted = true;
    this.complaintNo = null;
    if (this.registerForm.invalid) {
      // console.log('1-',this.registerForm);
      let fld = "";
      for (const field in this.registerForm.controls) { // 'field' is a string
        const control = this.registerForm.get(field); // 'control' is a FormControl 
        if (control.status == "INVALID") {
          fld = field;
          // console.log("---------------controle--fld----11111-------- ",fld);
          break;
        }
      }
      return;
    }
    let authFile = this.authorityDocFile;
    this.registerForm.controls['authorityDocFile'].setValue('');
    // console.log('2-',this.registerForm);
    // console.log('3-',this.registerForm.getRawValue());
    this.authenticationService.submitComplaintRequest(this.registerForm.getRawValue(), authFile).subscribe(res =>{
      // console.log('response of complaint submit: ', res);
      if(res!=null){
        if (res.responseStatus == "true") {
          this.registerForm.reset();
          this.registerForm.controls['requestType'].setValue(null);
          this.registerForm.controls['departmentId'].setValue('');
          this.registerForm.controls['subDepartmentId'].setValue('');
          this.submitted=false;
          this.registerForm.controls['authorityDoc'].setValue(null);
          this.authorityDocFile=null;
          this.complaintNo = res.responseMessage;
          this.alertmessage.successAlertMessage(res);
        } else {
          this.complaintNo = null;
          this.alertmessage.errorAlertMessage(res);
        }
      }
    });
  }

  showForms(){
    // console.log('requestType: '+this.registerForm.controls['requestType'].value);
    this.formCode = this.registerForm.controls['requestType'].value;
    if(this.formCode==1){
      this.getDepartment();
    }

  }


  send(source: any, destination: any) {
    // console.log(source.value);
    // console.log(destination);
    this.translateService.translateLang(source.value).subscribe(
      (res: any) => {
        let name: string = destination.name;
        this.registerForm.controls[name].setValue(res);
        // console.log(this.registerForm.controls[name]);
        //this.result = res.data.translations[0].translatedText;
      },
      err => {
        // console.log(err);
      }
    );
  }

  getAvailability(email:any) {
    // console.log('email: ', this.registerForm.controls.email);
    if (email==null || email == '' || email == undefined) {
      this.emailtaken = false;
    } else {
      this.oldEmail = email;
      this.authenticationService.checkAvailabilityOfEmail(email).subscribe(res => {
        if (res && res.responseStatus == 'true') {
          this.emailtaken = true;
          this.registerForm.controls['email'].setValue('');
          setTimeout(() => { this.emailtaken = false;}, 3000);
        } else {
          this.emailtaken = false;
        }
      });
    }

  }
  

}

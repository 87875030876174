import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  lan: number;
  private settings!: AppSetting;
  constructor(
     private translateService: TranslateHEService,
      private translate: TranslateService,
      private appSettingsService: AppSettingService
   
  ) { 
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
         this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });

     
  }

  ngOnInit(): void {
  }

}


<head>
    <meta charset="UTF-8">
</head>


<div>
    <iframe id="viewer" style="height: 100vh;width: 100%;" frameborder="0"></iframe>
</div>

<div class="e-sign" id="esignBtn" *ngIf="eregFlag!=undefined && eregFlag=='1' && (igrsEregPartyDetailsBean.contentStatus == 25 || igrsEregPartyDetailsBean.contentStatus == 73)">
   <button class="btn btn-primary" (click)="showEsign()"> 
   <i class="fa fa-pencil-square-o"></i> 
   {{'TakeElectronicSign.save'| translate}}
   </button>
</div>

<div *ngIf="eregFlag!=undefined && eregFlag=='1' && (igrsEregPartyDetailsBean.contentStatus == 25 || igrsEregPartyDetailsBean.contentStatus == 73)" 
class="border-left p-5 esignn" id="esignnId">
<button class="colsebtn" (click)="closeEsign()"> 
  <mat-icon>close</mat-icon>
   </button>
<em class="alert alert-info p-1 mb-2 d-block" *ngIf="lan == 0"> 
    Please complete e-sign process within 3 min otherwise session will be expired
</em>
<em class="alert alert-info p-1 mb-2 d-block" *ngIf="lan == 1"> 
कृपया ई-साइन प्रक्रिया 3 मिनट में पूरी करें अन्यथा सत्र समाप्त हो जाएगा
</em>
<div class="form-group mt-1 mb-2" *ngIf="esignType == 3">
    <a target="_blank" href="{{settings.unipay_dsc_software_link}}" class="text-primary"><u>

        {{'TakeElectronicSign.dscDownloadlink' | translate}}
     </u></a>
</div>
    <h4 class="text-primary font-italic font-weight-bold pl-3" style="font-size: 16px; margin-bottom: 12px">
        <u *ngIf="lan == 0">e-sign Form</u>
        <u *ngIf="lan == 1">ई-साइन फॉर्म</u>
    </h4>

    <lib-alertmessage></lib-alertmessage>

    <form [formGroup]="esignHtml" (ngSubmit)="submitprintPartyEsign(esignHtml.value)" method="post" novalidate>
        <div >
            <div class="p-2">
                 <div class="form-check form-check-inline mr-4" *ngIf="igrsEregPartyDetailsBean.aadharEkyc == 1">
                    <input class="form-check-input" type="radio" (click)="filterByEsignType(1)" name="esignType" value="1" id="esignTypeId"  formControlName="esignType"  required [ngClass]="{'is-invalid':submitted && t.esignType.errors}">
                    <label class="form-check-label" for="esignTypeId">
                        <span *ngIf="lan == 0">C-DAC</span>
                        <span *ngIf="lan == 1">सी-डैक</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline" *ngIf="igrsEregPartyDetailsBean.aadharEkyc == 1">
                    <input class="form-check-input" type="radio" (click)="filterByEsignType(2)" name="esignType"  value="2" id="esignTypeId1"  formControlName="esignType" required [ngClass]="{'is-invalid':submitted && t.esignType.errors}">
                    <label class="form-check-label" for="esignTypeId1">
                         <span *ngIf="lan == 0">eMudhra</span>
                        <span *ngIf="lan == 1">ईमुद्रा</span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline" *ngIf="(encryptedEregId == null || encryptedEregId == undefined)">
                    <input class="form-check-input" type="radio" (click)="filterByEsignType(3)" name="esignType"  value="3" id="esignTypeId1"  formControlName="esignType" required [ngClass]="{'is-invalid':submitted && t.esignType.errors}">
                    <label class="form-check-label" for="esignTypeId1">
                        
                         <span *ngIf="lan == 0">Unipay DSC</span>
                        <span *ngIf="lan == 1">यूनिपे डीएससी</span>
                    </label>
                  </div>

                  <div *ngIf="submitted"  class="invalid-feedback1">
                    <div *ngIf="esignType == undefined || esignType == ''" >
                        {{ "mandatory" | translate }}
                    </div>
                 </div>
            </div>
            <div class="p-2" *ngIf="esignType != '' && esignType != 3">
                <label class="form-check-label" >
                     <span *ngIf="lan == 0">Esign Mode</span>
                    <span *ngIf="lan == 1">ईसाइन मोड</span>
                  </label>
                <select class="form-control" formControlName="esignAuthMode" id="esignAuthMode"
                 [value]="esignAuthMode" (change)="esignAuthModeChange()">
                    <option value="" disabled>Select auth mode</option>
                    <option value="1" *ngIf="(settings.env != 'prod' && settings.env != 'uat')">{{'otp'|translate}}</option>
                    <option value="2">{{'kyc-details.bio-thumb'|translate}}</option>
                    <option value="3">{{'kyc-details.bio-iris'|translate}}</option>
                    <!-- <option value="4">{{'kyc-details.bio-face'|translate}}</option> -->
                </select>
            </div>

            </div>

        <div class="">
            <div class="form-group" *ngIf="esignType != '' && esignType != 3">

                <label style="
  color: #000;
  font-weight: unset;
  font-size: 15px;
  margin-bottom: 0;
">

                    {{ "e-stamp.Aadhar-digits" | translate }}
                    <span class="text-danger">*</span>
                </label>
                <div class="input-group mb-2">
                    <input style="height: 33px" type="text" maxlength="4" readonly [(ngModel)]="aadhaarLast4DigitsMasked"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        required [ngClass]="{'is-invalid':submitted && t.aadhaarLast4Digits.errors}" name="aadhaarLast4Digits" class="form-control" formControlName="aadhaarLast4Digits" />
                    <div *ngIf="submitted && t.aadhaarLast4Digits.errors" class="invalid-feedback">
                        <div *ngIf="t.aadhaarLast4Digits.errors.required">
                            {{ "mandatory" | translate }}
                        </div>
                    </div>
                </div>

                <label style="
  color: #000;
  font-weight: unset;
  font-size: 15px;
  margin-bottom: 0;
">
                    <!-- {{'admin-profile-page-data.center-serviceprovider-details.confacc' | translate}} -->
                    {{ "e-stamp.Remarks" | translate }}
                </label>
                <div class="input-group mb-3">
                    <textarea class="form-control" name="remarks" id="remarks" formControlName="remarks" placeholder="{{ 'RenameGuidelineLocation.EnterYourRemarks' | translate }}"
                        maxlength="100"></textarea>
                </div>
            </div>


         
            <div class="text-right">
                <button type="button" class="btn btn-primary mr-1"  (click)="backToPreviousPage()">{{'goback'
                    | translate}}</button>
                <button class="btn btn-primary ml-2_5" type="submit" formmethod="post" *ngIf="esignType != 3">
                    <i class="fa fa-pencil-square-o"></i>
                    {{ "esign" | translate }}
                </button>

                <button class="btn btn-primary ml-2_5" type="button" *ngIf="esignType == 3" (click)="partyDscEsignProcess()">
                    <i class="fa fa-pencil-square-o"></i>
                    {{ "esign" | translate }}
                </button>

            </div>
        </div>
        
    </form>
   

    <form ngNoForm method="post" id="ICGForm" name="ICGForm" action="" enctype="application/x-www-form-urlencoded">
        <input type="hidden" name="msg" value="" id="msg" />
    </form>
</div>

<ng-template #thumbDialog let-c="close" let-d="dismiss">
    <h2 matDialogTitle class="Consenterdialog-title">{{'kyc-details.party-thumb' | translate}}</h2>
    <button type="button" class="colsebtn h4" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="form-check form-check-inline mb-2">
            <input class="form-check-input" type="radio" name="Thumb" id="Thumb" checked>
            <label class="form-check-label" for="Thumb">
                {{'kyc-details.bio-thumb' | translate}}
            </label>
          </div>
            <div class="aadharInput">
                <div class="input-group mt-3  mb-3">
                    <label class="w-100">{{'kyc-details.select-thumb'|translate}}</label>
                    <div class="d-flex align-items-center w-100">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><img src="assets/images/thumb-scan.png" height="35">
                            {{'kyc-details.thumb-device'|translate}} </span>
                    </div>
                    <select disabled>
                        <option value="">{{'e-sign.SelectDeviceType' | translate}}</option>
                        <option value="mantra-fms100" selected>MANTRA MFS500</option>
                        <!--<option value="morpho-1300e3">MORPHO 1300E3</option>-->
                    </select>
                </div>
                </div>
          
            </div>
            <div class="alert alert-danger" *ngIf="isThumbCapture">{{'proIdentification.Pleaseputyourthumbindevice' | translate}}......</div>
            <div class="recapture mt-4 mb-2" *ngIf="recaptureWrap">
                <label>{{'e-sign.BiometricSign' | translate}}:</label>
                <div class="alert alert-danger" *ngIf="isThumbCapture">{{'proIdentification.Pleaseputyourthumbindevice' | translate}}......</div>
                <div class="border p-1 m-1 text-center">
                    <img [src]="thumbImageBase64" style="width:150px">
                </div>
            </div>
      </div>
  
  <div class="modal-footer">
 <!--   <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="backToPrimary(initiateESign); d('Cross click')">{{'TakeElectronicSign.back' | translate}}</button> -->
    <button type="button" class="btn btn-info" (click)="captureThumb()" *ngIf="captureHide">{{'e-sign.Capture' | translate}}</button>
    <button type="button" class="btn btn-info"  *ngIf="recaptureShow" (click)="captureThumb()">{{'e-sign.Recapture' | translate}}</button>
    <button type="button" class="btn btn-primary" *ngIf="esignNextButtonShow" (click)="openUSBDongle(); d('Cross click')">{{'TakeElectronicSign.next' | translate}}</button>
  </div>
</ng-template>

<ng-template #dongleDialog let-c="close" let-d="dismiss">
    <h2 matDialogTitle class="Consenterdialog-title">{{'e-sign.DSCSign' | translate}}</h2>
    <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>


    <div class="modal-body">
    
                <div class="aadharInput">
            </div>
            <div class="recapture mt-4 mb-2">
                <div class="border p-1 m-1 text-center">
                    <img src="assets/images/3763108.png" style="width:150px">
                </div>
            </div>
      </div>
 
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="openCaptureThumbDialog(); d('Cross click')">{{'TakeElectronicSign.back' | translate}}</button>
    <button type="button" class="btn btn-primary"  (click)="dscPartyEsignRequest();d('Cross click')">{{'TakeElectronicSign.save' | translate}}</button>
  </div>
</ng-template>


<!-- <button (click)="onBtnPrintClick()" target="_blank" class="btn btn-primary m-0 d-print-none mr-2 mt-1 ml-1">
    print
</button> -->
<!-- <ngx-ui-loader [loaderId]="'ereg-deed-template'"></ngx-ui-loader> -->
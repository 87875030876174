import { IgrsProtestRequestHistoryBean } from "./IgrsProtestRequestHistoryBean";

export class IgrsProtestRequestTxn {
    id?: number;
    fkProtestTypeId?: number;
    isDocRegistered?: boolean;
    fkCourtId: number;
    fkStateId: number;
    fkDistrictId: number;
    docType: string;
    address: string;
    courtOrderNumber: string;
    courtOrderDate: Date;
    stayStartDate: Date;
    protestDetails?: string;
    authorityOrder?: string;
    authorityOrderFile?: string;
    isFileChange:boolean;
    nameOfRepresentative: string;
    stayOrderOnProperty: string = null;
    status?: number;
    protestReqBy: string;
    isRequestBySr: boolean;
    isRequestNullAndVoid : boolean;
    protestInitiationId: string;
    protestAmount: number;
    paymentMode: string;
    paymentStatus: number;
    finalRemarks: string;
    eregId: string;
    urn: string;
    finalProtestReqId: string;
    regDocNo: string;
    regDocDate: Date;
    propSourceTypeName: string;
    propOwnerName:string;
    applicantMobNo:string;
    createdDate?: Date;
    createdBy?: number;
    modifiedDate?: Date;
    modifiedBy?: number;
    isBankEntity: boolean;
    documentMMYear: string;
    nullVoidDoc: string;
    userName:string;
    userPhoneNumber: number;
    email:string;
    userWhatspNo: number;
    igrsProtestRequestHistoryBean : IgrsProtestRequestHistoryBean[];


  }
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';

@Injectable({
    providedIn: 'root'
  })
export class MapMyindiaService{

    private settings!: AppSetting;
    mapmyindiaToken:string = "";
    token:string="";

    constructor(private appSettingsService: AppSettingService, private http:HttpClient) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
            //this.saveTocken();
        });
    }

    saveTocken(){
        this.fetchTocken().subscribe((response:any)=>{
            if(response){
                this.setToken(response.access_token);
                //this.cookieService.set('searchToken', response.access_token);
            }else{
            //alert("Data Not Found \n डेटा नहीं मिला।");
            }
        },(error:any)=>{
            
        });
    }

    fetchTocken<T>():Observable<T>{
        let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json');//.set('Authorization', `Bearer 2759087a-3222-443f-aa5e-db44519610b2`);
        return this.http.post<T | any>(this.settings.mapmyindiaTockenGen, null).pipe(
            map((data:any) => {
                if (!data) {
                    throw { Message: data, Type: "Failed" };
                }
                return data;
            }),catchError(err=> throwError(this.handleError(err)))
          );
      }
    
      handleError = (error: HttpErrorResponse) => {
         // console.log('Handle on Component', error);
      };

      setToken(token: string): void {
        this.token = token;
      }
    
      getToken(): string {
        return this.token;
      }
}
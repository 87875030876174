import { DeedCategoryBean } from "./deed-category-bean";

export class DeedTypeBean {

     id:number;
     deedTypeNameEn:string;
     deedTypeNameHi:string;
	 deedCategoryId: DeedCategoryBean;
	 deedTypeDescEn:string;
	 deedTypeDescHi:string;
	 status:boolean;
     createdBy:number;
	 createdDate:Date;
	 modifiedBy:number;
	 modifiedDate:Date;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CitizenUserProfileDetails, MstPayServiceMatrixBean, MstPayTreasuryConfigBean, MstWalletPayHeadBean, SPLicenseConfigBean } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { NgForm } from '@angular/forms';
import { DeedInstrumentComponent } from 'projects/common-lib/src/lib/component/deed-instrument/deed-instrument.component';

import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';
import { DutyService } from '../duty-service/duty.service';
import { EstampService } from '../../../service/estamp.service';
import { CitizenService } from '../../../service/citizen.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

export class DutyInstFieldMappingElement {
  sNo: number;
  fieldNameEn: string;
  fieldNameHi: string;
  fieldType: string;
  parentId:number;
  instrumentId: any = <any>{};
  id: number;
   
  constructor( 
    fieldNameEn: string,
    fieldNameHi: string,
    fieldType: string,
    parentId:number,
    instrumentId: any = <any>{},
    id: number,
    ) {
    
    //console.log("Inside constructor instrumentId  == :",instrumentId);
    this.fieldNameEn = fieldNameEn;
    this.fieldNameHi=fieldNameHi;
    this.fieldType=fieldType;
    this.parentId=parentId;
    this.instrumentId=instrumentId;
    this.id=id;
  }
}
export class DutyInstFieldMappingModel {
  id:number;
  sNo: number;
  fieldNameEn: string;
  fieldNameHi: string;
  fieldType: string;
  parentId:number;
  //instrumentId:number;
  instrumentId: any = <any>{};
}

const DutyInstField_ELEMENT_DATA: DutyInstFieldMappingModel[] =[];

@Component({
  selector: 'app-instrument-field-mapping-form',
  templateUrl: './instrument-field-mapping-form.component.html',
  styleUrls: ['./instrument-field-mapping-form.component.scss']
})


export class InstrumentFieldMappingFormComponent implements OnInit {
  sNo: number;
  fieldNameEN: string;
  fieldNameHI: string;
  fieldType: string;
  parentId:number;
  lan:number=1
  instrumentId: any = <any>{};
  //@ViewChild('childid') child: DemographyLoadComponent;
  pInstrumentId: string;
  pDeedCategoryId: string;
  pDeedTypeId: string;
  @ViewChild(DeedInstrumentComponent) child: any;
  dutyInstFieldMappingModel:DutyInstFieldMappingModel=new DutyInstFieldMappingModel();
  dutyInstFieldMappingDto:DutyInstFieldMappingModel=new DutyInstFieldMappingModel();
  constructor(public dialog: MatDialog,
    private estampService: EstampService,
    private dcService: DutyService,
    private activatedRoute: ActivatedRoute,
    private userService: CitizenService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private authenticationService:AuthenticationService,
    private translateService: TranslateHEService,
    private translate:TranslateService,
    ) { 
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
    }

  dataSource = new MatTableDataSource<DutyInstFieldMappingModel>(DutyInstField_ELEMENT_DATA);
  displayedColumns: string[] = ["sNo","FieldNameEnglish","FieldNameHindi","FieldType","instrumentId","actions"];
 // displayedColumns: string[] = ["sNo","FieldNameEnglish","FieldNameHindi","FieldType","ParentId","instrumentId","actions"];
  
  ngOnInit(): void {
    this.dutyInstFieldMappingModel = { 
      id:0,
      sNo:0,
      fieldNameEn:null,
      fieldNameHi:null,
      fieldType:null,
      parentId:0,
    //  instrumentId:0,
      instrumentId:null,
    };

    this.activatedRoute.paramMap.subscribe(params=>{
      // let value=((((((((((((((((((((((((((((((params.get('status').replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/")).replace("~!~", "/"));
      let instrumentId:any=params.get('instrumentId');
      let deedCategoryId:any=params.get('deedCategoryId');
      let deedTypeId:any=params.get('deedTypeId');
      this.pInstrumentId = instrumentId;
      this.pDeedTypeId = deedTypeId;
      this.pDeedCategoryId = deedCategoryId;
       console.log("instrumentId val1----------- ",instrumentId+" deedCategoryId:: "+deedCategoryId+" deedTypeId::"+deedTypeId);
    });

  }
  dutyInstFieldMappingBeanList: InstMappingBean[] = new Array();
  getInstIdEmitter(event:any){
    this.instrumentId = event;
    console.log("istrument Id::"+event);
  }
  getInstFieldMappingEmitter(event:any){
    //console.log(event)
    this.dutyInstFieldMappingBeanList = event;

    for(let i=DutyInstField_ELEMENT_DATA.length;i>0;i--){
      DutyInstField_ELEMENT_DATA.splice((i-1),1);
     }
     if(this.dutyInstFieldMappingBeanList.length > 0)
     {
      for(let i=0;i<this.dutyInstFieldMappingBeanList.length;i++){
        this.dutyInstFieldMappingDto=new DutyInstFieldMappingModel();
        this.dutyInstFieldMappingDto.id=this.dutyInstFieldMappingBeanList[i].id;
        this.dutyInstFieldMappingDto.fieldNameEn=this.dutyInstFieldMappingBeanList[i].fieldNameEn;
        this.dutyInstFieldMappingDto.fieldNameHi=this.dutyInstFieldMappingBeanList[i].fieldNameHi;
        this.dutyInstFieldMappingDto.fieldType=this.dutyInstFieldMappingBeanList[i].fieldType;
        this.dutyInstFieldMappingDto.parentId=this.dutyInstFieldMappingBeanList[i].parentId;
        this.dutyInstFieldMappingDto.instrumentId=this.dutyInstFieldMappingBeanList[i].instrumentId;
       
          console.log("dutyInstFieldMappingBeanList values : ",this.dutyInstFieldMappingDto);
          DutyInstField_ELEMENT_DATA.push(this.dutyInstFieldMappingDto);
        
        }
         for(let i=0;i<DutyInstField_ELEMENT_DATA.length;i++){
       
          DutyInstField_ELEMENT_DATA[i].sNo=i+1;
          this.dataSource = new MatTableDataSource<any>(DutyInstField_ELEMENT_DATA);
        
        }
     }else{
      this.ngxService.stop();
    }
  }

  editClick(dutyInstFieldMappingModel: DutyInstFieldMappingModel) {

  this.dutyInstFieldMappingModel=new DutyInstFieldMappingModel();
  this.dutyInstFieldMappingModel=dutyInstFieldMappingModel;
 
  
  document.getElementById("addParty").innerHTML = "Update";
  
}

removeClick(dutyInstFieldMappingModel: DutyInstFieldMappingElement){
  if(confirm("Are you sure to delete ")) {
    console.log("Inside remove===== ",dutyInstFieldMappingModel.id);
  if(DutyInstField_ELEMENT_DATA.length>1){
    DutyInstField_ELEMENT_DATA.splice((dutyInstFieldMappingModel.sNo-1),1);
     for(let i=0;i<DutyInstField_ELEMENT_DATA.length;i++){
      DutyInstField_ELEMENT_DATA[i].sNo=i+1;
      this.dataSource = new MatTableDataSource<any>(DutyInstField_ELEMENT_DATA);
    }
    }else{
      DutyInstField_ELEMENT_DATA.pop();
      this.dataSource = new MatTableDataSource<any>([]);
    } 
//------Delete record start -----------------
    this.dcService.deleteDutyInstFieldMappingDetails(dutyInstFieldMappingModel).subscribe(res => {
      if (res.responseStatus == 'true') {
        
        this.ngxService.stop(); 
      } else{
        this.ngxService.stop();
       
      }
       
     },
     (err) => {
     
       this.ngxService.stop();
       
     })
     //---Delete record end--------------
  }
  else{
    return
  }
  

}


onSubmitForEstampPartyDetails(form:NgForm){
  console.log("Inside onSubmitForEstampPartyDetails :",form.invalid)
  if(form.invalid){
    return
  }
  console.log("Inside dutyInstFieldMappingDetails :",DutyInstField_ELEMENT_DATA)
if(document.getElementById("addParty").innerText==='Add'){
  let fNameEn = this.dutyInstFieldMappingModel.fieldNameEn;
  let fType = this.dutyInstFieldMappingModel.fieldType;
  console.log("Button value : ",document.getElementById("addParty").innerText);
  if((fNameEn === null || fNameEn === undefined) || (fType === null || fType === undefined))
  {
    return
  }
 this.dutyInstFieldMappingDto=new DutyInstFieldMappingModel();
 this.dutyInstFieldMappingDto.fieldNameEn=this.dutyInstFieldMappingModel.fieldNameEn;
 this.dutyInstFieldMappingDto.fieldNameHi=this.dutyInstFieldMappingModel.fieldNameHi;
 this.dutyInstFieldMappingDto.fieldType=this.dutyInstFieldMappingModel.fieldType;
 this.dutyInstFieldMappingDto.parentId=this.dutyInstFieldMappingModel.parentId;
 this.dutyInstFieldMappingDto.instrumentId={id : this.instrumentId}

 DutyInstField_ELEMENT_DATA.push(this.dutyInstFieldMappingDto)
   console.log(DutyInstField_ELEMENT_DATA.length)
  for(let i=0;i<DutyInstField_ELEMENT_DATA.length;i++){
    DutyInstField_ELEMENT_DATA[i].sNo=i+1;
    this.dataSource = new MatTableDataSource<any>(DutyInstField_ELEMENT_DATA);
  }

this.dutyInstFieldMappingModel=new DutyInstFieldMappingModel();
   
}else{

for(let i=0;i<DutyInstField_ELEMENT_DATA.length;i++){
   
  this.dataSource = new MatTableDataSource<DutyInstFieldMappingModel>(DutyInstField_ELEMENT_DATA);
}
document.getElementById("addParty").innerHTML = "Add";

this.dutyInstFieldMappingModel=new DutyInstFieldMappingModel()
}
}


//for save record in database

saveDutyInstFieldMappingDetails(){
   
  console.log("Inside saveDutyInstFieldMappingDetails :",DutyInstField_ELEMENT_DATA)
  console.log("Id :",this.dutyInstFieldMappingDto.id)
  this.ngxService.start();
 //if(this.dutyInstFieldMappingDto.id==0){
  this.dcService.saveDutyInstFieldMappingDetails(DutyInstField_ELEMENT_DATA).subscribe(res => {
    if (res.responseStatus == 'true') {
      this.ngxService.stop(); 
    } else{
      this.ngxService.stop();
      //this.alertmessage.errorAlertMessage(res);
    }
      // console.log("this.amount==",this.amount);
   },
   (err) => {
   
     this.ngxService.stop();
     //this.alertmessage.errorAlertMessage('tech_error_msg');
   })
//}

}

resetDetails(){
  this.dutyInstFieldMappingModel=new DutyInstFieldMappingModel();
}

}

import { IgrsSubClausePropertyMappingBean } from "./IgrsSubClausePropertyMappingBean";
import { MstDemographyBean } from "./MstDemographyBean";
import { MstPropertyTypeBean } from "./MstPropertyTypeBean";
import { PropertyOwner } from "./PropertyOwner";
import { UOMEnum } from "./UOMEnum";
import { EregPropertyLinkModel, IgrsEregIvrsDetlsBean, IgrsEregLoanDetlsBean, IgrsEregMunicipalTaxesBean, IgrsEregPartyDetailsBean, IgrsEregPropAdditionalDetlsBean, IgrsEregPropChargeCasesBean, IgrsEregPropImgDisplayBean, IgrsEstampPartyPropMappingBean } from "./e_registry/e-registry.model";

export class WebGisResponseBeanData {
	
	id: number
	unit: UOMEnum
	propertyTypeId: any
	landParcelId: any
	ULPin: any
	surveyNo: any
	latitude: any
	longitude: any
	propId: string
	northboundary?: string
	southboundary?: string
	westboundary?: string
	eastboundary?: string
	otherDetails?: string;
	totalArea: any//total area
	workGroupId?: number
	//Mapped with landUseType with CLR response. Only for case Plot 
	buildingAreaType: any
	isAdjacentKhashra?: boolean
	isCoOwner?: boolean
	isOwnerSameFamily?: boolean
	//Area of Type Mapped based on landUseType for Residencial Property
	residentialPlotArea: any
	commercialPlotArea: any
	industrialPlotArea: any
	healthPlotArea: any
	eduPlotArea: any
	othersPlotArea: any
	residentialCumCommercialPlotArea: any
	landTypeName: any
	isIrrigated: any
	usage: any; //diverted,undiverted,both () ; //Diverted/ Undiverted/Both -  agricultural land  //divertated undivertated plot
	totalSellableAreaL: any
	totalSellableAreaUndiverted: any
	totalSellableAreaDiverted: any
	unIrrigatedArea: any;
	irrigatedArea: any;
	singleCropArea: any;
	doubleCropArea: any;
	partialTransactArea: any
	//public string landUseType :any;
	LandRevenue: any
	khasraType: any
	LandTpeId: any
	isHypothecation:any
	LandTypeName: any
	isMortgage: any
	isCourtCase:any
	buyersSameFamily: any
	remarks:string
	districtId:string
	tehsilId:String
	// if land is un-Undiverted Or Both ask for irregatated/ Un-irregatated/ single crop area /Double Crop Area
	moreBuyers: any
	isBuyersSameFamily: any
	noOfBuyers: any
	isConstOnAgriLand: any
	landUseType: any
	//propId:any
	propertyOwner?: PropertyOwner[] = []
	//property ,demography subClause,propertyType
	subClause: IgrsSubClausePropertyMappingBean[] = new Array();
	demography: MstDemographyBean = new MstDemographyBean();
	demographyArray: MstDemographyBean[] = new Array()
	propertyType: MstPropertyTypeBean = new MstPropertyTypeBean();

	igrsEregMunicipalTaxesBean: IgrsEregMunicipalTaxesBean;
	igrsEregLoanDetlsBean: IgrsEregLoanDetlsBean;
	igrsEregChargeAndCasesBean: IgrsEregPropChargeCasesBean;
	igrsEregIvrsDetlsBean: IgrsEregIvrsDetlsBean;
	igrsEregPropAdditionalDetlsBean = new IgrsEregPropAdditionalDetlsBean();
	igrsEregPropLinking: EregPropertyLinkModel[];
	igrsEregPropImgDisplayBean = new IgrsEregPropImgDisplayBean();

	propertyImageBase64:string
	partyBeans: IgrsEregPartyDetailsBean[] = [];
	partyBuyerBeans: IgrsEregPartyDetailsBean[] = [];
	partySellerBeans: IgrsEregPartyDetailsBean[] = [];
	partyConsenterBeans: IgrsEregPartyDetailsBean[] = [];
	LGDCode:any
	lgdcode:any
	partyBean: IgrsEregPartyDetailsBean = new IgrsEregPartyDetailsBean();
	expanded: boolean;
	makerRemarks:string;
	checkerRemarks:string;
	mortageRemarks:string
    addPartyWithoutCustodianData: boolean = false;
    mutationFees: number;
    ideStatus:number
	compartmentNo:any;
	isCheked:boolean
	isPartyForEdit:boolean;

}


 <div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2> 
                 {{
              lan == 0
                ? "Frequently asked questions (FAQs)"
                : "अक्सर पूछे जाने वाले प्रश्न"
            }} - {{'detailsPages.eRegis' | translate}}
                </h2>

                <!-- <button mat-button class="btn btn-primary" id="sticky_Div" >
                    {{'detailsPages.processForRegi' | translate}}
                </button> -->
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title> </mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-xl-12 col-sm-12">


<mat-accordion>
	<mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
		<mat-expansion-panel-header>
			    <mat-panel-title>
                                        <div *ngIf="lan == 0">What is e-registration ?</div>
                                        <div *ngIf="lan == 1">ई-रजिस्ट्रेशन क्या है?</div>
                                    </mat-panel-title>
		</mat-expansion-panel-header>
		<ng-template matExpansionPanelContent>
     <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            e-Registration is a process through which the documents
                                            related to movable or immovable properties are registered
                                            electronically, i.e. sale, gift, loan etc. In the
                                            e-Registration various stages/processes like deed
                                            drafting, execution or deed signing, duty payments,
                                            presentation, admission of execution etc. are performed
                                            electronically and online through the notified system. In
                                            Madhya Pradesh, the online system “SAMPADA” (Stamps and
                                            Management of Property and Documents Application) is
                                            notified for this purpose and running from year 2015. With
                                            the improved processes, version 2.0 of Sampada is launched
                                            in year 2024.
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        ई-पंजीकरण एक ऐसी प्रक्रिया है जिसके माध्यम से चल या अचल
                                        संपत्तियों से संबंधित दस्तावेजों को इलेक्ट्रॉनिक रूप से
                                        पंजीकृत किया जाता है, जैसे की बिक्री, उपहार, ऋण इत्यादि।
                                        ई-पंजीकरण में विभिन्न चरण / प्रक्रियाएं जैसे डीड ड्राफ्टिंग,
                                        निष्पादन या डीड पर हस्ताक्षर करना, शुल्क भुगतान, प्रस्तुति ,
                                        निष्पादन की स्वीकृति आदि अधिसूचित प्रणाली के माध्यम से
                                        इलेक्ट्रॉनिक और ऑनलाइन किए जाते हैं। मध्य प्रदेश में, ऑनलाइन
                                        प्रणाली "संपदा" (संपत्ति और दस्तावेज़ आवेदन के टिकट और
                                        प्रबंधन) इस उद्देश्य के लिए अधिसूचित है और वर्ष 2015 से चल
                                        रही है। बेहतर प्रक्रियाओं के साथ, संपदा का संस्करण 2.0 वर्ष
                                        2024 में लॉन्च किया गया है।
                                    </div>
                                </div>
		</ng-template>
  </mat-expansion-panel>
 
 
  <mat-expansion-panel  [expanded]="step === 1" (opened)="setStep(1)" >
  <mat-expansion-panel-header>
     <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            What changes / improvements introduced in e-Registration
                                            with Sampada 2.0
                                        </div>
                                        <div *ngIf="lan == 1">
                                            सम्पदा 2.0 के साथ ई-पंजीकरण में क्या परिवर्तन / सुधार लाए
                                            गए हैं?
                                        </div>
                                    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            With the version 2.0 of Sampada system following are key
                                            improvements introduced in the e-registration process –
                                        </p>
                                        <ol>
                                            <li>
                                                Property Identification - Integration with the custodian
                                                department for property ownership and associated
                                                information is done for the document registration where
                                                any immovable property (Agriculture land, plot,
                                                building, flat etc.) is involved. The system facilitates
                                                the user to initiate the registration process by
                                                selecting the property based on the custodian
                                                departments data. System fetches the parameters required
                                                for the valuation of the property automatically from the
                                                custodian department and based on the information /
                                                declaration of the user. Custodian departments for
                                                various type of properties are as below –

                                                <ul>
                                                    <li>
                                                        RoR (Khasra / Records of Rights) maintained by
                                                        Commissioner Land Records – Agriculture Land,
                                                        Residential plots those ownership given under
                                                        Swamitva Scheme
                                                    </li>
                                                    <li>
                                                        Property Tax Registers maintained by Urban Local
                                                        Bodies – All type of properties (Plots, Building,
                                                        Flats etc).
                                                    </li>
                                                    <li>
                                                        Property Tax Registers maintained by the Rural Local
                                                        Bodies /Gram Panchayats - All type of properties
                                                        (Plots, Building, Flats etc).
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                Property Geo-tagging – Property geo-tagging is made
                                                available using the Sampada Mobile App for the document
                                                registration where immovable property is involved.
                                            </li>
                                            <li>
                                                For the identification of parties & witnesses electronic
                                                mode of identification using various e-KYC are
                                                introduced.
                                            </li>
                                            <li>
                                                Deed execution (signing) is performed electronically
                                                either through the e-Sign or Digital Signature.
                                            </li>
                                            <li>
                                                Payment of the stamp duty & other fees are online
                                                through the modes available in Cyber Treasury of the
                                                State. A single click payment of all the government
                                                duties and fees are facilitated through the Wallet
                                                facility. Wallet facility is extended for citizens also.
                                            </li>
                                            <li>
                                                Faceless Registration – For certain documents decided by
                                                the competent authority, people may not require visiting
                                                to the sub-registrar office for document registration.
                                                Parties can appear in a virtual mode and present the
                                                document through Video KYC and video recordings of the
                                                declaration / oath. In the faceless registration
                                                following two modes are available -
                                                <ul>
                                                    <li>
                                                        Non-Assisted Mode – For certain type of the
                                                        instruments, the executants can prepare, submit,
                                                        admit, and present the documents for registration
                                                        electronically.
                                                    </li>
                                                    <li>
                                                        Assisted Mode – For certain type of the instruments,
                                                        the executants can prepare, submit, admit, and
                                                        present the documents for registration
                                                        electronically, Sub-Registrar will interact with the
                                                        parties through the customized video conferencing
                                                        solution.
                                                    </li>
                                                </ul>

                                                For documents where faceless registration is not
                                                provisioned then user can register the document by
                                                visiting the sub-registrar office on scheduled date &
                                                time fixed during the slot booking.
                                            </li>
                                            <li>
                                                Registration Completion & Delivery of Document – The
                                                delivery of the registered document is done
                                                electronically as a digitally singed soft copy through
                                                the e-mail/ Sampada system login. There is no hard copy
                                                of the document will be given. For any charge creation
                                                on the document (i.e. equitable mortgage/ mortgage etc.
                                                case) respective agencies/ banks can perform the same
                                                using the Sampada system only.
                                            </li>
                                            <li>
                                                Electronic process for impound, return & refuse are
                                                added in the registration completion stage. The case is
                                                automatically generated and electronically forwarded to
                                                Collector of Stamps / District Registrar for further
                                                action.
                                            </li>
                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            संपदा प्रणाली के संस्करण 2.0 के साथ ई-पंजीकरण प्रक्रिया
                                            में निम्नलिखित प्रमुख सुधार पेश किए गए हैं -
                                        </p>

                                        <ol>
                                            <li>
                                                संपत्ति की पहचान - दस्तावेज़ पंजीकरण के लिए संपत्ति के
                                                स्वामित्व और संबंधित जानकारी कस्टोडियन विभाग से लेने के
                                                लिए उसके साथ एकीकरण किया है जहां कोई अचल संपत्ति (कृषि
                                                भूमि, भूखंड, भवन, फ्लैट आदि) शामिल है। सिस्टम उपयोगकर्ता
                                                को कस्टोडियन विभाग के डेटा के आधार पर संपत्ति का चयन
                                                करके पंजीकरण प्रक्रिया शुरू करने की सुविधा प्रदान करता
                                                है। सिस्टम संपत्ति के मूल्यांकन के लिए आवश्यक पैरामीटर
                                                स्वचालित रूप से कस्टोडियन विभाग से और उपयोगकर्ता की
                                                जानकारी / घोषणा के आधार पर प्राप्त करता है। विभिन्न
                                                प्रकार की संपत्तियों के लिए कस्टोडियन विभाग इस प्रकार
                                                हैं -
                                                <ul>
                                                    <li>
                                                        RoR (खसरा / अधिकारों के रिकॉर्ड) आयुक्त भूमि अभिलेख
                                                        द्वारा बनाए रखा जाता है - कृषि भूमि, आवासीय भूखंड
                                                        जिनका स्वामित्व स्वामित्व योजना के तहत दिया गया है
                                                    </li>
                                                    <li>
                                                        शहरी स्थानीय निकायों द्वारा बनाए गए संपत्ति कर
                                                        रजिस्टर - सभी प्रकार की संपत्तियां (भूखंड, भवन,
                                                        फ्लैट आदि)।
                                                    </li>
                                                    <li>
                                                        ग्रामीण स्थानीय निकायों/ग्राम पंचायतों द्वारा बनाए
                                                        गए संपत्ति कर रजिस्टर - सभी प्रकार की संपत्तियां
                                                        (भूखंड, भवन, फ्लैट आदि)।
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                संपत्ति जियो-टैगिंग - जहां अचल संपत्ति शामिल है, वहां
                                                दस्तावेज़ पंजीकरण के लिए संपदा मोबाइल ऐप का उपयोग करके
                                                संपत्ति जियो-टैगिंग उपलब्ध कराई जाती है।
                                            </li>
                                            <li>
                                                पक्षकारो और गवाहों की पहचान के लिए विभिन्न ई-केवाईसी का
                                                उपयोग करके पहचान के इलेक्ट्रॉनिक तरीके पेश किए गए हैं।
                                            </li>
                                            <li>
                                                डीड निष्पादन (हस्ताक्षर) इलेक्ट्रॉनिक रूप से ई-साइन या
                                                डिजिटल हस्ताक्षर के
                                            </li>
                                            <li>
                                                स्टांप ड्यूटी और अन्य शुल्क का भुगतान राज्य के साइबर
                                                ट्रेजरी में उपलब्ध तरीकों के माध्यम से ऑनलाइन है। वॉलेट
                                                सुविधा के माध्यम से सभी सरकारी कर्तव्यों और शुल्कों का
                                                एक क्लिक से भुगतान की सुविधा मिलती है। वॉलेट सुविधा
                                                नागरिकों के लिए भी विस्तारित की गई है।
                                            </li>
                                            <li>
                                                फेसलेस पंजीकरण - सक्षम प्राधिकारी द्वारा तय किए गए कुछ
                                                दस्तावेजों के लिए, लोगों को दस्तावेज़ पंजीकरण के लिए
                                                उप-पंजीयक कार्यालय में जाने की आवश्यकता नहीं हो सकती है।
                                                पक्षकार वर्चुअल मोड में उपस्थित हो सकते हैं और वीडियो
                                                केवाईसी और घोषणा / शपथ की वीडियो रिकॉर्डिंग के माध्यम से
                                                दस्तावेज़ प्रस्तुत कर सकते हैं। फेसलेस पंजीकरण में
                                                निम्नलिखित दो मोड उपलब्ध हैं -
                                                <ul>
                                                    <li>
                                                        नॉन असिस्टेड - कुछ प्रकार के उपकरणों के लिए,
                                                        निष्पादक इलेक्ट्रॉनिक रूप से पंजीकरण के लिए
                                                        दस्तावेज़ तैयार कर सकते हैं, जमा कर सकते हैं,
                                                        स्वीकार कर सकते हैं और प्रस्तुत कर सकते हैं।
                                                    </li>
                                                    <li>
                                                        असिस्टेड मोड - कुछ प्रकार के उपकरणों के लिए,
                                                        निष्पादक इलेक्ट्रॉनिक रूप से पंजीकरण के लिए
                                                        दस्तावेज़ तैयार कर सकते हैं, जमा कर सकते हैं,
                                                        स्वीकार कर सकते हैं और प्रस्तुत कर सकते हैं,
                                                        उप-रजिस्ट्रार अनुकूलित वीडियो कॉन्फ्रेंसिंग समाधान
                                                        के माध्यम से पक्षकारो के साथ बातचीत करेगा।
                                                    </li>
                                                </ul>
                                                उन दस्तावेजों के लिए जहां फेसलेस पंजीकरण का प्रावधान
                                                नहीं है, उपयोगकर्ता स्लॉट बुकिंग के दौरान निर्धारित तिथि
                                                और समय पर उप-पंजीयक कार्यालय में जाकर दस्तावेज़ को
                                                पंजीकृत कर सकते हैं।
                                            </li>
                                            <li>
                                                पंजीकरण समापन और दस्तावेज़ की डिलीवरी - पंजीकृत
                                                दस्तावेज़ की डिलीवरी इलेक्ट्रॉनिक रूप से ई-मेल / सम्पदा
                                                सिस्टम लॉगिन के माध्यम से डिजिटल रूप से सॉफ्ट कॉपी के
                                                रूप में की जाएगी। दस्तावेज़ की कोई हार्ड कॉपी नहीं दी
                                                जाएगी। दस्तावेज़ पर किसी भी शुल्क सृजन के लिए (अर्थात्
                                                साम्यिक बंधक/बंधक आदि मामले) संबंधित एजेंसियां/बैंक केवल
                                                सम्पदा प्रणाली का उपयोग करके ऐसा कर सकते हैं।
                                            </li>
                                            <li>
                                                पंजीकरण पूरा होने के चरण में ज़ब्त, रिटर्न और रिफ्यूज के
                                                लिए इलेक्ट्रॉनिक प्रक्रिया जोड़ी जाती है। मामला स्वचालित
                                                रूप से उत्पन्न होता है और इलेक्ट्रॉनिक रूप से आगे की
                                                कार्रवाई के लिए स्टांप कलेक्टर/जिला रजिस्ट्रार को भेज
                                                दिया जाता है।
                                            </li>
                                        </ol>
                                    </div>
                                </div>    
  </ng-template>
  </mat-expansion-panel>
  
  
</mat-accordion>

 
                    </div>
                  
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
import { Component, OnInit, ViewChild, ViewEncapsulation,Inject } from '@angular/core';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
 
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { Demography } from 'projects/common-lib/src/lib/models/demography.list.model/demography';
import { error } from 'console';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';
import { DefaultServiceService } from '../../../default/default-service.service';

@Component({
  selector: 'app-sr-sp-locations',
  templateUrl: './sr-sp-locations.component.html',
  styleUrls: ['./sr-sp-locations.component.scss']
})
export class SrSpLocationsComponent implements OnInit {
 private settings!: AppSetting;

  active = 1;
  images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  districtId:number=null;
  districts:Array<Demography>=[];
  map:L.Map;
  markers:Array<L.Marker>=[];
  selectOffice:string="sr";
  redIcon:L.Icon;
  popup:any = L.popup({offset: [1, -35]});
  popupConfig:any=[
    {"name":"contactNo", "lable_hi":"संपर्क नंबर","lable_en":"Contact No"},
    {"name":"officeName", "lable_hi":"कार्यालय का पता","lable_en":"Office Address", "data_en":"officeNameEn","data_hi":"officeNameHi"}
  ];
  public lan:number=0;

   constructor(private defaultServiceService:DefaultServiceService, private router: Router,private translateService: TranslateHEService,private appSettingsService: AppSettingService,
    @Inject(DOCUMENT) private _document: Document, private common: CommonService, private translate:TranslateService) { 
      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
      });
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg;
        this.translate.use(msg==1?'hi':'en')
       });
      this.getDistricts();
  }

  ngOnInit(): void {

    this.defaultServiceService.setDefaultType('home');
    //sessionStorage.setItem("selectedLanguage","hi")
    if(sessionStorage.getItem("token")!=null || sessionStorage.getItem("token")!="")
    {
      //this.authService.logout();
    }
    this.redIcon = L.icon({
      iconUrl: './assets/images/marker-icon-red.png',
      shadowUrl: './assets/images/marker-shadow.png',
    });
   
  }


 getDistricts() {
    //this.ngxService.start();
    this.common.getDistricts().subscribe(res => {
      //this.ngxService.stop();
      if (res.responseStatus) {
        this.districts = res.responseData;
        //
        //this.successMsg=res.responseMessage;
        //Swal.fire("Success", res.responseMessage, "success")
      } else {
        //this.errorMsg=res.responseMessage;
        //Swal.fire("Error", res.responseMessage, "error")
      }
    });

  }








onLoadMap(event:any){
    //console.log(event);
    this.map=event;
  }

  get_offices_map(){
    this.removemarkers();
    if(this.districtId != null){
      let url="";
      if(this.selectOffice == "sr"){
        url="common/getSROfficeGeoLoc/"
      }else if(this.selectOffice == "sp"){
        url="common/getSPGeoLoc/"
      }else{

      }
      if(url !=""){
        this.common.getOfficeLocationDistricWise(url, this.districtId.toString()).subscribe((res:any)=>{
          //console.log(res);
          this.setMarker(res.responseData)
        }, (error:any)=>{
          
        })
      }
    }
  }

  setMarker(locations:Array<any>){
    let properties:any;
    locations.forEach((location:any, i:number)=>{
      if(!Number.isNaN(Number(location.latitude))  && !Number.isNaN(Number(location.longitude))){
        let latlng:any = [Number(location.latitude),Number(location.longitude)]
        let marker =   L.marker(latlng,{
          zIndexOffset: 0,
          draggable:false,
          icon:this.redIcon,
          data:locations[i]
        }).addTo(this.map);
        marker.on('mouseover', (e)=>{
          //console.log(e);
          properties=this.setPopupContent(e.target.options.data);
          this.popup.setLatLng(e.latlng).setContent(properties).openOn(this.map);
          this.popup._closeButton.removeAttribute("href");
        });
        marker.on('mouseout', ()=> { this.map.closePopup(); });
        this.markers.push(marker);
      }
    });
    if(this.markers.length>0){
      var bounds:any = L.latLngBounds(this.markers.map(function(marker) {
        return marker.getLatLng();
      }));
      this.map.fitBounds(bounds,{ padding: [30, 30] });
    }
  }
   setPopupContent(feature:any):any{
    let html=`<table class="table table-striped table-bordered">
                <tbody>`;
    this.popupConfig.forEach((item:any)=>{
      if(feature[item.name] != undefined){
        if(this.lan==0){
          let keyname = (item.data_en)?item.data_en:item.name;
          html=html+`<tr> <td>${item.lable_en}</td>   <td>${feature[keyname]}</td>   </tr>`;
        }else{
          let keyname = (item.data_en)?item.data_hi:item.name;
          html=html+`<tr> <td>${item.lable_hi}</td>   <td>${feature[keyname]}</td>   </tr>`;
        }
      }
    });
    html=html+` </tbody></table>`;
    return html;
  }

  removemarkers(){
    if(this.markers.length>0){
      this.markers.forEach((marker:L.Marker)=>{
        this.map.removeLayer(marker);
      });
      this.markers=[];
    }
  }
}

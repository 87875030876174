<h2 class="Consenterdialog-title" mat-dialog-title>{{lan==0?this.popupNameEn:this.popupNameHi}}</h2>

<div mat-dialog-action>
    <a class="colsebtn" (click)="closeMe()">
        <!-- {{'duty-calculation.close' | translate}} -->
        <em class="fa fa-times"></em>
    </a>
</div>

<div mat-dialog-content class="mt-10"  [ngClass]="{'other-detail-disable' : readOnlyMode}">


    <form #dutyOtherform="ngForm" (ngSubmit)="(validationEnabled==true?dutyOtherform.form.valid:false) && submitOtherForm(dutyOtherform)">


        <!-- <legend>{{lan==0?filterInstMappingList[0].popupNameEn:filterInstMappingList[0].popupNameHi}}</legend> -->
        <!-- <div *ngFor="let fieldArry of fieldArray; let f = index"> -->
        <table class="table table-bordered table-sm">
          <thead *ngIf="fieldArray?.length>0"  class="thead-dark">
            <tr>
                <th>
                    Sr.no
                </th>
                <ng-container *ngFor="let pMapping of filterInstMappingListDisplay">
                <th *ngFor="let field of pMapping.parentMappingList | orderBy: 'id';">
          
                          {{lan==0?field.fieldNameEn:field.fieldNameHi}} 
                </th>
                </ng-container>
                <th>
            Action
                </th>
               </tr>
          </thead>
           
           
           <tbody>
            <tr *ngFor="let fieldArry of fieldArray; let f = index">
                <td>{{f+1}}</td>
                  <td *ngFor="let fieldObj of fieldArry | keyvalue | orderBy: 'key';">

                        <div *ngFor="let pMapping of filterInstMappingListDisplay">
                        
                            <ng-container *ngFor="let field of pMapping.parentMappingList | orderBy: 'id';">
                                <ng-container *ngIf="field.id == fieldObj.key && field.status">
                                     {{fieldObj.value}}
                                    <!-- <ng-container *ngIf="field.fieldType==='TEXT' ">
                                        <div class="form-group m-0">
                                      
                                            <input type="text" maxlength="12" readonly class="form-control"
                                                id="{{fieldObj.key}}" value="{{fieldObj.value}}"
                                                placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                                class="form-control form-control-sm mt-0">
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="field.fieldType==='NUMBER' ">
                                        <div class="form-group m-0">
                                            
                                            <input type="number" maxlength="12" readonly class="form-control"
                                                id="{{fieldObj.key}}" value="{{fieldObj.value}}" 
                                                [max]="field.maximum" placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                                class="form-control form-control-sm mt-0">
                                        </div>
                                    </ng-container> -->


                                </ng-container>
                            
                            </ng-container>
                        </div>
                    </td>
         <td>
            <button class="btn btn-danger" type="button" (click)="deleteFieldValue(f)">{{'duty-calculation.delete' |
                translate}}</button>
            </td>
        </tr>
           </tbody>
           
         
        </table>
        <!-- </div> -->


        <fieldset class="sidebar__widget-title mt-2">
            <div class="row mb-2" *ngFor="let pMapping of filterInstMappingList ; index as m">
                <ng-container *ngFor="let field of pMapping.parentMappingList | orderBy: 'orderId'; index as i">
                    <ng-container *ngIf="field.parentId != null && field.status">
                        <div class="col-md-6"> 
                            <div class="form-group"> 
                        <ng-container *ngIf="field.fieldType==='TEXT' ">
                                <label><strong>{{lan==0?field.fieldNameEn:field.fieldNameHi}}</strong></label>
                                <mat-slider thumbLabel tickInterval="1000" step="1000" min="0" max="100000"
                                    aria-label="units" #gridsize (change)="updateSetting($event)" step="5" min="10"
                                    max="100"></mat-slider>
                                {{gridsize.value}}
                                <input type="text" maxlength="12" required [appCopyPastValidation]="true"
                                    class="form-control" id="{{field.id}}" [(ngModel)]="inputOtherFields[field.id]"
                                    #inputOtherFields[field.id]="ngModel" name="F_{{field.id}}"
                                    placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                    class="form-control form-control-sm ">                      
                        </ng-container>

                        <ng-container *ngIf="field.fieldType==='NUMBER' ">                                                
                                <label><strong>{{lan==0?field.fieldNameEn:field.fieldNameHi}}</strong></label>
                                <input type="number" [readonly]="field.readonly" [OnlyNumber]="true" 
                                    [appCopyPastValidation]="true" (keypress)="validateNumber($event,field)" step="1"
                                    maxlength="10" required class="form-control" id="{{field.id}}"
                                    [(ngModel)]="inputOtherFields[field.id]" #inputOtherFields[field.id]="ngModel"
                                    name="F_{{field.id}}" placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                     [max]="field.maximum" class="form-control form-control-sm ">
                        </ng-container>
                    
                        <div *ngIf="((dutyOtherform.submitted||addFlag) && validationEnabled) && !dutyOtherform.form.valid"
                            class="requiredColor"> {{lan==0?field.validationMsgEn:field.validationMsgHi}}</div>
                        <div class="requiredColor"
                            *ngIf="((dutyOtherform.submitted||addFlag) && validationEnabled) && !inputOtherFields[field.id]">
                            {{lan==0?field.fieldNameEn:field.fieldNameHi}} {{'duty-calculation.is_required' |
                            translate}}</div>
                        <small>
                            {{inputOtherFields[field.id] | AmtToWords}}
                        </small>
                    </div>
                        </div>

                        <!-- <div class="requiredColor" *ngIf=" && !inputOtherFields[field.id]"> {{lan==0?field.fieldNameEn:field.fieldNameHi}} is required</div> -->
                    </ng-container>
                </ng-container>
                <div class="col-md-12" *ngIf="!readOnlyMode">
                    <button *ngIf="pMapping.otherCalFlag===DutyOtherFlagEnum.MORE" class="btn btn-primary" type="button"
                    (click)="addFieldValue()">{{'duty-calculation.add' | translate}}</button>
                </div>
                  </div>

        </fieldset>
        <div class="text-right mt-2" *ngIf="!readOnlyMode">
            <button type="submit" mat-raised-button class="btn btn-info mr-1">{{'duty-calculation.Submit' |
                translate}}</button>
            <button mat-flat-button class="btn btn-danger" type="button" (click)="resetDutyOtherFields()"> {{
                'duty-calculation.Reset' | translate }}</button>
           </div>


    </form>

</div>
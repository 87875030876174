export class DeedCategoryBean {


	 id:number;
	 deedCategoryId:number;
	 deedCategoryNameEn:string;
	 deedCategoryNameHi:string;
	 deedCategoryDescEn:string;
	 deedCategoryDescHi:string;
	 status:boolean;
	 createdBy:number;
	 createdDate:Date;
	 modifiedBy:number;
	 modifiedDate:Date;

	 

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SrOfficeDisplayService } from '../sr-office-display/sr-office-display.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
@Component({
  selector: 'app-encrypt-eregid',
  templateUrl: './encrypt-eregid.component.html',
  styleUrls: ['./encrypt-eregid.component.scss']
})
export class EncryptEregidComponent implements OnInit {

  eregNo:string
  encryptedEregId:string;

  constructor(private srOfficeDisplayService:SrOfficeDisplayService,
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      if (typeof params['eregNo'] !== 'undefined') {
        this.eregNo = params['eregNo'];

      } else {
        alert("Something wrong.");
        this.eregNo = "0";
      }
    })

    this.srOfficeDisplayService.encryptEregId(this.eregNo).subscribe(res => {

      this.ngxService.stop();
      if (res.responseStatus == 'true') {
        let eregId = res.responseData;
        this.encryptedEregId = this._AESEncryptDecryptService.encrypt(eregId);
      }
    });
  }

}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { UserProfileModel } from 'projects/common-lib/src/lib/models/auth.userdetail.model/user.profile.model';
import { UserManagementServive } from '../../service/UserManagementServive';
import { AadharEkycOTPAuthResponse } from 'projects/common-lib/src/lib/models/aadharEkyc.model/AadharEkyc.model';

@Component({
  selector: 'app-login-kyc',
  templateUrl: './login-kyc.component.html',
  styleUrls: ['./login-kyc.component.scss']
})
export class LoginKycComponent implements OnInit {

  userProfileModel: UserProfileModel;
  //kycSource: string;
  //kycObject: any;

  @Output() verifyAdharOtpOut1 = new EventEmitter<AadharEkycOTPAuthResponse>();
  @Output() verifyPanOut1 = new EventEmitter<any>();

  @Input() kycSource: string;
  @Input() kycObject: any;

  aadharEkycOTPAuthResponse: AadharEkycOTPAuthResponse;

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;

  constructor(private userService: UserManagementServive, private router: Router, private _AESEncryptDecryptService: AESEncryptDecryptService) { }

  ngOnInit(): void {
    this.userProfileModel = JSON.parse(sessionStorage.getItem('authData') || "");
  }

  verifyAdharOtpOut(event: any) {
    // 
    this.kycObject = event;
    this.kycSource = 'Aadhaar';
    if (event != null && event.ret == 1) {
      // sessionStorage.setItem('isUserLoggedIn','true');
      //update status of email verify 
      let uid = this.userProfileModel.id;
      let userid = this._AESEncryptDecryptService.encrypt("" + uid);
      let uidToken = this._AESEncryptDecryptService.encrypt("" + event.residentDetails.uidToken);

      const param = {
        "kyc_unique_token": this.kycObject.residentDetails.uidToken
      }

      this.userService.checkAadhaarAlreadyExist(param).subscribe(res => {
        //
        if (res && res.responseStatus == 'true') {
          let param = { user_id: userid, status: "true", kycTxnLogNo: event.aadharTxnLogNo, kycType: "Aadhaar", kycUniqueToken: uidToken };
          this.userService.verifyFirstTimeKyc(param).subscribe(data => {
            //  
            if (data.responseStatus == 'true') {
              this.userProfileModel.emailVerifyStr = 'true';
              sessionStorage.setItem("authData", JSON.stringify(this.userProfileModel));

              this.aadharEkycOTPAuthResponse = res.responseData;
              setTimeout(() => { this.verifyAdharOtpOut1.emit(this.aadharEkycOTPAuthResponse); }, 1000);

              this.router.navigate(['/admin/dashboard']);
            } else {
              this.alertmessage.errorAlertMessage(data);
            }
          })
          this.alertmessage.successAlertMessage(res);
        } else {
          this.alertmessage.errorAlertMessage(res);
        }
      });




    }

  }

  verifyPanOut(event: any) {
    // 
    this.kycObject = event;
    this.kycSource = 'Pan';
  }

}

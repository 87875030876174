


export class EstampDeedContent {


  id:number;
  pageNumber:number;
  deedContent:string;
  estampId:number;
  deedId:number;
  contentStatus:number;
  createdBy:number;
  createdDate:Date;
  modifiedBy:number;
  modifiedDate:Date;
  deedRemarks:string;
  deedTemplateId:number;
  eregFlag:number;
  deedFileName:string;
  month:string;
  year:string;
  isFinalSubmit:boolean;
}

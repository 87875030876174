<lib-alertmessage #alertMessage></lib-alertmessage>
<div class="banner-header" *ngIf="isDataFound">
    <div class="container">
        <div class="breadcrumb-content">
            <div class="row">
                <div class="col-lg-12 d-flex justify-content-between">
                    <h2>
                        {{'estampDetails.eregQRCV' | translate}}
                    </h2>
                    <button (click)="onBtnPrintClick()" target="_blank"
                        class="btn btn-primary d-print-none mr-2 mt-1 ml-1">
                        {{'print' | translate}} / {{'admin-profile-page-data.center-serviceprovider-details.download' |
                        translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="qrCodeSection" *ngIf="isDataFound">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <mat-card class="example-card">
                    <mat-card-title></mat-card-title>
                    <mat-card-content class="mat-card-content">

                        <div class="modal-box" id="print-card">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{"Search-Document-Certified.reg_details" | translate}}
                                </legend>
                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>
                                                        {{'citizen-profile-details.citizen-juristic-service-details.registration-number'|
                                                        translate }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{igrsEregTxnDetlsBean?.regId}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>{{'Search-Document-Certified.registration_date'| translate
                                                        }}:</label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>
                                                        {{igrsEregTxnDetlsBean?.eregCompletionDate | date: 'dd-MM-yyyy hh:mm:ss a'}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-6" *ngIf="igrsEregTxnDetlsBean?.dutyDetailsWithConsGlDataDto?.glValue">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label> {{'QrcodeViewEregi.GLuValPro'| translate }} </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{igrsEregTxnDetlsBean?.dutyDetailsWithConsGlDataDto?.glValue| amount}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>{{'QrcodeViewEregi.ConsiderationAmountINR'| translate }}
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{consAmount| amount}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>{{'QrcodeViewEregi.totalregFee'| translate }} </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.totalPayableRegfee| amount}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>{{'QrcodeViewEregi.totalStamDuty'| translate }}</label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.totalStamp| amount}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'e-stamp.Deed-Details' | translate}}
                                </legend>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="table-responsive shadow-none">
                                            <table class="table table-bordered">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th>{{'e-stamp.Deed-Type'| translate }}</th>
                                                        <th>{{'duty-calculation.inst_desc'| translate }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 15rem;">{{lan == 0 ?
                                                            igrsEregTxnDetlsBean?.deedInstrumentSelected?.deedTypeId?.deedTypeNameEn:igrsEregTxnDetlsBean?.deedInstrumentSelected?.deedTypeId?.deedTypeNameHi}}
                                                        </td>
                                                        <td>
                                                            {{lan == 0 ?
                                                            igrsEregTxnDetlsBean?.deedInstrumentSelected?.instrumentNameEn:igrsEregTxnDetlsBean?.deedInstrumentSelected?.instrumentNameHi}}
                                                            ({{lan==0?
                                                            igrsEregTxnDetlsBean?.deedInstrumentSelected?.instrumentDescEn:igrsEregTxnDetlsBean?.deedInstrumentSelected?.instrumentDescHi}})
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                            <fieldset class="legend-section">

                                <legend class="sidebar__widget-title">


                                    {{'QrcodeViewEregi.estampDetails'| translate }}
                                </legend>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>{{'QrcodeViewEregi.estampCode'| translate }}</label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{igrsEregTxnDetlsBean?.igrsEregEstampBean?.estampCode}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>{{'QrcodeViewEregi.totalEstamAmount'| translate }}</label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div>
                                                        <span>{{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.totalStamp| amount}}</span>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-lg-1">

                                            </div>
                                            <div class="col-lg-11">

                                                <div class="mt-2 mb-3">
                                                    <table style=" width: 90%;">

                                                        <tr>
                                                            <th>
                                                                {{'duty-calculation.Govt_Stamp_Duty'| translate }}
                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.principalStampDuty| amount}}
                                                            </td>

                                                            <th>{{'duty-calculation.Municipal_Duty'|
                                                                translate }}
                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.municipalDuty| amount}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>{{'duty-calculation.Janpad_Duty'|
                                                                translate }}
                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.janpadDuty| amount}}
                                                            </td>

                                                            <th>{{'duty-calculation.Upkar_Duty'|
                                                                translate }}
                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.upkarDuty| amount}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>{{'e-stamp.Exempted-Amount'|
                                                                translate }}

                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.exempStamp| amount}}
                                                            </td>
                                                            <th>{{'QrcodeViewEregi.alreadyPDA'|
                                                                translate }} </th>
                                                            <td style="width: 25%;"> {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.totalStampDutyPaid| amount}}</td>

                                                        </tr>
                                                    </table>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>
                                                        {{'QrcodeViewEregi.estampType'| translate }}:
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{lan == 0 ?
                                                        igrsEregTxnDetlsBean?.igrsEregEstampBean?.mstEstampTypeBean?.estampNameEn:igrsEregTxnDetlsBean?.igrsEregEstampBean?.mstEstampTypeBean?.estampNameHi}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>
                                                        {{'QrcodeViewEregi.estampIssueDate'| translate }}:
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{igrsEregTxnDetlsBean?.igrsEregEstampBean?.createdDate |
                                                        date: 'dd-MM-yyyy hh:mm:ss a'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>
                                                        {{'QrcodeViewEregi.IssuedBy'| translate }}:
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span>{{lan==0? issueByEn:issueByHi}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-5">
                                                    <label>
                                                        {{'QrcodeViewEregi.typeofIssuer'| translate }}:
                                                    </label>
                                                </div>
                                                <div class="col-lg-7">
                                                    <span> &nbsp; {{lan==0? typeOfUser:typeOfUserHi}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-2">
                                                    <label>
                                                        {{'QrcodeViewEregi.issueLocation'| translate }}:
                                                    </label>
                                                </div>
                                                <div class="col-lg-9">
                                                    <span class="addmrgin">{{lan==0? IssueLocation:IssueLocationHi}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </fieldset>
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'QrcodeViewEregi.regFeeDetali' | translate}}
                                </legend>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-lg-3">
                                                    <label>{{'QrcodeViewEregi.totalRegFeeDetali'| translate }}</label>
                                                </div>
                                                <div class="col-lg-9">
                                                    <span>{{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.totalPayableRegfee| amount}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-lg-1">
                                            </div>
                                            <div class="col-lg-11">
                                                <div class="mt-2 mb-3">
                                                    <table style="width: 90%;">
                                                        <tr>
                                                            <th>{{'QrcodeViewEregi.RegistrationFee'|
                                                                translate }}
                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.totalReg| amount}}
                                                            </td>
                                                            <th>{{'e-stamp.Exempted-Amount'|
                                                                translate }}
                                                            </th>
                                                            <td style="width: 25%;">
                                                                {{igrsEregTxnDetlsBean?.igrsDutyCalculationTxnDto?.exempStamp| amount}}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                {{'QrcodeViewEregi.alreadyPaidFeeAm'| translate }}
                                                            </th>
                                                            <td style="width: 25%;"> 0 </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'QrcodeViewEregi.tranPartiesDet' | translate}}
                                </legend>
                                <div class="innner-box">
                                    <div class="row">
                                        <div [ngClass]="secondPartyAval? 'col-md-6' : 'col-md-12'">
                                            <fieldset class="legend-section">
                                                <legend class="sidebar__widget-title">
                                                    {{lan==0? firstPartyModel?.partyTypeNameEn:firstPartyModel?.partyTypeNameHi}}
                                                </legend>


                                                <div class="col-md-12">
                                                    <div class="table-responsive shadow-none">
                                                        <table class="table table-bordered">
                                                            <thead class="thead-dark">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{{'QrcodeViewEregi.Name'| translate }}</th>
                                                                    <th>{{'QrcodeViewEregi.GuardianName'| translate }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngFor="let party of firstPartyList;let m=index">
                                                                <tr *ngIf="party?.partySubTypeId==1">
                                                                    <td>{{m+1}}</td>
                                                                    <td *ngIf="party?.poaStatus==1">{{lan==0?party?.partyName:party?.partyNameHindi}} ({{lan==0?'POA':'पीओए'}})</td>
                                                                    <td *ngIf="party?.isMinor==1">{{lan==0?party?.partyName:party?.partyNameHindi}}  ({{lan==0?'Guardian':'अभिभावक'}})</td>
                                                                    <td *ngIf="party?.isMinor==0 && party?.poaStatus==0">{{lan==0?party?.partyName:party?.partyNameHindi}} </td>
                                                                    <td>
                                                                        {{lan==0? party?.fatherHusbandGardianNameEn:
                                                                        party?.fatherHusbandGardianNameHi}}
                                                                    </td>
                                                                </tr>
                                                                
                                                                <!--*ngIf="partyBean.transactingThroughPOA || partyBean.isMinor"-->

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                            </fieldset>
                                        </div>
                                        <div class="col-md-6" *ngIf="secondPartyAval">
                                            <fieldset class="legend-section">
                                                <legend class="sidebar__widget-title">
                                                    {{lan==0?
                                                    secondPartyModel?.partyTypeNameEn:secondPartyModel?.partyTypeNameHi}}
                                                </legend>
                                                <div class="col-md-12">
                                                    <div class="table-responsive  shadow-none">
                                                        <table class="table table-bordered">
                                                            <thead class="thead-dark">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{{'QrcodeViewEregi.Name'| translate }}</th>
                                                                    <th>{{'QrcodeViewEregi.GuardianName'| translate }}</th>                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody
                                                                *ngFor="let party of secondPartyList;let l=index">
                                                                <tr *ngIf="party?.partySubTypeId==2">
                                                                    <td>{{l+1}}</td>
                                                                    <td *ngIf="party?.poaStatus==1">{{lan==0?party?.partyName:party?.partyNameHindi}} ({{lan==0?'POA':'पीओए'}})</td>
                                                                    <td *ngIf="party?.isMinor==1">{{lan==0?party?.partyName:party?.partyNameHindi}}  ({{lan==0?'Guardian':'अभिभावक'}})</td>
                                                                    <td *ngIf="party?.isMinor==0 && party?.poaStatus==0">{{lan==0?party?.partyName:party?.partyNameHindi}} </td>
                                                                    <td>
                                                                        {{lan==0? party?.fatherHusbandGardianNameEn:
                                                                        party?.fatherHusbandGardianNameHi}}
                                                                    </td>
                                                                    
                                                                    
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="legend-section"
                                *ngIf="igrsEregTxnDetlsBean?.igrsEregPropIdentificationBean!=undefined">
                                <legend class="sidebar__widget-title">
                                    {{'property-valuation.Property-Details' | translate}}
                                </legend>
                                <div class="row">

                                    <div class="col-md-12">


                                        <div class="table-responsive shadow-none">
                                            <table class="table table-bordered">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{{'property-valuation.District'| translate }}</th>
                                                        <th>{{'property-valuation.Tehsil'| translate }}</th>
                                                        <th>{{'Search-Document-Certified.typeOfArea'| translate }}</th>
                                                        <th>{{'Search-Document-Certified.propertyType'| translate }}
                                                        </th>
                                                        <th>{{'Search-Document-Certified.address'| translate }}</th>
                                                        <th>{{'ereg-property-details.khasraNo'| translate }} /
                                                            {{'DecisionOnDocument.PropertyId'| translate }} </th>
                                                        <th>{{'property-valuation.Total-Area1'| translate }} </th>
                                                        <th>{{'ereg-property-details.transactingArea'| translate }}
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let row of igrsEregTxnDetlsBean.igrsEregPropIdentificationBean; let i=index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{lan ==0?
                                                            row?.propertyValuationRequestBean?.demography?.location?.district?.demographyNameEn:row?.propertyValuationRequestBean?.demography?.location?.district?.demographyNameHi}}
                                                        </td>


                                                        <td *ngIf="row?.propTypeId=='3'">{{lan ==0?
                                                            row?.propertyValuationRequestBean?.demography?.location?.tehsil?.demographyNameEn:row?.propertyValuationRequestBean?.demography?.location?.tehsil?.demographyNameHi}}
                                                        </td>
                                                        <td *ngIf="row?.propTypeId=='3'">
                                                            {{parseAreaType(row?.propertyValuationRequestBean?.webGisResponseBeanDataArr[0]?.demography?.demographyType?.demographyAreaType)}}
                                                        </td>


                                                        <td *ngIf="row?.propTypeId=='1' || row?.propTypeId=='2'">{{lan
                                                            ==0?
                                                            row?.propertyValuationRequestBean?.demography?.location?.localBody?.demographyNameEn:row?.propertyValuationRequestBean?.demography?.location?.localBody?.demographyNameHi}}
                                                        </td>
                                                        <td *ngIf="row?.propTypeId=='1' || row?.propTypeId=='2'">
                                                            {{parseAreaType(row?.propertyValuationRequestBean?.demography?.demographyType?.demographyAreaType)}}
                                                        </td>

                                                        <td>{{lan==0?
                                                            row.propertyValuationRequestBean?.propertyType?.nameEn:row?.propertyValuationRequestBean?.propertyType?.nameHi}}
                                                        </td>
                                                        <td>{{row?.propertyValuationRequestBean?.address}}</td>
                                                        <td *ngIf="row?.propTypeId=='3'">
                                                            {{row?.propertyValuationRequestBean.webGisResponseBeanDataArr[0]?.surveyNo}}
                                                            ||
                                                            {{row?.propertyValuationRequestBean.webGisResponseBeanDataArr[0]?.landParcelId}}
                                                        </td>
                                                        <td *ngIf="row?.propTypeId=='1' || row?.propTypeId=='2'">
                                                            {{row?.propertyValuationRequestBean?.houseFlatNo}}</td>
                                                        <td>{{row?.propertyValuationRequestBean?.totalArea}}</td>
                                                        <td>{{row?.propertyValuationRequestBean?.partialTransactArea}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>


                                </div>
                            </fieldset>



                            <fieldset class="legend-section"
                                *ngIf="igrsEregTxnDetlsBean?.igrsEregPropIdentificationBean!=undefined">
                                <legend class="sidebar__widget-title">
                                    {{'QrcodeViewEregi.tranShareDetails' | translate}}
                                </legend>
                                <div class="innner-box">



                                    <fieldset class="legend-section"
                                        *ngFor="let row of igrsEregTxnDetlsBean?.igrsEregPropIdentificationBean; let i=index">
                                        <legend class="sidebar__widget-title">
                                            <span *ngIf="row?.propTypeId=='1' || row?.propTypeId=='2'">
                                                {{lan ==0? row?.propertyValuationRequestBean?.propertyType?.nameEn:
                                                row?.propertyValuationRequestBean?.propertyType?.nameHi}} ||
                                                {{'QrcodeViewEregi.qrcode-house-flat' | translate}} : {{row?.propertyValuationRequestBean?.houseFlatNo}}

                                            </span>
                                            <span *ngIf="row?.propTypeId=='3'">
                                                {{lan ==0? row?.propertyValuationRequestBean?.propertyType?.nameEn:
                                                row?.propertyValuationRequestBean?.propertyType?.nameHi}} || {{'QrcodeViewEregi.qrcode-khasra' | translate}} :
                                                {{row?.propertyValuationRequestBean.webGisResponseBeanDataArr[0]?.surveyNo}}
                                            </span>

                                        </legend>


                                        <div class="row">
                                            <div class="col-md-6" [ngClass]="secondPartyAval? 'col-md-6' : 'col-md-12'">
                                                <fieldset class="legend-section h-100">
                                                    <legend class="sidebar__widget-title">
                                                        {{lan==0?
                                                        firstPartyModel?.partyTypeNameEn:firstPartyModel?.partyTypeNameHi}}
                                                    </legend>
                                                    <div class="col-md-12">
                                                        <div class="table-responsive shadow-none">
                                                            <table class="table table-bordered" *ngIf="row?.propTypeId==3">                                                         
                                                               
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>{{'QrcodeViewEregi.Name'| translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.GuardianName'| translate
                                                                            }}</th>
                                                                        <th>{{'property-valuation.Total-Area1'|
                                                                            translate }} </th>
                                                                        <th>{{'QrcodeViewEregi.TotalShare'| translate }}
                                                                        </th>
                                                                        <th>{{'ereg-property-details.transactingArea'|
                                                                            translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.transactingShare'|
                                                                            translate }}</th>

                                                                    </tr>
                                                                </thead>

                                                                <tbody *ngFor="let partyBean of CLRfirstPartyModel;let s=index;">
                                                                                                                                        
                                                                    <!-- <ng-container *ngFor="let partyBean of propDetlsKhasra?.partyBeans;let s=index;"> -->
                                                                    <ng-container *ngFor="let partyPropMapBean of partyBean?.partyPropMappingBeanList;let b1=index;">
                                                                     <ng-container *ngIf="partyBean?.partySubTypeId==1" >
                                                                    <td>{{s+1}}</td>
                                                                    <td >{{lan ==0?partyBean?.partyName:partyBean?.partyNameHindi}}</td>
                                                                    <td >{{lan == 0? partyBean?.fatherHusbandGardianNameEn:partyBean?.fatherHusbandGardianNameHi}}</td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareInPropertyArea}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareInPropertyPercentage}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareBeingSoldArea}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareBeingSoldPercentage}}
                                                                    </td>
                                                                 </ng-container>
                                                                </ng-container>
                                                                
                                                                
                                                                </tbody>
                                                            
                                                                
                                                            </table>
                                                            <table class="table table-bordered" *ngIf="row?.propTypeId==1 || row?.propTypeId==2">
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>{{'QrcodeViewEregi.Name'| translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.GuardianName'| translate
                                                                            }}</th>
                                                                        <th>{{'property-valuation.Total-Area1'|
                                                                            translate }} </th>
                                                                        <th>{{'QrcodeViewEregi.TotalShare'| translate }}
                                                                        </th>
                                                                        <th>{{'ereg-property-details.transactingArea'|
                                                                            translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.transactingShare'|
                                                                            translate }}</th>

                                                                    </tr>
                                                                </thead>
                                                               <ng-container  *ngIf="row?.propertySource=='PNRD'">
                                                                <tbody *ngFor="let reqbean of PNRDfirstPartyModel;let b=index; ">
                                                                   
                                                                    <ng-container *ngIf="reqbean?.partySubTypeId==1" >  
                                                                    <ng-container *ngFor="let partyPropMapBean of reqbean?.partyPropMappingBeanList;let b1=index;">                                                                      
                                                                       
                                                                    <td >{{b+1}}</td>
                                                                    <td >{{lan == 0?
                                                                        reqbean?.partyName:reqbean?.partyNameHindi}}</td>
                                                                    <td >{{lan == 0?
                                                                        reqbean?.fatherHusbandGardianNameEn:reqbean?.fatherHusbandGardianNameHi}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareInPropertyArea}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareInPropertyPercentage}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareBeingSoldArea}}
                                                                    </td>
                                                                    <td >
                                                                        {{partyPropMapBean?.shareBeingSoldPercentage}}
                                                                    </td>
                                                                    
                                                                    </ng-container>
                                                                </ng-container>
                                                            
                                                                </tbody>
                                                            </ng-container>
                                                                <ng-container  *ngIf="row?.propertySource=='ENP'">
                                                                <tbody *ngFor="let reqbean of ENPfirstPartyModel;let b=index; ">
                                                                    
                                                                        <ng-container *ngIf="reqbean?.partySubTypeId==1" > 
                                                                        <ng-container *ngFor="let partyPropMapBean of reqbean?.partyPropMappingBeanList;let b1=index;">
                                                                        

                                                                    <td >{{b+1}}</td>
                                                                     <td >{{lan == 0?
                                                                         reqbean?.partyName:reqbean?.partyNameHindi}}</td>
                                                                     <td >{{lan == 0?
                                                                         reqbean?.fatherHusbandGardianNameEn:reqbean?.fatherHusbandGardianNameHi}}
                                                                     </td>
                                                                     <td >
                                                                         {{partyPropMapBean?.shareInPropertyArea}}
                                                                     </td>
                                                                     <td >
                                                                         {{partyPropMapBean?.shareInPropertyPercentage}}
                                                                     </td>
                                                                     <td >
                                                                         {{partyPropMapBean?.shareBeingSoldArea}}
                                                                     </td>
                                                                     <td >
                                                                         {{partyPropMapBean?.shareBeingSoldPercentage}}
                                                                     </td>
                                                                     </ng-container>
                                                                     </ng-container>                                                                   
                                                                
                                                                 </tbody>
                                                                </ng-container>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div class="col-md-6" *ngIf="secondPartyAval">
                                                <fieldset class="legend-section  h-100">
                                                    <legend class="sidebar__widget-title">
                                                        {{lan==0?
                                                        secondPartyModel?.partyTypeNameEn:secondPartyModel?.partyTypeNameHi}}
                                                    </legend>
                                                    <div class="col-md-12">
                                                        <div class="table-responsive shadow-none">
                                                            <table class="table table-bordered"
                                                                *ngIf="row?.propTypeId==3">
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>{{'QrcodeViewEregi.Name'| translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.GuardianName'| translate
                                                                            }}</th>
                                                                        <th>{{'ereg-property-details.transactingArea'|
                                                                            translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.transactingShare'|
                                                                            translate }}</th>

                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody *ngFor="let toparty of CLRsecondPartyModel;let s=index;">
                                                                                                                                        
                                                                    <ng-container *ngIf="toparty?.partySubTypeId==2" >
                                                                    <ng-container *ngFor="let toPartyPropMapBean of toparty?.partyPropMappingBeanList;let b1=index;">
                                                                    
                                                                    <td >{{s+1}}</td>
                                                                    <td >{{lan == 0? toparty?.partyName:toparty?.partyNameHindi}}</td>
                                                                    <td >{{lan == 0?
                                                                        toparty?.fatherHusbandGardianNameEn:toparty?.fatherHusbandGardianNameHi}}
                                                                    </td>
                                                                    <td >
                                                                        {{toPartyPropMapBean?.shareBeingPurchasedArea}}
                                                                    </td>
                                                                    <td >
                                                                        {{toPartyPropMapBean?.shareBeingPurchasedPercentage}}
                                                                    </td>
                                                                    </ng-container>
                                                                    </ng-container>
                                                                <!-- </ng-container> -->
                                                                </tbody>
                                                               
                                                            </table>
                                                            <table class="table table-bordered"
                                                                *ngIf="row?.propTypeId==1 || row?.propTypeId==2">
                                                                <thead class="thead-dark">
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>{{'QrcodeViewEregi.Name'| translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.GuardianName'| translate
                                                                            }}</th>
                                                                        <th>{{'ereg-property-details.transactingArea'|
                                                                            translate }}</th>
                                                                        <th>{{'QrcodeViewEregi.transactingShare'|
                                                                            translate }}</th>

                                                                    </tr>
                                                                </thead>
                                                                <ng-container *ngIf="row?.propertySource=='PNRD'">
                                                                <tbody *ngFor="let toparty of PNRDsecondPartyModel;let b=index; ">
                                                                    
                                                                    <ng-container *ngFor="let partyPropMapBean of toparty?.partyPropMappingBeanList;let b1=index;">
                                                                            
                                                                    <ng-container *ngIf="toparty?.partySubTypeId==2" >          
                                                                    <td >{{b+1}}</td>
                                                                    <td>{{lan == 0?
                                                                        toparty?.partyName:toparty?.partyNameHindi}}</td>
                                                                    <td>{{lan == 0?
                                                                        toparty?.fatherHusbandGardianNameEn:toparty?.fatherHusbandGardianNameHi}}
                                                                    </td>
                                                                    <td>
                                                                        {{partyPropMapBean?.shareBeingPurchasedArea}}
                                                                    </td>
                                                                    <td>
                                                                        {{partyPropMapBean?.shareBeingPurchasedPercentage}}
                                                                    </td>
                                                                    </ng-container>
                                                                    </ng-container>
                                                                    
                                                                </tbody>
                                                            </ng-container>
                                                            <ng-container *ngIf="row?.propertySource=='ENP'">                                                      
                                                                
                                                                <tbody *ngFor="let toparty of ENPsecondPartyModel; let n=index;">
                                                                   
                                                                    <ng-container *ngFor="let partyPropMapBean of toparty?.partyPropMappingBeanList; let n=index;">                                                                       
                                                                    <ng-container *ngIf="toparty?.partySubTypeId==2" >          
                                                                    <td>{{n+1}}</td> 
                                                                    <td>{{lan == 0? toparty?.partyName:toparty?.partyNameHindi}}</td>
                                                                    <td>{{lan == 0? toparty?.fatherHusbandGardianNameEn:toparty?.fatherHusbandGardianNameHi}}
                                                                    </td>
                                                                    <td>
                                                                        {{partyPropMapBean?.shareBeingPurchasedArea}}
                                                                    </td>
                                                                    <td>
                                                                        {{partyPropMapBean?.shareBeingPurchasedPercentage}}
                                                                    </td>                                                                 
                                                                </ng-container>
                                                                   </ng-container>
                                                                </tbody>
                                                            </ng-container>
                                                            
                                                            
                                                            </table>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </fieldset>




                            <div class="d-none d-print d-print-block rotingtxt" style="
                            position: absolute;
                         top: 0;
                         bottom: 0;
                         left: 0;
                         right: 0;
                         height: auto;
                         text-align: center;
                         justify-content: center;
                         align-items: center;
                         flex-direction: row;
                         flex-wrap: wrap;
                         opacity: 0.1;
                         width: 100%;
                         display: block !important;
                         overflow: hidden;
                         height: 100%;
                         top: 0;
                         bottom: 0;
                         margin: 0 auto;
                         left: 0;
                         right: 0;" *ngIf="estampDetailsModel.estampStatus==130">
                     <div style="
                      transform: rotate(-45deg);
                         position: relative;
                         top: 0;
                         bottom: 0;
                         left: 0;
                         right: 0;
                         display: flex;
                         justify-content: center;
                         align-items: center;
                         width: 100%;
                         height: 100%;
                     ">
                     
                       <h1 style=" font-family: 'Roboto', sans-serif;   font-size: 79px;
                       margin-bottom: 42px;
                       color: #000;
                       text-transform: uppercase;
                       font-weight: 900;" *ngIf="lan==0">Cancelled</h1>
                      <h1 style="font-family: 'Roboto', sans-serif;font-size:250px;margin-bottom: 42px;
                     color: #000; text-transform: uppercase;
                     font-weight: 900;" *ngIf="lan==1">रद्द</h1>
                     </div>
                     </div>








                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<div class="banner-header" *ngIf="!isDataFound">
    <div class="container">
        <div class="breadcrumb-content">

            <h1 style="text-align: center; color: red;">{{dataMessage}}</h1>

        </div>
    </div>
</div>
<!-- <ngx-ui-loader></ngx-ui-loader> -->
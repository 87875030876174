import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';
import { CommonServiceService } from 'projects/common-lib/src/lib/helper-lib/services/common/common-service.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Captcha, CitizenForgot, RequestObject, User } from 'projects/common-lib/src/lib/models';
import { Subscription, timer } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  passhide = true;
  show = false;
  autohide = true;
  isUri:string

  usernameOption: boolean = true;
  otpOption: boolean;
  passwordOption: boolean;
  userName: string;
  otp: string;
  password: string;
  confPassword: string;
  captchaHandler?: Captcha = {};
  captchaImageBase64Img: string = '';
  captchaStr: string = '';
  captchaId: string = '';
  lan: number;
  lanVal: string = sessionStorage.getItem("selectedLanguage") || "hi";
  checkedVal: boolean;
  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;

  citizenForgot: CitizenForgot = new CitizenForgot();
  requestObject: RequestObject = new RequestObject();

  constructor(private translateService: TranslateHEService, private ngxService: NgxUiLoaderService
    , private authenticationService: AuthenticationService
    , private commonService: CommonServiceService
    , private router: Router
    , private translate: TranslateService
    , private titleService: Title
    , private _AESEncryptDecryptService: AESEncryptDecryptService,
      @Inject(DOCUMENT) private document: Document) {
   // this.translate.use(sessionStorage.getItem("selectedLanguage") || "en");
    this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    //this.translateService.updateApprovalMessage(this.lan);
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     })
     const url = this.document.baseURI;
     const parts = url.split('/');
     this.isUri = parts.filter(part => part.trim() !== '').pop();
  }

  ngOnInit(): void {
  }

  onSubmitUserNameForOtp() {
    this.ngxService.start()
    //this.authenticationService.getOtpForForget(this.userName).pipe().subscribe(s => {
    this.citizenForgot.userName = this.userName;
    this.citizenForgot.userType = 'department';
    
    this.authenticationService.getOtpForForget(this.citizenForgot, this.translate.currentLang).subscribe(s => {

      this.ngxService.stop()
      if (s.responseStatus == 'true') {
        this.counter = 30; // 3 min 3*60
        this.countDown = timer(0, this.tick).subscribe(() => {
          if (this.counter > 0) {
            --this.counter;
          }
        })
        this.getStartupCaptcha();
        this.usernameOption = false;
        this.otpOption = true;
        this.passwordOption = false;
        this.alertmessage.successAlertMessage(s);
        //Swal.fire("Success", s.responseMessage, "success");
        //this.router.navigate(['login']);

      }
      else {
        this.usernameOption = true;
        this.otpOption = false;
        this.passwordOption = false;
        this.alertmessage.errorAlertMessage(s);
        //Swal.fire("Error", s.responseMessage, "error");
      }
    },
      err => {

        this.usernameOption = true;
        this.otpOption = false;
        this.passwordOption = false;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('tech_error_msg');
        //Swal.fire("Error", 'tech_error_msg', "error");
      })
  }

  onOtpForPassword() {
    this.ngxService.start()
    this.authenticationService.verifyOtpForForget(this.userName, this.otp, this.captchaId, this.captchaStr).pipe().subscribe(s => {

      this.ngxService.stop()
      if (s.responseStatus == 'true') {
        this.usernameOption = false;
        this.otpOption = false;
        this.passwordOption = true;
        this.alertmessage.successAlertMessage(s);
        //Swal.fire("Success", s.responseMessage, "success");
        //this.router.navigate(['login']);

      }
      else {
        this.usernameOption = false;
        this.otpOption = true;
        this.passwordOption = false;
        this.alertmessage.errorAlertMessage(s);
        //Swal.fire("Error", s.responseMessage, "error");
      }
    },
      err => {

        this.usernameOption = false;
        this.otpOption = true;
        this.passwordOption = false;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('tech_error_msg');
        //Swal.fire("Error", 'tech_error_msg', "error");
      })
  }

  getStartupCaptcha() {

    this.ngxService.start();
    this.authenticationService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id || "";
      //this.captchaId = this.captchaHandler.responseData?.id;
      // 
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage("TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !");
      }
    );
  }

  onPasswordForSubmit() {
    if (this.password == undefined || this.password == null || this.password == ''
      || this.confPassword == undefined || this.confPassword == null || this.confPassword == '') {
      this.alertmessage.errorAlertMessage('invalid-password');
      return;
    }
    this.ngxService.start();
    //let formData = new FormData();

    //formData.append("userName", this.userName);
    //formData.append("userType", 'department');
    //formData.append("newPassword", this._AESEncryptDecryptService.encrypt(this.password));
    //formData.append("confirmPassword", this._AESEncryptDecryptService.encrypt(this.confPassword));

    // this.authenticationService.submitForgetPassword(this.userName, this.password, this.confPassword).pipe().subscribe(s => {
    //  
    
    this.requestObject.userName=this.userName;
    this.requestObject.userType='department';
    this.requestObject.newPassword=this.password;
    this.requestObject.confirmPassword=this.confPassword;
    this.requestObject.otp=this.otp;
    this.requestObject.captchaId=this.captchaId;
    this.requestObject.captchaStr=this.captchaStr;

    this.commonService.resetPasswordForUser(this.requestObject).subscribe(s => {
      if (s.responseStatus == 'true') {
        this.alertmessage.successAlertMessage(s);
        setTimeout(() => {
          this.usernameOption = true;
          this.otpOption = false;
          this.passwordOption = false;
          this.ngxService.stop()
          this.router.navigate(['alogin']);
        }, 5000)

      }
      else {
        this.usernameOption = false;
        this.otpOption = false;
        this.passwordOption = true;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage(s);
      }
    },
      err => {

        this.usernameOption = false;
        this.otpOption = false;
        this.passwordOption = true;
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('tech_error_msg');
        //Swal.fire("Error", 'tech_error_msg', "error");
      })

  }

  // changeLan(ev: any) {
  //   //

  //   if (ev.value == 1) {
  //     this.lan = 0;
  //     sessionStorage.setItem("selectedLanguage", 'en');
  //     this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    
  //     this.translateService.updateApprovalMessage(this.lan);
  //   } else {
  //     this.lan = 1;
  //     sessionStorage.setItem("selectedLanguage", 'hi');
  //     this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      
  //     this.translateService.updateApprovalMessage(this.lan);
  //   }

  //   this.translate.get("forgot-password-title").subscribe(name => {
  //     this.titleService.setTitle(name);
  //   });
  //   //window.location.reload();
  // }


}

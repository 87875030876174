<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2> 
          {{'detailsPages.walletDetail' | translate}}
        </h2>
        <!-- <button mat-button class="btn btn-primary" id="sticky_Div">
         {{ "detailsPages.processForwalletDetail" | translate }}
        </button> -->
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
        <div class="row">
          <div class="col-md-6">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is SAMPADA WALLET ?</div>
                    <div *ngIf="lan == 1">संपदा वॉलेट क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      SAMPADA Wallet is mainly used to keep the credit
                      limit(prepaid amount) so that user can do instant payment
                      to avail all the SAMPADA services like Registration
                      fees,E-stamp fees etc….
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपदा वॉलेट का उपयोग मुख्य रूप से क्रेडिट सीमा (प्रीपेड
                      राशि) रखने के लिए किया जाता है ताकि उपयोगकर्ता पंजीकरण
                      शुल्क, ई-स्टांप शुल्क आदि जैसी सभी संपदा सेवाओं का लाभ
                      उठाने के लिए तत्काल भुगतान कर सके।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Who can manage SAMPADA WALLET ?</div>
                    <div *ngIf="lan == 1">
                      संपदा वॉलेट मैनेज कौन कर सकता है?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      SAMPADA wallet can be managed by all the citizen(register
                      user) and service providers.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपदा वॉलेट को सभी नागरिकों (रजिस्टर उपयोगकर्ता) और सर्विस
                      प्रोवाइडर द्वारा मैनेज किया जा सकता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Type of SAMPADA wallet</div>
                    <div *ngIf="lan == 1">संपदा वॉलेट के प्रकार</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>There are five major category of SAMPADA wallet.</p>
                    <ol>
                      <li>
                        E-Stamps Non Judicial Credit Limit ( E-Wallet for
                        SAMPADA 2.0 Services)
                        <ul>
                          <li>Registration fee and other services</li>
                          <li>Estamp Judicial Credit Limit</li>
                        </ul>
                      </li>
                      <li>
                        Credit Limit for RCMS (E-Wallet for CLR Mutation fee)
                      </li>
                      <li>Credit Limit for UADD (E-Wallet for UADD)</li>
                      <li>
                        Credit Limit for CLR (E-Wallet for CLR(WebGIS Khasra
                        Information) )
                      </li>
                      <li>
                        Credit Limit for Panchayat and Rural Development
                        (E-Wallet for P&RD- Mutation fee)
                      </li>
                    </ol>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>संपदा वॉलेट की पांच प्रमुख श्रेणियां हैं</p>
                    <ol>
                      <li>
                        ई-स्टांप गैर न्यायिक क्रेडिट सीमा (संपदा 2.0 सेवाओं के
                        लिए ई-वॉलेट)
                        <ul>
                          <li>पंजीकरण शुल्क और अन्य सेवाएँ</li>
                          <li>न्यायिक क्रेडिट सीमा निर्धारित करें</li>
                        </ul>
                      </li>
                      <li>
                        आरसीएमएस के लिए क्रेडिट सीमा (सीएलआर म्यूटेशन शुल्क के
                        लिए ई-वॉलेट)
                      </li>
                      <li>
                        यूएडीडी के लिए क्रेडिट सीमा (यूएडीडी के लिए ई-वॉलेट)
                      </li>
                      <li>
                        सीएलआर के लिए क्रेडिट सीमा (सीएलआर के लिए ई-वॉलेट
                        (वेबजीआईएस खसरा सूचना))
                      </li>
                      <li>
                        पंचायत और ग्रामीण विकास के लिए क्रेडिट सीमा (पी एंड आरडी
                        के लिए ई-वॉलेट- म्यूटेशन शुल्क)
                      </li>
                    </ol>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">How to recharge your wallet ?</div>
                    <div *ngIf="lan == 1">अपने वॉलेट को कैसे रिचार्ज करें?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>Steps to recharge the SAMPADA wallet</p>
                    <ul>
                      <li>
                        User Login into the system and go to Payment Module.
                      </li>
                      <li>
                        The system should display the default created E-Wallet
                        of SAMPADA 2.0, which shall contain the E-Wallet of
                        Non-Judicial e stamp, E-Wallet of Sampada other
                        services, E-Wallet of CLR, UADD & P&RD.
                      </li>
                      <li>
                        Users shall have an option to select a single E-Wallet
                        or multi-wallet to recharge.
                      </li>
                      <li>
                        User will click add to wallet button and enter amount in
                        the E-Wallet which needs to recharge.
                      </li>
                      <li>There after user will add money to the wallet.</li>
                      <li>Enter amount and click submit</li>
                      <li>A link will be enabled to process online payment</li>

                      <li>Click on online payment and click pay now.</li>
                      <li>
                        The system opens the online payment information page
                        where user Information like-( Name, address, mobile
                        number, email id, SP license details, the purpose of
                        payment, revenue major head, minor head, enter amount
                        details, and name of treasury will be displayed).
                      </li>
                      <li>
                        User check the details and click next to process further
                      </li>
                      <li>
                        System generates CRN number and redirect to cyber
                        treasury payment gateway
                      </li>
                      <li>
                        Cyber treasury open the challan payment form, user
                        checks the details confirm and select bank for payment.
                      </li>
                      <li>System redirects to bank portal for payment</li>
                      <li>
                        Select services like Net banking, Debit or Credit card
                      </li>
                      <li>
                        On successful transaction, system generate the CIN
                        number and redirect to the cyber treasury
                      </li>
                      <li>
                        Cyber treasury generates the URN number and returns to
                        SAMPADA 2.0 application with an updated date and time.
                      </li>
                      <li>SAMPADA 2.0 recharges the E-Wallet successfully.</li>
                    </ul>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>संपदा वॉलेट वॉलेट को रिचार्ज करने के चरण</p>
                    <ul>
                      <li>
                        यूजर सिस्टम में लॉगइन करें और पेमेंट मॉड्यूल पर जाएं.
                      </li>
                      <li>
                        सिस्टम को संपदा 2.0 का डिफ़ॉल्ट निर्मित ई-वॉलेट
                        प्रदर्शित करना चाहिए, जिसमें गैर-न्यायिक ई-स्टांप का
                        ई-वॉलेट, संपदा की अन्य सेवाओं का ई-वॉलेट, सीएलआर,
                        यूएडीडी और पीएंडआरडी का ई-वॉलेट शामिल होगा।
                      </li>
                      <li>
                        यूजर्स के पास रिचार्ज करने के लिए सिंगल ई-वॉलेट या
                        मल्टी-वॉलेट चुनने का विकल्प होगा।
                      </li>
                      <li>
                        उपयोगकर्ता ऐड टू वॉलेट बटन पर क्लिक करेगा और ई-वॉलेट में
                        वह राशि दर्ज करेगा जिसे रिचार्ज करना होगा।
                      </li>
                      <li>इसके बाद यूजर वॉलेट में पैसे डाल देगा.</li>
                      <li>राशि दर्ज करें और सबमिट पर क्लिक करें</li>
                      <li>
                        ऑनलाइन भुगतान प्रक्रिया के लिए एक लिंक सक्षम किया जाएगा
                      </li>
                      <li>
                        ऑनलाइन पेमेंट पर क्लिक करें और अभी भुगतान करें पर क्लिक
                        करें.
                      </li>
                      <li>
                        सिस्टम ऑनलाइन भुगतान सूचना पृष्ठ खोलता है जहां
                        उपयोगकर्ता की जानकारी जैसे- (नाम, पता, मोबाइल नंबर, ईमेल
                        आईडी, एसपी लाइसेंस विवरण, भुगतान का उद्देश्य, राजस्व
                        प्रमुख शीर्ष, लघु शीर्ष, राशि विवरण दर्ज करें, और राजकोष
                        का नाम) प्रदर्शित किया जाएगा।
                      </li>
                      <li>
                        उपयोगकर्ता विवरण जांचें और आगे की प्रक्रिया के लिए अगला
                        क्लिक करें
                      </li>
                      <li>
                        सिस्टम सीआरएन नंबर जेनरेट करता है और साइबर ट्रेजरी
                        पेमेंट गेटवे पर रीडायरेक्ट करता है
                      </li>
                      <li>
                        साइबर ट्रेजरी चालान भुगतान फॉर्म खोलें, उपयोगकर्ता विवरण
                        की जांच करता है और भुगतान के लिए बैंक का चयन करता है।
                      </li>
                      <li>
                        सिस्टम भुगतान के लिए बैंक पोर्टल पर रीडायरेक्ट करता है
                      </li>
                      <li>
                        नेट बैंकिंग, डेबिट या क्रेडिट कार्ड जैसी सेवाओं का चयन
                        करें
                      </li>
                      <li>
                        सफल लेनदेन पर, सिस्टम CIN नंबर जेनरेट करता है और साइबर
                        ट्रेजरी में रीडायरेक्ट करता है
                      </li>
                      <li>
                        साइबर ट्रेजरी यूआरएन नंबर जेनरेट करता है और अद्यतन तिथि
                        और समय के साथ संपदा 2.0 एप्लिकेशन पर वापस आ जाता है।
                      </li>
                      <li>संपदा 2.0 ई-वॉलेट को सफलतापूर्वक रिचार्ज करता है।</li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Know your wallet balance / recharge</div>
                    <div *ngIf="lan == 1">अपने वॉलेट बैलेंस/रिचार्ज को जानें</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>To know you wallet balance/ recharge amount </p>
                    <ul>
                      <li>
                        Login into the system
                      </li>
                      <li>
                        Click on wallet from payment menu option
                      </li>
                      <li>
                        On the payment page top cornor click on show balance
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>अपने वॉलेट का बैलेंस/रीचार्ज राशि जानने के लिए</p>
                    <ul>
                      <li>सिस्टम में लॉगइन करें</li>
                      <li>पेमेंट मेनू विकल्प से वॉलेट पर क्लिक करें</li>
                      <li>भुगतान पृष्ठ के शीर्ष कोने पर शो बैलेंस पर क्लिक करें </li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>



              <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">When the failed transaction will be reconcile ?</div>
                    <div *ngIf="lan == 1">फेल ट्रांजैक्शन का समाधान कब होगा?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>MP Treasury take considerable time in reimbursing the amounts involved in such failed
                      transactions to account holders. In many cases,
                      the time taken is as much as 7 days.</p>

                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      एमपी ट्रेजरी को खाताधारकों को ऐसे विफल लेनदेन में शामिल राशि की प्रतिपूर्ति करने में काफी समय लगता
                      है। कई मामलों में तो 7 दिन तक का समय लग जाता है.
                    </p>

                  </div>
                </div>
              </mat-expansion-panel>
              <mat-expansion-panel [expanded]="step === 6" (opened)="setStep(6)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Check your transaction status</div>
                    <div *ngIf="lan == 1">अपने लेन-देन की स्थिति जांचें</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      To know you wallet transaction status</p>
                    <ul>
                      <li> Login into the system</li>
                      <li> Click on wallet from payment menu option </li>
                      <li> On the payment there are multiple wallet will display</li>
                      <li> Click on more option from wallet</li>
                      <li> Select wallet from dropdown</li>
                      <li> Click on submit</li>
                    </ul>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      अपने वॉलेट लेनदेन की स्थिति जानने के लिए
                    </p>
                    <ul>

                      <li> सिस्टम में लॉगइन करें</li>
                      <li> पेमेंट मेनू विकल्प से वॉलेट पर क्लिक करें</li>
                      <li> भुगतान पर कई वॉलेट प्रदर्शित होंगे</li>
                      <li> वॉलेट से मोर ऑप्शन पर क्लिक करें</li>
                      <li> ड्रॉपडाउन से वॉलेट चुनें</li>
                      <li> सबमिट पर क्लिक करें</li>
                    </ul>

                  </div>
                </div>
              </mat-expansion-panel>



            </mat-accordion>
          </div>
          <div class="col-md-6">
            <div class="process_img">
              <img src="assets/images/PaymentWallet.jpg" alt="PaymentWallet" style="width: 100%" />
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
//import { none } from 'ol/centerconstraint';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Captcha, Citizen } from 'projects/common-lib/src/lib/models';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { CompanyIdType, IdentityProof, juisticPersonDetail, OrganizationType } from 'projects/common-lib/src/lib/models/juristic-Person.model/juristicPersonDetails.model';
import { Subscription, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { DefaultServiceService } from '../../default/default-service.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IgrsEregPartyDetailsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';
import { MstBankMasterBean } from 'projects/common-lib/src/lib/models/serviceprovider/serviceprovider.model';

import { PanService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/pan.service';
import { PanKyc } from 'projects/common-lib/src/lib/models/panKyc.model/pankyc.model';
import { CitizenService } from '../../service/citizen.service';
import { EregistryService } from '../../service/eregistry.service';
import { DatePipe } from '@angular/common';
import { AppSetting } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { Department } from 'projects/common-lib/src/lib/models/api.masterdata.model/department';

@Component({
  selector: 'app-legal-person-registration',
  templateUrl: './legal-person-registration.component.html',
  styleUrls: ['./legal-person-registration.component.scss']
})
export class LegalPersonRegistrationComponent implements OnInit {

  citizenType: number = 2;

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  juristicPersonProfileModel: juisticPersonDetail = new juisticPersonDetail();
  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  bankMasterList: MstBankMasterBean[] = new Array();
  legalEntityTypesList: IgrsLegalEntityTypeBean[] = new Array();
  bankDiv: boolean = false;
  depDiv: boolean = false;
  gstData: any;
  cinData: any;
  panData: PanKyc;

  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;

  step1clear: boolean = false;
  step2clear: boolean = false;
  step3clear: boolean = false;
  step4clear: boolean = false;

  lan: number;
  checkedVal: boolean;

  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec

  otpGenerated: boolean = false;
  otpTouched: boolean = false;

  mobileNo: any;
  otp: any;

  captchaHandler?: Captcha = {};
  captchaImageBase64Img?: string = "";
  captchaId: any;
  captchaStr: any;

  organizationTypes: any;
  companyIdTypes: any;
  // identityProofs:any;

  kycSource: string;
  kycObject: any;

  panDob: any;
  panFullName:any;

  allDepartmentList: Department[] = new Array();
  departmentList: Department[] = new Array();

  citizen: Citizen = new Citizen();
  private settings!: AppSetting;
  constructor(private translateService: TranslateHEService, private translate: TranslateService, private ngxService: NgxUiLoaderService,
    private citizenAuthService: CitizenAuthenticationService, private userService: CitizenService, private panService: PanService, private router: Router, private titleService: Title, private common: CommonService, private eRegService: EregistryService,
    private defaultServiceService: DefaultServiceService, @Inject(MAT_DIALOG_DATA) public eRegPartyModel: IgrsEregPartyDetailsBean, private _AESEncryptDecryptService: AESEncryptDecryptService) {


    try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 0;
        selectedLanguage = 'hi';
      }
      this.lan = selectedLanguage == 'hi' ? 1 : 0;
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg == 1 ? 'hi' : 'en')
      })
    } catch (err) {
      // console.log(err);
    }


  }


  ngOnInit(): void {
    console.log("Legal-person-registration_eRegPartyModel : ", this.eRegPartyModel)
    this.defaultServiceService.setDefaultType('legal-person');
    this.getAllLegalEntityTypes();
    this.getStartupCaptcha();
    this.fetchOrganizationTypes();
    this.fetchCompanyIdTypes();
    this.juristicPersonProfileModel.companyIdType = new CompanyIdType();
    this.juristicPersonProfileModel.organizationType = new OrganizationType();
    this.juristicPersonProfileModel.identityProof = new IdentityProof();
    this.juristicPersonProfileModel.organizationType.id = 0;
    this.juristicPersonProfileModel.companyIdType.id = 0;
    this.settings = this.common.getAppSetting();
  }

  gotostep1() {
    this.getStartupCaptcha();
    this.otpGenerated = false;
    // this.mobileNo=null;
    this.otp = null;
    this.captchaStr = null;
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
  }
  gotostep2() {
    this.step2 = true;
    this.step1 = false;
    this.step3 = false;
    this.step4 = false;
    this.step5 = false;
  }
  gotostep3() {
    this.step5 = false;
    this.step4 = false;
    this.step3 = true;
    this.step2 = false;
    this.step1 = false;
  }
  gotostep4() {
    this.step5 = false;
    this.step4 = true;
    this.step3 = false;
    this.step2 = false;
    this.step1 = false;
  }

  gotostep5() {
    this.step5 = true;
    this.step4 = false;
    this.step3 = false;
    this.step2 = false;
    this.step1 = false;
  }

  goBack(step: any) {
    if (step == 2) {
      this.gotostep1();
    }
    if (step == 3) {
      this.gotostep2();
    }
    if (step == 4) {
      this.gotostep3();
    }
    if (step == 5) {
      this.gotostep4();
    }
  }

  reset() {
    this.gotostep1();
    this.counter = 0;
    this.otpTouched = false;
  }

  changeLan(ev: any) {
    //
    // 
    if (ev.value == 1) {
      this.lan = 0;
      sessionStorage.setItem("selectedLanguage", 'en');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      // this.translate.use(sessionStorage.getItem("selectedLanguage") || "en")
      this.translateService.updateApprovalMessage(this.lan);
    } else {
      this.lan = 1;
      sessionStorage.setItem("selectedLanguage", 'hi');
      this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
      //   this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi")
      this.translateService.updateApprovalMessage(this.lan);
    }
    // window.location.reload();
    this.translate.get("legal-person-title").subscribe(name => {
      this.titleService.setTitle(name);
    });
  }

  getStartupCaptcha() {
    // 
    this.ngxService.start();
    this.citizenAuthService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id;
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('captcha-error');
      }
    );
  }

  generateOtp() {
    let mb: string = this.mobileNo;
    if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == "") {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    } else if (mb.length != 10) {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }

    let mb1: string = mb.substring(0, 1);
    if (mb1 != '9' && mb1 != '8' && mb1 != '7' && mb1 != '6') {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }
    if (this.captchaStr == null || this.captchaStr == undefined || this.captchaStr == "") {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-captcha-error');
      return;
    } else {
      this.citizen.mobileNo = this.mobileNo;
      this.citizen.citizenType = this.citizenType;
      this.citizen.captchaId = this.captchaId;
      this.citizen.captchaStr = this.captchaStr;
      this.citizenAuthService.getOtp(this.citizen).subscribe(res => {
        if (res && res.responseStatus == 'true') {
          this.otpGenerated = true;
          this.otpTouched = true;
          this.counter = 30;
          this.countDown = timer(0, this.tick).subscribe(() => {
            if (this.counter > 0) {
              --this.counter;
            }
          });
          this.alertmessage.successAlertMessage(res);
        } else {
          this.otpGenerated = false;
          this.otpTouched = false;
          this.alertmessage.errorAlertMessage(res);
          this.gotostep1();
        }
      });
    }
  }

  verifyOtp() {
    if (this.otp == null || this.otp == undefined || this.otp == "") {
      this.alertmessage.errorAlertMessage('otp-error');
    } else {
      this.citizenAuthService.verifyOtp(this.mobileNo, this.otp).subscribe(res => {
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          this.step1clear = true;
          this.gotostep2();
        } else {
          this.alertmessage.errorAlertMessage(res);
          this.step1clear = false;
          this.otp = null;
        }
      });
    }
  }
  // verifyAdharOtpOut(event:any) {
  //   
  //   if(event!=null){
  //     this.kycObject = event;
  //     this.alertmessage.successAlertMessage(this.kycObject.status);
  //     this.gotostep5();
  //     // this.demographicDetails.updateMobileNumber(this.mobileNo);
  //   } else {
  //     this.alertmessage.errorAlertMessage('Failed Kyc');
  //     this.gotostep4();
  //   }
  // }

  verifyAdharOtpOut(event: any) {
    // 
    if (event != null && event.ret == 1) {
      this.kycObject = event;
      this.kycSource = 'Aadhaar';
      // this.step4clear = true;
      // this.gotostep5();

      const param = {
        "kyc_unique_token": this.kycObject.residentDetails.uidToken,
        "citizen_type": this.citizenType,
        "companyIdNo": this.juristicPersonProfileModel.companyIdNo
      }
      this.citizenAuthService.checkAadhaarAlreadyExist(param).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.step4clear = true;
          this.gotostep5();
          //this.alertmessage.successAlertMessage(res);
        } else {
          this.step4clear = false;
          this.alertmessage.errorAlertMessage(res);
          this.gotostep4();
        }
      });
    } else {
      // this.alertmessage.errorAlertMessage('Kyc Failed: AUA: '+event.auaErrorCode+' - SRDH: '+event.srdhErrorCode);
      this.step4clear = false;
      this.gotostep4();
    }
  }

  verifyPanOut(event: any) {
    console.log(event.status);
    if (event != null && event.skip == 1) {
      this.gotostep5();
    } else if (event != null && event.status == 1) {
      this.kycObject = event;
      this.kycSource = 'Pan';
      // this.step4clear = true;
      // this.gotostep5();

      this.citizenAuthService.checkPanAlreadyExist(this.kycObject.pan, '', '', this.citizenType, this.juristicPersonProfileModel.companyIdNo).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.step4clear = true;
          this.gotostep5();
          //this.alertmessage.successAlertMessage(res);
        } else {
          this.step4clear = false;
          this.alertmessage.errorAlertMessage(res);
          this.gotostep4();
        }
      });

    } else {
      this.alertmessage.errorAlertMessage('Kyc Failed');
      this.step4clear = false;
      this.gotostep4();
    }
  }

  fetchOrganizationTypes() {
    this.userService.get_OrganizationType().subscribe((result) => {
      this.organizationTypes = result.responseData;
    });
  }
  fetchCompanyIdTypes() {
    this.userService.get_CompanyIdType().subscribe((result) => {
      this.companyIdTypes = result.responseData;
    });
  }
  // fetchIdentityProof() {
  //   this.userService.get_IdentityProofList().subscribe((result) => {
  //     this.identityProofs = result.responseData;
  //   });
  // }

  fetchCompanyDetails() {
    if (this.juristicPersonProfileModel.companyIdType.id == 1) {
      this.getGstData();
    } else if (this.juristicPersonProfileModel.companyIdType.id == 2) {
     // this.validatePanData();
     this.validateProteanPanData();
    } else if (this.juristicPersonProfileModel.companyIdType.id == 3) {
      //this.getCinData();
      this.getCinToken();
    }

    // this.fetchIdentityProof();
    for (var i = 0; i < this.organizationTypes?.length; i++) {
      if (this.organizationTypes[i].id == (+this.juristicPersonProfileModel.organizationType.id)) {
        this.juristicPersonProfileModel.organizationType.organizationTypeName = this.organizationTypes[i].organizationTypeName;
      }
    }

  }

  validatePanData() {
    console.log("panDob::"+this.panDob+" panFullName::"+this.panFullName);
    if (this.juristicPersonProfileModel.companyIdNo != "") {

      this.ngxService.start();
      let encPan = this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo);
      this.panService.checkPanNumber(encPan).subscribe(data => {
        this.ngxService.stop();
        if (data.responseStatus == 'true') {
          this.panData = data.responseData;
          if (this.panData.status == 1 && this.panData.detailStatus == 'E') {
            //  this.fullName = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            if (this.panData.nameonCard == undefined || this.panData.nameonCard == "") {
              this.panData.nameonCard = this.panData.firstName + " " + (this.panData.middleName != "" ? this.panData.middleName + " " : "") + this.panData.lastName;
            }

            this.step2clear = true;
            this.gotostep3();
          } else {
            this.step2clear = false;
            this.ngxService.stop();
            this.alertmessage.errorAlertMessage({ responseMessage: this.panData.detailStatusDesc, responseMessageEn: this.panData.detailStatusDesc, responseMessageHi: this.panData.detailStatusDesc });
          }
        } else {
          this.step2clear = false;
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage(data);
        }
        // setTimeout(() => { this.verifyPanOut.emit(this.panKyc); }, 1000);
      });
      this.ngxService.stop();

    } else {
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage("pan-required");
    }
  }

  validateProteanPanData() {
    if (this.juristicPersonProfileModel.companyIdNo != "") {
      this.ngxService.start();
    
      let panDob = new DatePipe('en-US').transform(this.panDob, 'dd/MM/yyyy')
    //  console.log(" panDob::"+panDob+" panFullName::"+this.panFullName);

      let encVal = this._AESEncryptDecryptService.encrypt("pannumber=" + this.juristicPersonProfileModel.companyIdNo + "~panDob=" + panDob+"~panFullName="+this.panFullName +"~mobileNo="+this.mobileNo);

      this.panService.checkProteanPanNumber(encVal).subscribe(data => {
        this.ngxService.stop();
        if (data.responseStatus == 'true') {
          this.panData = data.responseData;
          if (this.panData.status == 1 && this.panData.detailStatus == 'E') {
            // this.fullName = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            // if (this.panKyc.nameonCard == undefined || this.panKyc.nameonCard == "") {
            //   this.panKyc.nameonCard = this.panKyc.firstName + " " + (this.panKyc.middleName != "" ? this.panKyc.middleName + " " : "") + this.panKyc.lastName;
            // }
            //this.fullName = this.panKyc.title + " " + this.panKyc.firstName + " " + this.panKyc.middleName + " " + this.panKyc.lastName;
            if(this.panData.dobStatus != 'Y'){
             // this.panDob = "";
              this.alertmessage.errorAlertMessage({ responseMessage: this.panData.dobStatusDesc, responseMessageEn: this.panData.dobStatusDesc, responseMessageHi: this.panData.dobStatusDesc });
            }
            else if(this.panData.fullNameStatus != 'Y'){
             // this.panFullName = "";
              this.alertmessage.errorAlertMessage({ responseMessage: this.panData.nameonCard, responseMessageEn: this.panData.nameonCard, responseMessageHi: this.panData.nameonCard });
            }else{
              this.step2clear = true;
              this.gotostep3();

              this.panData.dobStatusDesc = this.panDob;
              this.alertmessage.successAlertMessage({ responseMessage: 'Your PAN verification is successful', responseMessageEn: 'Your PAN verification is successful', responseMessageHi: 'आपका पैन सत्यापन सफलतापूर्वक हो गया है' });
            }

          } else {
            this.step2clear = false;
            this.alertmessage.errorAlertMessage({ responseMessage: this.panData.detailStatusDesc, responseMessageEn: this.panData.detailStatusDesc, responseMessageHi: this.panData.detailStatusDesc });
          }
        } else {
          this.alertmessage.errorAlertMessage(data);
        }
        // setTimeout(() => { this.verifyPanOut.emit(this.panKyc); }, 1000);
      });
      this.ngxService.stop();
    } else {
      this.ngxService.stop();
      this.alertmessage.errorAlertMessage("pan-required");
    }
  }

  // Getting Gst related data based on GST Number
  getGstData() {
    this.ngxService.start();
    if (this.juristicPersonProfileModel.companyIdNo != "") {

      const param = {
        "mobileNo": this._AESEncryptDecryptService.encryptInAngular(this.mobileNo),
        "companyIdNo": this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo),
      }

      let encMobileNo = this._AESEncryptDecryptService.encryptInAngular(this.mobileNo);
      let encGstin = this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo);
      this.userService.gstValidationData(param).subscribe(res => {
        // 
        if (res.responseStatus == 'true') {
          this.ngxService.stop();
          this.alertmessage.successAlertMessage(res);
          this.gstData = res.responseData;
          // 
          this.step2clear = true;
          this.gotostep3();
        } else {
          this.step2clear = false;
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage(res);
        }

      },
        err => {
          this.step2clear = false;
          this.ngxService.stop()
          this.juristicPersonProfileModel.companyIdNo = ''
          this.alertmessage.errorAlertMessage('tech_error_msg');
        })
    }
  }

  // Generate cin token 
  getCinToken() {
    this.ngxService.start();
    if (this.juristicPersonProfileModel.companyIdNo != "") {
      // let encMobileNo = this._AESEncryptDecryptService.encryptInAngular(this.mobileNo);
      // let encCin = this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo);
      const param = {
        "mobileNo": this._AESEncryptDecryptService.encryptInAngular(this.mobileNo),
        "companyIdNo": this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo),
      }
      this.userService.cinTokenData(param).subscribe(res => {
        //
        if (res.responseStatus == 'true') {
          this.ngxService.stop();
          this.getCinDetails();
        } else {
          this.step2clear = false;
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage(res);
        }
      },
        err => {
          this.step2clear = false;
          this.ngxService.stop()
          this.juristicPersonProfileModel.companyIdNo = ''
          this.alertmessage.errorAlertMessage('tech_error_msg');
        })
    }
  }

    // Getting CIN related Data based on CIN Number //NEW METHOD
    getCinDetails() {
      this.ngxService.start();
      if (this.juristicPersonProfileModel.companyIdNo != "") {
        // let encMobileNo = this._AESEncryptDecryptService.encryptInAngular(this.mobileNo);
        // let encCin = this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo);
        const param = {
          "mobileNo": this._AESEncryptDecryptService.encryptInAngular(this.mobileNo),
          "companyIdNo": this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo),
        }
        this.userService.getCinDetails(param).subscribe(res => {
          // 
          if (res.responseStatus == 'true') {
            this.ngxService.stop();
            this.alertmessage.successAlertMessage(res);
            this.cinData = res.responseData;
            // 
            this.step2clear = true;
            this.gotostep3();
          } else {
            // if(res.responseData != null && res.responseData.isTokenExpire) {}
            this.step2clear = false;
            this.ngxService.stop();
            this.alertmessage.errorAlertMessage(res);
          }
  
        },
          err => {
            this.step2clear = false;
            this.ngxService.stop()
            this.juristicPersonProfileModel.companyIdNo = ''
            this.alertmessage.errorAlertMessage('tech_error_msg');
          })
      }
    }

  // Getting CIN related Data based on CIN Number //OLD METHOD
  getCinData() {
    this.ngxService.start();
    if (this.juristicPersonProfileModel.companyIdNo != "") {
      let encCin = this._AESEncryptDecryptService.encryptInAngular(this.juristicPersonProfileModel.companyIdNo);
      this.userService.cinValidationData(encCin).subscribe(res => {
        // 
        if (res.responseStatus == 'true') {
          this.ngxService.stop();
          this.alertmessage.successAlertMessage(res);
          this.cinData = res.responseData;
          // 
          this.step2clear = true;
          this.gotostep3();
        } else {
          this.step2clear = false;
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage(res);
        }

      },
        err => {
          this.step2clear = false;
          this.ngxService.stop()
          this.juristicPersonProfileModel.companyIdNo = ''
          this.alertmessage.errorAlertMessage('tech_error_msg');
        })
    }
  }

  fileUploadEvent(pfileInput: any, docType: number): any {
    // 
    // 
    if (pfileInput.target.files && pfileInput.target.files[0]) {
      // Size Filter Bytes
      const pmax_size = 1000000;
      const pallowed_types = ["application/pdf"];
      const pmax_height = 15200;
      const pmax_width = 25600;
      // 
      if (pfileInput.target.files[0].size > pmax_size) {
        //this.alertmessage.errorAlertMessage('file-size-error');
        Swal.fire({
          title: `${this.lan == 0 ? "File Size Exceeds Allowed Limit" : "कुछ गलत हो गया!"}`,
          // title: "File Size Exceeds Allowed Limit",
          text: `${this.lan == 0 ? "Maximum size allowed is " + pmax_size / 1000000 + " MB" : "अधिकतम आकार अनुमत है " + pmax_size / 1000000 + " एमबी"}`,
          // text: "Maximum size allowed is " + pmax_size / 1000000 + " MB",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
          cancelButtonText: (this.lan == 0) ? 'No, keep it' : 'नहीं, इसे रखो',
          allowOutsideClick: false,
        }).then((result) => {
          if (docType == 1)
            this.juristicPersonProfileModel.documentPath = '';

          if (docType == 2)
            this.juristicPersonProfileModel.undertakingDocumentPath = '';

          return false;
        });
        return false;
      }
      if (!pallowed_types.includes(pfileInput.target.files[0].type, 0)) {
        //this.alertmessage.errorAlertMessage('file-type-error');
        Swal.fire({
          title: `${this.lan == 0 ? "UnSupported File Type" : "असमर्थित फ़ाइल प्रकार"}`,
          // title: "UnSupported File Type",
          text: `${this.lan == 0 ? "Only files are allowed of type ( pdf )" : "केवल (पीडीएफ) प्रकार की फ़ाइलों की अनुमति है"}`,
          // text: "Only files are allowed of type ( pdf )  ",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
          cancelButtonText: (this.lan == 0) ? 'No, keep it' : 'नहीं, इसे रखो',
          allowOutsideClick: false,
        }).then((result) => {
          //pfileInput.target.value = '';
          return false;
        });
        //pfileInput.target.value = '';
        return false;
      }
      const preader = new FileReader();
      preader.onload = (pe: any) => {
        const pimage = new Image();
        pimage.src = pe.target.result;
        // 
        // 
        // 
        if (docType == 1) {
          this.juristicPersonProfileModel.documentPath = (pe.target.result).split(',')[1];
          this.juristicPersonProfileModel.documentName = pfileInput.target.files[0].name;
        } else if (docType == 2) {
          this.juristicPersonProfileModel.undertakingDocumentPath = (pe.target.result).split(',')[1];
          this.juristicPersonProfileModel.undertakingDocumentName = pfileInput.target.files[0].name;
        }

        // 
      };
      //this.profileImage.hpf = pfileInput.target.files[0];
      preader.readAsDataURL(pfileInput.target.files[0]);
    }
  }

  // Save Juristic Person Profile Data
  onSubmitJuristicDetail(form: any) {
    // this.ngxService.start();

    let err = "";

    if (this.juristicPersonProfileModel.documentPath == '' || this.juristicPersonProfileModel.documentPath == null) {
      err = 'citizen-profile-details.citizen-juristic-service-details.upload-document-authorization';
      this.alertmessage.errorAlertMessage(err, 'required');

    } 
    // else if (this.juristicPersonProfileModel.undertakingDocumentPath == '' || this.juristicPersonProfileModel.undertakingDocumentPath == null) {
    //   err = 'citizen-profile-details.citizen-juristic-service-details.upload-document-undertaking';
    //   this.alertmessage.errorAlertMessage(err, 'required');

    // }

    else {

      // Binding GST Related Data
      if (this.gstData != null && this.gstData != undefined) {
        if (this.gstData?.legalName == undefined) {
          this.juristicPersonProfileModel.legalNameOfBusiness = "";
        } else {
          this.juristicPersonProfileModel.legalNameOfBusiness = this.gstData.legalName;
        }
        if (this.gstData?.location == undefined) {
          this.juristicPersonProfileModel.principalPlaceOfBusiness = "";
        } else {
          this.juristicPersonProfileModel.principalPlaceOfBusiness = this.gstData.location;
        }
        if (this.gstData?.streetCode == undefined) {
          this.juristicPersonProfileModel.additionalPlaceOfBusiness = "";
        } else {
          this.juristicPersonProfileModel.additionalPlaceOfBusiness = this.gstData.streetCode;
        }
        if (this.gstData?.stateJurisdiction == undefined) {
          this.juristicPersonProfileModel.stateJurisdiction = "";
        } else {
          this.juristicPersonProfileModel.stateJurisdiction = this.gstData.stateJurisdiction;
        }
        if (this.gstData?.centreJurisdiction == undefined) {
          this.juristicPersonProfileModel.centreJurisdiction = "";
        } else {
          this.juristicPersonProfileModel.centreJurisdiction = this.gstData.centreJurisdiction;
        }
        if (this.gstData?.registrationDate == undefined) {
          this.juristicPersonProfileModel.dateOfRegistration = "";
        } else {
          this.juristicPersonProfileModel.dateOfRegistration = this.gstData.registrationDate;
        }
        if (this.gstData?.constitutionOfBusiness == undefined) {
          this.juristicPersonProfileModel.constitutionOfBusiness = "";
        } else {
          this.juristicPersonProfileModel.constitutionOfBusiness = this.gstData.constitutionOfBusiness;
        }
        if (this.gstData?.taxPayerType == undefined) {
          this.juristicPersonProfileModel.taxpayerType = "";
        } else {
          this.juristicPersonProfileModel.taxpayerType = this.gstData.taxPayerType;
        }
        if (this.gstData?.status == undefined) {
          this.juristicPersonProfileModel.gstinStatus = "";
        } else {
          this.juristicPersonProfileModel.gstinStatus = this.gstData.status;
        }
      } else if (this.cinData != null && this.cinData != undefined) {
        // Binding CIN Related Data
        if (this.cinData?.companyName == undefined) {
          this.juristicPersonProfileModel.legalNameOfBusiness = "";
        } else {
          this.juristicPersonProfileModel.legalNameOfBusiness = this.cinData.companyName;
        }
        if (this.cinData?.rocCode == undefined) {
          this.juristicPersonProfileModel.rocCode = "";
        } else {
          this.juristicPersonProfileModel.rocCode = this.cinData.rocCode;
        }
        // if (this.cinData?.cin == undefined) {
        //   this.juristicPersonProfileModel.companyIdNo = "";
        // } else {
        //   this.juristicPersonProfileModel.companyIdNo = this.cinData.cin;
        // }
        if (this.cinData?.companyName == undefined) {
          this.juristicPersonProfileModel.companyName = "";
        } else {
          this.juristicPersonProfileModel.companyName = this.cinData.companyName;
        }
        if (this.cinData?.companyStatus == undefined) {
          this.juristicPersonProfileModel.companyStatus = "";
        } else {
          this.juristicPersonProfileModel.companyStatus = this.cinData.companyStatus;
        }
        // if (this.cinData?.email == undefined) {
        //   this.juristicPersonProfileModel.email = "";
        // } else {
        //   this.juristicPersonProfileModel.email = this.cinData.email;
        // }
        if (this.cinData?.incorporationDate == undefined) {
          this.juristicPersonProfileModel.dateOfIncorp = "";
        } else {
          this.juristicPersonProfileModel.dateOfIncorp = this.cinData.incorporationDate;
        }
        if (this.cinData?.registeredAddress == undefined) {
          this.juristicPersonProfileModel.registeredAddress = "";
        } else {
          this.juristicPersonProfileModel.registeredAddress = this.cinData.registeredAddress;
        }
        this.userProfileModel.juristicPerson = this.juristicPersonProfileModel;
      } else {
        if (this.panData?.nameonCard == undefined) {
          this.juristicPersonProfileModel.legalNameOfBusiness = "";
        } else {
          this.juristicPersonProfileModel.legalNameOfBusiness = this.panData.nameonCard;
        }
        this.userProfileModel.juristicPerson = this.juristicPersonProfileModel;
      }


      this.alertmessage.successAlertMessage(this.translate.instant('details-saved'));
      this.step3clear = true;
      this.gotostep4();
    }

    // this.userService.saveJuristicPersonDetail(this.userProfileModel).subscribe(res => {
    //   this.ngxService.stop();
    //   
    //   
    //   if (res.responseStatus == 'true') {
    //     this.alertmessage.successAlertMessage(res.responseMessage);
    //   } else {
    //     this.alertmessage.errorAlertMessage(res.responseMessage);
    //   }
    // })
  }

  demographicDetailsSaveOut(event: any) {
    // 
    if (event != null) {
      if (event.responseStatus == "true") {
        this.alertmessage.successAlertMessage(event);
        setTimeout(() => {
          this.router.navigate(['']);
        }, 2000)
      } else {
        this.alertmessage.errorAlertMessage(event);
      }
    }

  }

  getAllLegalEntityTypes() {
    this.ngxService.start();
    this.common.getAllLegalEntityTypes().subscribe(res => {
      this.ngxService.stop();
      if (res.responseStatus == 'true') {
        this.legalEntityTypesList = res.responseData;
        if (this.eRegPartyModel != null && this.eRegPartyModel.applicantType != null && this.eRegPartyModel.applicantType != undefined) {
          this.legalEntityTypesList = this.legalEntityTypesList.filter(x => x.id > 3);
          console.log("legalEntityTypesList", this.legalEntityTypesList.filter(x => x.id > 3));
        } else {
          this.legalEntityTypesList = res.responseData;
          console.log("legalEntityTypesList", this.legalEntityTypesList);
        }
        //let firstPartyTypePropList = this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.filter(x => x.partySubTypeId == 1);
      } else {
        this.alertmessage.errorAlertMessage(res);

      }
    });

  }
  onChangeLegalEntityType(legalEntityTypeId: any) {

    if (legalEntityTypeId == 1) {
      this.getBankMasterList();
      this.bankDiv = true;
      this.depDiv = false;
    } else if (legalEntityTypeId == 16) {
      this.getDepartment();
      this.depDiv = true;
      this.bankDiv = false;
    } else {
      this.bankDiv = false;
      this.depDiv = false;
    }
  }

  getBankMasterList() {

    this.eRegService.getBankMasterList().subscribe((res: any) => {
      if (res.responseStatus == "true") {
        res.responseData.forEach((e: any) => {
          this.bankMasterList.push(e);

        });
        let mstBankMasterBean = new MstBankMasterBean();
        mstBankMasterBean.id = 0;
        mstBankMasterBean.bankName = 'Other Bank';
        mstBankMasterBean.bankNameHi = 'अन्य बैंक';
        this.bankMasterList.push(mstBankMasterBean);
      } else {
        //this.alertmessage.errorAlertMessage(res);
      }
    });
  }

  getDepartment() {
    this.common.get_all_departmets().subscribe((result) => {
      this.allDepartmentList = result.responseData;
      this.departmentList = this.allDepartmentList.filter(x=>x.parentId=='0');

      // let dept:Department = new Department();
      // dept.id=99998;
      // dept.departmentNameEn='Other Department';
      // dept.departmentNameHi='अन्य विभाग';
      // this.departmentList.push(dept);
    });
    // console.log('departmentList: ', this.departmentList);
  }

  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = /^[a-zA-Z]$/;
    if (!allowedKeys.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
  pdfView() {

    var url = this.settings.igrs_gateway_url + "common/downloadLegalPersonAuthLetter";
    window.open(url, '_blank');

  }
}
export class IgrsLegalEntityTypeBean {
  id: number;
  legalEntityNameEn: string;
  legalEntityNameHi: string;
  status: number;

}

<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2> 
                     {{'detailsPages.stampDutyRegis' | translate}}
                </h2>
                  <button mat-button class="btn btn-primary" id="sticky_Div" routerLink="/duty-calculation">
                     {{'detailsPages.processForstampDutyRegis' | translate}}
                </button> 
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-md-6">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">What is a Stamp Duty and Registration Fee ?</div>
                                        <div *ngIf="lan == 1">स्टाम्प ड्यूटी और पंजीकरण शुल्क क्या है ?</div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Stamp Duty is a tax imposed by governments on certain documents,
                                            transactions, and instruments, typically related to the transfer of assets
                                            or property. It is calculated based on the value of the transaction or the
                                            property involved.
                                        </p>
                                        <p> Registration Fee, on the other hand, is a fee paid to register a document or
                                            transaction with a government authority, such as a land registry or a
                                            business registrar. This fee is separate from Stamp Duty and is often a
                                            fixed amount or a percentage of the transaction value
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            स्टाम्प ड्यूटी दस्तावेजों, लेनदेन और इंस्ट्रूमेंट्स पर राज्य सरकार द्वारा
                                            लगाया जाने वाला कर है, जो आमतौर पर संपत्ति या संपत्ति के हस्तांतरण से
                                            संबंधित है। इसकी गणना लेनदेन के मूल्य या शामिल संपत्ति के आधार पर की जाती
                                            है।
                                        </p>
                                        <p>
                                            दूसरी ओर, पंजीकरण शुल्क, किसी सरकारी प्राधिकरण, जैसे भूमि रजिस्ट्री या
                                            व्यवसाय रजिस्ट्रार के साथ किसी दस्तावेज़ या लेनदेन को पंजीकृत करने के लिए
                                            भुगतान किया जाने वाला शुल्क है। यह शुल्क स्टाम्प ड्यूटी से अलग है और अक्सर
                                            एक निश्चित राशि या लेनदेन मूल्य का एक प्रतिशत होता है
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Type of Stamp Duties ?</div>
                                        <div *ngIf="lan == 1">
                                            स्टाम्प ड्यूटी का प्रकार ?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            There are following four types of Stamp Duties –
                                        </p>
                                        <ol>
                                            <li>
                                                Principal Stamp Duty - The Principal Stamp Duty is a term used to
                                                describe the main or primary Stamp Duty applicable in a particular
                                                jurisdiction or government. It is the basic rate of Stamp Duty that is
                                                levied on various types of documents and transactions as determined by
                                                the government.
                                            </li>
                                            <li>
                                                Municipal Duty - The municipal duty refer to the taxes or fees levied by
                                                municipal bodies or local authorities. The exact rates and specifics of
                                                Municipal duty would depend on the nature of the transaction or document
                                                being registered
                                            </li>
                                            <li>
                                                Janpad Duty - The Janpad duty refer to the taxes or fees levied by the
                                                Janpad Panchayat (a local government body at the block level). The exact
                                                rates and specifics of Janpad duty would depend on the nature of the
                                                transaction or document being registered
                                            </li>
                                            <li>
                                                Upkar - The Upkar refer to the taxes or fees levied by
                                                government. The exact rates and specifics of Upkar would depend on
                                                the nature of the transaction or document being registered
                                            </li>
                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            स्टाम्प शुल्क निम्नलिखित चार प्रकार के होते हैं -
                                        </p>
                                        <ol>
                                            <li>
                                                प्रिंसिपल स्टाम्प ड्यूटी - प्रिंसिपल स्टाम्प ड्यूटी एक शब्द है जिसका
                                                उपयोग किसी विशेष क्षेत्राधिकार या सरकार में लागू मुख्य या प्राथमिक
                                                स्टाम्प ड्यूटी का वर्णन करने के लिए किया जाता है। यह स्टाम्प ड्यूटी की
                                                मूल दर है जो सरकार द्वारा निर्धारित विभिन्न प्रकार के दस्तावेजों और
                                                लेनदेन पर लगाई जाती है।
                                            </li>
                                            <li>
                                                नगरपालिका शुल्क - नगरपालिका शुल्क का तात्पर्य नगर निकायों या स्थानीय
                                                अधिकारियों द्वारा लगाए गए करों या शुल्क से है। म्यूनिसिपल शुल्क की सटीक
                                                दरें और विशिष्टताएं पंजीकृत होने वाले लेनदेन या दस्तावेज़ की प्रकृति पर
                                                निर्भर करेंगी
                                            </li>
                                            <li>
                                                जनपद शुल्क - जनपद शुल्क का तात्पर्य जनपद पंचायत (ब्लॉक स्तर पर एक
                                                स्थानीय सरकारी निकाय) द्वारा लगाए गए करों या शुल्क से है। जनपद शुल्क की
                                                सटीक दरें और विशिष्टताएँ पंजीकृत होने वाले लेनदेन या दस्तावेज़ की
                                                प्रकृति पर निर्भर करेंगी
                                            </li>
                                            <li>
                                                उपकर - उपकर सरकार द्वारा लगाए गए करों या शुल्क को संदर्भित
                                                करता है। उपकर की सटीक दरें और विशिष्टताएं पंजीकृत होने वाले लेनदेन
                                                या दस्तावेज़ की प्रकृति पर निर्भर करेंगी
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">What is the Stamp Duty and Registration Fee Exemption?
                                        </div>
                                        <div *ngIf="lan == 1">स्टाम्प ड्यूटी और पंजीकरण शुल्क छूट क्या है ?</div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Stamp Duty and Registration Fee exemptions are provisions that allow certain
                                            individuals or entities to be exempt from paying Stamp Duty and Registration
                                            Fees on specific documents or transactions. These exemptions are usually
                                            granted by governments to promote certain social objectives, economic
                                            activities, or to provide relief in certain situations.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            स्टाम्प ड्यूटी और पंजीकरण शुल्क छूट ऐसे प्रावधान हैं जो कुछ व्यक्तियों या
                                            संस्थाओं को विशिष्ट दस्तावेजों या लेनदेन पर स्टाम्प ड्यूटी और पंजीकरण शुल्क
                                            का भुगतान करने से छूट देते हैं। ये छूट आमतौर पर सरकारों द्वारा कुछ सामाजिक
                                            उद्देश्यों, आर्थिक गतिविधियों को बढ़ावा देने या कुछ स्थितियों में राहत
                                            प्रदान करने के लिए दी जाती हैं।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Who can calculate the Stamp Duty and Registration Fee ?
                                        </div>
                                        <div *ngIf="lan == 1">स्टाम्प ड्यूटी और पंजीकरण शुल्क की गणना कौन कर सकता है ?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            In Sampada 2.0, both registered and unregistered users can calculate the
                                            Stamp Duty and Registration Fee through the portal.
                                        </p>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            सम्पदा 2.0 में, पंजीकृत और अपंजीकृत दोनों प्रकार के उपयोगकर्ता पोर्टल के
                                            माध्यम से स्टाम्प ड्यूटी और पंजीकरण शुल्क की गणना कर सकते हैं।
                                        </p>

                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Pre-requisite to calculate Stamp Duty and Registraion Fee.
                                        </div>
                                        <div *ngIf="lan == 1">
                                            स्टाम्प ड्यूटी और पंजीकरण शुल्क की गणना के लिए पूर्व-आवश्यकता।
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>To calculate Stamp Duty and Registration Fee below are the prerequisites:
                                        </p>
                                        <ol>
                                            <li>
                                                The person should possess knowledge of the Deed and Document/Instrument
                                                Name for which they intend to calculate Stamp Duty and Registration fees
                                            </li>
                                            <li>
                                                The system requires specific details such as
                                                consideration/secure/premium/loan amount, property addition, stamp and
                                                registration fee exemptions, etc., which are based on the selected
                                                document / instrument.
                                            </li>

                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>स्टाम्प ड्यूटी और पंजीकरण शुल्क की गणना करने के लिए नीचे दी गई शर्तें हैं:
                                        </p>
                                        <ol>
                                            <li> व्यक्ति को उस विलेख और दस्तावेज़ / इंस्ट्रूमेंट के नाम का ज्ञान होना
                                                चाहिए जिसके लिए वे स्टाम्प ड्यूटी और पंजीकरण शुल्क की गणना करना चाहते
                                                हैं।
                                            </li>
                                            <li>
                                                चयनित दस्तावेज़ / इंस्ट्रूमेंट के आधार पर सिस्टम को विशिष्ट विवरणों की
                                                आवश्यकता होती है जैसे की कन्सिडरेसन / सिक्योर / प्रीमियम / लोन राशि,
                                                संपत्ति विवरण, स्टांप ड्यूटी और पंजीकरण शुल्क छूट इत्यादि।
                                            </li>

                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>



                            <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Process of Stamp Duty and Registration Fee Calculation ?
                                        </div>
                                        <div *ngIf="lan == 1">स्टाम्प ड्यूटी और पंजीकरण शुल्क गणना की प्रक्रिया ?</div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>To Stamp Duty and Registration Fee calculation follow below steps :</p>
                                        <ul>
                                            <li>
                                                Access the SAMPADA 2.0 portal.
                                            </li>
                                            <li>
                                                Click the Duty Calculation button
                                            </li>
                                            <li>
                                                Select the deed and document / instrument
                                            </li>
                                            <li>
                                                Click on the Submit button
                                            </li>
                                            <li>
                                                Enter required details such as consideration / secure / premium / loan
                                                amount, add property, etc., based on the selected document / instrument.
                                            </li>
                                            <li>
                                                Avail the stamp and registration fee exemptions (If user is applicable
                                                for this)
                                            </li>
                                            <li>
                                                Click on the 'Duty Calculate' button
                                            </li>
                                            <li>
                                                System will display the Stamp Duty and Registration Fee
                                            </li>
                                            <li>
                                                Users can also print the calculated details of Stamp Duty and
                                                Registration Fee
                                            </li>
                                        </ul>

                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            स्टाम्प ड्यूटी और पंजीकरण शुल्क की गणना के लिए नीचे दिए गए चरणों का पालन
                                            करें:
                                        </p>
                                        <ul>
                                            <li>
                                                संपदा 2.0 पोर्टल को ओपन करे।
                                            </li>
                                            <li>
                                                ड्यूटी कैलकुलेशन बटन पर क्लिक करे।
                                            </li>
                                            <li>
                                                डीड और दस्तावेज़/इंस्ट्रूमेंट का चयन करे।
                                            </li>
                                            <li>
                                                सबमिट बटन पर क्लिक करे।
                                            </li>
                                            <li>
                                                चयनित दस्तावेज़ / उपकरण के आधार पर आवश्यक विवरण जैसे कन्सिडरेसन /
                                                सिक्योर / प्रीमियम / लोन राशि, संपत्ति जोड़ें आदि दर्ज करें।
                                            </li>
                                            <li>
                                                स्टांप और पंजीकरण शुल्क छूट का लाभ उठाएं (यदि उपयोगकर्ता इसके लिए
                                                उपयुक्त है तो)
                                            </li>
                                            <li>
                                                ड्यूटी कैलकुलेट बटन पर क्लिक करे।
                                            </li>
                                            <li>
                                                सिस्टम स्टाम्प ड्यूटी और पंजीकरण शुल्क प्रदर्शित करेगा।
                                            </li>
                                            <li>
                                                उपयोगकर्ता स्टांप ड्यूटी और पंजीकरण शुल्क की गणना की जानकारी को प्रिंट
                                                भी कर सकता है।
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                            </mat-expansion-panel>


                        </mat-accordion>
                    </div>
                    <div class="col-md-6">
                        <div class="process_img">
                            <img src="assets/images/DutyCalculationEnglish.png" alt="PaymentWallet" style="width: 100%"
                                *ngIf="lan == 0" />
                            <img src="assets/images/DutyCalculationHindi.png" alt="PaymentWallet" style="width: 100%"
                                *ngIf="lan == 1" />
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from '../../helper-lib/services/common/translateHE.service';

@Component({
  selector: 'lib-alertmessage',
  templateUrl: './alertmessage.component.html',
  styleUrls: ['./alertmessage.component.css']
})
export class AlertmessageComponent implements OnInit {

  errorMsg: string = '';
  successMsg: string = '';
  errorMsgEn: string = '';
  successMsgEn: string = '';
  errorMsgHi: string = '';
  successMsgHi: string = '';
  errorMsgop: string = '';
  successMsgop: string = '';
  errorMsgEnop: string = '';
  successMsgEnop: string = '';
  errorMsgHiop: string = '';
  lan: number;
  warnMsgEnop:string='';
  warnMsgHiop:string='';
  warnMsgEn:string='';
  warnMsgHi:string='';
  warnMsg:string='';
  displayTime:number=3000
  constructor(private translateService: TranslateHEService, private translate: TranslateService) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
  }

  ngOnInit(): void {
  }

  successAlertMessage(msg: any,displayTime?:number) {
    if (msg.responseMessage != undefined) {
      this.successMsg = "Success! "+msg.responseMessage;
      this.successMsgEn = "Success! "+msg.responseMessageEn;
      this.successMsgHi = "सफलता! "+msg.responseMessageHi;
    } else {
      this.successMsg = "Success! "+msg;
      this.successMsgEn = "Success! "+msg;
      this.successMsgHi = "सफलता! "+msg;
    }
    this.alertMessageStop(7000);
  }

  errorAlertMessage(msg: any, options?: any,displayTime?:number) {
    
   // console.log(msg)
    if (msg.responseMessage != undefined && msg.responseMessage != null) {
      // 
      this.errorMsg = "Error! "+msg.responseMessage;
      this.errorMsgEn = "Error! "+msg.responseMessageEn;
      this.errorMsgHi = "त्रुटि! "+msg.responseMessageHi;
    } else {
      // 
      // if(options!=undefined && options!=""){
      // 
      //console.log("error msg : ",msg)
      this.errorMsg = "Error! "+this.translate.instant(msg);
      if(options!=undefined && options!=""){
        this.errorMsg+=" ."+this.translate.instant(options);
      }
      // 
      this.translate.getTranslation('en').subscribe(values => {
        let msgs = msg.split('.');
        if (msgs != null && msgs.length > 0) {
          if (msgs[2] != null) {
            this.errorMsgEn = "Error! "+values[msgs[0]][msgs[1]][msgs[2]];
          } else if (msgs[1] != null) {
            this.errorMsgEn = "Error! "+values[msgs[0]][msgs[1]];
          } else if (msgs[0] != null) {
            this.errorMsgEn = "Error! "+values[msgs[0]];
          } else {
            this.errorMsgEn = "Error! "+values[msg];
          }
        } else {
          this.errorMsgEn = "Error! "+this.errorMsg;
        }
      });
      this.translate.getTranslation('hi').subscribe(values => {
        let msgs = msg.split('.');
        if (msgs != null && msgs.length > 0) {
          if (msgs[2] != null) {
            this.errorMsgHi = "त्रुटि! "+values[msgs[0]][msgs[1]][msgs[2]];
          } else if (msgs[1] != null) {
            this.errorMsgHi = "त्रुटि! "+values[msgs[0]][msgs[1]];
          } else if (msgs[0] != null) {
            this.errorMsgHi = "त्रुटि! "+values[msgs[0]];
          } else {
            this.errorMsgHi = "त्रुटि! "+values[msg];
          }
        } else {
          this.errorMsgHi = "त्रुटि! "+this.errorMsg;
        }
      });
      if(options!=undefined && options!=""){
        this.translate.getTranslation('en').subscribe(values => {
          let msgs = options.split('.');
          if (msgs != null && msgs.length > 0) {
            if (msgs[2] != null) {
              this.errorMsgEnop = ". "+values[msgs[0]][msgs[1]][msgs[2]];
            } else if (msgs[1] != null) {
              this.errorMsgEnop = ". "+values[msgs[0]][msgs[1]];
            } else if (msgs[0] != null) {
              this.errorMsgEnop = ". "+values[msgs[0]];
            } else {
              this.errorMsgEnop = ". "+values[msg];
            }
            this.errorMsgEn+=this.errorMsgEnop;
          } else {
            // this.errorMsgEn = "Error! "+this.errorMsg;
          }
        });
        this.translate.getTranslation('hi').subscribe(values => {
          let msgs = options.split('.');
          if (msgs != null && msgs.length > 0) {
            if (msgs[2] != null) {
              this.errorMsgHiop = ". "+values[msgs[0]][msgs[1]][msgs[2]];
            } else if (msgs[1] != null) {
              this.errorMsgHiop = ". "+values[msgs[0]][msgs[1]];
            } else if (msgs[0] != null) {
              this.errorMsgHiop = ". "+values[msgs[0]];
            } else {
              this.errorMsgHiop = ". "+values[msg];
            }
            this.errorMsgHi+=this.errorMsgHiop;
          } else {
            // this.errorMsgHi = "त्रुटि! "+this.errorMsg;
          }
        });
      }

      
      //  =this.translate.getParsedResult(this.translate.translations['EN'], msg);
      // this.errorMsgHi=this.translate.getParsedResult(this.translate.translations['HI'], msg);
      // }else{
      // 
      // this.errorMsg=msg;
      // this.errorMsgEn=msg;
      // this.errorMsgHi=msg;
      // }
    }
    this.alertMessageStop(displayTime);
  }

  warnAlertMessage(msg: any, options?: any,displayTime?:number) {
    
    // console.log(msg)
     if (msg.responseMessage != undefined && msg.responseMessage != null) {
       // 
       this.warnMsg = "Warning! "+msg.responseMessage;
       this.warnMsgEn = "Warning! "+msg.responseMessageEn;
       this.warnMsgHi = "चेतावनी! "+msg.responseMessageHi;
     } else {
       // 
       // if(options!=undefined && options!=""){
       // 
       //console.log("error msg : ",msg)
       this.warnMsg = "Warning! "+this.translate.instant(msg);
       if(options!=undefined && options!=""){
         this.warnMsg+=" ."+this.translate.instant(options);
       }
       // 
       this.translate.getTranslation('en').subscribe(values => {
         let msgs = msg.split('.');
         if (msgs != null && msgs.length > 0) {
           if (msgs[2] != null) {
             this.warnMsgEn = "Warning! "+values[msgs[0]][msgs[1]][msgs[2]];
           } else if (msgs[1] != null) {
             this.warnMsgEn = "Warning! "+values[msgs[0]][msgs[1]];
           } else if (msgs[0] != null) {
             this.warnMsgEn = "Warning! "+values[msgs[0]];
           } else {
             this.warnMsgEn = "Warning! "+values[msg];
           }
         } else {
           this.warnMsgEn = "Warning! "+this.warnMsg;
         }
       });
       this.translate.getTranslation('hi').subscribe(values => {
         let msgs = msg.split('.');
         if (msgs != null && msgs.length > 0) {
           if (msgs[2] != null) {
             this.warnMsgHi = "चेतावनी! "+values[msgs[0]][msgs[1]][msgs[2]];
           } else if (msgs[1] != null) {
             this.warnMsgHi = "चेतावनी! "+values[msgs[0]][msgs[1]];
           } else if (msgs[0] != null) {
             this.warnMsgHi = "चेतावनी! "+values[msgs[0]];
           } else {
             this.warnMsgHi = "चेतावनी! "+values[msg];
           }
         } else {
           this.warnMsgHi = "चेतावनी! "+this.warnMsg;
         }
       });
       if(options!=undefined && options!=""){
         this.translate.getTranslation('en').subscribe(values => {
           let msgs = options.split('.');
           if (msgs != null && msgs.length > 0) {
             if (msgs[2] != null) {
               this.warnMsgEnop = ". "+values[msgs[0]][msgs[1]][msgs[2]];
             } else if (msgs[1] != null) {
               this.warnMsgEnop = ". "+values[msgs[0]][msgs[1]];
             } else if (msgs[0] != null) {
               this.warnMsgEnop = ". "+values[msgs[0]];
             } else {
               this.warnMsgEnop = ". "+values[msg];
             }
             this.warnMsgEn+=this.warnMsgEnop;
           } else {
             // this.warnMsgEn = "Error! "+this.warnMsg;
           }
         });
         this.translate.getTranslation('hi').subscribe(values => {
           let msgs = options.split('.');
           if (msgs != null && msgs.length > 0) {
             if (msgs[2] != null) {
               this.warnMsgHiop = ". "+values[msgs[0]][msgs[1]][msgs[2]];
             } else if (msgs[1] != null) {
               this.warnMsgHiop = ". "+values[msgs[0]][msgs[1]];
             } else if (msgs[0] != null) {
               this.warnMsgHiop = ". "+values[msgs[0]];
             } else {
               this.warnMsgHiop = ". "+values[msg];
             }
             this.warnMsgHi+=this.warnMsgHiop;
           } else {
             // this.warnMsgHi = "त्रुटि! "+this.warnMsg;
           }
         });
       }
 
       
       //  =this.translate.getParsedResult(this.translate.translations['EN'], msg);
       // this.warnMsgHi=this.translate.getParsedResult(this.translate.translations['HI'], msg);
       // }else{
       // 
       // this.warnMsg=msg;
       // this.warnMsgEn=msg;
       // this.warnMsgHi=msg;
       // }
     }
     this.alertMessageStop(displayTime);
   }

  alertMessageStop(displayTime:number) {
    
    this.displayTime=(displayTime!=null&&displayTime!=undefined&&displayTime!=0)?displayTime:7000
   
    setTimeout(() => { this.successMsg = ''; this.errorMsg = ''; this.successMsgEn = ''; this.errorMsgEn = ''; this.successMsgHi = ''; this.errorMsgHi = ''; this.warnMsg = ''; this.warnMsgEn = ''; this.warnMsgHi = '';}, this.displayTime)
  }
  

  
}

import { HttpHeaders, HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Captcha, Citizen } from 'projects/common-lib/src/lib/models';
import { Subscription, timer } from 'rxjs';
//import { DefaultServiceService } from 'src/app/front-end/default/default-service.service';
import Swal from 'sweetalert2';
import { DefaultServiceService } from '../../../default/default-service.service';
import { EstampService } from '../../../service/estamp.service';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { AddEstamp, IgrsEstampDetailDisplay } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';

@Component({
  selector: 'app-estamp-login',
  templateUrl: './estamp-login.component.html',
  styleUrls: ['./estamp-login.component.scss']
})
export class EstampLoginComponent implements OnInit {
  redType: any[] = [];
  type: any;
  estampId: any;
  inputMobileNo: any;//this is direct pass by ereg party esign window
  inputEstampId: any;//this is direct pass by ereg party esign window
  isReadOnly: boolean = false;
  eregFlag: any;
  linkDirectionFlag: any;
  lan: number;
  partyId:any;

  paramArray: any[] = [];

  igrsEregEstampBean: AddEstamp = new AddEstamp();

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  citizen: Citizen = new Citizen();

  userList: CitizenUserProfileDetails[] = [];
  isUser: boolean = false;
  selectedUser: number;

  isSampadaRegisteredUser: boolean = false;

  constructor(private router: Router, private translateService: TranslateHEService, private translate: TranslateService,
    private ngxService: NgxUiLoaderService, private citizenAuthService: CitizenAuthenticationService,
    private translateServiceIns: TranslateService,
    private _AESEncryptDecryptService: AESEncryptDecryptService, private citizenAuthenticationService: CitizenAuthenticationService,
    private estampService: EstampService, private route: ActivatedRoute, private defaultServiceService: DefaultServiceService) {
    //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg == 1 ? 'hi' : 'en')
    })
    this.redType = this.route.snapshot.url.toString().split(",");
    this.type = this.route.snapshot.url;
    this.estampId = this.redType[1];
    console.log("this.redType" + JSON.stringify(this.route.snapshot.url))
    // if(router.getCurrentNavigation().extras.state.data[0] != null && router.getCurrentNavigation().extras.state.data[0] != undefined)
    // this.inputMobileNo = router.getCurrentNavigation().extras.state.data[0];
    // if(router.getCurrentNavigation().extras.state.data[1] != null && router.getCurrentNavigation().extras.state.data[1] != undefined)
    // this.inputEstampId = router.getCurrentNavigation().extras.state.data[1];

    this.route.queryParams.subscribe(param => {
      if (param['inputMobileNo'] != undefined && param['inputMobileNo'] != null) {
        this.inputMobileNo = param['inputMobileNo'];
        this.mobileNo = this.inputMobileNo;
      }
      if (param['inputEstampId'] != undefined && param['inputEstampId'] != null) {
        this.inputEstampId = param['inputEstampId'];
      }
    })

    console.log("this.mobileNo::" + this.mobileNo + " this.inputEstampId::" + this.inputEstampId)
  }

  ngOnInit(): void {

    this.getStartupCaptcha();
    // this.defaultServiceService.setDefaultType('estamp-Login');
    const codec = new HttpUrlEncodingCodec();
    console.log("this.redType[0] url::" + this.redType[0])
    console.log("this.redType[1] estampId::" + this.redType[1])
    console.log("this.redType[2] mobileNo::" + this.redType[2])
    console.log("this.redType[3] partyId::" + this.redType[3])
    console.log("this.redType[4] eregFlag::" + this.redType[4])
    console.log("this.redType[5] linkDirectionFlag::" + this.redType[5])

    console.log("decpt param ::" + this._AESEncryptDecryptService.decryptInAngular(codec.decodeValue(this.redType[1])));

    let param =  this._AESEncryptDecryptService.decryptInAngular(codec.decodeValue(this.redType[1]));
    if(param != null && param != undefined){
       this.paramArray =  param.split("&");
      console.log("paramArray length ::"+this.paramArray.length +" paramArray::"+JSON.stringify(this.paramArray));
      //["9123","9977905818","12778","0"]
    }

   
    let eregFlag = this.paramArray[3];
    let linkDirectionFlag = this.paramArray[4];
    let estampId: any;
    let mobileNo: any;
     this.partyId = this.paramArray[2];
    // if (userId != null && userId != undefined) {
    //   this.selectedUser = userId;
    // }

    let headers = new HttpHeaders()
    headers=headers.append('Access-Control-Allow-Origin', '*')
    if (!headers.has('content-type')) {
      headers=headers.append('content-type','application/json')
    }
    if (!headers.has('User-Agent')) {
      headers=headers.append('User-Agent','true')
    }

    if (eregFlag != null && eregFlag != undefined && (eregFlag == 0 || eregFlag == 1)) {
      // console.log("dec estampId ::"+this._AESEncryptDecryptService.decryptInAngular(this.redType[1].replaceAll("$", "==")));
      // console.log("dec mobileNo ::"+this._AESEncryptDecryptService.decryptInAngular(this.redType[2].replaceAll("$", "==")));

      //   estampId = this._AESEncryptDecryptService.decryptInAngular(this.redType[1].replaceAll("$", "=="));
      //   mobileNo = this._AESEncryptDecryptService.decryptInAngular(this.redType[2].replaceAll("$", "=="));

      // console.log("dec estampId ::" + this._AESEncryptDecryptService.decryptInAngular(codec.decodeValue(this.redType[1])));
      // console.log("dec mobileNo ::" + this._AESEncryptDecryptService.decryptInAngular(codec.decodeValue(this.redType[2])));

      // estampId = this._AESEncryptDecryptService.decryptInAngular(codec.decodeValue(this.redType[1]));
      // mobileNo = this._AESEncryptDecryptService.decryptInAngular(codec.decodeValue(this.redType[2]));

      estampId =this.paramArray[0];
      mobileNo = this.paramArray[1];

      this.mobileNo = mobileNo;
      this.inputMobileNo = mobileNo;
      this.inputEstampId = estampId;
      this.eregFlag = eregFlag;
      this.linkDirectionFlag = linkDirectionFlag;

      if (this.mobileNo != null && this.mobileNo != undefined)
        this.isReadOnly = true;
     // this.changeContactNo();

      //this.getAllEstampByContactNumber(0,5,mobileNo,estampId);
    }
   // console.log("this.eregFlag::"+this.eregFlag+" this.linkDirectionFlag::"+this.linkDirectionFlag+" this.inputEstampId::"+this.inputEstampId)
    if ((this.eregFlag != null && this.eregFlag != undefined && this.eregFlag == 0) && (this.linkDirectionFlag != null && this.linkDirectionFlag != undefined && this.linkDirectionFlag == 0) ){
      this.getEstampDetails(this.inputEstampId);
    }

    if (this.redType[1] != null && this.redType[1] != undefined) {

      let msg: any;
      msg = codec.decodeValue(this.redType[2]);
      let msgDeCr = this._AESEncryptDecryptService.decryptInAngular(msg.replaceAll("~", "/"));
      //let msgDeCr= this._AESEncryptDecryptService.decryptInAngular(this.redType[2]);
      console.log("msgEnCr ::" + msgDeCr)


      if (this.redType[1] == 1) {
        Swal.fire({
          title: msgDeCr,
          icon: "error",
          confirmButtonText: 'OK',
        }).then((result) => {
          this.router.navigate([`estamp-Login`]);
        });
      } else if (this.redType[1] == 0) {

        Swal.fire({
          title: `${this.lan == 0 ? "ESign Failed, Please Try Again Or Later" : "ई-साइन विफल, कृपया दोबारा या बाद में प्रयास करें"}`,
          // title: 'ESign Failed, Please Try Again Or Later',
          icon: "error",
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
        }).then((result) => {
          this.router.navigate([`estamp-Login`]);
        });

      }
    }

    this.route.queryParams.subscribe(param => {
      if (param['status'] != undefined && param['status'] == 1) {


        //Swal.fire(param['message'])

        Swal.fire({
          title: param['msg'],
          icon: "success",
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
        }).then((result) => {
          this.router.navigate([`estamp-Login`]);
        });
      } else if (param['status'] != undefined && param['status'] == 0) {
        //Swal.fire(param['message'])
        Swal.fire({
          title: param['msg'],
          icon: "error",
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
        }).then((result) => {
          this.router.navigate([`estamp-Login`]);
        });
      }
    })


  }

  getEstampDetails(estampId:any) {
    this.ngxService.start();
    this.estampService.getEstampDetailsById(Number(estampId)).subscribe(res => {
      if (res.responseStatus == 'true') {
        this.ngxService.stop();
        this.igrsEregEstampBean = res.responseData
        // *****start check validation ***********
        if(!(this.igrsEregEstampBean.isFinalDeedGenerated==1)){
          Swal.fire({
            icon: 'warning',
            title: (this.lan==0)?'Final Deed':'अंतिम विलेख',
            text: (this.lan==0)?'Please wait for few seconds as your deed is being generate':'कृपया डीड तैयार होने तक कुछ सेकंड प्रतीक्षा करें',
            confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
        // *****End check validation ***********


      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(res);
      }
    },
      (err) => {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }

  getAllEstampByContactNumber(pageNumber: number, pageSize: number, loginContactNumber: any, inputEstampId: any) {
    this.ngxService.start();

    this.estampService.getEstampListByContactNumber(pageNumber, pageSize, loginContactNumber, inputEstampId, 0, this.partyId, this.selectedUser, "").subscribe(res => {
      this.ngxService.stop();
      if (res.responseStatus == 'true') {
        console.log("res party details list ::" + JSON.stringify(res.responseData[0]));
        if (res.responseData[0].eregFlag != null && res.responseData[0].eregFlag == 1) {
          this.goToPreview(loginContactNumber, res.responseData[0].estampId, res.responseData[0].partyContentStatusId, res.responseData[0].partyType,
            res.responseData[0].partySubType, res.responseData[0].contentStatusId, res.responseData[0].natureInstr, res.responseData[0].eregFlag,
            res.responseData[0].tempEregId, res.responseData[0].partyTypeNameEn, res.responseData[0].partyName, res.responseData[0].deedAcceptLink,res.responseData[0].partyTypeNameHi);
        } else {
          this.goToPreview(loginContactNumber, res.responseData[0].estampId, res.responseData[0].partyContentStatusId, res.responseData[0].partyType,
            res.responseData[0].partySubType, res.responseData[0].contentStatusId, res.responseData[0].natureInstr, res.responseData[0].eregFlag,
            res.responseData[0].estampCode, res.responseData[0].partyTypeNameEn, res.responseData[0].partyName, res.responseData[0].deedAcceptLink,res.responseData[0].partyTypeNameHi);
        }

      }
      else {
        this.ngxService.stop();
        Swal.fire((this.lan == 0) ? "Record Not Found" : "रिकॉर्ड नहीं मिला");
      }
    });
  }

  getAllEstampByContactNumberForEsign(pageNumber: number, pageSize: number, loginContactNumber: any, inputEstampId: any) {
    this.ngxService.start();

    this.estampService.getEstampListByContactNumber(pageNumber, pageSize, loginContactNumber, inputEstampId, 0, this.partyId, this.selectedUser, "").subscribe(res => {
      this.ngxService.stop();
      if (res.responseStatus == 'true') {
        console.log("res party details list ::" + JSON.stringify(res.responseData[0]));
        if (res.responseData[0].eregFlag != null && res.responseData[0].eregFlag == 1) {
          this.viewEregEsign(res.responseData[0].estampId, res.responseData[0].partyContentStatus, res.responseData[0].userId);
        } else {
          this.goToEsign(res.responseData[0].estampId, res.responseData[0].partyContentStatus, res.responseData[0].userId);
        }

      }
      else {
        this.ngxService.stop();
        Swal.fire((this.lan == 0) ? "Record Not Found" : "रिकॉर्ड नहीं मिला");
      }
    });
  }

  goToPreview(loginContactNumber: any, estampId: number, contentStatus: string, partyType: number, partySubType: number, estampContentStatus: number, natureOfInstrument: string, eregFlag: any, code: any, partyTypeNameEn: any, partyName: any, deedAcceptLink: any,partyTypeNameHi:any) {
    this.router.navigate(['estamp-deed-updation'], {
      state: {
        data: [estampId, loginContactNumber, contentStatus, partyType, estampContentStatus, natureOfInstrument, partySubType, eregFlag, code, partyTypeNameEn, partyName, this.selectedUser, deedAcceptLink,partyTypeNameHi,this.partyId]
      }
    });
  }

  mobileNo: any;
  otp: any;

  checkedVal: boolean;
  captchaHandler?: Captcha = {};
  captchaImageBase64Img?: string = "";
  captchaId: any;
  captchaStr: any;
  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec

  otpGenerated: boolean = false;
  otpTouched: boolean = false;

  changeContactNo() {
    this.userList = [];
    this.isUser = false;
    if (this.mobileNo != null || this.mobileNo != undefined || this.mobileNo?.trim() != '') {
      this.ngxService.start();
      let param = { mobileNo: this.mobileNo };
      this.citizenAuthenticationService.getUserListByContactNo(param).subscribe(result => {
        this.ngxService.stop();
        if (result.responseStatus == 'true') {
          this.selectedUser = null;
          if (result.responseData.length > 1) {
            this.isUser = true;
            this.userList = result.responseData;
          } else if (result.responseData.length == 0) {
            this.isUser = false;
            this.alertmessage.errorAlertMessage('office-not-assign');
          } else {
            this.isUser = false;
           // this.selectedUser = result.responseData[0].id;
            this.userList = result.responseData;
          }

        } else {
          this.isUser = false;
        }

      },
        err => {
          this.isUser = false;
          this.ngxService.stop()
          //  Swal.fire("Error", "TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !", "error");
          this.alertmessage.errorAlertMessage('tech_error_msg');
        }
      );
    }
  }

  selectUser() {
    console.log("Select User---------", this.selectedUser)
  }

  generateOtp() {
    let mb: string = this.mobileNo;
    this.otpGenerated = false;
    if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == "") {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    } else if (mb.length != 10) {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }

    let mb1: string = mb.substring(0, 1);
    if (mb1 != '9' && mb1 != '8' && mb1 != '7' && mb1 != '6') {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }
    if (this.captchaStr == null || this.captchaStr == undefined || this.captchaStr == "") {
      this.otpGenerated = false;
      this.alertmessage.errorAlertMessage('mobile-captcha-error');
      return;
    } else {
      this.ngxService.start();
      this.citizen.userId = this.selectedUser
      this.citizen.mobileNo = this.mobileNo;
      //this.citizen.captchaId = this.captchaId;
      //this.citizen.captchaStr = this.captchaStr;
      this.estampService.generateOtpForUser(this.citizen).subscribe(res => {
        this.ngxService.stop();
        if (res && res.responseStatus == 'true') {
          this.otpGenerated = true;
          this.otpTouched = true;
          this.isSampadaRegisteredUser = true;
          // this.counter = 30;
          // this.countDown = timer(0, this.tick).subscribe(() => {
          //   if (this.counter > 0) {
          //     --this.counter;
          //   }
          // });
          this.timer();
          this.alertmessage.successAlertMessage(res);
        } else {
          this.citizen.captchaId = this.captchaId;
          this.citizen.captchaStr = this.captchaStr;
          this.estampService.getOtp(this.citizen).subscribe(res => {
            this.ngxService.stop();
            if (res && res.responseStatus == 'true') {
              this.otpGenerated = true;
              this.otpTouched = true;
              this.isSampadaRegisteredUser = false;
              // this.counter = 30;
              // this.countDown = timer(0, this.tick).subscribe(() => {
              //   if (this.counter > 0) {
              //     --this.counter;
              //   }
              // });
              this.timer();
              this.alertmessage.successAlertMessage(res);
            } else {


              //-----------------------------
              this.otpGenerated = false;
              this.otpTouched = false;
              this.alertmessage.errorAlertMessage(res);
              // this.gotostep1();
              this.ngxService.stop();
            }
          });

          //-----------------------------
          // this.otpGenerated = false;
          // this.otpTouched = false;
          // this.alertmessage.errorAlertMessage(res);
          // this.ngxService.stop();
        }
      });
    }
  }
  display: any;
  public timerInterval: any;
  seconds: number = 0;
  timer() {
    // let minute = 1;
    // let seconds: number = minute * 60;
    this.seconds = 30;
    let textSec: any = '0';
    let statSec: number = 30;

    // const prefix = minute < 10 ? '0' : '';
    const prefix = '0';

    this.timerInterval = setInterval(() => {
      this.seconds--;
      if (statSec != 0) statSec--;
      else statSec = 29;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(this.seconds / 60)}:${textSec}`;

      if (this.seconds == 0) {
        console.log('finished');
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  verifyOtp() {
    if (this.otp == null || this.otp == undefined || this.otp == "") {
      this.alertmessage.errorAlertMessage('otp-error');
    } else {
      this.ngxService.start();

      if (this.isSampadaRegisteredUser) {
        this.estampService.verifyOtpForUser(this.selectedUser, this.otp, this.mobileNo, this.captchaId, this.captchaStr).subscribe(res => {
          this.ngxService.stop();
          if (res && res.responseStatus == 'true') {
            this.afterLoginProcess(res);
          } else {
            this.alertmessage.errorAlertMessage(res);
            // this.step1clear = false;
            this.otp = null;
            this.ngxService.stop();
          }
        });
      } else {
        this.estampService.verifyOtp(this.mobileNo, this.otp).subscribe(res => {
          this.ngxService.stop();
          if (res && res.responseStatus == 'true') {
            this.afterLoginProcess(res);
          } else {
            this.alertmessage.errorAlertMessage(res);
            // this.step1clear = false;
            this.otp = null;
            this.ngxService.stop();
          }
        });
      }
    }
  }

  afterLoginProcess(res: any) {
    console.log("LOGIN RES:" + JSON.stringify(res));
    this.alertmessage.successAlertMessage(res);

    if (this.eregFlag != null && this.eregFlag != undefined && this.eregFlag == 0) { //This condition go for Estamp deed accept/reject
      if (this.linkDirectionFlag != null && this.linkDirectionFlag != undefined && this.linkDirectionFlag == 0) {
        this.getAllEstampByContactNumberForEsign(0, 5, this.inputMobileNo, this.inputEstampId);
      } else {
        this.getAllEstampByContactNumber(0, 5, this.inputMobileNo, this.inputEstampId);
      }
    } else if (this.eregFlag != null && this.eregFlag != undefined && this.eregFlag == 1) { //This condition go for Ereg deed accept/reject
      if (this.linkDirectionFlag != null && this.linkDirectionFlag != undefined && this.linkDirectionFlag == 1) {
        this.getAllEstampByContactNumberForEsign(0, 5, this.inputMobileNo, this.inputEstampId);
      } else {
        this.getAllEstampByContactNumber(0, 5, this.inputMobileNo, this.inputEstampId);
      }

    } else {
      this.router.navigate(['estamp-list'], {
        state: {
          data: this.mobileNo,
          estampId: this.estampId,
          inputMobileNo: this.inputMobileNo,
          inputEstampId: this.inputEstampId,
          userId: this.selectedUser
        }
      });
    }
  }

  viewEregEsign(estampId: number, contentStatus: number, userid: number) {
    let encryptedEregId: any;
    if (estampId != null) {

      this.router.navigate(['EregEsignTemplate'], {
        state: {
          data: [estampId, '1', this.inputMobileNo, contentStatus, userid, encryptedEregId, this.inputEstampId,,this.partyId]
        }
      });
    }
    else {
      alert("something wrong in parameters");
    }
  }


  goToEsign(estampId: number, contentStatus: string, userid: number) {
    if (estampId != null && userid != null) {
      this.router.navigate(['e-sign'], {
        state: {
          data: [estampId, this.inputMobileNo, contentStatus, userid, , 4,this.partyId]
        }
      });
    }
    else {
      alert("something wrong in parameters");
    }
  }

  getStartupCaptcha() {

    this.ngxService.start();
    this.citizenAuthService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id;
      console.log(this.captchaId)
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('captcha-error');
      }
    );
  }

}

export class DutyInputFields {

    constructor(){
        console.log("DutyInputFields constructor");
    }

    id: number;
    value: string;
    
}

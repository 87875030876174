
<div class="card p-3">
<h2 mat-dialog-title class="dialog-title">OWM PRINTER</h2><button mat-button mat-dialog-close class="colsebtn"> <em class="fa fa-times"></em></button>

<mat-dialog-content class="dialog-content">
<div id="container">
    <div class="row">
        <div class="col-md-12" id="background"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <form class="form-horizontal">
                <div class="form-group">
					<div id="pageTitle" class="col-md-12" align="center">
						<span class="label label-danger form-control alert alert-danger p-1">SAMPADA 2.0 PRINTING</span>
					</div>
				</div>
                <div class="col-md-12">
                    <table class="printControl" align="center">
                        <thead>
                            <tr>
                            </tr>
                            <tr>
                                <td class="col-md-12" colspan="3">
                                    <span id="printVersion" class="printControlVersion"></span>
                                    <span id="upgradeLink" class="printControlVersion"></span>
                                </td>
                            </tr>
                            <tr>
                                <td class="col-md-12" colspan="3" id="printControlTitle">
                                    <img src="assets/images/owm/Printer.png" height="40" width="40" />
                                    <span style="position: relative; font-size: 10pt">SAMPADA PRINT CONTROL</span>
                                </td>
                            </tr>
                            <tr id="printControlMessages">
                                <td class="col-md-12" colspan="3">
                                    <span id="printControlStatus">Checking Print Control</span>
                                    <a id="executeLink" href="javascript:PrintService.launchApp()" style="margin-left: 5px; display: none">(run print control)</a>
                                    <span id="downloadLink" style="margin-left: 5px; display: none">Click <a target="_blank" href="assets/images/owm/pcp.exe">here</a> to download Print Control</span>
                                    <span id="downloadPrintControl" style="display: none"> (After download completes, run the app)</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody id="printOptions" style="display: none">
                            <tr>
                                <td class="col-md-3">Printer:</td>
                                <td class="col-md-6"><span id="printerListContainer" >Loading Printer List</span></td>
                                <td class="col-md-3"><button type="button" id="refreshBtn" class="btn btn-secondary" onclick="queryPrinters(true); " style="position: relative;  display: none"><mat-icon>refresh</mat-icon>Refresh</button></td>
                            </tr>
                            <tr>
                                <td class="col-md-3">&nbsp;</td>
                                <!-- <td><a mat-raised-button color="default" class="mr-2 w-100 col-md-2 float-right btn btn-secondary" routerLink="/citizen/listingEstamp">{{'goback'|translate}}</a></td> -->
                                <td class="col-md-6"><button type="button" id="printBtn" style="display: none" class="btn btn-primary mt-2 d-flex">	<mat-icon>print</mat-icon> Print</button></td>
                                <td class="col-md-3" id="printJob"></td>
                            </tr>
                            <tr>
                                <td class="col-md-3">&nbsp;</td>
                                <td class="col-md-9" id="progress" colspan="3" style="display: none"></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>&nbsp;</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </form>
        </div>
    </div>
</div>
</mat-dialog-content>
</div>
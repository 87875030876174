import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { ResultModel } from "../../../models/api.result.model/result.model";
import { AppSetting, AppSettingService } from "../../app.setting";
import { CustomHttpClient } from '../../httpclient/custom.httpclient';

@Injectable({
    providedIn: 'root'
  })

  export class PanService{

    private settings!: AppSetting;
    constructor(private appSettingsService: AppSettingService,private httpClient: HttpClient,private customeHttpClient: CustomHttpClient) { 
      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
    });
    }

  


    checkPanNumber(pannumber:any):Observable<any>{

        return this.customeHttpClient.Post<ResultModel<any>>(
             this.settings.igrs_gateway_url + "common/get_pan_validation", pannumber
         ).pipe(
             map((result: any) => {
                 
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }

    //  checkProteanPanNumber(pannumber:any,panDob:any,panFullName:any):Observable<any>{
    //     let param = {
    //         "pannumber":pannumber,
    //         "panDob":panDob,
    //         "panFullName":panFullName
    //     }

    //     return this.customeHttpClient.Post<ResultModel<any>>(
    //          this.settings.igrs_gateway_url + "common/get_protean_pan_validation", param
    //      ).pipe(
    //          map((result: any) => {
                 
    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }
    
    //          })
    //      );
    //  }

     checkProteanPanNumber(encVal: any): Observable<any> {
        // let encVal = this._AESEncryptDecryptService.encrypt("type_id=" + typeid + "&demographyId=" + demographyId);
         return this.customeHttpClient.Get<ResultModel<any>>(
             this.settings.igrs_gateway_url + "common/get_protean_pan_validation?request=" + encVal 
         ).pipe(
             map((result: any) => {
 
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
 
             })
         );
     }
    

}
<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>
            {{'detailsPages.srsplocation' | translate}}
        </h2>
        
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
    
    <div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-sr-tab" data-toggle="pill" href="#pills-sr" role="tab" (click)="selectOffice='sr';get_offices_map()"
                    aria-controls="pills-sr" aria-selected="true">{{'homepage.SR-Offices' | translate}}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-sp-tab" data-toggle="pill" href="#pills-sr" role="tab" (click)="selectOffice='sp';get_offices_map()"
                    aria-controls="pills-sp" aria-selected="false">{{'homepage.SP-Office' | translate}} </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-lsk-tab" data-toggle="pill" href="#pills-sr" role="tab" (click)="selectOffice='lsk';get_offices_map()"
                    aria-controls="pills-lsk" aria-selected="false">{{'homepage.LSK-Offices' | translate}}</a>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-sr" role="tabpanel" aria-labelledby="pills-sr-tab">
                <div class="loation_Map">
               
                        <div style="height: 450px;width: 100%;position: relative;">
                            
                            <app-map (MapLoadEvent)="onLoadMap($event)" ></app-map>
                            <div class="form-group" style="position: absolute;top:0;left:0;z-index: 100;">
                                <!-- <div class="input-group mb-3"> -->
                                <div class="d-flex">
                                    <ng-select required appendTo="body" style="width: 300px;"
                                        name="district" #district="ngModel" [(ngModel)]="districtId"
                                        required (change)="get_offices_map()"
                                        placeholder="{{'citizen-profile-details.citizen-contact-details.district' | translate}}">
                                        <ng-option value="null"
                                            disabled>{{'citizen-profile-details.citizen-contact-details.district' |
                                            translate}}</ng-option>
                                        <ng-option *ngFor="let dis of districts"
                                            [value]="dis.id">{{lan==0?dis.demographyNameEn:dis.demographyNameHi}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                               
                                <!-- </div> -->
                            </div>
                        </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-sp" role="tabpanel" aria-labelledby="pills-sp-tab">
                <div class="loation_Map">

                     
                </div>
            </div>
            <div class="tab-pane fade" id="pills-lsk" role="tabpanel" aria-labelledby="pills-lsk-tab">
                <div class="loation_Map">
 
                </div>
            </div>
        </div>
    </div>


      </mat-card-content>
    </mat-card>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  lan: number;

   constructor( 
 
    private ngxLoader: NgxUiLoaderService,
    
    private translate: TranslateService,
    private translateServiceIns: TranslateService,
    private translateService: TranslateHEService,
 ) {
    //sessionStorage.setItem('eRegId', '1');
    //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;
    try {
      console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }

      // translate.use(selectedLanguage).toPromise();
      // this.lan = selectedLanguage == 'hi' ? 1 : 0;

      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg == 1 ? 'hi' : 'en')
      }
      );
    } catch (err) {
      console.log(err);
    }

    
  }

  ngOnInit(): void {
  }

}

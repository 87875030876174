import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontWebSharedModule } from 'projects/front-web/src/app/app.module';
import { CitizenLoginComponent } from './citizen-login/citizen-login.component';





const routes: Routes = [

  {
    path: 'clogin',
    component: CitizenLoginComponent, data: { breadcrumb: 'Next' }
  },
  {
    path:'citizen',
    loadChildren:() => import('../app/load/loadCitizen.module').then(m => m.LoadCitizenModule)
   },
   { path: '**', redirectTo: '', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false }), 
    FrontWebSharedModule.forRoot()
  ],
  exports: [RouterModule],
})
export class CitizenAppRoutingModule { }


import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DefaultServiceService } from '../../default/default-service.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-citizen-registration',
  templateUrl: './citizen-registration.component.html',
  styleUrls: ['./citizen-registration.component.scss']
})
export class CitizenRegistrationComponent  implements OnInit {

  lan: number = 1

  constructor(private defaultServiceService: DefaultServiceService, private translateService: TranslateHEService
    , private translate: TranslateService) {
      this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
  }

  // ngOnInit(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit() {
    this.defaultServiceService.setDefaultType('app-citizen-registration');
  }


}

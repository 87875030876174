import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
// import { AppConfig } from 'src/app/models/AppConfig';
// import { ConfigService } from 'src/app/services/app.config.service';
import { AddressSearchServices } from './address-search.service';
import { MapMyindiaService } from 'projects/common-lib/src/lib/helper-lib/services/MapMyindia.service';
import { promises } from 'dns';
import { forkJoin, fromEvent } from 'rxjs';
import { AlertmessageComponent } from '../alertmessage/alertmessage.component';
import * as turf from '@turf/turf';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AddressSearchComponent implements OnInit {

  showcrosButton:boolean=false;
  google:any;
  appConfig:AppSetting|any;
  southWest:any;
  northEast:any;
  markers:any[]=[]
  iconOptions:L.Icon |any;
  public islistShow:boolean=false;
  public suggestionDatan:Array<any>=[];
  @Input() map:L.Map |any;

  @ViewChild('inputAdd') searchInput: HTMLInputElement | any;
  @ViewChild('suggetionList')  suggetionList!: ElementRef<any>;
  @ViewChild('ExtentsuggetionList')  ExtentsuggetionList!: ElementRef<any>;
  @Output() SearchLaodEvent = new EventEmitter();
  public currentAddressObject:any;
  searchOption:Array<Object> = [
    {value: 0, name_en: "By Address", name_hi:"पते से"},
    {value: 1, name_en: "By LatLng", name_hi:"लैटलंग द्वारा"},
    {value: 2, name_en: "By Colony ID", name_hi:"कॉलोनी आईडी द्वारा"}
  ];
  @Input() showOption:boolean=false;
  @Input() selectedSearchoption:number=0
  @Input() address:string="";
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @Input() mpBoundaryGeom:any=null;
  @Input() withExtent:boolean=false;

  constructor(private appSettingsService: AppSettingService, 
  private serchservice:AddressSearchServices, @Inject(DOCUMENT) private document:Document, private renderer: Renderer2,private mapmyindia:MapMyindiaService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.appConfig = settings;
    });
    this.mapmyindia.saveTocken();
   }

  ngOnInit(): void {
    // Creating a custom icon
    L.Marker.prototype.options.icon = L.icon({
      iconUrl: "./assets/images/marker.png",
      //shadowUrl: "./assets/images/marker-shadow.png",
      iconSize: [60, 60],
      iconAnchor: [30, 62]
    });
  }

  ngAfterViewInit() {
    if(this.address != null && this.address != ""){
        this.searchInput.nativeElement.value = this.address;
        this.keyupAction(true);
    }
  }


  addMarker(latlng:any){
    this.clearMarker();
    let marker =   L.marker(latlng,{
      zIndexOffset: 0                       
    });
    this.map.addLayer(marker);
    this.markers.push(marker);
    this.map.flyTo([latlng.lat, latlng.lng], 18, {
      animate: true,
      duration: 2
    });
    marker.on('click',()=>{
      this.SearchLaodEvent.emit(this.currentAddressObject);
    }); 
  }

  public clearMarker() {
    this.markers.forEach((marker: any) => {
      this.map.removeLayer(marker)
    });
    this.markers = [];
  }

  public clearInputBox(){
    this.searchInput.nativeElement.value='';
    this.clearMarker();
    this.showcrosButton=false;
    this.islistShow=false;
  }



  async keyupAction(status:boolean){
    this.suggestionDatan=[];
    if(this.searchInput.nativeElement.value.length>0)
      this.showcrosButton=true;
    if(this.searchInput.nativeElement.value.length>2 && this.selectedSearchoption == 0 ){
      //if(status){
        if(this.chackItislatlng()){
          this.addLatLngInSuggetion(this.searchInput.nativeElement, status);
        }else{
          this.addAdressSuggetion(status);
        }
      // }else{
      //   this.addAdressSuggetion(status);
      //   this.addLatLngInSuggetion(this.searchInput.nativeElement, status);
      // }
    }else if(this.searchInput.nativeElement.value.length>5 && this.selectedSearchoption == 1){
      this.addLatLngInSuggetion(this.searchInput.nativeElement, status);
    }else if(this.searchInput.nativeElement.value.length>3 && this.selectedSearchoption == 2 && status){
      this.searchByColonyId(this.searchInput.nativeElement);
    }else{
      if(this.markers.length==0)
        this.showcrosButton=false;
      this.islistShow=false;
      this.suggestionDatan=[];
    }
  }

  

  async addAdressSuggetion(status:boolean=false){
    this.showcrosButton=true;
    //this.suggestionDatan=[];
    this.islistShow=true
    this.showcrosButton=true;
    var res = await this.getSuggetions();
    this.suggestionDatan=[];
    this.removeaddresSearch();
    if(status){
      //this.suggestionDatan=[];
      //this.suggetionList?.nativeElement?.replaceChildren();
      this.islistShow=false;
      if(res[0]?.suggestedLocations.length>0)
          this.placeaddress(res[0].suggestedLocations[0]);
      else{
        this.alertmessage.errorAlertMessage({responseMessage:'Address not found',responseMessageEn:'Address not found',responseMessageHi:'पता नहीं मिला'})
      }
    }else{        
      if(res.length>0){
        res[0]?.suggestedLocations?.forEach((item:any)=>{
          //console.log(item);
          this.suggestionDatan.push(item);
          const sugetion:HTMLElement = this.document.createElement('li');
          sugetion.setAttribute('id',item.eLoc);
          sugetion.setAttribute('class','suggetionListing')
          let city:string = (item.cityName  != "NO_CITY")?"("+item.cityName+")":"";
          sugetion.innerHTML=`<i class="fa fa-map-marker"></i> &nbsp;  <b>${item.placeName} ${city}</b>`;
          fromEvent(sugetion, 'click').subscribe((e:any)=>{          
            this.searchInput.value=item.placeName+` (${item.placeAddress})`;
            this.suggetionList.nativeElement.replaceChildren();
            this.placeaddress(item);
            this.islistShow=false;
          });
          this.renderer.appendChild(this.suggetionList.nativeElement, sugetion);
        });
        res[1]?.suggestedLocations?.forEach((item:any)=>{
          //console.log(item);
          this.suggestionDatan.push(item);
          const sugetion:HTMLElement = this.document.createElement('li');
          sugetion.setAttribute('id',item.eLoc);
          sugetion.setAttribute('class','suggetionListing');
          let city:string = (item.cityName  != "NO_CITY")?"("+item.cityName+")":"";
          sugetion.innerHTML=`<i class="fa fa-map-marker"></i> &nbsp;  <b>${item.placeName}  ${city}</b>`;
          fromEvent(sugetion, 'click').subscribe((e:any)=>{          
            this.searchInput.value=item.placeName+` (${item.placeAddress})`;
            this.ExtentsuggetionList.nativeElement.replaceChildren();
            this.placeaddress(item);
            this.islistShow=false;
          });
          this.renderer.appendChild(this.ExtentsuggetionList.nativeElement, sugetion);
        });
      }else{
        const sugetion:HTMLElement = this.document.createElement('li');
        sugetion.setAttribute('id',"not_found");
        sugetion.setAttribute('class','suggetionListing')
        sugetion.innerHTML=`<i class="fa fa-map-marker"></i> &nbsp;  <b>Place Not Found</b>`;
        this.renderer.appendChild(this.suggetionList.nativeElement, sugetion);
        this.searchInput.nativeElement.focus();
      }
    } 
  }

  addLatLngInSuggetion(searchValue:any, status= false){
    if(searchValue.value.length>2){
      var inputVal ="";
      if(searchValue.value?.split(",")?.length==2){
          inputVal = searchValue.value?.split(",");
      }
      else if(searchValue.value?.split(" ")?.length==2){
        inputVal = searchValue.value?.split(" ");
      }
      if(inputVal != "" && inputVal[0] != "" && inputVal[1] != "" && Number.isFinite(Number(inputVal[0].trim())) && Number.isFinite(Number(inputVal[1].trim()))){
        var pt = turf.point([Number(inputVal[1]),Number(inputVal[0]) ]);
        var pt2 = turf.point([Number(inputVal[0]),Number(inputVal[1]) ]);
        let latlng:any;
        let ptstatus:boolean =false;
        if(!turf.booleanPointInPolygon(pt, this.mpBoundaryGeom)){
          //this.alertmessage.errorAlertMessage({responseMessage:'You have Click or Search Outsite of Mp State Boundary',responseMessageEn:'You Had Click or Search Outsite of Mp State Boundary',responseMessageHi:'आपने एमपी राज्य की सीमा के बाहर क्लिक या सर्च किया है '})
          ptstatus =true;
        }
        if(ptstatus && !turf.booleanPointInPolygon(pt2, this.mpBoundaryGeom)){
          this.alertmessage.errorAlertMessage({responseMessage:'You have Click or Search Outsite of Mp State Boundary',responseMessageEn:'You Had Click or Search Outsite of Mp State Boundary',responseMessageHi:'आपने एमपी राज्य की सीमा के बाहर क्लिक या सर्च किया है '})
          return;
        }else{
          if(!ptstatus){
            latlng = {
              latitude:inputVal[0],
              longitude:inputVal[1]
            }
          }else{
            latlng = {
              latitude:inputVal[1],
              longitude:inputVal[0]
            }
          }
        }
        
        if(status){
          this.placeaddress(latlng);
          this.SearchLaodEvent.emit(this.currentAddressObject);
          this.islistShow=false;
        }else{
          if(!this.islistShow)
            this.islistShow=true;
          setTimeout(()=>{
            const sugetionList:HTMLElement = this.document.createElement('li');
            sugetionList.setAttribute('id',"latlngS");
            sugetionList.setAttribute('class','suggetionListing')
            sugetionList.innerHTML=`<h6>${latlng.latitude +", "+ latlng.longitude}</h6>`;
            sugetionList.addEventListener('click',(e:Event)=>{
              //this.logger.log(list.eLoc);
              searchValue.value=latlng.latitude +", "+ latlng.longitude;
              this.suggetionList.nativeElement.replaceChildren();
              this.placeaddress(latlng);
              this.SearchLaodEvent.emit(this.currentAddressObject);
              this.islistShow=false;
            });
            if(this.islistShow && this.suggetionList.nativeElement.childNodes.length>0){
              let l:number=this.suggetionList.nativeElement.childElementCount
              if(this.suggetionList.nativeElement.childNodes[l-1].id == "latlngS"){
                this.suggetionList.nativeElement.removeChild(this.suggetionList.nativeElement.lastChild)
              }
              this.renderer.appendChild(this.suggetionList.nativeElement, sugetionList);
              //this.suggetionList.nativeElement.replaceChildren();
            }else{
              this.renderer.appendChild(this.suggetionList.nativeElement, sugetionList);
            }
          },300);
        }
      }
    }
  }

  chackItislatlng():boolean{
    let returnstatus:boolean = false;
    if(this.searchInput.nativeElement.value.length>8){
      var inputVal ="";
      if(this.searchInput.nativeElement.value?.split(",")?.length==2){
          inputVal = this.searchInput.nativeElement.value?.split(",");
      }
      else if(this.searchInput.nativeElement.value?.split(" ")?.length==2){
        inputVal = this.searchInput.nativeElement.value?.split(" ");
      }
      if(inputVal != "" && inputVal[0] != "" && inputVal[1] != "" && Number.isFinite(Number(inputVal[0].trim())) && Number.isFinite(Number(inputVal[1].trim()))){
        returnstatus = true;
      }
    }
    return returnstatus;
  }

  searchByColonyId(searchValue:any){
    if(Number.isFinite(Number(searchValue.value.trim()))){
      let sendObject:Object ={
        "colonyId":searchValue.value,
        "lattitude":"",
        "longitude":""
      }
      this.SearchLaodEvent.emit(sendObject);
    }else{
      this.alertmessage.errorAlertMessage({responseMessage:'Please Search Correct Colony ID',responseMessageEn:'Please Search Correct Colony ID',responseMessageHi:'कृपया सही कॉलोनी आईडी खोजें'});
    }
  }

  async getSuggetions():Promise<any>{
    const promise = new Promise(async (resolve, reject) => {
      let queryParams = new HttpParams();
      let ne:any =this.map.getBounds().getNorthWest();
      let sw:any = this.map.getBounds().getSouthEast();
      queryParams = queryParams.append("query",`${this.searchInput.nativeElement.value}`).append('access_token', this.mapmyindia.getToken());
      let queryParams2 = new HttpParams();
      queryParams2 = queryParams2.append("query",`${this.searchInput.nativeElement.value}`).append('access_token', this.mapmyindia.getToken()).append("filter","bounds:"+ne.lat+","+ne.lng+";"+sw.lat+","+sw.lng); 
      let request:Array<Observable<any>>=[];
      request.push(this.serchservice.getPlaceSuggetion(this.appConfig.suggestionURLofMapMyIndia, queryParams)); 
      if(this.withExtent){
        request.push(this.serchservice.getPlaceSuggetion(this.appConfig.suggestionURLofMapMyIndia, queryParams2)); 
      }
      let g_response:Array<any>=[];
      for(let i=0;i<request.length;i++){
        try{
          let req:Observable<any> = request[i]
          let response:any = await req.toPromise();
          if(response?.suggestedLocations.length>0){
            g_response.push(response);
          }else{
            g_response.push([]);
          } 
        }catch(error:any){
          g_response.push([]);
          if(error?.status == 401 || error?.responsecode == 400){
            this.mapmyindia.saveTocken();
          }
        }  
      }
      resolve(g_response);
    });
    return promise;
  }

 

  placeaddress(response:any):void{
      this.searchInput.nativeElement.value = (response.placeName)?response.placeName:this.searchInput.nativeElement.value;
      var latlng = L.latLng(response.latitude, response.longitude);
      //this.islistShow=false;
      this.currentAddressObject = response;
      this.suggestionDatan=[];
      this.addMarker(latlng)
  }

  removeaddresSearch(){
    if(this.suggetionList?.nativeElement?.childNodes.length>0){
      this.suggetionList.nativeElement.replaceChildren();
    }
    if(this.ExtentsuggetionList?.nativeElement?.childNodes.length>0){
      this.ExtentsuggetionList.nativeElement.replaceChildren();
    }
  }

  onSearchSelectionChange(){
    this.clearInputBox()
  }



}

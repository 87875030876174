<div class="fid-header"></div>
<div class="container mt-3">

    <!-- <h2 class="loginTitle">{{'citizen-registration-details.sign-up-as-legal' | translate}} </h2> -->
    <div class="registrationSteps">
        <div class="steps ">
            <div class="stepBox ">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-sign-in"></i>
                    <p>{{'login' | translate}}</p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>

        <div class="steps mx-3 active">
            <div class="stepBox">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-list-alt"></i>
                    <p> {{'e-stamp.deed-List' | translate}} </p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>
        <div class="steps mx-3">
            <div class="stepBox">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-pencil-square-o"></i>
                    <!-- <p> {{'e-stamp.deed-content-approval' | translate}} </p> -->
                    <p> {{'e-stamp.deed-content-view-validate' | translate}} </p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>
    </div>
    <lib-alertmessage></lib-alertmessage>
    <mat-card class="example-card">
        <mat-card-title> </mat-card-title>
        <mat-card-content class="mat-card-content">

            <div class="row m-0">


                <div class="table-responsive shadow-none">
                    <table id="viewEstamp" mat-table [dataSource]="dataSource" matSort
                        class="mat-elevation-z8 demo-table w-100 ">


                        <ng-container matColumnDef="sNo">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'duty-calculation.SNO' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element; let i = index">{{element.rnum}} </td>
                        </ng-container>
                        <ng-container matColumnDef="estampCode">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.code' | translate}}</th>
                            <td mat-cell *matCellDef="let element">

                                <span *ngIf="element.eregFlag==undefined || element.eregFlag==0">{{element.estampCode
                                    }}</span>
                                <span *ngIf="element.eregFlag==1">{{element.eregId }}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="isERegistry">
                            <th mat-header-cell *matHeaderCellDef>ERegistry </th>
                            <td mat-cell *matCellDef="let element"> <span
                                    *ngIf="element.eregFlag==undefined || element.eregFlag==0">No</span><span
                                    *ngIf="element.eregFlag==1">Yes</span> </td>
                        </ng-container>
                        <ng-container matColumnDef="deedCatagory">
                            <th mat-header-cell *matHeaderCellDef> {{'duty-calculation.Deed-Catagory' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                         <!-- {{element.deedCategoryNameEn}}  -->
                                             {{lan==0 ?element.deedCategoryNameEn:element.deedCategoryNameHi}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="deedType">
                            <th mat-header-cell *matHeaderCellDef> {{'duty-calculation.Deed-Type' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> 
                                <!-- {{element.deedTypeNameEn}}  -->
                                {{lan==0 ?element.deedTypeNameEn:element.deedTypeNameHi}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="instrument">
                            <th mat-header-cell *matHeaderCellDef> {{'duty-calculation.Instrument' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <!-- {{element.instrumentNameEn}}  -->
                                {{lan==0 ?element.instrumentNameEn:element.instrumentNameHi}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="natureInstr">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.NatureOfInstrument' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.natureInstr }} </td>
                        </ng-container>
                        <ng-container matColumnDef="transactionBehalf">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.Transaction-Behalf' | translate}}</th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element.tbehalf==='0';else cond1">{{'e-stamp.SELF' | translate}}</span>
                                <ng-template #cond1>{{'e-stamp.OTHER' | translate}}</ng-template>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="estampStatus">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.estamp-ereg-status' | translate}}</th>
                            <td mat-cell *matCellDef="let element">  {{lan==0 ?element.estampStatus:element.estampStatusHi}} </td>
                        </ng-container>
                        <ng-container matColumnDef="estampcontentstatus">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.Estamp-Content-Status' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{lan==0 ?element.contentStatus:element.contentStatusHi}} </td>
                        </ng-container>
                        <ng-container matColumnDef="partystatus">
                            <th mat-header-cell *matHeaderCellDef> {{'e-stamp.Party-Status' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{lan==0 ?element.partyContentStatus:element.partyContentStatusHi}} </td>
                        </ng-container>
                        <ng-container matColumnDef="remarkStatus">
                            <th mat-header-cell *matHeaderCellDef [style.display]="'none'">{{'e-stamp.Remark-Status' |
                                translate}}</th>
                            <td mat-cell *matCellDef="let element" [style.display]="'none'"> {{element.nameEn }} </td>
                        </ng-container>
                        <ng-container matColumnDef="partyType">
                            <th mat-header-cell *matHeaderCellDef>
                                {{'e-stamp.Party-Type' | translate}}
                            </th>
                            <!-- <td mat-cell *matCellDef="let element"> {{getPartyType(element.partyType) }} </td> -->
                            <td mat-cell *matCellDef="let element"> 
                                 {{lan==0 ?element.partyTypeNameEn:element.partyTypeNameHi}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="contentStatusId">
                            <th mat-header-cell *matHeaderCellDef [style.display]="'none'">

                                {{'e-stamp.Estamp-Content-Status' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element" [style.display]="'none'"> {{element.contentStatusId
                                }} </td>
                        </ng-container>
                        <ng-container matColumnDef="createdOn">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.initiated-on' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.createdDate | date:'dd-MM-yyyy HH:mm:ss'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="modifiedDate">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.modified-Date' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.modifiedDate | date:'dd-MM-yyyy
                                HH:mm:ss'}} </td>
                        </ng-container>
                        <ng-container matColumnDef="userName">
                            <th mat-header-cell *matHeaderCellDef>{{'e-stamp.initiated-by' | translate}}</th>
                            <td mat-cell *matCellDef="let element"> {{element.userName }} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> {{'e-stamp.Action' | translate}}</th>

                            <td mat-cell *matCellDef="let element" class="d-flex">


                                <ng-container
                                    *ngIf="(element.eregFlag==undefined || element.eregFlag==0) && element.estampStatusId>='23';">


                                    <button mat-icon-button>
                                        <mat-icon
                                            (click)="goToPreview(element.estampId,element.partyContentStatus,element.partyType,element.partySubType,element.contentStatusId,element.natureInstr,element.eregFlag,element.estampCode,element.partyTypeNameEn,element.partyName,element.userId,element.deedAcceptLink,element.partyTypeNameHi,element.partyId)"
                                            svgIcon="file-eye" class="mr-1 text-info" matTooltip="View">
                                        </mat-icon>
                                    </button>

                                    <ng-container
                                        *ngIf="element.contentStatusId=='12' && (element.partyContentStatusId=='13' || element.partyContentStatusId=='25')">
                                        <button mat-icon-button>
                                            <mat-icon
                                                (click)="openEsignPopup(initiateESign,element.estampId,element.partyContentStatus,element.userId,
                                element.aadharPhoto,element.partyName,element.mobileNo,element.ekycStatus,element.partyId,element.partyNameHindi)"
                                                class="mr-1 text-primary" matTooltip="E-Sign" svgIcon="file-edit">
                                            </mat-icon>
                                        </button>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="element.estampStatusId==21 && element.contentStatusId=='12' && (element.partyContentStatusId=='13' || element.partyContentStatusId=='25') && element.partySubType==2 ; else cond3">
                                        <ng-container *ngIf="element.natureInstr=='Bilateral'">
                                            <button mat-icon-button>
                                                <mat-icon
                                                    (click)="goToEsign(element.estampId,element.partyContentStatus,element.partyId,element.userId)"
                                                    class="mr-1 text-primary" matTooltip="E-Sign" svgIcon="file-edit">
                                                </mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #cond3>
                                        <ng-container
                                            *ngIf="element.estampStatusId==21 && element.contentStatusId=='12' && (element.partyContentStatusId=='13' || element.partyContentStatusId=='25') && element.partySubType!=2">
                                            <button mat-icon-button>
                                                <mat-icon
                                                    (click)="goToEsign(element.estampId,element.partyContentStatus,element.partyId,element.userId)"
                                                    class="mr-1 text-primary" matTooltip="E-Sign" svgIcon="file-edit">
                                                </mat-icon>
                                            </button>
                                        </ng-container>
                                    </ng-template>
                                    <!--ng-container *ngIf="element.estampStatus==='26';"-->
                                    <!-- ng-container *ngIf="element.partyContentStatusId===17; -->
                                    <ng-container *ngIf="element.estampStatusId===26 && (element.partySubType == 1 || element.partySubType == 2)">
                                        <button mat-icon-button>
                                            <div class="mt-1">
                                                <mat-icon (click)="downloadDMSEstamp(element.estampId)"
                                                    svgIcon="file-download" class="mr-1 text-danger"
                                                    matTooltip="Download Estamp"></mat-icon>
                                            </div>
                                        </button>
                                    </ng-container>


                                </ng-container>

                                <ng-container
                                    *ngIf="element.eregFlag!=undefined && element.eregFlag==1 && element.estampStatusId>='21';">


                                    <button mat-icon-button>
                                        <mat-icon
                                            (click)="goToPreview(element.estampId,element.partyContentStatusId,element.partyType,element.partySubType,element.contentStatusId,element.natureInstr,element.eregFlag,element.eregId,element.partyTypeNameEn,element.partyName,element.userId,element.deedAcceptLink,element.partyTypeNameHi,element.partyId)"
                                            svgIcon="file-eye" class="mr-1 text-info" matTooltip="View">
                                        </mat-icon>
                                    </button>

                                    <ng-container
                                        *ngIf="element.estampStatusId=='21' && element.contentStatusId=='12' && (element.partyContentStatusId=='73' || element.partyContentStatusId=='25') && element.partySubType==2 && element.aadharEkyc == 1 && element.esignLink != null && element.esignLink != undefined ; else cond1">
                                        <!-- below code commented on 21-03-2024 for authenticated biolateral instrument -->
                                        <!-- <ng-container *ngIf="element.natureInstr=='Bilateral'"></ng-container> -->
                                        <ng-container>
                                            <button mat-icon-button>
                                                <mat-icon
                                                    (click)="viewEregEsign(element.estampId,element.partyContentStatusId,element.partyId,element.userId)"
                                                    svgIcon="file-edit" class="mr-1 text-danger"
                                                    matTooltip="Go To For Esign"></mat-icon>

                                            </button>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #cond1>
                                        <ng-container
                                            *ngIf="element.estampStatusId=='21' && element.contentStatusId=='12' && (element.partyContentStatusId=='73' || element.partyContentStatusId=='25') && element.partySubType!=2 && element.aadharEkyc == 1 && element.esignLink != null && element.esignLink != undefined">
                                            <button mat-icon-button>
                                                <mat-icon
                                                    (click)="viewEregEsign(element.estampId,element.partyContentStatusId,element.partyId,element.userId)"
                                                    svgIcon="file-edit" class="mr-1 text-danger"
                                                    matTooltip="Go To For Esign"></mat-icon>

                                            </button>
                                        </ng-container>
                                    </ng-template>
                                    <!-- *ngIf="element.partyContentStatusId===17;" -->
                                    <ng-container *ngIf="element.eregStatusId===72 && (element.partySubType == 1 || element.partySubType == 2)">
                                        <button mat-icon-button>
                                            <div class="mt-1">
                                                <!-- <mat-icon (click)="downloadEsign(element.estampId)"
                                                    svgIcon="file-download" class="mr-1 text-danger"
                                                    matTooltip="Download Eregistry"></mat-icon> -->
                                                    <mat-icon (click)="downloadCompletedEreg(element.eregId,element.eregTxnId)"
                                                    svgIcon="file-download" class="mr-1 text-danger"
                                                    matTooltip="Download Eregistry"></mat-icon>
                                            </div>
                                        </button>
                                    </ng-container>


                                </ng-container>

                            </td>

                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> {{'e-stamp.Action' | translate}} </th>
                            <td mat-cell *matCellDef="let row">

                                <!-- <div *ngFor="let offlist of featureListSessiondata"> -->
                                <button mat-icon-button [matMenuTriggerFor]="action"
                                    aria-label="Example icon-button with a menu" class="">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #action="matMenu" xPosition="before">

                                </mat-menu>

                                <!-- </div> -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>
                 
                </div>
                <div class="w-100">
                       <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="pageChangedES($event)"
                        showFirstLastButtons></mat-paginator>
                </div>
                <div class="col-md-12 text-left" *ngIf="eregFlag == 0">
                    <a mat-raised-button color="default" class="btn btn-secondary mr-1" routerLink="/citizen/listingEstamp">{{'goback'
                        | translate}}</a>
                </div>
                <div class="col-md-12 text-left" *ngIf="eregFlag == 1">
                    <a mat-raised-button color="default" class="btn btn-secondary mr-1" routerLink="/citizen/View-Registration-Detail">{{'goback'
                        | translate}}</a>
                </div>
            </div>


        </mat-card-content>

    </mat-card>
    
</div>

<ng-template #initiateESign let-c="close" let-d="dismiss">
    <h2 matDialogTitle class="Consenterdialog-title">{{'TakeElectronicSign.modalheading' | translate}}</h2>
    <button type="button" class="colsebtn h4" aria-label="Close" (click)="d('Cross click')" style="top: 9px">
        <span aria-hidden="true">&times;</span>
    </button>


    <div class="modal-body">
        <div class="p-2 d-flex justify-content-center">
            <div class="form-check form-check-inline mr-4" *ngIf="ekycStatus == 1">
                <!--*ngIf="partyDetailsModel.aadharEkyc == 1"-->
                <input class="form-check-input" type="radio" name="flexRadioDefault" value="esign"
                    (click)="onClickRadio('esign')" id="flexRadioDefault1" [(ngModel)]="readioSelected" checked>
                <label class="form-check-label" for="flexRadioDefault1">
                    {{'TakeElectronicSign.eSign' | translate}}
                </label>
            </div>
            <div class="form-check form-check-inline" aria-disabled="true" disabled>
                <input class="form-check-input" type="radio" name="flexRadioDefault" value="dsc"
                    (click)="onClickRadio('dsc')" id="flexRadioDefault1" [(ngModel)]="readioSelected">
                <label class="form-check-label" for="flexRadioDefault1">
                    {{'TakeElectronicSign.DSC' | translate}}
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer" [ngClass]="{'justify-content-between': readioSelected === 'dsc'}">
        <a target="_blank" href="{{settings.unipay_dsc_software_link}}" class="text-primary"
            *ngIf="readioSelected == 'dsc'"><u>{{'TakeElectronicSign.dscDownloadlink' | translate}}</u></a>
        <div class="text-right">
            <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal"
                (click)="d('Cross click')">{{'TakeElectronicSign.cancel' | translate}}</button>
            <button type="button" class="btn btn-primary"
                (click)="partyEsignNext();d('Cross click')">{{'TakeElectronicSign.next' | translate}}</button>
        </div>
    </div>
</ng-template>


<ngx-ui-loader></ngx-ui-loader>
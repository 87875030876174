import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { map } from "rxjs/operators";
import { AppSetting } from "projects/common-lib/src/lib/helper-lib/app.setting/app.setting";
import { AppSettingService } from "projects/common-lib/src/lib/helper-lib/app.setting/app.setting.service";
import { CustomHttpClient } from "projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient";
import { ResultModel } from "projects/common-lib/src/lib/models/api.result.model/result.model";
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';



@Injectable({ providedIn: "root" })
export class CommanDemographyManagementServive {
    private settings!: AppSetting;
    constructor(private appSettingsService: AppSettingService,private customeHttpClient: CustomHttpClient,private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });
    }


    getDemoGraphyByCurrentLevel(id: any, departmentId: any, areaType: any) {
        let obj = {};
        if (areaType != null) {
            obj = { 'demographyId': id, 'departmentId': departmentId, 'areaType': areaType }
        }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyCurrentLevel", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    getFetchDemoGraphyNextLevel(typeid: any, parentid: any, areaType: any) {
        //type_id, parent_id
        let obj = {};
        if (typeid != null) {
            obj = { 'type_id': typeid, 'parent_id': parentid }
        } else {
            obj = { 'areaType': areaType }
        }
        let encVal = this._AESEncryptDecryptService.encrypt("type_id=" + typeid + "&parent_id=" + parentid);
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demographies_map?request=" + encVal
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    getDemoGraphyTypeAll() {
        let param = "";
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_demography_type", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    fetchDemoGraphyHierarchy(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyHierarchy", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    getDemoGraphyByCurrentLevelByareaType(areaType: any) {
        let obj = {};
        if (areaType != null) {
            obj = { 'areaType': areaType }
        }
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/fetchDemoGraphyCurrentLevel", obj
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

}


<div>
    <p #textbox [class.show-less]="expand === false">
      <ng-content></ng-content>
    </p>
    <a class="readmore"
      (click)="toggle()"
      *ngIf="
        textbox.offsetHeight < textbox.scrollHeight ||
        (expand === true && textbox.offsetHeight > 40)
      "
      >{{ lan ==0?anchor:anchorHi}}</a
    >
  </div>

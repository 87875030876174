import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DutyCalculationData } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-calculation-data';
import { CommunicationServiceService } from '../duty-service/communication-service.service';
import { DutyService } from '../duty-service/duty.service';
export interface DialogData {
  instData: DeedInstrumentBean;
  calData: DutyCalculationData;
}
@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss']
})
export class DetailViewComponent implements OnInit {
lan: any;
     deedInstrumentSelected: DeedInstrumentBean= new DeedInstrumentBean();
     DutyCalculationData:DutyCalculationData = new DutyCalculationData();
upkarDutyDisplay:string;
municipalDutyDisplay:string;
janpadDutyDisplay:String;

  constructor(private  dialogRef:  MatDialogRef<DetailViewComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData,private translateService: TranslateHEService, private translate: TranslateService, private commServ: CommunicationServiceService) {
 
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     })
    //console.log("constructor : DetailViewComponent");
    //console.log(data.instData);
    this.deedInstrumentSelected = data.instData;
    this.DutyCalculationData = data.calData;


        //Municipal Duty
        if(this.DutyCalculationData?.municipalDuty==null || this.DutyCalculationData?.municipalDuty==undefined){
          this.municipalDutyDisplay ='';
        }else{
          this.municipalDutyDisplay =  this.lan==0? this.DutyCalculationData?.municipalDuty + ' '+ this.DutyCalculationData?.municipalDutyType+'' : this.DutyCalculationData?.municipalDuty+ ' '+this.DutyCalculationData?.municipalDutyTypeHi+'';
        }


    //Janpad Duty
    if(this.DutyCalculationData?.janpadDuty==null || this.DutyCalculationData?.janpadDuty==undefined){
      this.janpadDutyDisplay ='';
    }else{
      this.janpadDutyDisplay =  this.lan==0? this.DutyCalculationData?.janpadDuty + ' '+ this.DutyCalculationData?.janpadDutyType+'' : this.DutyCalculationData?.janpadDuty+ ' '+this.DutyCalculationData?.janpadDutyTypeHi+'';
    }

    //Upkar Duty
    if(this.DutyCalculationData?.upkarDuty==null || this.DutyCalculationData?.upkarDuty==undefined){
      this.upkarDutyDisplay ='';
    }else{
      this.upkarDutyDisplay =  this.lan==0? this.DutyCalculationData?.upkarDuty +' % of Principal Stamp Duty':'शासकीय स्टाम्प शुल्क का '+ this.DutyCalculationData?.upkarDuty+' %';
    }
   

    dialogRef.disableClose = false;
   
  }

  ngOnInit(){
    console.log("ngOnInit : DetailViewComponent");
  
  }

  ngDoCheck(){
    console.log("ngDoCheck : DetailViewComponent");
  }
  ngAfterContentInit(){
    console.log("ngAfterContentInit : DetailViewComponent");
  }
  ngAfterContentChecked(){
    console.log("ngAfterContentChecked : DetailViewComponent");
  }
  ngAfterViewInit(){
    console.log("ngAfterViewInit : DetailViewComponent");
  
  }
  ngAfterViewChecked(){
    console.log("ngAfterViewChecked : DetailViewComponent");
  }
  ngOnDestroy(){
    console.log("ngOnDestroy : DetailViewComponent");
  }




  public  closeMe() {
    this.dialogRef.close();
  }
}

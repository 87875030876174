import { UOMEnum } from "projects/common-lib/src/lib/models/UOMEnum";

export class PropertyMasterUnit{
    static readonly type='[Property Unit] GET'

    constructor(public payload:UOMEnum){

    }
}
export class PropertyMasterUomAction{
    static readonly type='[Property Uom] GET'
}
export class PropertyMasterPropetyTypeAction{
    static readonly type='[Property Type] GET'
}
<lib-alertmessage></lib-alertmessage>
<div class="row">
    <div class="">
        <div class="p-0  col-lg-12">
           
            <div class="m-auto">
                <div class="bdr_one_grey1 tabbed">
                    
                    <div class="form-check form-check-inline bdr_one_grey "
                        [ngClass]="{ 'active': propertyValuationRequestBean.buildingSubType=='Independent Building'}">

                        <input  class="form-check-input" type="radio"
                            [(ngModel)]="propertyValuationRequestBean.buildingSubType" name="inlineRadioOptions"
                            id="IndependentBuilding" value="Independent Building" class="w-auto">

                        <label class="form-check-label w-100 ic1" for="IndependentBuilding"
                            (click)="buildingSubChangeEvent('Independent Building')">
                            {{'property-valuation.Independent-Building' | translate}}</label>

                    </div>

                    <div class="form-check form-check-inline bdr_one_grey"
                        [ngClass]="{ 'active': propertyValuationRequestBean.buildingSubType=='IndependentFloor'}">
                        <input  class="form-check-input" type="radio"
                            [(ngModel)]="propertyValuationRequestBean.buildingSubType" name="inlineRadioOptions"
                            id="IndependentFloor" value="IndependentFloor" class="w-auto">
                        <label class="form-check-label w-100 ic2" for="IndependentFloor"
                            (click)="buildingSubChangeEvent('IndependentFloor')">{{'property-valuation.Independent-Floor'
                            |
                            translate}}</label>
                    </div>

                    <div class="form-check form-check-inline bdr_one_grey"
                        [ngClass]="{ 'active': propertyValuationRequestBean.buildingSubType=='Multistorey'}">
                        <input  class="form-check-input" type="radio"
                            [(ngModel)]="propertyValuationRequestBean.buildingSubType" name="inlineRadioOptions"
                            id="Multistorey" value="Multistorey" class="w-auto">
                        <label class="form-check-label w-100 ic3" for="Multistorey"
                            (click)="buildingSubChangeEvent('Multistorey')">{{'property-valuation.Multistorey' |
                            translate}}</label>
                    </div>

                    <div class="form-check form-check-inline bdr_one_grey"
                        [ngClass]="{ 'active': propertyValuationRequestBean.buildingSubType=='Open Terrace'}">
                        <input #input4 class="form-check-input" type="radio"
                            [(ngModel)]="propertyValuationRequestBean.buildingSubType" name="inlineRadioOptions"
                            id="OpenTerrace" value="Open Terrace" class="w-auto">
                        <label class="form-check-label w-100 ic4" for="OpenTerrace"
                            (click)="buildingSubChangeEvent('Open Terrace')">{{'property-valuation.Open-Terrace' |
                            translate}}</label>
                    </div>

                </div>

            </div>
           
            <div class="card filterArea border-curve1">
               
                <div class="card-body p-1" >
                    <div class="moreFiler">
                        <div class="row">
                            <div class="col-md-12"
                                *ngIf="propertyValuationRequestBean.buildingSubType=='Independent Building'&& !propertyValuationRequestBean.isIndustrial">
                                <label class="btn btn-sm rounded-0 text-left p-0" style="font: size 80%; ;">
                                    <input type="checkbox" name="housing_board" value="true"
                                        [(ngModel)]="propertyValuationRequestBean.isHousingBoard"
                                        (ngModelChange)="housingBoardEvent($event)" id="housing_board">
                                    {{'housing_board' | translate}}
                                </label>
                            </div>
                            <div class="col-md-12"
                            *ngIf="(propertyValuationRequestBean.buildingSubType=='Independent Building'&& !propertyValuationRequestBean.isResidentialCumCommercial) && propertyValuationRequestBean.isIndustrial">
                            <label class="btn btn-sm rounded-0 text-left p-0" style="font: size 80%; ;">
                                <input type="checkbox" name="housing_board" value="true"
                                    [(ngModel)]="propertyValuationRequestBean.isIndustrialDevAuth"
                                    (ngModelChange)="housingBoardEvent($event)" id="housing_board">
                                {{'isIndustrial_Dev_Auth' | translate}}
                            </label>
                        </div>
                        <div class="col-md-12"
                        *ngIf="(propertyValuationRequestBean.buildingSubType=='Independent Building' && !propertyValuationRequestBean.isResidentialCumCommercial)&& propertyValuationRequestBean.isIndustrialDevAuth && propertyValuationRequestBean.isIndustrial">
                        <label class="btn btn-sm rounded-0 text-left p-0" style="font: size 80%; ;">
                            <input type="checkbox" name="housing_board" value="true"
                                [(ngModel)]="propertyValuationRequestBean.isSuperSturct"
                                (click)="housingBoardEvent()" id="housing_board">
                            {{'isSuper_Sturct' | translate}}
                        </label>
                    </div>
                            <small class="alert alert-warning  col-12 p-1 m-0 bdr3" role="alert">
                                {{'The_sum_of_area-plot' | translate}}
                            </small>
                            <div class="col pl-3" *ngIf="!isPartialTransact; else isPartialTransactfalse" >

                                <h3 class="text-primary mb-0">{{'property-valuation.Total-Area' | translate}}
                                    <span class="rstarcolor">*</span>
                                </h3>
                                <input #sourceInput type="text" placeholder="{{'property-valuation.Total-Area' | translate}}"
                                    maxlength="12" [(ngModel)]="propertyValuationRequestBean.totalArea" 
                                    (input)="targetInput.value = sourceInput.value"
                                    #TotalArea="ngModel"
                                    TwoDigitDecimalNumber
                                    appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                    [disabled]="propertyValuationRequestBean.isHousingBoard||propertyValuationRequestBean.isSuperSturct"
                                    
                                    (keyup)="inputChangeEventValidation()" class="form-control form-control-sm">
                                <div *ngIf="totalAreaMsg&&!propertyValuationRequestBean.totalArea"
                                    class="custom-invalid-feedback">
                                    <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>

                                </div>
                                <div *ngIf="isAgriAreaMsg"
                                class="custom-invalid-feedback">
                                <div *ngIf="isAgriAreaMsg">{{lan==0?'Building Area always less than to Agriculture Area':'भवन क्षेत्र सदैव कृषि क्षेत्र से कम होता है'}}</div>

                            </div>

                            </div>
                            <ng-template #isPartialTransactfalse>
                            <div class="col pl-3" >

                                <h3 class="text-primary mb-0">{{'property-valuation.Total-Area' | translate}}
                                    <span class="rstarcolor">*</span>
                                </h3>
                                <input type="text" placeholder="{{'property-valuation.Total-Area' | translate}}" readonly
                                    maxlength="12" [(ngModel)]="propertyValuationRequestBean.overAllArea" 
                                    appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                    TwoDigitDecimalNumber
                                    class="form-control form-control-sm">
                                <div *ngIf="totalAreaMsg&&!propertyValuationRequestBean.overAllArea"
                                    class="custom-invalid-feedback">
                                    <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>

                                </div>

                            </div>
                            </ng-template>
                            <div class="col pl-2">
                                <h3 class="text-primary mb-0">{{'property-valuation.Unit' | translate}}</h3>
                                <select class="form-control form-control-sm"
                                    [(ngModel)]="propertyValuationRequestBean.unit" #unit="ngModel"
                                    [disabled]="(mainAreaType==2&&propertyValuationRequestBean.isConstOnAgriLand==true)">
                                 
                                    <option *ngFor="let unt of uomList | slice:0:2" [value]="unt">
                                        <div *ngIf="lan==0">{{convertedUOM(unt)}}</div>
                                        <div *ngIf="lan==1">{{convertedUOM(unt)}}</div>
                                    </option>
                                </select>
                            </div>



                            <ng-container class="col-md-12">
                                <label class="col-md-12 mt-3" style="font-size: 90%;">
                                    <input type="checkbox" name="isPartialTransact"
                                        [(ngModel)]="propertyValuationRequestBean.isPartialTransact"
                                        (change)="onCheckDataPush($event)"
                                         class="w-auto h-10"
                                        placeholder="{{'property-valuation.partial-Area-received-building' | translate}}">

                                    {{'property-valuation.partial-Area-received-building' |translate}}


                                </label>
                                <div class="col-md-12 mt-2">
                                    <label class="w-100">{{'valuationproperty.TransactArea' | translate}}</label>

                                 <div class="col-md-6 pl-0">
                                    <input #targetInput type="text" name="partialTransactArea" 
                                    [disabled]="!propertyValuationRequestBean.isPartialTransact"
                                    [(ngModel)]="propertyValuationRequestBean.partialTransactArea"
                                   
                                    TwoDigitDecimalNumber
                                    appPositiveNumbersOnlyDirective
                                    (keyup)="validationLessThan($event)"

                                    class="form-control"
                                    placeholder="{{'valuationproperty.TransactArea' | translate}}">
                                 </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>


                    <fieldset class="m-2">

                        <div class="moreFiler p-0"
                            *ngIf="propertyValuationRequestBean.buildingSubType=='Independent Building'">
                            <h3 class="text-primary mb-0">{{'property-valuation.Usage-Type' | translate}} <span
                                    class="rstarcolor">*</span>
                            </h3>

                            <div class="">
                                <div class="btn-group btn-group-toggle d-block " data-toggle="buttons">
                                    <div class="shalash p-0 py-2 d-flex">
                                        <div class="col-md-6 p-0 line-height30">
                                        {{'property-valuation.Residential-Cum-Commercial' | translate}}</div>
                                        <div class="d-flex col-md-6">
                                            <label class="btn btn-sm rounded-0 bg-none color-dark p-1 label-line">
                                                <input type="radio" name="buildingAreaType1" class="w-auto h-auto mr-1"
                                                    [(ngModel)]="propertyValuationRequestBean.isResidentialCumCommercial"
                                                    (ngModelChange)="userTypeEvent($event);"
                                                    [value]="true">{{'property-valuation.Yes' |
                                                translate}}
                                            </label>
                                        

                                            <label class="btn btn-sm rounded-0 bg-none color-dark label-line p-1">
                                                <input type="radio" name="buildingAreaType2" class="w-auto mr-1"
                                                    [(ngModel)]="propertyValuationRequestBean.isResidentialCumCommercial"
                                                    (ngModelChange)="userTypeEvent($event)" [value]="false">
                                                {{'property-valuation.No' | translate}}
                                            </label>
                                        </div>

                                    </div>
                                    <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber [appCopyPastValidation]="true"
                                        maxlength="12" [disabled]="this.propertyValuationRequestBean.isHousingBoard"
                                        *ngIf="propertyValuationRequestBean.isResidentialCumCommercial"
                                        (keyup)="setIndBuildingValue('ResidentialCumCommercial',$event)"
                                        id="isResidentialCumCommercialttl"
                                        [(ngModel)]="propertyValuationRequestBean.residentialCumCommercialPlotArea"
                                        placeholder="{{'property-valuation.Residential-Cum-Commercial-area' | translate}}"
                                        class="form-control form-control-sm col-md-5">

                                    <div *ngIf="!propertyValuationRequestBean.isResidentialCumCommercial"
                                        class="row col-md-12 p-0 m-0 newinput">
                                        <div class="col-md-4 pl-0 pb-2">
                                            <label class="btn btn-sm rounded-0 p-0">
                                                <input type="checkbox"
                                                    [(ngModel)]="propertyValuationRequestBean.isResidensial"
                                                    name="buildingAreaType3"
                                                    [value]="propertyValuationRequestBean.isResidensial==false ? true :false"
                                                    (ngModelChange)="checkBoxEvent($event,'residensial')">
                                                {{'property-valuation.Residensial' | translate}}


                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber
                                                [appCopyPastValidation]="true" maxlength="12"
                                                [hidden]="!propertyValuationRequestBean.isResidensial"
                                                (keyup)="setIndBuildingValue('residential',$event)"
                                                [disabled]="this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct"
                                                id="isResidensialttl" 
                                                [(ngModel)]="propertyValuationRequestBean.residentialPlotArea"
                                                #resi="ngModel"
                                                placeholder="{{'property-valuation.Residential-area' | translate}}"
                                                class="form-control form-control-sm col-md-5">

                                                <div *ngIf="isResMsg&&(propertyValuationRequestBean.residentialPlotArea==''||propertyValuationRequestBean.residentialPlotArea==null)"
                                                class="custom-invalid-feedback">
                                                <div *ngIf="isResMsg">{{'mandatory' | translate}}
                                                </div>
                                                </div>
                                        </div>

                                        <div class="col-md-4 pl-0 pb-2">
                                            <label class="btn btn-sm rounded-0 p-0">
                                                <input type="checkbox"
                                                    [(ngModel)]="propertyValuationRequestBean.isCommercial"
                                                    name="buildingAreaType4"
                                                    [value]="propertyValuationRequestBean.isCommercial==false ? true :false"
                                                    (ngModelChange)="checkBoxEvent($event,'commercial')" id="commercial">
                                                {{'property-valuation.Commercial' | translate}}
                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber
                                                [appCopyPastValidation]="true" maxlength="12"
                                                *ngIf="propertyValuationRequestBean.isCommercial"
                                                (keyup)="setIndBuildingValue('commercial',$event)"
                                                [disabled]="this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct"
                                                id="isCommercialttl"
                                                [(ngModel)]="propertyValuationRequestBean.commercialPlotArea"
                                                placeholder="{{'property-valuation.commercial-area' | translate}}"
                                                class="form-control form-control-sm col-md-5">
                                                <div *ngIf="isCommMsg&&(propertyValuationRequestBean.commercialPlotArea==''||propertyValuationRequestBean.commercialPlotArea==null)"
                                                class="custom-invalid-feedback">
                                                <div *ngIf="isCommMsg">{{'mandatory' | translate}}
                                                </div>
                                                </div>
                                        </div>


                                        <div class="col-md-4 pl-0 pb-2">
                                            <label class="btn btn-sm rounded-0 p-0">
                                                <input type="checkbox"
                                                [disabled]="propertyValuationRequestBean.isHousingBoard"
                                                    [(ngModel)]="propertyValuationRequestBean.isIndustrial"
                                                    name="buildingAreaType5" id="industrial"
                                                    [value]="propertyValuationRequestBean.isIndustrial==false ? true :false"
                                                    (ngModelChange)="checkBoxEvent($event,'industrial')">
                                                {{'property-valuation.Industrial' |
                                                translate}}
                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber
                                                [appCopyPastValidation]="true" maxlength="12"
                                                *ngIf="propertyValuationRequestBean.isIndustrial"
                                                (keyup)="setIndBuildingValue('industrial',$event)"
                                                [disabled]="this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct"
                                                id="isIndustrialttl"
                                                [(ngModel)]="propertyValuationRequestBean.industrialPlotArea"
                                                placeholder="{{'property-valuation.Industrial-area' | translate}}"
                                                class="form-control form-control-sm col-md-5">
                                                <div *ngIf="isIndusMsg&&(propertyValuationRequestBean.industrialPlotArea==''||propertyValuationRequestBean.industrialPlotArea==null)"
                                                class="custom-invalid-feedback">
                                                <div *ngIf="isIndusMsg">{{'mandatory' | translate}}
                                                </div>
                                                </div>
                                        </div>

                                        <div class="col-md-4 pl-0 pb-2">
                                            <label class="btn btn-sm rounded-0 p-0">
                                                <input type="checkbox"
                                                    [(ngModel)]="propertyValuationRequestBean.isHealth" name="health"
                                                    [value]="propertyValuationRequestBean.isHealth==false ? true :false"
                                                    (ngModelChange)="checkBoxEvent($event,'health')" id="health">
                                                {{'property-valuation.Health' | translate}}
                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber
                                                [appCopyPastValidation]="true" maxlength="12"
                                                *ngIf="propertyValuationRequestBean.isHealth"
                                                (keyup)="setIndBuildingValue('health',$event)"
                                                [disabled]="this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct"
                                                id="isHealthttl"
                                                [(ngModel)]="propertyValuationRequestBean.healthPlotArea"
                                                placeholder="{{'property-valuation.Health-area' | translate}}"
                                                class="form-control form-control-sm col-md-5">
                                                <div *ngIf="isHlthMsg&&(propertyValuationRequestBean.healthPlotArea==''||propertyValuationRequestBean.healthPlotArea==null)"
                                                class="custom-invalid-feedback">
                                                <div *ngIf="isHlthMsg">{{'mandatory' | translate}}
                                                </div>
                                                </div>
                                        </div>

                                        <div class="col-md-4 pl-0 pb-2">
                                            <label class="btn btn-sm rounded-0 p-0">
                                                <input type="checkbox"
                                                    [(ngModel)]="propertyValuationRequestBean.isEduArea"
                                                    name="buildingAreaType6"
                                                    [value]="propertyValuationRequestBean.isEduArea==false ? true :false"
                                                    (ngModelChange)="checkBoxEvent($event,'education')" id="education">
                                                {{'property-valuation.Education' | translate}}
                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber
                                                [appCopyPastValidation]="true" maxlength="12"
                                                *ngIf="propertyValuationRequestBean.isEduArea"
                                                [disabled]="this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct"
                                                [(ngModel)]="propertyValuationRequestBean.eduPlotArea" id="isEduAreattl"
                                                (keyup)="setIndBuildingValue('edu',$event)"
                                                placeholder="{{'property-valuation.Education-area' | translate}}"
                                                class="form-control form-control-sm col-md-5">
                                                <div *ngIf="isEduMsg&&(propertyValuationRequestBean.eduPlotArea==''||propertyValuationRequestBean.eduPlotArea==null)"
                                                class="custom-invalid-feedback">
                                                <div *ngIf="isEduMsg">{{'mandatory' | translate}}
                                                </div>
                                                </div>
                                        </div>

                                        <div class="col-md-4 pl-0 pb-2">
                                            <label class="btn btn-sm rounded-0 p-0">
                                                <input type="checkbox"
                                                    [(ngModel)]="propertyValuationRequestBean.isOthers"
                                                    name="buildingAreaType7"
                                                    [value]="propertyValuationRequestBean.isOthers==false ? true :false"
                                                    (ngModelChange)="checkBoxEvent($event,'other')" id="other">
                                                {{'property-valuation.other' | translate}}
                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective TwoDigitDecimalNumber
                                                [appCopyPastValidation]="true" maxlength="12"
                                                *ngIf="propertyValuationRequestBean.isOthers"
                                                [disabled]="this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct"
                                                [(ngModel)]="propertyValuationRequestBean.othersPlotArea"
                                                id="isOthersttl" (keyup)="setIndBuildingValue('other',$event)"
                                                placeholder="{{'property-valuation.other' | translate}}"
                                                class="form-control form-control-sm col-md-5">
                                                <div *ngIf="isOthMsg&&(propertyValuationRequestBean.othersPlotArea==''||propertyValuationRequestBean.othersPlotArea==null)"
                                                class="custom-invalid-feedback">
                                                <div *ngIf="isOthMsg">{{'mandatory' | translate}}
                                                </div>
                                                </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>


                        <!-- [hideSelected]="true" --> 
                        <div class="moreFiler p-0"
                            *ngIf="propertyValuationRequestBean.buildingSubType=='Independent Building'">
                            <label class="btn btn-sm d-flex bg_grey mt-1 ht-10per"><span
                                    class="col-md-4 text-left p-0 p-2">{{'property-valuation.Floor-type'
                                    | translate}} <span class="rstarcolor">*</span></span>

                                <ng-select [multiple]="true" [(ngModel)]="selectedFloors" 
                                    (change)="floorChangeEvent($event);addFloorInCurrentList($event)"
                                    [maxSelectedItems]="20" (remove)="onSelectDropdownClosed($event)"
                                    placeholder="{{'property-valuation.Floor-type' | translate}}"
                                    class="text-left col-md-8 p-0">
                                    <ng-option *ngFor="let flrt of floorList|limitTo: floorIndex" value="{{flrt.id}}">
                                       {{lan==0?flrt.nameEn:flrt.nameHi}}
                                       </ng-option>
                             
                                </ng-select>

                               
                            </label>
                            <div *ngIf="selectFloorValid&&selectedFloors!=null"
                            class="custom-invalid-feedback">
                            <div *ngIf="selectFloorValid&&selectedFloors!=null">{{lan==0?'Error !Please select floor ':'कृपया मंज़िल  चयन करें'}}
                            </div>
                            </div>
                            <div *ngIf="floorOrderValidation"
                            class="custom-invalid-feedback">
                            <div *ngIf="floorOrderValidation">{{lan==0?'Please select floor order wise':'कृपया मंज़िल क्रमवार चयन करें'}}
                            </div>
                            </div>
                            <!-- *ngFor="let sbc of subClauseMasterBean" -->

                            <div *ngFor="let flr of selectedFloors , let i=index">

                                <div class="btn-group btn-group-toggle d-block floortypedd" data-toggle="buttons">

                                    <!-- <div class="moreFiler p-0" *ngIf="propertyValuationRequestBean.isResidentialCumCommercial && mainAreaType==2"> -->
                                    <div class="moreFiler p-0"
                                        *ngIf="propertyValuationRequestBean.isResidentialCumCommercial">
                                        <div class="row m-0">
                                            <label class="btn btn-sm rounded-0 m-0 p-0 line-ht38">
                                                {{showFloorName(flr)}}
                                                {{'property-valuation.Residential-Cum-Commercial-Total-Area'
                                                | translate}}
                                            </label>
                                            <input type="text" appPositiveNumbersOnlyDirective [hidden]="true"
                                                [appCopyPastValidation]="true" maxlength="12"
                                                *ngIf="propertyValuationRequestBean.isResidentialCumCommercial"
                                                id="RCumCom{{flr}}"
                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','RCumCom','subtypeinput')"
                                                placeholder="{{'property-valuation.Residential-Cum-Commercial-area' | translate}}"
                                                class="form-control form-control-sm col-md-5">
                                          
                                        </div>
                                        <fieldset class="fieldset_cls p-0 m-0 pb-2">
                                            <legend>
                                                <h3 class="text-primary mb-0">{{showFloorName(flr)}}
                                                    {{'property-valuation.Construction-Type' |
                                                    translate}}</h3>
                                            </legend>

                                            <div class="d-flex no-check-box">
                                                <div class="btn-group btn-group-toggle row px-2" data-toggle="buttons">
                                                    <div class="col-md-6 d-flex">
                                                        <label class="btn btn-sm  rounded-0 col-md-6 p-0 m-0 mt-1 text-left">
                                                            <input type="checkbox" value="rcc" name="rcc" id="rcc">
                                                            {{'property-valuation.RCC' |
                                                            translate}}
                                                        </label>
                                                        <input type="text" id="rcc{{flr}}"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','rcc')"
                                                            placeholder="{{'property-valuation.RCC' | translate}}"
                                                            class="form-control form-control-sm col-md-6 mb-1">
                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <label class="btn btn-sm rounded-0 col-md-6 p-0 m-0 mt-1 text-left">
                                                            <input type="checkbox" value="rbc" name="rbc"
                                                                id="rbc">{{'property-valuation.RBC' |
                                                            translate}}
                                                        </label>
                                                        <input type="text" id="rbc{{flr}}"
                                                            placeholder="{{'property-valuation.RBC' | translate}}"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','rbc')"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true"
                                                            class="form-control form-control-sm col-md-6 mb-1">
                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <label class="btn btn-sm m-0 rounded-0 col-md-6 p-0 text-left">
                                                            <input type="checkbox" value="kacchaKabelu"
                                                                name="kacchaKabelu" id="kacchaKabelu">
                                                            {{'property-valuation.Kaccha-Kabelu' |
                                                            translate}}
                                                        </label>
                                                        <input type="text" id="kacchaKabelu{{flr}}"
                                                            placeholder="{{'property-valuation.Kaccha-Kabelu' | translate}}"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','kacchaKabelu')"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true"
                                                            class="form-control form-control-sm col-md-6 mb-1">
                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <label class="btn btn-sm rounded-0 col-md-6 m-0 p-0 mt-1 text-left">
                                                            <input type="checkbox" value="tinShade" name="tinShade"
                                                                id="tinShade">
                                                            {{'property-valuation.Tin-Shade' |
                                                            translate}}
                                                        </label>
                                                        <input type="text" id="tinShade{{flr}}"
                                                            placeholder="{{'property-valuation.Tin-Shade' | translate}}"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','tinShade')"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true"
                                                            class="form-control form-control-sm col-md-6 mb-1">
                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <label class="btn btn-sm rounded-0 col-md-6 m-0 mt-1 p-0 text-left">
                                                            <input type="checkbox" value="shopArea" name="shopArea"
                                                                id="shopArea">
                                                            {{'property-valuation.Shop-Area' |
                                                            translate}}
                                                        </label>
                                                        <input type="text" id="shopArea{{flr}}"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true" maxlength="12"
                                                            (keyup)="inputChangeEventValidation()"
                                                            placeholder="{{'property-valuation.Shop-Area' | translate}}"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','shopArea')"
                                                            class="form-control form-control-sm col-md-6 mb-1">

                                                    </div>
                                                    <div class="col-md-6 d-flex">
                                                        <label class="btn btn-sm rounded-0 col-md-6 m-0 mt-1 p-0 text-left">
                                                            <input type="checkbox" value="officeArea" name="officeArea"
                                                                id="officeArea">
                                                            {{'property-valuation.Office-Area' |
                                                            translate}}
                                                        </label>
                                                        <input type="text" id="officeArea{{flr}}"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true" maxlength="12"
                                                            (keyup)="inputChangeEventValidation()"
                                                            placeholder="{{'property-valuation.Office-Area' | translate}}"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','officeArea')"
                                                            class="form-control form-control-sm col-md-6 mb-1">

                                                    </div>
                                                    <div class="col-md-6 d-flex">

                                                        <label class="btn btn-sm rounded-0 col-md-6 p-0 m-0 text-left">
                                                            <input type="checkbox" value="godownArea" name="godownArea"
                                                                id="godownArea">{{'property-valuation.Godown-Area'
                                                            | translate}}
                                                        </label>
                                                        <input type="text" id="godownArea{{flr}}"
                                                            appPositiveNumbersOnlyDirective
                                                            [appCopyPastValidation]="true" maxlength="12"
                                                            (keyup)="inputChangeEventValidation()"
                                                            placeholder="{{'property-valuation.Godown-Area' | translate}}"
                                                            (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'isResidentialCumCommercial','godownArea')"
                                                            class="form-control form-control-sm col-md-6 mb-1">
                                                    </div>

                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div *ngIf="propertyValuationRequestBean.isResidentialCumCommercial==false">
                                        <div class="moreFiler row input-width-set"
                                            *ngIf="propertyValuationRequestBean.isResidensial && mainAreaType==2">
                                            <fieldset class="fieldset_cls">
                                                <div class="col-md-12 row">
                                                    <label class="btn btn-sm rounded-0 col-md-12 text-left">
                                                        {{showFloorName(flr)}}
                                                        {{'property-valuation.Residential' | translate}}
                                                    </label>
                                                    <input type="text" id="residensial{{flr}}" [hidden]="true"
                                                        (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'residensial','residensialttl','subtypeinput')"
                                                        placeholder="{{'property-valuation.Residential-area' | translate}}"
                                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                                        class="form-control form-control-sm  col-md-6">
                                                </div>
                                                <legend>
                                                    <!-- <h3 class="text-primary">{{showFloorName(flr)}}
                                                    {{'property-valuation.Residensial-Construction-Type'
                                                    | translate}}</h3> -->
                                                </legend>
                                                <div class="d-flex">
                                                    <div class="btn-group btn-group-toggle v-center row no-check-box"
                                                        data-toggle="buttons">
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rcc" name="rcc" id="rcc">
                                                                {{'property-valuation.RCC' | translate}}
                                                            </label>
                                                            <input type="text" id="residensialrcc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'residensial','rcc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RCC' | translate}}"
                                                                class="form-control form-control-sm ng-dirty  col-md-6">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0  col-md-6 text-left">
                                                                <input type="checkbox" value="rbc" name="rbc"
                                                                    id="rbc">{{'property-valuation.RBC'
                                                                |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="residensialrbc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'residensial','rbc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RBC' | translate}}"
                                                                class="form-control form-control-sm  col-md-6">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm  rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="kacchaKabelu"
                                                                    name="kacchaKabelu" id="kacchaKabelu">
                                                                {{'property-valuation.Kaccha-Kabelu' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="residensialkacchaKabelu{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'residensial','kacchaKabelu')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Kaccha-Kabelu' | translate}}"
                                                                class="form-control form-control-sm col-md-6 mt-2">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6  text-left">
                                                                <input type="checkbox" value="tinShade" name="tinShade"
                                                                    id="tinShade">
                                                                {{'property-valuation.Tin-Shade' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="residensialtinShade{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'residensial','tinShade')"
                                                                placeholder="{{'property-valuation.Tin-Shade' | translate}}"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                class="form-control form-control-sm col-md-6">
                                                        </div>

                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>


                                        <div class="moreFiler row input-width-set"
                                            *ngIf="propertyValuationRequestBean.isCommercial">
                                            <fieldset class="fieldset_cls">
                                                <div class="col-md-12 row">
                                                    <label class="btn btn-sm rounded-0">
                                                        {{showFloorName(flr)}}
                                                        {{'property-valuation.Commercial' | translate}}
                                                    </label>
                                                    <input type="text" id="commercial{{flr}}" [hidden]="true"
                                                        (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'commercial','commercialttl','subtypeinput')"
                                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                                        placeholder="{{'property-valuation.Commercial-area' | translate}}"
                                                        class="form-control form-control-sm col-md-6">
                                                </div>
                                                <legend>
                                                    <!-- <h3 class="text-primary">{{showFloorName(flr)}}
                                                    {{'property-valuation.Commercial-Construction-Type'
                                                    | translate}}</h3> -->
                                                </legend>
                                                <div class="d-flex">
                                                    <div class="btn-group btn-group-toggle v-center row no-check-box"
                                                        data-toggle="buttons">
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="shopArea" name="shopArea"
                                                                    id="shopArea">
                                                                {{'property-valuation.Shop-Area' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="commercialshopArea{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'commercial','shopArea')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Shop-Area' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="officeArea"
                                                                    name="officeArea" id="officeArea">
                                                                {{'property-valuation.Office-Area' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="commercialofficeArea{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'commercial','officeArea')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Office-Area' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="godownArea"
                                                                    name="godownArea"
                                                                    id="godownArea">{{'property-valuation.Godown-Area'
                                                                | translate}}
                                                            </label>
                                                            <input type="text" id="commercialgodownArea{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'commercial','godownArea')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Godown-Area' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>

                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>


                                        <div class="moreFiler row input-width-set"
                                            *ngIf="propertyValuationRequestBean.isIndustrial">
                                            <fieldset class="fieldset_cls">
                                                <div class="col-md-12 row">
                                                    <label class="btn btn-sm rounded-0">
                                                        {{showFloorName(flr)}}
                                                        {{'property-valuation.Industrial' | translate}}
                                                    </label>
                                                    <input type="text" id="industrial{{flr}}" [hidden]="true"
                                                        (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'industrial','Industrialttl','subtypeinput')"
                                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                                        placeholder="{{'property-valuation.Industrial-area' | translate}}"
                                                        class="form-control form-control-sm col-md-6">
                                                </div>
                                                <legend>
                                                    <!-- <h3 class="text-primary">{{showFloorName(flr)}}
                                                    {{'property-valuation.Industrial-Construction-Type'
                                                    | translate}}</h3> -->
                                                </legend>
                                                <div class="d-flex">
                                                    <div class="btn-group btn-group-toggle v-center row no-check-box"
                                                        data-toggle="buttons">
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rcc" name="rcc" id="rcc">
                                                                {{'property-valuation.RCC' | translate}}
                                                            </label>
                                                            <input type="text" id="industrialrcc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'industrial','rcc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RCC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rbc" name="rbc"
                                                                    id="rbc">{{'property-valuation.RBC' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="industrialrbc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'industrial','rbc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RBC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="kacchaKabelu"
                                                                    name="kacchaKabelu"
                                                                    id="kacchaKabelu">{{'property-valuation.Kaccha-Kabelu'
                                                                | translate}}
                                                            </label>
                                                            <input type="text" id="industrialkacchaKabelu{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'industrial','kacchaKabelu')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Kaccha-Kabelu' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">

                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="tinShade" name="tinShade"
                                                                    id="tinShade">
                                                                {{'property-valuation.Tin-Shade' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="industrialtinShade{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'industrial','tinShade')"
                                                                placeholder="{{'property-valuation.Tin-Shade' | translate}}"
                                                                class="form-control form-control-sm">

                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="moreFiler row input-width-set"
                                            *ngIf="propertyValuationRequestBean.isHealth">
                                            <fieldset class="fieldset_cls">
                                                <div class="col-md-12 row">
                                                    <label class="btn btn-sm rounded-0 ">
                                                        {{showFloorName(flr)}}
                                                        {{'property-valuation.Health' | translate}}
                                                    </label>
                                                    <input type="text" id="health{{flr}}" [hidden]="true"
                                                        (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'health','healthttl','subtypeinput')"
                                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                                        placeholder="{{'property-valuation.Health-area' | translate}}"
                                                        class="form-control form-control-sm col-md-6">
                                                </div>

                                                <legend>
                                                
                                                </legend>
                                                <div class="d-flex">
                                                    <div class="btn-group btn-group-toggle v-center row no-check-box"
                                                        data-toggle="buttons">
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rcc" name="rcc" id="rcc">
                                                                {{'property-valuation.RCC' | translate}}
                                                            </label>
                                                            <input type="text" id="healthrcc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'health','rcc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RCC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rbc" name="rbc"
                                                                    id="rbc">{{'property-valuation.RBC' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="healthrbc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'health','rbc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RBC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="kacchaKabelu"
                                                                    name="kacchaKabelu" id="kacchaKabelu">
                                                                {{'property-valuation.Kaccha-Kabelu' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="healthkacchaKabelu{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'health','kacchaKabelu')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Kaccha-Kabelu' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="tinShade" name="tinShade"
                                                                    id="tinShade">
                                                                {{'property-valuation.Tin-Shade' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="healthtinShade{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'health','tinShade')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Tin-Shade' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <div class="moreFiler row input-width-set"
                                            *ngIf="propertyValuationRequestBean.isEduArea">
                                            <fieldset class="fieldset_cls">
                                                <div class="col-md-12 row">
                                                    <label class="btn btn-sm rounded-0 ">
                                                        {{showFloorName(flr)}} {{'property-valuation.Education'
                                                        | translate}}
                                                    </label>
                                                    <input type="text" id="edu{{flr}}" [hidden]="true"
                                                        (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'edu','eduttl','subtypeinput')"
                                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                                        placeholder="{{'property-valuation.Education' | translate}}"
                                                        class="form-control form-control-sm col-md-6">
                                                </div>
                                                <legend>
                                                    <!-- <h3 class="text-primary">{{showFloorName(flr)}}
                                                    {{'property-valuation.' | translate}}</h3> -->
                                                </legend>
                                              
                                                <div class="d-flex">
                                                    <div class="btn-group btn-group-toggle v-center row no-check-box"
                                                        data-toggle="buttons">
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rcc" name="rcc" id="rcc">
                                                                {{'property-valuation.RCC' | translate}}
                                                            </label>
                                                            <input type="text" id="edurcc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'edu','rcc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RCC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rbc" name="rbc"
                                                                    id="rbc">{{'property-valuation.RBC' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="edurbc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'edu','rbc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RBC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="kacchaKabelu"
                                                                    name="kacchaKabelu"
                                                                    id="kacchaKabelu">{{'property-valuation.Kaccha-Kabelu'
                                                                | translate}}
                                                            </label>
                                                            <input type="text" id="edukacchaKabelu{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'edu','kacchaKabelu')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Kaccha-Kabelu' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="tinShade" name="tinShade"
                                                                    id="tinShade">{{'property-valuation.Tin-Shade'
                                                                | translate}}
                                                            </label>
                                                            <input type="text" id="edutinShade{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'edu','tinShade')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Tin-Shade' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>

                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>


                                        <div class="moreFiler row input-width-set"
                                            *ngIf="propertyValuationRequestBean.isOthers">
                                            <fieldset class="fieldset_cls">
                                                <div class="col-md-12 row">
                                                    <label class="btn btn-sm rounded-0">
                                                        {{showFloorName(flr)}} {{'property-valuation.other'
                                                        | translate}}
                                                    </label>
                                                    <input type="text" id="other{{flr}}" [hidden]="true"
                                                        (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'other','otherttl','subtypeinput')"
                                                        appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                                        placeholder="{{'property-valuation.other' | translate}}"
                                                        class="form-control form-control-sm col-md-6">
                                                </div>
                                                <legend>
                                                    <!-- <h3 class="text-primary">{{showFloorName(flr)}}
                                                    {{'property-valuation.' | translate}}</h3> -->
                                                </legend>
                                           
                                                <div class="d-flex ">
                                                    <div class="btn-group btn-group-toggle v-center row no-check-box"
                                                        data-toggle="buttons">
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rcc" name="rcc" id="rcc">
                                                                {{'property-valuation.RCC' | translate}}
                                                            </label>
                                                            <input type="text" id="otherrcc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'other','rcc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RCC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="rbc" name="rbc"
                                                                    id="rbc">{{'property-valuation.RBC' |
                                                                translate}}
                                                            </label>
                                                            <input type="text" id="otherrbc{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'other','rbc')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.RBC' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="kacchaKabelu"
                                                                    name="kacchaKabelu"
                                                                    id="kacchaKabelu">{{'property-valuation.Kaccha-Kabelu'
                                                                | translate}}
                                                            </label>
                                                            <input type="text" id="otherkacchaKabelu{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'other','kacchaKabelu')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Kaccha-Kabelu' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>
                                                        <div class="col-md-6 d-flex">
                                                            <label class="btn btn-sm rounded-0 col-md-6 text-left">
                                                                <input type="checkbox" value="tinShade" name="tinShade"
                                                                    id="tinShade">{{'property-valuation.Tin-Shade'
                                                                | translate}}
                                                            </label>
                                                            <input type="text" id="othertinShade{{flr}}"
                                                                (keyup)="changeAddFloorUsaseTypeEvent($event,flr,'other','tinShade')"
                                                                appPositiveNumbersOnlyDirective
                                                                [appCopyPastValidation]="true"
                                                                placeholder="{{'property-valuation.Tin-Shade' | translate}}"
                                                                class="form-control form-control-sm">
                                                        </div>

                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <!--user type only building end here-->

                        <div class="moreFiler p-0"
                            *ngIf="propertyValuationRequestBean.buildingSubType=='Multistorey'">
                            <h3 class="text-primary mb-0"></h3>
                            <div class="twogrid">
                                <div class="btn-group bdrone" data-toggle="buttons">
                                    <label class="btn btn-sm active text-left mr-2">
                                        <input type="radio" [(ngModel)]="propertyValuationRequestBean.buildingType"
                                            (change)="changeFloor($event)" value="Residential" name="Residential"
                                            id="Residential">
                                        {{'property-valuation.Residential' | translate}}
                                    </label>
                                    <label class="btn btn-sm text-left ml-2">
                                        <input type="radio" name="Commercial"
                                            [(ngModel)]="propertyValuationRequestBean.buildingType"
                                            (change)="changeFloor($event)" value="Commercial" name
                                            id="Commercial">{{'property-valuation.Commercial-area1' |
                                        translate}}

                                    </label>
                                </div>
                            </div>
                            <div class="row input-width-set-50 px-2">
                                <label class="btn btn-sm col-md-6 text-left"
                                    *ngIf="propertyValuationRequestBean.buildingType!=''">{{'property-valuation.Buit-Up-Area'
                                    | translate}}<span class="rstarcolor">*</span>
                                    <input type="text" [(ngModel)]="propertyValuationRequestBean.buitupArea"
                                        (keyup)="inputChangeEventValidation()" appPositiveNumbersOnlyDirective
                                        TwoDigitDecimalNumber
                                        [appCopyPastValidation]="true"
                                        placeholder="{{'property-valuation.Buit-Up-Area' | translate}}"
                                        class="form-control form-control-sm w-100">
                                        <div *ngIf="propertyValuationRequestBean.totalArea!=null&&(propertyValuationRequestBean.buitupArea==''||propertyValuationRequestBean.buitupArea==null)"
                                        class="custom-invalid-feedback">
                                        <div *ngIf="propertyValuationRequestBean.totalArea!=null">{{'mandatory' | translate}}
                                        </div>
    
                                    </div>
                                       
                                </label>

                                <label class="btn btn-sm col-md-6 text-left"
                                    *ngIf="propertyValuationRequestBean.buildingType!=''">{{'property-valuation.Common-Area'
                                    | translate}} <span class="rstarcolor">*</span>
                                    <input type="text" disabled [(ngModel)]="propertyValuationRequestBean.commonArea"
                                        (keyup)="inputChangeEventValidation()" appPositiveNumbersOnlyDirective
                                        TwoDigitDecimalNumber
                                        [appCopyPastValidation]="true"
                                        placeholder="{{'property-valuation.Common-Area' | translate}}"
                                        class="form-control form-control-sm w-100">
                                        <div *ngIf="propertyValuationRequestBean.totalArea!=null&&(propertyValuationRequestBean.commonArea==''||propertyValuationRequestBean.commonArea==null)"
                                        class="custom-invalid-feedback">
                                        <div *ngIf="propertyValuationRequestBean.totalArea!=null">{{'mandatory' | translate}}
                                        </div>
                                    </div>
                                </label>
                            </div>

                        </div>


                      


                        <div class="moreFiler p-0" *ngIf="mainAreaType==2 && (propertyValuationRequestBean.buildingSubType=='IndependentFloor'
                            || propertyValuationRequestBean.buildingSubType=='Multistorey')">
                            <h3 class="text-primary mb-0">{{'property-valuation.Floor-type' | translate}}
                                <span class="rstarcolor">*</span>
                            </h3>
                            <div class="d-block bdrone">
                                <div class="btn-group d-block column2" data-toggle="buttons">
                                    <label class="btn btn-sm w-100 d-block" *ngFor="let flrt of floorList|limitTo: multiSoryFloorIndex"
                                        [ngClass]="{ 'active': propertyValuationRequestBean.floorType==flrt.id }">
                                        <input type="radio" (ngModelChange)="inputChangeEventValidation()"
                                            [(ngModel)]="propertyValuationRequestBean.floorType" name="flrt"
                                            [value]="flrt.id"
                                            id="usage_flr_cc_{{flrt.id}}">{{lan==0?flrt.nameEn:flrt.nameHi}}
                                    </label>
                                    <div *ngIf="propertyValuationRequestBean.floorType==''||propertyValuationRequestBean.floorType==null"
                                    class="custom-invalid-feedback">
                                    <div *ngIf="propertyValuationRequestBean.floorType==''||propertyValuationRequestBean.floorType==null">{{lan==0?'Please select floor Type':'कृपया मंज़िल  चयन करें'}}</div>

                                </div>
                                </div>
                               
                            </div>
                        </div>


                        <div class="moreFiler p-0"
                            *ngIf="propertyValuationRequestBean.buildingType=='Commercial'&&propertyValuationRequestBean.buildingSubType=='Multistorey'">
                            <h3 class="text-primary mb-0">{{'property-valuation.Select-the-Property-Type' |
                                translate}} <span class="rstarcolor">*</span></h3>
                            <div class="d-flex bdrone">
                                <div class="btn-group" data-toggle="buttons">
                                    <label class="btn btn-sm "
                                        [ngClass]="{'active': propertyValuationRequestBean.multistoryCommercialPopType=='Shop' }">
                                        <input type="radio"
                                            [(ngModel)]="propertyValuationRequestBean.multistoryCommercialPopType"
                                            name="Shop" value="Shop" id="Shop">
                                        {{'property-valuation.Shop' | translate}}
                                    </label>
                                    <label class="btn btn-sm">
                                        <input type="radio"
                                            [(ngModel)]="propertyValuationRequestBean.multistoryCommercialPopType"
                                            name="Office" value="Office" id="Office">{{'property-valuation.Office' |
                                        translate}}
                                    </label>
                                    <label class="btn btn-sm ">
                                        <input type="radio"
                                            [(ngModel)]="propertyValuationRequestBean.multistoryCommercialPopType"
                                            name="Godown" value="Godown" id="Godown">
                                        {{'property-valuation.Godown' | translate}}
                                    </label>
                                
                                </div>
                            </div>
                        </div>



                        <div class="moreFiler p-0 mb-2"
                            *ngIf="((propertyValuationRequestBean.buildingSubType=='IndependentFloor')
                            || ((propertyValuationRequestBean.buildingSubType=='Independent Building'
                             && propertyValuationRequestBean.isResidensial==true)
                             &&(propertyValuationRequestBean.isCommercial!=true
                             &&propertyValuationRequestBean.isIndustrial!=true
                             &&propertyValuationRequestBean.isHealth!=true
                             &&propertyValuationRequestBean.isEduArea!=true
                             &&propertyValuationRequestBean.isOthers!=true))
                             || (propertyValuationRequestBean.buildingSubType=='Multistorey'&&propertyValuationRequestBean.buildingType=='Residential'))">
                            <h3 class="text-primary mb-0">{{'property-valuation.Construction-Time' |
                                translate}} <span class="rstarcolor">*</span></h3>
                            <div class="d-flex bdrone">
                                <select class="form-control form-control-sm" name="constructionTime"
                                    [(ngModel)]="propertyValuationRequestBean.constrnTime"
                                    (ngModelChange)="inputChangeEventValidation()">
                                    <option [disabled]="true">
                                        {{'property-valuation.choose-construction-year' |translate }}</option>
                                    <option *ngFor="let yr of yearList" [value]="yr">{{yr}}</option>

                                </select>
                                <div *ngIf="constrnIsValid&&(propertyValuationRequestBean.constrnTime==''||propertyValuationRequestBean.constrnTime==null)"
                                class="custom-invalid-feedback">
                                <div *ngIf="constrnIsValid&&(propertyValuationRequestBean.constrnTime==''||propertyValuationRequestBean.constrnTime==null)">
                                    {{'mandatory' | translate}}
                                </div>

                            </div>

                            </div>
                        </div>


                        <div class="moreFiler mb-0 p-0"
                            *ngIf="(mainAreaType==2) && (propertyValuationRequestBean.buildingSubType=='Multistorey'||propertyValuationRequestBean.buildingSubType=='IndependentFloor')">
                            <h3 class="text-primary mb-0"> {{'property-valuation.CONSTRUCTION-DETAILS' | translate}}
                            </h3>
                            <hr class="m-0" />
                            <label class="btn btn-sm m-0 p-0 d-flex">
                                <input type="checkbox" [(ngModel)]="propertyValuationRequestBean.liftFacility"
                                    value="true" name="liftFacility" id="liftFacility">
                             
                                <div *ngIf="propertyValuationRequestBean.buildingType=='Commercial'">
                                    {{'property-valuation.Is-the-mall-available-in-the-Building' | translate}}
                                </div>
                                <div *ngIf="propertyValuationRequestBean.buildingType!='Commercial'">
                                    {{'property-valuation.Is-the-Lift-facility-available-in-the-Building' | translate}}
                                </div>
                            </label>
                        </div>

                        <div class="moreFiler p-0"
                            *ngIf="(mainAreaType==2) && (propertyValuationRequestBean.buildingSubType=='Multistorey'||propertyValuationRequestBean.buildingSubType=='IndependentFloor')">
                            <label class="btn btn-sm m-0 p-0">
                                <input type="checkbox" [(ngModel)]="propertyValuationRequestBean.transactOpenTerrace"
                                    value="true" name="transactOpenTerrace"
                                    id="transactOpenTerrace">{{'property-valuation.Do-you-want-to-transact-open-terrace'
                                | translate}}
                            </label>
                        </div>

                        <div class="moreFiler p-0"
                            *ngIf="(propertyValuationRequestBean.transactOpenTerrace&&propertyValuationRequestBean.buildingSubType!='Independent Building')||(mainAreaType==2 && propertyValuationRequestBean.buildingSubType=='Open Terrace')">
                            <label class="btn btn-sm col-md-12 text-left p-0 pl-1" style="line-height:30px">
                                {{'property-valuation.Select-Conveyance-type-of-open-Terrace' |
                                translate}} <span class="rstarcolor">*</span>
                               <select class="form-control form-control-sm" name="ConveyanceType"
                                    [(ngModel)]="propertyValuationRequestBean.conveyanceType"
                                    (change)="inputChangeEventValidation()">
                                    <option [value]="null" [disabled]="true">
                                        {{'property-valuation.Select-Conveyance-type' | translate}}
                                    </option>
                                    <option value="Residential">{{'property-valuation.Residensial' |
                                        translate}}</option>
                                    <option value="Commercial">{{'property-valuation.Commercial' |
                                        translate}}</option>
                                 
                                </select>
                                <div *ngIf="conveyanceTypeMsg&&(propertyValuationRequestBean.conveyanceType==''||propertyValuationRequestBean.conveyanceType==null)"
                                    class="custom-invalid-feedback">
                                    <div *ngIf="conveyanceTypeMsg">{{'mandatory' | translate}}</div>

                                </div>
                            </label>
                          
                            <label class="btn btn-sm col-md-12 text-left p-0"
                                *ngIf="propertyValuationRequestBean.buildingSubType!='Open Terrace'">{{'property-valuation.Enter-the-Area-of-open-Terrace'
                                | translate}} <span class="rstarcolor">*</span>
                                <input type="text" [(ngModel)]="propertyValuationRequestBean.conveyanceArea"
                                    placeholder="{{'property-valuation.Enter-the-Area-of-open-Terrace' | translate}}"
                                    appPositiveNumbersOnlyDirective [appCopyPastValidation]="true"
                                    (ngModelChange)="validateConveyanceArea()"
                                    class="form-control form-control-sm">
                                    <div *ngIf="conveyanceTypeAreaMsg&&(propertyValuationRequestBean.conveyanceArea==''||propertyValuationRequestBean.conveyanceArea==null||propertyValuationRequestBean.conveyanceArea==undefined)"
                                    class="custom-invalid-feedback">
                                    <div *ngIf="conveyanceTypeAreaMsg">{{'mandatory' | translate}}</div>

                                </div>
                            </label>
                        </div>
                    </fieldset>

                    <div class="moreFiler subclauses m-2 px-1" *ngIf="propertyValuationRequestBean.buildingSubType=='Independent Building'">
                        <h3 class="text-primary mb-0">{{'property-valuation.Subclause' | translate}}</h3>
                        <div *ngFor="let sbc of subClauseMasterBean">
                            <div class="form-check" >
                                    <label  [hidden]="sbc.hidden" class="form-check-label" for="subclause{{sbc.id}}">
                                        <!-- [checked]="checkSubclauseCheckORUnchecked(sbc.id)" -->
                                        <input class="form-check-input" type="checkbox" [(ngModel)]="sbc.checked"
                                            [disabled]="sbc.disabled" (ngModelChange)="addSubclauseEvent($event,sbc);onlyOneSubclauseSelectedAtATime(sbc)"
                                            value="{{sbc}}" id="subclause{{sbc.id}}">
                                        {{lan==0?sbc.descEn:sbc.descHi}}
                                    </label>

                            </div>

                         
                        </div>

                    </div>

                    <div class="moreFiler subclauses m-2" *ngIf="propertyValuationRequestBean.buildingSubType=='Multistorey'&& propertyValuationRequestBean.buildingType=='Residential'">

                        <h3 class="text-primary mb-0">{{'property-valuation.Subclause' | translate}}</h3>
                        <!-- *ngIf="radio==1" -->
                        <div *ngFor="let sbc of subClauseMasterBean">
                            <!--  -->

                            <div class="form-check" *ngIf="sbc.id==7">


                                    <label  [hidden]="sbc.hidden" class="form-check-label" for="subclause{{sbc.id}}">
                                        <!-- [checked]="checkSubclauseCheckORUnchecked(sbc.id)" -->
                                        <input class="form-check-input" type="checkbox" [(ngModel)]="sbc.checked"
                                            [disabled]="sbc.disabled" (ngModelChange)="addSubclauseEvent($event,sbc)"
                                            value="{{sbc}}" id="subclause{{sbc.id}}">
                                        {{lan==0?sbc.descEn:sbc.descHi}}
                                    </label>

                            </div>


                        </div>

                    </div>


                    

                    <button *ngIf="!isContinueButton" mat-flat-button class="btn btn-primary m-2" 
                        (click)="showMarketValueEvent()">{{'property-valuation.Show-Market-Value' |
                        translate}}</button>
                     



                  <button *ngIf="isContinueButton" mat-flat-button class="btn btn-primary m-2"
                  (click)="pushbuildingDataMoveAgriCulture()">{{'valuationproperty.contin' | translate}}</button>  


                </div>
            </div>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'builing-01'"></ngx-ui-loader>
</div>
<div class="container">
  <div class="page__box">
    <div class="row">
      <div class="col-lg-8">
        <h2 class="PageTitle">{{'duty-calculation.Instrument-field-Mapping' | translate}}</h2>

      </div>

    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body py-1">
      <h3 class="card-title"><i class="fa fa-search"></i> {{'duty-calculation.Instrument-field-Mapping' | translate}}
      </h3>

      <div class="searchtop">
        <form class="row" (ngSubmit)="f.form.valid && onSubmitForEstampPartyDetails(f)" #f="ngForm">
          <div class="col-md-12">
<lib-deed-instrument (instMappingEvent)="getInstFieldMappingEmitter($event)" (instrumentIdEmit)="getInstIdEmitter($event)" [pInstrumentId]="pInstrumentId" [pDeedCategoryId]="pDeedCategoryId" [pDeedTypeId]="pDeedTypeId"></lib-deed-instrument>
            <!-- <lib-instrument-field-mapping #childid></lib-instrument-field-mapping> -->
          </div>
          <div class="col-md-3">
            <div class="form-group mr-md-1 mb-2">
              <div class="form-group">
              <label>{{'duty-calculation.field-name-en' | translate}}*</label>
              <input type="text" [(ngModel)]="dutyInstFieldMappingModel.fieldNameEn" #fieldNameEn="ngModel" name="fieldNameEn" minlength="4" maxlength="50" class="form-control"
                placeholder="{{'duty-calculation.field-name-en' | translate}}">
                <div *ngIf="f.submitted && fieldNameEn.invalid" class="invalid-feedback">
                  <div *ngIf="fieldNameEn.errors.required"  class="alert alert-danger error_message p-1 float-right mb-0">fieldNameEn is required</div>
              </div>
            </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mr-md-1 mb-2">
              <label>{{'duty-calculation.field-name-hi' | translate}}</label>
              <input type="text" [(ngModel)]="dutyInstFieldMappingModel.fieldNameHi" #fieldNameHi="ngModel" name="fieldNameHi" minlength="4" maxlength="50" class="form-control"
                placeholder="{{'duty-calculation.field-name-hi' | translate}}">
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>{{'duty-calculation.field-type' | translate}}<span class="rstarcolor">*</span></label>
              <ng-select  [(ngModel)]="dutyInstFieldMappingModel.fieldType" #fieldType="ngModel" name="fieldType" placeholder="{{'duty-calculation.field-type' | translate}}">
                <ng-option value="">{{'duty-calculation.field-type' | translate}} </ng-option>
                <ng-option [value]="'NUMBER'">NUMBER</ng-option>
                <ng-option [value]="'LABEL'">LABEL</ng-option>
                <ng-option [value]="'RADIO'">RADIO</ng-option>
              </ng-select>
            </div>
          </div>
          <!-- <div class="col-md-3">
            <div class="form-group mr-md-1 mb-2">
              <label>{{'duty-calculation.ParentId' | translate}}</label>
              <ng-select  placeholder="{{'duty-calculation.ParentId' | translate}}">
                <ng-option value="">{{'duty-calculation.ParentId' | translate}} </ng-option>
              </ng-select>
            </div>
          </div> -->
          <div class="advanceFilterOptionfooter mt-4">
            <div class="advanceFilterOptionfooter  text-right">
              <button type="submit" name="addParty"  id="addParty" class="ml-1 btn btn-primary mt-1" mat-raised-button >{{'duty-calculation.add' | translate}}</button>
              <a (click)="resetDetails()"  name="reset" class="ml-1 btn btn-secondary mt-1" mat-raised-button>{{'e-stamp.reset' | translate}}</a>
            </div>
            </div>
        </form>




      </div>
    </div>
  </div>

  <div class="mat-elevation-z0 table-responsive">
    <div class="table-responsive">
      <table id="viewEstamp" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 demo-table w-100">


        <ng-container matColumnDef="sNo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
          <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
        </ng-container>
        <ng-container matColumnDef="FieldNameEnglish">
          <th mat-header-cell *matHeaderCellDef> Field Name English </th>
          <td mat-cell *matCellDef="let element"> {{element.fieldNameEn}} </td>
        </ng-container>
        <ng-container matColumnDef="FieldNameHindi">
          <th mat-header-cell *matHeaderCellDef> Field Name Hindi</th>
          <td mat-cell *matCellDef="let element"> {{element.fieldNameHi}} </td>
        </ng-container>

        <ng-container matColumnDef="FieldType">
          <th mat-header-cell *matHeaderCellDef > Field Type </th>
          <td mat-cell *matCellDef="let element" > {{element.fieldType}} </td>
        </ng-container>
        <ng-container matColumnDef="ParentId">
          <th mat-header-cell *matHeaderCellDef > Parent Id </th>
          <td mat-cell *matCellDef="let element"> {{element.parentId}} </td>
        </ng-container>
        <ng-container matColumnDef="instrumentId" >
          <th mat-header-cell *matHeaderCellDef [style.display]="'none'"> Estamp </th>
          <td mat-cell *matCellDef="let element"[style.display]="'none'" > {{element.instrumentId}} </td>
          </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> {{'e-stamp.Action' | translate}} </th>
          <td mat-cell *matCellDef="let row">
          
              <!-- <div *ngFor="let offlist of featureListSessiondata"> -->
              <button mat-icon-button [matMenuTriggerFor]="action"
                  aria-label="Example icon-button with a menu" class="">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #action="matMenu" xPosition="before">
                  <!-- <button  *ngIf="offlist.featureCode=='F-0005'" mat-raised-button color="primary" routerLink="/admin/adddepartmentusers" class="mb-2 text-right"><i class="fa fa-user-plus"></i> {{'admin-profile-page-data.center-personal-detail-useronboard.add-departmet-user' | translate}}</button> -->
          
                  <button [value]="row" #editidot 
                      class="h-auto list-unstyled" mat-menu-item>
                      <!-- routerLink="/admin/editepartmentusers/{{row.id}}" -->
                      <li (click)="editClick(row)"><!--(click)="onClick('some text')" -->
                          <mat-icon id="edit">rate_review</mat-icon>
                          <span>{{'edit_view' | translate}}</span>
                      </li>
          
                      <li  (click)="removeClick(row)">
                          <mat-icon class="bg-secondary text-light border-5" id="remove">remove</mat-icon>{{'Remove' |
                          translate}}
                      </li>
                  </button>
          
              </mat-menu>
          
              <!-- </div> -->
          </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

       <div id="saveDiv" class="float-right mt-3 mb-3 p-0">
        <button mat-raised-button  (click)="saveDutyInstFieldMappingDetails()" id="save" name="save" 
        class="mr-2 w-100 col-md-2 float-right btn btn-primary">{{'guideline.save' | translate}} </button>
        <a mat-raised-button color="default" class="mr-2 w-100 col-md-2 float-right btn btn-secondary"
                routerLink="/admin/deed-instrument-listing">{{'goback'|translate}}</a>
      </div> 
    </div>
  </div>
      


</div>
<ngx-ui-loader></ngx-ui-loader>
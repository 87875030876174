<footer class="footer">
    <div class="footer-bottom container">
        <div class="container pr">
            <div class="float-left text-center text-md-left">
                <img src="assets/images/server-img/web1.png" width="25px" height="25px" class="mr-1">
                <span>{{'copyright-year' | translate}} <a href="https://www.mpigr.gov.in" target="_blank">{{'igrs' |
                        translate}}</a> {{'copyright-rights' | translate}}</span>

                <!-- Content Provided and Maintained by Department of Registration and Stamps.. -->
            </div>
            <div class="float-right text-center text-md-right">

                <!-- <span class="ml-auto"> {{'property-valuation.Designed-developed' | translate}} <a href="https://www.mpsedc.mp.gov.in" target="_blank">{{'property-valuation.COE' | translate}}</a></span> -->
                <span *ngIf="lan==0"> {{'property-valuation.Designed-developed' | translate}} <a
                        href="https://www.mpsedc.mp.gov.in" target="_blank">{{'property-valuation.COE' |
                        translate}}</a></span>
                <span *ngIf="lan==1"> <a href="https://www.mpsedc.mp.gov.in" target="_blank">{{'property-valuation.COE'
                        | translate}}</a> {{'property-valuation.Designed-developed' | translate}} </span>

            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</footer>

<!-- <app-chatbot cdkDrag></app-chatbot> -->
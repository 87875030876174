import { Component, OnInit, ɵɵqueryRefresh } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { Inject } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
//import * as custom from 'src/test.js';

declare const $: any;
declare var PrintService :any;
declare var PrintServiceUtil :any;
declare function ajaxQueryPrinters(refresh : any,showUnsupported : any): any;
declare function renderPrinterListComboBox(printerList : any): any;
declare function launchApp(): any;
declare function loadPrinter() :any;
declare function begin() :any;
declare function requiresDiffPrintControlVersion(appVersion:any) :any;
declare function initUpdatePrintControl() :any;
declare function checkPrintControlStatus():any;
var timer :any;
var appStatusPollIntervalMs = 2000;
var hasLaunchedApp = false;
var pollForAppStatus = true;
var isAppRunning = false;
var pPrintControlVersion = "";
@Component({
  selector: 'lib-owm-printer',
  templateUrl: './owm-printer.component.html',
  styleUrls: ['./owm-printer.component.css']
})
export class OwmPrinterComponent implements OnInit {
  estampId : number;
  private settings!: AppSetting;
  constructor(private activatedRoute: ActivatedRoute,private router: Router, @Inject(MAT_DIALOG_DATA) public data: any,
  private appSettingsService: AppSettingService) { 
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }
  ngAfterViewInit(): void {
    //var fileName = "Sample11111.pdf";
    //var pFilename = fileName,
    var pFilename = this.estampId,
    //pURL = "http://localhost:8082/sampadaService/common/get_owm_print",
    //pURL = "http://10.115.96.80:8082/sampadaService/common/get_owm_print",
    pURL = this.settings.igrs_services_url+'common/get_owm_print',
    pSessionKey = "JSESSIONID",
    pSessionValue = "MyJSessionIDValue",
    pPrintControlVersion = "",
    //printControlBaseUrl = "https://127.0.0.1:50000/",
    printControlBaseUrl = this.settings.printControlBaseUrl,
    pCustom="",
		pCustomHeader="customHeader1Name:customHeader1Value,customHeader2Name:customHeader2Value",
		pCustomParam="customParam1Name:customParam1Value,customParam2Name:customParam2Value",
    pAlias="",
    pVerifyString = this.settings.pVerifyString;
   // pVerifyString="aHR0cDovL2xvY2FsaG9zdDo4MDgwfGhxYlVWdG13N3pIUWR5SUVuYU1Odk9TT0grMTJRVE1KRjNYY0czWGpSa0tmbmdWa2FUUkVnOWp3TlJQZEJBWDQxQkE3LzBhOUJXTlVNZGhMRUU5MThpeEpwajZ0NkN6VTJzS3gwZXRxb3ZpN1Q2MHFhS0lxb2dKNHZMWitjR25WZzlnNURvSzhzR053YXorN0dXbnNvWXQwRXM4T2pxNjRKWmFQclhKS2dSRFVOTS9kaGw1dzdPa1Bjd2RDb3pxd0sxUFI5WjB5VkZnYnBBeGtzc25LVzJkS2QyNUQ0WEpVNEFGTFMxdFJlZzhqT2VUb0pNMDY0UmRrd2RDRDlSQW91azhJLzhNc2ZvUjlIazZ2MU9EZ1EweFZBM0Q0RkZaWElia296S3hSN205cm42cWZ3S3d4MDJvYk1sU0ljM1RCYWxldDhycnNCdDhaTDllTXpTbjc0QT09";
    PrintService.init(printControlBaseUrl, pSessionKey, pSessionValue, pFilename, pURL, null, null, pCustom, pCustomHeader, pCustomParam, pVerifyString);
    var i = 0;
                timer = setInterval(function() {
                    i = ++i % 4;
                    $("#printControlStatus").html("Checking Print Control" + Array(i+1).join("."));
                }, 500);
                // check if app is running
                checkPrintControlStatus();
                console.log("pURL::"+pURL);
  }
  ngOnInit(): void {
   // loadPrinter();
   // PrintService.launchApp();
  // this.activatedRoute.queryParams
  // .subscribe(params => {
  //   this.estampId=params.estampId;
  //  console.log("estampId----------- ",this.estampId);
  // });
  this.estampId=this.data.estampId;
  console.log("estampId::"+this.estampId);
  }
  
  queryPrinters(refresh : any){
    $('#printBtn').hide();
				$('#refreshBtn').hide();
				$("#printerListContainer").html("<img src=\"assets/images/owm/loading.gif\" height=\"40\" width=\"40\" /><span> &nbsp;Loading Printer List</span>");
			
          PrintService.ajaxQueryPrinters(refresh, false).done(function (printerList : any) {
                    var printerListComboBox = PrintServiceUtil.renderPrinterListComboBox(printerList);
                    $('#printerListContainer').html(printerListComboBox);
					$('#printBtn').show();
                    $('#refreshBtn').show();

                    if (printerList.length <= 0) {
                        alert('Print Control: Could not find any compatible printers.');
						$('#printOptions :input').prop("disabled", true);
                    }
                }).fail(function (data :any, textStatus :any) {
					// OPTIONAL TODO: Logic to implement when printer list is unable to be loaded.
					$('#printerListContainer').html('Unable to complete loading printer list. Please try again later.');
					$('#printOptions :input').prop("disabled", true);
				});
  }
  
  
}

import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';


import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';


import Swal from 'sweetalert2';
import { TicketService } from '../../../service/ticket.service';

const TICKET_ELEMENT_DATA: TicketModelElement[] =[];
export class TicketModelElement {
  ticketNumber : string;
  subject : string;
  description : string;
  priority_name : string;
  category_name : string;
  dueDate : string;
  created_date : string;
  ticketstatus : string;
  status : string;
  ticket_id : string;
}
export class TicketModel {
  userId : number;
  email : string;
  name : string;
  deptId : string;
  officeId : string;
  distId : string;
  subject : string;
  description : string;
  fileData : string;
  fileName : string;
  applicationId : string;
  helptopicId : string;
  otherData : string;
  subHelptopicId: string;
  mobileNo : any;
}
export class DepartmentModel
{
    id : string;
    name : string;
}
export class DistrictModel
{
    id : string;
    name : string;
    district_name_hindi : string;
}
export class OfficeModel
{
    id : string;
    name : string;
}
export class ApplicationModel
{
  application_id : string;
  application_name_e : string;
}
export class HelptopicModel
{
  helptopic_id : string;
  helptopic_name_e : string;
  helptopic_name_h : string;
}
export class SubHelptopicModel
{
  helptopic_id : string;
  helptopic_name_e : string;
  helptopic_name_h : string;
}
export class TitleModel
{
  id : number;
  titleName : string;
  titleNameHi : string;
  categoryId : number;
  status : number;
}
export class TitleFieldModel
{
  id : number;
  fieldName : string;
  fieldNameHi : string;
  status : number;
}
export class TitleFieldMappingModel
{
  id : number;
  titleId : TitleModel;
  titleFieldIds : TitleFieldModel;
  optionalStatus : number;
}




@Component({
  selector: 'app-unregis-ticket-creation',
  templateUrl: './unregis-ticket-creation.component.html',
  styleUrls: ['./unregis-ticket-creation.component.scss']
})
export class UnregisTicketCreationComponent implements OnInit {

  @Input('mobileNoInput') mobileNo:any;
  @ViewChild('alertMessage') alertmessage: AlertmessageComponent;
  @ViewChild('formRef') form: any;
  @ViewChild('content') content: any;
  @ViewChild('selfCloseContent') selfCloseContent: any;
  @ViewChild('reOpenContent') reOpenContent: any;
  //userProfileModel : CitizenUserProfileDetails=new CitizenUserProfileDetails() ;
  ticketModel : TicketModel = new TicketModel();
  departmentModel : DepartmentModel = new DepartmentModel();
  districtModel : DistrictModel = new DistrictModel();
  officeModel : OfficeModel = new OfficeModel();
  applicationModel : ApplicationModel = new ApplicationModel();
  helptopicModel : HelptopicModel = new HelptopicModel();
  departmentModelList: DepartmentModel[] = new Array();
  districtModelList: DistrictModel[] = new Array();
  officeModelList: OfficeModel[] = new Array();
  applicationList: ApplicationModel[] = new Array();
  helptopicList: HelptopicModel[] = new Array();
  subHelptopicList: SubHelptopicModel[] = new Array();
  titleList: TitleModel[] = new Array();
  titleFieldMappingList: TitleFieldMappingModel[] = new Array();
  lan : number=1;
  res : any;
  err : any;
  deptId : number;
  userEmail : string;
  userName : string;
  paginator: MatPaginator;
  searchTicketNo : string = null;
  advnc_Status: any = null;
  isShow = true;
  hiddenvalue: string = "hidden";
  resetDemo: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<any>(TICKET_ELEMENT_DATA);
  displayedColumns: string[] = ["sNo","ticketNumber","subject","createDate","dueDate","ticketstatus","actions"];
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  dialog: any;
  district:string;
  showTicketIssueCatDiv: boolean = false;
  ticketId:string;
  uploadfileErrorMsg : boolean = true;
  subString : string = '';
  subjectMap = new Map<string, string>();
  offSdata : any = null;
  actionTicketStatus:any;
  constructor(private translateService: TranslateHEService,private translate: TranslateService,private ngxService: NgxUiLoaderService, private router: Router,private ticketService: TicketService, private httpClient: HttpClient,
    private _AESEncryptDecryptService: AESEncryptDecryptService, private modalService: NgbModal)
  {
      // translate.use( sessionStorage.getItem("selectedLanguage")||"en")
       this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
   }

  ngOnInit(): void {
   // this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
   console.log("input logged In Mobile No ::"+this.mobileNo);
    this.offSdata = sessionStorage.getItem('selectedOffice');
     //this.userEmail='abc';
     let uEmail = sessionStorage.getItem('userEmail');
     if(uEmail != null && uEmail != undefined && uEmail != ''){
      this.userEmail=uEmail;
     }
    // this.userName=this.userProfileModel.userName;
    
   // this.district = this.userProfileModel.demography.demographyName;
    this.ticketModel = {
      // userId : this.userProfileModel.id,
      // email:this.userProfileModel.email,
      // name:this.userProfileModel.userName,
      mobileNo:this.mobileNo,
      userId : null,
      email:null,
      name:null,
      deptId:null,
      officeId:null,
      distId:null,
      subject:null,
      description:null,
      fileData:null,
      fileName:null,
      applicationId : null,
      helptopicId : null,
      otherData : null,
      subHelptopicId : null,
    };
    this.getTicketAllDropDown();
    this.getAllTickets();

   // this.getAllTitleFieldByTitleId(7);
  }

  getAllTicketTitles(){
    this.ticketService.getAllTicketTitles().subscribe(dcdata => {
      if(dcdata.responseStatus){
        this.titleList = dcdata.responseData;
      }else{
        console.error('There was an error in getAllTicketTitles()!', dcdata.responseMessage);
      }
    });
  }

  getAllTitleFieldByTitleId(titleId:any){
    this.ticketService.getAllTitleFieldByTitleId(titleId).subscribe(dcdata => {
      if(dcdata.responseStatus){
        //this.openModal();
        this.titleFieldMappingList = dcdata.responseData;
        this.showTicketIssueCatDiv=true;
        this.ticketModel.subject = null;
        this.subjectMap = new Map<string, string>();
      }else{
        console.error('There was an error in getAllTicketTitles()!', dcdata.responseMessage);
      }
    });

  }
  getSubHelpTopicList(helpTopicId:any){
    this.showTicketIssueCatDiv=false;
    this.ticketModel.subHelptopicId = null;
   // this.subHelptopicList=[];
    this.ticketService.getSubHelpTopicList(helpTopicId).subscribe(dcdata => {
      if(dcdata.responseStatus){
        dcdata = dcdata.responseData.replace(/\\/g, "");
           var data =JSON.parse(dcdata);
           this.subHelptopicList = data.result.getSubhelptopic;
           //this.getAllTicketTitles();
       }else{
         console.error('There was an error in getSubHelpTopicList()!', dcdata.responseMessage);
       }
    });

  }
  selectedUserDistrict(){
    for(var i=0;i<this.districtModelList.length;i++){
      if(this.districtModelList[i].name == this.district){
        this.ticketModel.distId = this.districtModelList[i].id;
       break;
      }
 }
  }
  getTicketAllDropDown(){
    const param = {
      "HELPTOPICTYPE": "Public",
    };
    this.ticketService.getTicketAllDropDown(param).subscribe(dcdata => {

      if(dcdata.responseStatus){
       dcdata = dcdata.responseData.replace(/\\/g, "");
          var data =JSON.parse(dcdata);
          this.departmentModelList=[];
          this.officeModelList=[];
          this.applicationList=[];
          this.departmentModelList.push(data.result.getDepartment);
          this.districtModelList = data.result.getDistrict;
          this.officeModelList.push(data.result.getOffice);
          this.applicationList.push(data.result.getApplication);
          this.ticketModel.deptId=this.departmentModelList[0].id;
          this.ticketModel.officeId=this.officeModelList[0].id;
          this.ticketModel.applicationId=this.applicationList[0].application_id;
          this.helptopicList = data.result.getHelptopic;
          this.selectedUserDistrict();
      }else{
        console.error('There was an error in getTicketAllDropDown()!', dcdata.responseMessage);
      }
    });

    //----------------------------------------------
  //   const headers = { 'ITSUPPORTSECRETKEY': 'e170245abbcfe9b0384704d0851f7f41', 'RNUM': '1671527062', 'APPLICATIONCODE': 'MSDC01' };
  //   const body = { APPLICATIONCODE: 'MSDC01' };
  //   this.httpClient.post<any>('https://bhabha-coe.mapit.gov.in/helpdesk-demo/api/tickets/getAlldropdown', body, { headers }).subscribe({
  //     next: data => {
  //         this.res = data;
  //         this.departmentModelList=[];
  //         this.officeModelList=[];
  //         this.departmentModelList.push(data.result.getDepartment);
  //         this.districtModelList = data.result.getDistrict;
  //         this.officeModelList.push(data.result.getOffice);
  //         this.ticketModel.deptId=this.departmentModelList[0].id;
  //         this.ticketModel.officeId=this.officeModelList[0].id;
  //         console.info('Result:', data);
  //     },
  //     error: error => {
  //         this.err = error;
  //         console.error('There was an error in getTicketAllDropDown()!', error);
  //     }
  // })

  }
  selectedFile : any = null;
  image: File;
  onFileSelected(event : any) {
    this.selectedFile = event.target.files[0];
    // Size Filter Bytes
    const pmax_size = 3000000;
   // const pmax_size = 20000;
    const pallowed_types = ["application/pdf","image/jpeg"];//, "image/jpeg", "image/jpg"
    if (!pallowed_types.includes(event.target.files[0].type, 0)) {
     // this.uploadfileErrorMsg=false;
      this.form.controls[event.target.name].setValue(null);
      Swal.fire({
        title: `${this.lan==0?"UnSupported File Type":"असमर्थित फ़ाइल प्रकार"}`,
        // title: "UnSupported File Type",
        text: `${this.lan==0?"Only files are allowed of type ( pdf/jpg )":"केवल (पीडीएफ/जेपीजी) प्रकार की फ़ाइलों की अनुमति है"}`,
        // text: "Only files are allowed of type ( pdf/jpg )  ",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: (this.lan == 0) ? 'Ok':'ठीक है',
        cancelButtonText: (this.lan == 0) ? 'No, keep it':'नहीं, इसे रखो', 
        allowOutsideClick: false,
      }).then((result) => {
        //return false;
      });
    }else if((event.target.files[0].size > pmax_size)){
     // this.uploadfileErrorMsg=false;
     // this.alertmessage.errorAlertMessage('file-size-error');
      this.form.controls[event.target.name].setValue(null);
      Swal.fire({
        title: `${this.lan==0?"File Size Exceeds Allowed Limit":"फ़ाइल का आकार अनुमत सीमा से अधिक है"}`,
        // title: "File Size Exceeds Allowed Limit",
        text: `${this.lan==0?"Maximum size allowed is " + pmax_size / 1000000 + "mb":"अधिकतम आकार अनुमत है " + pmax_size / 1000000 + "एमबी"}`,
        // text: "Maximum size allowed is " + pmax_size / 1000000 + "mb",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: (this.lan == 0) ? 'Ok':'ठीक है',
        cancelButtonText: (this.lan == 0) ? 'No, keep it':'नहीं, इसे रखो', 
        allowOutsideClick: false,
      }).then((result) => {
       // return false;
      });
    }
    else{
      this.uploadfileErrorMsg=true;
    const preader = new FileReader();
    preader.onload = (pe: any) => {
      const pimage = new Image();
      pimage.src = pe.target.result;
      //this.form.controls[event.target.name].setValue(this.selectedFile.name);
      //this.eregPropertyLinkModel.documentType = fileInput.target.files[0].type.split('/')[1];
      //this.eregPropertyLinkModel.filePath = (pe.target.result).split(',')[1];
      this.ticketModel.fileData = (pe.target.result).split(',')[1];
      this.ticketModel.fileName = this.selectedFile.name;
    };
    preader.readAsDataURL(event.target.files[0]);
  }
  }
  onSubmit(form:NgForm) {
    this.userEmail = this.ticketModel.email;
    this.ngxService.start();
    this.onSubmitIssueCatDialogForm();
    this.ticketService.createTicket(this.ticketModel).subscribe(dcdata=>{
      this.ngxService.stop();
      if(dcdata.responseStatus!="false"){
        // dcdata = dcdata.responseData.replace(/\\/g, "");
        // var data =JSON.parse(dcdata);
        console.log("ticket creation response ::"+JSON.stringify(dcdata.responseData))
        //  this.alertmessage.successAlertMessage("Ticket Create Successfully");
        Swal.fire((this.lan == 0) ? "Ticket Create Successfully.Ticket No :-"+dcdata.responseData  : "टिकट सफलतापूर्वक बनाया गया। टिकट नं :-"+dcdata.responseData );
              form.resetForm();
              this.getTicketAllDropDown();
              this.getAllTickets();
              this.showTicketIssueCatDiv=false;
              this.ticketModel.subject=null;
        }else{
          this.alertmessage.errorAlertMessage(dcdata.responseMessage);
        }
    });
    // console.log("form Data::"+JSON.stringify(form.value));
    // const formBody = new FormData();
    // formBody.append('email', this.userEmail);
    // formBody.append('name', this.userName);
    // formBody.append('deptId', this.ticketModel.deptId);
    // formBody.append('officeId', this.ticketModel.officeId);
    // formBody.append('app_service', '6');
    // formBody.append('helptopicID', '5');
    // formBody.append('distId', this.ticketModel.distId);
    // formBody.append('subject', this.ticketModel.subject);
    // formBody.append('description', this.ticketModel.description);
    // if(this.selectedFile != null){
    // formBody.append('images', this.selectedFile, this.selectedFile.name);
    // }
    // this.httpClient
    //   .post(`https://bhabha-coe.mapit.gov.in/helpdesk-demo/api/tickets/createTicket`,
    //   formBody, {
    //       headers: {
    //         ITSUPPORTSECRETKEY:'e170245abbcfe9b0384704d0851f7f41',
    //         RNUM:'1671527062',
    //         APPLICATIONCODE:'MSDC01'
    //       }
    //     }
    //   ).subscribe((data: any) => {
    //     if(data.status == 1){
    //       this.alertmessage.successAlertMessage("Ticket Create Successfully");
    //       form.resetForm();
    //       this.getTicketAllDropDown();
    //       this.getAllTickets();
    //     }else{
    //       this.alertmessage.errorAlertMessage(data.message);
    //     }
    //     console.log(JSON.stringify(data));
    //   });
  }

  //Data Table code
  editClick(ticketModelElement :TicketModelElement){
    console.info("ticket No::"+ticketModelElement.ticketNumber);
    sessionStorage.setItem("ticketNo",ticketModelElement.ticket_id);
    sessionStorage.setItem("userEmail",this.userEmail);
   // let ticketNo = this._AESEncryptDecryptService.encryptInAngular(ticketModelElement.ticketNumber);
   // this.router.navigate([`/citizen/ticketView/${ticketModelElement.ticketNumber}`]);
   //this.router.navigate([`/citizen/ticketView`]);
   this.router.navigate([`/raiseTicketView`]);
  //  if(this.offSdata != null){
  //   this.router.navigate([`/admin/ticketView`]);
  //  }else{
  //   this.router.navigate([`/citizen/ticketView`]);
  //  }
  }
  pageChangedSrvPrvd(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllTickets();
  }
  getAllTickets(){
    this.ngxService.start();
    const param = {
      "mailID": this.userEmail,
      "limit": this.pageSize,
      "start": this.pageSize*this.currentPage,
      "ticketNumber": this.searchTicketNo,
      "searchByStatus": this.advnc_Status,
      "mobileNo":this.mobileNo
    };
    this.ticketService.getAllTickets(param).subscribe(res=>{
        res = res.responseData.replace(/\\/g, "");
        var data =JSON.parse(res);
      if(res.responseStatus!="false"){
          this.dataSource = data.result.TicketsData;
          this.totalRows = data.result.totalRec;
          this.paginator.pageIndex = this.currentPage;
        }else{
          console.error('There was an error in getAllTickets()!', data.message);
        }
    });
   // this.advnc_Status = null;
    this.ngxService.stop();

  //   this.ngxService.start();
  //   const headers = { 'ITSUPPORTSECRETKEY': 'e170245abbcfe9b0384704d0851f7f41', 'RNUM': '1671527062', 'APPLICATIONCODE': 'MSDC01' };
  //   const formBody = new FormData();
  //   formBody.append('mailID', this.userEmail);
  //   formBody.append('limit', this.pageSize+"");
  //   formBody.append('start', this.currentPage+"");
  //   if(this.searchTicketNo != null){
  //   formBody.append('TicketNumber', this.searchTicketNo);
  //   }
  //   if(this.advnc_Status != null){
  //     formBody.append('searchByStatus', this.advnc_Status);
  //     }
  //  // const body = { mailID: this.userEmail,limit: this.pageSize+"",start: this.currentPage+"" };
  //   this.httpClient.post<any>('https://bhabha-coe.mapit.gov.in/helpdesk-demo/api/tickets/gettickets', formBody, { headers }).subscribe({
  //     next: data => {
  //         this.dataSource = data.result.TicketsData;
  //         this.totalRows = data.result.totalRec;
  //          this.paginator.pageIndex = this.currentPage;
  //         console.info('getAllTickets Result:', data);
  //     },
  //     error: error => {
  //         this.err = error;
  //         console.error('There was an error in getAllTickets()!', error);
  //     }
  // })
  // this.advnc_Status = null;
  // this.ngxService.stop();
  }
  applyFilter(searchdata: any,userEmail : any) {
    this.searchTicketNo=searchdata;
    this.userEmail = userEmail;
    this.pageSize = 5;
    this.currentPage = 0;
    if(this.userEmail == null || this.userEmail == undefined || this.userEmail == ""){
      Swal.fire( (this.lan == 0) ? "User Email Id Is Required" : "उपयोगकर्ता ईमेल आईडी आवश्यक है");
      return
    }else{
      this.getAllTickets();
    }
    
  }
  reloadTabledata() {
    this.searchTicketNo = null;
    this.advnc_Status = null;
    this.pageSize = 5;
    this.currentPage = 0;
    this.getAllTickets();
  }
  toggleStatus() {
    this.resetDemo = true;
    this.isShow = !this.isShow;
    if (this.isShow) {
      this.hiddenvalue = "hidden";
    } else {
      this.hiddenvalue = "";
    }
  }
  advanceFilter() {
    this.resetDemo = false;
    this.pageSize = 5;
    this.currentPage = 0;
    this.getAllTickets();
  }
  reset_advanceFilter() {
    this.advnc_Status = null;
    this.pageSize = 5;
    this.currentPage = 0;
    this.resetDemo = true;
  }
  close(){
    this.advnc_Status = null;
    this.resetDemo = false;
  }
  onItemChange(event: any) {
    this.advnc_Status = event.target.value;
  }

  onSubmitIssueCatDialogForm() {
    let subjectStr = '';
    var fieldDataList = [];
    var i : number=0;
    for (var obj of this.titleFieldMappingList) {
      var fieldValue = (<HTMLInputElement>document.getElementById("id_"+obj.titleFieldIds.id)).value;
      if(fieldValue != ''){
      fieldDataList.push({
        id:   obj.titleFieldIds.id,
        name: obj.titleFieldIds.fieldName,
        value: fieldValue
      });
    }
      if(subjectStr != '' && fieldValue !=''){
      subjectStr += " ("+(++i)+") "+obj.titleFieldIds.fieldName + " : " + fieldValue
      }
      else if(fieldValue !=''){
        subjectStr += "("+(++i)+") "+obj.titleFieldIds.fieldName + " : " + fieldValue
      }
    }

    var helptopicId = (<HTMLInputElement>document.getElementById("helptopicId")).value;
    var subHelptopicId = (<HTMLInputElement>document.getElementById("subHelptopicId")).value;
    var helptopicName : string;
    var subHelptopicName : string;
    for(var i=0;i<this.helptopicList.length;i++){
      if(this.helptopicList[i].helptopic_id == helptopicId+''){
        helptopicName = this.helptopicList[i].helptopic_name_e;
       break;
      }
    }
    for(var i=0;i<this.subHelptopicList.length;i++){
      if(this.subHelptopicList[i].helptopic_id == subHelptopicId+''){
        subHelptopicName = this.subHelptopicList[i].helptopic_name_e;
       break;
      }
    }
    subjectStr = helptopicName + " : " + subHelptopicName + " :- "+ subjectStr;
    this.ticketModel.subject = subjectStr;
    var subjectData = {
      "helptopicId":helptopicId,
      "helptopicName":helptopicName,
      "subHelptopicId":subHelptopicId,
      "subHelptopicName":subHelptopicName,
      "fieldData": fieldDataList
    };
    this.ticketModel.otherData = JSON.stringify(subjectData);
 }
 changeStatus_drop(event: any, ticketModelElement :TicketModelElement) {
    let selectedValue = event.target.value;
    this.ticketId = ticketModelElement.ticket_id;
    if (selectedValue == 1) {
      this.actionTicketStatus = 1;
      this.selfCloseModal();
    }
    else if (selectedValue == 3) {
      this.actionTicketStatus = 3;
      this.reOpenModal();
    }
 }

 selfCloseModal(){
  // this.modalService.open(this.content,  { centered: true });
   this.modalService.open(this.selfCloseContent,  { centered: true });
 }

 reOpenModal(){
  // this.modalService.open(this.content,  { centered: true });
   this.modalService.open(this.reOpenContent,  { centered: true });
 }

 onChangeField(event: any,inputfieldName:any){
  var helptopicId = (<HTMLInputElement>document.getElementById("helptopicId")).value;
  var subHelptopicId = (<HTMLInputElement>document.getElementById("subHelptopicId")).value;
  var helptopicName : string;
  var subHelptopicName : string;

    for(var i=0;i<this.helptopicList.length;i++){
      if(this.helptopicList[i].helptopic_id == helptopicId+''){
        helptopicName = this.helptopicList[i].helptopic_name_e;
       break;
      }
    }
    for(var i=0;i<this.subHelptopicList.length;i++){
      if(this.subHelptopicList[i].helptopic_id == subHelptopicId+''){
        subHelptopicName = this.subHelptopicList[i].helptopic_name_e;
       break;
      }
    }

  //var name :string = event.target.attributes.getNamedItem('ng-reflect-name').value;
  var name :string = inputfieldName ;
  var value :string = event.target.value;
  this.subjectMap.set(name,value);
  this.subString = '';
  var i : number=0;
  for (let [key, value] of this.subjectMap.entries()) {
    if(this.subString != ''){
    this.subString += " ("+(++i)+") "+ key + " : " + value;
  }
  else{
    this.subString += "("+(++i)+") "+ key + " : " + value;
  }
}
this.ticketModel.subject = helptopicName + " : " + subHelptopicName + " :- " + this.subString;

}

 onSubmitTicketSelfClose(){
  var remark = (<HTMLInputElement>document.getElementById("remark")).value;
  this.ngxService.start();
    const param = {
      "mailID": this.userEmail,
      "remark": remark,
      "ticketId":this.ticketId,
     // "userId":this.userProfileModel.id,
      "actionTicketStatus": this.actionTicketStatus
    };
    this.ticketService.ticketStatusChange(param).subscribe(res=>{
        res = res.responseData.replace(/\\/g, "");
        var data =JSON.parse(res);
      if(res.responseStatus!="false"){
          this.alertmessage.successAlertMessage("Ticket Self Closed Successfully");
          this.getAllTickets();
        }else{
          console.error('There was an error in ticketSelfClose()!', data.message);
          this.alertmessage.errorAlertMessage(res.responseMessage);
        }
    });
    this.ngxService.stop();
 }

 onSubmitTicketReOpen(){
  var remark = (<HTMLInputElement>document.getElementById("remark")).value;
  this.ngxService.start();
    const param = {
      "mailID": this.userEmail,
      "remark": remark,
      "ticketId":this.ticketId,
     // "userId":this.userProfileModel.id,
      "actionTicketStatus": this.actionTicketStatus
    };
    this.ticketService.ticketStatusChange(param).subscribe(res=>{
        res = res.responseData.replace(/\\/g, "");
        var data =JSON.parse(res);
      if(res.responseStatus!="false"){
          this.alertmessage.successAlertMessage("Ticket Re-Open Successfully");
          this.getAllTickets();
        }else{
          console.error('There was an error in onSubmitTicketReOpen()!', data.message);
          this.alertmessage.errorAlertMessage(res.responseMessage);
        }
    });
    this.ngxService.stop();
 }


}
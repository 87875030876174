import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from "../../../../common-lib/src/lib/helper-lib/app.setting";
import { CustomHttpClient } from "../../../../common-lib/src/lib/helper-lib/httpclient/custom.httpclient";
import { map } from "rxjs/operators";
import { ResultModel } from "../../../../common-lib/src/lib/models/api.result.model/result.model";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LcncService {

    private settings!: AppSetting;
    constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });
    }


    redirectLCNCRequest(param: any) {
        const headers = new HttpHeaders()
            .append("Authorization", param.responseData)
            .append("Content-type", "application/json");

        const httpOptions = {
            headers
        };
        const externalUrl = 'http://10.115.96.181:8080/ords/r/igrs/sam21/slot-statitics-report';
        const dataToPut = param.responseData1;

        return this.customeHttpClient.PostLCNC(externalUrl, param, httpOptions);

    }


    getUserEncData(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getUserEncData", param
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }
    getCitizenEncData(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getUserEncData", param
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    getLMSRedirectionData(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getLMSRedirectionData", param
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    getVkycURLWithUid(param: any) {
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "department/getVkycURLWithUid", param
        ).pipe(
            map((result: any) => {
    
                // 
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }
            })
        );
    }

}




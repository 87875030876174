import { Component, Inject, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef, Renderer2  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DutyCalculationData } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-calculation-data';
import { DutyCalculationResponse } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-calculation-response';
import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { CommunicationServiceService } from './duty-service/communication-service.service';
import { DutyService } from './duty-service/duty.service';
import { InstrumentSearchComponent } from './instrument-search/instrument-search.component';
import { NavigationEnd, Router, NavigationStart  } from '@angular/router';
export interface DialogData {
  cancellationFlag: boolean;
  cancellationInstBean: DeedInstrumentBean
}

@Component({
  selector: 'app-duty-calculation',
  templateUrl: './duty-calculation.component.html',
  styleUrls: ['./duty-calculation.component.scss'],
  providers: [DutyCalculationComponent]
})
export class DutyCalculationComponent implements OnInit, OnChanges{
  @ViewChild('instrumentSearchComponent', {static: false}) instrumentSearchComponent: InstrumentSearchComponent;
  lan: any;
  instMappingBeanListParent: InstMappingBean[] = new Array();
  deedInstrumentSelected: DeedInstrumentBean =  new DeedInstrumentBean();
  selInstrumentBean: DeedInstrumentBean=new DeedInstrumentBean();
  dutyCalRespBeanOutput: DutyCalculationResponse = new DutyCalculationResponse();
  dutyCalculationData:DutyCalculationData  = new DutyCalculationData();
  displayPropDivParent:boolean;
  cancellationFlag:boolean=false;
  cancellationInstBean: DeedInstrumentBean =  new DeedInstrumentBean();
  sales: boolean = false;
  mortgage: boolean = false;
  calculationduty: boolean = false;
  willdeed: boolean = false;
  leasedeed: boolean = false;
  giftdeed: boolean = false;
  showMore = false;
  isDutyFlag:boolean=false
   previousUrl: string;
 // constructor(private dcService: DutyService, private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, @Inject(MAT_DIALOG_DATA) public data: DialogData,

  constructor(private router: Router, private dcService: DutyService, private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private  dialogRef:  MatDialogRef<DutyCalculationComponent>,private translate: TranslateService, public dialog: MatDialog,private serv: CommunicationServiceService, private elementRef: ElementRef) {
   
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
      if(data!=undefined){
        this.cancellationFlag = data.cancellationFlag;
        this.cancellationInstBean = data.cancellationInstBean;
        console.log("DutyCalculationComponent:constructor:cancellationFlag-",data.cancellationFlag);
      }

      // this.router.events
      // .subscribe(
      //   (event: any) => {
      //     if(event instanceof NavigationEnd) {
      //       console.log(event.url);
      //       if(event.url=="/citizen/duty-calculation"){
      //         this.isDutyFlag=true;
      //       }

      //     }
      //   });

      // this.router.events
      // .subscribe((event) => {
      //   if (event instanceof NavigationStart) {
      //     if (this.previousUrl) {
      //       console.log("remove previous url",this.previousUrl)
      //       this.renderer.removeClass(document.body, this.previousUrl);
      //     }
      //     let currentUrlSlug = event.url.slice(1)
      //     if (currentUrlSlug) {
      //       console.log("add current url",currentUrlSlug)
      //       this.renderer.addClass(document.body, currentUrlSlug);
      //     }
      //     this.previousUrl = currentUrlSlug;
      //   }
      // });




  }
  ngOnChanges(changes: SimpleChanges): void {
   // throw new Error('Method not implemented.');
   console.log("ngOnChanges -- ",changes);
  }


  showMoreMethod(){
    this.lan=0
    this.showMore = !this.showMore;

    //this.showMore=true

  }
  showMore1 = false;
  showMoreMethod1(){
    this.lan=0
    this.showMore1 = !this.showMore1;

    //this.showMore=true

  }
  ngOnInit(): void {
    this.addBodyClass();
    this.serv.instSearchOrDeedChange.subscribe(s=> {if(s){
        // this.setDefaultHighliter();
      }}
      );

  }
  addBodyClass() {
    const bodyTag = document.body;
    bodyTag.classList.add('DutyToProperty');

   if(this.isDutyFlag){
    bodyTag.classList.add('afterLoginDutyToProperty');
    }else{
     bodyTag.classList.remove('afterLoginDutyToProperty');

    }
      this.isDutyFlag=false;

  }
//  ngAfterViewInit(){
  ngAfterViewInit(){
    console.log("ngAfterViewInit : DutyCalculationComponent");
    this.serv.receivedFilter.subscribe((param: DeedInstrumentBean) => {
      console.log("subscribe : DutyCalculationComponent ",param);
      this.deedInstrumentSelected = param;
    });
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string) {
    //console.log(this.deedInstrumentSelected);
    let obj = { instData: this.deedInstrumentSelected, calData: this.dutyCalculationData};
    this.dialog.open(DetailViewComponent,  {
      data: obj,
    });
  }
  isCalculationDuty(calculationduty:boolean){
    this.calculationduty = calculationduty;
  }
  getCalRespBean(dutyRespBean:DutyCalculationResponse){
        this.dutyCalRespBeanOutput = dutyRespBean;
  }
  sendDutyFieldsDetails(instMappingBeanList: InstMappingBean[]){
    console.log("sendDutyFieldsDetailss:howDutyFields");
    console.log("sendDutyFieldsDetails:instMappingBeanList",instMappingBeanList);
    console.log("sendDutyFieldsDetails:this.deedInstrumentSelected - ",this.deedInstrumentSelected);
    //this.showDeedFields = true;
   // this.showDeedEvent.emit(this.showDeedFields);
   this.selInstrumentBean = this.deedInstrumentSelected;

   if(this.deedInstrumentSelected!==undefined && this.deedInstrumentSelected!==null){
    this.getCalculationDataByInstId(this.deedInstrumentSelected.id);
  }

   if(instMappingBeanList!==undefined && instMappingBeanList!==null){
    this.instMappingBeanListParent = new Array();
    this.instMappingBeanListParent = instMappingBeanList;
      if(instMappingBeanList.length>0){
            if(this.cancellationFlag){
              this.selInstrumentBean.instCancellationFlag =  true;
            }else{
              this.selInstrumentBean.instCancellationFlag =  false;
            }
            let propDivflagList= instMappingBeanList.map(item => item).filter((item) => (item.otherCalFlag === 'FTP' || item.otherCalFlag === 'STP') );
            console.log("setPropDisplayFlag-othercalflag:",propDivflagList);
            if(propDivflagList!=undefined && propDivflagList.length>0){
              if(propDivflagList[0].otherCalFlag!=''){
              // if(flag!=''){
                this.displayPropDivParent=false;
              }

            }else{
              this.displayPropDivParent=true;
            }
          }
   }
  }
  getCalculationDataByInstId(instId:number){
    console.log(instId);
    this.dcService.getCalculationDataByInstId(instId).subscribe(dcdata => {
      console.log("getCalculationDataByInstId -subscribe..... ");
      this.dutyCalculationData = dcdata.responseData;
      if(this.dutyCalculationData === undefined){
               console.log(" DutyCalculationData  undefined");
      }else{
            console.log("getCalculationDataByInstId:-",this.dutyCalculationData);
      }

    });

  }

  public  closeMe() {
    this.dialogRef.close();
  }
}

import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { MPenagarpalikaResponsModel } from 'projects/common-lib/src/lib/models/enagarpalikaModel';
import { PropertyValuationService } from '../../property-valuation/property-valuation.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';
import { PnrdModel } from 'projects/common-lib/src/lib/models/PnrdModel';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';

@Component({
  selector: 'app-mannual-building.',
  templateUrl: './mannual.building.component.html',
  styleUrls: ['./mannual.building.component.scss']
})
export class MannualBuildingComponent implements OnInit {
  EnagarpalikaForm:FormGroup
  submitted = false;
  mPenagarpalikaResponsModel:MPenagarpalikaResponsModel
  mstDempgraphy:MstDemographyBean
  lan: number=1
  isPnrd:boolean=false
  uomList: any[] = new Array();
  @Output() validData = new EventEmitter<any>();
 
  prndModel:PnrdModel
  propertyTypeId:number
  constructor(
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private pvService: PropertyValuationService,
    private ngxService: NgxUiLoaderService,
    public utilityService:UtilityService,
    private translateService: TranslateHEService, 
    private translate: TranslateService, ) {
    

      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
     }

      // पंचायत आईडी  //Panchayat Property ID  
   //मूनिसिपल संपत्ति आईडी //Municipal Property ID 
  changeLabel():string{
    return this.isPnrd?this.lan==0?'Panchayat Property ID':'पंचायत आईडी ':this.lan==0?'Municipal Property ID ':'मूनिसिपल संपत्ति आईडी '
   }

  ngOnInit(): void {
    this.EnagarpalikaForm=this.fb.group({
        id:['',[Validators.required]],
        propertyNo: ['', [Validators.required]],
        unit: ['',[Validators.required]],
        buildingSubType: ['', [Validators.required]],
        buildingUsageType: [''],
        buildingType: [],
        isResidensial: [false],
	      isCommercial: [false],
	      isIndustrial: [false],
	      isHealth: [false],
	      isEdu: [false],
	      isOthers: [false],
        totalArea: ['', [Validators.required]],
        houseFlatNo: ['', [Validators.required]],
        landOwnerShip: ['', [Validators.required]],
        demographyId: ['', [Validators.required]],
        propertyId:['',]
    })
    this.getAllUOM()
  }
  changePropertyType(propertyTypeId:number){
    this.propertyTypeId=propertyTypeId
  }

  getAllUOM(){
    this.pvService.getAllUOM().subscribe(dmodata => {
        this.uomList = dmodata.responseData;;
     })
    }
    convertedUOM(uom: any) {
      return this.utilityService.convertedUOM(uom,this.lan)
    }

 
  setPropertyObectdata(propertyObject:any){
    console.log(propertyObject)
    this.EnagarpalikaForm.controls['propertyNo'].setValue(propertyObject.PropertyObject.propertyNo)
    this.EnagarpalikaForm.controls['propertyId'].setValue(propertyObject.PropertyObject.propertyNo)
    this.EnagarpalikaForm.controls['demographyId'].setValue(propertyObject.PropertyObject.demography.id)
    this.mstDempgraphy=propertyObject.PropertyObject.demography
    this.EnagarpalikaForm.controls['id'].setValue(propertyObject.PropertyObject.id)
    this.isPnrd=propertyObject.PropertyObject.isPnrd
  }

  get f() { return this.EnagarpalikaForm.controls; }

  onSubmit() {
    this.submitted = true;
    if(!this.EnagarpalikaForm.valid) {
      console.log(this.EnagarpalikaForm)
     
    
    }
      else {
        if(this.propertyTypeId==1){
          this.prndModel = <PnrdModel> this.EnagarpalikaForm.value;
          this.prndModel.demography=this.mstDempgraphy
          this.prndModel.propertyTypeId=2
          console.log(this.prndModel instanceof PnrdModel);
          console.log(this.prndModel);
          this.validData.emit(this.prndModel)
        }else{
          this.mPenagarpalikaResponsModel = <MPenagarpalikaResponsModel> this.EnagarpalikaForm.value;
          this.mPenagarpalikaResponsModel.demography=this.mstDempgraphy
          this.mPenagarpalikaResponsModel.propertyTypeId=2
          this.mPenagarpalikaResponsModel.id
          console.log(this.mPenagarpalikaResponsModel instanceof MPenagarpalikaResponsModel);
          console.log(this.mPenagarpalikaResponsModel);
          this.validData.emit(this.mPenagarpalikaResponsModel)
        }
       
      
    }
  }
  setBuildType(type:string){
    this.mPenagarpalikaResponsModel.buildingtype=type
  }
 
}

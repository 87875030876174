import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {  CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {  DomSanitizer } from '@angular/platform-browser';
import { environment } from "../environments/environment";
import {  CitizenAppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Md5Pipe } from 'projects/common-lib/src/lib/pipes/md5/md5.pipe';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import {  MatIconRegistry } from '@angular/material/icon';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { HttpXsrfInterceptor } from 'projects/common-lib/src/lib/helper-lib/httpclient/HttpXSRFInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CitizenLoginComponent } from './citizen-login/citizen-login.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'projects/front-web/src/app/shared/shared.module';
import { CommonLibModule, httpTranslateLoader } from 'projects/common-lib/src/projects';

import { AuthLayoutModule } from 'projects/front-web/src/app/auth-layout/auth-layout.module';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { AuthGuardCitizen } from './AuthGuard';
import { MaterialModule } from 'projects/common-lib/material/material.module';
 

const providers: any[] = [];
const ngxUiLoaderConfigCitizen: NgxUiLoaderConfig = {
 "bgsColor": "rgb(209, 76, 69)",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 80,
  "bgsType": "three-strings",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "rgb(209, 76, 69)",
  "fgsPosition": "center-center",
  "fgsSize": 100,
  "fgsType": "three-strings",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "rgb(209, 76, 69)",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Please Wait....",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime":900
};
@NgModule({
  declarations: [
    AppComponent,
    CitizenLoginComponent,
    
 

  ],
  
  imports: [
    CommonModule,
    CommonLibModule,
    FormsModule,
    ReactiveFormsModule,
    AuthLayoutModule,
    HttpClientJsonpModule,
    SharedModule,
    MaterialModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfigCitizen),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'hi',
   
    }),

   CitizenAppRoutingModule
  ],
  exports: [
    TranslateModule
  ],


  providers: [Md5Pipe,AuthGuardCitizen,
    { provide: "environment", useValue: environment },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private dom: DomSanitizer,private translateService :TranslateHEService,private  translate:TranslateService) {
    matIconRegistry.addSvgIconSet(dom.bypassSecurityTrustResourceUrl('assets/mdi.svg'));
 
     
   
  }
}
@NgModule({})
export class CitizenAppSharedModule {
  
  static forRoot(): ModuleWithProviders<CitizenAppSharedModule> {
    return {
      ngModule: AppModule,
      providers,
    };
  }
  
}

// export function httpTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
// }
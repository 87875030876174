import { Injectable, OnDestroy } from '@angular/core';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { BuildingPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/buildingPropertyValuationRequestBean';
import { IgrsEregTxnDetlsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { BehaviorSubject } from 'rxjs';








@Injectable(
  {
    providedIn: 'root'
  }
)
export class DataService implements OnDestroy {

  constructor() {
    console.log('constructor initialized')
  }
  ngOnDestroy(): void {
    console.log('constructor destroy')
  }

  private agriculturalWithConstruction$ = new BehaviorSubject<any>({} as any);
  private pushBuidingagricultural$ = new BehaviorSubject<any>({} as any);
  private pushPlotagricultural$ = new BehaviorSubject<any>({} as any);
  private changeMainAreaType$ = new BehaviorSubject<any>({} as any);
  private DempgraphyType$ = new BehaviorSubject<any>({} as any);
  private propertyValuationRequest$ = new BehaviorSubject<any>({} as any);
  private searchCertfiedPaymentTxn$ = new BehaviorSubject<any>({} as any);
  private estampPartyData$ = new BehaviorSubject<any>({} as any);
  private estampUserDetail$ = new BehaviorSubject<any>({} as any)
  private mortageIgrsEregTxnData$ = new BehaviorSubject<any>({} as any)
  private mortageInstrumentType$ = new BehaviorSubject<any>({} as any)

  private searchCertfiedPaymentTxn: any;
  setAgriculturalWithConstruction(agriculturalWithConstruction: any) {
    this.agriculturalWithConstruction$.next(agriculturalWithConstruction);
  }

  getAgriculturalWithConstruction() {
    return this.agriculturalWithConstruction$.asObservable();
  }

  setchangeMainAreaType(changeMainAreaType: any) {
    this.changeMainAreaType$.next(changeMainAreaType)
  }

  getChangeMainAreaType() {
    return this.changeMainAreaType$.asObservable();
  }

  setdemographyType(demographic: any) {
    this.DempgraphyType$.next(demographic)
  }
  getdemographyType() {
    return this.DempgraphyType$.asObservable();
  }

  setPropertyValuationRequestBean(propertyValuationRequestBean: PropertyValuationRequestBean) {
    this.propertyValuationRequest$.next(propertyValuationRequestBean)
  }

  getPropertyValuationRequestBean() {
    return this.propertyValuationRequest$.asObservable();
  }
  setSearchCertfiedPaymentTxn(searchCertfiedPaymentTxn: any) {
    this.searchCertfiedPaymentTxn = searchCertfiedPaymentTxn;
  }
  getSearchCertfiedPaymentTxn() {
    return this.searchCertfiedPaymentTxn;
  }

  setPartyData(partyData: any) {
    console.log(partyData)
    this.estampPartyData$.next(partyData);
  }

  getPartyData() {
    return this.estampPartyData$.asObservable();
  }
  setEstampUserDetail(userDetail: any) {
    this.estampUserDetail$.next(userDetail)
  }
  getEstampUserDetail() {
    return this.estampUserDetail$.asObservable();
  }

  setDataBuildingDataAgri(propertyValuationRequestBean: BuildingPropertyValuationRequestBean) {
    this.pushBuidingagricultural$.next(propertyValuationRequestBean);
  }
  setDataplotDataAgri(propertyValuationRequestBean: any) {
    this.pushPlotagricultural$.next(propertyValuationRequestBean);
  }
  getDataBuildingDataAgri() {
    return this.pushBuidingagricultural$.asObservable()
  }
  getDataplotDataAgri() {
    return this.pushPlotagricultural$.asObservable()
  }


  setDataMortageIgrsEregTxnData(igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean) {
    this.mortageIgrsEregTxnData$.next(igrsEregTxnDetlsBean);
  }
  getDataMortageIgrsEregTxnData() {
    return this.mortageIgrsEregTxnData$.asObservable()
  }

  setDataMortageInstrumentType(instrumentType: string) {
    this.mortageInstrumentType$.next(instrumentType);
  }
  getDataMortageInstrumentType() {
    return this.mortageInstrumentType$.asObservable()
  }


}
import { CitizenUserProfileDetails } from "../citizen.login.model/citizen.profile.model";
import { EstampDeedContent } from "../estamp.model/estamp-deed-content";
import { IgrsEregPartyDetailsBean } from "../e_registry/e-registry.model";

export class AddEstamp {

  id: number;
  categoryId: number;
  deedTypeId: number;
  instrumentId: number;
  amount: number
  igrsEstampAmtDto: IgrsEstampAmtDto[] = new Array()
  deedTemplateId: number;
  transactionBehalf: boolean;
  initialContent: string;
  finalContent: string;
  contentStatus: number;
  totalDuty: number;
  upkarDuty: number;
  principalStampDuty: number
  janpadDuty: number
  municipalDuty: number
  createdBy: number
  modifiedBy: number
  createdDate: string;
  modifiedDate: string;
  estampStatus: number;
  deedRemarks: string;
  dutyExemption: number;
  finalEstampId: number;
  estampCode: string;
  dutyId: number;
  finalSignFileName: string;
  paymentMode: string;
  dmsDocId: number;
  editFlag: number;
  editFlagTime: Date;
  eregFlag: number;
  eregId: number;
  estampType:number;
  deedLang:string;
  mstEstampTypeBean: MstEstampTypeBean;
  deedTemplateList: deedTemplateList[] = new Array();
  representedBy:any;
  isFinalDeedGenerated:number;
  districtCode :any;
  stateCode : any;
  districtId:any;
  userId:number;
}
export class MstEstampTypeBean {

  id: number;
  estampNameEn: string;
  estampNameHi: string;
  estampDiscriptionEn: string;
  estampDiscriptionHi: string;

}

export class IgrsEstampAmtDto {
  fieldId: number
  fieldValue: string
}

export class EstampCommonBean {
  igrsEstampDetailDisplay: IgrsEstampDetailDisplay = new IgrsEstampDetailDisplay();
  igrsEstampPartyBeanList: IgrsEregPartyDetailsBean[] = new Array();
  igrsEregEstampBean: AddEstamp = new AddEstamp();
  igrsCitizenUserBean: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  qrCode: string;
  viewJasperDeed: boolean = false;
  estampDeedContent: EstampDeedContent = new EstampDeedContent();
  isDeedRequired: boolean = true;
  findPdfText: any;
}

export class IgrsEstampDetailDisplay {
  estampId: number;
  categoryId: number;
  deedCategoryEn: string;
  deedCategoryHi: string;
  deedTypeId: number;
  deedTypeEn: string;
  deedTypeHi: string;
  instrumentId: number;
  instrumentNameEn: string;
  instrumentNameHi: string;
  transactionBehalf: string;
  amount: number;
  initialContent: string;
  deedTemplateId: string;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: string;
  //@Lob
  //@Nationalized
  finalContent: string;
  contentStatus: string;
  janpadDuty: number;
  municipalDuty: number;
  principalStampDuty: number;
  totalDuty: number;
  upkarDuty: number;
  finalEstampId: string;
  natureOfInstrument: string;
  estampStatus: string;
  dmsDocId: string;
  estampCode: string;
  dutyId: number;
  representedBy:any;
}

export class EstampPartyModel {
  id: number;
  sNo: number;
  partyType: any;
  userId:number;
  userType: string;
  applicantType: number;
  cinPanGstin: string;
  mobileNo: string;
  partyName: string;
  partyNameHindi: string;
  emailId: string;
  estampId: number;
  ekycStatus: number;
  wifeFatherName: string;
  wifeFatherNameHindi: string;
  dob: string;
  identityCardNo: string;
  address: string;
  addressHindi: string;
  gender: string;
  relationshipType: string;
  country: number;
  state: number;
  district: number;
  city: string;
  pinCode: string;
  organization: string;
  identityCardType: string
  noOfPerson: string;
  kycUniqueToken: string;
  eregFlag: number;
  partySubTypeId: number;
  aadharNumberMasked: string;
  aadharPhoto: string;
  aadharRefKey: string;
  aadharEkyc: number;
  kycTxnLogNo: string;
  aadhaarName: string;
  firstNameEn: string;
  lastNameEn: string;
  middleNameEn: string;
  firstNameHi: string;
  lastNameHi: string;
  middleNameHi: string;
  panResponseId: string;
  panEkyc: number = 0;
  panNumber: string;
  igrsPartyTypeBean: PartyTypeModel = new PartyTypeModel();
  isAadhaarKycPerformed:boolean;
  isProtestRequest: boolean;
  orgDesignationEn: string;
  govtDeptName: string;
  orgDesignationHi: string;
  govtDeptId: string;
}

export class PartyTypeModel {
  partyTypeId: number
  instrumentId: number;
  partySubTypeId: number;
  partyTypeNameEn: string;
  partyTypeNameHi: string;
}

export class deedTemplateList {
  id: number;
  folderName: string;
  templateNameEn: string;
  templateNameHi: string;
  status: boolean;
  instrumentId: string;
  designTemplateEn: string;
  designTemplateHi: string;
}

<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>
                    {{'homepage.deedAcceptSing' | translate}}
                </h2>

            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title> </mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-sm-12">
                        <mat-accordion class="example-headers-align">

                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">
                                            Deed acceptance process-
                                        </div>
                                        <div *ngIf="lan == 1">
                                            विलेख स्वीकृति प्रक्रिया
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            In SAMPADA 2.0 after deed writing all parties need to accept the deed
                                            content to final submit. Following steps will be follow for deed acceptance.
                                        </p>
                                        <ol>
                                            <li>
                                                All parties will get deed link on their mobile via SMS/Email/Whatsapp.
                                            </li>
                                            <li>
                                                User will open the message and click on the given link
                                            </li>
                                            <li>
                                                User will login with register mobile number through mobile OTP
                                            </li>
                                            <li>
                                                Once login user can view the deed content
                                            </li>
                                            <li>
                                                If deed content is ok then user can take the action for the same
                                            </li>
                                            <li>
                                                User will click on take action and select "Accept" or "Reject" and click
                                                on final submit.
                                            </li>
                                            <li>
                                                Once all party accept the deed user can perform their E-sign or DSC
                                            </li>

                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            सम्पदा 2.0 में विलेख लेखन के बाद सभी पक्षों को अंतिम रूप से जमा करने के लिए
                                            विलेख कंटेंट को स्वीकार करना होगा। विलेख स्वीकृति के लिए निम्नलिखित चरणों का
                                            पालन किया जाएगा।
                                        </p>

                                        <ol>
                                            <li>
                                                सभी पक्षों को उनके मोबाइल पर एसएमएस/ईमेल/व्हाट्सएप के माध्यम से डीड लिंक
                                                मिलेगा।

                                            </li>
                                            <li>
                                                यूजर मैसेज खोलेगा और दिए गए लिंक पर क्लिक करेगा
                                            </li>
                                            <li>
                                                उपयोगकर्ता मोबाइल ओटीपी के माध्यम से रजिस्टर मोबाइल नंबर के साथ लॉगिन
                                                करेगा
                                            </li>
                                            <li>
                                                एक बार लॉगिन करने पर उपयोगकर्ता डीड सामग्री देख सकता है
                                            </li>
                                            <li>
                                                यदि विलेख कंटेंट ठीक है तो उपयोगकर्ता उसके लिए कार्रवाई कर सकता है
                                            </li>

                                            <li>
                                                उपयोगकर्ता कार्रवाई पर क्लिक करेगा और "स्वीकार करें" या "अस्वीकार करें"
                                                का चयन करेगा और अंतिम सबमिट पर क्लिक करेगा।
                                            </li>
                                            <li>
                                                एक बार जब सभी पक्ष विलेख स्वीकार कर लेते हैं तो उपयोगकर्ता अपना ई-साइन
                                                या डीएससी कर सकता है
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button mat-button class="btn btn-primary" routerLink="/estamp-Login">
                                        {{'detailsPages.processdeedAccept' | translate}}
                                    </button>
                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="quest_card">
    <div class="card_head">
        <div class="quest_order">{{ question.id }}</div>
        <div class="question">
            {{lan==0 ? question.quesEn : question.quesHi }}

        </div>
    </div>
    <div class="card_body">
        <div class="rating rating_box">
            <div class="rating_list">
                <div *ngFor="let star of stars; let i = index;" style="display: inline-block">
                    <span (click)="countStar(star)" [ngClass]="{'selected': (star <= selectedValue)}">
                        <mat-icon>star</mat-icon>
                    </span>
                </div>

                <div class="rate-view"> Your Rating :
                    <span class="desc bg-primary">
                        {{ selectedValue }}
                    </span>
                </div>
            </div>


        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import * as turf from '@turf/turf'
import { TranslateService } from '@ngx-translate/core';
import 'src/assets/js/L.Control.LayerListbtn.js';
import { TranslateHEService } from '../../helper-lib/services/common/translateHE.service';
@Component({
  selector: 'app-measure-tool',
  templateUrl: './measure-tool.component.html',
  styleUrls: ['./measure-tool.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class MeasureToolComponent implements OnInit {

  //@Output() closeEvent: EventEmitter<MouseEvent> = new EventEmitter<MoupseEvent>();
  @Output() onActive: EventEmitter<any> = new EventEmitter<any>();

  @Input() map:L.Map|any;
  private tracepoly:L.Polyline | any;
  private tracingMarker:L.CircleMarker|any;
  private tracingLabel:L.Marker|any;
  private tracingcords:any[]=[];
  private measureMarkers:any[]=[];
  private measurelabels:any[]=[];
  private measureLineStart:boolean=false;
  private measurePolygonStart:boolean=false;
  public lng:number=0;
  geomType:string="Geometory";
  currentLengthAndArea:string="0.0";
  measuretoolStart:boolean=false;
  measuretoolButton:any;
  showTools:boolean=false;
  distanceUnits = [
    { value : 'meters' , name : 'M', label:"M"},
    { value : 'kilometers' , name : 'KM', label:"KM"},
  ];
  selectUnit:any=this.distanceUnits[0];


  constructor(private translate: TranslateService, private translateService: TranslateHEService) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lng = msg;
      this.translate.use(msg==1?'hi':'en')
     });
   }

  ngOnInit(): void {
    this.tracepoly=L.polyline([],{
      color: '#0046FF',
      dashArray: '5',
      weight: 3
    });

    this.tracingMarker=L.circleMarker([0,0], {
      color: 'blue',
      fillColor: 'white',
      fillOpacity: 1,
      radius: 7,
      weight:1               
    });

    this.tracingLabel = new L.Marker([0,0], {
      icon: new L.DivIcon({
          className: 'label-div-icon',
          html: '<span class="label-div-span">'+this.currentLengthAndArea+' '+this.selectUnit.label+'</span>',
          iconSize:     [0, 0]
      })
    });
    this.addMeasureToolButton();
    this.addMeasureToolButtonAction();
    this.onMapEvents();

    if(!this.map.tools)
      this.map.tools={}
    this.map.tools.measureTool =false;

    // this.tools.closeMeasureTool.subscribe((status:boolean) => {
    //   if(status){
    //     this.closeComponent();
    //   }
    // });
  }

  ngAfterViewInit():void {
    this.translate.onLangChange.subscribe(lang=>{
      if(lang.lang == "hi"){
        this.lng=1;
      }else{
        this.lng=0;
      }
    });
  }

  addMeasureToolButton(){
    this.measuretoolButton=L.control.MeasureButton({
      position: 'bottomright'
    });
    this.map.addControl(this.measuretoolButton);
  }

  addMeasureToolButtonAction(){
    this.measuretoolButton._container.addEventListener('click',(e:any)=> {
      L.DomEvent.stopPropagation(e);
      this.startTool();  
    });
  }

  startTool(){
    if(this.showTools==false){
      this.showTools=true;
      this.map.tools.measureTool =true;
      this.onActive.emit();
    } else{
      this.closeComponent();
    }
  }


  drawLine() {
    this.clearMeasureLayers();
    this.geomType="Length ";
    this.measureLineStart=true;
    this.measurePolygonStart=false;
    this.tracepoly=L.polyline([],{
      color: '#0046FF',
      dashArray: '5',
      weight: 3
    });
    
    this.addLayersinMap();
    this.tracingMarker._path.style.cursor="crosshair";
    this.measuretoolStart=true;
    //this.onMapEvents();
  }

  drawPolygon(){
    this.clearMeasureLayers();
    this.geomType="Area ";
    this.measureLineStart=false;
    this.measurePolygonStart=true;
    this.tracepoly=L.polygon([],{
      color: '#0046FF',
      dashArray: '5',
      weight: 3
    });
    
    this.addLayersinMap();
    this.tracingMarker._path.style.cursor="crosshair";
    this.measuretoolStart=true;
    //this.onMapEvents();
  }

  addLayersinMap():void{
    this.map.addLayer(this.tracepoly);
    this.tracingMarker.addTo(this.map);
    this.tracingLabel.addTo(this.map);
    this.tracingMarker.bringToFront();
  }

  onMapEvents(){
    this.onMousemover();
    this.onMapClick();
    this.onMapDblclick();
  }

  onMousemover():void{
    let index:number=-1;
    this.map.on('mousemove', (e:any)=>{
      if(this.measuretoolStart){
        this.tracingMarker.setLatLng([e.latlng.lat,e.latlng.lng]);
        this.tracingLabel.setLatLng([e.latlng.lat,e.latlng.lng]);
        if(this.measureLineStart==true)
          this.currentLengthAndArea = this.getLength(this.tracepoly);
        else
          this.currentLengthAndArea = this.getArea(this.tracepoly);
        this.setTempLabelIcon(this.currentLengthAndArea);
        this.addtempcords();
      }
    });
  }

  onMapClick():void{
    this.map.on('click', (e:any)=>{
      if(this.measuretoolStart){
        var latlng=this.tracingMarker.getLatLng();
        this.tracingcords.push([latlng.lat,latlng.lng]);
        this.tracepoly.setLatLngs(this.tracingcords);
        if(this.measureLineStart)
          this.addMarkerWithLable(latlng);  
      }  
    });
  }

  addMarkerWithLable(latlng:any){
    var mm=L.circleMarker(latlng, {
      color: 'blue',
      fillColor: 'white',
      fillOpacity: 1,
      radius: 5,
      weight:1               
    }).addTo(this.map);

    var lineLength = this.getLength(this.tracepoly);

    var ml=new L.Marker(latlng, {
      icon: new L.DivIcon({
          className: 'label-div-icon',
          html: '<span class="label-div-span">'+lineLength+' '+this.selectUnit.label+'</span>',
      })
    }).addTo(this.map);
    this.measureMarkers.push(mm);
    this.measurelabels.push(ml);
  }

  onMapDblclick(){
    this.map.on('contextmenu', (e:any)=>{
      if(!this.measuretoolStart){
        return;
      }
      L.DomEvent.stopPropagation(e);
      if(this.tracingcords.length>0){
        var latlng=this.tracingMarker.getLatLng();
        this.tracingcords.push([latlng.lat,latlng.lng]);
        this.tracepoly.setLatLngs(this.tracingcords);
        if(this.measureLineStart==true){
          this.currentLengthAndArea = this.getLength(this.tracepoly);
          this.addMarkerWithLable(latlng);
          this.removeTraserLabel();
        }          
        else{
          this.currentLengthAndArea = this.getArea(this.tracepoly);
          var center = turf.center(this.tracepoly.toGeoJSON());
          console.log(center.geometry.coordinates);
          this.tracingLabel.setLatLng([center.geometry.coordinates[1],center.geometry.coordinates[0]]);
          this.setTempLabelIcon(this.currentLengthAndArea);
        }
        //this.offMapEvents();
        this.removeTraserMarker();
        this.tracingcords=[];
        this.measuretoolStart=false; 
      }
    });
  }

  offMapClick():void{
    this.map.off('click');
  }

  offMousemover(){
    this.map.off('mousemove');
  }
  offMapDblclick(){
    this.map.off('contextmenu');
  }

  offMapEvents(){
    this.offMapClick();
    this.offMousemover();
    this.offMapDblclick();
  }

  removeTraserMarker(){
    this.map.removeLayer(this.tracingMarker);
    this.tracingMarker.setLatLng([0,0]);
  }

  removeTraserLabel(){
    this.map.removeLayer(this.tracingLabel);
    this.tracingLabel.setLatLng([0,0]);
  }

  removeMeasureMarkers(){
    this.measureMarkers.forEach((marker)=>{
      this.map.removeLayer(marker);
    });
    this.measureMarkers = [];
  }

  removeMeasureLabels(){
    this.measurelabels.forEach((marker)=>{
      this.map.removeLayer(marker);
    });
    this.measurelabels = [];
  }

  addtempcords():void{
    if(this.tracingcords.length>0){
      var latlng=this.tracingMarker.getLatLng();
      var temppolyline=Object.assign([], this.tracingcords);
      temppolyline.push([latlng.lat,latlng.lng]);
      this.tracepoly.setLatLngs(temppolyline);
    }else{
      this.tracepoly.setLatLngs([]);    
    }
  }

  setTempLabelIcon(lineLength:any){
    let unit:string = (this.measureLineStart)?this.selectUnit.label:'Sq'+this.selectUnit.label
    var ti = new L.DivIcon({
      className: 'label-div-icon',
      html: '<span class="label-div-span">'+lineLength+' '+unit+'</span>',
      iconSize: [0, 0]
    });
    this.tracingLabel.setIcon(ti);
  }

  getLength(line:any):string{
    var length = turf.length(line.toGeoJSON(), {units: this.selectUnit.value});
    return length.toFixed(2);
  }

  getArea(polygon:any){
    if(polygon._rings.length>0 && polygon._rings[0].length>0){
    var area = turf.area(polygon.toGeoJSON()).toFixed(2);
      if(this.selectUnit.value=='kilometers'){
        area = (Number(area)/1000000).toFixed(2);
      }
      return area;
    }else{
      return "0.0";
    }
  }

  unitChange(){
    this.clearMeasureLayers();
  }

  closeComponent(){
    this.clearMeasureLayers();
    this.showTools=false;
    this.map.tools.measureTool =false;
  }

  clearMeasureLayers(){
    this.map.removeLayer(this.tracepoly);
    this.tracingcords=[];
    this.tracepoly.setLatLngs(this.tracingcords);
    this.removeTraserMarker();
    this.removeMeasureLabels();
    this.removeMeasureMarkers();
    this.removeTraserLabel();
    //this.offMapEvents();
    this.currentLengthAndArea="0.0";
    this.geomType=" ";
    this.measureLineStart=false;
    this.measurePolygonStart=false;
  }

  ngOnDestroy() {
    console.log("close");
    this.closeComponent();
  }

}

<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">

<head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <title>Registration and Stamp Department</title>

    <!--    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css" integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossorigin="anonymous">-->
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&family=Roboto:wght@300;400;500&display=swap"
        rel="stylesheet" />
</head>

<body>
    <style>
        .td-col:nth-child(1) {
            display: none;
        }

        .td-col:nth-child(2) {
            display: none;
        }

        .td-col:nth-child(4),
        .td-col:nth-child(5) {
            display: none;
        }

        * {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }

        table {
            border-collapse: collapse;
        }

        body {
            
            padding: 5px;
         
            color: #767676;
            background-image: url(/assets/images/sampada-logo11.png);
            /* filter: grayscale(100%); */
            background-size: 10%;
            border: none;
            font-weight: 500;
            /* width: 857px !important; */
        }

    

        th {
            background: rgba(0, 0, 0, 0.3);
        }

        tfoot {
            background: #cdcdcd;
            color: #000;
        }

        table tr th,
        table tr td {
            border-collapse: collapse;
            /* border: 1px solid #CCC; */
            padding: 4px;
            font-weight: 500;
        }

        .col-md-4 {
            width: 33.3%;
        }

        .card-title {
            font-size: 1.2rem !important;
            color: #4682b4 !important;
            font-weight: 500 !important;
            border-left: 3px solid #ff9800;
            padding-left: 10px;
        }

        .form-control {
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: var(--bs-gray-900);
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #335e89;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }

        .form-control:disabled,
        .form-control[readonly] {
            background-color: #e9ecef;
            opacity: 1;
        }

        label {
            display: inline-block;
            margin-bottom:0rem;
            font-weight: normal;
        }

        .fid-ht {
            height: 400px;
            overflow: scroll;
        }

        .d-flex {
            display: flex;
        }

        .new-footer td {
            border: 0px !important;
        }

        .input-group-append {
            right: 85px;
        }

        @media print {
            .container {
                max-width: 100%;
                padding: 0 1rem;
            }

            body {
                background-image: url(./assets/images/sampada-logo11.png);
                filter: grayscale(100%);
                background-size: 10%;
            }

            table,
            td,
            th {
                border: 1px solid #ddd;
                text-align: left;
            }

            table {
                border-collapse: collapse;
                width: 100%;
            }

            th,
            td {
                font-size: 14px;
                padding: 15px;
            }

            .row {
                display: flex;

                margin-right: -15px;
                margin-left: -15px;
            }

            .col-xl-3 {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .d-flex {
                display: flex !important;
            }

            .col-xl-3 {
                position: relative;

                padding-right: 15px;
                padding-left: 15px;
            }

            .form-group {
                margin-bottom: 1rem;
            }

            fieldset {
                margin-bottom: 10px;
            }

            .cardprint:last-child {
                page-break-after: auto;
            }

            .name {
                font-size: 20px;
            }

            .font-weight-bold {
                font-weight: 600;
            }
        }

        @media screen,
        print {

            table,
            td,
            th {
                border: 1px solid #ddd;
                text-align: left;
            }

            table {
                border-collapse: collapse;
                width: 100%;
                margin-bottom: 1rem;
            }

            th,
            td {
                padding: 15px;
            }

            .row {
                display: flex;

                margin-right: -15px;
                margin-left: -15px;
            }

            .col-xl-3 {
                flex: 0 0 25%;
                max-width: 25%;
            }

            .d-flex {
                display: flex !important;
            }

            .col-xl-3 {
                position: relative;

                padding-right: 15px;
                padding-left: 15px;
            }

            .form-group {
                margin-bottom: 1rem;
            }

            fieldset {
                margin-bottom: 10px;
            }

            .cardprint:last-child {
                page-break-after: auto;
            }

            .bg-grey {
                background: #eee;
            }

            .fid-ht {
                margin-top: -20px;
            }

            .bg_new {
                background-image: url(/assets/images/sampada-logo11.png);
            }
        }
    </style>
    <div class="container-fluid">
      <div class="text-right">
        <!--<img title="Print" style="cursor: pointer;" alt="Print" onclick="window.print();" src="assets/images/print.png"/>-->
      </div>

      <div class="bh">
        <iframe id="viewer" style="height: 100vh;width: 100%;" frameborder="0"></iframe>
      </div>
<div class="e-sign" id="esignBtn" *ngIf="igrsEregPartyDetailsBean.contentStatus != 17 || (estampCommonBean.igrsEregEstampBean.estampStatus == 89 && directionFlag != 4)">
   <button class="btn btn-primary" (click)="showEsign()"> 
   <i class="fa fa-pencil-square-o"></i> 
   {{'TakeElectronicSign.save'| translate}}
   </button>
</div>
      <div class="esign-section" id="esignnId" *ngIf="igrsEregPartyDetailsBean.contentStatus != 17 || (estampCommonBean.igrsEregEstampBean.estampStatus == 89 && directionFlag != 4)">
      <button class="colsebtn" (click)="closeEsign()"> 
  <mat-icon>close</mat-icon>
   </button>
      
      
        <em class="alert alert-info p-1 mb-2 d-block"> Please complete e-sign process within 3 min otherwise session will be expired</em>

        <div class="form-group mt-1 mb-2" *ngIf="esignType == 3">
            <a target="_blank" href="{{settings.unipay_dsc_software_link}}" class="text-primary"><u>
        
                {{'TakeElectronicSign.dscDownloadlink' | translate}}
             </u></a>
        </div>
        <h4 class="text-primary font-italic font-weight-bold pl-3" style="font-size: 16px; margin-bottom: 12px">
            <u>e-sign Form</u>
        </h4>

        <form [formGroup]="esignHtml" (ngSubmit)="submitprintPartyEsign(esignHtml.value)"
              method="post" novalidate=novalidate>
              <div>
                <div class="p-2">
                    <div class="form-check form-check-inline mr-4" *ngIf="(igrsEregPartyDetailsBean.ekycStatus == 1 || estampCommonBean.igrsEregEstampBean.estampStatus == 89)">
                        <input class="form-check-input" type="radio" (click)="filterByEsignType(1)" name="esignType"  value="1" id="esignTypeId" formControlName="esignType"   required [ngClass]="{'is-invalid':submitted && t.esignType.errors}">
                        <label class="form-check-label" for="flexRadioDefault1">
                            CDAC
                        </label>
                      </div>
                      <div class="form-check form-check-inline" *ngIf="(igrsEregPartyDetailsBean.ekycStatus == 1 ||  estampCommonBean.igrsEregEstampBean.estampStatus == 89)">
                        <input class="form-check-input" type="radio" (click)="filterByEsignType(2)" name="esignType"  value="2" id="esignTypeId" formControlName="esignType" required [ngClass]="{'is-invalid':submitted && t.esignType.errors}">
                        <label class="form-check-label" for="flexRadioDefault1">
                            eMudhra
                        </label>
                      </div>
                      <div class="form-check form-check-inline" *ngIf="estampCommonBean.igrsEregEstampBean.estampStatus != 89 && (directionFlag != null && directionFlag == 4)">
                        <input class="form-check-input" type="radio" (click)="filterByEsignType(3)" name="esignType"  value="3" id="esignTypeId1"  formControlName="esignType" required [ngClass]="{'is-invalid':submitted && t.esignType.errors}">
                        <label class="form-check-label" for="esignTypeId1">
                            Unipay DSC
                        </label>
                      </div>
                      <div *ngIf="submitted"  class="invalid-feedback1">
                        <div *ngIf="esignType == undefined || esignType == ''" >
                            {{ "mandatory" | translate }}
                        </div>
                     </div>
                </div>

                <div class="pt-2 pb-2" *ngIf="esignType != '' && esignType != 3">
                    <label class="form-check-label" >
                        Esign Mode
                    </label>
                    <select class="form-control" formControlName="esignAuthMode" id="esignAuthMode"
                     [value]="esignAuthMode" (change)="esignAuthModeChange()">
                        <option value="" disabled>Select auth mode</option>
                        <option value="1">{{'otp'|translate}}</option>
                        <option value="2">{{'kyc-details.bio-thumb'|translate}}</option>
                        <option value="3">{{'kyc-details.bio-iris'|translate}}</option>
                        <!-- <option value="4">{{'kyc-details.bio-face'|translate}}</option> -->
                    </select>
                </div>
                    
            </div>
            
          <div >
            <div class="form-group" *ngIf="esignType != '' && esignType != 3">
              <!-- <label>
              {{'admin-profile-page-data.center-serviceprovider-details.confacc' |
              translate}}<span class="text-danger">*</span>
          </label> -->
              <label style="
                          color: #000;
                          font-weight: unset;
                          font-size: 15px;
                          margin-bottom: 0;
                        ">

              <!-- {{'admin-profile-page-data.center-serviceprovider-details.confacc' | translate}} -->
              {{ "e-stamp.Aadhar-digits" | translate }}
                <span class="text-danger">*</span>
              </label>
              <div class="input-group mb-2">
                <input style="height: 33px" type="text" maxlength="4" [readonly]="isReadOnly"
                       onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                       required=required [ngClass]="{
                            'is-invalid':
                              submitted && t.aadhaarLast4Digits.errors
                          }" name="aadhaarLast4Digits" class="form-control" formControlName="aadhaarLast4Digits" />
                <div *ngIf="submitted && t.aadhaarLast4Digits.errors"
                     class="invalid-feedback">
                  <div *ngIf="t.aadhaarLast4Digits.errors.required">
                    {{ "mandatory" | translate }}
                  </div>
                </div>
              </div>

              <label style="
                          color: #000;
                          font-weight: unset;
                          font-size: 15px;
                          margin-bottom: 0;
                        ">

              <!-- {{'admin-profile-page-data.center-serviceprovider-details.confacc' | translate}} -->
              {{ "e-stamp.Remarks" | translate }}
              </label>
              
              <div class="input-group mb-3">
                <textarea class="form-control" name="remarks" id="remarks"
                          formControlName="remarks" maxlength="100"></textarea>
              </div>
            </div>
            <div class="text-right">
            <!-- <button type="button" class="btn btn-primary mr-1"  (click)="backToPreviousPage()">{{'goback'
                    | translate}}</button> -->

              <button class="btn btn-primary mr-1" type="submit" formmethod="post" *ngIf="esignType != 3">
                <i class="fa fa-pencil-square-o"></i>
                {{ "esign" | translate }}
              </button>
              <button class="btn btn-primary" type="button" *ngIf="esignType == 3" (click)="partyDscEsignProcess()">
                <i class="fa fa-pencil-square-o"></i>
                {{ "esign" | translate }}
            </button>

            </div>
          </div>
          <!-- <a mat-raised-button color="danger" class="margright-50"
      >Submit</a> -->
        </form>


        <form ngNoForm=ngNoForm method="post" id="ICGForm" name="ICGForm" action=""
              enctype="application/x-www-form-urlencoded">
          <input type="hidden" name="msg" value="" id="msg" />

          <!-- <button type="submit">Send</button> -->
          <!-- <button type="submit" id="esign" name="esign" (click)='esignform.submit()' class="btn btn-primary">Submit</button> -->
        </form>
      </div>








    </div>

  <style>
    .esign-section, .showEsign{
      position: fixed;
    bottom: 35px;
    z-index: 999;
    background: #fff;
    right: 20px;
    width: 22rem;
    padding: 1.1rem !important;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    }
    div#esignBtn {
        display: none;
    }

#esignnId button.colsebtn {
    display: none;
}


    @media(max-width:450px){
         .esign-section, .showEsign{
    
    bottom: 23px;
   right: 6px;
    width: 19rem;
    padding: 0.5rem !important;
    
    } 
    }
    @media(max-width:767px){
   .esign-section {
    
    display: none;
}
div#esignBtn {
    position: fixed;
    bottom: 9rem;
    right: -8px;
    display: block;
}
#esignnId button.colsebtn {
   display: block;
    z-index: 9;
    top: -28px;
    right: 3px;
}
  .showEsign{
    
    bottom: 23px;
   right: 6px;
    width: 19rem;
    padding: 0.5rem !important;
    
    }
    }
  </style>
</body>

</html>
<!-- <ngx-ui-loader></ngx-ui-loader> -->

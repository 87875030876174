<div mat-dialog-content>
    <div  mat-dialog-action>
        <!-- <button  mat-raised-button (click)="closeMe()">{{ 'duty-calculation.close' | translate }}</button> -->
        <button class="colse-mpdal-btn"  mat-raised-button (click)="closeMe()">
  <em class="fa fa-times"></em>
        </button>

    </div>
<app-property-valuation [propIdAndLandParcelFromPV]="propIdAndLandParcelFromPV"></app-property-valuation>
</div>

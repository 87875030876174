<div class="fid-header"></div>
<div class="container mt-3">
    <!-- <h2 class="loginTitle">{{'citizen-registration-details.sign-up-as-legal' | translate}} </h2> -->
    <div class="registrationSteps">
        <div class="steps active">
            <div class="stepBox ">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-sign-in"></i>
                    <p>{{'login' | translate}}</p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>

        <div class="steps mx-3">
            <div class="stepBox">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-list-alt"></i>
                    <p> {{'e-stamp.deed-List' | translate}} </p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>
        <div class="steps mx-3">
            <div class="stepBox">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-pencil-square-o"></i>
                    <p> {{'e-stamp.deed-content-view-validate' | translate}} </p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>
    </div>



    <lib-alertmessage></lib-alertmessage>
    <mat-card class="example-card">
        <mat-card-title> </mat-card-title>
        <mat-card-content class="mat-card-content">
            <div class="row m-0">
                <div class="col-xl-5 col-lg-6 col-md-5 col-xs-12 col-sm-12 logInForms bg-white radius4 p-5">
                    <form (ngSubmit)="verifyOtp()">

                        <div class="row">
                            <div class="col-12">
                                <div class="from-group mb-3">
                                      <label class="w-100">{{'e-stamp.mobile-number' | translate}} <span
                                            style="color: red;">*</span></label>
                                <div class="input-group ">
                                  
                                    <div class="input-group-append">
                                        <span class="input-group-text"><i class="fa fa-phone"></i></span>
                                    </div>
                                    <input type="text" name="mobileNo" [(ngModel)]="mobileNo"
                                        class="form-control input_user" [readonly]="isReadOnly"
                                        placeholder="{{'enter-mobile-number' | translate}}" minlength="10"
                                        maxlength="10" (change)="changeContactNo()">
                                        </div>
                                        </div>
                                 <div class="from-group mb-3 mt-3"  *ngIf="isUser">
                                     <label class="w-100">{{'select-user' | translate}}
                                            <span style="color: red;">*</span></label>
                                    <div class="input-group">
                                       
                                        <div class="input-group-append"> <span class="input-group-text bg-primary"><i
                                                    class="fa fa-user"></i></span> </div>

                                        <ng-select required class="form-control p-0 pl-4" name="selectedUser"
                                            [(ngModel)]="selectedUser" (change)="selectUser()"
                                            placeholder="{{'select-user' | translate}}">
                                            <ng-option value="">{{'select-user'|translate}}</ng-option>
                                            <ng-option *ngFor="let user of userList" [value]="user.id">
                                                <!-- {{user.userName}} -->
                                                {{ lan == 0 ? 
                                                    user.userName + ' [' + user.firstNameEn + (user.middleNameEn?" "+user.middleNameEn: "") + (user.lastNameEn ?" "+user.lastNameEn : "") + ']' : 
                                                    user.userName + ' [' + user.firstNameHi + (user.middleNameHi ?" "+user.middleNameHi + " " : "") + (user.lastNameHi ?" "+user.lastNameHi : "") + ']' }}</ng-option>
                                        </ng-select>

                                    </div>
                                    </div>
                            
                            </div>
                        </div>
                        <div class="row align-items-center mt-2 mb-3">
                            <div class="col-5">
                                <div class="input-group " style="align-content: center;">
                                    <img src="{{captchaImageBase64Img}}" height="40" />
                                    <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                </div>
                            </div>
                            <div class="col-2 text-center">
                                <img src="assets/images/refresh_image.png" height="26px" 
                                    (click)="getStartupCaptcha()" />
                            </div>
                            <div class="col-5">
                                <div class="input-group ">
                                    <!-- <input type="text" name="captchaStr" required
                                            class="form-control input_user pl-3"
                                            placeholder="{{'enter-captcha' | translate}}"> -->

                                    <input type="text" name="captchaStr" [(ngModel)]="captchaStr" required
                                        class="form-control input_user"
                                        placeholder="{{'enter-captcha' | translate}}" maxlength="4">
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-end mt-1">
                            <!-- <div class="col-12"> -->
                           <div class="col-lg-6 input-group-prepend">
                                    <span *ngIf="seconds != 0">{{seconds | formatTime}} </span>
                                    <a *ngIf="seconds == 0 && !otpTouched" class="btn btn-primary btn-block"
                                        (click)="generateOtp()" (keyup.enter)="generateOtp()">{{'get-otp' |
                                        translate}}</a>
                                    <a *ngIf="seconds == 0 && otpTouched" class="btn btn-secondary btn-block"
                                        (click)="generateOtp()" (keyup.enter)="generateOtp()">{{'resend-otp' |
                                        translate}}</a>
                                </div>
                            <!-- </div> -->
                        </div>

                        <div *ngIf="otpGenerated" class=" mt-3" style="background-color: #ffffff4a!important;">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group mb-3">
                                        <label class="w-100">{{'enter-otp' | translate}}</label>
                                        <input type="text" name="mobile-otp" minlength="6" maxlength="6"
                                            class="form-control input_user" [(ngModel)]="otp">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="otpGenerated">
                            <div class="row mt-3">
                                <div class="col-md-6 col-6">
                                    <button class="btn btn-primary btn-block" type="submit">{{'submit' |
                                        translate}}</button>
                                </div>
                                <div class="col-md-6 col-6">
                                    <button class="btn btn-danger btn-block">{{'cancel' | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-xl-7 col-lg-6 col-md-7 col-xs-12 col-sm-12 hidden-xs   align-self-center p-5">
                    <div class="alert alert-primary p-2 mb-3" role="alert">
                        <div class="d-flex">
                            <i class="fa fa-info-circle mr-1"></i>
                            <p>{{'guidelines-lp-in'|translate}}</p>
                            <!-- <p *ngIf="lan==0">Enter mobile number and captcha (in the format shown) and click 'get OTP' to confirm it. 
                                An OTP will be sent to your phone via SMS. Copy the OTP from the SMS and enter into OTP box then, click the 'submit' button.
                            </p>
                            <p *ngIf="lan==1">  मोबाइल फोन नंबर और कैप्चा दर्ज करें (दिखाए गए प्रारूप में) और इसकी पुष्टि के लिए 'ओटीपी प्राप्त करें' पर क्लिक करें। आपके फोन पर एसएमएस के जरिए एक ओटीपी सत्यापन कोड भेजा जाएगा। एसएमएस से ओटीपी कोड कॉपी करें और ओटीपी बॉक्स में दर्ज करें, फिर 'सबमिट' बटन पर क्लिक करें।
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>

    </mat-card>






</div>
<ngx-ui-loader></ngx-ui-loader>
<div class="auth-main">
    <div class="container h-100">
        <div class="login_name_wrapper">
            <div class="d-flex justify-content-center">IGRS-SAMPADA</div>
        </div>
    <div class="d-flex justify-content-center h-50" >
        <div class="user_card">
            <div class="d-flex justify-content-center">
                <div class="login_logo_container"> <img src="./assets/images/logo-1.png" class="login_logo" alt="Logo"> </div>
            </div> 
            <div class="d-flex justify-content-center">
                <h5 class="login-title d-block ">{{'login-form-data.select-office-name' | translate}}</h5>
            </div>
            <lib-alertmessage></lib-alertmessage>
            <div class="d-flex justify-content-center form_container">
                <form name="form" (ngSubmit)="loginFormHtml.form.valid && onSubmitForLogin()" #loginFormHtml="ngForm" novalidate style="width: 320px">
                    <div class="input-group mb-3">
                        <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-user"></i></span> </div>
                         <input type="text" name="userName" #userName="ngModel"
                        [(ngModel)]="userProfileModel.userName" class="form-control input_user"
                        placeholder="{{'username' | translate}}" id="username" disabled> 
                    </div>
                    <div class="input-group mb-3">
                        <div class="input-group-append"> <span class="input-group-text"><i class="fa fa-globe"></i></span> </div>
                        <select class="form-control" name="selectedOffice" [(ngModel)]="selectedOffice">
                            <option value="0">{{'admin-profile-page-data.center-personal-detail-useronboard.select-office' | translate}}</option>
                            <option *ngFor="let office of officeList" [value]="office.id">{{office.officeName}}</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="btn btn-info" (click)="Cancel();">{{'cancel' | translate}}</button>
                        </div>
                        <div class="col-6">
                            <button type="submit" class="btn login_btn" style="float: right;">{{'login' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div class="mt-2">
                <div class="d-flex justify-content-center links"><a class="backtohome" routerLink="/"><i class="fa fa-home"></i> </a> </div>
            </div> -->
    
            
        <hr id="formLine">
        </div>
    </div>
    </div>
    </div>
    
    
    <ngx-ui-loader></ngx-ui-loader>
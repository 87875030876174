<div class=" ">

    <lib-alertmessage></lib-alertmessage>
    <form (ngSubmit)="onSubmitForPersonalProfile($event, profilePersonalDetailHtml.form)"
        #profilePersonalDetailHtml="ngForm" novalidate autocomplete="off">

        <!-- <h3 *ngIf="profilePersonalDetailHtml.form.valid" class="card-title">Some error {{profilePersonalDetailHtml.form.valid}}</h3> -->
        <h3 class="card-title">{{'citizen-registration-details.demographic-detail'|translate}}</h3>

        <mat-accordion class="demoGraphicDetails example-headers-align">

            <mat-expansion-panel [expanded]="true"
                *ngIf="userProfileModel.citizenType==1 && eRegPartyModel?.applicantType == '2'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'citizen-registration-details.govt-official-detail'|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-------------Govt Official Details------------->
                <div class="row">
                    <!-- <div class="form-group col-lg-4">
                        <label>{{'citizen-registration-details.personal-details.govt-dept-name' | translate}} <span
                                style="color: red;">*</span></label>
                        <input [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && govtDeptName.invalid }"
                            [required]="userProfileModel.citizenType==1 && eRegPartyModel?.applicantType == '2'"
                            name="govtDeptName" #govtDeptName="ngModel" [(ngModel)]="userProfileModel.govtDeptName"
                            class="form-control form-control-sm"
                            placeholder="{{'citizen-registration-details.personal-details.govt-dept-name' | translate}}"
                            maxlength="60" alpha [appCopyPastValidation]="true">
                        <div *ngIf="profilePersonalDetailHtml.submitted && govtDeptName.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(govtDeptName.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(govtDeptName.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div> -->
                    <div class="form-group col-lg-4">
                        <label>{{'citizen-registration-details.personal-details.govt-dept-name' | translate}} <span
                                style="color: red;">*</span></label>
                        <ng-select
                            placeholder="{{'admin-profile-page-data.center-office-details.select-department'|translate}}"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && govtDeptName.invalid }"
                            name="govtDeptName" #govtDeptName="ngModel" required
                            [(ngModel)]="userProfileModel.govtDeptId" (change)="allDepartmentList()">
                            <!-- <ng-option value=""
                                selected>{{'admin-profile-page-data.center-office-details.select-department'|translate}}</ng-option> -->
                            <ng-option *ngFor="let dl of departmentList"
                                [value]="dl.id">{{lan==1?dl.departmentNameHi:dl.departmentNameEn}}</ng-option>
                        </ng-select>
                        <div *ngIf="profilePersonalDetailHtml.submitted && govtDeptName.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(govtDeptName.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(govtDeptName.errors?.required)">{{'required' | translate}}</div>
                        </div>

                    </div>
                    <div class="form-group col-lg-4">
                        <label>{{'citizen-registration-details.personal-details.designation' | translate}} <span
                                style="color: red;">*</span></label>
                        <input
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && orgDesignationEn.invalid }"
                            [required]="userProfileModel.citizenType==1 && eRegPartyModel?.applicantType == '2'"
                            (change)="send(orgDesignationEn, orgDesignationHi,'')"
                            name="orgDesignationEn" #orgDesignationEn="ngModel"
                            [(ngModel)]="userProfileModel.orgDesignationEn" class="form-control"
                            placeholder="{{'citizen-registration-details.personal-details.designation' | translate}}"
                            maxlength="60" alpha [appCopyPastValidation]="true" autocomplete="off">
                        <div *ngIf="profilePersonalDetailHtml.submitted && orgDesignationEn.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(orgDesignationEn.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(orgDesignationEn.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-4">
                        <label>{{'citizen-registration-details.personal-details.designationHi' | translate}} <span
                                style="color: red;">*</span></label>
                        <input
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && orgDesignationHi.invalid }"
                            [required]="userProfileModel.citizenType==1 && eRegPartyModel?.applicantType == '2'"
                            (change)="sendHi(orgDesignationHi,orgDesignationEn,'')"
                            name="orgDesignationHi" #orgDesignationHi="ngModel"
                            [(ngModel)]="userProfileModel.orgDesignationHi" class="form-control"
                            placeholder="{{'citizen-registration-details.personal-details.designationHi' | translate}}"
                            maxlength="60" alpha [appCopyPastValidation]="true" autocomplete="off">
                        <div *ngIf="profilePersonalDetailHtml.submitted && orgDesignationHi.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(orgDesignationHi.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(orgDesignationHi.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                </div>

            </mat-expansion-panel>







            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'citizen-registration-details.personal-detail'|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>


                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>{{'citizen-registration-details.personal-details.fullname' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group mb-3">
                                <select
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && title.invalid }"
                                    required id="title" name="title" #title="ngModel"
                                    [(ngModel)]="userProfileModel.title" class="form-control form-control-sm"
                                    style="max-width: 60px;padding: 0;">
                                    <option [ngValue]="null" disabled>Title</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Miss">Miss</option>
                                </select>
                                <input [disabled]="firstNameDisable"
                                    (change)="send(firstNameEn, firstNameHi, userProfileModelAadharInfo.firstNameEn)"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && firstNameEn.invalid }"
                                    required id="firstNameEn" name="firstNameEn" #firstNameEn="ngModel"
                                    [(ngModel)]="userProfileModel.firstNameEn" class="form-control form-control-sm"
                                    placeholder="{{'kyc-details.FirstName' | translate}}" maxlength="60"
                                    (keypress)="onKeyPress($event)" [appCopyPastValidation]="true">
                                <input [disabled]="middleNameDisable" id="middleNameEn" name="middleNameEn"
                                    #middleNameEn="ngModel" [(ngModel)]="userProfileModel.middleNameEn"
                                    class="form-control form-control-sm"
                                    placeholder="{{'kyc-details.MiddleNameOptional' | translate}}" maxlength="60"
                                    (keypress)="onKeyPress($event)" [appCopyPastValidation]="true"
                                    (change)="send(middleNameEn, middleNameHi, userProfileModelAadharInfo.firstNameEn)">
                                <input [disabled]="lastNameDisable"
                                    (change)="send(lastNameEn, lastNameHi, userProfileModelAadharInfo.firstNameEn)"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && lastNameEn.invalid }"
                                    id="lastNameEn" name="lastNameEn" #lastNameEn="ngModel"
                                    [(ngModel)]="userProfileModel.lastNameEn" class="form-control form-control-sm"
                                    placeholder="{{'e-RegUserDetail.lastName' | translate}}" maxlength="60"
                                    [appCopyPastValidation]="true" (keypress)="onKeyPress($event)">
                                <div *ngIf="profilePersonalDetailHtml.submitted && (title.invalid || firstNameEn.invalid || lastNameEn.invalid)"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="lan==0 &&(title.errors?.required || firstNameEn.errors?.required || lastNameEn.errors?.required)">
                                        This Field is Mandatory</div>
                                    <div
                                        *ngIf="lan==1 &&(title.errors?.required || firstNameEn.errors?.required || lastNameEn.errors?.required)">
                                        {{'required' | translate}}</div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>{{'citizen-registration-details.personal-details.fullnamehi' | translate}} <span
                                    style="color: red;">*</span></label>
                            <div class="input-group mb-3">
                                <select
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && titlehi.invalid }"
                                    required id="titlehi" name="titlehi" #titlehi="ngModel"
                                    [(ngModel)]="userProfileModel.title" class="form-control form-control-sm"
                                    style="max-width: 60px;padding: 0;">
                                    <option [ngValue]="null" disabled>शीर्षक</option>
                                    <option value="Mr.">श्री</option>
                                    <option value="Mrs.">श्रीमती</option>
                                    <option value="Miss">कुमारी</option>
                                </select>
                                <input pattern="[\u0900-\u097F. : - , /]+"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && firstNameHi.invalid }"
                                    (change)="sendHi(firstNameHi,firstNameEn, userProfileModelAadharInfo.firstNameEn)"
                                    required id="firstNameHi" name="firstNameHi" #firstNameHi="ngModel"
                                    [(ngModel)]="userProfileModel.firstNameHi" class="form-control form-control-sm"
                                    placeholder="प्रथम नाम" maxlength="60">
                                <input pattern="[\u0900-\u097F. : - , /]+" id="middleNameHi" name="middleNameHi"
                                    #middleNameHi="ngModel" [(ngModel)]="userProfileModel.middleNameHi"
                                    (change)="sendHi(middleNameHi,middleNameEn, userProfileModelAadharInfo.firstNameEn)"
                                    class="form-control form-control-sm" placeholder="मध्य नाम (वैकल्पिक)"
                                    maxlength="60">
                                <input pattern="[\u0900-\u097F. : - , /]+"
                                    [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && lastNameHi.invalid }"
                                    id="lastNameHi" name="lastNameHi" #lastNameHi="ngModel"
                                    [(ngModel)]="userProfileModel.lastNameHi"
                                    (change)="sendHi(lastNameHi,lastNameEn, userProfileModelAadharInfo.lastNameEn)"
                                    class="form-control form-control-sm" placeholder="उपनाम" maxlength="60">
                                <div *ngIf="profilePersonalDetailHtml.submitted && (firstNameHi.invalid || lastNameHi.invalid)"
                                    class="invalid-feedback">
                                    <div *ngIf="lan==0 &&(firstNameHi.errors?.required || lastNameHi.errors?.required)">
                                        This Field is Mandatory</div>
                                    <div *ngIf="lan==1 &&(firstNameHi.errors?.required || lastNameHi.errors?.required)">
                                        {{'required' | translate}}</div>
                                </div>
                                <div *ngIf="(firstNameHi.touched && firstNameHi.invalid) || (middleNameHi.touched && middleNameHi.invalid) || (lastNameHi.touched && lastNameHi.invalid)"
                                    class="invalid-feedback">
                                    <div
                                        *ngIf="firstNameHi.errors?.pattern || middleNameHi.errors?.pattern || lastNameHi.errors?.pattern">
                                        हिंदी में टाइप करें</div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-lg-6">
                        <label>{{'citizen-registration-details.personal-details.mothername' | translate}} <span
                                style="color: red;">*</span></label>
                        <input (change)="send(motherNameEn, motherNameHi, '')"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && motherNameEn.invalid }"
                            required name="motherNameEn" #motherNameEn="ngModel"
                            [(ngModel)]="userProfileModel.motherNameEn" class="form-control form-control-sm"
                            placeholder="{{'kyc-details.entermotherName' | translate}}" maxlength="60"
                            (keypress)="onKeyPress($event)" [appCopyPastValidation]="true" autocomplete="off">
                        <div *ngIf="profilePersonalDetailHtml.submitted && motherNameEn.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(motherNameEn.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(motherNameEn.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-6">
                        <label>{{'citizen-registration-details.personal-details.mothernamehi' | translate}} <span
                                style="color: red;">*</span></label>
                        <input (change)="sendHi( motherNameHi,motherNameEn, '')" pattern="[\u0900-\u097F. : - , /]+"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && motherNameHi.invalid }"
                            required name="motherNameHi" #motherNameHi="ngModel"
                            [(ngModel)]="userProfileModel.motherNameHi" class="form-control form-control-sm"
                            placeholder="माता का नाम हिंदी में" maxlength="60" autocomplete="off">
                        <div *ngIf="profilePersonalDetailHtml.submitted && motherNameHi.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(motherNameHi.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(motherNameHi.errors?.required)">{{'required' | translate}}</div>
                        </div>
                        <div *ngIf="motherNameHi.touched && motherNameHi.invalid" class="invalid-feedback">
                            <div *ngIf="motherNameHi.errors?.pattern">हिंदी में टाइप करें</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-lg-4">
                        <label>{{'citizen-registration-details.personal-details.fatherguardianname' | translate}} <span
                                style="color: red;">*</span></label>
                        <input [disabled]="relativeNameDisable"
                            (change)="send(relativeNameEn, relativeNameHi, userProfileModelAadharInfo.relativeNameEn)"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && relativeNameEn.invalid }"
                            required name="relativeNameEn" #relativeNameEn="ngModel"
                            [(ngModel)]="userProfileModel.relativeNameEn" class="form-control form-control-sm"
                            placeholder="{{'citizen-registration-details.personal-details.fatherguardianname' | translate}}"
                            maxlength="60" (keypress)="onKeyPress($event)" [appCopyPastValidation]="true">
                        <div *ngIf="profilePersonalDetailHtml.submitted && relativeNameEn.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(relativeNameEn.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(relativeNameEn.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                    <!-- [\u0900-\u097F. : -] -->
                    <div class="form-group col-lg-4">
                        <label>{{'citizen-registration-details.personal-details.fatherguardiannamehi' | translate}}
                            <span style="color: red;">*</span></label>
                        <input
                            (change)="sendHi(relativeNameHi,relativeNameEn, userProfileModelAadharInfo.relativeNameEn)"
                            pattern="[\u0900-\u097F. : - , /]+"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && relativeNameHi.invalid }"
                            required name="relativeNameHi" #relativeNameHi="ngModel"
                            [(ngModel)]="userProfileModel.relativeNameHi" class="form-control form-control-sm"
                            placeholder="पिता/अभिभावक/पति का नाम" maxlength="60" autocomplete="off">
                        <div *ngIf="profilePersonalDetailHtml.submitted && relativeNameHi.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(relativeNameHi.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(relativeNameHi.errors?.required)">{{'required' | translate}}</div>
                        </div>
                        <div *ngIf="relativeNameHi.touched && relativeNameHi.invalid" class="invalid-feedback">
                            <div *ngIf="relativeNameHi.errors?.pattern">हिंदी में टाइप करें</div>
                        </div>
                    </div>

                    <div class="form-group col-lg-4">
                        <label>{{'relation' | translate}} <span style="color: red;">*</span></label>
                        <select
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && relativeRelation.invalid }"
                            required name="relativeRelation" #relativeRelation="ngModel"
                            [(ngModel)]="userProfileModel.relativeRelation" class="form-control form-control-sm">
                            <option value="" disabled>{{'select-relation' | translate}}</option>
                            <option value="Father">{{'father' | translate}}</option>
                            <option value="Guardian">{{'guardian' | translate}}</option>
                            <option value="Husband">{{'husband' | translate}}</option>
                        </select>
                        <div *ngIf="profilePersonalDetailHtml.submitted && relativeRelation.invalid"
                            class="invalid-feedback">
                            <div *ngIf="lan==0 &&(relativeRelation.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(relativeRelation.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-lg-3">
                        <label>{{'gender' | translate}} <span style="color: red;">*</span></label>
                        <select [disabled]="genderDisable"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && gender.invalid }"
                            name="gender" #gender="ngModel" [(ngModel)]="userProfileModel.gender" required
                            class="form-control form-control-sm">
                            <option value="" disabled>{{'select-gender' | translate}}</option>
                            <option *ngIf="userProfileModel.title === 'Mr.' || userProfileModel.gender=='M'" value="M">
                                {{'male' | translate}}</option>
                            <option
                                *ngIf="userProfileModel.title === 'Mrs.' || userProfileModel.title === 'Miss' || userProfileModel.gender=='F'"
                                value="F">{{'female' | translate}}</option>
                            <option value="O">{{'other' | translate}}</option>
                        </select>
                        <div *ngIf="profilePersonalDetailHtml.submitted && gender.invalid" class="invalid-feedback">
                            <div *ngIf="lan==0 &&(gender.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(gender.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <label>{{'dob' | translate}} <span style="color: red;">*</span></label>
                        <label id="age" name="age" style="float: right;">
                            {{'citizen-registration-details.personal-details.age' | translate}}: <b>{{age}}</b>
                            {{'citizen-registration-details.personal-details.years' | translate}}</label>
                        <input [disabled]="dobDisable"
                            [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && dob.invalid }" required
                            type="date" [min]="minToDate" [max]="maxToDate" name="dob" #dob="ngModel"
                            [(ngModel)]="userProfileModel.dob" (focusout)="validateDOB(dob.value)"
                            class="form-control form-control-sm">
                        <div *ngIf="profilePersonalDetailHtml.submitted && dob.invalid" class="invalid-feedback">
                            <div *ngIf="lan==0 &&(dob.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(dob.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <label>{{'category' | translate}} <span style="color: red;">*</span></label>
                        <select [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && caste.invalid }"
                            required name="caste" #caste="ngModel" [(ngModel)]="userProfileModel.caste"
                            class="form-control form-control-sm">
                            <option value="" disabled>{{'select-category' | translate}}</option>
                            <option value="General">{{'General' | translate}}</option>
                            <option value="OBC">{{'OBC' | translate}}</option>
                            <option value="ST">{{'ST' | translate}}</option>
                            <option value="SC">{{'SC' | translate}}</option>
                        </select>
                        <div *ngIf="profilePersonalDetailHtml.submitted && caste.invalid" class="invalid-feedback">
                            <div *ngIf="lan==0 &&(caste.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(caste.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group col-lg-3">
                        <label>{{'religion' | translate}} <span style="color: red;">*</span></label>
                        <select [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && religion.invalid }"
                            required name="religion" #religion="ngModel" [(ngModel)]="userProfileModel.religion"
                            class="form-control form-control-sm">
                            <option value=undefined disabled>{{'select-religion' | translate}}</option>
                            <option value="Hinduism">{{'Hinduism' | translate}}</option>
                            <option value="Islam">{{'Islam' | translate}}</option>
                            <option value="Christianity">{{'Christianity' | translate}}</option>
                            <option value="Sikhism">{{'Sikhism' | translate}}</option>
                            <option value="Buddhism">{{'Buddhism' | translate}}</option>
                            <option value="Jainism">{{'Jainism' | translate}}</option>
                            <option value="Other">{{'other' | translate}}</option>
                            <option value="No religion">{{'No-religion' | translate}}</option>
                        </select>

                        <div *ngIf="profilePersonalDetailHtml.submitted && religion.invalid" class="invalid-feedback">
                            <div *ngIf="lan==0 &&(religion.errors?.required)">This Field is Mandatory</div>
                            <div *ngIf="lan==1 &&(religion.errors?.required)">{{'required' | translate}}</div>
                        </div>
                    </div>
                </div>


            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'citizen-registration-details.address-detail'|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <fieldset class="mb-3">
                    <legend>{{'citizen-registration-details.address-details.permanent-address'|translate}}</legend>
                    <div class="row">
                        <div class="form-group col-lg-6 col-md-6" hidden>
                            <label>{{'citizen-profile-details.citizen-contact-details.country'|translate}}</label>
                            <select class="form-control form-control-sm" readonly>
                                <option selected>{{'citizen-registration-details.address-details.india'|translate}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.state'|translate}} <span
                                    style="color: red;">*</span></label>
                            <ng-select
                                [disabled]="this.userProfileModelAadharInfo.state != null && this.userProfileModelAadharInfo.state != undefined"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && stateIds.invalid }"
                                name="stateIds" #stateIds="ngModel" required [(ngModel)]="stateId"
                                (change)="setState('')" (change)="checkForSameAddress()">
                                <ng-option value=""
                                    selected>{{'citizen-registration-details.address-details.select-state'|translate}}</ng-option>
                                <ng-option *ngFor="let ds of states"
                                    [value]="ds.id">{{lan==1?ds.demographyNameHi:ds.demographyNameEn}}</ng-option>
                            </ng-select>
                            <div *ngIf="profilePersonalDetailHtml.submitted && stateIds.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(stateIds.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(stateIds.errors?.required)">{{'required' | translate}}</div>
                            </div>

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.district'|translate}} <span
                                    style="color: red;">*</span></label>
                            <ng-select
                                [disabled]="this.userProfileModelAadharInfo.demography != null && this.userProfileModelAadharInfo.demography != undefined"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && districtIds.invalid }"
                                name="districtIds" #districtIds="ngModel" required [(ngModel)]="districtId"
                                (change)="setDistrict('')" (change)="checkForSameAddress()">
                                <ng-option value=""
                                    selected>{{'citizen-registration-details.address-details.select-district'|translate}}</ng-option>
                                <ng-option *ngFor="let ds of districts"
                                    [value]="ds.id">{{lan==1?ds.demographyNameHi:ds.demographyNameEn}}</ng-option>
                            </ng-select>
                            <div *ngIf="profilePersonalDetailHtml.submitted && districtIds.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(districtIds.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(districtIds.errors?.required)">{{'required' | translate}}</div>
                            </div>

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="addressLine1Disable"
                                (change)="send(addressLine1,addressLine1Hi, userProfileModelAadharInfo.addressLine1)"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && addressLine1.invalid }"
                                name="addressLine1" #addressLine1="ngModel" required
                                [(ngModel)]="userProfileModel.addressLine1" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-1'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA (change)="checkForSameAddress()"
                                [appCopyPastValidation]="true">
                            <div *ngIf="profilePersonalDetailHtml.submitted && addressLine1.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(addressLine1.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(addressLine1.errors?.required)">{{'required' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-1-hi'|translate}}
                                <span style="color: red;">*</span></label>
                            <input [disabled]="addressLine1HiDisable"
                                pattern="[0-9०-९\u0900-\u097F .  _ ( ) , . / & # : ; | @ $ % = - ----Address ' ? *  + { } \s]*"
                                (change)="sendHi(addressLine1Hi,addressLine1, userProfileModelAadharInfo.addressLine1)"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && addressLine1Hi.invalid }"
                                name="addressLine1Hi" #addressLine1Hi="ngModel" required
                                [(ngModel)]="userProfileModel.addressLine1Hi" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-1-hi'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA (change)="checkForSameAddress()"
                                [appCopyPastValidation]="true">
                            <div *ngIf="profilePersonalDetailHtml.submitted && addressLine1Hi.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(addressLine1Hi.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(addressLine1Hi.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="addressLine1Hi.touched && addressLine1Hi.invalid" class="invalid-feedback">
                                <div *ngIf="addressLine1Hi.errors?.pattern">हिंदी में टाइप करें</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}</label>
                            <input [disabled]="addressLine2Disable" name="addressLine2" #addressLine2="ngModel"
                                (change)="send(addressLine2,addressLine2Hi, userProfileModelAadharInfo.addressLine2)"
                                [(ngModel)]="userProfileModel.addressLine2" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-2'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA (change)="checkForSameAddress()"
                                [appCopyPastValidation]="true">
                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-2-hi'|translate}}</label>
                            <input [disabled]="addressLine2HiDisable" name="addressLine2Hi" #addressLine2Hi="ngModel"
                                pattern="[0-9०-९\u0900-\u097F .  _ ( ) , . / & # : ; | @ $ % = - ----Address ' ? *  + { } \s]*"
                                (change)="sendHi(addressLine2Hi,addressLine2, userProfileModelAadharInfo.addressLine2)"
                                [(ngModel)]="userProfileModel.addressLine2Hi" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-2-hi'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA (change)="checkForSameAddress()"
                                [appCopyPastValidation]="true">

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.city'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="cityDisable" (change)="send(city,cityHi, '')"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && city.invalid }"
                                name="city" #city="ngModel" required [(ngModel)]="userProfileModel.city"
                                class="form-control"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-city'|translate}}"
                                maxlength="60" alpha [appCopyPastValidation]="true" (change)="checkForSameAddress()">
                            <div *ngIf="profilePersonalDetailHtml.submitted && city.invalid" class="invalid-feedback">
                                <div *ngIf="lan==0 &&(city.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(city.errors?.required)">{{'required' | translate}}</div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.city-hi'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="cityHiDisable" pattern="[\u0900-\u097F. : -]+"
                                (change)="sendHi(cityHi,city,'')"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && cityHi.invalid }"
                                name="cityHi" #cityHi="ngModel" required [(ngModel)]="userProfileModel.cityHi"
                                class="form-control"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-city-hi'|translate}}"
                                maxlength="60" [appCopyPastValidation]="true" (change)="checkForSameAddress()">
                            <div *ngIf="profilePersonalDetailHtml.submitted && cityHi.invalid" class="invalid-feedback">
                                <div *ngIf="lan==0 &&(cityHi.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(cityHi.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="cityHi.touched && cityHi.invalid" class="invalid-feedback">
                                <div *ngIf="cityHi.errors?.pattern">हिंदी में टाइप करें</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="postalCodeDisable"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && postalCode.invalid }"
                                id="postalCode" name="postalCode" #postalCode="ngModel" required
                                [(ngModel)]="userProfileModel.postalCode" class="form-control"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-postal-code'|translate}}"
                                minlength="6" maxlength="6" [OnlyNumber]="true" (change)="checkForSameAddress()"
                                pattern="^[1-9]\d{5}$">
                            <div *ngIf="profilePersonalDetailHtml.submitted && postalCode.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(postalCode.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(postalCode.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="postalCode.touched && postalCode.invalid" class="invalid-feedback">
                                <div *ngIf="postalCode.errors?.pattern">
                                    {{'citizen-registration-details.address-details.postal-code-validate' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset class="mb-3">
                    <legend>
                        {{'citizen-registration-details.address-details.present-address'|translate}} &nbsp;&nbsp;
                        &nbsp;&nbsp;
                        <span *ngIf="isIndian==1"> :
                            <input type="checkbox" id="sameaddress" name="sameaddress" (click)="sameAddress()">
                            {{'citizen-registration-details.address-details.same-as-permanent'|translate}}
                        </span>
                    </legend>
                    <div class="row">
                        <!-- <div class="form-group col-lg-6 col-md-6" hidden>
                        <label>{{'citizen-profile-details.citizen-contact-details.country'|translate}}</label>
                        <select class="form-control form-control-sm" readonly>
                            <option selected>{{'citizen-registration-details.address-details.india'|translate}}</option>
                        </select>
                    </div> -->
                        <div class="form-group col-lg-6 col-md-6" *ngIf="isIndian==0">
                            <label>{{'citizen-profile-details.citizen-contact-details.country'| translate}}<span
                                    style="color: red;">*</span></label>


                            <ng-select [disabled]="addisabled"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && countryIdPres.invalid }"
                                name="countryIdPres" #countryIdPres="ngModel" required [(ngModel)]="countryIdPre"
                                (change)="onChangeCountry($event)">
                                <ng-option value=""
                                    selected>{{'citizen-registration-details.address-details.select-country'|translate}}</ng-option>
                                <ng-option *ngFor="let cn of country"
                                    [value]="cn.id">{{lan==1?cn.countryNameHi:cn.countryNameEn}}</ng-option>
                            </ng-select>
                            <div *ngIf="profilePersonalDetailHtml.submitted && countryIdPres.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(countryIdPres.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(countryIdPres.errors?.required)">{{'required' | translate}}</div>
                            </div>

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.state'|translate}} <span
                                    style="color: red;">*</span></label>
                            <ng-select [disabled]="addisabled || isCountryDisable"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && stateIdPres.invalid }"
                                name="stateIdPres" #stateIdPres="ngModel" required [(ngModel)]="stateIdPre"
                                (change)="setStatePre()">
                                <ng-option value=""
                                    selected>{{'citizen-registration-details.address-details.select-state'|translate}}</ng-option>
                                <ng-option *ngFor="let dn of states"
                                    [value]="dn.id">{{lan==1?dn.demographyNameHi:dn.demographyNameEn}}</ng-option>
                            </ng-select>
                            <div *ngIf="profilePersonalDetailHtml.submitted && stateIdPres.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(stateIdPres.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(stateIdPres.errors?.required)">{{'required' | translate}}</div>
                            </div>

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.district'|translate}} <span
                                    style="color: red;">*</span></label>
                            <ng-select [disabled]="addisabled || isCountryDisable"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && districtIdPres.invalid }"
                                name="districtIdPres" #districtIdPres="ngModel" required [(ngModel)]="districtIdPre"
                                (change)="setDistrictPre()">
                                <ng-option value=""
                                    selected>{{'citizen-registration-details.address-details.select-district'|translate}}</ng-option>
                                <ng-option *ngFor="let dn of districtsPre"
                                    [value]="dn.id">{{lan==1?dn.demographyNameHi:dn.demographyNameEn}}</ng-option>
                            </ng-select>
                            <div *ngIf="profilePersonalDetailHtml.submitted && districtIdPres.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(districtIdPres.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(districtIdPres.errors?.required)">{{'required' | translate}}</div>
                            </div>

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="addisabled"
                                (change)="send(addressLine1Pre, addressLine1PreHi, userProfileModelAadharInfo.addressLine1Pre)"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && addressLine1Pre.invalid }"
                                name="addressLine1Pre" #addressLine1Pre="ngModel" required
                                [(ngModel)]="userProfileModel.addressLine1Pre" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-1'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA [appCopyPastValidation]="true">
                            <div *ngIf="profilePersonalDetailHtml.submitted && addressLine1Pre.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(addressLine1Pre.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(addressLine1Pre.errors?.required)">{{'required' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-1-hi'|translate}}
                                <span style="color: red;">*</span></label>
                            <input [disabled]="addisabled"
                                pattern="[0-9०-९\u0900-\u097F .  _ ( ) , . / & # : ; | @ $ % = - ----Address ' ? *  + { } \s]*"
                                (change)="sendHi(addressLine1PreHi, addressLine1Pre, userProfileModelAadharInfo.addressLine1Pre)"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && addressLine1PreHi.invalid }"
                                name="addressLine1PreHi" #addressLine1PreHi="ngModel" required
                                [(ngModel)]="userProfileModel.addressLine1PreHi" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-1-hi'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA [appCopyPastValidation]="true">
                            <div *ngIf="profilePersonalDetailHtml.submitted && addressLine1PreHi.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(addressLine1PreHi.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(addressLine1PreHi.errors?.required)">{{'required' | translate}}
                                </div>
                            </div>
                            <div *ngIf="addressLine1PreHi.touched && addressLine1PreHi.invalid"
                                class="invalid-feedback">
                                <div *ngIf="addressLine1PreHi.errors?.pattern">हिंदी में टाइप करें</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}</label>
                            <input [disabled]="addisabled" name="addressLine2Pre" #addressLine2Pre="ngModel"
                                (change)="send(addressLine2Pre, addressLine2PreHi, userProfileModelAadharInfo.addressLine2Pre)"
                                [(ngModel)]="userProfileModel.addressLine2Pre" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-2'|translate}}"
                                maxlength="120" appAlphaNumericDotCommaDashSlashSpaceA [appCopyPastValidation]="true">
                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.address-line-2-hi'|translate}}</label>
                            <input [disabled]="addisabled" name="addressLine2PreHi" #addressLine2PreHi="ngModel"
                                pattern="[0-9०-९\u0900-\u097F .  _ ( ) , . / & # : ; | @ $ % = - ----Address ' ? *  + { } \s]*"
                                (change)="sendHi(addressLine2PreHi, addressLine2Pre, userProfileModelAadharInfo.addressLine2Pre)"
                                [(ngModel)]="userProfileModel.addressLine2PreHi" class="form-control form-control-sm"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-address-line-2-hi'|translate}}"
                                maxlength="120" [appCopyPastValidation]="true">
                        </div>

                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.city'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="addisabled" (change)="send(cityPre,cityPreHi,'')"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && cityPre.invalid }"
                                name="cityPre" #cityPre="ngModel" required [(ngModel)]="userProfileModel.cityPre"
                                class="form-control"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-city'|translate}}"
                                maxlength="60" alpha [appCopyPastValidation]="true">
                            <div *ngIf="profilePersonalDetailHtml.submitted && cityPre.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(cityPre.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(cityPre.errors?.required)">{{'required' | translate}}</div>
                            </div>

                        </div>
                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.city-hi'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="addisabled" pattern="[\u0900-\u097F. : -]+"
                                (change)="sendHi(cityPreHi,cityPre,'')"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && cityPreHi.invalid }"
                                name="cityPreHi" #cityPreHi="ngModel" required [(ngModel)]="userProfileModel.cityPreHi"
                                class="form-control"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-city-hi'|translate}}"
                                maxlength="60" [appCopyPastValidation]="true">
                            <div *ngIf="profilePersonalDetailHtml.submitted && cityPreHi.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(cityPreHi.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(cityPreHi.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="cityPreHi.touched && cityPreHi.invalid" class="invalid-feedback">
                                <div *ngIf="cityPreHi.errors?.pattern">हिंदी में टाइप करें</div>
                            </div>
                        </div>

                        <div class="form-group col-lg-6 col-md-6">
                            <label>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input [disabled]="addisabled"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && postalCodePre.invalid }"
                                name="postalCodePre" #postalCodePre="ngModel" required
                                [(ngModel)]="userProfileModel.postalCodePre" class="form-control"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-postal-code'|translate}}"
                                minlength="6" maxlength="6" [OnlyNumber]="true" pattern="^[1-9]\d{5}$">
                            <div *ngIf="profilePersonalDetailHtml.submitted && postalCodePre.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(postalCodePre.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(postalCodePre.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="postalCodePre.touched && postalCodePre.invalid" class="invalid-feedback">
                                <div *ngIf="postalCodePre.errors?.pattern">
                                    {{'citizen-registration-details.address-details.postal-code-validate' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>

            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'citizen-profile-details.citizen-contact-details.heading'|translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{'citizen-profile-details.citizen-contact-details.e-mail'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input type="text" class="form-control form-control-sm" name="email" #email="ngModel"
                                [(ngModel)]="userProfileModel.email"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-e-mail'|translate}} "
                                maxlength="60" required (contextmenu)="false;" [appCopyPastValidation]="true"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && email.invalid }"
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" (blur)="onChangeEmail()"
                                autocomplete="off">
                            <!-- (change)="getEmailAvailability(email.value)"> -->
                            <div *ngIf="profilePersonalDetailHtml.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="lan==0 &&(email.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(email.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="email.touched && email.invalid" class="invalid-feedback-1">
                                <div *ngIf="email.errors?.pattern">
                                    {{'citizen-profile-details.citizen-contact-details.mail-validate' | translate}}
                                </div>
                            </div>

                            <!-- <label class="w-100" *ngIf="emailtaken"><span style="color: red;">{{'citizen-profile-details.login-detail-profile.emailtaken' | translate}}</span></label>
                            <label class="w-100" *ngIf="emailavail"><span style="color: green;">{{'citizen-profile-details.login-detail-profile.emailavailable' | translate}}</span></label>         -->
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{'citizen-profile-details.citizen-contact-details.alternate-mail'|translate}}
                            </label>
                            <input type="text" class="form-control form-control-sm" name="altEmail" #altEmail="ngModel"
                                [(ngModel)]="userProfileModel.altEmail"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-e-mail'|translate}}"
                                maxlength="60" (contextmenu)="false;" [appCopyPastValidation]="true"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && altEmail.invalid }"
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" (blur)="onChangeEmail()"
                                autocomplete="off">
                            <div *ngIf="altEmail.touched && altEmail.invalid" class="invalid-feedback">
                                <div *ngIf="altEmail.errors?.pattern">
                                    {{'citizen-profile-details.citizen-contact-details.mail-validate' | translate}}
                                </div>
                            </div>
                            <!-- <div *ngIf="altEmail.touched && altEmail.invalid" class="invalid-feedback-1">
                                    <div *ngIf="altEmail.errors?.pattern" >{{'citizen-profile-details.citizen-contact-details.mail-validate' | translate}}</div>
                                </div> -->
                            <div class="invalid-feedback-1" *ngIf="invalidAltEmail">
                                {{'citizen-profile-details.login-detail-profile.altemail-email-not-same' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div *ngIf="isIndian==0" class="form-group">
                            <label>{{'citizen-profile-details.citizen-contact-details.mobile-number'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input type="text" class="form-control form-control-sm" name="contactNo"
                                #contactNo="ngModel" [(ngModel)]="userProfileModel.contactNo"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-mobile-number'|translate}}"
                                maxlength="10" required (contextmenu)="false;" [appCopyPastValidation]="true"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && contactNo.invalid }"
                                pattern="^[6-9]\d{9}$" autocomplete="off">
                            <div *ngIf="profilePersonalDetailHtml.submitted && contactNo.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(contactNo.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(contactNo.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="contactNo.touched && contactNo.invalid" class="invalid-feedback">
                                <div *ngIf="contactNo.errors?.pattern">
                                    {{'citizen-profile-details.login-detail-profile.mobile-validate' | translate}}</div>
                            </div>
                        </div>
                        <div *ngIf="isIndian==1" class="form-group">
                            <label>{{'citizen-profile-details.citizen-contact-details.mobile-number'|translate}} <span
                                    style="color: red;">*</span></label>
                            <input type="text" class="form-control form-control-sm" name="contactNo"
                                #contactNo="ngModel" [(ngModel)]="userProfileModel.contactNo"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-mobile-number'|translate}}"
                                maxlength="10" required readonly disabled (contextmenu)="false;"
                                [appCopyPastValidation]="true"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && contactNo.invalid }"
                                pattern="^[6-9]\d{9}$" autocomplete="off">
                            <div *ngIf="profilePersonalDetailHtml.submitted && contactNo.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(contactNo.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(contactNo.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="contactNo.touched && contactNo.invalid" class="invalid-feedback">
                                <div *ngIf="contactNo.errors?.pattern">
                                    {{'citizen-profile-details.login-detail-profile.mobile-validate' | translate}}</div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{'citizen-profile-details.citizen-contact-details.alternate-number'|translate}}
                            </label>
                            <input type="text" class="form-control form-control-sm" name="altContactNo"
                                #altContactNo="ngModel" [(ngModel)]="userProfileModel.altContactNo"
                                placeholder="{{'citizen-profile-details.citizen-contact-details.enter-mobile-number'|translate}}"
                                maxlength="10" minlength="10" [OnlyNumber]="true"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && altContactNo.invalid }"
                                pattern="^[6-9]\d{9}$" (blur)="onChangeAltMob()" autocomplete="off">
                            <div *ngIf="altContactNo.touched && altContactNo.invalid" class="invalid-feedback">
                                <div *ngIf="altContactNo.errors?.pattern">
                                    {{'citizen-profile-details.login-detail-profile.mobile-validate' | translate}}</div>
                            </div>
                            <div class="invalid-feedback-1" *ngIf="invalidAltMob">
                                {{'citizen-profile-details.login-detail-profile.altmob-mob-not-same' | translate}}
                            </div>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'citizen-profile-details.login-detail-profile.name' | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-lg-4">
                        <div class="input-group mb-3">
                            <label class="w-100">{{'citizen-profile-details.login-detail-profile.username' | translate}}
                                <span style="color: red;">*</span></label>
                            <input [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && userName.invalid }"
                                name="userName" #userName="ngModel" [(ngModel)]="userProfileModel.userName" required
                                class="form-control" (contextmenu)="false;" [appCopyPastValidation]="true"
                                maxlength="40" placeholder="{{'enter-username' | translate}}"
                                (change)="getAvailability(userName.value)"
                                pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@_.]{3,}$" autocomplete="off">

                            <div *ngIf="profilePersonalDetailHtml.submitted && userName.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(userName.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(userName.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div *ngIf="userName.invalid" class="w-100">
                                <div *ngIf="lan==0">
                                    User Name Must Have<br />
                                    1. Minimum 3 characters <br />
                                    2. Combination of letters(Capital/Small) and numbers <br />
                                    3. Optional special character like(@_.)<br />
                                </div>
                                <div *ngIf="lan==1">
                                    उपयोगकर्ता होना चाहिए<br />
                                    1. न्यूनतम 3 वर्ण <br />
                                    2. अक्षर (बड़े/छोटे) और अंकों का संयोजन <br />
                                    3. वैकल्पिक विशेष वर्ण जैसे (@ _.)<br />
                                </div>
                            </div>
                            <label class="w-100" *ngIf="usernametaken"><span
                                    style="color: red;">{{'citizen-profile-details.login-detail-profile.taken' |
                                    translate}}</span></label>
                            <label class="w-100" *ngIf="usernameavail"><span
                                    style="color: green;">{{'citizen-profile-details.login-detail-profile.available' |
                                    translate}}</span></label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>{{'citizen-profile-details.login-detail-profile.password' | translate}} <span
                                    style="color: red;">*</span></label>
                            <input [type]="passhide ? 'password' : 'text'" name="password1" #password1="ngModel"
                                (contextmenu)="false;" required [appCopyPastValidation]="true"
                                [(ngModel)]="userProfileModel.password" class="form-control input_pass" minlength="8"
                                maxlength="14"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#_$!%*?&])[A-Za-z\d@$#_!%*?&]{8,}$"
                                placeholder="{{'enter-password' | translate}}" id="password"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && password1.invalid }"
                                autocomplete="new-password">
                            <div class="input-group-prepend showPassword"> <button type="button" class=" "
                                    (click)="passhide = !passhide" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="passhide"><mat-icon>{{passhide ? 'visibility_off' :
                                        'visibility'}}</mat-icon></button> </div>
                            <div *ngIf="(profilePersonalDetailHtml.submitted || password1.touched) && password1.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(password1.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(password1.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="password1.dirty && ((password1.errors && password1.errors?.compare) || (confpassword1.errors && confpassword1.errors?.compare)) && !password1.errors?.required">
                                {{'notmatch-password' | translate}}
                            </div>
                            <div *ngIf="password1.invalid">
                                <div *ngIf="lan==0">
                                    Password Must Have<br />
                                    1. Minimum 6 and Maximum 14 characters <br />
                                    2. at least one small letter <br />
                                    3. at least one capital letter<br />
                                    4. at least one number <br />
                                    5. at least one special character<br />
                                </div>
                                <div *ngIf="lan==1">
                                    पासवर्ड होना चाहिए<br />
                                    1. न्यूनतम 8 और अधिकतम 14 वर्ण <br />
                                    2. कम से कम एक छोटा अक्षर <br />
                                    3. कम से कम एक कैपिटल अक्षर<br />
                                    4. कम से कम एक नंबर <br />
                                    5. कम से कम एक विशेष वर्ण<br />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>{{'citizen-profile-details.login-detail-profile.confirm-password' | translate}} <span
                                    style="color: red;">*</span></label>
                            <input [type]="passhidec ? 'password' : 'text'" name="confpassword1"
                                #confpassword1="ngModel" compare-password="password1" minlength="6" maxlength="14"
                                (contextmenu)="false;" required [appCopyPastValidation]="true"
                                [(ngModel)]="userProfileModel.confirmPass" class="form-control input_pass"
                                placeholder="{{'conf-password' | translate}}"
                                [ngClass]="{ 'is-invalid': profilePersonalDetailHtml.submitted && confpassword1.invalid }"
                                autocomplete="new-password">
                            <div class="input-group-prepend showPassword"> <button type="button" class=" "
                                    (click)="passhidec = !passhidec" [attr.aria-label]="'Hide password'"
                                    [attr.aria-pressed]="passhidec"><mat-icon>{{passhidec ? 'visibility_off' :
                                        'visibility'}}</mat-icon></button> </div>
                            <div *ngIf="profilePersonalDetailHtml.submitted && confpassword1.invalid"
                                class="invalid-feedback">
                                <div *ngIf="lan==0 &&(confpassword1.errors?.required)">This Field is Mandatory</div>
                                <div *ngIf="lan==1 &&(confpassword1.errors?.required)">{{'required' | translate}}</div>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="confpassword1.dirty && ((password1.errors && password1.errors?.compare) || (confpassword1.errors && confpassword1.errors?.compare)) && !confpassword1.errors?.required">
                                {{'notmatch-password' | translate}}
                            </div>
                        </div>
                    </div>
                </div>





            </mat-expansion-panel>
        </mat-accordion>


        <button *ngIf="detailCheckedVal" class="mt-2  ml-2 btn btn-primary float-right" mat-raised-button type="submit"
            id="submit" name="submit" value="submit" [disabled]="isSubmitted">{{'submit_details' | translate}}</button>

        <button *ngIf="!detailCheckedVal" class="mt-2  ml-2 btn btn-primary float-right" mat-raised-button
            disabled>{{'submit_details' | translate}}</button>


        <button mat-raised-button type="button" class="mt-2 btn btn-warning float-right ml-2" data-toggle="modal"
            data-target="#loginoptions">{{'verify_details' | translate}} </button>

        <div class="modal fade" id="loginoptions" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="loginoptionsLabel" aria-hidden="true">
            <div class="modal-dialog  modal-dialog-centered modal-xl">

                <div class="modal-content loginoptionsmodal">
                    <div>
                        <h2 class="Consenterdialog-title">
                            {{'header-note-demographic'|translate}}
                        </h2>
                        <button type="button" class="colsebtn h3" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <!-- <h5 class="modal-title" id="loginoptionsLabel">{{'header-note-demographic'|translate}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body">

                        <section>
                            <style>
                                .alldetails p {
                                    font-size: 13px;
                                    color: darkgray;
                                    margin: 0;
                                }

                                .alldetails h3 {
                                    font-size: 15px;
                                    color: #000000d1;
                                }
                            </style>
                            <div class=" alldetails">
                                <!-- <h2>{{'header-note-demographic'|translate}}</h2> -->

                                <!-- <table class="table table-bordered">
                            <tr>
                                <td><label>{{'citizen-type' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.citizenType}} - {{userProfileModel.isIndianResident}}</td>
                            </tr>
                            <tr *ngIf="userProfileModel.juristicPerson!=null">
                                <td colspan="2">{{'legal-entity-details' | translate}}</td>
                            </tr>
                            <tr *ngIf="userProfileModel.juristicPerson!=null">
                                <td><label>{{'company-details-type' | translate}} <span style="color: red;">*</span></label></td>

                                <td *ngIf="userProfileModel.juristicPerson.companyIdType?.id==1">{{userProfileModel.juristicPerson.companyIdNo}} - {{'gstin' | translate}}</td>
                                <td *ngIf="userProfileModel.juristicPerson.companyIdType?.id==3">{{userProfileModel.juristicPerson.companyIdNo}} - {{'cin' | translate}}</td>
                            </tr>
                            <tr *ngIf="userProfileModel.juristicPerson!=null">
                                <td><label>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.juristicPerson.companyName}}{{userProfileModel.juristicPerson.legalNameOfBusiness}}</td>
                            </tr>
                        </table>
                        <hr> -->

                                <div class="p-2 border bg-light mb-3">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <p class="text-primary mb-3"><strong>{{'citizen-type' | translate}}</strong>
                                            </p>
                                            <!-- <p>{{'kyc-details.kyc-source' | translate}} <span style="color: red;">*</span> </p> -->
                                            <!-- <h3>{{userProfileModel.citizenType}} - {{userProfileModel.isIndianResident}}</h3> -->
                                            <h3>
                                                <span class="badge badge-default font-weight-normal mr-2"
                                                    *ngIf="isIndian==0">{{'citizen-registration-details.non-indian-citizen'|translate}}</span>
                                                <span class="badge badge-default font-weight-normal mr-2"
                                                    *ngIf="isIndian==1">{{'citizen-registration-details.indian-citizen'|translate}}</span>
                                                <span class="badge badge-secondary font-weight-normal mr-2"
                                                    *ngIf="citizenType==1">{{'citizen'|translate}} </span>
                                                <span class="badge badge-secondary font-weight-normal mr-2"
                                                    *ngIf="citizenType==2">{{'legal_entity'|translate}}</span>
                                            </h3>
                                        </div>

                                        <div class="col-lg-4">
                                            <p class="text-primary mb-3"><strong>{{'kyc-detail' | translate}}</strong>
                                            </p>
                                            <p>{{'kyc-details.kyc-source' | translate}} <span
                                                    style="color: red;">*</span> </p>
                                            <h3>{{userProfileModel.kycType}} </h3>
                                        </div>

                                        <div class="col-lg-4">
                                            <div *ngIf="userProfileModel.juristicPerson!=null">
                                                <p class="text-primary mb-3">{{'legal-entity-details' | translate}}</p>
                                            </div>
                                            <div *ngIf="userProfileModel.juristicPerson!=null">
                                                <p>{{'company-details-type' | translate}} <span
                                                        style="color: red;">*</span></p>

                                                <h3 *ngIf="userProfileModel.juristicPerson.companyIdType?.id==1">
                                                    {{userProfileModel.juristicPerson.companyIdNo}} - {{'gstin' |
                                                    translate}}</h3>
                                                <h3 *ngIf="userProfileModel.juristicPerson.companyIdType?.id==3">
                                                    {{userProfileModel.juristicPerson.companyIdNo}} - {{'cin' |
                                                    translate}}</h3>
                                            </div>
                                            <div *ngIf="userProfileModel.juristicPerson!=null">
                                                <p>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name'
                                                    | translate}} <span style="color: red;">*</span></p>
                                                <h3>{{userProfileModel.juristicPerson.companyName}}{{userProfileModel.juristicPerson.legalNameOfBusiness}}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--
                                <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="userProfileModel.juristicPerson!=null">
                                    <p>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name' | translate}} <span style="color: red;">*</span> </p>
                                    <h3 class="badge badge-primary">{{userProfileModel.juristicPerson.companyName}}{{userProfileModel.juristicPerson.legalNameOfBusiness}} </h3>
                                </div>
            -->
                                        <!-- <div class="col-lg-3 col-md-4 col-sm-6">

                                    <p>{{'kyc-details.kyc-source' | translate}} <span style="color: red;">*</span> </p>
                                    <h3>{{userProfileModel.kycType}} </h3>
                                </div> -->
                                        <!-- <div class="col-lg-3 col-md-4 col-sm-6">

                                </div> -->

                                    </div>
                                </div>



                                <!-- <h2 class="card-title">{{'demographic-details' | translate}}</h2> -->
                                <fieldset class="legend-section mb-3">
                                    <legend class="sidebar__widget-title">
                                        {{'citizen-registration-details.personal-detail'|translate}} </legend>
                                    <div class=" ">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'citizen-registration-details.personal-details.fullname' |
                                                    translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.firstNameEn}} {{userProfileModel.middleNameEn}}
                                                    {{userProfileModel.lastNameEn}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'citizen-registration-details.personal-details.fullnamehi' |
                                                    translate}} <span style="color: red;">*</span> </p>

                                                <h3>{{userProfileModel.firstNameHi}} {{userProfileModel.middleNameHi}}
                                                    {{userProfileModel.lastNameHi}}</h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'citizen-registration-details.personal-details.mothername' |
                                                    translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.motherNameEn}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'citizen-registration-details.personal-details.mothernamehi' |
                                                    translate}} <span style="color: red;">*</span></p>
                                                <h3>{{userProfileModel.motherNameHi}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'citizen-registration-details.personal-details.fatherguardianname'
                                                    | translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.relativeNameEn}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'citizen-registration-details.personal-details.fatherguardiannamehi'
                                                    | translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.relativeNameHi}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'relation' | translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.relativeRelation}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'gender' | translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.gender}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'dob' | translate}} <span style="color: red;">*</span></p>
                                                <h3>{{userProfileModel.dob | date: 'dd/MM/yyyy'}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'category' | translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.caste}} </h3>
                                            </div>
                                            <div class="col-lg-3 col-md-4 col-sm-6">
                                                <p>{{'religion' | translate}} <span style="color: red;">*</span> </p>
                                                <h3>{{userProfileModel.religion}} </h3>
                                            </div>




                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset class="legend-section mb-3">
                                    <legend class="sidebar__widget-title">
                                        {{'citizen-registration-details.address-detail'|translate}}
                                    </legend>
                                    <div class="innner-box">
                                        <fieldset class="legend-section mb-3">
                                            <legend class="sidebar__widget-title">
                                                {{'citizen-registration-details.address-details.permanent-address'|translate}}
                                            </legend>

                                            <div class="row mb-3">
                                                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <h3>{{'citizen-registration-details.address-details.permanent-address'|translate}}
                                                    </h3>
                                                </div> -->
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.state'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.stateName}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.district'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.demography!=null?userProfileModel.demography.demographyNameEn:''}}
                                                        -
                                                        {{userProfileModel.demography!=null?userProfileModel.demography.demographyNameHi:''}}
                                                    </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.addressLine1}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-1-hi'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.addressLine1Hi}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}
                                                    </p>
                                                    <h3 *ngIf="userProfileModel.addressLine2!=null">
                                                        {{userProfileModel.addressLine2}} </h3>
                                                    <h3 *ngIf="userProfileModel.addressLine2==null">-</h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-2-hi'|translate}}
                                                    </p>
                                                    <h3 *ngIf="userProfileModel.addressLine2Hi!=null">
                                                        {{userProfileModel.addressLine2Hi}} </h3>
                                                    <h3 *ngIf="userProfileModel.addressLine2Hi==null">-</h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.city'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.city}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.city-hi'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.cityHi}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.postalCode}} </h3>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="innner-box">
                                        <fieldset class="legend-section">
                                            <legend class="sidebar__widget-title">
                                                {{'citizen-registration-details.address-details.present-address'|translate}}
                                            </legend>

                                            <div class="row mb-3">

                                                <!-- <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <h3>{{'citizen-registration-details.address-details.present-address'|translate}}
                                                    </h3>
                                                </div> -->
                                                <div class="col-lg-3 col-md-4 col-sm-6" *ngIf="isIndian==0">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.country'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.countryNameEn}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.state'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.stateNamePre}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.district'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.demographyPre!=null?userProfileModel.demographyPre.demographyNameEn:''}}
                                                        -
                                                        {{userProfileModel.demographyPre!=null?userProfileModel.demographyPre.demographyNameHi:''}}
                                                    </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.addressLine1Pre}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-1-hi'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.addressLine1PreHi}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}
                                                    </p>
                                                    <h3 *ngIf="userProfileModel.addressLine2Pre!=null">
                                                        {{userProfileModel.addressLine2Pre}} </h3>
                                                    <h3 *ngIf="userProfileModel.addressLine2Pre==null">-</h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.address-line-2-hi'|translate}}
                                                    </p>
                                                    <h3 *ngIf="userProfileModel.addressLine2PreHi!=null">
                                                        {{userProfileModel.addressLine2PreHi}} </h3>
                                                    <h3 *ngIf="userProfileModel.addressLine2PreHi==null">-</h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.city'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.cityPre}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.city-hi'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.cityPreHi}} </h3>
                                                </div>
                                                <div class="col-lg-3 col-md-4 col-sm-6">
                                                    <p>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}}
                                                        <span style="color: red;">*</span>
                                                    </p>
                                                    <h3>{{userProfileModel.postalCodePre}} </h3>
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>



                                </fieldset>

                                <fieldset class="legend-section mb-3">
                                    <legend class="sidebar__widget-title">
                                        {{'citizen-profile-details.citizen-contact-details.heading'|translate}}</legend>

                                    <div class="row mb-3">
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <p>{{'citizen-profile-details.citizen-contact-details.e-mail'|translate}}
                                                <span style="color: red;">*</span>
                                            </p>
                                            <h3>{{userProfileModel.email}} </h3>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <p>{{'citizen-profile-details.citizen-contact-details.alternate-mail'|translate}}
                                            </p>
                                            <h3 *ngIf="userProfileModel.altEmail!=null">{{userProfileModel.altEmail}}
                                            </h3>
                                            <h3 *ngIf="userProfileModel.altEmail==null||userProfileModel.altEmail==''">-
                                            </h3>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <p>{{'citizen-profile-details.citizen-contact-details.mobile-number'|translate}}
                                                <span style="color: red;">*</span>
                                            </p>
                                            <h3>{{userProfileModel.contactNo}} </h3>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <p>{{'citizen-profile-details.citizen-contact-details.alternate-number'|translate}}
                                            </p>
                                            <h3 *ngIf="userProfileModel.altContactNo!=null">
                                                {{userProfileModel.altContactNo}} </h3>
                                            <h3 *ngIf="userProfileModel.altContactNo==null">-</h3>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </section>




                        <table class="table d-none">
                            <tr>
                                <td><label>{{'citizen-type' | translate}} <span style="color: red;">*</span></label>
                                </td>
                                <td>{{userProfileModel.citizenType}} - {{userProfileModel.isIndianResident}}</td>
                            </tr>
                            <tr *ngIf="userProfileModel.juristicPerson!=null">
                                <td colspan="2">{{'legal-entity-details' | translate}}</td>
                            </tr>
                            <tr *ngIf="userProfileModel.juristicPerson!=null">
                                <td><label>{{'company-details-type' | translate}} <span
                                            style="color: red;">*</span></label></td>

                                <td *ngIf="userProfileModel.juristicPerson.companyIdType?.id==1">
                                    {{userProfileModel.juristicPerson.companyIdNo}} - {{'gstin' | translate}}</td>
                                <td *ngIf="userProfileModel.juristicPerson.companyIdType?.id==3">
                                    {{userProfileModel.juristicPerson.companyIdNo}} - {{'cin' | translate}}</td>
                            </tr>
                            <tr *ngIf="userProfileModel.juristicPerson!=null">
                                <td><label>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name' |
                                        translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.juristicPerson.companyName}}{{userProfileModel.juristicPerson.legalNameOfBusiness}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">{{'kyc-detail' | translate}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'kyc-details.kyc-source' | translate}} <span
                                            style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.kycType}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">{{'demographic-details' | translate}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">{{'citizen-registration-details.personal-detail'|translate}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-registration-details.personal-details.fullname' | translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td> {{userProfileModel.firstNameEn}} {{userProfileModel.middleNameEn}}
                                    {{userProfileModel.lastNameEn}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-registration-details.personal-details.fullnamehi' | translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td> {{userProfileModel.firstNameHi}} {{userProfileModel.middleNameHi}}
                                    {{userProfileModel.lastNameHi}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-registration-details.personal-details.mothername' | translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.motherNameEn}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-registration-details.personal-details.mothernamehi' | translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.motherNameHi}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-registration-details.personal-details.fatherguardianname' |
                                        translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.relativeNameEn}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-registration-details.personal-details.fatherguardiannamehi' |
                                        translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.relativeNameHi}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'relation' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.relativeRelation}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'gender' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.gender}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'dob' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.dob | date: 'dd/MM/yyyy'}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'category' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.caste}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'religion' | translate}} <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.religion}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">{{'citizen-registration-details.address-detail'|translate}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    {{'citizen-registration-details.address-details.permanent-address'|translate}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.state'|translate}} <span
                                            style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.stateName}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.district'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.demography!=null?userProfileModel.demography.demographyNameEn:''}}
                                    -
                                    {{userProfileModel.demography!=null?userProfileModel.demography.demographyNameHi:''}}
                                </td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.addressLine1}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}
                                    </label></td>
                                <td>{{userProfileModel.addressLine2}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.city'|translate}} <span
                                            style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.city}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.postalCode}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    {{'citizen-registration-details.address-details.present-address'|translate}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.state'|translate}} <span
                                            style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.stateNamePre}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.district'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.demographyPre!=null?userProfileModel.demographyPre.demographyNameEn:''}}
                                    -
                                    {{userProfileModel.demographyPre!=null?userProfileModel.demographyPre.demographyNameHi:''}}
                                </td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.address-line-1'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.addressLine1Pre}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.address-line-2'|translate}}
                                    </label></td>
                                <td>{{userProfileModel.addressLine2Pre}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.city'|translate}} <span
                                            style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.cityPre}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.postal-code'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.postalCodePre}}</td>
                            </tr>
                            <tr>
                                <td colspan="2">{{'citizen-profile-details.citizen-contact-details.heading'|translate}}
                                </td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.e-mail'|translate}} <span
                                            style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.email}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.alternate-mail'|translate}}
                                    </label></td>
                                <td>{{userProfileModel.altEmail}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.mobile-number'|translate}}
                                        <span style="color: red;">*</span></label></td>
                                <td>{{userProfileModel.contactNo}}</td>
                            </tr>
                            <tr>
                                <td><label>{{'citizen-profile-details.citizen-contact-details.alternate-number'|translate}}
                                    </label></td>
                                <td>{{userProfileModel.altContactNo}}</td>
                            </tr>
                        </table>
                        <div class="col-m">
                            <div class="card mat-elevation-z2 mt-3">
                                <div class="card-body">
                                    <div class="form-group form-check">
                                        <input class="form-check-input" *ngIf="detailCheckedVal" type="checkbox"
                                            id="detailCheck" name="detailCheck" (click)="detailChecked()" checked>
                                        <input class="form-check-input" *ngIf="!detailCheckedVal" type="checkbox"
                                            id="detailCheck" name="detailCheck" (click)="detailChecked()">

                                        <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1"> -->
                                        <label class="form-check-label text-danger" for="detailCheck"
                                            (click)="detailChecked();">{{'citizen-registration-note'|translate}}</label>

                                        <ng-template #popContent>{{'footer-note-demographic' | translate}}</ng-template>
                                        &nbsp;&nbsp;<i class="fa fa-info-circle" [ngbPopover]="popContent"
                                            triggers="mouseenter:mouseleave" (click)="showConsent();"></i>
                                        <div *ngIf="consentChecked">
                                            <ol>
                                                <li>{{'consent-a'|translate}}</li>
                                                <li>{{'consent-b'|translate}}</li>
                                                <li>{{'consent-c'|translate}}</li>
                                                <li>{{'consent-d'|translate}}</li>
                                                <li>{{'consent-e'|translate}}</li>
                                                <li>{{'consent-f'|translate}}</li>
                                                <li>{{'consent-g'|translate}}</li>
                                                <li>{{'consent-h'|translate}}</li>
                                                <li>{{'consent-i'|translate}}</li>
                                                <li>{{'consent-j'|translate}}</li>
                                                <li>{{'consent-k'|translate}}</li>
                                            </ol>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row w-100 align-items-center">
                            <div class="col-md-8">
                                <div class="form-group form-check d-none">
                                    <input class="form-check-input" *ngIf="detailCheckedVal" type="checkbox"
                                        id="detailCheck" name="detailCheck" (click)="detailChecked()" checked>
                                    <input class="form-check-input" *ngIf="!detailCheckedVal" type="checkbox"
                                        id="detailCheck" name="detailCheck" (click)="detailChecked()">

                                    <!-- <input type="checkbox" class="form-check-input" id="exampleCheck1"> -->
                                    <label class="form-check-label text-danger"
                                        for="detailCheck">{{'footer-note-demographic'|translate}}</label>


                                </div>
                            </div>

                            <div class="col-md-4">
                                <button *ngIf="detailCheckedVal" type="button" class="btn btn-secondary float-right"
                                    data-dismiss="modal" [disabled]="isSubmitted">
                                    {{'AcceptandClose'|translate}}</button>

                                <button *ngIf="!detailCheckedVal" class="btn btn-secondary float-right"
                                    disabled>{{'AcceptandClose'|translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>
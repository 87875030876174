
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { MineralMstBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/mineral-mst-bean';
import { CommunicationServiceService } from '../duty-service/communication-service.service';
export interface DialogData {
  filterMineralList: MineralMstBean[] ;
  popUpValueSel: string;
  savedMineralList: MineralMstBean[];
}
@Component({
  selector: 'app-mineral-selection',
  templateUrl: './mineral-selection.component.html',
  styleUrls: ['./mineral-selection.component.scss']
})
export class MineralSelectionComponent implements OnInit {
  @ViewChild("dutyMineralform" ,{static:false}) dutyMineralform: NgForm;
  lan:number=1;
  popUpValueSel:string;
  filterMineralList: MineralMstBean[] = new Array() ;
  selectedMineralList: MineralMstBean[] = new Array() ;
  savedMineralList: MineralMstBean[] = new Array() ;
  constructor(private commService: CommunicationServiceService,private  dialogRef:  MatDialogRef<MineralSelectionComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData, private translateService:
    TranslateHEService, private translate: TranslateService) { 

    
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
      this. selectedMineralList = new Array() ;
      this.filterMineralList =  data.filterMineralList;
      this.popUpValueSel  = data.popUpValueSel;
      this.savedMineralList = data.savedMineralList;
      if(this.savedMineralList != null && this.savedMineralList != undefined && this.savedMineralList.length > 0){
        this.selectedMineralList = this.savedMineralList;
        for (let mineralMstBean of this.selectedMineralList) {
          this.filterMineralList.find(item => item.id == mineralMstBean.id).checked = true;
        }
      }
      console.log("MineralSelectionComponent - filterMineralList- ",JSON.stringify(this.filterMineralList));
      console.log("this.savedMineralList::"+JSON.stringify(this.savedMineralList))
    }

  ngOnInit(): void {
  }

  onMineralChange(event: any, mineralId: number , mineralSel: MineralMstBean){
      if(event.target.checked){
       this.selectedMineralList.push(mineralSel);
      }else{
        this.selectedMineralList =  this.selectedMineralList.filter(m=>m.id!=mineralId);
      }

      console.log("selected mineral list-"+this.selectedMineralList);
  }

  submitMineral(dutyMineralForm: NgForm){
      if(this.selectedMineralList==undefined || this.selectedMineralList==null || this.selectedMineralList.length==0){
          this.dutyMineralform.invalid;
      }else{
            this.commService.emitSelectedMineral(this.selectedMineralList);
            this.closeMe();
      }
  }
  trackByFn(index:any, item:any) { 
    return index; 
}
  public  closeMe() {
   // this.dialogRef.close();
    this.dialogRef.close({selectedMineralList:this.selectedMineralList});
  }

}


    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
      <div class="d-flex justify-content-between">

    <h2>{{'complaint-request.title-status' | translate}}</h2>
    <a class="nav-link float-right  btn btn-primary" routerLink="/complaint-request" role="button">{{'complaint-request.title' | translate}}</a>
      </div>
    </div>
</div>
<div class="auth-main25">
    <div class="container">
        <lib-alertmessage></lib-alertmessage>


            <mat-card class="mat-card">
                <mat-card-title class="mat-card-title"></mat-card-title>
                  <mat-card-content class="mat-card-content">
                    <fieldset class="legend-section">
                        <legend class="sidebar__widget-title">
                            {{'complaint-request.check-status' | translate}}
                        </legend>
                         <!-- <div class="card"> -->
                    <form name="form" (ngSubmit)="complaintStatusFormHtml.form.valid && onSubmit()" #complaintStatusFormHtml="ngForm" novalidate>
                        <div class="row">
                            <div class="col-lg-4">
                                <label>{{'complaint-request.request-type' | translate}} </label>
                                <select class="form-control" name="requestType"  #requestType="ngModel" [(ngModel)]="requestTypeId"
                                        [ngClass]="{ 'is-invalid': complaintStatusFormHtml.submitted && requestType.invalid }">
                                    <option value="null" >{{'complaint-request.select-request-type' | translate}}</option>
                                    <option *ngFor="let crl of complaintReqList" value="{{crl.id}}">
                                        {{lan==1?crl.requestTypeNameHi:crl.requestTypeNameEn}}
                                    </option>
                                </select>

                                <div *ngIf="complaintStatusFormHtml.submitted && requestType.invalid" class="invalid-feedback">
                                    <div *ngIf="requestType.errors?.required">{{'required' | translate}}</div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">

                                <div class="form-group">
                                    <label>{{'complaint-request.request-id' |
                                        translate}} <span class="rstarcolor">*</span></label>
                                    <input type="text" name="registrationNo"  #registrationNo="ngModel" [(ngModel)]="registrationNumber"
                                        class="form-control" required placeholder="{{'complaint-request.enter-request-id' | translate}}"
                                        [ngClass]="{ 'is-invalid': complaintStatusFormHtml.submitted && registrationNo.invalid }">
                                    <div *ngIf="complaintStatusFormHtml.submitted && registrationNo.invalid" class="invalid-feedback">
                                        <div *ngIf="registrationNo.errors?.required">{{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">
                                <label class="w-100">&nbsp;</label>
                                <button mat-raised-button type="submit" class="btn btn-primary mb-2">{{'submit' | translate}}</button>
                                <button mat-raised-button type="button" class="btn btn-primary mb-2 ml-2" (click)="resetData()">
                                    <mat-icon>refresh</mat-icon>
                                  </button>
                            </div>

                        </div>
                        <hr/>
                    </form>
                    <div class="row">
                        <div class="col-md-4" *ngIf="requestData?.nameEn"><label>{{'admin-profile-page-data.center-personal-detail-useronboard.authorized-person-name' |
                            translate}} : </label> {{requestData.nameEn}}</div>
                        <div class="col-md-4" *ngIf="requestData?.nameHi"><label>{{'admin-profile-page-data.center-personal-detail-useronboard.authorized-person-nameHi' |
                            translate}} : </label> {{requestData.nameHi}}</div>
                        <div class="col-md-4" *ngIf="statusBean != null"><label>{{'complaint-request.current-status' | translate}} : </label> {{statusBean}}</div>
                        <div class="col-md-4" *ngIf="requestDate != null"><label>{{'complaint-request.request-date' | translate}} : </label> {{requestDate | date:"yyyy-MM-dd"}}</div>

                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="remark != null"><label>{{'complaint-request.remark' | translate}} : </label> {{remark}}</div>
                    </div>
                <!-- </div> -->
                    </fieldset>

            </mat-card-content>
            </mat-card>


    </div>





</div>

import { Injectable, Inject } from '@angular/core';

import { HttpInterceptor, HttpXsrfTokenExtractor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {

  constructor(private tokenExtractor: HttpXsrfTokenExtractor, private spinnerService: SpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let requestMethod: string = req.method;
    requestMethod = requestMethod.toLowerCase();

    if (requestMethod && (requestMethod === 'post' || requestMethod === 'delete' || requestMethod === 'put')) {
      const headerName = 'X-XSRF-TOKEN';
      let token = this.tokenExtractor.getToken() as string;
      if (token !== null && !req.headers.has(headerName)) {
        req = req.clone({ headers: req.headers.set(headerName, token) });
      }
    }
    if (req.url.includes('/request')) {
      // If it matches, bypass the interceptor and proceed with the original request
      return next.handle(req);
    }
    if (req.url.includes('/regenerate_token')) {
      return next.handle(req);
    }
    if (this.shouldIgnoreUrl(req.url)) {
      return next.handle(req); // Skip interception and pass the request through
    }

    const spinnerRef = this.spinnerService.start('loading...');
    return next.handle(req)
      .pipe(
        finalize(() => this.spinnerService.stop(spinnerRef)),
        catchError((error: HttpErrorResponse) => {
          console.log(error)
          if (error.status == 503) {
            Swal.fire({
              title: "Service Unavailable . Please try again later."
            });
          }
          if (error.status == 404) {
            Swal.fire({
              title: "Service Unavailable . Please try again later."
            });
          }
          if (error.status == 500) {
            Swal.fire({
              title: "Service error . Please try again later."
            });

          }
          if (error.status == 502) {
            Swal.fire({
              title: "Service Unavailable 502. Please try again later."
            });
          }
          if (error.status === 0) {
            Swal.fire({
              title: "Connection refused. Please try again later."
            });
          }
          return throwError(error);
        })
      );

  }

  private shouldIgnoreUrl(url: string): boolean {

    const ignoredUrls = [
      'http://127.0.0.1:11100/', 'http://127.0.0.1:11101/', 'http://127.0.0.1:11102/', 'http://127.0.0.1:11103/', 'http://127.0.0.1:11104/', 'http://127.0.0.1:11105/', 'http://127.0.0.1:11106/', 'http://127.0.0.1:11107/', 'http://127.0.0.1:11108/', 'http://127.0.0.1:11109/', 'http://127.0.0.1:11200/',
      'https://127.0.0.1:11100/', 'https://127.0.0.1:11101/', 'https://127.0.0.1:11102/', 'https://127.0.0.1:11103/', 'https://127.0.0.1:11104/', 'https://127.0.0.1:11105/', 'https://127.0.0.1:11106/', 'https://127.0.0.1:11107/', 'https://127.0.0.1:11108/', 'https://127.0.0.1:11109/', 'https://127.0.0.1:11200/'
      // Add more URLs to ignore as needed
    ];

    // Check if the provided URL should be ignored
    return ignoredUrls.some((ignoredUrl) => url.includes(ignoredUrl));
  }
}
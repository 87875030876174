import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyBAllowedCharacter]'
})
export class OnlyBAllowedCharacter {
  // Regex allows 'B' followed by one or more digits
  private regex: RegExp = new RegExp(/^B?[0-9]*$/); 
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow special keys like Backspace, Tab, Arrow keys, and Delete
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    // Allow only 'B' followed by digits
    if (!this.regex.test(next)) {
     // console.log('Invalid input:', next); // Debugging rejected input
      event.preventDefault(); // Prevent invalid input
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    // Handle pasted values and only allow 'B' followed by digits
    let clipboardData = event.clipboardData 
    let pastedText = clipboardData.getData('text');
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(pastedText);

    // Prevent anything other than 'B' followed by digits from being pasted
    if (!this.regex.test(next)) {
      event.preventDefault();
    }
  }
  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;

    // If the value does not match the regex, clear the input or correct it
    if (!this.regex.test(currentValue)) {
      input.value = ''; // Clear the invalid input or correct as per the need
     // console.log('Autocomplete or invalid input prevented:', currentValue);
    }
  }
}

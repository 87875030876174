<h2 mat-dialog-title class="Consenterdialog-title">{{'duty-calculation.sel_mineral' |translate}}</h2>

 <button mat-button mat-dialog-close class="colsebtn"> <em class="fa fa-times"></em></button>

<div mat-dialog-content class="dialog-content">

        <!-- <button  mat-raised-button (click)="closeMe()">{{'duty-calculation.close' |translate}}</button> -->



        <form #dutyMineralform="ngForm" (ngSubmit)="dutyMineralform.form.valid && submitMineral(dutyMineralform)" >

        <fieldset class="" >
          <legend class="bg-primary" *ngIf="popUpValueSel==='MAJOR'">{{'duty-calculation.maj_mineral' |translate}}</legend>
          <legend class="bg-primary" *ngIf="popUpValueSel==='MINOR'">{{'duty-calculation.min_mineral' |translate}}</legend>
          <div class="requiredColor"  *ngIf="dutyMineralform.submitted && (!selectedMineralList || dutyMineralform.invalid || selectedMineralList.length==0)"> {{ 'duty-calculation.sel_mineral_required' | translate }}</div>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="bg-light">{{'duty-calculation.SNO' | translate}}</th>
                <th class="bg-light">{{'duty-calculation.mineral_name' | translate}}</th>
                <th class="bg-light">{{'duty-calculation.select' | translate}}</th>
</tr>
            </thead>
            <tbody>
              <tr *ngFor="let field of filterMineralList;trackBy: trackByFn; index as i">
                <td>{{i+1}}</td>
                <td >
                    {{lan==0?field.mineralNameEn:field.mineralNameHi}}
                </td>
                <td >
                  <input type="checkbox" [(ngModel)]="field.checked" name="{{field.id}}" value="{{field.id}}" (change)="onMineralChange($event,field.id,field)">
                </td>
              </tr>
            </tbody>
          </table>
            </fieldset>
            <div class="pb-2 text-right">
              <button type="submit" mat-raised-button class="btn btn-primary">{{'duty-calculation.add_mineral_bttn' | translate}}</button>
            </div>

        </form>


  <!-- <div class="footer d-none d-print d-print-block fd-position">
    <div class="footer-bottom container">
      <div class="container pr">
          <div class="float-left text-center text-md-left">
              Content Provided and Maintained by Department of Registration and Stamps..
          </div>
          <div class="float-right text-center text-md-right">
              Designed & developed by <a href="https://mapit.gov.in/?page=7vG6I3NGGec1YPQRwT3nWQ%3D%3D" target="_blank" class="text-white"> Center of Excellence (CoE), MAP_IT.</a>
          </div>
          <div class="clearfix"></div>
      </div>
  </div>

  </div> -->


  </div>

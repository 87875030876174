
<div  mat-dialog-action>
    <button class="btn-colse"  mat-raised-button (click)="closeMe()">
       <!--{{'duty-calculation.close' | translate}}-->
<em class="fa fa-times"></em>
    </button>
</div>

<h3 class="modal-title">{{'duty-calculation.total_stamp_reg' | translate }}</h3>


<div class="modal-body">

<div class="row">
    <div class="col-md-12">
       <div class="table-responsive">
        <table class="w-100 table shadow1">

                <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.Deed-Catagory' | translate }}</th>
                    <td class="p-2"> {{lan==0?deedInstrumentSelected.deedTypeId.deedCategoryId.deedCategoryNameEn:deedInstrumentSelected.deedTypeId.deedCategoryId.deedCategoryNameHi}}</td>

                </tr>
                <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.Deed-Type' | translate }}</th>
                    <td class="p-2">{{lan==0?deedInstrumentSelected.deedTypeId.deedTypeNameEn:deedInstrumentSelected.deedTypeId.deedTypeNameHi}} </td>


                </tr>
                <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.inst_name' | translate }}</th>
                    <td class="p-2">{{lan==0?deedInstrumentSelected.instrumentNameEn:deedInstrumentSelected.instrumentNameHi}}</td>

                </tr>
                <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.registration' | translate }} {{'duty-calculation.opt_comp' | translate }}</th>
                    <td class="p-2">{{lan==0?deedInstrumentSelected.registrationOptionalCompulsory:deedInstrumentSelected.registrationOptionalCompulsoryHi}}</td>
                </tr>
                <tr>
                    <th scope="col" class="p-2"> {{'duty-calculation.Govt_Stamp_Duty' | translate}} <br>{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2"> {{DutyCalculationData.principalStampDuty}} {{lan==0?DutyCalculationData.principalStampDutyType:DutyCalculationData.principalStampDutyTypeHi}}</td>
                </tr>
                <!-- <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2">{{lan==0?DutyCalculationData.principalStampDutyType:DutyCalculationData.principalStampDutyTypeHi}}</td>
                </tr> -->
                <tr>
                    <th scope="col" class="p-2"> {{'duty-calculation.Municipal_Duty' | translate }} <br> {{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2"> {{municipalDutyDisplay}}</td>
                </tr>
                <tr>
                    <th scope="col" class="p-2"> {{'duty-calculation.Janpad_Duty' | translate }} <br>{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2"> {{janpadDutyDisplay}}</td>
                </tr>
                <tr>
                    <th scope="col" class="p-2"> {{'duty-calculation.Upkar_Duty' | translate }} <br>{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2"> {{upkarDutyDisplay}} </td>
                </tr>
                <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.min_stamp' | translate }} <br>{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2">{{DutyCalculationData.minStampDuty}} {{lan==0?DutyCalculationData.minStampDutyType:DutyCalculationData.minStampDutyTypeHi}}</td>
                </tr>
                <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.max_stamp' | translate }} <br>{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2"> {{DutyCalculationData.maxStampduty}} {{lan==0?DutyCalculationData.maxStampDutyType:DutyCalculationData.maxStampDutyTypeHi}}</td>
                </tr>
                <tr>
                    <th scope="col" class="p-2"> {{'duty-calculation.regFee' | translate }} <br> {{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2">{{DutyCalculationData.registrationFee}} {{lan==0?DutyCalculationData.registrationFeeType:DutyCalculationData.registrationFeeTypeHi}}</td>
                </tr>
                <!-- <tr>
                    <th scope="col" class="p-2">{{'duty-calculation.perOrFix' | translate }}</th>
                    <td class="p-2">{{lan==0?DutyCalculationData.registrationFeeType:DutyCalculationData.registrationFeeTypeHi}}</td>
                </tr> -->



        </table>

    </div>
</div>











    <!-- <div class="col-md-6 pt-3">


        <div style="display: block;">
            <canvas baseChart height="135" [data]="pieChartData" [labels]="pieChartLabels"
                [chartType]="pieChartType" [options]="pieChartOptions"
                [plugins]="pieChartPlugins" [legend]="pieChartLegend">
            </canvas>
        </div>
        <div style="display: block;" class="mt-5">
            <canvas baseChart height="135" [data]="pieChartData1" [labels]="pieChartLabels1"
                [chartType]="pieChartType1" [options]="pieChartOptions1"
                [plugins]="pieChartPlugins1" [legend]="pieChartLegend1">
            </canvas>
        </div>
    </div> -->



</div>
</div>
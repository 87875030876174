import { AfterViewInit, Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IgrsSubClausePropertyMappingBean } from 'projects/common-lib/src/lib/models/IgrsSubClausePropertyMappingBean';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { MstDemographyBeanSearch } from 'projects/common-lib/src/lib/models/MstDemographyBeanSearch';
import { MstPropertyTypeBean } from 'projects/common-lib/src/lib/models/MstPropertyTypeBean';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { PropertyValuationRequestBeanMap } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBeanMap';
import { PropertyValuationResponseBean } from 'projects/common-lib/src/lib/models/PropertyValuationResponseBean';

import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { ResidentialCumCommercialConstructionType } from 'projects/common-lib/src/lib/models/ResidentialCumCommercialConstructionType';
import { ResidentialConstructionType } from 'projects/common-lib/src/lib/models/ResidentialConstructionType';
import { IndependentBuildingTypeBean } from 'projects/common-lib/src/lib/models/IndependentBuildingTypeBean';
import { CommercialConstructionType } from 'projects/common-lib/src/lib/models/CommercialConstructionType';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PropertyValuationService } from '../property-valuation.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { DataService } from '../DataService';
import { Observable, Subscription } from 'rxjs';
import { AgriPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/agriPropertyValuationRequestBean';
import { BuildingPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/buildingPropertyValuationRequestBean';
import { MPenagarpalikaResponsModel } from 'projects/common-lib/src/lib/models/enagarpalikaModel';
import { Select, Store } from '@ngxs/store';
import { PropertyValuationState } from 'src/app/store/state/property-valuation.state';
import { UOMEnum } from 'projects/common-lib/src/lib/models/UOMEnum';
import { PropertyMasterPropetyTypeAction, PropertyMasterUomAction } from 'src/app/store/action/property-valuation.action';
import { NewShowMaketValueComponent } from 'projects/common-lib/src/lib/component/new-show-maket-value/new-show-maket-value.component';
import { NgModel } from '@angular/forms';
import { PnrdModel } from 'projects/common-lib/src/lib/models/PnrdModel';
import { ImpoundData } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { CommunicationServiceService } from '../../duty-calculation/duty-service/communication-service.service';
import { LoggerService } from 'projects/common-lib/src/lib/service/logger.service';
import { Renderer2 } from '@angular/core';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';


@Component({
  selector: 'app-building-valuation',
  templateUrl: './building-valuation.component.html',
  styleUrls: ['./building-valuation.component.scss']
 })
export class BuildingValuationComponent implements OnInit,AfterViewInit,OnChanges {
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @Input() selectDemographyData:any
  @Input() propertyValuationRequestBeanData:PropertyValuationRequestBean
  @Input() subClauseMasterBeanData:IgrsSubClausePropertyMappingBean[]
  @Input() mpenagarpalikaResponsModel:MPenagarpalikaResponsModel
  @Input() pnrdDataModel:PnrdModel
  @Input() fromDutyModule:boolean = false;
  @Input() fromEregModule:boolean = false;
  @Input() fromCMSModule:boolean = false;
  @Input() fromCheckerModule: boolean;
  @Input() propertyTypeData:MstPropertyTypeBean[]
  @Input() builderShareData:number;
  @Input() isImpoundAddProperty:boolean;
  @Input() guildeValuationData:any
  @ViewChild('TotalArea') totalAreaModelRef: NgModel;
  @ViewChild('unit') unitModelRef: NgModel;
  @ViewChild('resi') residentialPlotAreaRef:NgModel;


  @Select(PropertyValuationState.getPropertyTypeList) mstPropertyTypeBean$: Observable<MstPropertyTypeBean[]>
  @Select(PropertyValuationState.getUomList) uomList$: Observable<UOMEnum[]>
  @Select(PropertyValuationState.getPropertTypeLoaded) PropertTypeLoaded$:Observable<boolean>
  @Select(PropertyValuationState.getUomLoaded) uomListloaded$: Observable<boolean>

 
  constructor(public matDialogRef: MatDialogRef<NewShowMaketValueComponent>,public matDialogRefBuilding: MatDialogRef<BuildingValuationComponent>
    ,private activeRoute: ActivatedRoute,private dataService: DataService 
    ,private router: Router,private pvService: PropertyValuationService, 
    private ngxService: NgxUiLoaderService, 
    private translateService: TranslateHEService, 
    private translate: TranslateService, 
    private renderer: Renderer2,
    public utilityService:UtilityService,
    public dialog: MatDialog,private commServ : CommunicationServiceService,
    private store:Store,
    private logger:LoggerService,) {

      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
   
  }
  isContinueButton:boolean=false
  mstDemographyBeanList: MstDemographyBean[] = new Array();
  demographyBeanSearch: MstDemographyBeanSearch[] = new Array();
  MstDemographyBeanListHistory: MstDemographyBean[] = new Array();
  subClauseMasterBean: IgrsSubClausePropertyMappingBean[] = new Array();
 
  subclassIdKhasra: IgrsSubClausePropertyMappingBean[] = new Array();
  mstPropertyTypeBean: MstPropertyTypeBean[] = new Array();
  propertyValuationResponseBean: PropertyValuationResponseBean = new PropertyValuationResponseBean();
  uomList: any[] = new Array();
  selectedMstDemographyBean: MstDemographyBean;
  propertyValuationRequestBean: PropertyValuationRequestBean = new PropertyValuationRequestBean();
  agriPropertyValuationRequestBean:AgriPropertyValuationRequestBean=new AgriPropertyValuationRequestBean();
  propertyValuationRequestBeanMap: PropertyValuationRequestBeanMap = new PropertyValuationRequestBeanMap();
  buildingPropertyValuationRequestBean:BuildingPropertyValuationRequestBean=new BuildingPropertyValuationRequestBean()
  floorList: any = new Array();
  allowedValues:any= new Array();
  resetSubcl: boolean = true;
  totalAreaMsg: boolean = false;
 // showMarketValueBtn: boolean = false;
  divertedErrMsg: boolean = false;
  conveyanceTypeMsg: boolean = false;
  conveyanceTypeAreaMsg:boolean=false
  commonAreaMsg: boolean = false;
  commonFloorAreaMsg:boolean=false
  selectedFloors: any = new Array();
  usageType: string;
  lan: number=1;
  mainAreaType: number = 2;
  yearList: any = new Array();
  buildingGuildeValueData:any
  agriVal:number
  agriValLessCheck:number
  errorObj:{}
  selectFloorValid:boolean
  isResMsg:boolean
  isCommMsg:boolean
  isIndusMsg:boolean
  isEduMsg:boolean
  isHlthMsg:boolean
  isOthMsg:boolean
  isAgriAreaMsg:boolean
  isMaxValue:any
  
  ngOnInit(): void {
    this.buildingGuildeValueData=this.guildeValuationData
    this.getAllUOM();
    if(this.propertyValuationRequestBeanData!=undefined){
      this.propertyValuationRequestBean=this.propertyValuationRequestBeanData
    }
    //subclause
    if( this.subClauseMasterBeanData!=undefined){
    this.subClauseMasterBean=this.subClauseMasterBeanData.filter(s=>{
      //hide subClause by default
      if(s.id==15){
        s.hidden=true
      }
      else if(s.id==16){
        s.hidden=true
      }
      return s.propertyTypeId==2})
    }
    
    if(this.propertyTypeData!=undefined){
      this.mstPropertyTypeBean=this.propertyTypeData
    }
    
    this.getAllPropertyType();
    this.loadFloorList();
    this.loadyearList();
    //this.getAllSubClause();
    this.propertyValuationRequestBean.builderShare = this.builderShareData;

    // this.dataService.getChangeMainAreaType().subscribe(res=>{
    //     console.log("data reach on building case ",res)      
    // })

  }
  getAllUOM() {
    this.uomListloaded$.subscribe(res=>{
      this.logger.log(res)
      if(!res){
        this.store.dispatch(new PropertyMasterUomAction())
      }
      })
    this.uomList$.subscribe(res=>{
      this.uomList=res
    })
    
  }
 
  ngAfterViewInit(){
    
   this.dataService.getAgriculturalWithConstruction().subscribe((data:any)=>{
      if(data!=null &&Object.keys(data).length!=0){
        console.log("agriculture data on building",data)
      this.agriPropertyValuationRequestBean=data.agriculturalWithConstruction
      this.agriVal=this.agriPropertyValuationRequestBean.totalArea
      this.agriPropertyValuationRequestBean.isConstOnAgriLand=true 
      this.propertyValuationRequestBean=data.agriculturalWithConstruction.agriculturalWithConstruction
      
     this.propertyValuationRequestBean.unit="SQMT"
     this.isContinueButton=data.isContinueButton
     this.mstPropertyTypeBean?.filter(propertyTypeId => {
      if (propertyTypeId.id == 2) {
        this.propertyValuationRequestBean.propertyType = propertyTypeId
      }
      });
     
      }

    })
   
   
    if(this.mpenagarpalikaResponsModel!=null&&this.mpenagarpalikaResponsModel.subClause.length==0){
      this.subClauseMasterBeanData?.filter((d) => {
          d.checked = false;
          d.disabled= false;
      })
    }

    this.subClauseMasterBeanData?.filter((d) => {
      this.mpenagarpalikaResponsModel?.subClause?.filter((s:any) => {
        if (d.id === s.id && d.propertyTypeId==2) {
         
          d.checked = s.checked;
          d.disabled= s.disabled;
          this.addSubclauseEvent(true,d)
        }
        else if(d.id != s.id&&d.propertyTypeId==2){
         // this.logger.log("print subclause else",d)
          //d.checked = false;
          //d.disabled= false;
        }
      });
    });
    if(!this.isContinueButton){
    this.totalAreaModelRef.valueChanges.subscribe(res=>{
      console.log("totalArea ref",res)
      
      this.propertyValuationRequestBean.commercialPlotArea=null
      this.propertyValuationRequestBean.residentialPlotArea=null
      this.propertyValuationRequestBean.othersPlotArea=null
      this.propertyValuationRequestBean.healthPlotArea=null
      this.propertyValuationRequestBean.eduPlotArea=null
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea=null
    })
  }

    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 0, // Minimum number of fraction digits
      maximumFractionDigits: 10, 
      useGrouping: false // Maximum number of fraction digits
      };
    this.unitModelRef.valueChanges.subscribe(res=>{
     
      if(res=='SQMT'&& this.isContinueButton){
        const totalArea=Number(new Intl.NumberFormat('en-US', options).format(this.agriVal*10000)).toFixed(2)
        this.propertyValuationRequestBean.totalArea = totalArea.replace(/\.00$/, '');
        this.propertyValuationRequestBean.partialTransactArea = totalArea.replace(/\.00$/, '');
        this.isMaxValue=Number(new Intl.NumberFormat('en-US', options).format(this.agriVal*10000)).toFixed(2)
        }
      else if(res=='SQFT'&&  this.isContinueButton){
        const totalArea=Number(new Intl.NumberFormat('en-US', options).format(this.agriVal*107639.104)).toFixed(2)
        this.propertyValuationRequestBean.totalArea=totalArea.replace(/\.00$/, '');
        this.propertyValuationRequestBean.partialTransactArea = totalArea.replace(/\.00$/, '');
          this.isMaxValue=Number(new Intl.NumberFormat('en-US', options).format(this.agriVal*107639.104)).toFixed(2)
        }
    })

    this.residentialPlotAreaRef.valueChanges.subscribe(plotRes=>{
      this.propertyValuationRequestBean.independentBuildBean.length=0
      this.logger.log(plotRes)
    })
  }
  loadyearList() {
    this.yearList = [];
    let year: number = (new Date()).getFullYear();
    year = year + 1;
    for (let i = 0; i < 300; i++) {
      year = year - 1;
      this.yearList.push(year);
    }
  }

  getAllSubClause() {
    this.pvService.getAllSubClauses().subscribe(dmodata => {
      this.subClauseMasterBean = dmodata.responseData;

    });
    this.subClauseMasterBean=this.subClauseMasterBean?.filter(s=>{
      //hide subClause by default
      if(s.id==15){
        s.hidden=true
      }
      else if(s.id==16){
        s.hidden=true
      }
      return s.propertyTypeId==2})
  }

  // getAllUOM() {
  //   this.pvService.getAllUOM().subscribe(dmodata => {
  //     // this.MstDemographyBeanList=dmodata.responseData;
  //     this.uomList = dmodata.responseData;;

  //   });
  // }

  getAllPropertyType() {
    this.PropertTypeLoaded$.subscribe(res=>{
      if(!res){
        this.store.dispatch(new PropertyMasterPropetyTypeAction())
      }
      this.mstPropertyTypeBean$.subscribe(res=>{
        this.mstPropertyTypeBean=res
      })
    })
      this.mstPropertyTypeBean.filter(pdata => { // indrajeet
        if (pdata.id == 2) {
          this.propertyValuationRequestBean.propertyType = pdata
        }
      });
  
  }
  convertedUOM(uom:any){
    return this.utilityService.convertedUOM(uom,this.lan)
  }

  buildingSubChangeEvent(value?:any) {
    if(value=='Multistorey'){
      this.propertyValuationRequestBean.buildingType='Residential'
      this.multiSoryFloorIndex=6
    }
    if(value=='IndependentFloor'){
      this.multiSoryFloorIndex=5
    }
    this.propertyValuationRequestBean=new PropertyValuationRequestBean();
    this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '';
    this.propertyValuationRequestBean.unit='SQMT'
    this.propertyValuationRequestBean.buildingType=value=='Multistorey'?'Residential':''
    this.propertyValuationRequestBean.buildingSubType=value
    this.loadFloorList();
    this.mstPropertyTypeBean.filter(pdata=>{
      if (pdata.id == 2) {
        this.propertyValuationRequestBean.propertyType = pdata
      }
    })
  
  }
  //usage on Mutlistory
  multiSoryFloorIndex=6
  changeFloor(event:any) {
   this.logger.log(event)
    if(event.target.value=='Residential'){
      this.multiSoryFloorIndex=6
      this.loadFloorList();
    }if(event.target.value=='Commercial'){
      this.multiSoryFloorIndex=9
      this.loadFloorList();
    }
  }
  changeFloorMultiStory(value:any){
    if(value=='Residential'){
      this.multiSoryFloorIndex=6
     
    }
  }

  isBlank(obj: any): boolean {
     return JSON.stringify(obj) === '{}'
  }
  superStructEvent(){
      this.propertyValuationRequestBean.totalArea = '0';
      this.propertyValuationRequestBean.residentialPlotArea = '0';
      this.propertyValuationRequestBean.commercialPlotArea = '0';
      this.propertyValuationRequestBean.industrialPlotArea = '0';
      this.propertyValuationRequestBean.healthPlotArea = '0';
      this.propertyValuationRequestBean.eduPlotArea = '0';
      this.propertyValuationRequestBean.othersPlotArea = '0';
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '0';
  }
 
  housingBoardEvent(event:any) {
    console.log(event)
    if (!this.propertyValuationRequestBean.isHousingBoard||!this.propertyValuationRequestBean.isSuperSturct) {
   
      this.propertyValuationRequestBean.totalArea = '0';
      this.propertyValuationRequestBean.residentialPlotArea = '0';
      this.propertyValuationRequestBean.commercialPlotArea = '0';
      this.propertyValuationRequestBean.industrialPlotArea = '0';
      this.propertyValuationRequestBean.healthPlotArea = '0';
      this.propertyValuationRequestBean.eduPlotArea = '0';
      this.propertyValuationRequestBean.othersPlotArea = '0';
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '0';
    } else {
      this.propertyValuationRequestBean.totalArea = '';
      this.propertyValuationRequestBean.residentialPlotArea = '';
      this.propertyValuationRequestBean.commercialPlotArea = '';
      this.propertyValuationRequestBean.industrialPlotArea = ''; 
      this.propertyValuationRequestBean.healthPlotArea = '';
      this.propertyValuationRequestBean.eduPlotArea = '';
      this.propertyValuationRequestBean.othersPlotArea = '';
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '';
    }
  }
   onInputChange(event:number) {
    
    if(this.isContinueButton){
      this.propertyValuationRequestBean.partialTransactArea=this.propertyValuationRequestBean.totalArea
    if (event < this.isMaxValue)
    {
      this.isAgriAreaMsg=false
    } else if (event > this.isMaxValue) {
     this.propertyValuationRequestBean.totalArea=''
     this.propertyValuationRequestBean.partialTransactArea= ''
        this.isAgriAreaMsg=true
    } else {
      this.isAgriAreaMsg=false
    }
  }
  }
 
  inputChangeEventValidation():boolean {
    this.isAgriAreaMsg=false
    if(this.agriPropertyValuationRequestBean.isConstOnAgriLand){
      if(this.isMaxValue<Number(this.propertyValuationRequestBean.totalArea)){
        this.propertyValuationRequestBean.totalArea=''
        this.isAgriAreaMsg=true
        return false
      }
    }
    if (this.propertyValuationRequestBean.totalArea != undefined && this.propertyValuationRequestBean.totalArea != '') {
      this.totalAreaMsg = false;
      let totalCount: number = 0;
   
       if (this.propertyValuationRequestBean.propertyType.id == 2) { // building
      
        if (this.propertyValuationRequestBean.isConstOnAgriLand == true) {
          if (Number(this.agriPropertyValuationRequestBean.totalArea) >= Number(this.propertyValuationRequestBean.totalArea)) {
          } else {
            this.alertmessage.errorAlertMessage('building-with-argi')
            this.propertyValuationRequestBean.totalArea = '';
          }
        }
        this.logger.log(this.propertyValuationRequestBean.buildingSubType)
        if (this.propertyValuationRequestBean.buildingSubType == "Open Terrace") {
          if (this.propertyValuationRequestBean.conveyanceType == ""||this.propertyValuationRequestBean.conveyanceType==null) {
            this.conveyanceTypeMsg = true;
            return false
          } else {
            this.conveyanceTypeMsg = false;
          }
        } else if (this.propertyValuationRequestBean.buildingSubType == "IndependentFloor") {
          if(this.propertyValuationRequestBean.constrnTime==null && this.propertyValuationRequestBean.floorType==null){
           return false
          }
          if(this.propertyValuationRequestBean.transactOpenTerrace){
            if((this.propertyValuationRequestBean.conveyanceType == ""||this.propertyValuationRequestBean.conveyanceType==null)){
              this.conveyanceTypeMsg = true;
              return false
             }
             else{
              this.conveyanceTypeMsg = false;
             }
             if(this.propertyValuationRequestBean.conveyanceArea==null||this.propertyValuationRequestBean.conveyanceArea==undefined){
              this.conveyanceTypeAreaMsg=true
              return false
             }
             else{
              this.conveyanceTypeAreaMsg=false
             }
          }
        } else if (this.propertyValuationRequestBean.buildingSubType == "Multistorey") {
          if (Number(this.propertyValuationRequestBean.totalArea) >= Number(this.propertyValuationRequestBean.buitupArea)) {
            let substValue= (Number(this.propertyValuationRequestBean.totalArea) - Number(this.propertyValuationRequestBean.buitupArea));
            this.propertyValuationRequestBean.commonArea =Number(substValue.toFixed(2));
           } else {
             this.propertyValuationRequestBean.buitupArea = undefined;
             this.propertyValuationRequestBean.commonArea = Number(this.propertyValuationRequestBean.totalArea);
           }
          let totalCount = 0;
          if (this.propertyValuationRequestBean.buitupArea != undefined) {
            totalCount = totalCount + Number(this.propertyValuationRequestBean.buitupArea);
          }
          if (this.propertyValuationRequestBean.commonArea != undefined && this.propertyValuationRequestBean.commonArea!=0) {
            totalCount = totalCount + Number(this.propertyValuationRequestBean.commonArea);
            if (Number(totalCount) == Number(this.propertyValuationRequestBean.totalArea)) {
              this.commonAreaMsg = false;
            } else {
              this.commonAreaMsg = true;
              return false
            }
          } else {
            this.commonAreaMsg = true;
            return false;
          }
          if (totalCount == Number(this.propertyValuationRequestBean.totalArea)) {
          
          } else {
            return false
          }
          //if BuildUpArea And Common Area filled than check floor
          if(!this.propertyValuationRequestBean.buitupArea){
            return false
          }
          else{
            if(this.propertyValuationRequestBean.floorType==null&&this.propertyValuationRequestBean.floorType==undefined){
              return false
            }
          }
          if(this.propertyValuationRequestBean.transactOpenTerrace){
            if((this.propertyValuationRequestBean.conveyanceType == ""||this.propertyValuationRequestBean.conveyanceType==null)){
              this.conveyanceTypeMsg = true;
              return false
             }
             else{
              this.conveyanceTypeMsg = false;
             }
             if(this.propertyValuationRequestBean.conveyanceArea==null||this.propertyValuationRequestBean.conveyanceArea==undefined){
              this.conveyanceTypeAreaMsg=true
              return false
             }
             else{
              this.conveyanceTypeAreaMsg=false
             }
          }
        } else if (this.propertyValuationRequestBean.buildingSubType == "Independent Building") {
          if(this.propertyValuationRequestBean.isResidensial
            ||this.propertyValuationRequestBean.isCommercial||this.propertyValuationRequestBean.isIndustrial
            ||this.propertyValuationRequestBean.isHealth||this.propertyValuationRequestBean.isEduArea||this.propertyValuationRequestBean.isOthers){
              //if Checked Box true then Check Area
          if(this.propertyValuationRequestBean.residentialPlotArea||
            this.propertyValuationRequestBean.commercialPlotArea||this.propertyValuationRequestBean.industrialPlotArea
            ||this.propertyValuationRequestBean.healthPlotArea||this.propertyValuationRequestBean.eduPlotArea||this.propertyValuationRequestBean.isOthers){

          }
          else{
            if(this.propertyValuationRequestBean.isResidensial){
             this.isResMsg=this.propertyValuationRequestBean.isResidensial
            }
            if(this.propertyValuationRequestBean.isCommercial){
              this.isCommMsg=this.propertyValuationRequestBean.isCommercial
            }
            if(this.propertyValuationRequestBean.isIndustrial){
              this.isIndusMsg=this.propertyValuationRequestBean.isIndustrial
            }
            if(this.propertyValuationRequestBean.isHealth){
              this.isHlthMsg=this.propertyValuationRequestBean.isHealth
            }
            if(this.propertyValuationRequestBean.isEduArea){
              this.isEduMsg=this.propertyValuationRequestBean.isEduArea
            }
            if(this.propertyValuationRequestBean.isOthers){
              this.isOthMsg=this.propertyValuationRequestBean.isOthers
            }
            return false
          }
          }else{
            if(this.propertyValuationRequestBean.isResidentialCumCommercial){
              return true
            }
              return false
          }
            if(this.selectedFloors==null||this.selectedFloors==undefined||this.selectedFloors==''){
              this.selectFloorValid=true
            }
        
          if(this.propertyValuationRequestBean.independentBuildBean.length==0){
             return false
           }
            if(this.propertyValuationRequestBean.totalArea!=Number(this.propertyValuationRequestBean.residentialPlotArea)
            +Number(this.propertyValuationRequestBean.industrialPlotArea)
            +Number(this.propertyValuationRequestBean.healthPlotArea)
            +Number(this.propertyValuationRequestBean.eduPlotArea)
            +Number(this.propertyValuationRequestBean.commercialPlotArea)
            +Number(this.propertyValuationRequestBean.othersPlotArea)){
              this.logger.log(Number(this.propertyValuationRequestBean.residentialPlotArea)
              +Number(this.propertyValuationRequestBean.industrialPlotArea)
              +Number(this.propertyValuationRequestBean.healthPlotArea)
              +Number(this.propertyValuationRequestBean.eduPlotArea)
              +Number(this.propertyValuationRequestBean.commercialPlotArea)
              +Number(this.propertyValuationRequestBean.othersPlotArea))
              this.alertmessage.errorAlertMessage('sum_of_sub_area');
              return false
            }
          }//Independent Building
          }// Property Type 2
       return true
    } else {
      this.totalAreaMsg = true;
      this.propertyValuationRequestBean.commonArea=null
      this.propertyValuationRequestBean.buitupArea=null
      return false
    }
  }
  

  hideSubClause(event:any){
        this.subClauseMasterBean.filter(s=>{
          if(s.id==15){
            s.hidden=event
            s.checked=event
          }
          else if(s.id==16){
            s.hidden=event
            s.checked=event
          }
        })
   }
validateConveyanceArea() {
  if (Number(this.propertyValuationRequestBean.conveyanceArea) > Number(this.propertyValuationRequestBean.totalArea)) {
      this.propertyValuationRequestBean.conveyanceArea=''
  }
}

checkBoxEvent(event:any,type?:any){
  const indicesToRemove = [0, 1, 2, 3,4,5,6,7,8];
  indicesToRemove.sort((a, b) => b - a);

  if(event==true && type=='residensial'){
   this.propertyValuationRequestBean.isResidensial=true
  }
  else if(event==false&&type=='residensial'){
    this.propertyValuationRequestBean.residentialPlotArea = '';
   
   if(this.propertyValuationRequestBean.independentBuildBean.length>0){
    indicesToRemove.forEach(index => {
     if (index < this.propertyValuationRequestBean.independentBuildBean.length) {
       delete this.propertyValuationRequestBean.independentBuildBean[index].resiConstType;
     }
   });
  }
  }
  if(event==true && type=='commercial'){
    this.propertyValuationRequestBean.isCommercial=true
    //both Subclause 25 Year and 50 year only commercial True
    this.hideSubClause(false)
  }
  else if(event==false&&type=='commercial'){
    this.propertyValuationRequestBean.commercialPlotArea = '';
    this.hideSubClause(true)
     if(this.propertyValuationRequestBean.independentBuildBean.length>0){
      indicesToRemove.forEach(index => {
       if (index < this.propertyValuationRequestBean.independentBuildBean.length) {
         delete this.propertyValuationRequestBean.independentBuildBean[index].commConstType;
       }
     });
    }
  }
  if(event==true && type=='industrial'){
    this.propertyValuationRequestBean.isIndustrial=true
  
  }
  else if(event==false&&type=='industrial'){

    this.propertyValuationRequestBean.industrialPlotArea = '';
    this.propertyValuationRequestBean.isSuperSturct=false
    this.propertyValuationRequestBean.isIndustrialDevAuth=false
    if(this.propertyValuationRequestBean.independentBuildBean.length>0){
       indicesToRemove.forEach(index => {
        if (index < this.propertyValuationRequestBean.independentBuildBean.length) {
          delete this.propertyValuationRequestBean.independentBuildBean[index].industrialConstructionType;
        }
      });
     }
  }
  if(event==true && type=='health'){
    this.propertyValuationRequestBean.isHealth=true
  }
  else if(event==false&&type=='health'){
    this.propertyValuationRequestBean.healthPlotArea = '';
    if(this.propertyValuationRequestBean.independentBuildBean.length>0){
      indicesToRemove.forEach(index => {
       if (index < this.propertyValuationRequestBean.independentBuildBean.length) {
         delete this.propertyValuationRequestBean.independentBuildBean[index].healthAreaConstructionType;
       }
     });
    }
  }

  if(event==true && type=='education'){
    this.propertyValuationRequestBean.isEduArea=true
  }
  else if(event==false&&type=='education'){
    this.propertyValuationRequestBean.eduPlotArea = '';
    if(this.propertyValuationRequestBean.independentBuildBean.length>0){
      indicesToRemove.forEach(index => {
       if (index < this.propertyValuationRequestBean.independentBuildBean.length) {
         delete this.propertyValuationRequestBean.independentBuildBean[index].eduAreaConstructionType;
       }
     });
    }
  }
  if(event==true && type=='other'){
    this.propertyValuationRequestBean.isOthers=true
  }
  else if(event==false&&type=='other'){
    this.propertyValuationRequestBean.othersPlotArea = '';
    if(this.propertyValuationRequestBean.independentBuildBean.length>0){
      indicesToRemove.forEach(index => {
       if (index < this.propertyValuationRequestBean.independentBuildBean.length) {
         delete this.propertyValuationRequestBean.independentBuildBean[index].otherAreaConstructionType;
       }
     });
    }
  }
}

 

  userTypeEvent(event: any) {
    
    if (this.propertyValuationRequestBean.isResidentialCumCommercial) {
      this.propertyValuationRequestBean.residentialPlotArea = '';
      this.propertyValuationRequestBean.commercialPlotArea = '';
      this.propertyValuationRequestBean.industrialPlotArea = '';
      this.propertyValuationRequestBean.healthPlotArea = '';
      this.propertyValuationRequestBean.eduPlotArea = '';
      this.propertyValuationRequestBean.othersPlotArea = '';
      this.selectedFloors = [];
      this.propertyValuationRequestBean.isResidensial = false;
      this.propertyValuationRequestBean.isCommercial = false;
      this.propertyValuationRequestBean.isIndustrial = false;
      this.propertyValuationRequestBean.isHealth = false;
      this.propertyValuationRequestBean.isEduArea = false;
      this.propertyValuationRequestBean.isOthers = false;
      this.propertyValuationRequestBean.isSuperSturct=false
      this.propertyValuationRequestBean.isIndustrialDevAuth=false
        
    } else {
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '';
    }
   
    this.propertyValuationRequestBean.buildingAreaType = event==true?"RCumCom":"no";

  }
  //usage only independent Building
  setIndBuildingValue(type: any, event: any) {
    this.selectedFloors = [];
   // this.showMarketValueBtn = true;
    let ttl = 0;
    if (type == 'ResidentialCumCommercial') {
      if (this.propertyValuationRequestBean.residentialCumCommercialPlotArea) {
        ttl = Number(this.propertyValuationRequestBean.residentialCumCommercialPlotArea);
      }
    } else {
      if (this.propertyValuationRequestBean.residentialPlotArea) {
        ttl = Number(ttl) + Number(this.propertyValuationRequestBean.residentialPlotArea);
      }
      if (this.propertyValuationRequestBean.commercialPlotArea) {
        ttl = Number(ttl) + Number(this.propertyValuationRequestBean.commercialPlotArea);
      }
      if (this.propertyValuationRequestBean.industrialPlotArea) {
        ttl = Number(ttl) + Number(this.propertyValuationRequestBean.industrialPlotArea);
      }
      if (this.propertyValuationRequestBean.healthPlotArea) {
        ttl = Number(ttl) + Number(this.propertyValuationRequestBean.healthPlotArea);
      }
      if (this.propertyValuationRequestBean.eduPlotArea) {
        ttl = Number(ttl) + Number(this.propertyValuationRequestBean.eduPlotArea);
      }
      if (this.propertyValuationRequestBean.othersPlotArea) {
        ttl = Number(ttl) + Number(this.propertyValuationRequestBean.othersPlotArea);
      }
    }
    if (type == 'ResidentialCumCommercial') {
      this.propertyValuationRequestBean.residentialCumCommercialPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isResidentialCumCommercialttl')).value = '';
      }
    } else if (type == 'residential') {
     
      this.propertyValuationRequestBean.residentialPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isResidensialttl')).value = '';
      }
    } else if (type == 'commercial') {
      this.propertyValuationRequestBean.commercialPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isCommercialttl')).value = '';
      }
    } else if (type == 'industrial') {
      this.propertyValuationRequestBean.industrialPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isIndustrialttl')).value = '';
      }
    } else if (type == 'health') {
      this.propertyValuationRequestBean.healthPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isHealthttl')).value = '';
      }
    } else if (type == 'edu') {
      this.propertyValuationRequestBean.eduPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isEduAreattl')).value = '';
      }
    } else if (type == 'other') {
      this.propertyValuationRequestBean.othersPlotArea = event.target.value;
      if (!this.showErrorMsg(ttl)) {
        (<HTMLInputElement>document.getElementById('isOthersttl')).value = '';
      }
    }
   
  }
  showErrorMsg(sumof: number): boolean {
    let res = true;
    if (Number(this.propertyValuationRequestBean.totalArea) >= Number(sumof)) {
    } else {
      this.alertmessage.errorAlertMessage('sum_of_sub_area');
      res = false;
    }
    return res;
  }

 
  loadFloorList() {
    this.logger.log("isResidentialCumCommercial",this.propertyValuationRequestBean.isResidentialCumCommercial
    )
    if (this.propertyValuationRequestBean.buildingSubType == 'Multistorey' && this.propertyValuationRequestBean.buildingType == 'Commercial') {
      this.floorList = [{ id: 1, "nameEn": "Basement", "nameHi": "तलघर" },
      { id: 2, "nameEn": "Mezzanine Floor", "nameHi": "मेज़नाइन फ्लोर" },
      { id: 3, "nameEn": "Ground Floor", "nameHi": "तल मंज़िल" },
      { id: 4, "nameEn": "First Floor", "nameHi": "पहली मंजिल" },
      { id: 5, "nameEn": "Second Floor", "nameHi": "दूसरी मंजिल" },
      { id: 6, "nameEn": "Third Floor", "nameHi": "तीसरी मंजिल" },
      { id: 7, "nameEn": "Fourth Floor and above", "nameHi": "चौथी मंजिल और ऊपर" },
      // { id: 8, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 9, "nameEn": "Lower Ground Floor", "nameHi": "लोअर ग्राउंड फ्लोर" },
      { id: 10, "nameEn": "Upper ground floor", "nameHi": "अपर ग्राउंड फ्लोर" }
      ];
    } 
    else if(this.propertyValuationRequestBean.buildingSubType == 'Multistorey'){
      this.floorList = [{ id: 1, "nameEn": "Basement", "nameHi": "तलघर" },
      // { id: 2, "nameEn": "Mezzanine Floor", "nameHi": "मेज़नाइन फ्लोर" },
      { id: 3, "nameEn": "Ground Floor", "nameHi": "तल मंज़िल" },
      { id: 4, "nameEn": "First Floor", "nameHi": "पहली मंजिल" },
      { id: 5, "nameEn": "Second Floor", "nameHi": "दूसरी मंजिल" },
      // { id: 6, "nameEn": "Third Floor", "nameHi": "तीसरी मंजिल" },
      // { id: 7, "nameEn": "Fourth Floor and above", "nameHi": "चौथी मंजिल और ऊपर" },
      { id: 8, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 9, "nameEn": "Lower Ground Floor", "nameHi": "लोअर ग्राउंड फ्लोर" },]
    }

    
    else {
      this.floorList = [{ id: 1, "nameEn": "Basement", "nameHi": "तलघर" },
      // { id: 2, "nameEn": "Mezzanine Floor", "nameHi": "मेज़नाइन फ्लोर" },
      { id: 3, "nameEn": "Ground Floor", "nameHi": "तल मंज़िल" },
      { id: 4, "nameEn": "First Floor", "nameHi": "पहली मंजिल" },
      { id: 5, "nameEn": "Second Floor", "nameHi": "दूसरी मंजिल" },
      // { id: 6, "nameEn": "Third Floor", "nameHi": "तीसरी मंजिल" },
      // { id: 7, "nameEn": "Fourth Floor and above", "nameHi": "चौथी मंजिल और ऊपर" },
      { id: 8, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 9, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 10, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 11, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 12, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 13, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 14, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 15, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 16, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 17, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 18, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 19, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 20, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 21, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 22, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 23, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 24, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
      { id: 25, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" }
      //{ id: 9, "nameEn": "Lower Ground Floor", "nameHi": "लोअर ग्राउंड फ्लोर" },
        // { id: 10, "nameEn": "Upper ground floor", "nameHi": "अपर ग्राउंड फ्लोर" }
      ];
      
    }
   
  }
  floorIndex:number=5
  addFloorInCurrentList(event?:any){

  if(event.length>=4){
  this.floorIndex++
   }
    
  }
  trackByFn(i: number) { 
    return i
  }


 
  onSelectDropdownClosed(event: any) {
    this.logger.log("floor index  remove",event)
   if(event.index==0){
     this.selectedFloors=[]
     this.floorIndex=event.index+5
   }else{
     this.floorIndex=event.index<4?5:event.index+1
   }
   
    this.propertyValuationRequestBean.independentBuildBean.length=0
  }

  
//usage Only independent Building
  floorOrderValidation:boolean=false
  floorChangeEvent(event: any) {
    this.floorOrderValidation=false
    var ordeArray = [1,3,4,5,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25];//independent building
    var index=1
    var ordeArray1 = [1,2,3,4,5,6,7,9,10];//Multistorey ,commerial
    this.selectedFloors = [];
    if (event != undefined && event.length > 0) {

    if(this.propertyValuationRequestBean.buildingSubType=='Independent Building'){
      for (let i = 0; i < event.length; i++) {

        if(ordeArray[index]==Number(event[i])){
          this.selectedFloors.push(event[i]);
          index++
        }
        else if(Number(event[i])==1){
          this.selectedFloors.push(event[i]);
         }
         else{
          this.floorOrderValidation=true
          this.alertmessage.errorAlertMessage('sel-order-wise')
         }
      }
    }
    else{
      for (let i = 0; i < event.length; i++) {
        if(ordeArray1[index]==Number(event[i])){
         this.selectedFloors.push(event[i]);
         index++
       }
       else if(event[i]==1){
        this.selectedFloors.push(event[i]);
       }

      }
    }
    }
    else{
      this.propertyValuationRequestBean.independentBuildBean.length=0
    }
     if(this.selectedFloors!=null){
      this.selectFloorValid=false
     }
  }

  showFloorName(ftype: any) {
    let res = "";
    this.floorList.filter((flrdata: { id: any; nameEn: any; nameHi: any; }) => {
      if (flrdata.id == ftype) {
        res = this.lan == 0 ? flrdata.nameEn : flrdata.nameHi;
      }
    })
    
    return res;
  }
 
 //using Only Independing Building
 changeAddFloorUsaseTypeEvent(event: any, flr: any, usagetype: any, usagetypeVlaue: any, inputType?: string) {
  let changeInputType = '';
  if (usagetype === 'isResidentialCumCommercial') {
    let resiCumCommType = this.propertyValuationRequestBeanMap.floorResidentialCumCommercialList.get(flr);
    if (resiCumCommType != undefined) {
    } else {
      resiCumCommType = new ResidentialCumCommercialConstructionType();
    }
    if (inputType != undefined && inputType == 'subtypeinput') {
      (<HTMLInputElement>document.getElementById('rbc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('rcc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('kacchaKabelu' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('tinShade' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('shopArea' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('officeArea' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('godownArea' + flr)).value = '';
      resiCumCommType = new ResidentialCumCommercialConstructionType();
    }
 
    resiCumCommType.totalTypeArea = this.propertyValuationRequestBean.residentialCumCommercialPlotArea;
     let rCumTotalAr=0
    if (usagetypeVlaue == 'rcc') {
      changeInputType = 'rcc' + flr;
     
      resiCumCommType.rccArea = event.target.value;
    }
    if (usagetypeVlaue == 'rbc') {
      changeInputType = 'rbc' + flr;
      resiCumCommType.rbcArea = event.target.value;
    }
    if (usagetypeVlaue == 'kacchaKabelu') {
      changeInputType = 'kacchaKabelu' + flr;
      resiCumCommType.kacchaKabeluArea = event.target.value;
    }
    if (usagetypeVlaue == 'tinShade') {
      changeInputType = 'tinShade' + flr;
      resiCumCommType.tinShadeArea = event.target.value;
    }
    if (usagetypeVlaue == 'shopArea') {
      changeInputType = 'shopArea' + flr;
      resiCumCommType.shopArea = event.target.value;
    }
    if (usagetypeVlaue == 'officeArea') {
      changeInputType = 'officeArea' + flr;
      resiCumCommType.officeArea = event.target.value;
    }
    if (usagetypeVlaue == 'godownArea') {
      changeInputType = 'godownArea' + flr;
      resiCumCommType.godownArea = event.target.value;
    }
    let tcount = 0;
    if (resiCumCommType.godownArea) {
      tcount = Number(tcount) + Number(resiCumCommType.godownArea);
    }
    if (resiCumCommType.kacchaKabeluArea) {
      tcount = Number(tcount) + Number(resiCumCommType.kacchaKabeluArea);
    }
    if (resiCumCommType.officeArea) {
      tcount = Number(tcount) + Number(resiCumCommType.officeArea);
    }
    if (resiCumCommType.rbcArea) {
      tcount = Number(tcount) + Number(resiCumCommType.rbcArea);
    }
    if (resiCumCommType.rccArea) {
      tcount = Number(tcount) + Number(resiCumCommType.rccArea);
    }
    if (resiCumCommType.shopArea) {
      tcount = Number(tcount) + Number(resiCumCommType.shopArea);
    }
    if (resiCumCommType.tinShadeArea) {
      tcount = Number(tcount) + Number(resiCumCommType.tinShadeArea);
    }
    
    this.checkFloorValidation(resiCumCommType, tcount, flr, usagetype, changeInputType,usagetypeVlaue);
    if(this.propertyValuationRequestBean.floorResidentialCumCommercialList!=undefined){
    this.propertyValuationRequestBean.floorResidentialCumCommercialList.forEach((element, index) => {
      if (element.floorType == flr)
       this.propertyValuationRequestBean.floorResidentialCumCommercialList.splice(index, 1);
    });
   }
   else{
    this.propertyValuationRequestBean.floorResidentialCumCommercialList=new Array()
   }
    this.propertyValuationRequestBeanMap.floorResidentialCumCommercialList.set(flr, resiCumCommType);
    let rsOjb = { 'floorId': flr, resiCumCommType }
  
    if (Array.isArray(this.propertyValuationRequestBean.floorResidentialCumCommercialList)) {
      this.propertyValuationRequestBean.floorResidentialCumCommercialList.push(rsOjb);
  } else {
      throw new Error("floorResidentialCumCommercialList is not an array");
  }
    let arrayUniqueByKey: any = [...new Map(this.propertyValuationRequestBean.floorResidentialCumCommercialList.map((item: any) =>
      [item.floorId, item])).values()];

   let lastElement= this.propertyValuationRequestBean.independentBuildBean.pop();
    this.propertyValuationRequestBean.independentBuildBean = arrayUniqueByKey;
  }
  //Residensial Com no
  else if (usagetype == 'residensial') {
    let resiConstType = this.propertyValuationRequestBeanMap.floorTypeResidensialList.get(flr);
    if (resiConstType != undefined) {
    } else {
      resiConstType = new ResidentialConstructionType();
    }
    if (inputType != undefined && inputType == 'subtypeinput') {
     
      (<HTMLInputElement>document.getElementById('residensialrbc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('residensialrcc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('residensialkacchaKabelu' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('residensialtinShade' + flr)).value = '';
      resiConstType = new ResidentialConstructionType();
    }
  
    resiConstType.totalTypeArea = this.propertyValuationRequestBean.residentialPlotArea;
   
    if (usagetypeVlaue == 'rcc') {
      changeInputType = 'residensialrcc' + flr;
      resiConstType.rccArea = event.target.value;
    }
    if (usagetypeVlaue == 'rbc') {
      changeInputType = 'residensialrbc' + flr;
      resiConstType.rbcArea = event.target.value;
    }
    if (usagetypeVlaue == 'kacchaKabelu') {
      changeInputType = 'residensialkacchaKabelu' + flr;
      resiConstType.kacchaKabeluArea = event.target.value;
    }
    if (usagetypeVlaue == 'tinShade') {
      changeInputType = 'residensialtinShade' + flr;
      resiConstType.tinShadeArea = event.target.value;
    }
    let tcount = 0;
    if (resiConstType.kacchaKabeluArea) {
      tcount = Number(tcount) + Number(resiConstType.kacchaKabeluArea);
    }
    if (resiConstType.rbcArea) {
      tcount = Number(tcount) + Number(resiConstType.rbcArea);
    }
    if (resiConstType.rccArea) {
      tcount = Number(tcount) + Number(resiConstType.rccArea);
    }
    if (resiConstType.tinShadeArea) {
      tcount = Number(tcount) + Number(resiConstType.tinShadeArea);
    }
   
    this.propertyValuationRequestBean?.floorTypeResidensialList?.forEach((element, index) => {
      if (element.floorType == flr) this.propertyValuationRequestBean.floorTypeResidensialList.splice(index, 1);
    });
    
    this.propertyValuationRequestBeanMap.floorTypeResidensialList.set(flr, resiConstType);
    this.checkFloorValidation(resiConstType, tcount, flr, usagetype, changeInputType,usagetypeVlaue)
    let independentBuildingTypeBean = new IndependentBuildingTypeBean();
    independentBuildingTypeBean.floorId = flr;
    independentBuildingTypeBean.resiConstType = resiConstType;
    this.makeCustomIndependentBuildingTypeBean('residensial', independentBuildingTypeBean);
    
  } else if (usagetype == 'commercial') {
    let commercialConstructionType = this.propertyValuationRequestBeanMap.floorTypeCommercialList.get(flr);
    if (commercialConstructionType != undefined) {
    } else {
      commercialConstructionType = new CommercialConstructionType();
    }
    if (inputType != undefined && inputType == 'subtypeinput') {
      (<HTMLInputElement>document.getElementById('commercialshopArea' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('commercialofficeArea' + flr)).value = '';
    
      (<HTMLInputElement>document.getElementById('commercialgodownArea' + flr)).value = '';
      commercialConstructionType = new CommercialConstructionType();
    }

    commercialConstructionType.totalTypeArea = this.propertyValuationRequestBean.commercialPlotArea;
    
    if (usagetypeVlaue == 'shopArea') {
      changeInputType = 'commercialshopArea' + flr;
      commercialConstructionType.shopArea = event.target.value;
    }
    if (usagetypeVlaue == 'officeArea') {
      changeInputType = 'commercialofficeArea' + flr;
      commercialConstructionType.officeArea = event.target.value;
    }
    if (usagetypeVlaue == 'godownArea') {
      changeInputType = 'commercialgodownArea' + flr;
      commercialConstructionType.godownArea = event.target.value;
    }
    let tcount = 0;
    if (commercialConstructionType.godownArea) {
      tcount = Number(tcount) + Number(commercialConstructionType.godownArea);
    }
    if (commercialConstructionType.officeArea) {
      tcount = Number(tcount) + Number(commercialConstructionType.officeArea);
    }
    if (commercialConstructionType.shopArea) {
      tcount = Number(tcount) + Number(commercialConstructionType.shopArea);
    }
    this.propertyValuationRequestBeanMap.floorTypeCommercialList.set(flr, commercialConstructionType);
    //  this.propertyValuationRequestBean.floorTypeCommercialList.pop(rsOjb);
    this.propertyValuationRequestBean?.floorTypeCommercialList?.forEach((element, index) => {
      if (element.floorType == flr) this.propertyValuationRequestBean.floorTypeCommercialList.splice(index, 1);
    });
    let independentBuildingTypeBean = new IndependentBuildingTypeBean();
    independentBuildingTypeBean.floorId = flr;
    independentBuildingTypeBean.commConstType = commercialConstructionType;
    this.makeCustomIndependentBuildingTypeBean('commercial', independentBuildingTypeBean);
    this.checkFloorValidation(commercialConstructionType, tcount, flr, usagetype, changeInputType,usagetypeVlaue);
  } else if (usagetype == 'industrial') {
    let industrialConstructionType = this.propertyValuationRequestBeanMap.floorTypeIndustrialList.get(flr);
    if (industrialConstructionType != undefined) {
    } else {
      industrialConstructionType = new ResidentialConstructionType();
    }
   
    industrialConstructionType.totalTypeArea = this.propertyValuationRequestBean.industrialPlotArea;
    // }
    if (inputType != undefined && inputType == 'subtypeinput') {
   
      (<HTMLInputElement>document.getElementById('industrialrbc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('industrialrcc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('industrialkacchaKabelu' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('industrialtinShade' + flr)).value = '';
      industrialConstructionType = new ResidentialConstructionType();
    }
    if (usagetypeVlaue == 'rcc') {
      changeInputType = 'industrialrcc' + flr;
      industrialConstructionType.rccArea = event.target.value;
    }
    if (usagetypeVlaue == 'rbc') {
      changeInputType = 'industrialrbc' + flr;
      industrialConstructionType.rbcArea = event.target.value;
    }
    if (usagetypeVlaue == 'kacchaKabelu') {
      changeInputType = 'industrialkacchaKabelu' + flr;
      industrialConstructionType.kacchaKabeluArea = event.target.value;
    }
    if (usagetypeVlaue == 'tinShade') {
      changeInputType = 'industrialtinShade' + flr;
      industrialConstructionType.tinShadeArea = event.target.value;
    }
    let tcount = 0;
    if (industrialConstructionType.kacchaKabeluArea) {
      tcount = Number(tcount) + Number(industrialConstructionType.kacchaKabeluArea);
    }
    if (industrialConstructionType.rbcArea) {
      tcount = Number(tcount) + Number(industrialConstructionType.rbcArea);
    }
    if (industrialConstructionType.rccArea) {
      tcount = Number(tcount) + Number(industrialConstructionType.rccArea);
    }
    if (industrialConstructionType.tinShadeArea) {
      tcount = Number(tcount) + Number(industrialConstructionType.tinShadeArea);
    }
    this.propertyValuationRequestBeanMap.floorTypeIndustrialList.set(flr, industrialConstructionType);
    this.propertyValuationRequestBean?.floorTypeIndustrialList?.forEach((element, index) => {
      if (element.floorType == flr) this.propertyValuationRequestBean.floorTypeIndustrialList.splice(index, 1);
    });
    this.checkFloorValidation(industrialConstructionType, tcount, flr, usagetype, changeInputType,usagetypeVlaue);
    let independentBuildingTypeBean = new IndependentBuildingTypeBean();
    independentBuildingTypeBean.floorId = flr;
    independentBuildingTypeBean.industrialConstructionType = industrialConstructionType;
    this.makeCustomIndependentBuildingTypeBean('industrial', independentBuildingTypeBean);
  } else if (usagetype == 'health') {
    let healthConstructionType = this.propertyValuationRequestBeanMap.floorTypeHealthList.get(flr);
    if (healthConstructionType != undefined) {
    } else {
      healthConstructionType = new ResidentialConstructionType();
    }
    // if (usagetypeVlaue == 'healthttl') {
    // healthConstructionType.totalTypeArea = event.target.value;
    healthConstructionType.totalTypeArea = this.propertyValuationRequestBean.healthPlotArea;
    // changeInputType='health'+flr;
    // }
    if (inputType != undefined && inputType == 'subtypeinput') {
      // document.getElementById('rbc'+flr).value('');
      (<HTMLInputElement>document.getElementById('healthrbc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('healthrcc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('healthkacchaKabelu' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('healthtinShade' + flr)).value = '';
      healthConstructionType = new ResidentialConstructionType();
    }
    if (usagetypeVlaue == 'rcc') {
      changeInputType = 'healthrcc' + flr;
      healthConstructionType.rccArea = event.target.value;
    }
    if (usagetypeVlaue == 'rbc') {
      changeInputType = 'healthrbc' + flr;
      healthConstructionType.rbcArea = event.target.value;
    }
    if (usagetypeVlaue == 'kacchaKabelu') {
      changeInputType = 'healthkacchaKabelu' + flr;
      healthConstructionType.kacchaKabeluArea = event.target.value;
    }
    if (usagetypeVlaue == 'tinShade') {
      changeInputType = 'healthtinShade' + flr;
      healthConstructionType.tinShadeArea = event.target.value;
    }
    let tcount = 0;
    if (healthConstructionType.kacchaKabeluArea) {
      tcount = Number(tcount) + Number(healthConstructionType.kacchaKabeluArea);
    }
    if (healthConstructionType.rbcArea) {
      tcount = Number(tcount) + Number(healthConstructionType.rbcArea);
    }
    if (healthConstructionType.rccArea) {
      tcount = Number(tcount) + Number(healthConstructionType.rccArea);
    }
    if (healthConstructionType.tinShadeArea) {
      tcount = Number(tcount) + Number(healthConstructionType.tinShadeArea);
    }
    this.propertyValuationRequestBeanMap.floorTypeHealthList.set(flr, healthConstructionType);
    this.propertyValuationRequestBean?.floorTypeHealthList?.forEach((element, index) => {
      if (element.floorType == flr) this.propertyValuationRequestBean.floorTypeHealthList.splice(index, 1);
    });
    this.checkFloorValidation(healthConstructionType, tcount, flr, usagetype, changeInputType,usagetypeVlaue);
    //this.checkFloorValidation(healthConstructionType, tcount, flr, usagetype, changeInputType);
    let independentBuildingTypeBean = new IndependentBuildingTypeBean();
    independentBuildingTypeBean.floorId = flr;
    independentBuildingTypeBean.healthAreaConstructionType = healthConstructionType;
    this.makeCustomIndependentBuildingTypeBean('health', independentBuildingTypeBean);
  } else if (usagetype == 'edu') {
    let eduConstructionType = this.propertyValuationRequestBeanMap.floorTypeEducationList.get(flr);
    if (eduConstructionType != undefined) {
    } else {
      eduConstructionType = new ResidentialConstructionType();
    }
    // if (usagetypeVlaue =='eduttl') {
    // eduConstructionType.totalTypeArea = event.target.value;
    eduConstructionType.totalTypeArea = this.propertyValuationRequestBean.eduPlotArea;
    // changeInputType='edu'+flr;
    // }
    if (inputType != undefined && inputType == 'subtypeinput') {
      // document.getElementById('rbc'+flr).value('');
      (<HTMLInputElement>document.getElementById('edurbc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('edurcc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('edukacchaKabelu' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('edutinShade' + flr)).value = '';
      eduConstructionType = new ResidentialConstructionType();
    }
    if (usagetypeVlaue == 'rcc') {
      changeInputType = 'edurcc' + flr;

      eduConstructionType.rccArea = event.target.value;
    }
    if (usagetypeVlaue == 'rbc') {
      changeInputType = 'edurbc' + flr;
      eduConstructionType.rbcArea = event.target.value;
    }
    if (usagetypeVlaue == 'kacchaKabelu') {
      changeInputType = 'edukacchaKabelu' + flr;
      eduConstructionType.kacchaKabeluArea = event.target.value;
    }
    if (usagetypeVlaue == 'tinShade') {
      changeInputType = 'edutinShade' + flr;
      eduConstructionType.tinShadeArea = event.target.value;
    }
    let tcount = 0;
    if (eduConstructionType.kacchaKabeluArea) {
      tcount = Number(tcount) + Number(eduConstructionType.kacchaKabeluArea);
    }
    if (eduConstructionType.rbcArea) {
      tcount = Number(tcount) + Number(eduConstructionType.rbcArea);
    }
    if (eduConstructionType.rccArea) {
      tcount = Number(tcount) + Number(eduConstructionType.rccArea);
    }
    if (eduConstructionType.tinShadeArea) {
      tcount = Number(tcount) + Number(eduConstructionType.tinShadeArea);
    }
    this.propertyValuationRequestBeanMap.floorTypeEducationList.set(flr, eduConstructionType);
    this.propertyValuationRequestBean?.floorTypeEducationList?.forEach((element, index) => {
      if (element.floorType == flr) this.propertyValuationRequestBean.floorTypeEducationList.splice(index, 1);
    });
    this.checkFloorValidation(eduConstructionType, tcount, flr, usagetype, changeInputType,usagetypeVlaue);
    let independentBuildingTypeBean = new IndependentBuildingTypeBean();
    independentBuildingTypeBean.floorId = flr;
    independentBuildingTypeBean.eduAreaConstructionType = eduConstructionType;
    this.makeCustomIndependentBuildingTypeBean('edu', independentBuildingTypeBean);
  } else if (usagetype == 'other') {
    let otherConstructionType = this.propertyValuationRequestBeanMap.floorTypeOtherList.get(flr);
    if (otherConstructionType != undefined) {
    } else {
      otherConstructionType = new ResidentialConstructionType();
    }
    // if (usagetypeVlaue =='otherttl') {
    otherConstructionType.totalTypeArea = this.propertyValuationRequestBean.othersPlotArea;
    // }
    if (inputType != undefined && inputType == 'subtypeinput') {
      // document.getElementById('rbc'+flr).value('');
      (<HTMLInputElement>document.getElementById('otherrbc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('otherrcc' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('otherkacchaKabelu' + flr)).value = '';
      (<HTMLInputElement>document.getElementById('othertinShade' + flr)).value = '';
      otherConstructionType = new ResidentialConstructionType();
    }
    if (usagetypeVlaue == 'rcc') {
      changeInputType = 'otherrcc' + flr;
      otherConstructionType.rccArea = event.target.value;
    }
    if (usagetypeVlaue == 'rbc') {
      changeInputType = 'otherrbc' + flr;
      otherConstructionType.rbcArea = event.target.value;
    }
    if (usagetypeVlaue == 'kacchaKabelu') {
      changeInputType = 'otherkacchaKabelu' + flr;
      otherConstructionType.kacchaKabeluArea = event.target.value;
    }
    if (usagetypeVlaue == 'tinShade') {
      changeInputType = 'othertinShade' + flr;
      otherConstructionType.tinShadeArea = event.target.value;
    }
    let tcount = 0;
    if (otherConstructionType.kacchaKabeluArea) {
      tcount = Number(tcount) + Number(otherConstructionType.kacchaKabeluArea);
    }
    if (otherConstructionType.rbcArea) {
      tcount = Number(tcount) + Number(otherConstructionType.rbcArea);
    }
    if (otherConstructionType.rccArea) {
      tcount = Number(tcount) + Number(otherConstructionType.rccArea);
    }
    if (otherConstructionType.tinShadeArea) {
      tcount = Number(tcount) + Number(otherConstructionType.tinShadeArea);
    }
    this.propertyValuationRequestBeanMap.floorTypeOtherList.set(flr, otherConstructionType);
    this.propertyValuationRequestBean?.floorTypeOtherList?.forEach((element, index) => {
      if (element.floorType == flr) this.propertyValuationRequestBean.floorTypeOtherList.splice(index, 1);
    });
    this.checkFloorValidation(otherConstructionType, tcount, flr, usagetype, changeInputType,usagetypeVlaue);
    let independentBuildingTypeBean = new IndependentBuildingTypeBean();
    independentBuildingTypeBean.floorId = flr;
    independentBuildingTypeBean.otherAreaConstructionType = otherConstructionType;
    this.makeCustomIndependentBuildingTypeBean('other', independentBuildingTypeBean);
  }
}



  checkFloorValidation(ConstructionType: any, tcount: number, flr: number, type: string, changeInputType: string,usagetypeVlaue?:string) {
   
    if (!this.propertyValuationRequestBean.isHousingBoard&&!this.propertyValuationRequestBean.isSuperSturct) {
      if (Number(this.propertyValuationRequestBean.totalArea) >= Number(ConstructionType.totalTypeArea)) {
        // tcount != 0 &&
        if (Number(ConstructionType.totalTypeArea) >= tcount) {
          //this.showMarketValueBtn = false;
        } else {
          if (changeInputType != '') {
            if (usagetypeVlaue == 'rcc') {
              ConstructionType.rccArea =''
            }
            if (usagetypeVlaue == 'rbc') {
              ConstructionType.rbcArea = '';
            }
            if (usagetypeVlaue == 'kacchaKabelu') {
              ConstructionType.kacchaKabeluArea ='';
            }
            if (usagetypeVlaue == 'tinShade') {
              ConstructionType.tinShadeArea = ''
            }
            if (usagetypeVlaue == 'shopArea') {
              ConstructionType.shopArea = '';
            }
            if (usagetypeVlaue == 'officeArea') {
              ConstructionType.officeArea ='';
            }
            if (usagetypeVlaue == 'godownArea') {
              ConstructionType.godownArea = ''
            }
            (<HTMLInputElement>document.getElementById(changeInputType)).value = '';
            this.showFloorErrorMst(type, "subtype", flr);
          }
        //  this.showMarketValueBtn = false;
        }
      } else {
        if (changeInputType != '') {
        //  (<HTMLInputElement>document.getElementById(changeInputType)).value = '';
        }
        this.showFloorErrorMst(type, "prnt", flr);
      
      }
    } 
    
   
  }
  makeCustomIndependentBuildingTypeBean(type: string, independentBuildingTypeBean: IndependentBuildingTypeBean) {
    let arrayUniqueByKey: any = [...new Map(this.propertyValuationRequestBean.independentBuildBean.map((item: any) =>
      [item.floorId, item])).values()];
    this.propertyValuationRequestBean.independentBuildBean.pop();
    if (arrayUniqueByKey.length == 0) {
      if (type == 'residensial') {
        let indbulflow: IndependentBuildingTypeBean = new IndependentBuildingTypeBean();
        indbulflow.resiConstType = independentBuildingTypeBean.resiConstType;
        indbulflow.floorId = independentBuildingTypeBean.floorId;
        this.propertyValuationRequestBean.independentBuildBean.push(indbulflow);
      } else if (type == 'commercial') {
        let indbulflow: IndependentBuildingTypeBean = new IndependentBuildingTypeBean();
        indbulflow.commConstType = independentBuildingTypeBean.commConstType;
        indbulflow.floorId = independentBuildingTypeBean.floorId;
        this.propertyValuationRequestBean.independentBuildBean.push(indbulflow);
      } else if (type == 'industrial') {
        let indbulflow: IndependentBuildingTypeBean = new IndependentBuildingTypeBean();
        indbulflow.industrialConstructionType = independentBuildingTypeBean.industrialConstructionType;
        indbulflow.floorId = independentBuildingTypeBean.floorId;
        this.propertyValuationRequestBean.independentBuildBean.push(indbulflow);
      } else if (type == 'health') {
        let indbulflow: IndependentBuildingTypeBean = new IndependentBuildingTypeBean();
        indbulflow.healthAreaConstructionType = independentBuildingTypeBean.healthAreaConstructionType;
        indbulflow.floorId = independentBuildingTypeBean.floorId;
        this.propertyValuationRequestBean.independentBuildBean.push(indbulflow);
      } else if (type == 'edu') {
        let indbulflow: IndependentBuildingTypeBean = new IndependentBuildingTypeBean();
        indbulflow.eduAreaConstructionType = independentBuildingTypeBean.eduAreaConstructionType;
        indbulflow.floorId = independentBuildingTypeBean.floorId;
        this.propertyValuationRequestBean.independentBuildBean.push(indbulflow);
      } else if (type == 'other') {
        let indbulflow: IndependentBuildingTypeBean = new IndependentBuildingTypeBean();
        indbulflow.otherAreaConstructionType = independentBuildingTypeBean.otherAreaConstructionType;
        indbulflow.floorId = independentBuildingTypeBean.floorId;
        this.propertyValuationRequestBean.independentBuildBean.push(indbulflow);
      }
    } else {
      let notMatch: boolean = true;
      arrayUniqueByKey.filter((indObj: any) => {
        if (indObj.floorId == independentBuildingTypeBean.floorId) {
          notMatch = false;
          if (type == 'residensial') {
            indObj.resiConstType = independentBuildingTypeBean.resiConstType;
            indObj.floorId = independentBuildingTypeBean.floorId;
            this.propertyValuationRequestBean.independentBuildBean.push(indObj);
          } else if (type == 'commercial') {
            indObj.commConstType = independentBuildingTypeBean.commConstType;
            indObj.floorId = independentBuildingTypeBean.floorId;
            this.propertyValuationRequestBean.independentBuildBean.push(indObj);
          } else if (type == 'industrial') {
            indObj.industrialConstructionType = independentBuildingTypeBean.industrialConstructionType;
            indObj.floorId = independentBuildingTypeBean.floorId;
            this.propertyValuationRequestBean.independentBuildBean.push(indObj);
          } else if (type == 'health') {
            indObj.healthAreaConstructionType = independentBuildingTypeBean.healthAreaConstructionType;
            indObj.floorId = independentBuildingTypeBean.floorId;
            this.propertyValuationRequestBean.independentBuildBean.push(indObj);
          } else if (type == 'edu') {
            indObj.eduAreaConstructionType = independentBuildingTypeBean.eduAreaConstructionType;
            indObj.floorId = independentBuildingTypeBean.floorId;
            this.propertyValuationRequestBean.independentBuildBean.push(indObj);
          } else if (type == 'other') {
            indObj.otherAreaConstructionType = independentBuildingTypeBean.otherAreaConstructionType;
            indObj.floorId = independentBuildingTypeBean.floorId;
            this.propertyValuationRequestBean.independentBuildBean.push(indObj);
          }
        } else {
          if (notMatch === true) {
            this.propertyValuationRequestBean.independentBuildBean.push(independentBuildingTypeBean);
          }
          this.propertyValuationRequestBean.independentBuildBean.push(indObj);
        }
      });
      let arrayUniqueByKey2: any = [...new Map(this.propertyValuationRequestBean.independentBuildBean.map((item: any) =>
        [item.floorId, item])).values()];
      this.propertyValuationRequestBean.independentBuildBean.pop();
      this.propertyValuationRequestBean.independentBuildBean = arrayUniqueByKey2;
    }
  }
  showFloorErrorMst(type: string, subtype: string, flr: number) {
   
    if (type == 'isResidentialCumCommercial') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-isResidentialCumCommercial' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-isResidentialCumCommercial' + flr);
      }
    } else if (type == 'residensial') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-residensial' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-residensial' + flr);
      }
    } else if (type == 'commercial') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-commercial' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-commercial' + flr);
      }
    } else if (type == 'industrial') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-industrial' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-industrial' + flr);
      }
    } else if (type == 'health') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-health' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-health' + flr);
      }
    } else if (type == 'edu') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-edu' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-edu' + flr);
      }
    } else if (type == 'other') {
      if (subtype == 'subtype') {
        this.alertmessage.errorAlertMessage('sum_of_sub_area-other' + flr);
      } else {
        this.alertmessage.errorAlertMessage('sum_of_total_area-other' + flr);
      }
    }
  }
  
 

  addSubclauseEvent(event: any, slrow?: any) {
    
    if (event== true) {
      this.propertyValuationRequestBean.subClause.push(slrow);
    } else {
      this.propertyValuationRequestBean?.subClause?.forEach((value, index) => {
        if (value.id == slrow.id) {
          this.propertyValuationRequestBean.subClause.splice(index, 1);
        } 
      });
    }
  }

  onlyOneSubclauseSelectedAtATime(sbc:any) {

    let x = <HTMLInputElement>document.getElementById("subclause15");
    let y = <HTMLInputElement>document.getElementById("subclause16");
    if (sbc.id==15) {
      y.checked = false;
      this.propertyValuationRequestBean?.subClause?.forEach((value, index) => {
        if (value.id == 16) {
          this.propertyValuationRequestBean.subClause.splice(index, 1);
        } 
      });
    }
    else if(sbc.id==16) {
      x.checked = false;
      this.propertyValuationRequestBean?.subClause?.forEach((value, index) => {
        if (value.id == 15) {
          this.propertyValuationRequestBean.subClause.splice(index, 1);
        } 
      });
    }
  }

  constrnIsValid:boolean=false
  showMarketValueEvent() :boolean{
   
   
    if(!this.inputChangeEventValidation()){
      this.errorObj= { responseMessage:'Sorry, this form is invalid. Please provide correct information and try again',
          responseMessageEn:'Sorry, this form is invalid. Please provide correct information and try again',
          responseMessageHi:'माफ़ करें, यह फ़ॉर्म अमान्य है। कृपया सही जानकारी प्रदान करें और पुनः प्रयास करें'}
          this.alertmessage.errorAlertMessage(this.errorObj,'error',2000)
     return false
    }
   
  if(this.propertyValuationRequestBean.independentBuildBean!=null){
    this.propertyValuationRequestBean.independentBuildBean.forEach(index=>{
      if(index.floorId>=9){
        index.floorId=8
      }
    })
  }

   if(this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct){
    if(this.propertyValuationRequestBean.independentBuildBean.length==0){
      this.alertmessage.errorAlertMessage({responseMessage:'No details available for input',responseMessageEn:'No details available for input',responseMessageHi:'इनपुट के लिए कोई विवरण उपलब्ध नहीं है'})
      return false
    }
   }

    if(((this.propertyValuationRequestBean.buildingSubType=='IndependentFloor')
    || ((this.propertyValuationRequestBean.buildingSubType=='Independent Building'
     && this.propertyValuationRequestBean.isResidensial==true)
     &&(this.propertyValuationRequestBean.isCommercial!=true
     &&this.propertyValuationRequestBean.isIndustrial!=true
     &&this.propertyValuationRequestBean.isHealth!=true
     &&this.propertyValuationRequestBean.isEduArea!=true
     &&this.propertyValuationRequestBean.isOthers!=true))
     || (this.propertyValuationRequestBean.buildingSubType=='Multistorey'&&this.propertyValuationRequestBean.buildingType=='Residential'))){
      if(this.propertyValuationRequestBean.constrnTime==null)
      {
         this.constrnIsValid=true
         return false
       }
    }


   if (this.agriPropertyValuationRequestBean.isConstOnAgriLand == true) {
     this.propertyValuationRequestBean.demography=this.agriPropertyValuationRequestBean.demography
     this.dataService.setDataBuildingDataAgri(this.agriPropertyValuationRequestBean)
  } else {
    this.buildingPropertyValuationRequestBean.demography=this.selectDemographyData
    this.propertyValuationRequestBean.demography=this.selectDemographyData
   // this.ngxService.startLoader('builing-01');
    this.pvService.propValuation(this.propertyValuationRequestBean).subscribe(data => {
     
      if (data.responseStatus == "true") {
        this.propertyValuationResponseBean = data.responseData;
       // this.ngxService.stopLoader('builing-01');
        this.openDialog(this.propertyValuationResponseBean);
      } else {
       // this.ngxService.stopLoader('builing-01');
        this.alertmessage.errorAlertMessage(data);
      }
    }
     );
  }
    
 return true
  }
  
  openDialog(data: any) {

    this.propertyValuationRequestBean.isImpoundAddProperty = this.isImpoundAddProperty
    this.propertyValuationRequestBean.builderShare = this.builderShareData

    let obj = { searchPropType: this.propertyValuationRequestBean, formPropModule:true, propData: data , fromDutyModule:this.fromDutyModule,
       propertyOwner:this.propertyValuationRequestBean.propertyOwner,buildingGuildeValueData:this.buildingGuildeValueData,fromCMSModule:this.fromCMSModule,fromCheckerModule:this.fromCheckerModule};
    this.matDialogRef=this.dialog.open(NewShowMaketValueComponent, {
      data: obj,
    });

    this.matDialogRef
    .afterClosed()
    .pipe()
    .subscribe((result=>{
      this.logger.log(" return from SMV in BUILDING :-",result);
     if(result.isContinued ){
     
      // this.router.navigateByUrl('#/property-valuation', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['#/property-valuation']);
      // });
      this.reloadComponent(true,'property-valuation')
    }else if(result.fromCMSClose || result.fromCheckerClose ){
      this.emitDataToImpoundModule(result);
      this.matDialogRefBuilding.close();
    }
    else{
      //this.agriPropertyValuationRequestBean=this.propertyValuationRequestBean
     // this.propertyValuationRequestBean=this.propertyValuationResponseBean.agriculturalWithConstruction
     // this.ngxService.stopLoader('builing-01'); 
      this.matDialogRef.close()
    }

 }))
  }

  emitDataToImpoundModule(res:any){
    let impoundData:ImpoundData;
              impoundData = res.impoundData;   
      this.commServ.emitImpoundData(impoundData);         
  }


  allFormReset() {
    
    this.propertyValuationRequestBean = new PropertyValuationRequestBean();
    this.buildingSubChangeEvent();
    this.resetMainOption();

    this.mstPropertyTypeBean.filter(pdata => {
    // this.logger.log(pdata)
      if (pdata.id == this.mainAreaType) {
        this.clickAreaTypeEvent(pdata)
        this.propertyValuationRequestBean.propertyType = pdata
      }
    });

  }
  resetMainOption() {
    this.propertyValuationRequestBean.isConstOnAgriLand = false;
   // this.allFormReset();
  }
  clickAreaTypeEvent(mainAreaType: any) {
    this.propertyValuationRequestBean.propertyType = mainAreaType;
    this.propertyValuationRequestBean.subClause = [];
    //this.usageType = '';
    this.propertyValuationRequestBean.totalArea = '';
    this.propertyValuationRequestBean.isHousingBoard = false;
    this.propertyValuationRequestBean.transactOpenTerrace = false;
    this.propertyValuationRequestBean.liftFacility = false;
    this.propertyValuationRequestBean.conveyanceArea = '';
    this.propertyValuationRequestBean.conveyanceType = '';
    this.propertyValuationRequestBean.residentialPlotArea = '';
    this.propertyValuationRequestBean.commercialPlotArea = '';
    this.propertyValuationRequestBean.industrialPlotArea = '';
    this.propertyValuationRequestBean.healthPlotArea = '';
    this.propertyValuationRequestBean.eduPlotArea = '';
    this.propertyValuationRequestBean.othersPlotArea = '';
    this.propertyValuationRequestBean.residentialCumCommercialPlotArea = '';
    this.propertyValuationRequestBean.isConstOnAgriLand = false;
    this.propertyValuationRequestBean.isSuperSturct=false
    this.propertyValuationRequestBean.isIndustrialDevAuth=false
  }

  ngOnChanges(changes:SimpleChanges){

    if (changes['selectDemographyData']?.currentValue!= changes['selectDemographyData']?.previousValue) {
      this.propertyValuationRequestBean.demography=changes['selectDemographyData']?.currentValue
      this.propertyValuationRequestBean.unit = 'SQMT';

   } else {

   }


  }

  ngOnDestroy(){
    this.logger.log("ng desstroy call")
  }






  // continueWithAgriculture(){
  //   this.logger.log(this.applyValidatioBeforeValuation())
  //    if(this.applyValidatioBeforeValuation())
  //     this.matDialogRef.close({propertyValuationRequestBean:this.propertyValuationRequestBean})
     
  // }


applyValidatioBeforeValuation():boolean{
  if(this.propertyValuationRequestBean.isHousingBoard||this.propertyValuationRequestBean.isSuperSturct){
    this.logger.log(this.propertyValuationRequestBean.independentBuildBean.length)
    if(this.propertyValuationRequestBean.independentBuildBean.length==0){
      this.alertmessage.errorAlertMessage({responseMessage:'No details available for input',responseMessageEn:'No details available for input',responseMessageHi:'इनपुट के लिए कोई विवरण उपलब्ध नहीं है'})
      return false
    }
   }


    if(((this.propertyValuationRequestBean.buildingSubType=='IndependentFloor')
    || ((this.propertyValuationRequestBean.buildingSubType=='Independent Building'
     && this.propertyValuationRequestBean.isResidensial==true)
     &&(this.propertyValuationRequestBean.isCommercial!=true
     &&this.propertyValuationRequestBean.isIndustrial!=true
     &&this.propertyValuationRequestBean.isHealth!=true
     &&this.propertyValuationRequestBean.isEduArea!=true
     &&this.propertyValuationRequestBean.isOthers!=true))
     || (this.propertyValuationRequestBean.buildingSubType=='Multistorey'&&this.propertyValuationRequestBean.buildingType=='Residential'))){

      if(this.propertyValuationRequestBean.constrnTime==null)
      {
         this.alertmessage.errorAlertMessage('conts-time');
      return false
    
       }
    }
  
    if (this.propertyValuationRequestBean.totalArea != undefined && this.propertyValuationRequestBean.totalArea != '') {
      this.totalAreaMsg = false;
    } else {
      this.totalAreaMsg = true;
      //this.showMarketValueBtn=true
      return false
     
    }
    return true
}

isPartialTransact:boolean=false
onCheckDataPush(event: any) {
  this.logger.log("onCheckDataPush",event.target.checked)
  this.isPartialTransact=event.target.checked
  // this.isPartialTransact?
  //   this.propertyValuationRequestBean.overAllArea=this.propertyValuationRequestBean.totalArea
  // :this.propertyValuationRequestBean.totalArea=this.propertyValuationRequestBean.overAllArea
  if(this.isPartialTransact){
    if(this.propertyValuationRequestBean.overAllArea==undefined&&this.propertyValuationRequestBean.totalArea!='0'){
      this.propertyValuationRequestBean.overAllArea=this.propertyValuationRequestBean.totalArea
    }

  }
  else{
    this.propertyValuationRequestBean.totalArea=this.propertyValuationRequestBean.overAllArea
    this.propertyValuationRequestBean.partialTransactArea= this.propertyValuationRequestBean.overAllArea
  }
  this.propertyValuationRequestBean.buitupArea=null
  this.propertyValuationRequestBean.commonArea=null
  this.inputChangeEventValidation()
}
validationLessThan(event:any){
  
  
  if(Number(this.propertyValuationRequestBean.partialTransactArea)>=Number(this.propertyValuationRequestBean.overAllArea)){
    this.logger.log("totalArea ",this.propertyValuationRequestBean.totalArea)
    this.logger.log("partialTransactArea ",this.propertyValuationRequestBean.partialTransactArea)

    this.propertyValuationRequestBean.partialTransactArea='';
   }
   else if(this.propertyValuationRequestBean.totalArea==''){
    this.propertyValuationRequestBean.partialTransactArea=''
   }
   else if(this.propertyValuationRequestBean.totalArea != undefined && this.propertyValuationRequestBean.totalArea != ''&&this.propertyValuationRequestBean.partialTransactArea!=''){
    this.propertyValuationRequestBean.totalArea=this.propertyValuationRequestBean.partialTransactArea
   }
  this.resetForm();
}

 getPatrialTransactArea(){
 this.resetForm();
 }

resetForm(){
  this.propertyValuationRequestBean.residentialPlotArea = '';
  this.propertyValuationRequestBean.commercialPlotArea = '';
  this.propertyValuationRequestBean.industrialPlotArea = '';
  this.propertyValuationRequestBean.healthPlotArea = '';
  this.propertyValuationRequestBean.eduPlotArea = '';
  this.propertyValuationRequestBean.othersPlotArea = '';
  this.propertyValuationRequestBean.residentialCumCommercialPlotArea='';
}
pushbuildingDataMoveAgriCulture(){
 
  if(this.showMarketValueEvent()){
    this.dataService.setchangeMainAreaType({id:3})
  }
}


reloadComponent(self:boolean,urlToNavigateTo ?:string){
  //skipLocationChange:true means dont update the url to / when navigating
 this.logger.log("Current route I am on:",this.router.url);
 const url=self ? this.router.url :urlToNavigateTo;
 this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
   this.router.navigate([`/${url}`]).then(()=>{
     this.logger.log(`After navigation I am on:${this.router.url}`)
   })
 })
}

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { param } from 'jquery';
import { NgxUiLoaderService } from 'ngx-ui-loader';


import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { AfterViewChecked, ChangeDetectorRef } from '@angular/core'
import { GuidelineService } from '../../service/GuidelineService';




@Component({
  selector: 'app-print-guideline',
  templateUrl: './print-guideline.component.html',
  styleUrls: ['./print-guideline.component.scss']
})
export class PrintGuidelineComponent implements OnInit, AfterViewChecked {
  lan: any;
  districtId: any;
  districtIdEnc: any;
  redType: any[] = [];
  financialYear: any = "";
  demographyNameEn: any = "";
  demographyNameHi: any = "";
  public srNo: number;
  responseData: any[] = new Array();
  finalGlRateData: FinYearPrintViewGl[] = [];
  finYearPrintViewGl: FinYearPrintViewGl = new FinYearPrintViewGl();
  constructor(private _AESEncryptDecryptService: AESEncryptDecryptService, private route: ActivatedRoute,
    private router: Router, private ngxService: NgxUiLoaderService,
    private translateService: TranslateHEService,
    private translate: TranslateService,
    private glService: GuidelineService, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
    this.redType = this.route.snapshot.url.toString().split(",");
    this.districtId = this.redType[1];
    //this.srNo=0;
  }
  ngAfterViewChecked(): void {
    this.srNo = 0;
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.ngxService.start();
    let distEnc = this._AESEncryptDecryptService.encryptInAngular(this.districtId);
    this.glService.getGuidelineDataByDistrictIdFy(this.districtId).subscribe(async ObjectList => {
      this.ngxService.stop();
      this.srNo = 0;
      if (ObjectList.responseData) {
        let gLListData = ObjectList.responseData;
        this.financialYear = ObjectList.responseData?.financialYear;
        this.finYearPrintViewGl.finYear = this.financialYear;
        this.demographyNameEn = ObjectList.responseData?.demographyNameEn;
        this.demographyNameHi = ObjectList.responseData?.demographyNameHi;

        if (gLListData.listRecords != null && gLListData.listRecords.length > 0) {
          let flg = gLListData.listRecords.length;
          this.responseData = gLListData.listRecords;
          console.log("*****", this.responseData)
        }
      }
    });
  }

  SetSeqNo() {
    this.srNo += 1;
    return this.srNo;
  }

  printPayDetails() {
    this.finalGlRateData = [];
    this.ngxService.start();
    this.responseData.forEach((element: any) => {
      element.responseData.forEach((objdta: any) => {
        let tmpObj = new FinYearPrintViewGl();
        tmpObj.id = objdta.id;
        tmpObj.finYear = objdta.financialyr;
        tmpObj.plotresi = objdta.plotresi;
        tmpObj.plotcomm = objdta.plotcomm;
        tmpObj.plotindus = objdta.plotindus;
        tmpObj.buildrcc = objdta.buildrcc;
        tmpObj.buildrbc = objdta.buildrbc;
        tmpObj.buildtinshade = objdta.buildtinshade;
        tmpObj.buildkabelu = objdta.buildkabelu;
        tmpObj.buildshop = objdta.buildshop;
        tmpObj.buildoffice = objdta.buildoffice;
        tmpObj.buildgodown = objdta.buildgodown;
        tmpObj.buildmultiresi = objdta.buildmultiresi;
        tmpObj.buildmulticomm = objdta.buildmulticomm;
        tmpObj.agriirrg = objdta.agriirrg;
        tmpObj.agriunirrg = objdta.agriunirrg;
        tmpObj.landuptoresi = objdta.landuptoresi;
        tmpObj.landuptocomm = objdta.landuptocomm;
        tmpObj.districtName = (this.lan == 0) ? objdta.demography.location.district.demographyNameEn : objdta.demography.location.district.demographyNameHi;
        tmpObj.colonyName = (this.lan == 0) ? objdta.colony.demographyNameEn : objdta.colony.demographyNameHi;
        tmpObj.totalDemgh = (this.lan == 0) ? objdta.totalDemghEn : objdta.totalDemghHi;
        this.finalGlRateData.push(tmpObj);
      })

    })
    console.log("&&&&&&&&", this.finalGlRateData)
    this.glService.printPdfListJasper(this.finalGlRateData).subscribe(async ObjectList => {
      this.ngxService.stop();
      console.log(ObjectList);
      let url = window.URL.createObjectURL(ObjectList.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.setAttribute('target', 'blank');
      a.href = url;
      a.download = ObjectList.filename;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }, error => {

      console.log(error);
    });
    // let printData = document.getElementById('print-card').innerHTML;
    // document.body.appendChild(printData);

    // var myWindow = window.open("", "_blank");
    // myWindow.document.write(printData)
    // myWindow.print();

    //     let printContents, popupWin;
    //     printContents = document.getElementById('pdfDivId').innerHTML;
    //     popupWin = window.open('', 'top=0,left=0,height=100%,width=100%');
    //     popupWin.document.open();
    //     popupWin.document.write(`
    //     <html>
    //     <head>
    //         <title>Print tab</title>

    //             <style type = "text/css" > @media screen {
    //                 table,
    //                 td,
    //                 th {
    //                     border: 1px solid #ddd;
    //                     text-align: left;
    //                 }

    //                 table {
    //                     border-collapse: collapse;
    //                     width: 100%;
    //                     margin-bottom: 1rem;
    //                 }

    //                 th,
    //                 td {
    //                     padding: 8px;
    //                 }

    //             }

    //             @media print {

    //                filter: grayscale(100%);
    //               background-size: 10%;}
    //                 table,
    //                 td,
    //                 th {
    //                     border: 1px solid #ddd;
    //                     text-align: left;
    //                 }

    //                 table {
    //                     border-collapse: collapse;
    //                     width: 100%;

    //                 }

    //                 th,
    //                 td {
    //                     padding: 8px;
    //                 }
    //                 .d-print-block{
    //                   display:block !important;
    //                 }

    //             }
    //             @media screen, print {
    //                 table,
    //                 td,
    //                 th {
    //                     border: 1px solid #ddd;
    //                     text-align: left;
    //                 }

    //                 table {
    //                     border-collapse: collapse;
    //                     width: 100%;
    //                     margin-bottom: 1rem;
    //                 }

    //                 th,
    //                 td {
    //                     padding: 8px;
    //                 }



    //             }

    //         </style>
    //     </head>
    //     <body onload="window.print();window.close()">

    //         ${printContents}

    //     </body>
    // </html>`
    //     );
    //     popupWin.document.close();

  }
}

export class FinYearPrintViewGl {
  id: string;
  finYear: string;
  colonyName: string;
  districtName: string;
  selectedLang: string;
  plotresi: string;
  plotcomm: string;
  plotindus: string;
  buildrcc: string;
  buildrbc: string;
  buildtinshade: string;
  buildkabelu: string;
  buildshop: string;
  buildoffice: string;
  buildgodown: string;
  buildmultiresi: string;
  buildmulticomm: string;
  agriirrg: string;
  agriunirrg: string;
  landuptoresi: string;
  landuptocomm: string;
  totalDemgh: string;

}
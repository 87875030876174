import { ModuleWithProviders, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from "../environments/environment";

import { AppComponent } from './app.component';

import { Md5Pipe } from 'projects/common-lib/src/lib/pipes/md5/md5.pipe';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonLibModule, httpTranslateLoader } from 'projects/common-lib/src/projects';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CKEditorModule } from 'ckeditor4-angular';
import { HttpClientXsrfModule } from '@angular/common/http';
import { HttpXsrfInterceptor } from 'projects/common-lib/src/lib/helper-lib/httpclient/HttpXSRFInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSliderModule } from "@angular-slider/ngx-slider";


import { DeedInstrumentListingComponent } from './modules/duty-calculation/duty-service/deed-instrument-listing/deed-instrument-listing.component';
import { ConsenterDetailsComponent } from './modules/duty-calculation/consenter-details/consenter-details.component';
import { QrCodeViewComponent } from './modules/qr-code-view/qr-code-view.component';
import { SrOfficeDisplayComponent } from './modules/sr-office-display/sr-office-display.component';
import { FingerprintScannerComponent } from './modules/fingerprint-scanner/fingerprint-scanner.component';
import { QrcodeViewEregiComponent } from './modules/qrcode-view-eregi/qrcode-view-eregi.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthLayoutModule } from './auth-layout/auth-layout.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from './modules/property-valuation/DataService';
import { AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting/app.setting.service';
import { DefaultModule } from './default/default.module';
import { NgxsModule } from '@ngxs/store';
import { PropertyValuationState } from 'src/app/store/state/property-valuation.state';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { BreadcrumbModule } from 'angular-crumbs';
import { MaterialModule } from 'projects/common-lib/material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
const providers: any[] = [
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
];

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "rgb(209, 76, 69)",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 80,
  "bgsType": "three-strings",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "rgb(209, 76, 69)",
  "fgsPosition": "center-center",
  "fgsSize": 100,
  "fgsType": "three-strings",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "rgb(209, 76, 69)",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "Please Wait....",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 900
};
@NgModule({
  declarations: [
    AppComponent,
    DeedInstrumentListingComponent,
    ConsenterDetailsComponent,
    QrCodeViewComponent,
    SrOfficeDisplayComponent,
    FingerprintScannerComponent,
    QrcodeViewEregiComponent
  ],

  
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    DefaultModule,
    CKEditorModule,
    HttpClientXsrfModule, // Adds xsrf support
    NgxSliderModule,
    ReactiveFormsModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    DragDropModule,
    NgSelectModule,
    CommonLibModule,
    AuthLayoutModule,
    BreadcrumbModule,
    ChartsModule,
    NgbModule,
    MaterialModule,
    NgxsModule.forRoot([PropertyValuationState] ),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'hi'
    })
  ],
  providers: [Md5Pipe,
    { provide: "environment", useValue: environment },
    { provide: MAT_DIALOG_DATA, useValue: {}},
    { provide: MatDialogRef, useValue: {} },
    {provide : LocationStrategy , useClass: PathLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    AppSettingService
   ,DatePipe,
   DataService
  ],
  exports:[
    
    
  ],
  
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class AppModule {
  constructor(private translateService :TranslateHEService,private  translate:TranslateService){
   // translate.setDefaultLang('hi')
  }
 }
@NgModule({
})
export class FrontWebSharedModule {
  static forRoot(): ModuleWithProviders<FrontWebSharedModule> {
    return {
      ngModule: AppModule,
      providers,
    };
  }
constructor(){
  console.log("front")
}


}
// export function httpTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
// }
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { AppSettingService, AppSetting } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { map } from "rxjs/operators";

import { HttpClient } from '@angular/common/http';
import { TicketModel } from '../modules/unregistuser-ticket/unregis-ticket-creation/unregis-ticket-creation.component';


@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private settings!: AppSetting;
  public lan: number;

  constructor(private appSettingsService: AppSettingService, private router: Router, private customeHttpClient: CustomHttpClient, private http: HttpClient, private translate: TranslateService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
      translate.compiler
    });
  }

  getTicketAllDropDown(param:any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/getTicketAllDropDown", param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }
  createTicket(ticketModel: TicketModel) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/createTicket", ticketModel
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }
  getAllTickets(param: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/getAllTickets", param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }
  getTicketDetails(param: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/getTicketDetails", param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }
  manageTicket(param: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/manageTicket", param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }

  getAllTicketTitles() {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/getAllTicketTitles"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getAllTitleFieldByTitleId(titleId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/getAllTitleFieldByTitleId/" + titleId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  ticketStatusChange(param: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/ticketStatusChange", param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }

  getSubHelpTopicList(helpTopicId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/ticket/getSubHelpTopicList/" + helpTopicId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  ticketDetails() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getallticket/", {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }



  getTitleById(id: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `department/ticket/${id}`, {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  newTicketTitle(title: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + 'department/savenewtitle', title
      ).pipe(
        map((result: any) => {
          return result;
        })
      )
  }

  updatetitledetails(title: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + 'department/updateticket', title
      ).pipe(
        map((result: any) => {
          return result;
        })
      )
  }

  getCategory() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getCategory", {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  getstatusandcategoryId(param: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getstatusandcategory", param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {

            return error;
          }

        )
      )
  }

  getStatus() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getStatus", {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  ticketFieldDetails() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getallticketfield/",{}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  addticketField(field: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/savenewfield/", field
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  updateticketField(field: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/updateticketfield/",field
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getFieldById(id: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `department/field/${id}`,{}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getFieldStatus() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getstatusfield",{}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  statuspost(param: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/statuspost", param
      ).pipe(
        map((result: any) => {

          return result;
        },
          (error: any) => {

            return error;
          }

        )
      )
  }

  getMappingTitle() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getallTitle", {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  getMappingField() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getallfield", {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getAllTicketMappingFieldListing() {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + "department/getalllistingtitle", {}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getselectedFieldListByTitleId(titleid: any) {
    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url + `department/getfieldbytitleid/${titleid}`,{}
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  saveMappingTitleField(ticketmappedfield: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "department/saveticketmapping/",ticketmappedfield
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}

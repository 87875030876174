export class AadharEkycOTPAuthRequest{

    aadhaarNumber:string;
	refKey:string;
	vid:string;
	otp:string;
	txn:string;
	username:string;
	pidData:string;
	citizenId:number;
	departmentId:number;
}

export class AadharEkycOTPAuthResponse{

	srdhFailureCode:string;
	auaFailureCode:string;
	ksaFailureCode:string;
    srdhErrorCode:string;
	auaErrorCode:string;
	transactionId:string;
	refKey:string;
	residentDetails:ResidentDetails;
	ret:number;
	aadharTxnLogNo:string;
	aadharResMsg:any;
}

export class ResidentDetails{

    aadhaarId:string;
	pincode:string;
	PO:string;
	state:string;
	subdistrict:string;
	district:string;
	vtc:string;
	locality:string;
	landmark:string;
	street:string;
	building:string;
	mobile:string;
	guardianRelationType:string;
	guardianName:string;
	gender:string;
	dob:string;
	resName:string;
	emailId:string;
	residentPhoto:string;
	uidToken:string;
}


export class AadharEkycOTPGenResponse{

    ret:number;
	auaErrorCode:string;
	srdhErrorCode:string;
	transactionId:string;
	txn:string;
	maskedMobile:string;
	maskedEmail:string;
}
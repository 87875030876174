import { EventEmitter, Injectable } from '@angular/core';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ERegCommunicationService {

  resetDutyFields:Subject<boolean>;
  validateDutyFields:Subject<boolean>;
  //emitInstDetlsToEregModule: EventEmitter<DeedInstrumentBean>;
  
  constructor() {
    //this.emitInstDetlsToEregModule = new EventEmitter<DeedInstrumentBean>();
    this.resetDutyFields =  new Subject<boolean>();
    this.validateDutyFields =  new Subject<boolean>();
   }

   
}

<lib-alertmessage #alertMessage></lib-alertmessage>
<div class="table-responsive shadow-none">
  <table mat-table #outerSort="matSort" [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8"
    matSort>

    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'e-registry-completion.s.no' | translate}} </th>
      <td mat-cell *matCellDef="let element" style="width:8%"><span class="iconcls ml-2"
          *ngIf="element.igrsEstampPartyStatusDetailsList?.data.length">
          <div *ngIf="!(expandedElement) &&  (element == expandedElement) "><mat-icon>keyboard_arrow_right</mat-icon>
          </div>
          <div *ngIf="expandedElement && element == expandedElement"><mat-icon>keyboard_arrow_down</mat-icon></div>
        </span>
        <span class="iconcls ml-2" *ngIf="element.igrsEstampPartyStatusDetailsList?.data.length">
          <div *ngIf="!(element == expandedElement) "><mat-icon>keyboard_arrow_right</mat-icon>
          </div>
        </span>{{element.sno}}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef> {{'proIdentification.DeedActionDate' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.createdDate | date:'dd/MM/YYYY, h:mm:ss a'}} </td>
    </ng-container>
    <ng-container matColumnDef="contentStatus">
      <th mat-header-cell *matHeaderCellDef>{{'proIdentification.DeedStatus' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{getContentStatus(element.contentStatus)}} </td>
    </ng-container>
    <ng-container matColumnDef="contentStatusRemarks">
      <th mat-header-cell *matHeaderCellDef style="width: 300px;"> {{'proIdentification.DeedRemarks' | translate}}</th>
      <td mat-cell *matCellDef="let element" style="width: 300px;"> {{element.deedRemarks}} </td>
    </ng-container>
    <ng-container matColumnDef="estampId">
      <th mat-header-cell *matHeaderCellDef [style.display]="'none'"> {{'proIdentification.EstampId' | translate}}</th>
      <td mat-cell *matCellDef="let element" [style.display]="'none'"> {{element.estampId}} </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef [style.display]="'none'"> {{'proIdentification.Id' | translate}}</th>
      <td mat-cell *matCellDef="let element" [style.display]="'none'"> {{element.id}} </td>
    </ng-container>
    <ng-container matColumnDef="deedContent">
      <th mat-header-cell *matHeaderCellDef [style.display]="'none'"> {{'proIdentification.DeedContent' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" [style.display]="'none'"> {{element.deedContent}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 250px;"> {{'proIdentification.Action' | translate}} </th>

      <td mat-cell *matCellDef="let element" class="text-nowrap" style="width: 250px;">
        <!-- <button *ngIf="eregFlag!=1 && (!statusCheck && !partyStatus) && (element.igrsEstampPartyStatusDetailsList?.data.length == '' || element.igrsEstampPartyStatusDetailsList?.data.length == undefined)" id="element.id" name="element.id"
                          mat-button (click)="viewEstampDeedJrxmlToHtml(element.estampId,element.deedFileName,consideerartionModal,true)"
                          class="btn-secondary" >
                          {{"e-stamp.View-Deed-action" | translate}}
                          </button> -->
        <button *ngIf="eregFlag!=1 && (element.sno == 1) && (!statusCheck && !partyStatus) " id="element.id"
          name="element.id" mat-button
          (click)="viewEstampDeedJrxmlToHtml(element.estampId,element.deedFileName,consideerartionModal,true)"
          class="btn-secondary">
          {{"e-stamp.View-Deed-action" | translate}}
        </button>
        <button *ngIf="eregFlag!=1 && !(element.sno == 1) && (!statusCheck && !partyStatus) " id="element.id"
          name="element.id" mat-button
          (click)="viewEstampDeedJrxmlToHtml(element.estampId,element.deedFileName,consideerartionModal,false)"
          class="btn-secondary">
          {{ "e-stamp.View-Deed" | translate }}
        </button>

        <button *ngIf="eregFlag!=1 && !(!statusCheck && !partyStatus) " id="element.id" name="element.id" mat-button
          (click)="viewEstampDeedJrxmlToHtml(element.estampId,element.deedFileName,consideerartionModal,false)"
          class="btn-secondary">
          {{ "e-stamp.View-Deed" | translate }}
        </button>
        <!-- <button *ngIf="eregFlag!=1 &&  !(element.igrsEstampPartyStatusDetailsList?.data.length == '' || element.igrsEstampPartyStatusDetailsList?.data.length == undefined)" id="element.id" name="element.id"
                          mat-button (click)="viewEstampDeedJrxmlToHtml(element.estampId,element.deedFileName,consideerartionModal,false)"
                          class="btn-secondary" >
                          {{ "e-stamp.View-Deed" | translate }}
                          </button> -->

        <button
          *ngIf="eregFlag==1 && (element.sno == 1) && (deedAcceptLink != null && deedAcceptLink != undefined) && (!statusCheck && !partyStatus) "
          id="viewDeed" name="viewDeed" type="button" mat-button
          (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,true)"
          class="btn btn-primary mr-1">
          {{"e-stamp.View-Deed-action" | translate}}
        </button>

        <button
          *ngIf="eregFlag==1 && !(element.sno == 1) && (deedAcceptLink != null && deedAcceptLink != undefined) && (!statusCheck && !partyStatus) "
          id="viewDeed" name="viewDeed" type="button" mat-button
          (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,false)"
          class="btn btn-primary mr-1">
          {{ "e-stamp.View-Deed" | translate }}
        </button>

        <button
          *ngIf="eregFlag==1 && (deedAcceptLink != null && deedAcceptLink != undefined) && !(!statusCheck && !partyStatus) "
          id="viewDeed" name="viewDeed" type="button" mat-button
          (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,false)"
          class="btn btn-primary mr-1">
          {{ "e-stamp.View-Deed" | translate }}
        </button>

        <button
          *ngIf="eregFlag==1 && !(deedAcceptLink != null && deedAcceptLink != undefined) && !(!statusCheck && !partyStatus) "
          id="viewDeed" name="viewDeed" type="button" mat-button
          (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,false)"
          class="btn btn-primary mr-1">
          {{ "e-stamp.View-Deed" | translate }}
        </button>
        <button *ngIf="isDeedLinkInput==1 && estampSearch?.contentStatus == 11 && element.sno == 1" id="deedLink"
          name="deedLink" mat-button (click)="deedAcceptLinkSendToParty(element.estampId)"
          class="btn btn-secondary pr-2 pl-2">
          {{ "e-stamp.deed-link-button" | translate }}
        </button>

        <!-- <button *ngIf="eregFlag==1 && !(deedAcceptLink != null && deedAcceptLink != undefined) && (!statusCheck && !partyStatus) && (element.igrsEstampPartyStatusDetailsList?.data.length == '' || element.igrsEstampPartyStatusDetailsList?.data.length == undefined)" id="viewDeed" name="viewDeed" type="button" mat-button (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,false)"  class="btn btn-primary mr-1">
                            {{ "e-stamp.View-Deed" | translate }}
                          </button>
                          <button *ngIf="eregFlag==1 && !(!statusCheck && !partyStatus) && (element.igrsEstampPartyStatusDetailsList?.data.length == '' || element.igrsEstampPartyStatusDetailsList?.data.length == undefined)" id="viewDeed" name="viewDeed" type="button" mat-button (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,false)"  class="btn btn-primary mr-1">
                            {{ "e-stamp.View-Deed" | translate }}
                           </button>
                          <button *ngIf="eregFlag==1 && !(element.igrsEstampPartyStatusDetailsList?.data.length == '' || element.igrsEstampPartyStatusDetailsList?.data.length == undefined)" id="viewDeed" name="viewDeed" type="button" mat-button (click)="viewEregDeedJrxmlToHtmlDeedHistory(element.estampId,element,consideerartionModal,false)"  class="btn btn-primary mr-1">
                            {{ "e-stamp.View-Deed" | translate }}
                          </button>
                           -->

      </td>

    </ng-container>


    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail" *ngIf="element.igrsEstampPartyStatusDetailsList?.data.length"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="inner-table  shadow-none new_color" *ngIf="expandedElement">

            <table #innerTables mat-table #innerSort="matSort" [dataSource]="element.igrsEstampPartyStatusDetailsList"
              matSort>

              <ng-container matColumnDef="sNo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'e-registry-completion.s.no' | translate}} </th>
                <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
              </ng-container>
              <ng-container matColumnDef="partyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'e-sign.partyname' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  {{lan==0 ?element.firstNameEn:element.firstNameHi}} {{lan==0
                  ?element.middleNameEn:element.middleNameHi}} {{lan==0 ?element.lastNameEn:element.lastNameHi}}
                </td>
              </ng-container>
              <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef> {{'userDeedTemplate.createdDate' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.createdDate | date:'dd/MM/YYYY, h:mm:ss a'}} </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>{{'e-stamp.Party-Status' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{getPartyContentStatus(element.status)}} </td>
              </ng-container>
              <ng-container matColumnDef="remarks">
                <th mat-header-cell *matHeaderCellDef> {{'e-stamp.Remarks' | translate}}</th>
                <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      [class.example-element-row]="element.igrsEstampPartyStatusDetailsList?.data.length"
      [class.example-expanded-row]="expandedElement === element" (click)="toggleRow(element)"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="columnsToDisplay.length">
        {{'No-data-found' | translate}}
      </td>
    </tr>
  </table>

</div>


<ng-template #consideerartionModal let-c="close" let-d="dismiss">
  <div [ngClass]="{'fullScreenDeed': exitZoomDeedViewIf, '' : !exitZoomDeedViewIf }">
    <ng-container *ngIf="eregFlag==1;else estampHead">
      <h2 class="Consenterdialog-title">{{'proIdentification.ViewERegistryDeed' | translate}}</h2>
    </ng-container>
    <ng-template #estampHead>
      <h2 class="Consenterdialog-title">{{'proIdentification.ViewEStampDeed' | translate}}</h2>
    </ng-template>
    <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click');exitZoomDeedViewIf=false"
      id="modalDeedPdfClose">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="controlsbox">

      <span class="zoomIcon" (click)="zoomDeedView()" *ngIf="!exitZoomDeedViewIf"><i class="material-icons"
          style="line-height: 18px;">&#xe5d0;</i></span>
      <span class="zoomIcon" (click)="noZoomDeedView()" *ngIf="exitZoomDeedViewIf"><i class="material-icons"
          style="line-height: 18px;">fullscreen_exit</i></span>
    </div>


    <div class="modal-body">
      <iframe id="viewer" style="height:100vh;width: 100%;" frameborder="0"></iframe>
    </div>
    <div class="modal-footer">
      <!-- <app-actiondeed-component> </app-actiondeed-component> -->

      <app-actiondeed-component *ngIf="partyId!=null"
        [partyId]="(inputPartyId != null && inputPartyId != undefined) ? inputPartyId : partyId "
        [estampId]="estampIdInput" [mobileNo]="mobileNo" [partyStatus]="partyStatus" [partyType]="partyType"
        [userId]="userId" [isActionButtonShow]="isActionButtonShow" [estampContentStatus]="estampContentStatus"
        [natureOfInstrument]="natureOfInstrument" [partySubType]="partySubType" [eregFlag]="eregFlag"
        style="width: 100%;">
      </app-actiondeed-component>

    </div>
  </div>
</ng-template>


<ngx-ui-loader [loaderId]="'deed-content-history'"></ngx-ui-loader>






  <div class="auth-main">

    <div class="container">



        <div class="row">
            <div class="col-xl-12">
                <lib-alertmessage></lib-alertmessage>

                <div>
                    <h2 class="loginTitle">{{'ticketView.needrasiedticket' | translate}}</h2>
     </div>


                <div *ngIf="step0">
                    <div class="registrationSteps" *ngIf="isIndian==1">
                        <div class="steps active">
                            <div class="stepBox ">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-mobile"></i>
                                    <p>{{'mobile-verification' | translate}}</p>
                                </div>
                                <i class="fa fa-caret-right stepforword"></i>
                            </div>

                        </div>

                        <div class="steps mx-3"  *ngIf="step1clear"   (click)="gotostep12()">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-user-circle-o"></i>
                                    <p> {{'ticketView.raiseTickets' | translate}}</p>
                                </div>
                            </div>
                            <i class="fa fa-caret-right stepforword"></i>
                        </div>

                        <div class="steps mx-3" *ngIf="!step1clear">
                            <div class="stepBox">
                                <div class="d-flex  align-items-center">
                                    <i class="stepIcon fa fa-user-circle-o"></i>
                                    <p>  {{'ticketView.raiseTickets' | translate}}</p>
                                </div>
                            </div>
                            <i class="fa fa-caret-right stepforword"></i>
                        </div>

                    </div>



                </div>

                <div class="card bg-transparent" *ngIf="isIndian==1 && step11">
                    <div class="card-body p-0 pl-3 pr-3">

                     <div class="row">

                            <div
                                class="col-xl-6 col-lg-7 col-md-7 col-xs-12 col-sm-12  logInForms bg-white radius4 pt-2 pd-5">

                                <form (ngSubmit)="verifyOtp()">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                 <label class="w-100 sdf">{{ 'enter-mobile-number'| translate}} <span
                                                        style="color: red;">*</span></label>
                                            <div class="input-group mb-3">
                                               
                                                <div class="input-group-append"> <span class="input-group-text"><i
                                                            class="fa fa-phone"></i></span> </div>
                                                <input type="text" name="mobileNo" [(ngModel)]="mobileNo"
                                                    [OnlyNumber]="true" maxlength="10" minlength="10" required
                                                    class="form-control input_user" pattern="^[6-9]\d{9}$"
                                                    placeholder="{{eRegPartyModel.isMinor!=1 ? ((eRegPartyModel.applicantType=='1' || eRegPartyModel.applicantType==undefined) ? ('enter-mobile-number' | translate) : ('enter-authorized-person-mobile-number' | translate)) : ('enter-guardian-mobile-number' | translate)}}">
                                            </div>
                                          </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 col-sm-5 col-6">
                                            <div class="input-group mb-2" style="align-content: center;">
                                                <img src="{{captchaImageBase64Img}}" height="50" />
                                                <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-sm-2 col-6 text-center">
                                            <img src="assets/images/refresh_image.png" height="26px" class="mt-2"
                                                (click)="getStartupCaptcha()" />
                                        </div>
                                        <div class="col-md-6 col-sm-5">
                                            <div class="input-group mb-2">
                                                <input type="text" name="captchaStr" [(ngModel)]="captchaStr" required
                                                    minlength="4" maxlength="4" class="form-control input_user p-3"
                                                    placeholder="{{'enter-captcha' | translate}}">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- <div class="col-6 hidden-xs"></div> -->
                                        <div class="col-md-6 col-sm-12">
                                            <label class="w-100">&nbsp;</label>

                                            <a mat-raised-button *ngIf="counter == 0 && !otpTouched" mat-button
                                                class="btn btn-primary w-100" (click)="setOtpClicked()"
                                                (keyup.enter)="setOtpClicked()">{{'get-otp' |
                                                translate}}</a>

                                            <a *ngIf="counter == 0 && otpTouched" mat-button
                                                class="btn btn-sm btn-secondary w-100"
                                                (click)="setOtpClicked()"
                                                (keyup.enter)="setOtpClicked()">{{'resend-otp' | translate}}</a>

                                        </div>
                                    </div>

                                    <div class="p-3 mt-3" style="background-color: #0000000a!important;"
                                        *ngIf="getOtpClicked">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="input-group mb-3">
                                                    <label
                                                        class="w-100 d-flex align-items-center justify-content-between"><span
                                                            class="float-left">{{'enter-otp' | translate}}</span>

                                                        <span class="float-right" *ngIf="counter != 0">{{counter |
                                                            formatTime}}</span>

                                                    </label>
                                                    <input class="form-control input_user" name="otp" [(ngModel)]="otp"
                                                        minlength="6" maxlength="6" required [OnlyNumber]="true">
                                                </div>
                                            </div>

                                            <!-- <div class="col-md-4">
                                                    <label class="w-100">&nbsp;</label>
                                                    <button class="w-100 btn login_btn"  >Submit OTP</button>
                                                </div> -->
                                        </div>

                                    </div>



                                    <div class="row mt-3" *ngIf="getOtpClicked">
                                        <div class="col-md-6">
                                            <button mat-raised-button class="w-100 btn btn-primary"
                                                (click)="verifyOtp()">{{'submit' |
                                                translate}}</button>
                                        </div>
                                        <div class="col-md-6">
                                            <button mat-raised-button *ngIf="counter == 0" class="w-100 btn btn-default"
                                                (click)="resetForm()">{{'reset' | translate}}</button>
                                        </div>
                                    </div>
                                </form>


                            </div>

                            <div class="col-xl-6 col-lg-5 col-md-5 col-xs-12 col-sm-12 hidden-xs   align-self-center pt-2 bg-light"
                                *ngIf="step0">


                                <div class="alert alert-primary p-2 mb-3" role="alert">
                                    <div class="d-flex">
                                        <i class="fa fa-info-circle mr-1"></i>
                                        <p>{{'guidelines-lp-in'|translate}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>




        <div *ngIf="isIndian==1 && step12">
            <div class="registrationSteps">
                <div class="steps ">
                    <div class="stepBox ">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-mobile"></i>
                            <p>{{'mobile-verification'|translate}}</p>
                        </div>
                        <button (click)="goBack(12)" class="stepgoback">{{'goback'|translate}}</button>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>
                <div class="steps active mx-3">
                    <div class="stepBox">
                        <div class="d-flex  align-items-center">
                            <i class="stepIcon fa fa-user-circle-o"></i>
                            <p>  {{'ticketView.raiseTickets' | translate}}</p>
                        </div>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>

                </div>


            </div>
<app-unregis-ticket-creation [mobileNoInput]="mobileNo"></app-unregis-ticket-creation>

           <button mat-raised-button (click)="goBack(12)" class="mt-2">{{'goback'|translate}}</button>
        </div>





    </div>
</div>



export class EstampDeedTemplateContent {


  id:number;
  estampId:number;
  estampPartyId:number;
  partyContent:string;
  remarks:string;
  status:number;
  editFlag:number;
  createdBy:number;
  createdDate:Date;
  modifiedBy:number;
  modifiedDate:Date;
  

}

<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>
            {{
              lan == 0
                ? "Frequently asked questions (FAQs)"
                : "अक्सर पूछे जाने वाले प्रश्न"
            }}
          </h2>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
        <div class="services_chip d-flex flex-wrap align-items-center justify-content-center">
            <a class="d-flex align-items-center"  routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_1 icon_round"> 
                <mat-icon>receipt_long</mat-icon>
              </span>
              <div class="application-content-inner">
                 <strong> {{"homepage.eRegistration" | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
              </div>
             
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_2 icon_round"> <mat-icon> display_settings</mat-icon></span>
              <div class="application-content-inner">
                <strong> {{'living-point-2' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
              </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_3 icon_round"> <mat-icon>calculate</mat-icon></span>
             <div class="application-content-inner">
               <strong>{{'homepage.Duty-Calcutation' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
             </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_4 icon_round"> <mat-icon>travel_explore</mat-icon></span>
              <div class="application-content-inner">
                <strong>{{'property-valuation.property-valuation' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
              </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_5 icon_round"> <mat-icon> assignment_turned_in</mat-icon></span>
              <div class="application-content-inner">
                <strong>  {{'homepage.deedAcceptSing' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
              </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_6 icon_round"> <mat-icon>manage_search</mat-icon></span>
             <div class="application-content-inner">
               <strong> {{'homepage.docSCertiCopy' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
             </div>
            </a>
        
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_7 icon_round"> <mat-icon>view_headline</mat-icon></span>
              <div class="application-content-inner">
                <strong> {{'homepage.View-Guideline' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
              </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_8 icon_round"> <mat-icon>calendar_month</mat-icon></span>
             <div class="application-content-inner">
               <strong>  {{'homepage.sloatAvil' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
             </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_1 icon_round"> <mat-icon>location_city</mat-icon></span>
             <div class="application-content-inner">
               <strong>{{'homepage.srspLm' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
             </div>
            </a>
            <a class="d-flex align-items-center" routerLink="/faqDetails">
              <span class="material-symbols-outlined icon_2 icon_round"> <mat-icon>account_balance_wallet</mat-icon></span>
              <div class="application-content-inner">
                <strong>{{'e-wallet.title' | translate}}</strong>
              <mat-icon class="mtIcon">chevron_right</mat-icon>
              </div>
            </a>
        
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

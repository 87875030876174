export class Representative {

    id:number;
	citizenId:number;
	representativeId:number;
	docPath:string;
	status:number;
	registrationNo:string;
	docType:string;
	roleId:number[] = new Array();
	bankId:number;
	ifscCode:string;
	branchId:number;
	firstNameEn:any;
	firstNameHi:any;
	middleNameEn:any;
	middleNameHi:any;
	lastNameEn:any;
	lastNameHi:any;
	contactNo:any;
	username:any;
    instrumentIds : number[];

}


export class SpFunctionality {

    id:number;
	nameEn:string;
	nameHi:string;
	
}

<lib-alertmessage></lib-alertmessage>
<div class="row justify-content-center">
    <div class="col-lg-10 duty-card">
        <h3 class="Search-title">
            {{ 'duty-calculation.commonly_used_document' | translate }}
        </h3>
        <div class="row">
            <div class="col-md-12">
                <div class="new_menu">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                            id="navbarNav2" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation" aria-controls="navbarNav">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav" aria-labelledby="navbarNav2">
                            <ul class="navbar-nav w-100">
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': sales}"
                                        (click)="changeDutyCalculate('sales');">
                                        <!-- <i class="fa fa-calculator" aria-hidden="true"></i> -->
                                        <mat-icon
                                            class="material-icons-outlined text-primary">real_estate_agent</mat-icon>
                                        <span class="spantext">{{'duty-calculation.sale_purchase' | translate
                                            }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': mortgage}"
                                        (click)="changeDutyCalculate('mortgage');">
                                        <mat-icon class="material-icons-outlined text-primary">apartment</mat-icon>
                                        <span class="spantext">{{'duty-calculation.mortgage' | translate }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': willdeed}"
                                        (click)="changeDutyCalculate('willdeed');">
                                        <mat-icon class="material-icons-outlined text-primary">task</mat-icon>
                                        <span class="spantext">{{'duty-calculation.will_Deed' | translate }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': giftdeed}"
                                        (click)="changeDutyCalculate('giftdeed');">
                                        <mat-icon class="material-icons-outlined text-primary">redeem</mat-icon>
                                        <span class="spantext">{{'duty-calculation.gift_deed' | translate }}</span></a>
                                </li>
                                <li class="nav-item" (click)="close_Nav()">
                                    <a class="nav-link" [ngClass]="{'active': leasedeed}"
                                        (click)="changeDutyCalculate('leasedeed');">
                                        <mat-icon class="material-icons-outlined text-primary">home_work</mat-icon>
                                        <span class="spantext">{{'duty-calculation.lease_deed' | translate }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="or-divider">
            <span class="span1">
                <span class="text-primary">{{'duty-calculation.or' | translate }}</span>
                ( {{ 'duty-calculation.other_document' | translate }} )</span>
        </div>

        <!-- <div class="arrow-box" *ngIf="arrow_box">
            <mat-icon (click)="arrowFunction()">keyboard_arrow_down</mat-icon>
        </div> -->
        <!-- <div *ngIf="search_div"> -->

        <form #dutySearchForm="ngForm"
            (ngSubmit)="dutySearchForm.form.valid && onSubmitDeedDetails(dutySearchForm,true)">

            <div class="row justify-content-center">
                <div class="col-xl-4 col-sm-4">

                    <!-- <label class="required">{{ 'duty-calculation.Select-deed-Catagory' |
                                    translate }}</label> -->
                    <ng-select appendTo="body" placeholder="{{ 'duty-calculation.Select-deed-Catagory' | translate }}"
                        #DeedCatNgSelect [(ngModel)]="deedCatSelectedId" name="DeedCatNgSelect" [clearable]="true"
                        required (ngModelChange)="onDeedCategorySelected()"
                        [ngbTooltip]="deedCatSelected.deedCategoryNameEn!==undefined?lan==0?deedCatSelected.deedCategoryNameEn:deedCatSelected.deedCategoryNameHi:''"
                        [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                        <ng-option [value]="deedCatItem.id"
                            *ngFor="let deedCatItem of deedCategoryBeanList | orderBy: 'deedCategoryNameEn'">
                            <div
                                [title]="deedCatItem.deedCategoryNameEn!==undefined? lan==0?deedCatItem.deedCategoryNameEn:deedCatItem.deedCategoryNameHi:''">
                                {{lan==0?deedCatItem.deedCategoryNameEn:deedCatItem.deedCategoryNameHi}}
                            </div>
                        </ng-option>
                    </ng-select>
                    <div class="requiredColor" *ngIf="dutySearchForm.submitted && !deedCatSelectedId"> {{
                        'duty-calculation.deed-Catagory-Required' | translate }}</div>

                </div>
                <div class="col-xl-4 col-sm-4">
                    <!-- <label class=" required">{{ 'duty-calculation.Select-Deed-Type' | translate
                                }}</label> -->
                    <ng-select appendTo="body" #DeedTypeNgSelect name="DeedTypeNgSelect"
                        [(ngModel)]="deedTypeSelectedId"
                        placeholder="{{ 'duty-calculation.Select-Deed-Type' | translate }}" required [clearable]="true"
                        [ngbTooltip]="deedTypeSelected.deedTypeNameEn!==undefined? lan==0?deedTypeSelected.deedTypeNameEn:deedTypeSelected.deedTypeNameHi:''"
                        (ngModelChange)="onDeedTypeSelected()"
                        [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                        <ng-option [value]="deedTypeItem.id"
                            *ngFor="let deedTypeItem of deedTypeCategoryBeanList | orderBy: 'deedTypeNameEn'">
                            <div
                                [title]="deedTypeItem.deedTypeNameEn!==undefined? lan==0?deedTypeItem.deedTypeNameEn:deedTypeItem.deedTypeNameHi:''">
                                {{lan==0?deedTypeItem.deedTypeNameEn:deedTypeItem.deedTypeNameHi}}</div>
                        </ng-option>
                    </ng-select>
                    <div class=" requiredColor" *ngIf="dutySearchForm.submitted && !deedTypeSelectedId"> {{
                        'duty-calculation.deed-Type-Required' | translate }}</div>
                </div>
                <div class="col-xl-4 col-sm-4">
                    <!-- <label class=" required">{{ 'duty-calculation.Select-Instrument' | translate
                                }}</label> -->
                    <ng-select appendTo="body" #DeedInstNgSelect name="DeedInstNgSelect"
                        placeholder="{{ 'duty-calculation.Select-Instrument' | translate }}" required [clearable]="true"
                        [ngbTooltip]="deedInstrumentSelected!==undefined? lan==0?deedInstrumentSelected?.instrumentNameEn:deedInstrumentSelected?.instrumentNameHi:''"
                        [(ngModel)]="deedInstrumentSelectedId" (ngModelChange)="onDeedInstrumentSelected(true)"
                        [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">
                        <ng-option [value]="instItem.id"
                            *ngFor="let instItem of deedInstrumentBeanList | orderBy: 'instrumentNameEn'">
                            <div
                                [title]="instItem?.instrumentNameEn!==undefined? lan==0?instItem?.instrumentNameEn:instItem?.instrumentNameHi:''">
                                {{lan==0?instItem.instrumentNameEn:instItem.instrumentNameHi}}</div>
                        </ng-option>
                    </ng-select>
                    <div class="requiredColor" *ngIf="dutySearchForm.submitted && !deedInstrumentSelectedId">
                        {{'duty-calculation.deed-Inst-Required' | translate }}</div>
                </div>

            </div>
            <div *ngIf="!hideSearchFileds" class="or-divider"><span class="span1"><span
                        class="text-primary">{{'duty-calculation.or' | translate }}</span> ( {{
                    'duty-calculation.Search_Instrument' | translate }} )</span></div>


            <div *ngIf="!hideSearchFileds" class="row form-inline mb-3">
                <div class="col-md-12">

                    <div class=" m-0 justify-content-center">
                        <ng-select appendTo="body" class="searchInst" name="searchInst" [multiple]="false"
                            [clearable]="true"
                            [ngbTooltip]="searchTextInst?.instrumentNameEn==null?'': lan==0?searchTextInst?.instrumentNameEn:searchTextInst?.instrumentNameHi"
                            placeholder="{{ 'duty-calculation.Search_Instrument' | translate }}" #searchInst
                            [(ngModel)]="searchTextInstId" minlength="3" [closeOnSelect]="true"
                            (keyup)="onFilterKeyChange(searchInst)" [maxSelectedItems]="1"
                            (ngModelChange)="onSearchInstrumentSelected()"
                            [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">

                            <ng-option *ngFor="let inst of serachInstList" [value]="inst?.id">
                                <div
                                    *ngIf="serachInstList!=null && serachInstList!=undefined && serachInstList?.length>0">
                                    <div
                                        [title]="inst?.instrumentNameEn==null?'': lan==0?inst?.instrumentNameEn:inst?.instrumentNameHi">
                                        <i class="fa fa-search"></i>
                                        {{lan==0?inst.instrumentNameEn:inst.instrumentNameHi}}
                                    </div>
                                </div>
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>


            <!--------Mining Lease----------->
            <div class="miningLease mt-4 mb-2"
                *ngIf="(deedInstrumentSelectedId == 81 || deedInstrumentSelectedId == 83 || deedInstrumentSelectedId == 84) && fromEregistryModule">
                <div class="form-check form-check-inline mr-5 flex-wrap">
                    <label class="form-check-label" for="mineralRadio1">
                        <input class="form-check-input" type="radio" name="major" id="mineralRadio1" [value]=1
                            (click)="openMineralDialog($event,1)" [(ngModel)]="mineralTypeId">
                        {{'duty-calculation.majo-mineral' | translate }}

                    </label>

                </div>
                <div class="form-check form-check-inline flex-wrap">
                    <label class="form-check-label" for="mineralRadio2">
                        <input class="form-check-input" type="radio" name="major" id="mineralRadio1" [value]=2
                            (click)="openMineralDialog($event,2)" [(ngModel)]="mineralTypeId">

                        {{'duty-calculation.mino-mineral' | translate }}
                    </label>

                </div>
            </div>
            <!-------Mining Lease------------>
 







            <div *ngIf="!fromEregistryModule" class="text-right mt-2 mb-3">
                <div class=" ">
                    <button type="button" mat-flat-button class="btn btn-secondary mr-1" (click)="resetFields()" [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45">{{
                        'duty-calculation.Reset' | translate }}</button>
                    <!--     <button (click)="onSubmitDeedDetailsOld()" class="btn btn-primary mr-3" type="button" id="button-addon2">{{ 'duty-calculation.Submit' | translate }}</button>-->
                    <button *ngIf="showSubmitBttn" mat-flat-button class="btn btn-primary " type="submit" [disabled]="!fromEregistryModuleImpoundORCOS && igrsEregTxnDetlsBean?.statusBean?.id >= 45"
                        id="button-addon2">{{
                        'duty-calculation.Submit' | translate }}</button>

                </div>
            </div>
        </form>
    </div>

    <ngx-ui-loader [loaderId]="'instrument-search'"></ngx-ui-loader>
</div>
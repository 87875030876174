import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { map } from 'rxjs/operators';





import { UserProfileModel } from 'projects/common-lib/src/lib/models/auth.userdetail.model/user.profile.model';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { GLDraftHstBean, GLDraftSuggAndFYBean, GLMasterDraftDetails } from 'projects/common-lib/src/lib/models/GLMasterDraftDetails';
import { FinYearPrintViewGl } from '../modules/print-guideline/print-guideline.component';
import { SRWardAndVillageBean } from 'projects/common-lib/src/lib/models/SRWardAndVillageBean';
import { RenameDemographyBean } from 'projects/common-lib/src/lib/models/RenameDemographyBean';


@Injectable({
    providedIn: 'any'
})
export class GuidelineService {



    private settings!: AppSetting;
    constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient,private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });
    }

    get_GLDetails(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/search_guideline", param
            ).pipe(
                map((result: any) => {

                    //  

                    // return result.responseData;
                    // console.log("-----------------result-------------------- ",result);
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }

   /* get_financial_year() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/get_financial_year_gl"
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }*/

    getglConsRequestStatus() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/get_cons_request_status"
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    fetchFinancialYear() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/fetchFinancialYear"
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    getCurrentfinancialyearAndDate() {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/fetchFinancialYearByCurrentDate"
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }
    getAvailablefinancialyearAndDate(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_user_active_fy",param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    get_glmasterbyid(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_glmaster_by_id", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    saveGLDraft(gLMasterDraftDetails: GLMasterDraftDetails) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/save_gl_draft_entry",
                gLMasterDraftDetails
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    get_GLDraftDetails(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/search_guideline_draft", param
            ).pipe(
                map((result: any) => {

                    //  

                    // return result.responseData;
                    // console.log("-----------------result-------------------- ",result);
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }

    get_gldraftbyid(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_guideline_draft_by_id", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }


   /* updateGLDraftDetailsStatus(gLDraftHstBean: GLDraftHstBean) {
        return this.customeHttpClient.PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/workFlowApprovalGLDraftUpdate", gLDraftHstBean
        ).pipe(
            map((result: any) => {

                return result;
            },
                (error: any) => {

                    return error;
                })
        );
    }*/

    getCountGLDraftDetailsByStatus(param: any) {
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/getCountAllDataForwardGL", param
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }


            })
        );
    }

    getCountGLDraftDetailsByStatusN(param: any) {
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/getGuidelineDashBoardData", param
        ).pipe(
            map((result: any) => {

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }


            })
        );
    }

    bulkUpdateGLStatus(param: any) {
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/bulkUpdateGuidelineStatus", param
        ).pipe(
                map((result: any) => {
                    return result;
                },
                (error: any) => {
                    return error;
                })
        );
    }

    updateGlFlatRateDateExAppStatus(gLDraftHstBean: GLDraftHstBean) {
        return this.customeHttpClient.PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/FlatRate/updateGlFlatRateDateExAppStatus", gLDraftHstBean
        ).pipe(
            map((result: any) => {

                return result;
            },
                (error: any) => {

                    return error;
                })
        );
    }

    getGuidelineDetailsByDemography(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_guideline_details_by_demography", param
            ).pipe(
                map((result: any) => {

                    return result;
                },
                (error: any) => {
                    return error;
                })
            );
    }

   /* get_guideline_draft_status(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_guideline_draft_status", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }*/

    get_guideline_construction_status(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_guideline_construction_status", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    checkDateValidationGlDraft() {
        let param = '';
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_add_gldraft_datetime_validation", param
            ).pipe(
                map((result: any) => {

                    //
                    // if (result && result.responseStatus == 'true') {
                    //     return result.responseData;
                    // } else {
                    //     return result.responseMessage;
                    // }
                    return result;
                })
            );
    }




   

    saveGLDraftSuggestion(glDraftSuggAndFYBean: any) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/save_gl_draft_suggestion",
                glDraftSuggAndFYBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    updateGlDraftById(glDraftSuggAndFYBean: any) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/updateGlDraftById",
                glDraftSuggAndFYBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }



    searchGLDraftBulkUpdate(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/search_GLDraftBulkUpdate", param
            ).pipe(
                map((result: any) => {

                    //  

                    // return result.responseData;
                    // console.log("-----------------result-------------------- ",result);
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }

    updateGLDraftBulkDetailsStatus(gLDraftHstBean: GLDraftHstBean) {
        return this.customeHttpClient.PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/update_gldraftbulkupdate_by_ids", gLDraftHstBean
        ).pipe(
            map((result: any) => {

                return result;
            },
                (error: any) => {

                    return error;
                })
        );
    }


    get_GlAndSuggestionDetails(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/search_guideline_draft_and_suggest", param
            ).pipe(
                map((result: any) => {

                    //  

                    // return result.responseData;
                    // console.log("-----------------result-------------------- ",result);
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }


    get_gldraftAndSuggestionById(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_guideline_draft_and_suggestion", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    // service for view citizen suggestion 
    get_CitizenSuggestion(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/search_gl_draft_CitizenSuggestion", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }
                })
            );
    }

    getmstGLFinYear(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_mst_fin_date", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }





    updateGLDraftSuggestionIsActiveById(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/update_gl_draft_suggestion_by_id", param
            ).pipe(
                map((result: any) => {

                    return result;
                })
            );
    }


    get_department_user() {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_department_user", {}
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );

    }



    saveSrWord(srWardAndVillageBean: SRWardAndVillageBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/save_sr_ward_entry",
                srWardAndVillageBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }



    getSrWord(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/getLocationBySROUser", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }
                })
            );

    }

    saveGlApprovalFlatRate(glDraftSuggAndFYBean: GLDraftSuggAndFYBean) {
        return this.customeHttpClient.PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "department/GL/FlatRate/saveGlApprovalFlatRate",
            glDraftSuggAndFYBean
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {

                    return error;
                }
            )
        );

    }

    downloadGlUploadedDoc(docPath:string){
        return this.customeHttpClient
        .Post<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "common/gl/downloadGlUploadedDoc/"+docPath,
            docPath
           // filename
        ).pipe(
            map((result: any) => {
                if (result) {
                    // var string1 = JSON.stringify(result);
                  //   var data:any;
                  //  filename: 'yourFileName.pdf',
                 //   data= result.blob()
                    return result;
                }
            },
                (error: any) => {
                    alert("not found");
                    return error;
                }
            )
        );
    }


    downloadSuggestionUploadedDoc(docPath:string){
        let encVal = this._AESEncryptDecryptService.encryptInAngular(docPath);
        return this.customeHttpClient
        .Post<ResultModel<any>>(
            this.settings.igrs_services_gl_url + "common/gl/downloadSuggestionUploadedDoc/"+docPath,
            docPath
          
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {
                    alert("not found");
                    return error;
                }
            )
        );
    }
    downloadSuggestionPdfDoc(docPath:string){
      //  let encVal = this._AESEncryptDecryptService.encryptInAngular(docPath);
        //this.settings.igrs_services_gl_url + "common/gl/downloadSuggestionUploadedDoc/"+encVal
        //window.open(this.settings.igrs_gateway_url + "common/gl/downloadSuggestionUploadedDoc/"+docPath);
        window.open(this.settings.igrs_gateway_url + "common/downloadSuggestionGlDoc/"+docPath);
    }
    
    
    getGlApprovalFlatRateDetails(param: any) {
        //
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/FlatRate/searchGlApprovalFlatRateDetails", param
            ).pipe(
                map((result: any) => {

                    //  

                    // return result.responseData;
                    console.log("-----------------result-----construction rate--------------- ", result);
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }


                })
            );
    }



    srUserDetailList(param: any) {


        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "department/GL/get_sr_user_Detail", param
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result;
                    }
                })
            );

    }



    get_glApprovalFlatRateById(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
               // this.settings.igrs_services_gl_url + "department/GL/get_gl_draft_construction", param
                this.settings.igrs_services_gl_url + "department/GL/FlatRate/getGlApprovalFlatRate", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    getUpdatedConsRate(updatedRateRes:any,updatedRateComm:any,isVishishtGram:boolean,demoghraphyId:number,startDate:any,endDate:any) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/fetchConsRateConfig?demographyId="+demoghraphyId+"&ratePlotResidential="+updatedRateRes+"&ratePlotCommercial="+updatedRateComm+"&startDate="+startDate+"&endDate="+endDate+"&isVishistGram="+isVishishtGram
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }
    getGuidelineValuesFixedPercentage(){
        var tempList=[{ id:"Fixed",name: "Fixed", nameEn: "Fixed", nameHi: "निश्चित" },
        { id:"Percentage",name: "Percentage", nameEn: "Percentage", nameHi: "प्रतिशत" },
        { id:"nosuggestion",name: "nosuggestion", nameEn: "No Suggestion", nameHi: "कोई सुझाव नहीं" }]
        return tempList;
    }

    getGuidelineValueChangeIncDecFix(){
        var tempList=[
            { id:"Increment",name: "Increment", nameEn: "Increase", nameHi: "बढ़ोतरी" },
            { id:"Decrement",name: "Decrement", nameEn: "Decrease", nameHi: "कमी" }
          ]
        return tempList;
    }

    getGuideLineDetailsbyLatLong(latlng:any) {
        return this.customeHttpClient.Get<ResultModel<any>>(
          this.settings.igrs_services_gis_url+"common/gis/getCurrentGLbyLatLong?lat="+latlng.lat+"&long="+latlng.lng
         ).pipe(
             map((result: any) => {
               
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
      }


      getGuidelineDataByDistrictIdFy(districtId:any) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url + "common/gl/view_guideline_district/"+districtId
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }

    printDownLoadGuidelineData(districtId:any,selecLang:string){
       window.open(this.settings.igrs_services_gl_url + "common/gl/get_print_pdf_download/"+districtId+"/"+selecLang, "_blank");
       return "";
    }

    printDownLoadDraftGuidelineData(fyYear:any,districtId:any,selecLang:string,userProfile:UserProfileModel){
      window.open(
        this.settings.igrs_services_gl_url + "common/gl/get_print_draft_gl_pdf_download/"+districtId+"/"+selecLang+"/"+userProfile.id+"/"+fyYear
      ,"_blank");
     }
     printDownLoadFinalGuidelineData(districtId:any,selecLang:string){
        window.open(this.settings.igrs_services_gl_url + "common/gl/get_print_final_gl_pdf_download/"+districtId+"/"+selecLang, "_blank");
    }

     //for print
  printGlDraftList(glDraftList: any) {
    // console.log(glDraftList);
     return this.customeHttpClient
         .Post<ResultModel<any>>(
             this.settings.igrs_services_gl_url + "common/gl/get_print_pdf" , glDraftList
         ).pipe(
             map((result: any) => {
               
                 if (result && result.responseStatus == 'true') {
                     return result.responseData;
                 } else {
                  return result.responseMessage;
                 }
             })
         );
 }


 printPdfListJasper(glPrintDataList: FinYearPrintViewGl[]) {
    // console.log(glDraftList);
    let headers = new HttpHeaders();
headers.append('Accept', 'application/pdf');
let requestOptions: any = { headers: headers, responseType: 'blob' };
     return this.customeHttpClient
         .Post<ResultModel<any>>(
             this.settings.igrs_services_gl_url + "common/gl/get_print_pdf", glPrintDataList,requestOptions
         ).pipe(
             map((result: any) => {
               
                return {
                    filename: 'dailyOrdersReport.pdf',
                    data: new Blob([result], {type: 'application/pdf'})
                };
             })
         );
 }

 loadRenameDemographyLocation(param: any){
    return this.customeHttpClient
    .GetApi<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/renameDemographyList", param
    ).pipe(
        map((result: any) => {

            //  

            // return result.responseData;
            // console.log("-----------------result-------------------- ",result);
            if (result && result.responseStatus == 'true') {
                return result.responseData;
            } else {
                return result;
            }


        })
    );
 }
 loadDraftDemographyLocation(param: any){
    return this.customeHttpClient
    .GetApi<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/draftDemographyList", param
    ).pipe(
        map((result: any) => {

            //  

            // return result.responseData;
             console.log("-----------------result-------------------- ",result);
          //  if (result && result.responseStatus == 'true') {
            //    return result.responseData;
           // } else {
                return result;
           // }


        })
    );
 }

 updateDemographyLocation(mstDemographyBean: any) {
    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/editDemographyLocation", mstDemographyBean
    ).pipe(
        map((result: any) => {

            return result;
        },
            (error: any) => {

                return error;
            })
    );
}
renameDemographyDraftBulkDetailsStatus(renameDemographyBean: RenameDemographyBean) {
    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/updateDemographydraftbulkupdate_by_ids", renameDemographyBean
    ).pipe(
        map((result: any) => {

            return result;
        },
            (error: any) => {

                return error;
            })
    );
}
finalDemographyApproveDetailsStatus(renameDemographyBean: RenameDemographyBean) {
    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/finalDemographybulkupdate_by_ids", renameDemographyBean
    ).pipe(
        map((result: any) => {

            return result;
        },
            (error: any) => {

                return error;
            })
    );
}

  getCountDemographyDraftCountByStatus(param:any){
    return this.customeHttpClient
    .GetApi<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/getCountAllDataForwardDraft", param
    ).pipe(
        map((result: any) => {

            //  

            // return result.responseData;
             console.log("-----------------result-------------------- ",result);
          //  if (result && result.responseStatus == 'true') {
            //    return result.responseData;
           // } else {
                return result;
           // }


        })
    );
  }
  getremarkDraftDemography(param:any){
    return this.customeHttpClient
    .GetApi<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "department/gis/getRemarkDraftDemography",param
    ).pipe(
        map((result: any) => {

            //  

            // return result.responseData;
             console.log("-----------------result-------------------- ",result);
          //  if (result && result.responseStatus == 'true') {
            //    return result.responseData;
           // } else {
                return result;
           // }


        })
    );
  }

}



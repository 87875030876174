import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import { Observable, from, throwError } from "rxjs";
import { of } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { AppSetting } from "./app.setting";

@Injectable({ providedIn: "root" })
export class AppSettingService {
  setting: AppSetting;
   origion :any=  location.origin;
  
  constructor(@Inject("environment") private envVar:any,private http: HttpClient) {
    this.setting = <AppSetting>envVar;
   //this.getSetting()
  // this.loadAppConfig();
  }
  getSetting(): Observable<AppSetting> {
     return of<AppSetting>(this.setting);
  }

 
getEnvironmentURL(): string {
  console.log(this.origion)
  switch (this.origion) {
    case 'http://localhost:4201':   return './config/config.json';
    case 'http://localhost:4200':   return './config/config.json';
    case 'http://10.115.96.80:85': return './config/config.json';
    case 'http://10.115.96.80': return  './config/config.json';
    case 'https://ersuat1.mp.gov.in': return  './config/config.json';
    case 'https://ersuat2.mp.gov.in': return './config/config.json';
    case 'http://ersuat1.mp.gov.in': return  './config/config.json';
    case 'http://ersuat2.mp.gov.in': return './config/config.json';
    default: throw Error('Unexpected base URL');
  }
}

getEnvironmentConfigData(data :any) {
   switch (this.origion) {

    case 'http://localhost:4201':   return data.dev;
    case 'http://localhost:4200':   return data.dev;
    case 'http://10.115.96.80:85': return data.developement;
    case 'http://10.115.96.80': return data.testing;
    case 'https://ersuat1.mp.gov.in': return data.audit;
    case 'https://ersuat2.mp.gov.in': return data.uat;
    case 'http://ersuat1.mp.gov.in': return data.audit;
    case 'http://ersuat2.mp.gov.in': return data.uat;
    default: throw Error('Unexpected base URL');
  }
}

 loadAppConfig() {
  const url = this.getEnvironmentURL() ?? './config/config.json';
   console.log(url);
   return this.http.get(url)
   .toPromise()
   .then(
   result => {
     if (result) {
       let data:any= result;
       let responsedata = this.getEnvironmentConfigData(data);
        this.setting = <AppSetting>responsedata;
   }
   });
 }
}

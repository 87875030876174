<form [formGroup]="pnrdForm" (ngSubmit)="onSubmit()">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label>{{changeLabel()}} <sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" aria-describedby="nagerPalikaPtr" name="propertyNo"
                    formControlName="propertyNo" [ngClass]="{ 'is-invalid': submitted && f.totalArea.errors }">
                <div *ngIf="submitted && f.propertyNo.errors" class="invalid-feedback">
                    <div *ngIf="f.propertyNo.errors.required">Property No is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>
                    {{'ereg-property-details.plotNo' | translate}} <sup class="text-danger">*</sup>
                </label>
                <input type="text" class="form-control" aria-describedby="" name="houseFlatNo"
                    formControlName="houseFlatNo" [ngClass]="{ 'is-invalid': submitted && f.houseFlatNo.errors }">
                <div *ngIf="submitted && f.houseFlatNo.errors" class="invalid-feedback">
                    <div *ngIf="f.houseFlatNo.errors.required">plot No is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">

                <label>{{'mannualProcess.landowner' | translate}} <sup class="text-danger">*</sup></label>
                <select class="form-control" id="landowner" formControlName="landOwnerShip"
                    [ngClass]="{ 'is-invalid': submitted && f.landOwnerShip.errors }">
                    <option value="" disabled>{{'mannualProcess.landownerPlacholder' | translate}}</option>
                    <option> {{'mannualProcess.private' | translate}} </option>
                    <option> {{'mannualProcess.Government' | translate}} </option>
                </select>
                <div *ngIf="submitted && f.landOwnerShip.errors" class="invalid-feedback">
                    <div *ngIf="f.landOwnerShip.errors.required">Land Ownership is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>
                    {{'mannualProcess.EnterTotalArea' | translate }}
                    <sup class="text-danger">*</sup>
                </label>
                <input type="text" class="form-control" aria-describedby="" name="totalArea" formControlName="totalArea"
                    [ngClass]="{ 'is-invalid': submitted && f.totalArea.errors }">
                <div *ngIf="submitted && f.totalArea.errors" class="invalid-feedback">
                    <div *ngIf="f.totalArea.errors.required">total area is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">

                <label>{{'mannualProcess.UnitType' | translate}} <sup class="text-danger">*</sup></label>
                <select class="form-control" id="landowner" formControlName="unit"
                    [ngClass]="{ 'is-invalid': submitted && f.unit.errors }">
                    <option value="" disabled>{{'mannualProcess.UnitTypeselect' | translate}}</option>
                    <option *ngFor="let unt of uomList | slice:0:2" [value]="unt">
                        <div *ngIf="lan==0">{{convertedUOM(unt)}}</div>
                        <div *ngIf="lan==1">{{convertedUOM(unt)}}</div>
                    </option>
                </select>
                <div *ngIf="submitted && f.unit.errors" class="invalid-feedback">
                    <div *ngIf="f.unit.errors.required">unit is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label class="d-block">
                    {{'valuationproperty.Building' | translate}} {{'property-valuation.Usage-Type' | translate}}

                    <sup class="text-danger">*</sup></label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="buildingType" formControlName="buildingType"
                        id="residentialRadio" value="residential">
                    <label class="form-check-label" for="residentialRadio">{{'valuationproperty.Residential' |
                        translate}}</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="buildingType" formControlName="buildingType"
                        id="commercialRadio" value="commercial">
                    <label class="form-check-label" for="commercialRadio">{{'valuationproperty.Commercial' |
                        translate}}</label>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <!-- <citizen-demography-hierarchy-load style="width: 100%;" [componentFlag]="'columnwise'" [type_id]="2" [parent_id]="1"
            [lastdemograpgy_id]="15" (loadgldata)="getDemography($event)"></citizen-demography-hierarchy-load> -->
            <div>
                <input type="hidden" class="form-control" aria-describedby="" name="demographyId"
                    formControlName="demographyId" [ngClass]="{ 'is-invalid': submitted && f.demographyId.errors }">
                <div *ngIf="submitted && f.demographyId.errors" class="invalid-feedback">
                    <div *ngIf="f.demographyId.errors.required">Demography is required</div>
                </div>
            </div>
        </div>
    </div>
</form>
import { Component, NgModule, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DeedCategoryBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-category-bean';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DeedTypeBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-type-bean';
import { EstampDetails } from 'projects/common-lib/src/lib/models/estamp.model/estamp-details';
import { EstampDeedTemplateContent } from 'projects/common-lib/src/lib/models/estamp.model/estampDeedTemplateContent';
import { CommunicationServiceService } from '../../duty-calculation/duty-service/communication-service.service';
import { DutyService } from '../../duty-calculation/duty-service/duty.service';
import { NgForm } from '@angular/forms';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { CommonServiceService } from 'projects/common-lib/src/lib/helper-lib/services/common/common-service.service';
import { DatePipe } from '@angular/common';
import { EstampCommonBean, EstampPartyModel } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';
import { EstampService } from '../../../service/estamp.service';
import {Location} from '@angular/common'; 

@Component({
  selector: 'app-estamp-content-updation',
  templateUrl: './estamp-content-updation.component.html',
  styleUrls: ['./estamp-content-updation.component.scss']
})


export class EstampContentUpdationComponent implements OnInit {
  estampDetailsModel:EstampDetails;
  deedCategoryBean: DeedCategoryBean;
  deedTypeBean: DeedTypeBean;
  deedInstrumentBean: DeedInstrumentBean;
  estampPartyDetails:EstampPartyModel[]=[];
  firstpartyList:EstampPartyModel[]=[];
  secondpartyList:EstampPartyModel[]=[];
  witnesspartyList:EstampPartyModel[]=[];
  estampPartyContentModel:EstampDeedTemplateContent=new EstampDeedTemplateContent();
  estampId:number;
  partyId:number;
  mobileNo:string;
  partyContent:string;
  partyStatus:any;
  acceptStatus:number;
  estampIdInput:string;
  userProfileModel : CitizenUserProfileDetails=new CitizenUserProfileDetails() ;
  pipe = new DatePipe('en-US');
  estampCreatedDate:string;
  public nextActionModelArray:any//NextActionModel[];
  public commentsModelArray:any //CommentsModel[] = [];
  serviceProvider:string;
  sPLicence:string;
  sroDetails:string
  partyType:number;
  partySubType:number
  estampContentStatus:number
  natureOfInstrument:string
  eregFlag:any;
  code:any;
  partyTypeNameEn:any;
  partyTypeNameHi:any;
  partyName:any;
  userId:any;
  deedAcceptLink:any;
  lan:number=1
 estampCommonBean: EstampCommonBean = new EstampCommonBean();
 estampPartyModel: EstampPartyModel = new EstampPartyModel();
  constructor(private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, 
    private translate: TranslateService, public dialog: MatDialog,private commServ: CommunicationServiceService,
    private location: Location,
    private customeHttpClient: CustomHttpClient,private estampService: EstampService,private dutyService:DutyService,private router: Router,private commonService:CommonServiceService,) {
      this.estampId=router.getCurrentNavigation().extras.state.data[0];
      this.mobileNo=router.getCurrentNavigation().extras.state.data[1];
      this.partyStatus=router.getCurrentNavigation().extras.state.data[2];
      this.partyType=router.getCurrentNavigation().extras.state.data[3];
      this.estampContentStatus=router.getCurrentNavigation().extras.state.data[4];
      this.natureOfInstrument=router.getCurrentNavigation().extras.state.data[5];
      this.partySubType =router.getCurrentNavigation().extras.state.data[6];
      this.eregFlag =router.getCurrentNavigation().extras.state.data[7];
      this.code =router.getCurrentNavigation().extras.state.data[8];
      this.partyTypeNameEn =router.getCurrentNavigation().extras.state.data[9];
      this.partyName =router.getCurrentNavigation().extras.state.data[10];
      this.userId =router.getCurrentNavigation().extras.state.data[11];
      this.deedAcceptLink =router.getCurrentNavigation().extras.state.data[12];
      this.partyTypeNameHi = router.getCurrentNavigation().extras.state.data[13];
      this.partyId = router.getCurrentNavigation().extras.state.data[14];
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
     }

  ngOnInit(): void {
    console.log("partyTypeNameHi::"+this.partyTypeNameHi+"  partyId::"+ this.partyId +" userId ::"+this.userId+" eregFlag::"+this.eregFlag)
    this.getPreviewOfSelectedEstamp(this.estampId);
    if(this.estampId!=null){
    this.estampIdInput=this.estampId.toString();
    }
  //  this.natureOfInstrument= sessionStorage.getItem("natureOfInstrumentId");
  //  this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
  }

  back(){
    this.location.back();
  }


  getPreviewOfSelectedEstamp(estampId:number){
this.ngxService.start();
    this.estampService.getEstampDetailsById(estampId).subscribe(res => {
      if (res.responseStatus == 'true') {
        this.ngxService.stop();
        this.estampDetailsModel=res.responseData;
        this.estampCommonBean.igrsEregEstampBean = res.responseData;
        this.estampCommonBean.igrsEregEstampBean.finalContent = res.responseData.finalContent;
        this.estampCreatedDate = this.pipe.transform(this.estampDetailsModel.createdDate,'dd-MM-yyyy hh-mm-ss');
        this.loadDeedInstrumentDetails(this.estampDetailsModel.categoryId,this.estampDetailsModel.deedTypeId,this.estampDetailsModel.instrumentId);
        this.loadPartyDetailsByEstampId(this.estampDetailsModel.id);
      }
      else
      {
        this.ngxService.stop();
      }
    });
  }


  loadDeedInstrumentDetails(categoryId:number,deedTypeId:number,instrId:number){
    this.ngxService.start();
    this.dutyService.getAllDeedInstrumentsByDeedTypeId(deedTypeId).subscribe(res=>{
      if(res.responseStatus=='true'){
        this.ngxService.stop();
        res.responseData.forEach((instObject:any) => {
        
          if(instObject.id==instrId && instObject.deedTypeId.id==deedTypeId && instObject.deedTypeId.deedCategoryId.id==categoryId){
           this.deedInstrumentBean=instObject;
           this.deedTypeBean=instObject.deedTypeId;
           this.deedCategoryBean=instObject.deedTypeId.deedCategoryId;
           delete this.deedInstrumentBean.deedTypeId;
           delete this.deedTypeBean.deedCategoryId;
          }
        });
      }
      else
      {
        this.ngxService.stop();
        console.log("ERROR:"+JSON.stringify(res));
      }
    })
  }

  loadPartyDetailsByEstampId(estampId:number){
    this.ngxService.start();
    this.loadCitizenWithServiceProvider(this.estampDetailsModel.createdBy);
    this.estampService.getEstampPartyDetailsByEstampId(estampId).subscribe(res=>{
      if(res.responseStatus=='true'){
        this.ngxService.stop();
        this.estampPartyDetails = res.responseData;
        this.estampCommonBean.igrsEstampPartyBeanList = res.responseData;
        if (this.estampPartyDetails[0].eregFlag!=null) {
          this.estampPartyDetails.forEach((item:EstampPartyModel)=>{
            //if(item.mobileNo==this.mobileNo && item.userId == this.userId)
            if(item.mobileNo==this.mobileNo && item.id == this.partyId)
            {
              this.partyId=item.id;
              this.estampPartyModel = item;
            }
            if(item.partySubTypeId===1){
               this.firstpartyList.push(item);
            }
            if(item.partySubTypeId===2){
              this.secondpartyList.push(item);
            }
            if(item.partySubTypeId===4){
              this.witnesspartyList.push(item);
            }
           })
        }else{

          this.estampPartyDetails.forEach((item: EstampPartyModel) => {
            //if(item.mobileNo==this.mobileNo && item.userId == this.userId)
          if(item.mobileNo==this.mobileNo && item.id == this.partyId)
          {
            this.partyId=item.id;
            this.estampPartyModel = item;
          }else if(item.mobileNo==this.mobileNo && (this.userId == null || this.userId == undefined || this.userId == ""))
          {
            this.partyId=item.id;
            this.estampPartyModel = item;
          }
          if(item.partySubTypeId===1){
             this.firstpartyList.push(item);
          }
          if(item.partySubTypeId===2){
            this.secondpartyList.push(item);
          }
          if(item.partySubTypeId===4){
            this.witnesspartyList.push(item);
          }
         })
      }
    }
      else
      {
        this.ngxService.stop();
        console.log("ERROR:"+JSON.stringify(res));
      }
    })
  }
  onSubmitPartyResponse(form:NgForm){
   
  }

  resetEstampAddPartyDetails(){

  }

  getNextAction() {
    // this.approvalRejectionService
    //   .getNextAction(this.actiontemplateId)
    //   .subscribe(
    //     (resp) => {
    //       console.log(resp);
    //       debugger
    //       if (resp && resp.status) {
      //     this.nextActionModelArray = resp.data;
    //       }
    //     },
    //     (err) => {
    //       console.log(JSON.stringify(err));
    //     }
    //   );
  }

  getCommentsHistoryByApplicationUniqueId() {
    // this.approvalRejectionService
    //   .getCommentsHistoryByApplicationUniqueId(this.applicationUniqueId)
    //   .subscribe(
    //     (resp) => {
    //       if (resp && resp.Status) {
    //         this.commentsModelArray = resp.Data;
    //       }
    //     },
    //     (err) => {
    //       console.log(JSON.stringify(err));
    //     }
    //   );
  }

  loadCitizenWithServiceProvider(citizenid:number){
    this.ngxService.start();
    this.estampService.getCitizenDetailsWithSp(citizenid).subscribe(res=>{
      if(res.responseStatus=='true'){
        this.ngxService.stop();
        this.userProfileModel = res.responseData;
        this.estampCommonBean.igrsCitizenUserBean = res.responseData;

        // if(res.responseData.serviceProvider!=undefined){
        //   // this.sroDetails=templateData.responseData.serviceProvider.officeBean.demography.demographyName
        //   this.sroDetails=res.responseData.serviceProvider.officeBean.officeName+" , "+res.responseData.serviceProvider.officeBean.officeAddress
        //  }else{
        //    this.sroDetails="NA"
        //  }

        if(res.responseData.serviceProvider!=undefined){
          // this.sroDetails=templateData.responseData.serviceProvider.officeBean.demography.demographyName
          if(res.responseData.serviceProvider.sPLicence!=undefined && res.responseData.serviceProvider.sPLicence!="undefined"){
            this.sPLicence="("+res.responseData.serviceProvider.sPLicence+")"
            this.sroDetails=res.responseData.serviceProvider.officeBean.officeName+" , "+res.responseData.serviceProvider.officeBean.officeAddress
          }else{
            this.sroDetails="NA"
            this.sPLicence=""
            
          }
         
         
         }else{
          this.sroDetails="NA"
          this.sPLicence=""
          
        }
       
       
        // this.estampPartyDetails=res.responseData;
        
      }
      else
      {
        this.ngxService.stop();
        console.log("ERROR:"+JSON.stringify(res));
      }
    })
  }
}

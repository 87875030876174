<lib-alertmessage class="allert-pv"></lib-alertmessage>

        <div class="row ">
            <div class="">
                   <div class="card filterArea border-curve1">
                 <div class="card-body p-1" >
                        <div class="moreFiler">
                            <div class="row">
                                <small class="alert alert-warning col-12 p-1 m-0 bdr3" role="alert">
                                    {{'The_sum_of_area-plot' | translate}}
                                </small>
                                <div class="col" *ngIf="!isPartialTransact; else isPartialTransactfalse" >
                                    <h3 class="text-primary mb-0">{{'property-valuation.Total-Area' | translate}}
                                        <span class="rstarcolor">*</span>
                                    </h3>
                                    <input #sourceInput type="text" 
                                        placeholder="{{'property-valuation.Total-Area' | translate}}"
                                        (input)="targetInput.value = sourceInput.value"
                                        maxlength="12" [(ngModel)]="propertyValuationRequestBean.totalArea"
                                        value="" appPositiveNumbersOnlyDirective
                                        TwoDigitDecimalNumber
                                        appAlphaNumericSpace
                                        #TotalArea="ngModel"
                                        [appCopyPastValidation]="true"
                                        (keyup)="inputChangeEventValidation()"
                                        class="form-control form-control-sm" >
                                    <div *ngIf="totalAreaMsg &&!propertyValuationRequestBean.totalArea"
                                        class="custom-invalid-feedback">
                                        <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>
                                    </div>
                                </div>
                                <ng-template #isPartialTransactfalse>
                                <div class="col" >
                                    <h3 class="text-primary mb-0">{{'property-valuation.Total-Area' | translate}}
                                        <span class="rstarcolor">*</span>
                                    </h3>
                                    <input type="text" readonly
                                        placeholder="{{'property-valuation.Total-Area' | translate}}"
                                        maxlength="12" [(ngModel)]="propertyValuationRequestBean.overAllArea"
                                         appPositiveNumbersOnlyDirective
                                         TwoDigitDecimalNumber
                                        appAlphaNumericSpace
                                        [appCopyPastValidation]="true"
                                        class="form-control form-control-sm" >
                                    <div *ngIf="totalAreaMsg &&!propertyValuationRequestBean.overAllArea"
                                        class="custom-invalid-feedback">
                                        <div *ngIf="totalAreaMsg">{{'mandatory' | translate}}</div>
                                    </div>
                                </div>
                              
                            </ng-template>
                            
                                <div class="col">
                                    <h3 class="text-primary mb-0">{{'property-valuation.Unit' | translate}}</h3>
                                    <select class="form-control form-control-sm"
                                        [(ngModel)]="propertyValuationRequestBean.unit" #unit="ngModel">
                                        <option *ngFor="let unt of uomList | slice:0:2" [value]="unt">
                                            <div *ngIf="lan==0">{{convertedUOM(unt)}}</div>
                                            <div *ngIf="lan==1">{{convertedUOM(unt)}}</div>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="moreFiler">
                        <div class="row">
                        <ng-container class="col-md-12">
                            <label class="col-md-12 mt-3" style="font-size: 90%;">
                                <input type="checkbox" name="isPartialTransact"
                                    [(ngModel)]="propertyValuationRequestBean.isPartialTransact"
                                    [disabled]="agriPropertyValuationRequestBean.isPartialTransact"
                                    (change)="onCheckDataPush($event)"
                                    value=true class="w-auto h-10"
                                    placeholder="{{'property-valuation.partial-Area-received' | translate}}">
                                    {{'property-valuation.partial-Area-received-building' |translate}}
                            </label>
                            <div class="col-md-12 mt-2">
                                <label class="w-100">{{'valuationproperty.TransactArea' | translate}}</label>

                             <div class="col-md-6 pl-0">
                                <input #targetInput type="text" name="partialTransactArea"
                                [disabled]="!propertyValuationRequestBean.isPartialTransact"
                                [(ngModel)]="propertyValuationRequestBean.partialTransactArea"
                                appPositiveNumbersOnlyDirective
                                TwoDigitDecimalNumber
                                
                                (ngModelChange)="validationLessThan($event)"
                                class="form-control"
                                placeholder="{{'valuationproperty.TransactArea' | translate}}">
                             </div>
                            </div>
                        </ng-container>
                        <div class="p-2" *ngIf="isContinueButton&&totalAreaInHectare">
                            <p>{{propertyValuationRequestBean.partialTransactArea}} square meters is equal to {{ totalAreaInHectare }} hectares.</p>
                        </div>
                    </div>
                </div>
                      

                        <div class="moreFiler">
                            <h3 class="text-primary mb-0">{{'property-valuation.Usage-Type' | translate}}<span
                                class="rstarcolor">*</span></h3>
                            <div class="">
                                <div class="btn-group btn-group-toggle d-block" data-toggle="buttons">
                                        <div class="d-flex px-2 pt-1 mb-2"  >
                                            <div class="col-md-6 p-0 line-height30">
                                            {{'property-valuation.Residential-Cum-Commercial' | translate}}</div>
                                            <div class="col-md-6">
                                            <label class="btn btn-sm rounded-0 mb-0 bg-none color-dark p-0 ml-2 ">
                                                <input type="radio" name="usage1" class="w-auto h-auto"
                                                [(ngModel)]="this.propertyValuationRequestBean.isResidentialCumCommercial"
                                                    (ngModelChange)="userTypeEvent($event)" [value]="true"
                                                    id="RCumCom1"> {{'property-valuation.Yes' | translate}}
                                            </label>
                                            <label class="btn btn-sm rounded-0 mb-0 bg-none color-dark p-0 ml-2"
                                               >
                                                <input type="radio" name="usage2" class="w-auto h-auto"
                                                [(ngModel)]="this.propertyValuationRequestBean.isResidentialCumCommercial"
                                                    (ngModelChange)="userTypeEvent($event)" [value]="false" id="RCumCom2">
                                                {{'property-valuation.No' | translate}}
                                            </label>
                                        </div>
                                        </div>
                                        <input type="text" TwoDigitDecimalNumber [lenght]="2"
                                            [(ngModel)]="propertyValuationRequestBean.residentialCumCommercialPlotArea"
                                            *ngIf="usageType=='RCumCom'" appPositiveNumbersOnlyDirective
                                            
                                            [appCopyPastValidation]="true" maxlength="12"
                                            id="residentialCumCommercialPlotArea"
                                            placeholder="{{'property-valuation.Residential-Cum-Commercial-area' | translate}}"
                                            (keyup)="inputChangeEventValidation()"
                                            class="form-control form-control-sm">
                                        <div *ngIf="usageType!='RCumCom'" class="row no-check-box">
                                            <div class="col-md-3">
                                                <label class="btn btn-sm rounded-0 mb-1">
                                                    <input type="checkbox" name="usage" value="residensial"
                                                        id="residensial"> {{'property-valuation.Residential'
                                                    | translate}}
                                                </label>
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" TwoDigitDecimalNumber appPositiveNumbersOnlyDirective
                                                    [appCopyPastValidation]="true" maxlength="12"
                                                    id="residentialPlotArea"
                                                    (keyup)="usageTypeEventPlot('residentialPlotArea')"
                                                    [(ngModel)]="propertyValuationRequestBean.residentialPlotArea"
                                                    placeholder="{{'property-valuation.Residential-area' | translate}}"
                                                    class="form-control form-control-sm">
                                            </div>
                                            <div class="col-md-3">
                                                <label class="btn btn-sm rounded-0 mb-1">
                                                    <input type="checkbox" name="commercial"
                                                        value="commercial"
                                                        id="commercial">{{'property-valuation.Commercial' |
                                                    translate}}
                                                </label>
                                            </div>
                                            <div class="col-md-3 ">
                                                <input type="text" appPositiveNumbersOnlyDirective
                                                TwoDigitDecimalNumber
                                                    [appCopyPastValidation]="true" maxlength="12"
                                                    id="commercialPlotArea"
                                                    (keyup)="usageTypeEventPlot('commercialPlotArea')"
                                                    [(ngModel)]="propertyValuationRequestBean.commercialPlotArea"
                                                    placeholder="{{'property-valuation.commercial-area' | translate}}"
                                                    class="form-control form-control-sm">
                                            </div>

                                            <div class="col-md-3">
                                                <label class="btn btn-sm rounded-0 mb-1">
                                                    <input type="checkbox" name="industrial"
                                                        value="industrial" id="industrial">
                                                    {{'property-valuation.Industrial' | translate}}
                                                </label>
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" appPositiveNumbersOnlyDirective
                                                TwoDigitDecimalNumber
                                                    [appCopyPastValidation]="true" maxlength="12"
                                                    id="industrialPlotArea"
                                                    (keyup)="usageTypeEventPlot('industrialPlotArea')"
                                                    [(ngModel)]="propertyValuationRequestBean.industrialPlotArea"
                                                    placeholder="{{'property-valuation.Industrial-area' | translate}}"
                                                    class="form-control form-control-sm">
                                            </div>
                                            <div class="col-md-3">
                                                <label class="btn btn-sm rounded-0 mb-1">
                                                    <input type="checkbox" name="health" value="health"
                                                        id="health">
                                                    {{'property-valuation.Health' | translate}}
                                                </label>
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" appPositiveNumbersOnlyDirective
                                                TwoDigitDecimalNumber
                                                    [appCopyPastValidation]="true" maxlength="12"
                                                    id="healthPlotArea"
                                                    (keyup)="usageTypeEventPlot('healthPlotArea')"
                                                    [(ngModel)]="propertyValuationRequestBean.healthPlotArea"
                                                    placeholder="{{'property-valuation.Health-area' | translate}}"
                                                    class="form-control form-control-sm">
                                            </div>
                                            <div class="col-md-3">
                                                <label class="btn btn-sm rounded-0 mb-1">
                                                    <input type="checkbox" name="education"
                                                        value="education" id="education">
                                                    {{'property-valuation.Education' | translate}}
                                                </label>
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" appPositiveNumbersOnlyDirective
                                                TwoDigitDecimalNumber
                                                    [appCopyPastValidation]="true" maxlength="12"
                                                    id="eduPlotArea"
                                                    (keyup)="usageTypeEventPlot('eduPlotArea')"
                                                    [(ngModel)]="propertyValuationRequestBean.eduPlotArea"
                                                    placeholder="{{'property-valuation.Education-area' | translate}}"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="m-2">
                            <div class="moreFiler"
                                *ngIf="propertyValuationRequestBean?.eduPlotArea">
                                <h3 class="text-primary mb-0"> {{'property-valuation.Usage' | translate}} <span
                                    class="rstarcolor">*</span></h3>
                                <div class="d-flex">
                                    <div class="btn-group bdrone" data-toggle="buttons">
                                        <label class="btn btn-sm p-0 py-1 px-1 mr-1"
                                            [ngClass]="{ 'active': propertyValuationRequestBean.eduType=='school'}">
                                            <input type="radio"
                                                [(ngModel)]="propertyValuationRequestBean.eduType"
                                                value="school" name="school" id="school" class="h-75">
                                            {{'property-valuation.School' | translate}}
                                        </label>
                                        <label class="btn btn-sm p-0 py-1 px-1 mr-1">
                                            <input type="radio" name="usage"
                                                [(ngModel)]="propertyValuationRequestBean.eduType"
                                                value="collageuniversity" name id="collageuniversity" class="h-75">
                                            {{'property-valuation.Collage/University' | translate}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                      
                        </div>
                     
                        <div class="moreFiler subclauses m-2 px-1">
                            <h3 class="text-primary mb-0">{{'property-valuation.Subclause' | translate}}</h3>
                            <div *ngFor="let sbc of subClauseMasterBean">
                                <div class="form-check" *ngIf="mainAreaType==sbc.propertyTypeId">
                                        <div class="form-check">
                                        <label class="form-check-label" for="subclause{{sbc.id}}">
                                            <input class="form-check-input" type="checkbox" [(ngModel)]="sbc.checked" [disabled]="sbc.disabled"
                                            (click)="addSubclauseEvent($event,sbc)" value="{{sbc}}"
                                                id="subclause{{sbc.id}}">
                                            {{lan==0?sbc.descEn:sbc.descHi}}
                                        </label>
                                    </div>
                                </div>
                               
                            </div>

                        </div>
                       
                        <button mat-flat-button class="btn btn-primary m-2"
                         (click)="showMarketValueEvent()">{{'property-valuation.Show-Market-Value' |
                            translate}}</button>
                     <!-- <button *ngIf="isContinueButton" mat-flat-button class="btn btn-primary m-2"
                       (click)="pushPlotDataMoveAgriCulture()">{{'valuationproperty.contin' | translate}}</button>   -->

                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 pr-0" >

                
        </div> -->


<ngx-ui-loader [loaderId]="'Plot-01'"></ngx-ui-loader>

<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>
           {{'detailsPages.ViewGLDetail' | translate}}
        </h2>
        <!-- <button mat-button class="btn btn-primary" id="sticky_Div1">
           {{'detailsPages.processForViewGLDetail' | translate}}
        </button> -->
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
        <div class="row">
          <div class="col-md-6">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel
                [expanded]="step === 0"
                (opened)="setStep(0)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      What is the Guideline Value of Property ?
                    </div>
                    <div *ngIf="lan == 1">
                      संपत्ति का गाइड लाइन मूल्य क्या है?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The guideline value of a property is the minimum value at
                      which the property can be registered in government
                      records. This value is determined by the government based
                      on various factors such as location, size, amenities, and
                      market conditions. The guideline value is used to
                      calculate stamp duty and registration charges during
                      property transactions to ensure fair valuation and prevent
                      underreporting of property prices.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति का गाइडलाइन मूल्य वह न्यूनतम मूल्य है जिस पर
                      संपत्ति को सरकारी रिकॉर्ड में पंजीकृत किया जा सकता है। यह
                      मूल्य स्थान, आकार, सुविधाओं और बाजार स्थितियों जैसे
                      विभिन्न कारकों के आधार पर सरकार द्वारा निर्धारित किया जाता
                      है। उचित मूल्यांकन सुनिश्चित करने और संपत्ति की कीमतों की
                      कम रिपोर्टिंग को रोकने के लिए संपत्ति लेनदेन के दौरान
                      स्टांप ड्यूटी और पंजीकरण शुल्क की गणना करने के लिए
                      गाइडलाइन मूल्य का उपयोग किया जाता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 1"
                (opened)="setStep(1)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      What is the published Guideline ?
                    </div>
                    <div *ngIf="lan == 1">प्रकाशित गाइडलाइन क्या है ?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The published guideline for a property refers to the
                      minimum value or rate at which properties in a particular
                      area or locality will be registered
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति के लिए प्रकाशित गाइडलाइन उस न्यूनतम मूल्य या दर को
                      संदर्भित करता है जिस पर किसी विशेष क्षेत्र या इलाके में
                      संपत्तियों को पंजीकृत किया जाएगा।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 2"
                (opened)="setStep(2)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">What is the Draft Guideline ?</div>
                    <div *ngIf="lan == 1">ड्राफ्ट गाइडलाइन क्या है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      The Draft Guideline Rate of a property refers to the
                      proposed minimum value at which a property should be
                      registered according to government guidelines.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति की ड्राफ्ट गाइडलाइन दर प्रस्तावित न्यूनतम मूल्य को
                      संदर्भित करती है जिस पर एक संपत्ति को सरकारी गाइडलाइन के
                      अनुसार पंजीकृत किया जायेगा ।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 3"
                (opened)="setStep(3)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Who veiw the Guideline ?</div>
                    <div *ngIf="lan == 1">गाइडलाइन कौन देखता है?</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      In Sampada 2.0, both registered and unregistered users can
                      view the Draft Guideline, Published Guideline, and their
                      rates through the portal.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      सम्पदा 2.0 में, पंजीकृत और अपंजीकृत दोनों प्रकार के
                      उपयोगकर्ता पोर्टल के माध्यम से ड्राफ्ट गाइडलाइन , प्रकाशित
                      गाइडलाइन और उसकी दरें आसानी से देख सकते हैं।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 4"
                (opened)="setStep(4)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      Who can post their suggestions or feedback for the draft
                      guideline?
                    </div>
                    <div *ngIf="lan == 1">
                      ड्राफ्ट गाइडलाइन के लिए कौन अपने सुझाव या प्रतिक्रिया
                      पोस्ट कर सकता है?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      In Sampada 2.0, both registered and unregistered users can
                      give their suggestions or feedback on Draft Guideline
                      through the portal.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपदा 2.0 में पंजीकृत और अपंजीकृत दोनों प्रकार के
                      उपयोगकर्ता पोर्टल के माध्यम से ड्राफ्ट गाइडलाइन पर अपने
                      सुझाव या प्रतिक्रिया दे सकते हैं।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 5"
                (opened)="setStep(5)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      Pre-requisite to view the Guidelines and their rate.
                    </div>
                    <div *ngIf="lan == 1">
                      गाइडलाइन और उनकी दर देखने की शर्त।
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      To view guidelines and their rates, below are the
                      prerequisites:
                    </p>
                    <ol>
                      <li>
                        Generally, there are no prerequisites to view the
                        guidelines and their rates. The person should possess
                        knowledge about the location of the guideline they are
                        willing to view.
                      </li>
                    </ol>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      गाइडलाइन और उनकी दरें देखने के लिए, नीचे आवश्यक शर्तें दी
                      गई हैं:
                    </p>
                    <ol>
                      <li>
                        आम तौर पर, गाइडलाइन और उनकी दरों को देखने के लिए कोई
                        पूर्वापेक्षाएँ नहीं हैं। व्यक्ति को उस गाइडलाइन के स्थान
                        के बारे में जानकारी होनी चाहिए जिसे वे देखना चाहते हैं।
                      </li>
                    </ol>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                [expanded]="step === 6"
                (opened)="setStep(6)"
                hideToggle
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      Process to View Draft Guideline, Published Guideline and
                      their rates ?
                    </div>
                    <div *ngIf="lan == 1">
                      ड्राफ्ट गाइडलाइन , प्रकाशित गाइडलाइन और उसकी दर देखने की
                      प्रक्रिया?
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      To view guidelines and their rates follow below steps :
                    </p>
                    <ul>
                      <li>Access the SAMPADA 2.0 portal.</li>
                      <li>Click the View Guideline button</li>
                      <li>
                        Select an option to view guidelines such as draft
                        guidelines, published guidelines, or guideline rates
                      </li>
                      <li>
                        Draft Guideline : Click on the Draft Guideline tab. And
                        Select the Ward/ Village Colony using the following
                        dropdowns: Financial Year, District, District Office,
                        SRO Office, Area Type, Local Body / Village and finally
                        Ward / Village Colony. Click on the Search button.
                        System will display details for the draft guideline.
                        Users can view and download the guideline locations and
                        their rate details. By clicking the 'Post' button, users
                        can also submit their suggestions for this draft
                        guideline
                      </li>
                      <li>
                        Published guidelines for current financial year : Click
                        on the name of the district displayed on the screen.
                        System will download the PDF of the Guideline Location
                        for the clicked district. Users can open and view it.
                      </li>
                      <li>
                        Guideline Rates for current financial year : Click on
                        the 'View Guideline Rates' tab. Either select the
                        guideline location demography thorugh drop down : Zone,
                        Distrcit, SRO, Area Typ, Local Body/ tehsil, Ward /
                        Village Colony or pin the guideline location on the map.
                        Then click on the displayed button 'View Guideline
                        Rate'. System will display the applicable guideline rate
                        for this guideline location.
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      गाइडलाइन और उनकी दरें देखने के लिए नीचे दिए गए चरणों का
                      पालन करें:
                    </p>
                    <ul>
                      <li>संपदा 2.0 पोर्टल को ओपन करे।</li>
                      <li>व्यू गाइड लाइन बटन पर क्लिक करे।</li>
                      <li>
                        ड्राफ्ट गाइडलाइन , प्रकाशित गाइडलाइन, या व्यू गाइडलाइन
                        दरें में से एक विकल्प चुनें ।
                      </li>
                      <li>
                        ड्राफ्ट गाइडलाइन : ड्राफ्ट गाइडलाइन टैब पर क्लिक करें।
                        निम्नलिखित ड्रॉपडाउन का उपयोग करके वार्ड/ग्राम कॉलोनी का
                        चयन करें: वित्तीय वर्ष, जिला, जिला कार्यालय, उप पंजीयक कार्यालय, क्षेत्र का प्रकार, स्थानीय निकाय/गांव और अंत
                        में वार्ड/ग्राम कॉलोनी। और सर्च बटन पर क्लिक करें ।
                        सिस्टम ड्राफ्ट गाइड लाइन का विवरण प्रदर्शित करेगा।
                        उपयोगकर्ता गाइडलाइन लोकेशन और उनके दर विवरण को देख और
                        डाउनलोड कर सकते हैं। 'पोस्ट' बटन पर क्लिक करके,
                        उपयोगकर्ता इस ड्राफ्ट गाइडलाइन लोकेशन के लिए अपने सुझाव
                        भी प्रस्तुत कर सकते हैं ।
                      </li>
                      <li>
                        चालू वित्तीय वर्ष की प्रकाशित गाइडलाइन : स्क्रीन पर
                        प्रदर्शित जिले के नाम पर क्लिक करें । सिस्टम क्लिक किए
                        गए जिले की गाइड लोकेशनस की पीडीएफ डाउनलोड करेगा।
                        उपयोगकर्ता इसे खोलकर देख सकते हैं
                      </li>
                      <li>
                        चालू वित्तीय वर्ष की गाइडलाइन की दरें : 'गाइडलाइन दरें
                        देखें' टैब पर क्लिक करें। या तो उपयोगकर्ता ड्रॉप डाउन के
                        माध्यम से गाइडलाइन लोकेशन डेमोग्राफी का चयन करें: जोन,
                        जिला, उप पंजीयक कार्यालय, क्षेत्र का प्रकार, स्थानीय निकाय / तहसील,
                        वार्ड / ग्राम कॉलोनी या डायरेक्ट मानचित्र पर गाइड लाइन
                        लोकेशन को पिन करें। फिर 'दिशानिर्देश दर देखें' बटन पर
                        क्लिक करें। सिस्टम इस गाइडलाइन लोकेशन की गाइडलाइन दरे
                        प्रदर्शित करेगा
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="col-md-6">
            <div class="process_img">
              <img
                src="assets/images/VGL-English.png"
                alt="EStampLifeCycle"
                style="width: 100%"
                *ngIf="lan == 0"
              />
              <img
                src="assets/images/VGL-Hindi.png"
                alt="EStampLifeCycle"
                style="width: 100%"
                *ngIf="lan == 1"
              />
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

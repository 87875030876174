<div mat-dialog-content>
   <div  mat-dialog-action>
       <button *ngIf="cancellationFlag"  mat-raised-button (click)="closeMe()" class="duty-close-modala-btn">
         <!-- {{ 'duty-calculation.close' | translate }} -->
         <em class="fa fa-times"></em>
      </button>
   </div>
</div>
<div class="PropertyValuation ">
   <div class="innerHeader">
      <div class="container">
         <div class="row pt-3">
            <div class="col-md-12">
               <h2> {{ 'duty-calculation.duty_cal_Hd' | translate }}</h2>
               <ol class="breadcrumb">
                  <li><a href="#">{{'property-valuation.property-valuation-home' | translate}}</a></li>
                  <li class="active">{{ 'duty-calculation.duty_cal_Hd' | translate }}</li>
               </ol>
            </div>
         </div>
      </div>
      <div class="innerHeader-image text-right btm-9">
         <!-- <img src="assets/images/calculation-duty.png" alt="image" style="width: 43%;">-->
      </div>
   </div>
   <div class="PropertyValuationInnerBody">

    <div class="container">
         <app-instrument-search [fromEregistryModule]="false" #instrumentSearchComponent (instMappingEvent)="sendDutyFieldsDetails($event)"
            (showDeedEvent)="isCalculationDuty($event)"></app-instrument-search>
   </div>
      <div class="mt-2" *ngIf="cancellationFlag">
    <div class="row justify-content-center">
         <div class="col-lg-8 col-md-8">
            <div class="card">
               <div class="card-body">
                  <table class="w-100 table shadow1 m-0">
                     <tr>
                        <th scope="col" class="p-2">{{'duty-calculation.Deed-Catagory' | translate }}</th>
                        <td class="p-2"> {{lan==0?cancellationInstBean.deedTypeId.deedCategoryId.deedCategoryNameEn:cancellationInstBean.deedTypeId.deedCategoryId.deedCategoryNameHi}}</td>
                     </tr>
                     <tr>
                        <th scope="col" class="p-2">{{'duty-calculation.Deed-Type' | translate }}</th>
                        <td class="p-2">{{lan==0?cancellationInstBean.deedTypeId.deedTypeNameEn:cancellationInstBean.deedTypeId.deedTypeNameHi}} </td>
                     </tr>
                     <tr>
                        <th scope="col" class="p-2">{{'duty-calculation.inst_name' | translate }}</th>
                        <td class="p-2">{{lan==0?cancellationInstBean.instrumentNameEn:cancellationInstBean.instrumentNameHi}}</td>
                     </tr>
               </table>
               </div>
             </div>
         </div>
        </div>


      </div>
      <div class="container using-eRegistry" *ngIf="calculationduty" id="print-fullWindow">
         <div class="row justify-content-center">

            <div class="col-lg-3 mt-2">
               <fieldset class="detail detail-left legend-section">
                  <legend for="DutyCalculation" class="sidebar__widget-title"> {{ 'duty-calculation.Inst_desc_duty_detls' | translate }}

                  </legend>
                  <form novalidate="" class="">
                     <div class="card12">
                        <div class="selected">
                           <div class="row">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                 <div class="div-divider" *ngIf="deedInstrumentSelected.deedTypeId!=null">
                                    <h3 class="heaading-sub">{{ 'duty-calculation.Deed-Catagory' | translate }}</h3>


                                    <p>
                                       {{lan==0?deedInstrumentSelected.deedTypeId.deedCategoryId.deedCategoryNameEn:deedInstrumentSelected.deedTypeId.deedCategoryId.deedCategoryNameHi}}
                                    </p>

                                 </div>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                 <div class="div-divider" *ngIf="deedInstrumentSelected.deedTypeId!=null">

                                    <h3 class="heaading-sub">{{ 'duty-calculation.Deed-Type' | translate }}</h3>




                                    <p for="RevenueMajorHead">
                                       {{lan==0?deedInstrumentSelected.deedTypeId.deedTypeNameEn:deedInstrumentSelected.deedTypeId.deedTypeNameHi}}
                                    </p>
                                    <h3 class="heaading-sub light-bor"><em>{{ 'duty-calculation.deed_desc' | translate }}</em></h3>


                                    <app-show-more [lan]="lan">
                                       <p>{{lan==0?deedInstrumentSelected.deedTypeId.deedTypeDescEn:deedInstrumentSelected.deedTypeId.deedTypeDescHi}}

                                       </p>
                                    </app-show-more>



                                 </div>
                              </div>



                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                 <div class="div-divider " *ngIf="deedInstrumentSelected.deedTypeId!=null">
                                    <h3 class="heaading-sub">{{ 'duty-calculation.Instrument' | translate }}</h3>
                                    <p for="RevenueSubMajorHead">
                                       {{lan==0?deedInstrumentSelected.instrumentNameEn:deedInstrumentSelected.instrumentNameHi}}
                                    </p>
                                    <h3 class="heaading-sub mt-2 light-bor"><em>{{ 'duty-calculation.inst_desc' | translate }}</em>
                                    </h3>
                                    <app-show-more [lan]="lan">
                                    <p>{{lan==0?deedInstrumentSelected.instrumentDescEn:deedInstrumentSelected.instrumentDescHi}}

                                    </p>
                                 </app-show-more>

                                 </div>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                 <div class="div-divider divider-last">
                                    <button mat-raised-button (click)="openDialog('3000ms', '1500ms')"
                                       class="btn btn-primary">{{ 'duty-calculation.show_duty_detls' | translate }}</button>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </form>
               </fieldset>


               <fieldset class="detail detail-left" style="display:none;">
                  <legend class="sidebar__widget-title">{{ 'duty-calculation.sel_Inst_Dtls' | translate }}

                  </legend>
                  <div class="card12 mb-3 shadow1">

                     <div class="panelbodycontent">


                        <form>
                           <div class="div-divider">
                              <div class="table-responsive">
                                 <table class="table table-bordered border-primary">
                                    <tr>
                                       <th scope="col"> <label class="">{{ 'duty-calculation.Total_Payable_StampAndReg'
                                             |
                                             translate }}</label></th>
                                       <td>{{dutyCalculationData.totalPayableStampAndRegPer}}</td>
                                    </tr>
                                    <tr>
                                       <th scope="col"> <label class="">{{ 'duty-calculation.Total_Payable_Stamp_Duty' |
                                             translate }}</label></th>
                                       <td>{{dutyCalculationData.totalPayableStampDutyPer}}</td>
                                    </tr>
                                    <tr>
                                       <th scope="col"> <label class="">{{ 'duty-calculation.Total_Payable_RegFee' |
                                             translate
                                             }}</label></th>
                                       <td>{{dutyCalculationData.totalPayableRegFeePer}}</td>
                                    </tr>
                                 </table>
                              </div>
                           </div>

                        </form>
                        <!---->
                        <!---->
                     </div>

                  </div>
               </fieldset>



            </div>
            <div class="col-lg-9 p-1 pt-0 mt-1" *ngIf="calculationduty">

               <app-calculate-duty (dutyCalculationResponseBeanEvent)="getCalRespBean($event)"
                  [instMappingBeanList]="instMappingBeanListParent" [instrumentBean]="selInstrumentBean"
                  [displayPropDiv]="displayPropDivParent" *ngIf="calculationduty"></app-calculate-duty>

               <!-- <app-mortgage *ngIf="mortgage"></app-mortgage>
               <app-sale-assignment *ngIf="sales"></app-sale-assignment>
               <app-will-deed *ngIf="willdeed"></app-will-deed>
               <app-lease-deed *ngIf="leasedeed"></app-lease-deed>
               <app-gift-deed *ngIf="giftdeed"></app-gift-deed> -->

            </div>
         </div>
      </div>
   </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
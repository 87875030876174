export class ResidentialConstructionType{
    totalTypeArea:number|string;
	rccArea:number|string;
	rbcArea:number|string;
	tinShadeArea:number|string;
	kacchaKabeluArea:number|string;
	 rccRate:string
	 rbcRate:string
	 tinShadeRate:string
	 kacchaKabeluRate:string
}
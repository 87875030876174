<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'e-stamp.E-stamp-Details' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-box">
                    <h3 class="card-title mr-2">{{'e-stamp.E-stamp-Details' | translate}}</h3>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Estamp Code </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.estampCode}}</label>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Total E-Stamp Amount </label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.totalDuty}}</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Govt. Stamp Duty</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.principalStampDuty| amount}}</label>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Municipal Duty</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.municipalDuty| amount}}</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Janpad Duty</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.janpadDuty| amount}}</label>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Upkar Amount</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.upkarDuty| amount}}</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Exempted Amount</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>0</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>E-Stamp Type</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Non-Judicial</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label> Issue Date and Time</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{estampDetailsModel?.createdDate | date:'medium'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Service Provider or Issuer Details</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{userProfileModel?.firstName}} {{userProfileModel?.lastName}} {{sPLicence}}</label>
                                    <!-- <label *ngIf="userProfileModel.serviceProvider && userProfileModel.serviceProvider.sPLicence && userProfileModel.serviceProvider.sPLicence!='undefined'"> ({{userProfileModel.serviceProvider.sPLicence}})</label></label>   -->

                                </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>SP/SRO/DRO/HO Details</label>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{sroDetails}}</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="firstpartyList.length!=0 ">
                    <h3 class="card-title mr-2">First Party Details</h3>
                    <table class="table table-bordered mb-4">
                        <tr>
                            <th>S.No.</th>
                            <th>Organization / Party Name</th>
                            <th>Contact Number</th>
                        </tr>
                        <tr *ngFor="let item of firstpartyList; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.partyName}}</td>
                            <td>{{item.mobileNo}}</td>
                        </tr>
                    </table>
                    </div>
                    <br>
                    <div *ngIf="secondpartyList.length!=0 ">
                    <h3 class="card-title mr-2">Second Party Details</h3>
                    <table class="table table-bordered">
                        <tr>
                            <th>S.No.</th>
                            <th>Organization / Party Name</th>
                            <th>Contact Number</th>
                        </tr>
                        <tr *ngFor="let item of secondpartyList; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{item.partyName}}</td>
                            <td>{{item.mobileNo}}</td>
                        </tr>
                    </table>
                    </div>
                    <br>
                    <div *ngIf="witnesspartyList.length!=0 ">
                        <h3 class="card-title mr-2">Witness Details</h3>
                        <table class="table table-bordered">
                            <tr>
                                <th>S.No.</th>
                                <th>Organization / Party Name</th>
                                <th>Contact Number</th>
                            </tr>
                            <tr *ngFor="let item of witnesspartyList; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{item.partyName}}</td>
                                <td>{{item.mobileNo}}</td>
                            </tr>
                        </table>
                        <br>
                    </div>
                    <br>
                    <h3 class="card-title mr-2">Affidavit cum Declaration</h3>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div [innerHTML]="estampDetailsModel?.finalContent"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>

<div class="fid-header"></div>
<div class="container mt-3">

    <!-- <h2 class="loginTitle">{{'citizen-registration-details.sign-up-as-legal' | translate}} </h2> -->
    <div class="registrationSteps">
        <div class="steps ">
            <div class="stepBox ">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-sign-in"></i>
                    <p>{{'login' | translate}}</p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>

        <div class="steps mx-3">
            <div class="stepBox">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-list-alt"></i>
                    <p> {{'e-stamp.deed-List' | translate}} </p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>
        <div class="steps mx-3 active">
            <div class="stepBox">
                <div class="d-flex  align-items-center">
                    <i class="stepIcon fa fa-pencil-square-o"></i>
                    <p> {{'e-stamp.deed-content-view-validate' | translate}} </p>
                </div>
            </div>
            <i class="fa fa-caret-right stepforword"></i>
        </div>
    </div>
    <lib-alertmessage></lib-alertmessage>
    <mat-card class="example-card mb-3">
        <mat-card-title> </mat-card-title>
<mat-card-content class="mat-card-content">
    <!--onSubmitForEstampPartyDetails(f) estampAddPartyDetails f.form.valid &&-->
    <div class="row justify-content-center m-0">

        <div class="col-md-12 ">

            <fieldset class="legend-section">
                <legend class="sidebar__widget-title">
                    {{'e-stamp.Party-Detail' | translate}}
                </legend>
                <div class="row">
                    <div class="col-md-4">
                        <fieldset class="p-1">
                            <legend><label>{{'e-stamp.code' | translate}}</label></legend>
                            <label>{{code}} </label>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class="p-1">
                            <legend><label>{{'e-stamp.Party-type' | translate}}</label></legend>
                            <label>{{ lan==0?partyTypeNameEn:partyTypeNameHi }}</label>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class="p-1">
                            <legend><label> {{'e-stamp.Party-name' | translate}}</label>
                            </legend>
                            <!-- {{partyName}} -->
                            <label>{{ lan==0?estampPartyModel?.partyName:estampPartyModel?.partyNameHindi }} </label>
                        </fieldset>
                    </div>

                </div>

            </fieldset>
            <fieldset class="legend-section">
                <legend class="sidebar__widget-title">
                    {{'e-stamp.Deed-Details' | translate}}
                </legend>
                <div class="row">
                    <div class="col-md-4">
                        <fieldset class="p-1">
                            <legend><label>{{'e-stamp.Deed-Category' | translate}}</label></legend>
                            <label>{{ lan==0?deedCategoryBean?.deedCategoryNameEn:deedCategoryBean?.deedCategoryNameHi }} </label>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class="p-1">
                            <legend><label>{{'e-stamp.Deed-Type' | translate}}</label></legend>
                            <label>{{ lan==0?deedTypeBean?.deedTypeNameEn:deedTypeBean?.deedTypeNameHi }}</label>
                        </fieldset>
                    </div>
                    <div class="col-md-4">
                        <fieldset class="p-1">
                            <legend><label> {{'e-stamp.Deed-Instrument' | translate}}</label>
                            </legend>
                            <label>{{ lan==0?deedInstrumentBean?.instrumentNameEn:deedInstrumentBean?.instrumentNameHi }} </label>
                        </fieldset>
                    </div>

                </div>
            </fieldset>
            <fieldset class="legend-section" *ngIf="deedAcceptLink != null && deedAcceptLink != undefined">
                <legend class="sidebar__widget-title">
                    {{'e-stamp.Deed-Content-List' | translate}}
                </legend>
                <deed-content-history  estampIdInput={{estampIdInput}}  [eregFlag]=eregFlag [partyId]="partyId" 
                [mobileNo]="mobileNo" [partyStatus]="partyStatus" [partyType]="partyType"  [userId]="userId" 
                [estampContentStatus]="estampContentStatus" [natureOfInstrument]="natureOfInstrument"
                [partySubType]="partySubType" [deedAcceptLink]="deedAcceptLink"></deed-content-history>
            </fieldset>
            <div class="col-md-12 text-left">
                <button type="button" mat-raised-button color="default" class="btn btn-secondary mr-1" (click)="back()">{{'e-stamp.back'| translate}}</button>
            </div>
           

            <!-- <app-action-component *ngIf="partyId!=null" [partyId]="partyId" [estampId]="estampId"
            [mobileNo]="mobileNo" [partyStatus]="partyStatus" [partyType]="partyType"
             [estampContentStatus]="estampContentStatus" [natureOfInstrument]="natureOfInstrument" [partySubType]="partySubType" [eregFlag]="eregFlag" >
            </app-action-component> -->




                            <!-- <button mat-raised-button type="button" class="btn btn-primary" data-toggle="modal"
                                data-target="#exampleModal">
                                {{'e-stamp.E-stamp' | translate}}
                            </button> -->






                        <!-- <form (ngSubmit)="f.form.valid && onSubmitPartyResponse(f)" #f="ngForm">
                        <h3  class="card-title mr-2">Please select your response and enter remark <span class="text-danger">*</span></h3>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control"  name="partyResponse" [(ngModel)]="estampPartyContentModel.status" #partyResponse="ngModel" required>
                                        <option [disabled]="true" [value]="null" selected>Select</option>
                                        <option [value]="1" id="1">ACCEPT</option>
                                        <option [value]="2" id="2">REJECT</option>
                                     </select>
                                     <div *ngIf="f.submitted && partyResponse.invalid" class="invalid-feedback">
                                        <div *ngIf="partyResponse.errors.required" class="alert alert-danger error_message p-1 float-right mb-0">Your response is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group">
                                    <textarea name="partyContent" [(ngModel)]="estampPartyContentModel.partyContent" #partyContent="ngModel" placeholder="Enter your remarks" rows ="3" cols="100" required></textarea>
                                    <div *ngIf="f.submitted && partyContent.invalid" class="invalid-feedback">
                                        <div *ngIf="partyContent.errors.required" class="alert alert-danger error_message p-1 float-right mb-0">Your remark is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 offset-md-3">
                                <button mat-raised-button (click)="resetEstampAddPartyDetails()"  name="reset"
                                class="mr-2 w-100  float-right btn btn-secondary">RESET</button>
                            </div>
                            <div class="col-md-3 ">
                                <button type="submit" mat-raised-button  color="primary" name="addRemark"  id="addRemark"
                    class="mr-2 w-100 float-right">SUBMIT RESPONSE</button>
                            </div>
                        </div>
                    </form> -->

        </div>
    </div>
</mat-card-content>

</mat-card>
</div>
<ngx-ui-loader></ngx-ui-loader>
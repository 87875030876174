import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatTableDataSource } from '@angular/material/table';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { EstampDeedContent } from 'projects/common-lib/src/lib/models/estamp.model/estamp-deed-content';
import { EstampDetails, EstampPartyElement } from 'projects/common-lib/src/lib/models/estamp.model/estamp-details';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { EstampTemplateBean } from 'projects/common-lib/src/lib/models/estamp.model/estamp-template-bean';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { IgrsEregPartyDetailsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { EstampCommonBean } from '../../models/addEstamp.model/add-estamp.model';
import { EstampService } from 'projects/front-web/src/app/service/estamp.service';
import { TemplateFieldsValuesModel } from '../../models/TemplateFieldModel';
import { TranslateHEService } from '../../helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';


const EstampParty_ELEMENT_DATA: EstampPartyElement[] = [];

@Component({
  selector: 'app-deed-template',
  templateUrl: './deed-template.component.html',
  styleUrls: ['./deed-template.component.scss']
})
export class DeedTemplateComponent implements OnInit {

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  http: any;
  lan:number=1
  safeHtml: SafeHtml;
  constructor(private ngxService: NgxUiLoaderService, private estampService: EstampService, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private sanitizer: DomSanitizer,
    private translateService:TranslateHEService, 
  private translate: TranslateService,) {
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.lan = msg
        this.translate.use(msg==1?'hi':'en')
       })
     }


  estampCommonBean: EstampCommonBean = new EstampCommonBean();
  dataSource = new MatTableDataSource<any>(EstampParty_ELEMENT_DATA);
  displayedColumns: string[] = ["sNo", "partyType", "userType", "applicantType", "cinPanGstin", "mobileNo", "partyName", "emailId"];
  estampId: string;
  deedType: string;
  deedInstrument: string;
  estamptemplateData: string;
  estamptemplateDataTemp: string;
  deedContentId: string;
  deedContent: any;
  qrCode: string;
  uid: number;
  estampTemplateBeanList: EstampTemplateBean[] = new Array();
  designTemplate: string
  deedTemplateContent: any
  field1Reg: any
  base64DeedPdf: any;
  templateFieldsValuesModelList: TemplateFieldsValuesModel[] = new Array();
  templateId: number
  userProfileModel : CitizenUserProfileDetails=new CitizenUserProfileDetails() ;
  partyNames: string=""
  partyAddress:string=""
  serviceProvider:string;
  sPLicence:string;
  issueDate:string
  sroDetails:string
  createdBy:number
  estampCode:string

  //add new
  maskedestampCode:string;


  ngOnInit(): void {

    this.estampCommonBean = this.data.estampCommonbean;
   
    
    if (this.data.deedContentId != undefined) {

      this.estampId = this.data.estampId;
      this.deedContentId = this.data.deedContentId;
      this.deedContent = this.data.deedContent;
      this.deedTemplateContent = this.data.deedTemplateContent
    
      if (this.data.deedTemplateContent != undefined) {
        this.estamptemplateData = this.deedTemplateContent + this.deedContent;
      } else {
        this.estamptemplateData = this.deedContent;
      }
     // alert("template::"+this.deedTemplateContent)
      this.getEstampDetails();
      this.getEstampPartyDetailsByEstampId();



    } else {

      this.estampId = sessionStorage.getItem("estampId");
      this.estampId = this.data.estampId;
      this.deedContentId = this.data.deedContentId;
      this.deedContent = this.data.deedContent;
      this.deedTemplateContent = this.data.deedTemplateContent

      if (this.data.deedTemplateContent != undefined) {
        this.estamptemplateData = this.deedTemplateContent + this.deedContent;
      } else {
        this.estamptemplateData = this.deedContent;
      }
      this.getEstampDetails();
      this.getEstampPartyDetailsByEstampId();



      //this.getDeedContentByEstampId();
    }
    this.getQrCode();

  }

  getQrCode() {
    this.estampService.getQrCodeService(Number(this.estampId)).subscribe(templateData => {
      if (templateData.responseStatus == 'true') {
        this.qrCode = 'data:image/png;base64,' + templateData.responseData;
        this.data.estampCommonbean.qrCode = templateData.responseData;
        this.data.estampCommonbean.viewJasperDeed = true;

        this.estampService.viewEstampDeedJrxmlToHtml(this.estampCommonBean).subscribe(reponse => {
          if (reponse.responseStatus == "true") {
            const byteArray = Uint8Array.from(atob(reponse.responseData.pdfBase64), c => c.charCodeAt(0));
            let blobMB = new Blob([byteArray], { type: 'application/pdf' });
            const deedViewer = document.getElementById('deedEsignViewer');
            this.base64DeedPdf = URL.createObjectURL(blobMB);
            deedViewer.setAttribute('src', this.base64DeedPdf);
            deedViewer.onload = () => URL.revokeObjectURL(this.base64DeedPdf);

          }
          else {
            this.alertmessage.errorAlertMessage('tech_error_msg');
          }
        })
      }
    })
  }

  estampDataList: string[] = new Array();
  estampPartyDetailsList: IgrsEregPartyDetailsBean[] = new Array();
  estampFirstPartyDetailsList: string[] = new Array();
  estampSecondPartyDetailsList: string[] = new Array();
  estampWitnessList: string[] = new Array();
  estampFirstPartyMobileList: temp[] = new Array();
  estampSecondPartyMobileList: temp[] = new Array();
  estampWitnessMobileList: temp[] = new Array();
  estampFirstPartyIdList: string[] = new Array();
  estampSecondPartyIdList: string[] = new Array();
  estampWitnessIdList: string[] = new Array();
  estampDeedContentList: EstampDeedContent[] = new Array();
  //addEstamp:AddEstamp=new AddEstamp();
  estampDetails: EstampDetails = new EstampDetails();



  getEstampPartyDetailsByEstampId() {


    this.ngxService.start();
    this.estampService.getEstampPartyDetailsByEstampId(Number(this.estampId)).subscribe(templateData => {


      if (templateData.responseStatus == 'true') {
        this.ngxService.stop();
        this.estampPartyDetailsList = templateData.responseData;
        let j = 0, k = 0, firstMobile = 0, secMobile = 0, firstId = 0, secId = 0;
        let m=0,witnessId=0,witnessMobile=0

        for (let i = 0; i < this.estampPartyDetailsList.length; i++) {
          this.data.estampCommonbean.igrsEstampPartyBeanList.push(this.estampPartyDetailsList[i]);
          if (this.estampPartyDetailsList[i].partyType == 1) {

            //1. Add entries
            let d : temp=new temp() ;
            d.party_name= this.estampPartyDetailsList[i].partyName;
            d.signature=this.estampPartyDetailsList[i].partyName+" signature";
            this.estampFirstPartyMobileList[firstMobile++] = d
                  
            // this.estampFirstPartyMobileList[firstMobile++] =this.estampPartyDetailsList[i].partyName+" signature ("+this.estampPartyDetailsList[i].mobileNo+")";
            this.estampFirstPartyIdList[firstId++] = this.estampPartyDetailsList[i].id.toString()
           // this.shriSmtKu = this.shriSmtKu+" , "+ this.estampPartyDetailsList[i].partyName;
            if(!(this.partyNames!="")){
             this.partyNames= this.estampPartyDetailsList[i].partyName;
            }else{
              this.partyNames = this.partyNames+" , "+ this.estampPartyDetailsList[i].partyName;
            }

            if(!(this.partyAddress!="")){
              this.partyAddress= this.estampPartyDetailsList[i].address;
             }else{
               this.partyAddress = this.partyAddress+" , "+ this.estampPartyDetailsList[i].address;
             }


            this.estampFirstPartyDetailsList[j++] = this.estampPartyDetailsList[i].partyName;
            this.estampFirstPartyDetailsList[j++] = this.estampPartyDetailsList[i].address;
            this.estampFirstPartyDetailsList[j++] = this.estampPartyDetailsList[i].mobileNo;

          } else if (this.estampPartyDetailsList[i].partyType == 2) {
            if(this.estampPartyDetailsList[i].partyType == 2 && this.estampDetails.natureOfInstrument!="Unilateral"){

            let d : temp=new temp() ;
            d.party_name= this.estampPartyDetailsList[i].partyName;
            d.signature=this.estampPartyDetailsList[i].partyName+" signature";
            this.estampSecondPartyMobileList[secMobile++] = d

          //  this.estampSecondPartyMobileList[secMobile++] = this.estampPartyDetailsList[i].partyName+" signature ("+this.estampPartyDetailsList[i].mobileNo+")";
            }this.estampSecondPartyIdList[secId++] = this.estampPartyDetailsList[i].id.toString()

            if(!(this.partyNames!="")){
              this.partyNames= this.estampPartyDetailsList[i].partyName;
             }else{
               this.partyNames = this.partyNames+" , "+ this.estampPartyDetailsList[i].partyName;
             }

             if(!(this.partyAddress!="")){
              this.partyAddress= this.estampPartyDetailsList[i].address;
             }else{
               this.partyAddress = this.partyAddress+" , "+ this.estampPartyDetailsList[i].address;
             }

            this.estampSecondPartyDetailsList[k++] = this.estampPartyDetailsList[i].partyName;
            this.estampSecondPartyDetailsList[k++] = this.estampPartyDetailsList[i].address;
            this.estampSecondPartyDetailsList[k++] = this.estampPartyDetailsList[i].mobileNo;
          }else if (this.estampPartyDetailsList[i].partyType == 3) {

            let d : temp=new temp() ;
            d.party_name= this.estampPartyDetailsList[i].partyName;
            d.signature=this.estampPartyDetailsList[i].partyName+" signature";
            this.estampWitnessMobileList[witnessMobile++] = d

           // this.estampWitnessMobileList[witnessMobile++] = this.estampPartyDetailsList[i].partyName+" signature ("+this.estampPartyDetailsList[i].mobileNo+")";;
            this.estampWitnessIdList[witnessId++] = this.estampPartyDetailsList[i].id.toString()
            this.estampWitnessList[m++] = this.estampPartyDetailsList[i].partyName;
            this.estampWitnessList[m++] = this.estampPartyDetailsList[i].address;
            this.estampWitnessList[m++] = this.estampPartyDetailsList[i].mobileNo;
          }


        }
      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(templateData);

      }
    });
  }



  getEstampDetails() {
    this.estampService.getListingEstamp(Number(this.estampId)).subscribe(templateData => {
      if (templateData.responseStatus == 'true') {
        this.ngxService.stop();
        this.estampDetails = templateData.responseData;

        
        this.data.estampCommonbean.igrsEstampDetailDisplay = templateData.responseData;

        
        this.getFieldsValues()
        
        this.deedType = this.estampDetails.deedTypeEn;
        this.deedInstrument = this.estampDetails.instrumentNameEn;
        this.issueDate=this.estampDetails.createdDate
        this.createdBy=this.estampDetails.createdBy
        this.estampCode=this.estampDetails.estampCode;
        let marks = "";
        for (let index = 0; index < this.estampCode.length-4; index++) {
          marks=marks.concat("X");
        }
        this.maskedestampCode=marks+this.estampCode.slice(-4);
        this.getServiceProviderDetails()
        //  if(this.data.deedContentId==undefined){
        //  this.estamptemplateData=this.estampDetails.finalContent;
        //  }else if(this.data.deedContentId=='0'){
        //   this.estamptemplateData=this.deedTemplateContent+this.data.deedContent;
        //  }



      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(templateData);

      }


    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');

      });
  }
  partyList: PartyModule[] = new Array();
  partyStr:any;

  getFieldsValues() {

    this.templateId = Number(sessionStorage.getItem("templateId"))
    
    this.estampService.getTemplateFieldValuesDetails(this.templateId, this.estampId).subscribe(templateData2 => {
      if (templateData2.responseStatus == 'true') {
        this.templateFieldsValuesModelList = templateData2.responseData

        if (this.data.deedTemplateContent != undefined) {
          for (let i = 0; i < this.templateFieldsValuesModelList.length; i++) {

            var str = "@{{field_" + this.templateFieldsValuesModelList[i].fieldId + "}}"
            const regex = new RegExp(str, 'g'); // i -> case insensitive  g -> global search (all occurrences)

            // var regex1 = new RegExp("\\b"+str+"\\b","g");

            this.deedTemplateContent = this.deedTemplateContent.replace(regex, this.templateFieldsValuesModelList[i].fieldValue);

          }
        }
        // Start  For Shri/smt/Ku
        if (this.data.deedTemplateContent != undefined) {
          const regexPartyName = new RegExp("@{{field_partyNames}}", 'g');
          this.deedTemplateContent = this.deedTemplateContent.replace(regexPartyName, this.partyNames);
        }
        // End  For Shri/smt/Ku

         // Start  For Party Address
         if (this.data.deedTemplateContent != undefined) {
          const regexPartyAddress = new RegExp("@{{field_partyAddress}}", 'g');
          this.deedTemplateContent = this.deedTemplateContent.replace(regexPartyAddress, this.partyAddress);
        }
        // End  For Party Address
       
        
        if (this.data.deedContentId == undefined) {
          this.estamptemplateDataTemp = this.data.deedContent;
        } else if (this.data.deedContentId == '0') {
          this.estamptemplateDataTemp = this.data.deedContent;
        } else if (this.data.deedContentId != undefined) {
          this.estamptemplateDataTemp = this.data.deedContent;
        }

        if (this.data.deedTemplateContent != undefined) {
          this.estamptemplateData = this.deedTemplateContent + this.estamptemplateDataTemp;
        } else {
          this.estamptemplateData = this.estamptemplateDataTemp;
        }

      }


    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('Error!');
      });


  }
  getColName(index: number) {
    index = index % 3;
    if (index == 0) {
      return 'Organization/Party Name'
    } if (index == 1) {
      return 'Address'
    }  if (index == 2) {
      return 'Contact No'
    }  else {
      return 0
    }

  }

  //new add mthd
  getEsign() {
    this.router.navigateByUrl("");
  }


  getEstampTemplate() {
    this.ngxService.start();
    this.estampService.getEstampTemplate().subscribe(templateData => {
      let re = /{{name}}/gi;
      // let  re1 = /Number2/gi;
      // let  re2 = /Number3/gi;
      // var re3 = /(\w+)\s(\w+)/;
      if (templateData.responseStatus == 'true') {
        this.ngxService.stop();
        this.estampTemplateBeanList = templateData.responseData;
        if (this.estampTemplateBeanList[0].instrumentId != undefined && this.estampTemplateBeanList[0].instrumentId == 29) {
          this.designTemplate = this.estampTemplateBeanList[0].designTemplate

          this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(
            this.designTemplate
          )
        }

      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(templateData);
      }

    },
      (err) => {

        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('Error!');
      });
  }

  getServiceProviderDetails(){

    this.ngxService.start();
    this.estampService.getCitizenDetailsWithSp(this.createdBy).subscribe(templateData => {
      this.ngxService.stop();
      if (templateData.responseStatus == 'true') {
        this.data.estampCommonbean.igrsCitizenUserBean = templateData.responseData;

        this.userProfileModel=templateData.responseData;
        if(templateData.responseData.serviceProvider!=undefined){
     // this.sroDetails=templateData.responseData.serviceProvider.officeBean.demography.demographyName
     if(templateData.responseData.serviceProvider.sPLicence!=undefined && templateData.responseData.serviceProvider.sPLicence!="undefined"){
      this.sPLicence="("+templateData.responseData.serviceProvider.sPLicence+")"
      this.sroDetails=templateData.responseData.serviceProvider.officeBean.officeName+" , "+templateData.responseData.serviceProvider.officeBean.officeAddress
    }else{
      this.sPLicence=""
      this.sroDetails="NA"
    }

    }else{
      this.sPLicence=""
      this.sroDetails="NA"
    }

    if(templateData.responseData.juristicPerson!=undefined){
      this.serviceProvider=templateData.responseData.juristicPerson.authorizedPersonName
    }else{
      this.serviceProvider=""
    }




    // if(templateData.responseData.serviceProvider!=undefined){
    //   this.sPLicence="("+templateData.responseData.serviceProvider.sPLicence+")"
    // }else{
    //   this.sroDetails="NA"
    // }

      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(templateData);

      }
    });
   // getCitizenDetailsWithSp
  }

  onBtnPrintClick() {
    // let printData = document.getElementById('print-card').innerHTML;
    // document.body.appendChild(printData);

    // var myWindow = window.open("", "_blank");
    // myWindow.document.write(printData)
    // myWindow.print();

    let printContents, popupWin;
    printContents = document.getElementById('print-card').innerHTML;
    popupWin = window.open('', 'top=0,left=0,height=100%,width=100%');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Print tab</title>

            <style type = "text/css" > @media screen {
                table,
                td,
                th {
                    border: 1px solid #ddd;
                    text-align: left;
                }

                table {
                    border-collapse: collapse;
                    width: 100%;
                    margin-bottom: 1rem;
                }

                th,
                td {
                    padding: 15px;
                }

            }

            @media print {

               filter: grayscale(100%);
              background-size: 10%;}
                table,
                td,
                th {
                    border: 1px solid #ddd;
                    text-align: left;
                }

                table {
                    border-collapse: collapse;
                    width: 100%;

                }

                th,
                td {
                    padding: 15px;
                }
                .d-print-block{
                  display:block !important;
                }

            }
            @media screen, print {
                table,
                td,
                th {
                    border: 1px solid #ddd;
                    text-align: left;
                }

                table {
                    border-collapse: collapse;
                    width: 100%;
                    margin-bottom: 1rem;
                }

                th,
                td {
                    padding: 15px;
                }



            }

        </style>
    </head>
    <body onload="window.print();window.close()">

        ${printContents}

    </body>
</html>`
    );
    popupWin.document.close();

  }

}

export class temp {
  party_name: string;
  signature: string;
}
export class PartyModule{
  name : string;
  fatherName:string;
  gender:string;
}

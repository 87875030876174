import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

@Component({
  selector: 'app-property-valuation-details',
  templateUrl: './property-valuation-details.component.html',
  styleUrls: ['./property-valuation-details.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(300)]),
      transition(':leave', [animate(500)]),
    ]),
  ],
})
export class PropertyValuationDetailsComponent implements OnInit {
  step = 0;
  lan: number;
  constructor(
    private activatedRoute: ActivatedRoute,

    private translateService: TranslateHEService,
    private translate: TranslateService
  ) {
    this.translateService.currentApprovalStageMessage.subscribe((msg) => {
      this.lan = msg;
      this.translate.use(msg == 1 ? 'hi' : 'en');
    });
  }

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: any) {
    if (window.pageYOffset > 210) {
      let element = document.getElementById('sticky_Div');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('sticky_Div');
      element.classList.remove('sticky');
    }
  }

  setStep(index: number) {
    this.step = index;
  }
}

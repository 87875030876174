<div class="row"> 

    <!-- for state -->

    <div class="col-md-3">
        <div class="form-group">
            <label>{{'duty-calculation.Select-deed-Catagory' | translate}}<span class="rstarcolor">*</span></label>
            <ng-select type="text" class="form-control" disabled [(ngModel)]="pDeedCategoryId" #categoryId="ngModel" required
                               name="categoryId"
                                  placeholder="{{ 'duty-calculation.Select-deed-Catagory' | translate }}"
                                  class="mb-1 p-0" bindLabel="deedCategoryNameEn"
                                  (ngModelChange)="getAllDeedTypeByCategoryId($event)"  >
                                  <ng-option selected [value]="null" [disabled]="true"> {{ 'e-stamp.Please-Select-deed-Catagory' | translate }} </ng-option>
                                  <ng-option value="{{vl.deedCategoryId}}" *ngFor="let vl of deedCategoryBeanList">
                                      {{lan==0?vl.deedCategoryNameEn:vl.deedCategoryNameHi}}
                                  </ng-option>
                                
                              </ng-select>
            
            <div class="invalid-feedback">
                <div>{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <label>{{'duty-calculation.Select-Deed-Type' | translate}}<span class="rstarcolor">*</span></label>
            <ng-select class="form-control" disabled [(ngModel)]="pDeedTypeId" name="deedTypeId" id="deedTypeId"
                                  placeholder="{{ 'duty-calculation.Select-Deed-Type' | translate }}"
                                  class="mb-1 p-0" bindLabel="deedTypeNameEn"
                                  (ngModelChange)="getAllDeedInstrumentsByDeedTypeId($event)" #deedTypeId="ngModel" required >
                                  <ng-option selected value="null"> {{ 'e-stamp.Please-Select-Deed-Type' | translate }}</ng-option>
                                  <ng-option value="{{item1.id}}" *ngFor="let item1 of deedTypeCategoryBeanList">
                                      {{lan==0?item1.deedTypeNameEn:item1.deedTypeNameHi}} 
                                  </ng-option>
                              </ng-select>
            <div class="invalid-feedback">
                <div>{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group">
            <label>{{'duty-calculation.Select-Instrument' | translate}}<span class="rstarcolor">*</span></label>
             <ng-select [(ngModel)]="pInstrumentId" name="instrumentId" disabled
                                  placeholder="{{ 'e-stamp.Please-Select-Instrument' | translate }}"
                                  class="mb-1  p-0" bindLabel="instrumentNameEn" 
                                  (ngModelChange)="getAllInstrumentMappingFieldsByInstrumentId($event)" #instrumentId="ngModel"  required  >
                                  <ng-option [disabled]="true" [value]="null"> {{ 'e-stamp.Please-Select-Instrument' | translate }} </ng-option>
                                  <ng-option value="{{item.id}}" *ngFor="let item of deedInstrumentBeanList" >
                                      {{lan==0?item.instrumentNameEn:item.instrumentNameHi}}
                                  </ng-option>
                              </ng-select> 
            <div class="invalid-feedback">
                <div>{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
   
</div>
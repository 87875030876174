import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSetting, AppSettingService } from '../../app.setting';

@Injectable({
  providedIn: 'root'
})
export class TranslateHEService {

  res: any;
  val: any;
  //  url:string = 'https://translation.googleapis.com/language/translate/v2?key=';
  //url:string = 'https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=';
  private settings!: AppSetting;
  private approvalStageMessage = new BehaviorSubject(1);
  currentApprovalStageMessage = this.approvalStageMessage.asObservable();
  resultArr: any[] = [];

  constructor(private appSettingsService: AppSettingService, private translate: TranslateService, private httpClient: HttpClient) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }

  translateLang(obj: string) {
    // this.settings.googleTranslateUrl
    console.log("obj" + obj);
    return this.httpClient.get<any>(this.settings.googleTransliteration_en_hi + obj + "&ime=transliteration_en_hi&num=5&cp=0&cs=0&ie=utf-8&oe=utf-8&app=jsapi").pipe(map((result: any) => {
      console.log("result", result[1]);
      this.resultArr = result[1];
      console.log("this.resultArr", this.resultArr);
      let finalResult: string;

      for (let i = 0; i < this.resultArr.length; i++) {
        //const element = this.resultArr[i];
        let element = Object.assign({}, ...this.resultArr[i]);
        console.log("Array_Element", element[0]);

        if (i > 0) {
          finalResult = finalResult + ' , ' + element[0];
        } else {
          finalResult = element[0];
        }
      }
      console.log("finalResult", finalResult);

      // Comment for Implement new thing(manish)
      // let newproduct = Object.assign({}, ...result[1]);
      // console.log("newproduct", newproduct);
      // // let newproduct2 = Object.assign({}, ...newproduct[1][0]);
      // console.log("newproduct2", newproduct[1][0]);

      //old not comment by me(manish)
      // if(result[0] != null){
      //   var res1 = result[0];
      //   var res2 = res1[0];
      //   this.val = res2[0];
      //   return this.val;
      // } else {
      //   return "";
      // }

      // Comment for Implement new thing(manish)
      // if (newproduct[1][0] != null) {
      //   return newproduct[1][0];
      // } else {
      //   return "";
      // }

      if (finalResult != null) {
        return finalResult;
      } else {
        return "";
      }
    })
    );

  }


  translateLangHi(obj: string) {
    return this.httpClient.get<any>(this.settings.googleTransliteration_en_hi + obj + "&ime=transliteration_hi_en&num=5&cp=0&cs=0&ie=utf-8&oe=utf-8&app=jsapi").pipe(map((result: any) => {

      /*  if(result[0] != null){
          var res1 = result[0];
          var res2 = res1[0];
          this.val = res2[0];
          return this.val;
        } else {
          return "";
        }*/
      console.log("result", result[1]);
      this.resultArr = result[1];
      console.log("this.resultArr", this.resultArr);
      let finalResult: string;

      for (let i = 0; i < this.resultArr.length; i++) {
        //const element = this.resultArr[i];
        let element = Object.assign({}, ...this.resultArr[i]);
        console.log("Array_Element", element[0]);

        if (i > 0) {
          finalResult = finalResult + ' , ' + element[0];
        } else {
          finalResult = element[0];
        }
      }
      console.log("finalResult", finalResult);

      // Comment for Implement new thing(manish)
      // let newproduct = Object.assign({}, ...result[1]);
      // console.log("newproduct", newproduct);
      // // let newproduct2 = Object.assign({}, ...newproduct[1][0]);
      // console.log("newproduct2", newproduct[1][0]);
      // if (newproduct[1][0] != null) {
      //   return newproduct[1][0];
      // } else {
      //   return "";
      // }

      if (finalResult != null) {
        return finalResult;
      } else {
        return "";
      }

    })
    );

  }

  updateApprovalMessage(message: number) {
    //this.translate.use(message == 0 ? "en" : "hi");
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage !== null) {
      this.translate.use(selectedLanguage);
      this.approvalStageMessage.next(selectedLanguage == 'en' ? 0 : 1)
    } else {
      this.translate.use((message == null || message == 0) ? 'en' : 'hi');
      this.approvalStageMessage.next(message)
    }


  }
}

import { MstDemographyTypeBean } from "./api.masterdata.model/MstDemographyTypeBean";
import { IgrsEregMunicipalTaxesBean, IgrsEregLoanDetlsBean, IgrsEregPropChargeCasesBean, IgrsEregIvrsDetlsBean, IgrsEregPropAdditionalDetlsBean, EregPropertyLinkModel, IgrsEregPropImgDisplayBean, IgrsEstampPartyPropMappingBean, IgrsEregPartyDetailsBean } from "./e_registry/e-registry.model";
import { IgrsSubClauseMasterBean } from "./IgrsSubClauseMasterBean";
import { IgrsSubClausePropertyMappingBean } from "./IgrsSubClausePropertyMappingBean";
import { MstDemographyBean } from "./MstDemographyBean";
import { MstPropertyTypeBean } from "./MstPropertyTypeBean";
import { PropertyOwner } from "./PropertyOwner";

export class PnrdModel {
	houseFlatNo:string
	buildingSubType:string
	SubClauseProperty: IgrsSubClauseMasterBean;
	gisSearchTxt: string
	igrsUqId: string;
	propertyId: string
	sub_Property_ID: string
	khasraNo: string;
	unique_Property_ID: string;
	propertyOwner: PropertyOwner[] = []
	otherDetails?: string;
	//UOM
	address:string
	unit: number
	partialTransactArea: number
	propertyTypeId: number //Set Property Type 1 for Plot 2 for building 3 for Agriculture land
	demography: MstDemographyBean = new MstDemographyBean();
	buildingAreaType: string
	totalArea: number; //total area
	applicationNo: string;
	propertyNo: string;
	latitude: number;
	longitude: number;
	constrnTime: string;
	propertyUsageType: string;
	propertyUsageSubType: string;
	id: number
	name: string
	aadharCard: string
	CornerPlot: string
	subClause: IgrsSubClausePropertyMappingBean[] = new Array();
	demographyArray: MstDemographyBean[] = new Array()
	propertyType: MstPropertyTypeBean = new MstPropertyTypeBean();
	AreaType: string
	GandiBasti: string
	OpenTerrace: string
	ConstructionYear: string
	Ews: string
	constructionType: string
	constructionDate: string
	DirectionNorth: string
	DirectionEast: string
	DirectionWest: string
	DirectionSouth: string
	districtName: string
	eKYCDate: string
	eKYCStatus: string
	gramPanchayat: string
	houseNo: string
	localBody: string
	noOfFloor: string
	pincode: string
	plotLength: string
	plotWidth: string
	propertyStatus: string
	propertyValue: string
	property_Address: string
	village: string
	villageCode: string
	wardName: string
	wardNo: string
	constructionYear: string
	buildingtype: string
	directionSouth: string
	foundation: string
	directionWest: string
	ews: string
	directionEast: string
	cornerPlot: any
	directionNorth: any

	igrsEregMunicipalTaxesBean: IgrsEregMunicipalTaxesBean;
	igrsEregLoanDetlsBean: IgrsEregLoanDetlsBean;
	igrsEregChargeAndCasesBean: IgrsEregPropChargeCasesBean;
	igrsEregIvrsDetlsBean: IgrsEregIvrsDetlsBean;
	igrsEregPropAdditionalDetlsBean = new IgrsEregPropAdditionalDetlsBean();
	igrsEregPropLinking: EregPropertyLinkModel[];
	igrsEregPropImgDisplayBean = new IgrsEregPropImgDisplayBean();

	partyBeans: IgrsEregPartyDetailsBean[] = [];
	partyBuyerBeans: IgrsEregPartyDetailsBean[] = [];
	partySellerBeans: IgrsEregPartyDetailsBean[] = [];
	partyConsenterBeans: IgrsEregPartyDetailsBean[] = [];

	partyBean: IgrsEregPartyDetailsBean = new IgrsEregPartyDetailsBean();
	expanded: boolean;
	makerRemarks: string;
	checkerRemarks: string;
	addPartyWithoutCustodianData: boolean = false;
	mauunalProcess:boolean=false
	isPnrd:boolean=true
	isPartyForEdit:boolean
}
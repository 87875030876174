import { PropertyValuationRequestBean } from "../models/PropertyValuationRequestBean"
//import { UOM } from "../models/UOMEnum";

export class UtilityService{
    deepClone(obj:any, hash = new WeakMap()):any {
        if (Object(obj) !== obj) return obj; // primitives
        if (hash.has(obj)) return hash.get(obj); // cyclic reference
        const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : Object.create(null);
        hash.set(obj, result);
        if (obj instanceof Map)
            Array.from(obj, ([key, val]) => result.set(this.deepClone(key, hash), this.deepClone(val, hash)));
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: this.deepClone(obj[key], hash) })));
    }


    // getDirectionName(uom: UOM): string {
    //     switch (uom) {
    //         case UOM.SQFT:
    //             return "SQFT";
    //         case UOM.SQMT:
    //             return "SQMT";
    //         case UOM.HA:
    //             return "HA";
        
          
    //     }

    // }


    getUnit(element: any,lan:any) {
        if (element == 'HA') {
          return lan == 0 ? 'HA' : 'हे.'
        } else if (element == 'SQMT') {
          return lan == 0 ? 'SQMT' : 'व.मी.'
        } else if (element == 'SQFT') {
          return lan == 0 ? 'SQFT' : 'व.फु.'
        } else {
          return ''
        }
      }

      getSubArea(isPlanningArea:any,lan:any){
        if(isPlanningArea == 1){
          return lan == 0 ? 'Planning Area' : 'योजना क्षेत्र'
        }else if(isPlanningArea == 0){
          return lan==0 ? 'Non Planning Area' :'गैर योजना क्षेत्र'
        }else{
          return ''
        }
      }


    
    convertedUOM(uom:any,lan:number):string{
        if(lan==0){
        if(uom=='SQMT'){
         return 'Square meter(SQM)'
        }
        else if(uom=='HA'){
         return 'Hectare (HA)'
        }
        else{
         return 'Square feet(SFT)'
        }
      }
      else{
        if(uom=='SQMT'){
          return 'वर्ग मीटर'
         }
         else if(uom=='HA'){
          return 'हेक्टेयर'
         }
         else{
          return 'वर्ग फुट'
         }
      }
      }
   //
 


 loadFloorList<T extends Floor>(buildingSubType: string, buildingType: string): Array<T> {
    let floorList: Array<T>;

    if (buildingSubType === 'Multistorey' && buildingType === 'Commercial') {
        floorList = [
            { id: 1, nameEn: "Basement", nameHi: "तलघर" },
            { id: 2, nameEn: "Mezzanine Floor", nameHi: "मेज़नाइन फ्लोर" },
            { id: 3, nameEn: "Ground Floor", nameHi: "तल मंज़िल" },
            { id: 4, nameEn: "First Floor", nameHi: "पहली मंजिल" },
            { id: 5, nameEn: "Second Floor", nameHi: "दूसरी मंजिल" },
            { id: 6, nameEn: "Third Floor", nameHi: "तीसरी मंजिल" },
            { id: 7, nameEn: "Fourth Floor and above", nameHi: "चौथी मंजिल और ऊपर" },
            { id: 9, nameEn: "Lower Ground Floor", nameHi: "लोअर ग्राउंड फ्लोर" },
            { id: 10, nameEn: "Upper ground floor", nameHi: "अपर ग्राउंड फ्लोर" }
        ] as Array<T>;
    } else {
        floorList = [
            { id: 1, nameEn: "Basement", nameHi: "तलघर" },
            { id: 3, nameEn: "Ground Floor", nameHi: "तल मंज़िल" },
            { id: 4, nameEn: "First Floor", nameHi: "पहली मंजिल" },
            { id: 5, nameEn: "Second Floor", nameHi: "दूसरी मंजिल" },
            { id: 8, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 9, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 10, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 11, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 12, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 13, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 14, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 15, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 16, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 17, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 18, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 19, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 20, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 21, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 22, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 23, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 24, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" },
            { id: 25, nameEn: "Third Floor And Above", nameHi: "तीसरी मंजिल और ऊपर" }
        ] as Array<T>;
    }

    return floorList;
}

//  roundUpToDecimal(num: number, decimalPlaces: number): number {
//     const factor = Math.pow(10, decimalPlaces);
//     const roundedValue = Math.ceil(num * factor) / factor;
//     return +roundedValue.toFixed(decimalPlaces); // Convert to fixed decimal places
// }
 hectaresToSquareFeet(hectares: number): number {
   // 1 hectare = 10,000 square meters
   const squareFeet = hectares * 107639.104;
   // 1 square meter = 10.7639 square feet
   
  
   return squareFeet
}
hectaresToSquareMeter(hectares: number): number {
    // 1 hectare = 10,000 square meters
    const squareFeet = hectares * 10000;
    // 1 square meter = 10.7639 square feet
    
   
    return squareFeet
 }


}

interface Floor {
    id: number;
    nameEn: string;
    nameHi: string;
}
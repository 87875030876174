import { Component, Inject, OnInit } from '@angular/core';
import { PropertyValuationRequestBean } from '../../models/PropertyValuationRequestBean';
import { PropertyOwner } from '../../models/PropertyOwner';
import { PropertyValuationResponseBean } from '../../models/PropertyValuationResponseBean';
import { IgrsEregPropIdentificationBean, IgrsEregTxnDetlsBean, ImpoundData, PartyTypeModel } from '../../models/e_registry/e-registry.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateHEService } from '../../helper-lib/services/common/translateHE.service';
import { NgxPrintElementService } from 'ngx-print-element';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { PropertyValuationDeviationBean } from '../../models/PropertyValuationDeviationBean';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PropertyValuationService } from 'projects/front-web/src/app/modules/property-valuation/property-valuation.service';
import { CommunicationServiceService } from 'projects/front-web/src/app/modules/duty-calculation/duty-service/communication-service.service';
import { EregistryService } from 'projects/front-web/src/app/service/eregistry.service';
import { LoggerService } from '../../service/logger.service';




export class DialogData {
  searchPropType: PropertyValuationRequestBean;
  propData: PropertyValuationResponseBean;
  fromDutyModule: boolean
  fromEregModule:boolean
  formPropModule:boolean
  fromCheckerModule:boolean
  fromCMSModule:boolean
  propertyOwner: PropertyOwner[] = []
  isHideTab:boolean=false
  landParcel:string[]=[]
  propertyRequestBean:PropertyValuationRequestBean
  igrsEregTxnDetlsBean:IgrsEregTxnDetlsBean
  propIdentificationObejct:IgrsEregPropIdentificationBean
  buildingGuildeValueData:any
  partyMst:PartyTypeModel[];
  isTab:boolean
}

@Component({
  selector: 'lib-new-show-maket-value',
  templateUrl: './new-show-maket-value.component.html',
  styleUrls: ['./new-show-maket-value.component.css']
})
export class NewShowMaketValueComponent implements OnInit {

  propertyValuationDeviationBean:  PropertyValuationDeviationBean
  propertyValuationResponseBean: PropertyValuationResponseBean = new PropertyValuationResponseBean();
  propertyValuationRequestBean: PropertyValuationRequestBean = new PropertyValuationRequestBean();
  fromDutyModule: boolean = false;
  fromEregModule:boolean=false;
  formPropModule:boolean=false
  guildeLineObj:any
  lan: any;
  floorList: any = new Array();
  propertyOwner: PropertyOwner[] = new Array()
  firstpartyname:string;
  secondpartyname:string;
  partyTypeList: PartyTypeModel[];
  fromCheckerModule:boolean=false;
  fromCMSModule:boolean=false;
  propIdentificationObj:any
  constructor(public matDialogRef: MatDialogRef<NewShowMaketValueComponent>,
    private ngxService: NgxUiLoaderService,
    private pvService: PropertyValuationService,
    private commService: CommunicationServiceService, private router: Router,
     @Inject(MAT_DIALOG_DATA) public responseData: DialogData,
      private translateService:TranslateHEService, 
      private translateServiceIns: TranslateService, 
      public print: NgxPrintElementService,
      public eregService:EregistryService,
      public logger:LoggerService, private translate:TranslateService) {
        logger.log("NewShowMaketValueComponent:-",responseData)
  this.partyTypeList = responseData.partyMst;
  this.propertyOwner = responseData.propertyOwner
  this.propertyValuationResponseBean = responseData.propData;
  this.propertyValuationResponseBean.pNRDResponseData=responseData.propData.pnrdresponseData
  this.propertyValuationResponseBean.mstDemographyBean = responseData.propData.mstDemographyBean;
  this.propertyValuationRequestBean = responseData.searchPropType;
  this.guildeLineObj=responseData.buildingGuildeValueData
  try {
    console.log("selected Language->" + sessionStorage.getItem("selectedLanguage"));
    let selectedLanguage = sessionStorage.getItem("selectedLanguage");
    if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
      this.lan = 1;
      selectedLanguage = 'hi';
    }

    this.translateService.currentApprovalStageMessage.subscribe(msg => {
     this.lan = msg
     this.translate.use(msg==1?'hi':'en')
    }
      );
  } catch (err) {
    console.log(err);
  }
  this.fromEregModule=responseData.fromEregModule
  this.fromDutyModule = responseData.fromDutyModule;
  this.formPropModule=responseData.formPropModule
  this.fromCMSModule = responseData.fromCMSModule
  this.fromCheckerModule = responseData.fromCheckerModule
  if (this.fromDutyModule ||  this.fromCheckerModule ||  this.fromCMSModule) {
    this.propertyValuationRequestBean.valTxnId = this.propertyValuationResponseBean.valTxnId;
    this.propertyValuationRequestBean.marketValue =  this.propertyValuationResponseBean.calculatedValue
    if(this.propertyValuationRequestBean.isPartialTransact){
      this.propertyValuationRequestBean.partialTransactArea =  this.propertyValuationRequestBean.totalArea
    }else{
      this.propertyValuationRequestBean.overAllArea = this.propertyValuationRequestBean.totalArea
    }
    this.propertyValuationRequestBean.fromCheckerModule = this.fromCheckerModule;
    this.propertyValuationRequestBean.fromCMSModule = this.fromCMSModule;
    this.propIdentificationObj  = responseData.propIdentificationObejct;
    // this.commService.propertyValuationResponseBean.next(this.propertyValuationResponseBean);
    // this.commService.propertyValuationRequestBean.next(this.propertyValuationRequestBean);
    // this.commService.propIdentificationObejct.next(responseData.propIdentificationObejct);
  
    
  }

  
  
}
objectToList=new Array()
  ngOnInit(): void {
    this.getCurrentGuildeLine(this.propertyValuationResponseBean.mstDemographyBean.id)
    this.floorList = [{ id: 1, "nameEn": "Basement", "nameHi": "तलघर" },
    { id: 2, "nameEn": "Mezzanine Floor", "nameHi": "मेज़नाइन फ्लोर" },
    { id: 3, "nameEn": "Ground Floor", "nameHi": "तल मंज़िल" },
    { id: 4, "nameEn": "First Floor", "nameHi": "पहली मंजिल" },
    { id: 5, "nameEn": "Second Floor", "nameHi": "दूसरी मंजिल" },
    { id: 6, "nameEn": "Third Floor", "nameHi": "तीसरी मंजिल" },
    { id: 7, "nameEn": "Fourth Floor and above", "nameHi": "चौथी मंजिल और ऊपर" },
    { id: 8, "nameEn": "Third Floor And Above", "nameHi": "तीसरी मंजिल और ऊपर" },
    { id: 9, "nameEn": "Lower Ground Floor", "nameHi": "लोअर ग्राउंड फ्लोर" },
    { id: 10, "nameEn": "Upper ground floor", "nameHi": "अपर ग्राउंड फ्लोर" }
    ];
    if(this.fromEregModule){
      //this.getPropertyValuationDeviationReprt()
    }

    
    //console.log("llllll",this.partyTypeList);
      
   
  }
   getPartyName(lan:number,partySubTypeId:number):String{
   // console.log(this.partyTypeList)
    for (const element of this.partyTypeList || []) {
      if (element.partySubTypeId === partySubTypeId) {
      
        return this.firstpartyname = lan === 0 ? element.partyTypeNameEn : element.partyTypeNameHi;
      } else if (element.partySubTypeId === partySubTypeId) {
        return this.secondpartyname = lan === 0 ? element.partyTypeNameEn : element.partyTypeNameHi;
      }
    }
    return ''
   }

  getPropertyValuationDeviationReprt() {
   this.ngxService.start()
   let fromCheckerOrCMSModule = false;
   if(this.fromCheckerModule || this.fromCMSModule){
     fromCheckerOrCMSModule  = true;
   }
   //console.log("----getPropertyValuationDeviationReprt fromCheckerModule----",  this.fromCheckerModule);
    this.eregService.getPropDeviationReport(this.propertyValuationRequestBean.eregId,this.propertyValuationResponseBean.propertyId[0],this.propertyValuationResponseBean.valTxnId,fromCheckerOrCMSModule).subscribe(
      (res: any)=>{
        if(res.responseStatus=="true"){
          this.ngxService.stop()
          this.propertyValuationDeviationBean=res.responseData
        }
        else{
          this.ngxService.stop()
      }
      }
    )
    
  }
  getCurrentGuildeLine(demography_id:any){
    this.ngxService.startLoader('loader-propvalCommon');
    this.pvService.getCurrentGLbyDemography(demography_id).subscribe(res => {

      //  console.log("guilde Line", res)
        if (res.responseStatus == 'true') {
          this.ngxService.stopLoader('loader-propvalCommon');
          this.guildeLineObj = res.responseData

        }
        else if(res.responseStatus == 'false'){
          this.guildeLineObj = null
          this.ngxService.stopLoader('loader-propvalCommon');
        }
      })
  }


  getBuildingTypeHi(text:any):string{
    if(text=="Independent Building"){
      return ' स्वतंत्र भवन'
     }else if(text=='IndependentFloor'){
      return 'स्वतंत्र तल'
     }
  
    else if(text=='Multistorey'){
       return'बहुमंजिला'
    }
    else{
      return 'खुली छत'
    }
   }
   
   close(){
 
    this.logger.log("fromDutyModule", this.fromDutyModule)
    if(this.fromDutyModule ){
      this.commService.propertyValuationResponseBean.next(this.propertyValuationResponseBean);
      this.matDialogRef.close({isContinued:false})
      return
    }
    else if(this.fromCMSModule){
      //this.matDialogRef.close({isContinued:false,fromCMSClose:true})
      let  impoundData :ImpoundData=new ImpoundData();
      
      // this.commService.emitPropertyResponseData(this.propertyValuationResponseBean)
      // this.commService.emitPropertyRequestData(this.propertyValuationRequestBean)
      // this.commService.emitPropertyIdentifaction(this.propIdentificationObj )
      impoundData.propIdentificationObj = this.propIdentificationObj;
      impoundData.propertyValuationResponseBean = this.propertyValuationResponseBean;
      impoundData.propertyValuationRequestBean = this.propertyValuationRequestBean;
      this.matDialogRef.close({isContinued:false,fromCMSClose:true,fromCMSModule:true,impoundData:impoundData})
      return
    }
    
    else if(this.fromCheckerModule){
      let  impoundData :ImpoundData=new ImpoundData();
      impoundData.propIdentificationObj = this.propIdentificationObj;
      impoundData.propertyValuationResponseBean = this.propertyValuationResponseBean;
      impoundData.propertyValuationRequestBean = this.propertyValuationRequestBean;
      //this.commService.emitPropertyResponseData(this.propertyValuationResponseBean)
      //this.commService.emitPropertyRequestData(this.propertyValuationRequestBean)
      //this.commService.emitPropertyIdentifaction(this.propIdentificationObj )
      this.matDialogRef.close({isContinued:false,fromCheckerClose:true,impoundData:impoundData})
      return
    }
    else if(this.fromEregModule){
      this.matDialogRef.close({propertyValuationResponseBean:this.propertyValuationResponseBean})
      return
    }
    else{      
            this.matDialogRef.close({isContinued:true})     
    }    
   }
  
   continue(){
    this.matDialogRef.close({isContinued:false})
   }
  
   getUnitMea(text:any):string{
    if(text=="HA"){
     return 'HA'
    }else if(text=='SQMT'){
     return 'SQM'
    }
      return' SFT'
   }


   getUnitMeaHi(text:any){
    if(text=="HA"){
      return 'हेक्टेयर'
     }else if(text=='SQMT'){
      return 'वर्ग मीटर'
     }
       return'वर्ग फुट'
   }
  

    getFloorName(id: any): string {
      return this.floorList.find((s: { id: number; }) => s.id == id).nameEn
    }
    
    getFloorNameHi(id: any): string {
      return this.floorList.find((s: { id: number; }) => s.id == id).nameHi
    }
    
    getUsageTypeHi(text:string):string{
     if(text=='undiverted'){
     return 'अव्यपवर्तित '
     }
    else if(text=='diverted'){
      return 'व्यपवर्तित '
     }
     else{
      return 'अव्यपवर्तित और व्यपवर्तित '
     }

     

    }

    // getSystemIdentified(subclausId:number):string{
    // let  isdeviation:string =this.lan==0?"NO":"नहीं"
    //  this.propertyValuationResponseBean.applicableSubClauseList.filter((sub:any)=>{
    //   if (sub.subClauseId==subclausId) {
    //     isdeviation=this.lan==0?"YES":"हां"
        
    //   }
    //  });
    //  return isdeviation
    // }
 
    isCheckOwnerDetail(propertyValuationResponseBean:PropertyValuationResponseBean):boolean{
    //  if((propertyValuationResponseBean.webGisResponseBeanDataArr!=null||undefined)&&propertyValuationResponseBean.webGisResponseBeanDataArr.length>=1 ){
      
    //   return true
    //  }
    //  if(propertyValuationResponseBean.mpenagarpalikaResponse!=null||undefined){
      
    //   return true
    //  }
    //  if(propertyValuationResponseBean.pnrdresponseData!=null||undefined){
    
    //   return true
    //  }
    
      return true
    }

    isObjectEmpty(obj: any): boolean {
      return Object.keys(obj).length != 0;
    }
    
 
    itemExists(id: Number): boolean {
      return this.propertyValuationResponseBean.applicableSubClauseList?.some(item=>item.subClauseId==id)
    }
 
 
 
 
   getPriceConversion(price:any){
    if(isNaN(price)){
      return ''
    }
    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: 0, // Minimum number of fraction digits
      maximumFractionDigits: 2, 
      useGrouping: false // Maximum number of fraction digits
      };

    return  new Intl.NumberFormat('en-US', options).format(price)
   }
 
   isNaN(myValue:number|string){
      if (typeof myValue === 'number' && isNaN(myValue)) {
        this.logger.log('The value is NaN.');
       return false
       }   
       else {
        this.logger.log('The value is a valid number or not a number at all.');
      return true
   }
   }
 
  
    getTotalValue(){
      let floorAmt=0
      this.propertyValuationResponseBean.independentBuildTypeBeanList.forEach(item=>{
        floorAmt=floorAmt+item.constCost
      })
      return floorAmt;
    }
 
 
 
 
 
 
 
  onBtnPrintClick() {

    let printContents, popupWin;
    printContents = document.getElementById('print-card').innerHTML;
    popupWin = window.open('', 'top=0,left=0,height=auto,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
    <head>
        <title>Print tab</title>
        <style>
            <style type = "text/css" >
            @media screen {

              body { padding: 0px;
                font: 400 14px/15px Roboto, "Helvetica Neue", sans-serif;
                margin: 0;
                font-family: Roboto, "Helvetica Neue", sans-serif;
             }

             * {
             font-family: 'Roboto', sans-serif;
             }

                *, *::before, *::after {
                    box-sizing: border-box;
                }
                table,
                td,
                th {font: 400 14px/15px Roboto, "Helvetica Neue", sans-serif;
                    border: 1px solid #ddd;
                    text-align: left;
                }

                table {
                    border-collapse: collapse;
                    width: 100%;
                    margin-bottom: 1rem;
                }

                th,
                td {
                  padding: 6px;
                  font: 400 14px/15px Roboto, "Helvetica Neue", sans-serif;
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0;
                    margin-left: 0;
                }
                .d-flex {
                  display: flex !important;
                  flex-wrap: wrap;
              }
              .col-md-12 {
                padding-right: 0px;
                padding-left: 0px;
                flex: 0 0 100%;
                max-width: 100%;
            }
                .col-md-6{
                  flex: 0 0 50%;
                  max-width: 50%;
                  padding-right: 0px;
                padding-left: 0px;
                }
                .form-group {
                  margin-bottom: 1rem;
              }
              fieldset {
                margin-bottom: 10px;
                border: 1px solid #afafaf;
            }
            legend.sidebar__widget-title {
              padding: 2px 6px 2px;
              /* border: 1px solid #ddd; */
              border-radius: 11px;
              font-weight: 500;
          }

.dis-title{d}

          }



            @media screen, print {
              body { padding: 0px;
                font: 400 14px/15px Roboto, "Helvetica Neue", sans-serif;
                margin: 0;
                font-family: Roboto, "Helvetica Neue", sans-serif;
             }

             * {
             font-family: 'Roboto', sans-serif;
             }

                *, *::before, *::after {
                    box-sizing: border-box;
                }
                table,
                td,
                th {font: 400 14px/15px Roboto, "Helvetica Neue", sans-serif;
                    border: 1px solid #ddd;
                    text-align: left;
                }

                table {
                    border-collapse: collapse;
                    width: 100%;
                    margin-bottom: 1rem;
                }

                th,
                td {
                  padding: 6px;
                  font: 400 14px/15px Roboto, "Helvetica Neue", sans-serif;
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 0;
                    margin-left: 0;
                }
                .d-flex {
                  display: flex !important;
                  flex-wrap: wrap;
              }
              .col-md-12 {
                padding-right: 0px;
                padding-left: 0px;
                flex: 0 0 100%;
                max-width: 100%;
            }
                .col-md-6{
                  flex: 0 0 50%;
                  max-width: 50%;
                  padding-right: 0px;
                padding-left: 0px;
                }
                .form-group {
                  margin-bottom: 1rem;
              }
              fieldset {
                margin-bottom: 10px;
                border: 1px solid #afafaf;
            }
            legend.sidebar__widget-title {
              padding: 2px 6px 2px;
              /* border: 1px solid #ddd; */
              border-radius: 11px;
              font-weight: 500;
          }

            }
        </style>
    </head>
    <body onload="window.print();window.close()">
    <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mt-3" style="text-align: center;
    border: none;">
    <tr>
      <td style="text-align: center;
      border: none;padding: 0px;">
        <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80" />
      </td>
    </tr>
  </table>
        ${printContents}

    </body>
</html>`
    );
    popupWin.document.close();

  }


}

export class Citizen {
    userId?: number;
    userName?: string;
    password?: string;
    captchaId?: string;
    captchaStr?: string;
    captchaStr2?: string;
    otp?: string;
    mobileNo?: string;
    email?: string;
    citizenType?: number;
    userType?: string;
    status?: string;
    partyType?: string;
    tempEregId?: string;
    key?: string;
    profileBase64?: string;
    ownerType?: string;
    eregId?: number;
    imageType?: string;
    // LoginType?: string;
}
export class CitizenForgot {
    userId?: number;
    userName?: string;
    captcha!: string;
    userType!: string;
    mobileNo?: string;
    status?: string;
}

export class RequestObject {
    userName?: string;
    captcha!: string;
    userType!: string;
    password!: string;
    captchaId!: string;
    captchaStr!: string;
    selectedOffice!: string;
    mobileNo!: string;
    otp!: string;
    userId!: string;
    enctoken!: string;
    redirectionmodule!: string;
    newPassword!: string;
    confirmPassword!: string;
}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ColorswitcherService } from '../shared/header/colorswitcher.service';
import { DefaultServiceService } from './default-service.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  //encapsulation: ViewEncapsulation.None
  
})
export class DefaultComponent implements OnInit {
  name="";
  redType:any;
  // themeColor:string;
  constructor(private colorservice:ColorswitcherService,public route:ActivatedRoute,private router: Router,private defaultServiceService:DefaultServiceService) {
    // this.colorservice.currentColors.subscribe(msg => this.themeColor = msg);
    // this.themeColor = sessionStorage.getItem('theme-color') || 'default-theme';
   
   }
 type:any
 hideHeaderAndFooter:boolean=false
  ngOnInit(): void {
    this.defaultServiceService.getDefaultType().subscribe(res=>{
      //this.type=res;
      if(res=='estamp-Login'){
        this.hideHeaderAndFooter=true
      }else{
        this.hideHeaderAndFooter=false
      }
   })
    this.redType=this.route.snapshot;
  //console.log("type------------------",this.type)
  this.route.queryParams.subscribe(param => {
    if (param['status'] != undefined && param['status'] == "1") {


      
    } else if (param['status'] != undefined && param['status'] == "0") {
      
     
    }
  })
}
  parentFunction(data:any){
    //console.warn(data)
    this.name=data.name;
  }


  // setTheme(theme:(any)){
  //   sessionStorage.setItem('theme-color' , theme);
  //   this.storedTheme = sessionStorage.getItem('theme-color') || "";
  // }
}

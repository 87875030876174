import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DutyOtherInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-input-fields';
import { MineralMstBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/mineral-mst-bean';
import { IgrsEregPropIdentificationBean, ImpoundData, ReCalDutyService } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { PropertyValuationResponseBean } from 'projects/common-lib/src/lib/models/PropertyValuationResponseBean';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommunicationServiceService  implements OnDestroy {
// 1
deedInstrumentSelected: DeedInstrumentBean = new DeedInstrumentBean();
sendDutyObjAfterImpound:Subject<any>;
sendDutyObjAfterImpoundMIS:Subject<any>;
sendDutyObjAfterCOSImpound:Subject<any>;
pvEregInit:Subject<ReCalDutyService>;
pvEregInitMIS:Subject<ReCalDutyService>;
pvEregInitFromChecker:Subject<boolean>;
pvEregInitFromCheckerCMSModule:Subject<boolean>;
pvEregInitFromCheckerCMSModuleMIS:Subject<boolean>;
eregImpoundDetailsLoaded:Subject<boolean>;
eregAdjudicationDetailsLoaded:Subject<boolean>;
fromDutyModule:Subject<boolean>;
fromCheckerModule:Subject<boolean>;
fromCMSModule:Subject<boolean>;
pvPopUpClose:Subject<boolean>;
instSearchOrDeedChange:Subject<boolean>;
fromDutyModuleBuilderShare:Subject<any>;
impoundDataEmit: EventEmitter<ImpoundData>;
propertyValuationResponseBean: EventEmitter<PropertyValuationResponseBean>;
propertyValuationRequestBean: EventEmitter<PropertyValuationRequestBean>;
propIdentificationObejct: EventEmitter<IgrsEregPropIdentificationBean>;
propertyValuationRequestBeanImpound: Subject<PropertyValuationRequestBean>;
propertyValuationRequestBeanCOSImpound: Subject<PropertyValuationRequestBean>;
propertyValuationRequestBeanCOSAdjudication: Subject<PropertyValuationRequestBean>;
selectedMineralListEmit : EventEmitter<MineralMstBean[]>;
selectedConsenterListEmit : EventEmitter<any>;
receivedFilter: EventEmitter<DeedInstrumentBean>;
clearDutyFields : EventEmitter<any>;
dutyOtherInputFieldsEmit : EventEmitter<DutyOtherInputFields[]>;

constructor() {
  console.log('CommunicationServiceService')
    this.receivedFilter = new EventEmitter<DeedInstrumentBean>();
    this.clearDutyFields = new EventEmitter<any>();
    this.pvEregInit =  new Subject<ReCalDutyService>();
    this.pvEregInitMIS =  new Subject<ReCalDutyService>();
    this.pvEregInitFromChecker =  new Subject<boolean>();
    this.pvEregInitFromCheckerCMSModule =  new Subject<boolean>();
    this.pvEregInitFromCheckerCMSModuleMIS =  new Subject<boolean>();
    this.eregImpoundDetailsLoaded=  new Subject<boolean>();
    this.eregAdjudicationDetailsLoaded=  new Subject<boolean>();
    this.fromDutyModule =  new Subject<boolean>();
    this.fromCheckerModule =  new Subject<boolean>();
    this.fromCMSModule =  new Subject<boolean>();
    this.pvPopUpClose =  new Subject<boolean>();
    this.instSearchOrDeedChange= new Subject<boolean>();
    this.fromDutyModuleBuilderShare =  new Subject<any>();
    this.propertyValuationResponseBean= new EventEmitter<PropertyValuationResponseBean>();
    this.propertyValuationRequestBean= new EventEmitter<PropertyValuationRequestBean>();
    this.propertyValuationRequestBeanImpound= new Subject<PropertyValuationRequestBean>();
    this.propertyValuationRequestBeanCOSImpound= new Subject<PropertyValuationRequestBean>();
    this.propertyValuationRequestBeanCOSAdjudication= new Subject<PropertyValuationRequestBean>();
    this.propIdentificationObejct= new EventEmitter<IgrsEregPropIdentificationBean>();
    this.sendDutyObjAfterImpound= new Subject<any>();
    this.sendDutyObjAfterImpoundMIS= new Subject<any>();
    this.sendDutyObjAfterCOSImpound= new Subject<any>();
    this.selectedMineralListEmit = new EventEmitter<MineralMstBean[]>();
    this.dutyOtherInputFieldsEmit = new EventEmitter<DutyOtherInputFields[]>();
    this.selectedConsenterListEmit =new EventEmitter<any>();
    this.impoundDataEmit =  new EventEmitter<ImpoundData>();
}
// 2
raiseEvent(id: DeedInstrumentBean): void {
    this.deedInstrumentSelected = id;
    this.receivedFilter.emit(this.deedInstrumentSelected);
  }

  emitImpoundData(impoundData:ImpoundData):void{
   let  impoundDataEmit = new ImpoundData();
   impoundDataEmit = impoundData;
    this.impoundDataEmit.emit(impoundDataEmit);
  }


  getImpoundData(){
    return  this.impoundDataEmit.asObservable();
  }

 raiseClearEvent():void {
  this.clearDutyFields.emit();
 }

 emitSelectedMineral(selectedMineralList:MineralMstBean[] ): void{
  this.selectedMineralListEmit.emit(selectedMineralList);
 }

 getSelectedMineral(){
  return this.selectedMineralListEmit.asObservable()
 }

 emitSelectedConsenterDetails(selectedConsenterList:Array<any>): void{
  this.selectedConsenterListEmit.emit(selectedConsenterList);
 }

 emitDutyOtherInputFields(dutyOtherInputFields: DutyOtherInputFields[]): void{
  this.dutyOtherInputFieldsEmit.emit(dutyOtherInputFields);
 }
 emitPropertyResponseData(propertyValuationResponseBean:PropertyValuationResponseBean,){
  let propertyValuationResponseBeanlet:PropertyValuationResponseBean=null
  propertyValuationResponseBeanlet=propertyValuationResponseBean
 this.propertyValuationResponseBean.emit(propertyValuationResponseBeanlet)
 }
 emitPropertyRequestData(propertyValuationRequestBean:PropertyValuationRequestBean){
  let propertyValuationRequestBeanlet:PropertyValuationRequestBean=null
  propertyValuationRequestBeanlet=propertyValuationRequestBean
  this.propertyValuationRequestBean.emit(propertyValuationRequestBeanlet)
 }
 emitPropertyIdentifaction(propIdentificationObejct:any){
  let propIdentificationObejctlet:any=null
  propIdentificationObejctlet=propIdentificationObejct
  this.propIdentificationObejct.emit(propIdentificationObejctlet)
 }
 getPropertyResponseData(){
  return this.propertyValuationResponseBean.asObservable()
  }
  getPropertyRequestData(){
    return this.propertyValuationRequestBean.asObservable()
  }
  getPropertyIdentifaction(){
    return this.propIdentificationObejct.asObservable()
  }

 ngOnDestroy(): void {
  console.log("CommunicationServiceService  OnDestroy")
    //this.propertyValuationResponseBean.unsubscribe();
   // this.propertyValuationRequestBean.unsubscribe();
    //this.propIdentificationObejct.unsubscribe();
    //this.propertyValuationRequestBeanImpound.unsubscribe();
    //this.propertyValuationRequestBeanCOSImpound.unsubscribe();
  }
}

<div class="card tools-card" style="width: 100%;" *ngIf="showTools">
    <a (click)="closeComponent()" class="m-close" style="display: block;">×</a>
    <h5 class="bg-primary measureTooltitle">{{lng==1?'मेज़रमेंट टूल':'Measurement Tool'}}</h5>
    <div class="card-body">
        <div class="measuredrawingTools">
            <div class="measuredrawingToolsTitle">
                <h3>{{lng==1?'ड्राइंग टूल':'Drawing Tool'}}</h3>
            </div>
            <div class="measuredrawingToolsButtonGroup">
                <button class="btn btn-outline-dark" (click)="drawLine()" title="Draw Polyline" [ngClass]="{'active': geomType === 'Length '}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-activity" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"/>
                  </svg></button>
                <button class="btn btn-outline-dark" (click)="drawPolygon()" title="Draw Polyline" [ngClass]="{'active': geomType === 'Area '}"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pentagon" viewBox="0 0 16 16">
                    <path d="M7.685 1.545a.5.5 0 0 1 .63 0l6.263 5.088a.5.5 0 0 1 .161.539l-2.362 7.479a.5.5 0 0 1-.476.349H4.099a.5.5 0 0 1-.476-.35L1.26 7.173a.5.5 0 0 1 .161-.54l6.263-5.087Zm8.213 5.28a.5.5 0 0 0-.162-.54L8.316.257a.5.5 0 0 0-.631 0L.264 6.286a.5.5 0 0 0-.162.538l2.788 8.827a.5.5 0 0 0 .476.349h9.268a.5.5 0 0 0 .476-.35l2.788-8.826Z"/>
                  </svg></button>
                <button class="btn btn-danger"  (click)="clearMeasureLayers()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                  </svg></button>
            </div>
        </div>
        <div class="measuredrawingTools">
            <div class="bufferdrawingToolsTitle">
                <h3>{{lng==1?'मेज़रमेंट यूनिट':'Measurement Unit'}}</h3>
            </div>
            <div>
                <select class="form-control form-control" [(ngModel)]="selectUnit" (change)="unitChange()">
                    <option *ngFor="let unit of distanceUnits;" [ngValue]="unit">
                        {{unit.name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="measuredrawingTools">
            <div class="bufferdrawingToolsTitle">
                <h3>{{lng==1?'परिणाम':'Result'}}</h3>
            </div>
            <div>
              
                    <div *ngIf="geomType == 'Area '">{{geomType}} :<span>{{currentLengthAndArea}}  Sq{{this.selectUnit.label}}</span></div>
                    <div *ngIf="geomType == 'Length '">{{geomType}} :<span>{{currentLengthAndArea}}  {{this.selectUnit.label}}</span></div>
            </div>
        </div>
    </div>
</div>
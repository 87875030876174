import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
//import { PropertyValuationComponent } from '../../property-valuation/property-valuation.component';
import { CalculateGuidelineValueComponent } from '../calculate-guideline-value/calculate-guideline-value.component';
export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
@Component({
  selector: 'app-sale-assignment',
  templateUrl: './sale-assignment.component.html',
  styleUrls: ['./sale-assignment.component.scss']
})
export class SaleAssignmentComponent implements OnInit {
  task: Task = {
    name: 'Stamp Duty Exemption',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Option 1', completed: false, color: 'primary'},
      {name: 'Option 2', completed: false, color: 'primary'},
      {name: 'Option 3', completed: false, color: 'primary'},
    ],
  };
  task1: Task = {
    name: 'Registration Fee Exemption.',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Option 1', completed: false, color: 'primary'},
      {name: 'Option 2', completed: false, color: 'primary'},
      {name: 'Option 3', completed: false, color: 'primary'},
    ],
  };
  panelOpenState = false;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };
  public pieChartOptions1: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };
  public pieChartLabels: Label[] = [['Total', 'Stamp Duty'], ['Total', 'Payable ', 'Registration Fees']];
  public pieChartData: SingleDataSet = [300, 500];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins:any = [];

  public pieChartLabels1: Label[] = [['Govt', 'Stamp Duty'], ['Municipal', 'Stamp Duty '], ['Janpad', 'Stamp Duty '], ['Upkar', 'Stamp Duty ']];
  public pieChartData1: SingleDataSet = [300, 500, 700, 100];
  public pieChartType1: ChartType = 'pie';
  public pieChartLegend1 = true;
  public pieChartPlugins1:any = [];
 

  
  constructor(public dialog: MatDialog) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
   }
   
  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }
  ngOnInit(): void {
  }
  openDialog1(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(CalculateGuidelineValueComponent, {
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '100%',
      // width: '100%',
      // panelClass: 'full-screen-modal'
    });
  }
}


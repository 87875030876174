

<header class="citizenHeader sticky-top1">
    <div class="d-nonee">
        <!-- <nav class="navbar navbar-expand-md navbar-light pad0 d-none" id="topnav">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="fa fa-gear fa-spin"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item font-selection px-1">
                            <a href="javascript:void(0);" class="sm js-font-decrease" title="Increase font size">A-</a>
                            <a href="javascript:void(0);" class="md js-font-normal" title="Reset font size">A</a>
                            <a href="javascript:void(0);" class="lg js-font-increase" title="Decrease font size">A+</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0)" class="palette theme-black" data-theme="theme2">A</a>
                            <a href="javascript: void(0)" class="palette theme-regular" data-theme="theme1">A</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">{{'top-bar.screen-reader' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#content-section" class="nav-link">{{'top-bar.skip-to-main-content' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a href="#navigation" class="nav-link">{{'top-bar.skip-to-navigation' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a href="" class="nav-link">
                                <i class="fa fa-sitemap"></i></a>
                        </li>
                    </ul>
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item deptlogin">
                            <a class="nav-link" routerLink="/login"><i class="fa fa-building-o"></i>
                                &nbsp;{{'department-login-text' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <div *ngIf="langOptionFlag==1">
                                <a (click)="changeLanguageToEnglish()" routerLinkActive="router-link-active"
                                    class="nav-link" style="cursor: pointer;">
                                    <i class="fa fa-globe"></i> &nbsp;{{'top-bar.selected-language' | translate}}</a>
                            </div>
                            <div *ngIf="langOptionFlag==0">
                                <a (click)="changeLanguageToHindi()" routerLinkActive="router-link-active" class="nav-link"
                                    style="cursor: pointer;">
                                    <i class="fa fa-globe"></i> &nbsp;{{'top-bar.selected-language' | translate}}</a>
                            </div>
    
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->
    
        <div class="logo-wrapper pb-2">
            <div class="container">
                <div class="row  align-items-center">
                    <div class="col-md-6 col-sm-4">
                        <div class="logo-holder">
                            <div class="logo">
                                <a routerLink="/">
                                    <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80"/>
                                </a>
                            </div>
                            <div class="logo-title d-none">
                                <h1>{{'default-header-text-1' | translate}} </h1>
                                <!-- <p>{{'default-header-text-2' | translate}}</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6  col-sm-8 text-right d-print-none">  
                        <ul class="top-nav mr-auto mb-2">
                            <li class="nav-item">
                                <button class="themeMenu" mat-button [matMenuTriggerFor]="colorswitcher"><mat-icon>format_color_fill</mat-icon> </button>
                              
        
                                <mat-menu #colorswitcher="matMenu" class="colorswitcheroptions">
                                    <div class="theme-options">
                                        <div class="clrsoptbox">
                                            <div class="clrsopt mr-2" id="theme-default" [ngClass]="(themeColor === 'theme-default') ? 'active' :  ''" (click)="setTheme('theme-default')"> </div>
                                            Default Theme
                                        </div>
                                        <div class="clrsoptbox">
                                            <div class="clrsopt mr-2" id="theme-green" [ngClass]="(themeColor === 'theme-green') ? 'active' :  ''" (click)="setTheme('theme-green')"></div>    
                                            Green Theme
                                        </div>
                                        <!-- <div class="clrsoptbox">
                                            <div class="clrsopt mr-2" id="theme-blue" [ngClass]="(themeColor === 'theme-blue') ? 'active' :  ''" (click)="setTheme('theme-blue')"></div>    
                                            Blue Theme
                                        </div> -->
                                        <div class="clrsoptbox">
                                            <div class="clrsopt mr-2" id="theme-dark" [ngClass]="(themeColor === 'theme-dark') ? 'active' :  ''" (click)="setTheme('theme-dark')"></div>    
                                            Dark Theme
                                        </div>
                                    </div>
                                   
                                </mat-menu>
                            </li>
                            <li class="nav-item font-selection">
                                <a  id="minus-zoom" [ngClass]="(defaultZoom === 'minus-zoom') ? 'active' :  ''" (click)="setZoom('minus-zoom')" class="sm js-font-decrease ml-2" title="Increase font size">A<sup>-</sup> </a>&nbsp; | &nbsp;
                                <a id="default-zoom" [ngClass]="(defaultZoom === 'default-zoom') ? 'active' :  ''" (click)="setZoom('default-zoom')" class="md js-font-normal" title="Reset font size">A</a>&nbsp; | &nbsp;
                                <a id="plus-zoom" [ngClass]="(defaultZoom === 'plus-zoom') ? 'active' :  ''" (click)="setZoom('plus-zoom')" class="lg js-font-increase" title="Decrease font size">A<sup>+</sup></a>&nbsp; | &nbsp;
                            </li>
                           
                            <li class="nav-item">
                                   
                                    <div *ngIf="langOptionFlag==1">
                                        <a (click)="changeLanguageToEnglish()" routerLinkActive="router-link-active"
                                            class="nav-link " style="cursor: pointer; " placement="bottom"
                                            ngbTooltip="Change to English">
                                            <i class="fa fa-globe"></i> &nbsp;{{'selected-language' | translate}} </a>
                                    </div>
                                    <div *ngIf="langOptionFlag==0">
                                        <a (click)="changeLanguageToHindi()" routerLinkActive="router-link-active"
                                            class="nav-link " style="cursor: pointer; " placement="bottom"
                                            ngbTooltip="Change to Hindi">
                                            <i class="fa fa-globe"></i> &nbsp;{{'selected-language' | translate}} </a>
                                    </div>
            
                                </li>
                        
                        
                         
                    </ul>
              
                    <span routerLink="/" matRipple  class="homeIcon float-left"><mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon></span>
    
                    <!-- <div matRipple class="openNavMenu">
                        <div  id="toggle">
                            <div class="one"></div>
                            <div class="two"></div>
                            <div class="three"></div>
                        </div>
                        <span class="menuName" >{{'Menu' | translate}}</span>
                    </div> -->
                      
                  
                            <!-- <a (click)="open(loginOptions)" class="btn-login mr-2" *ngIf="isUserLoggedIn!=true">
                                <i class="fa fa-lock"></i> &nbsp;{{'login' | translate}}
                            </a>
                         
                         
                            <a  routerLink="/registration" class="btn-register btn-primary" *ngIf="isUserLoggedIn!=true">
                                <i class="fa fa-user-plus"></i> &nbsp;{{'register' | translate}}
                            </a> -->
    
                          
    
                            <!-- <div *ngIf="isUserLoggedIn==true">
                                <button mat-button [matMenuTriggerFor]="menu" class="btn-register btn-theme"> 
                                  
                                    Profile &nbsp;
                                    <i class="fa fa-caret-down"></i> 
                                </button>
                                <mat-menu class="profileMenuContent"  #menu="matMenu" xPosition="before">
                                  
                                <div *ngIf="userType=='admin'">
                                    <div class="profileDetail">
                                        <span class="userImg"></span>
                                        <span class="text-white"> {{'admin-user' | translate}} </span>
                                        
                                      <h4 class=""> {{'admin-user' | translate}}</h4>
                                      </div>
                                  <button mat-menu-item routerLink="admin/get-user-profile">
                                    <mat-icon>account_circle</mat-icon>
                                    {{'profile' | translate}}
                                  </button> 
                                  <button mat-menu-item routerLink="admin/changepassword">
                                    <mat-icon>key</mat-icon>
                                    {{'change-password' | translate}}
                                  </button> 
                                </div>
                                <div *ngIf="userType=='citizen'">
                                    <div class="profileDetail">
                                        <span class="userImg"></span>
                                        <span class="text-white"> {{'citizen-user' | translate}} </span>
                                        
                                      <h4 class=""> {{'citizen-user' | translate}}</h4>
                                      </div>
                                  <button mat-menu-item routerLink="citizen/profile">
                                    <mat-icon>account_circle</mat-icon>
                                    {{'profile' | translate}}
                                  </button> 
                                  <button mat-menu-item routerLink="citizen/changepassword">
                                    <mat-icon>key</mat-icon>
                                    {{'change-password' | translate}}
                                  </button>
                                </div>
                                  <button mat-menu-item (click)="logoutAdmin()">
                                    <mat-icon>exit_to_app</mat-icon>
                                    {{'logout' | translate}}
                                  </button>
                                </mat-menu> 
                              </div> -->
                             
    
                       
                    </div>
    
                </div>
            </div>
        </div>
        <!-- nav -->
        
    
          
    
    </div>
    </header>
    
    
      
      <div id="menu" class="sticky-top">
       
    <div class="mainNav clearfix " id=" ">
    
    
        <nav class="navbar navbar-expand-md bg-theme p-0">
            <div class="container">
              
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fa fa-bars"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav">
                  <li class="nav-item active">
                    <a class="nav-link" routerLink="/">{{'home' | translate}} <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" routerLink="/property-valuation"> {{'property-valuation.property-valuation' | translate}} <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" routerLink="/duty-calculation">{{'homepage.Duty-Calcutation' | translate}} <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" routerLink="/citizenSuggestion">{{'homepage.View-Guideline' | translate}} <span class="sr-only">(current)</span></a>
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" routerLink="/complaint-request">{{'department-onboarding-form' | translate}}<span class="sr-only">(current)</span></a>
                  </li>
                 
                </ul>
              </div>
            </div>
          </nav>
    
    
    
    
       
    </div>
    
    
    </div>
    
    
        <!-- /navigation -->
    
    
    
        
          <!-- /Login Modal -->
    
    
    
          <!-- Button trigger modal -->
    
      
      <!-- Modal -->
    
    
    
    
    
        <ng-template #loginOptions let-modal>
            <div class="loginoptionsmodal">
                <div class="modal-header">
                    <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div> 
                    <div class="modal-body p-5">
                    
                        <a routerLink="/clogin" class="loginoptions mb-3 d-flex" (click)="modal.dismiss('Cross click')">
                            <span class="loginoptimg"> <img src="assets/images/living-person.png" height="35"> </span>
                            <p class="m-0">{{'login-citizen' | translate}}</p>
                        </a>
                        <a routerLink="/login" class="loginoptions mb-3 d-flex" (click)="modal.dismiss('Cross click')">
                            <span class="loginoptimg"><img src="assets/images/juristic-person.png" height="35"> </span>
                            <p class="m-0">{{'login-department' | translate}}</p>
                        </a> 
                        <a routerLink="/complaint-request" class="loginoptions mb-3 d-flex" (click)="modal.dismiss('Cross click')">
                            <span class="loginoptimg"><img src="assets/images/building.png" height="35"> </span>
                            <p class="m-0">{{'department-onboarding-form' | translate}} </p>
                        </a> 
        
                    </div> 
            </div>
                
          </ng-template>  
          
<div class="card mb-3 shadow1">
    <div class="card-body p-1">
       <div class="">
          <form class="row ">
             <div class="col-lg-6 form-group mb-3"><label class="sr-only">Valuation As Per Guideline</label>
                <input type="text" class="form-control" placeholder="Valuation As Per Guideline">
                <button mat-raised-button (click)="openDialog1('3000ms', '1500ms')" class="btn btn-primary mt-3 ">Calculate Guideline Value </button>
               
             </div>
             <div class="col-lg-6 form-group mb-0"><label class="sr-only">Consideration Amount</label>
                <input type="text" class="form-control" placeholder="Consideration Amount">
             </div>
             <div class="col-lg-6 form-group pl-3 mb-0">
                <label class="pr-4"> Do you want to avail any kind of Exemption.</label>
                <input id="selectOption1" type="checkbox" value="modifyAndSave" name="selectOption" checked class="w-auto">
                <label class="form-check-label1 px-2" for="inlineRadio1">Yes</label>
                <section class="example-section">
                   <span class="example-list-section">
                   <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                   [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                   {{task.name}}
                   </mat-checkbox>
                   </span>
                   <span class="example-list-section">
                      <ul>
                         <li *ngFor="let subtask of task.subtasks">
                            <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                            (ngModelChange)="updateAllComplete()">
                            {{subtask.name}}
                            </mat-checkbox>
                         </li>
                      </ul>
                   </span>
                </section>
                <section class="example-section">
                   <span class="example-list-section">
                   <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                   [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                   {{task1.name}}
                   </mat-checkbox>
                   </span>
                   <span class="example-list-section">
                      <ul>
                         <li *ngFor="let subtask of task1.subtasks">
                            <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                            (ngModelChange)="updateAllComplete()">
                            {{subtask.name}}
                            </mat-checkbox>
                         </li>
                      </ul>
                   </span>
                </section>
             </div>
             <div class="col-lg-6 form-group  p-3 mb-0"></div>
             <mat-accordion class="col-md-12">
                <mat-expansion-panel hideToggle class="shadow-none p-0">
                   <mat-expansion-panel-header class="p-0">
                      <button mat-flat-button class="btn btn-primary mr-3" type="button" id="button-addon2"> Calculate
                      Duty</button>
                      <button mat-flat-button class="btn btn-secondary" type="button"> Reset</button>
                   </mat-expansion-panel-header>
                   <div class="card-footer p-0 row">
                      <div class="col-md-6">
                         <table class="w-100 table shadow1">
                            <tbody>
                               <tr>
                                  <td class="p-0" colspan="2">
                                     <h3 class="bg-theme text-light p-2 mb-1 font-weight-normal">Stamp Duty</h3>
                                  </td>
                               </tr>
                               <tr>
                                  <td class="p-2">Govt Stamp Duty</td>
                                  <td class="p-2">44,000</td>
                               </tr>
                               <tr>
                                  <td class="p-2">Municipal Duty</td>
                                  <td class="p-2">26,000</td>
                               </tr>
                               <tr>
                                  <td class="p-2">Janpad Duty</td>
                                  <td class="p-2">8800</td>
                               </tr>
                               <tr>
                                  <td class="p-2">Upkar</td>
                                  <td class="p-2">4400</td>
                               </tr>
                               <tr>
                                  <td class="font-weight-bold">Total Stamp Duty</td>
                                  <td class="font-weight-bold">83,600</td>
                               </tr>
                               <tr class="">
                                  <td class="p-0" colspan="2">
                                     <h3 class="bg-theme text-light p-2 mb-1 font-weight-normal">Registration</h3>
                                  </td>
                               </tr>
                               <tr>
                                  <td>Registration</td>
                                  <td>26,500</td>
                               </tr>
                               <tr>
                                  <td class="font-weight-bold">Total Payable Registration Fees</td>
                                  <td class="font-weight-bold">26,500</td>
                               </tr>
                            </tbody>
                         </table>
                      </div>
                      <div class="col-md-6 pt-3">
                         <div style="display: block;">
                            <canvas baseChart height="135" [data]="pieChartData" [labels]="pieChartLabels"
                            [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                            [legend]="pieChartLegend">
                            </canvas>
                         </div>
                         <div style="display: block;" class="mt-5">
                            <canvas baseChart height="135" [data]="pieChartData1" [labels]="pieChartLabels1"
                            [chartType]="pieChartType1" [options]="pieChartOptions1" [plugins]="pieChartPlugins1"
                            [legend]="pieChartLegend1">
                            </canvas>
                         </div>
                      </div>
                   </div>
                </mat-expansion-panel>
             </mat-accordion>
          </form>
       </div>
    </div>
  </div>
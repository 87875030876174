<div class="fid-header"></div>
<lib-alertmessage #alertMessage></lib-alertmessage>
<div class="breadcrumb-content">
    <div class="row">
        <div class="col-lg-12">
            <h2>
                {{
                lan == 0
                ? "Feedback Form for E-Registry Document"
                : "ई-रजिस्ट्री दस्तावेज़ के लिए फीडबैक फ़ॉर्म"
                }}
            </h2>
        </div>
    </div>
</div>

<mat-card class="example-card mb-3">
    <mat-card-title></mat-card-title>
    <mat-card-content class="mat-card-content">
        <fieldset class="legend-section">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="form-group">
                        <label>
                            {{
                            lan == 0 ? "Temporary Registry ID" : "अस्थायी रजिस्ट्री आईडी"
                            }}
                        </label>
                        <input type="text" class="form-control" [value]="igrsEregTxnDetlsBean.tempEregId" disabled />
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="form-group">
                        <label>
                            {{
                            lan == 0
                            ? "Registered Document Number"
                            : "पंजीकृत दस्तावेज़ संख्या"
                            }}
                        </label>
                        <input type="text" class="form-control" [value]="igrsEregTxnDetlsBean.regId" disabled />
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="form-group">
                        <label>
                            {{ "legacyDocumentRequest.registrationDate" | translate }}
                        </label>
                        <input type="text" class="form-control"  [value]="igrsEregTxnDetlsBean.eregCompletionDate | date:'dd-MM-yyyy'"  disabled />
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6">
                    <div class="form-group">
                        <label>
                            {{ "DecisionOnDocument.SubRegistrarOffice" | translate }}
                        </label>
                        <input type="text" class="form-control" [value]="lan==0?srOfficeNameEn:srOfficeNameHi" disabled />
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
                {{ lan == 0 ? "Questionnaire" : "प्रश्नावली" }}
            </legend>
            <div class="col-md-12">

                <div *ngFor="let question of ratingEregFeedbackQuesList">
                    <app-feedback-question [question]="question" [stars]="[1, 2, 3, 4, 5]"
                        [selectedValue]="0" (valueChange)="onValueChange($event)"></app-feedback-question>
                </div>
                <div>
                    <div class="quest_card">
                        <div class="card_head">
                            <div class="quest_order">{{noRatingEregFeedbackQuesList[0]?.id}}</div>
                            <div class="question">
                                {{(lan==0)?(noRatingEregFeedbackQuesList[0]?.quesEn):(noRatingEregFeedbackQuesList[0]?.quesHi)}}
                            </div>
                        </div>
                        <div class="card_body">
                            <div class="rating rating_box">
                                <div class="rating_list">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            [(ngModel)]="selectedOption" id="inlineRadio1" value="Yes">
                                        <label class="form-check-label" for="inlineRadio1">
                                            {{'yes' | translate}}
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="inlineRadio2" value="No" [(ngModel)]="selectedOption" (click)="onInputRadio()">
                                        <label class="form-check-label" for="inlineRadio2">
                                            {{'no_swal' | translate}}
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline flex-column" *ngIf="selectedOption === 'Yes'">
                                        <input type="text" class="form-control" [OnlyNumber]="true" (click)="onInputRadio()" 
                                        [(ngModel)]="additionalAmount" maxlength="10"/>

                                        <div *ngIf="additionalAmountDiv">
                                            <small  class="rstarcolor"> {{(lan==0)?'Please Enter Additional Amount':'कृपया अतिरिक्त राशि दर्ज करें'}}</small>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="quest_card">
                        <div class="card_head">
                            <div class="quest_order">{{noRatingEregFeedbackQuesList[1]?.id}}</div>
                            <div class="question">
                                {{(lan==0)?(noRatingEregFeedbackQuesList[1]?.quesEn):(noRatingEregFeedbackQuesList[1]?.quesHi)}}
                            </div>
                        </div>
                        <div class="card_body">
                            <div class="rating rating_box">
                                <div class="rating_list">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="2" 
                                    maxlength="500" [(ngModel)]="remarks"
                                        aria-hidden="false"></textarea>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </fieldset>
        <div class="text-right">
            <button class="btn btn-primary" (click)="onSubmitFeedbackForm()" [disabled]="isSubmit">
                {{'forest-land-involved.submit' | translate}}
            </button>
        </div>
    </mat-card-content>
</mat-card>

<ngx-ui-loader></ngx-ui-loader>
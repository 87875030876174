import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { DutyConstants } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-constants';
import { DutyCalculationComponent } from '../duty-calculation.component';
import { CommunicationServiceService } from '../duty-service/communication-service.service';
export interface DialogData {
  propertySel: number;
  selctedhasraList: Array<any>;
  selectedConsenterList:  Array<any> ;
}
@Component({
  selector: 'app-consenter-details',
  templateUrl: './consenter-details.component.html',
  styleUrls: ['./consenter-details.component.scss']
})
export class ConsenterDetailsComponent implements OnInit {
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;

 //self = false;
  //other = false;

  Considerationself = false;
  //Considerationother = false;

  propertyValId:number;
  propIdSelect:number;
  propertylist: Array<any> = [];
  consDetlsAttribute: any = {};
  totalConsenter:number;
  withConsideration: boolean;
  considerationAmount:number;
  consenterDetailsList: Array<any> = [];
  consenterDetailsArray: Array<any> = [];

  constructor(private commService: CommunicationServiceService,private  dialogRef:  MatDialogRef<DutyCalculationComponent>, 
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: DialogData) { 

    if(data!=undefined){
      this.propertylist  = data.selctedhasraList;
      this.propertyValId = data.propertySel;
      this.consenterDetailsList  = data.selectedConsenterList;

      if(this.consenterDetailsList !=undefined && this.consenterDetailsList !=null){
        this.consenterDetailsList.forEach(c=>{
          const index: number = this.propertylist.indexOf( c.khasraId);
                if (index !== -1) {
                    this.propertylist.splice(index, 1);
                }
        }); 
      }else{
              this.consenterDetailsList  =  [];
      }
    }
    this.consDetlsAttribute.withConsideration = false;
  }

  ngOnInit(): void {
    
  }

  checkBtnConsideration(divid: string){
    if (divid === "self_tab") {
      this.Considerationself = true;
      //this.Considerationother = false;
      this.consDetlsAttribute.withConsideration = true;
    }
    else if (divid === "other_tab") {
      this.Considerationself = false;
      //this.Considerationother = true;
      this.consDetlsAttribute.withConsideration = false;
    }
  }

  addConsenterDetls() {
    if(this.consDetlsAttribute == null || this.consDetlsAttribute == undefined){
     let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.constDetlsErr1_En;
      errMsg.responseMessageEn= DutyConstants.constDetlsErr1_En;
      errMsg.responseMessageHi= DutyConstants.constDetlsErr1_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
       return;
    }else if(this.consDetlsAttribute.totalConsenter==null){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.constDetlsErr2_En;
      errMsg.responseMessageEn= DutyConstants.constDetlsErr2_En;
      errMsg.responseMessageHi= DutyConstants.constDetlsErr2_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
      return;
    }else if(this.consDetlsAttribute.withConsideration==null){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.constDetlsErr3_En;
      errMsg.responseMessageEn= DutyConstants.constDetlsErr3_En;
      errMsg.responseMessageHi= DutyConstants.constDetlsErr3_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
      return;
    }else if(this.consDetlsAttribute.withConsideration==="Yes" && this.consDetlsAttribute.considerationAmount==null){
      let errMsg: any = {};
      errMsg.responseMessage = DutyConstants.constDetlsErr4_En;
      errMsg.responseMessageEn= DutyConstants.constDetlsErr4_En;
      errMsg.responseMessageHi= DutyConstants.constDetlsErr4_Hi;
      this.alertmessage.errorAlertMessage(errMsg);
    }else{
          this.consDetlsAttribute.khasraId  = this.propIdSelect;
          this.consenterDetailsList.push(this.consDetlsAttribute);
          this.consDetlsAttribute = {};
          this.Considerationself = false;
          this.propIdSelect=null;
          this.consDetlsAttribute.withConsideration = "No";
          //this.Considerationother = true;
    } 
  }

  deleteConsenterDetls(index: any,consDetlsAttribute:any) {
  this.consenterDetailsList.splice(index, 1);
  this.propertylist.push(consDetlsAttribute.khasraId);
  }

  onPropertySelect(){
    console.log("onPropertySelect:propIdSelect-",this.propIdSelect);
    const index: number = this.propertylist.indexOf(this.propIdSelect);
    if (index !== -1) {
        this.propertylist.splice(index, 1);
    }
  }

  saveConsenterDetls(){
    console.log("saveConsenterDetls");
    if(this.consenterDetailsList!==undefined && this.consenterDetailsList!=null){
      console.log("saveConsenterDetls:consenterDetailsList--",this.consenterDetailsList);
      this.commService.emitSelectedConsenterDetails(this.consenterDetailsList);
      this.closeMe();
    }else{
              console.log(" consenter details not defined properly ");
              let errMsg: any = {};
              errMsg.responseMessage = DutyConstants.constDetlsErr1_En;
              errMsg.responseMessageEn= DutyConstants.constDetlsErr1_En;
              errMsg.responseMessageHi= DutyConstants.constDetlsErr1_Hi;
              this.alertmessage.errorAlertMessage(errMsg);
              return;
          }
}
  closeMe() {
      this.dialogRef.close();
  }
  

}

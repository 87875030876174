import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import { Subject, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SrOfficeDisplayWebSocketService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject$ = new Subject<any>();
  private srOfficeDemoId: number;

  public messages$ = this.messagesSubject$.asObservable();

  public connect(url: string, srOfficeDemoId: number): void {
    this.srOfficeDemoId = srOfficeDemoId;
    const wsUrl = this.convertToWebSocketUrl(url);

    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = new WebSocketSubject(wsUrl);
      this.socket$.subscribe(
        (message) => this.messagesSubject$.next(message),
        (err) => console.error(err),
        () => console.warn('Completed!')
      );

      // Send the srOfficeDemoId every 5 seconds
      interval(10000).pipe(
        switchMap(() => {
          this.sendMessage(this.srOfficeDemoId);
          return [];
        })
      ).subscribe();
    }
  }

  private convertToWebSocketUrl(httpUrl: string): string {
    const wsUrl = httpUrl.replace(/^http/, 'ws');
    return wsUrl;
  }

  public sendMessage(srOfficeDemoId: number): void {
    if (this.socket$) {
      this.socket$.next(srOfficeDemoId);
    }
  }

  public close(): void {
    if (this.socket$) {
      this.socket$.complete();
    }
  }
}

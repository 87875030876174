import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationServiceService } from '../duty-service/communication-service.service';
export interface DialogData {
  builderShare: number;
  addPropImpound:boolean
  fromCMSModule:boolean
  fromCheckerModule:boolean
  propIdAndLandParcelFromPV:[]
}
@Component({
  selector: 'app-calculate-guideline-value',
  templateUrl: './calculate-guideline-value.component.html',
  styleUrls: ['./calculate-guideline-value.component.scss']
})

export class CalculateGuidelineValueComponent implements OnInit {

  builderShare:number;
  addPropImpound:boolean=false;
  propData:any
  fromCMSModule:boolean=false;
  fromCheckerModule:boolean=false;
  propIdAndLandParcelFromPV:any=[]
  constructor(private _commService : CommunicationServiceService, private  dialogRefGV:  MatDialogRef<CalculateGuidelineValueComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData) { 
   this.propIdAndLandParcelFromPV=data.propIdAndLandParcelFromPV
   this.builderShare =  data?.builderShare;
   this.addPropImpound = data?.addPropImpound;
   this.propData = data;
   this.fromCMSModule = data?.fromCMSModule;
   this.fromCheckerModule = data?.fromCheckerModule;
   console.log("CalculateGuidelineValueComponent:duplicate- ",this.propIdAndLandParcelFromPV);
   console.log("CalculateGuidelineValueComponent:builderShare- ",this.builderShare);
  }

  ngOnInit(): void {
    console.log("CalculateGuidelineValueComponent - ngOnInit");

    

    if(this.fromCMSModule){
      this._commService.fromCMSModule.next(true);
      this._commService.fromDutyModuleBuilderShare.next( this.propData);
    }else if(this.fromCheckerModule){
     // this._commService  = new CommunicationServiceService();
      this._commService.fromCheckerModule.next(true);
      this._commService.fromDutyModuleBuilderShare.next( this.propData);
    }else{
          this._commService.fromDutyModule.next(true);
          this._commService.fromDutyModuleBuilderShare.next( this.propData);
    }
  }
  public  closeMe() {
    this._commService.pvPopUpClose.next(true);
    this.dialogRefGV.close();
  }

  ngOnDestroy(){

    console.log("CalculateGuidelineValueComponent:ngOnDestroy");
   // this._commService.fromCheckerModule.next(false);
   // this._commService.fromCMSModule.next(false);
   // this._commService.fromDutyModule.next(false);
    //this.dialogRefGV.close();
    this.closeMe();
  }
}

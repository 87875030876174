import { Component, Renderer2, ViewChild } from '@angular/core';
import { ActivationStart, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, BreadcrumbService } from 'angular-crumbs';
import { MapMyindiaService } from 'projects/common-lib/src/lib/helper-lib/services/MapMyindia.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';

import { Title } from '@angular/platform-browser';
import { ColorswitcherService } from 'projects/front-web/src/app/shared/header/colorswitcher.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'citizen-web';
  previousUrl: string;
  lan: number;
   
  themeColor:string;
  defaultZoom:string;
  // storedTheme: string = sessionStorage.getItem('theme-color');

  storedTheme:string = sessionStorage.getItem('theme-color') || "";
  storedZoom:string = sessionStorage.getItem('zoom-level') || "";


 
  @ViewChild(RouterOutlet) outlet: RouterOutlet;

  constructor(private router: Router, private renderer: Renderer2,private titleService:Title,
    private colorservice:ColorswitcherService, private translateService: TranslateHEService,private translate:TranslateService, private ngxService: NgxUiLoaderService){
      this.translate.setDefaultLang("hi");
      const selectedLanguage = localStorage.getItem("selectedLanguage");
      if (selectedLanguage !== null) {
        this.translate.use(selectedLanguage);
      } else {
        this.translate.use("hi");
      }
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
        this.titleService.setTitle(msg==1?'पंजीयन एवं मुद्रांक विभाग (वाणिज्यिक कर) मध्य प्रदेश':'Registration and Stamp, Commercial Tax Department, Government of Madhya Pradesh')
       })
    this.colorservice.currentColors.subscribe(msg => this.themeColor = msg);
    this.themeColor = sessionStorage.getItem('theme-color') || 'default-theme';

    this.colorservice.currentZoom.subscribe(msg => this.defaultZoom = msg);
    this.defaultZoom = sessionStorage.getItem('zoom-level') || 'default-zoom';
    this.router.events
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.ngxService.startLoader('loader-modules');
        if (this.previousUrl) {
         // console.log("remove previous url",this.previousUrl)
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        let currentUrlSlug = event.url.slice(1)
        if (currentUrlSlug) {
          //console.log("add current url",currentUrlSlug)
          this.renderer.addClass(document.body, currentUrlSlug);
        }
        this.previousUrl = currentUrlSlug;
      }else if (event instanceof NavigationEnd) {
        this.ngxService.stopLoader('loader-modules');
      }
    });
  }
 
  ngOnInit(){
   
    // this.router.events.subscribe(e  => {
    //   if (e instanceof ActivationStart && e.snapshot.outlet === "primary")
    //     //this.outlet.deactivate();
    // });
  }



 








}

export class ResidentialCumCommercialConstructionType{
    totalTypeArea:number|string;
	rccArea:number;
	rbcArea:number;
	tinShadeArea:number;
	kacchaKabeluArea:number;
	shopArea:number;
	officeArea:number;
	godownArea:number;
	rccRate:number|string
	rbcRate:number|string
	tinShadeRate:number|string
	kacchaKabeluRate:number|string
	godownRate:number|string
   officeRate:number|string
   shopRate:number|string

}
import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SrOfficeDisplayService {

  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService,private customeHttpClient: CustomHttpClient
    ,private _AESEncryptDecryptService: AESEncryptDecryptService) {
      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
          this.settings = settings;
      });
  }

  getSrDisplayData(srOfficeDemoId:number){
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_url + "common/ereg/getSrDisplayData/"+srOfficeDemoId
    ).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  getSROffices(): Observable<any> {
    let encVal = this._AESEncryptDecryptService.encrypt("type_id=4&parent_id=1&OnlyValidLiveSRO=1");
    return this.customeHttpClient.Get<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/get_demographies_map?request=" + encVal
    ).pipe(
        map((result: any) => {

            if (result && result.responseStatus == 'true') {
                return result;
            } else {
                return result;
            }

        })
    );
  }

  encryptEregId(eregNo:string){
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_url + "common/ereg/getEregIdFromDB/"+eregNo
    ).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
}

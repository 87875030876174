import { Injectable } from '@angular/core';
import { CustomHttpClient } from '../../httpclient/custom.httpclient';
import { AppSetting, AppSettingService } from "../../app.setting";
import { ResultModel } from '../../../models/api.result.model/result.model';
import { map } from 'rxjs/operators';
import { Observable, pipe } from 'rxjs';
import { RequestObject } from '../../../models';
import { AESEncryptDecryptService } from '../aesencrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {
    private settings!: AppSetting;
    constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient , private _AESEncryptDecryptService: AESEncryptDecryptService) { 
      this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
        this.settings = settings;
    });
    }
  
  
    resetPasswordForUser(requestObject: RequestObject) {
      //let encdata =  this._AESEncryptDecryptService.encrypt(JSON.stringify(requestObject));
      let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(requestObject) );
      return this.customeHttpClient.Post<ResultModel<any>>(
          this.settings.igrs_gateway_url + "common/reset_password_for_user", encVal
      ).pipe(
          map((result: any) => {
              if (result) {
                  return result;
              }
          },
              (error: any) => {
                 return error;
              }
          )
      );
     /* return this.customeHttpClient
          .Post<ResultModel<any>>(
              this.settings.igrs_gateway_url + "common/reset_password_for_user",encdata).pipe(
              map((result: any) => {
                  return result;
              },
                  (error: any) => {
                      return error;
                  }
              )
          );*/
  }
  
  getSamapadaUserByMobileno(mobileNo: any, userId: number){
    return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "citizen/get_samapada_user_by_mobileno?mobileno=" + mobileNo + "&user_id=" + userId,+""
        ).pipe(
            map((result: any) => {
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }
  
            },
            (error: any) => {
                return error;
            })
        );
  }
  
  getCitizenDetailsWithSp(citizenid: any){
      return this.customeHttpClient
          .Get<ResultModel<any>>(
              this.settings.igrs_gateway_url + "common/getCitizenDetailsWithSp/"+citizenid,
          ).pipe(
              map((result: any) => {
                      return result;
    
              })
          );
    }

    getBankRepresentativeListByUserId(param: any){
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(param));
        return this.customeHttpClient.Post<ResultModel<any>>(
                this.settings.igrs_services_url + "common/ereg/get_bank_representative_list_by_userid", encVal    
         ).pipe(
             map((result: any) => {
                 ;
                 //
                 if (result && result.responseStatus == 'true') {
                     return result;
                 } else {
                     return result;
                 }
    
             })
         );
     }
  
  }
  
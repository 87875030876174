
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DutyConstants } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-constants';
import { DutyInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-input-fields';
import { DutyOtherFlagEnum } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-flag-enum';
import { DutyOtherInputFields } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-input-fields';
import { DutyOtherSlab } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-other-slab';
import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';
import { CommunicationServiceService } from '../duty-service/communication-service.service';

export interface DialogData {
  filterInstMappingList: InstMappingBean[] ;
  inputOtherFieldDialogData: Array<any> ;
  readOnlyMode:boolean
}
@Component({
  selector: 'app-calculate-other',
  templateUrl: './calculate-other.component.html',
  styleUrls: ['./calculate-other.component.scss']
})
export class CalculateOtherComponent implements OnInit {
  @ViewChild("dutyOtherform" ,{static:false}) dutyOtherform: NgForm;
  lan: any;
  filterInstMappingList: InstMappingBean[] = new Array();
  //copy of filterInstMappingListis created as orderby id is updating sorting order as per id of parentmappingList in actual object
  filterInstMappingListDisplay: InstMappingBean[] = new Array();
  inputOtherFields: any = new Array();
  dutyInputOtherFields: DutyInputFields = new DutyInputFields();
  dutyInputOtherFieldList: DutyInputFields[] = new Array();
  dutyOtherInputFields: DutyOtherInputFields = new DutyOtherInputFields();
  dutyOtherInputFieldsArray:DutyOtherInputFields[] = new Array();
 // dutyOtherCalFlagEnum: DutyOtherFlagEnum;

   fieldArray: Array<any> = [];
   fieldArrayTemp: Array<any> = [];
     newAttribute: any = {};
     dutyOtherSlab:DutyOtherSlab;
  gridsize: number;
  addFlag:boolean=false;

  popupNameEn:string;
  popupNameHi:string;

  totalPeriod:number=0;
  totalPeriodArray: any = new Array();
  validationEnabled:boolean=true;
  readOnlyMode:boolean= false;

  constructor(private  dialogRef:  MatDialogRef<CalculateOtherComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData,private serv: CommunicationServiceService,private translateService:
  TranslateHEService, private translate: TranslateService) { 
  
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     })
    
     this.readOnlyMode = data.readOnlyMode;
     this.filterInstMappingList =  data.filterInstMappingList;
     this.filterInstMappingListDisplay  =  JSON.parse(JSON.stringify( this.filterInstMappingList));
   //this.filterInstMappingList[0].parentMappingList = this.filterInstMappingList[0].parentMappingList.sort((a,b)=> a.orderId-b.orderId)

   if( data.inputOtherFieldDialogData!=null &&  data.inputOtherFieldDialogData!=undefined){
    let inputOtherFieldDialogDataTemp :any 
    if(data.inputOtherFieldDialogData?.length>1){

      data.inputOtherFieldDialogData?.forEach( (element:any,index:any)=>{
        if(index==0){
          inputOtherFieldDialogDataTemp=new Array(element);
        }else{
                this.fieldArray.push(this.getValueInMapForm(new Array(element)));
        }        
      });
    }else if(data.inputOtherFieldDialogData?.length==1){
      inputOtherFieldDialogDataTemp  =data.inputOtherFieldDialogData;
    }


    if (Array.isArray(inputOtherFieldDialogDataTemp)) {
      //this.igrsEregTxnDetlsBean.inputFieldsImpound = {};
      let inputOtherFieldDialogDataTempArry = inputOtherFieldDialogDataTemp[0]['dutyOtherInputFieldList'];
      this.inputOtherFields = inputOtherFieldDialogDataTempArry.reduce(function (map: any, obj: any) {
        map[obj.id] = obj.value;
        return map;
      }, {});
    }
   }
  
   console.log("CalculateOtherComponent-filterInstMappingList:-", this.filterInstMappingList);
   let popupId = this.filterInstMappingList[0].isPopup;
   if(popupId==DutyConstants.popup_premium){
    this.popupNameEn  = DutyConstants.popup_premium_En;
    this.popupNameHi = DutyConstants.popup_premium_Hi;
   }else if(popupId==DutyConstants.popup_royalty){
    this.popupNameEn  = DutyConstants.popup_royalty_En;
    this.popupNameHi = DutyConstants.popup_royalty_Hi;
   }else if(popupId==DutyConstants.popup_rent){
    this.popupNameEn  = DutyConstants.popup_rent_En;
    this.popupNameHi = DutyConstants.popup_rent_Hi;
   }
   

  }

  public get DutyOtherFlagEnum(): typeof DutyOtherFlagEnum {
    return DutyOtherFlagEnum; 
  }


  getValueInMapForm(input:any):any{
        let outputMap:any
        let inputOtherFieldDialogDataTempArry = input[0]['dutyOtherInputFieldList'];
        outputMap = inputOtherFieldDialogDataTempArry.reduce(function (map: any, obj: any) {
      map[obj.id] = obj.value;
      return map;
    }, {});

    return outputMap;

  }

  ngOnInit(): void {
    this.totalPeriod = 0;
  }

  submitOtherForm(dutyOtherform: NgForm){
      console.log("-- Form Submitted --");
      console.log("dutyOtherform this.fieldArray- ",this.fieldArray);
      console.log("inputOtherFields- ",this.inputOtherFields);

      let lastIndex = this.lastIndexOfArray();
      if(this.validations(lastIndex,true)){

//calculation of object added in list
      this.fieldArray.forEach((element,index) => { //let i:number=0;
        console.log("element in fieldarray- ",this.fieldArray[index]);
        //console.log("element in fieldarray- ",element);
        this.dutyInputOtherFieldList = new Array();
            Object.keys(this.fieldArray[index]).forEach((key) => {
          
              //let index=0;
              console.log(key),console.log(this.fieldArray[index][key])
      
              this.dutyInputOtherFields.id = Number(key);
              this.dutyInputOtherFields.value = this.fieldArray[index][key];
             // this.dutyInputOtherFieldList.push(this.fieldArray[i]);
              this.dutyInputOtherFieldList.push(this.dutyInputOtherFields);

              this.dutyInputOtherFields= new DutyInputFields();
            console.log("dutyInputOtherFieldList:-",this.dutyInputOtherFieldList);
              //index++;
        
            });
        //i++;
        this.dutyOtherSlab=new DutyOtherSlab();
        this.dutyOtherSlab.dutyOtherInputFieldList = this.dutyInputOtherFieldList;
         this.dutyOtherInputFields.dutyOtherSlab.push(this.dutyOtherSlab);
      });

//calculation of last added object or in case of single object
    this.dutyInputOtherFieldList = new Array();
      Object.keys(this.inputOtherFields).forEach((key) => {
      
        let index=0;
        console.log(key),console.log(this.inputOtherFields[key])

        this.dutyInputOtherFields.id = Number(key);
        this.dutyInputOtherFields.value = this.inputOtherFields[key];
        this.dutyInputOtherFieldList.push(this.dutyInputOtherFields);
  
        this.dutyInputOtherFields= new DutyInputFields();
       console.log("dutyInputOtherFieldList:-",this.dutyInputOtherFieldList);
        index++;
  
      });
      this.dutyOtherInputFields.popUpId = this.filterInstMappingList[0].isPopup;
      this.dutyOtherInputFields.parentId = this.filterInstMappingList[0].id;
     // this.dutyOtherInputFields.dutyOtherInputFieldList = this.dutyInputOtherFieldList;
     this.dutyOtherSlab=new DutyOtherSlab();
     this.dutyOtherSlab.dutyOtherInputFieldList = this.dutyInputOtherFieldList;
      this.dutyOtherInputFields.dutyOtherSlab.push(this.dutyOtherSlab);
    
      if(this.filterInstMappingList[0].isPopup==DutyConstants.popup_premium) {
       this.dutyOtherInputFields.calculatedPremium= this.calculatePremium();
       
     }
      else if(this.filterInstMappingList[0].isPopup==DutyConstants.popup_royalty){
        this.dutyOtherInputFields.calculatedRoyalty =  this.calculateRoyalty();
      }else if(this.filterInstMappingList[0].isPopup==DutyConstants.popup_rent){
        this.dutyOtherInputFields.calculatedRent =  this.calculateRent();
      }

      this.dutyOtherInputFieldsArray.push(this.dutyOtherInputFields);
      this.serv.dutyOtherInputFieldsEmit.emit(this.dutyOtherInputFieldsArray);
      this.closeMe();
      }else{
                this.validationEnabled= true;
      }
  }
  updateSetting(event: { value: number; }) {
    this.gridsize = event.value;
  }
  calculatePremium():number{
    let premium:number = 0;
   this.dutyOtherInputFields.dutyOtherSlab.forEach(list=>{ list.dutyOtherInputFieldList
    .forEach( arg => {
      //ways to find 
      //let flag1 = this.filterInstMappingList[0].parentMappingList.filter(key=> key.id === arg.id).map(function(a) {return a.otherCalFlag;});
      //let flag = this.filterInstMappingList[0].parentMappingList.find(key=> key.id === arg.id).otherCalFlag.toString();
      //console.log("calculatePremium-flag:", flag);
      premium += Number(arg.value);
     });   
    });
    
    //this.dutyInputOtherFieldList.forEach( arg => {
     // premium += Number(arg.value);
    // });

     console.log("premium - ",premium); 
     premium = this.roundUptoTwoDecimal(premium);
     return premium;
  }

  calculateRent(): number{
    let month:number=0;
    let year:number=0;
    let period : number=0;
    let periodInMonths: number=0;
    let totalPeriod : number=0;
    let maintainence:number=0;
    let rent:number = 0;
     let calculatedRent:number = 0;
     console.log("this.dutyOtherInputFields.dutyOtherSlab-",this.dutyOtherInputFields.dutyOtherSlab);
    this.dutyOtherInputFields.dutyOtherSlab.forEach(list=>{ list.dutyOtherInputFieldList
      .forEach( arg => {
        console.log("arg-",arg);
        let flag:string = this.filterInstMappingList[0].parentMappingList.filter(key=> key.id === arg.id).find(key=>key.otherCalFlag)?.otherCalFlag;
          if(flag === DutyOtherFlagEnum.RT){
            rent = Number(arg.value);
          }else if(flag === DutyOtherFlagEnum.MT){
            maintainence = Number(arg.value);
          }
          else if(flag === DutyOtherFlagEnum.Y){
            year = Number(arg.value);
          }else if(flag === DutyOtherFlagEnum.M){
            month = Number(arg.value);
            //console.log("month-",month);
          }           
        });   
        period = year+(month/12);
        periodInMonths = (12*year)+month;
        console.log("period-",period);
        console.log("periodInMonths-",periodInMonths);
        console.log("rent+maintainence-",rent+maintainence);
        console.log("calculatedRent-",calculatedRent);

       // calculatedRent = calculatedRent+((rent+maintainence)*period);
        calculatedRent = calculatedRent+((rent+maintainence)*periodInMonths);

        totalPeriod =  totalPeriod+period;

        rent=0;
        maintainence=0;
        year=0;
        month=0;
      });

      console.log("totalPeriod - ",totalPeriod); 
      console.log("calculatedRent - ",calculatedRent); 

      calculatedRent = calculatedRent/totalPeriod;
    console.log("total calculated rent - ",calculatedRent); 
    calculatedRent = this.roundUptoTwoDecimal(calculatedRent);
    return calculatedRent; 
  }

  calculateRoyalty(): number{

        let month:number=0;
        let year:number=0;
        let period : number=0;
        let quantity:number=0;
        let royalty:number = 0;
        let deadRent:number = 0;
         let calculatedRoyalty:number = 0;
        this.dutyOtherInputFields.dutyOtherSlab.forEach(list=>{ list.dutyOtherInputFieldList
          .forEach( arg => {
            let flag = this.filterInstMappingList[0].parentMappingList.filter(key=> key.id === arg.id).find(key=>key.otherCalFlag)?.otherCalFlag;
              if(flag === DutyOtherFlagEnum.R){
                royalty = Number(arg.value);
              }else if(flag === DutyOtherFlagEnum.Q){
                quantity = Number(arg.value);
              }
              else if(flag === DutyOtherFlagEnum.Y){
                year = Number(arg.value);
              }else if(flag === DutyOtherFlagEnum.M){
                month = Number(arg.value);
                //console.log("month-",month);
              } else if(flag === DutyOtherFlagEnum.DR){
                deadRent = Number(arg.value);
                royalty = royalty+deadRent;
                //console.log("month-",month);
              }            
            });   
            period = year+(month/12);
            //console.log("period-",period);
            calculatedRoyalty += royalty*quantity*period;
            royalty=0;
            quantity=0;
            year=0;
            month=0;
          });

     console.log("calculatedRoyalty - ",calculatedRoyalty); 
     calculatedRoyalty = this.roundUptoTwoDecimal(calculatedRoyalty);
     return calculatedRoyalty; 
  }

  addFieldValue() {
    this.addFlag=true;
    this.validationEnabled= false;
    let valid:boolean=true;
    //let isValid:boolean=true;
  
    if(this.dutyOtherform.valid){
      let lastIndex = this.lastIndexOfArray();
        valid = this.validations(lastIndex,false);
      if(valid){
        this.fieldArray.push(this.inputOtherFields);
        console.log("addFieldValue- ",this.fieldArray);
        this.inputOtherFields = new Array();
        this.validationEnabled= true;
        this.addFlag=false;

        //this.filterInstMappingList[0].parentMappingList = this.filterInstMappingList[0].parentMappingList.sort((a,b)=> a.orderId-b.orderId)
        console.log("CalculateOtherComponent-addFieldValue -filterInstMappingList:-", this.filterInstMappingList);
      }
    } 
}


lastIndexOfArray():number{
  let lenghtOfarray =  this.fieldArray?.length
  lenghtOfarray =  lenghtOfarray>0?lenghtOfarray:0;
  return lenghtOfarray;
}


validations(index:any,isSubmit:boolean):boolean{
  let valid:boolean=true;
  
  let month:number=0;
  let year:number=0;
  let period : number=0;
  let minYear:number=0;
  let maxYear:number=0;  
  let isValidationApplicable:boolean =false; 
  let instMappingId:number;
    let errMsg:string ;

        Object.keys(this.inputOtherFields).forEach((key) => {   
          this.dutyInputOtherFields= new DutyInputFields();
          this.dutyInputOtherFields.id = Number(key);
          this.dutyInputOtherFields.value = this.inputOtherFields[key];

          this.filterInstMappingList.filter(f=> {
         
            f.parentMappingList.filter(p=>{
              if(p.otherCalFlag=='Y' && p.id == this.dutyInputOtherFields.id){    
               // this.inputOtherFields[p.id] = 'Y';  
               instMappingId  = this.dutyInputOtherFields.id;
                year = Number(this.dutyInputOtherFields.value);  
                minYear =p.minimum;
                maxYear = p.maximum;
                isValidationApplicable = true;
                   // if(Number(this.dutyInputOtherFields.value) >=p.minimum){
                     // isYearMinimum=true;   
                      errMsg= p.validationMsgEn;                     
                   // }              
              }
              if(p.otherCalFlag=='M' && p.id == this.dutyInputOtherFields.id){
               // this.inputOtherFields[p.id] = 'M';
                isValidationApplicable = true;
                month = Number(this.dutyInputOtherFields.value==null?0:this.dutyInputOtherFields.value);  
                //if(Number(this.dutyInputOtherFields.value)==0){
                 // isMonthZero=true;               
               // }
              }

            });
          });
        });

        period = year+(month/12);

        //this.totalPeriod = this.totalPeriod +period; 
        this.totalPeriodArray?.splice(index, 1);
        this.totalPeriodArray?.push(period);

        this.totalPeriod = 0;

        this.totalPeriodArray?.forEach((e:any)=>{
          if(e!=null && e!=undefined){
            this.totalPeriod = this.totalPeriod +e;  
          }
              
        });
        //|| period <maxYear
        if(isValidationApplicable){

          let isMinEqualApp:boolean=false;
          let isMaxEqualApp:boolean=false;

          let isMinEqualAppList = [753,726,660,569,706,640,650,716,591,580,581];
          //let isMaxEqualAppList = [0,569,580,591,753];
          let isMaxEqualAppList = [0,753];

           isMinEqualAppList?.forEach(f=> {if(f==instMappingId){isMinEqualApp= true} });
           isMaxEqualAppList?.forEach(f=> {if(f==instMappingId){isMaxEqualApp= true} });

          if(null!=minYear && (Number(this.totalPeriod)<Number(minYear) && isSubmit && !isMinEqualApp)){
            alert(errMsg);
            valid=false;
          }
          if(null!=minYear && (Number(this.totalPeriod)<=Number(minYear) && isSubmit && isMinEqualApp)){
            alert(errMsg);
            valid=false;
          }

          if(null!=maxYear && Number(this.totalPeriod)>Number(maxYear)&& !isMaxEqualApp){
            alert(errMsg);
            valid=false;
          }
          if(null!=maxYear && Number(this.totalPeriod)>=Number(maxYear) && isMaxEqualApp){
            alert(errMsg);
            valid=false;
          }
        }

return valid;
}

deleteFieldValue(index:any) {

  this.totalPeriodArray?.splice(index, 1);

    this.fieldArray.splice(index, 1);
    console.log("deleteFieldValue- ",this.fieldArray);
}

roundUptoTwoDecimal(val: number){
  console.log("round value:",Math.round(val));

 // return (Math.round(val*10^2)/10^2);
  //return (Math.round(val));

  return Math.round((val + Number.EPSILON) * 100) / 100;

}


validateNumber(event: any,field:any) {
  let returnVal:boolean =true;
  let decimalCheckApplied:boolean = true;
  const reg = /^-?\d*(\.\d{0,2})?$/;
  let input = event.target.value + String.fromCharCode(event.charCode);

  console.log("validateNumber event",event);
  console.log("field",field);
  console.log("field.otherCalFlag",field.otherCalFlag);

   if(field.otherCalFlag === DutyOtherFlagEnum.Y || field.otherCalFlag === DutyOtherFlagEnum.M){
    decimalCheckApplied= false;

    if(event.target.value.length>2){
      event.preventDefault();
      return false;
    }
  }

  if(!decimalCheckApplied){
   if(event.charCode == 46){
    return false;
   }
  }

  if (!reg.test(input) && decimalCheckApplied) {
      event.preventDefault();
      return false;
  }

  if(event.target.value.length>12){
    event.preventDefault();
    return false;
  }

  return returnVal;
}

  resetDutyOtherFields(){
    //this.dutyOtherform.reset();
    this.validationEnabled= true;
    this.fieldArray = [];
    this.dutyOtherform.resetForm();
  }

  public  closeMe() {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    console.log("ngOnDestroy : CalculateOtherComponent");
  }

}

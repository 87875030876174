import { Component, OnInit } from '@angular/core';
 
import { ActivatedRoute } from '@angular/router';
import { SlotModel } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { interval } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';
import { Demography } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { SrOfficeDisplayService } from '../sr-office-display/sr-office-display.service';
@Component({
  selector: 'app-sr-office-display-new',
  templateUrl: './sr-office-display-new.component.html',
  styleUrls: ['./sr-office-display-new.component.scss']
})
export class SrOfficeDisplayNewComponent implements OnInit {
    langOptionFlag: number = 1;
  lan: number;
  officeId: any;
  makerList:SlotModel[];
  checkerList:SlotModel[];
  waitList:SlotModel[];
  districtNameEn:string
  districtNameHi:string
  srofficeNameEn:string
  srofficeNameHi:string
  date:string
  qrCode:string
  srOfficeList: Demography[] = [];
  srOfficeDemoId:number;
  constructor(private srOfficeDisplayService:SrOfficeDisplayService,
    private activatedRoute: ActivatedRoute,
    private ngxService: NgxUiLoaderService,
    private translateService: TranslateHEService,
    private translate: TranslateService,) { 
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     })
  }

  ngOnInit(): void {
 this.newClassAdd()
    // this.activatedRoute.params.subscribe(params => {
    //   if (typeof params['id'] !== 'undefined') {
    //     this.officeId = params['id'];

    //   } else {
    //     alert("Something wrong.");
    //     this.officeId = "0";
    //   }
    // })
    this.srOfficeDisplayService.getSROffices().subscribe((res: { responseData: Demography[]; }) => {
      this.srOfficeList = res.responseData;
    });
  }

  onChangeSrOffice() {
    this.ngxService.start();
    interval(10 * 1000)
      .pipe(
        mergeMap(() =>
    this.srOfficeDisplayService.getSrDisplayData(this.srOfficeDemoId))).subscribe(res => {

      this.ngxService.stop();
      if (res.responseStatus == 'true') {
        this.qrCode = 'data:image/png;base64,' + res.responseData.qrCode;
        this.date= res.responseData.date;
        this.srofficeNameEn = res.responseData.srofficeNameEn;
        this.srofficeNameHi = res.responseData.srofficeNameHi;
        this.districtNameEn = res.responseData.districtNameEn;
        this.districtNameHi = res.responseData.districtNameHi;
        this.makerList = res.responseData.makerList;
        this.checkerList = res.responseData.checkerList;
        this.waitList = res.responseData.waitList;
      }
    });
  }
   changeLanguageToHindi(lan:any) {
    this.translate.use("hi")
    this.langOptionFlag = 1;
    this.lan=lan
    sessionStorage.setItem("selectedLanguage", "hi")
     localStorage.setItem("selectedLanguage", "hi");
    this.translateService.updateApprovalMessage(lan);

  }
  changeLanguageToEnglish(lan:any) {
    this.translate.use("en")
    this.langOptionFlag = 0;
    this.lan=lan
    sessionStorage.setItem("selectedLanguage", "en")
    localStorage.setItem("selectedLanguage", "en");
    this.translateService.updateApprovalMessage(lan);

  }
 newClassAdd() {
     const bodyTag = document.body;
    bodyTag.classList.add("SROfficeMobile")
  }
 

}

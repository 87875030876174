import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHEService } from '../helper-lib/services/common/translateHE.service';

@Pipe({
  name: 'AmtToWords'
})
export class AmtToWords implements PipeTransform {

  lan: any;
  constructor(private translate: TranslateService,    private translateService: TranslateHEService,) {


   try {
   
    let selectedLanguage = sessionStorage.getItem("selectedLanguage");
    if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
      this.lan = 0;
      selectedLanguage = 'en';
    }

    //translate.use(selectedLanguage).toPromise();
    this.lan = selectedLanguage == 'hi' ? 1 : 0;
   // this.translateService.updateApprovalMessage(this.lan);
    this.translateService.currentApprovalStageMessage.subscribe(msg => this.lan = msg);
  } catch (err) {
   // console.log(err);
  }

 }

  transform(value: number): string {

    if (value == null || value == undefined) {
      return '';
    }
    let ones = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    let tens = ['Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    let teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];

    if (this.lan != 0) {
      ones = ['शून्य', 'एक', 'दो', 'तीन', 'चार', 'पांच', 'छः', 'सात', 'आठ', 'नौ'];
      tens = ['दस', 'बीस', 'तीस', 'चालीस', 'पचास', 'साठ', 'सत्तर', 'अस्सी', 'नब्बे'];
      teens = ['ग्यारह', 'बारह', 'तेरह', 'चौदह', 'पंद्रह', 'सोलह', 'सत्रह', 'अठारह', 'उन्नीस'];
    }
    const twentys = ['इक्कीस', 'बाईस', 'तेईस', 'चौबीस', 'पच्चीस', 'छब्बीस', 'सत्ताईस', 'अट्ठाईस', 'उनतीस'];
    const thirtys = ['इकत्तीस', 'बत्तीस', 'तेंतीस', 'चौंतीस', 'पैंतीस', 'छत्तीस', 'सैंतीस', 'अड़तीस', 'उनतालीस'];
    const fortys = ['एकतालीस', 'बायलीस', 'तैंतालीस', 'चौवालीस', 'पैंतालिस', 'छियालीस', 'सैंतालीस', 'अड़तालीस', 'उनचास'];
    const fiftys = ['इक्यबन', 'बावन', 'तिरपन', 'चौवन', 'पचपन', 'छप्पन', 'सत्तावन', 'अट्ठावन', 'उनसठ'];
    const sixtys = ['इकसठ', 'बासठ', 'तिरसठ', 'चौंसठ', 'पैंसठ', 'छियासठ', 'सड़सठ', 'अड़सठ', 'उनहत्तर'];
    const seventys = ['इकहत्तर', 'बहत्तर', 'तिहत्तर', 'चौहत्तर', 'पचहत्तर', 'छीहत्तर', 'सतहत्तर', 'अठहत्तर', 'उनासी'];
    const eightys = ['इक्यासी', 'बायसी', 'तिरासी', 'चौरासी', 'पचासी', 'छियासी', 'सतासी', 'अट्ठासी', 'नवासी'];
    const ninetys = ['इक्यानवे', 'बानवे', 'तिरानवे', 'चौरानवे', 'पंचानवे', 'छियानवे', 'सतानवे', 'अट्ठानवे', 'निन्यानवे'];

    let words = '';

    if ((value.toString()).length > 10) return '';

    if (value === 0) {
      return ones[0];
    }

    // Function to convert three-digit number to words
    const convertThreeDigitNumber = (num: number): string => {
      let result = '';

      // Extract hundreds place digit
      const hundreds = Math.floor(num / 100);
      if (hundreds > 0) {
        result += ones[hundreds] + ' Hundred ';
      }

      // Extract last two digits
      const lastTwoDigits = num % 100;

      if (lastTwoDigits > 0) {
        if (lastTwoDigits < 10) {
          result += ones[lastTwoDigits] + ' ';
        } else if (lastTwoDigits > 10 && lastTwoDigits < 20) {
          result += teens[lastTwoDigits - 11] + ' ';
        } else {
          const tensDigit = Math.floor(lastTwoDigits / 10);
          const onesDigit = lastTwoDigits % 10;

          result += tens[tensDigit - 1] + ' ';
          if (onesDigit > 0) {
            result += ones[onesDigit] + ' ';
          }
        }
      }

      return result;
    };

    // Function to convert three-digit number to words in hindi
    const convertThreeDigitNumberHindi = (num: number): string => {
      let result = '';

      // Extract hundreds place digit
      const hundreds = Math.floor(num / 100);
      if (hundreds > 0) {
        result += ones[hundreds] + ' सौ ';
      }

      // Extract last two digits
      const lastTwoDigits = num % 100;

      if (lastTwoDigits > 0) {
        if (lastTwoDigits < 10) {
          result += ones[lastTwoDigits] + ' ';
        } else if (lastTwoDigits > 10 && lastTwoDigits < 20) {
          result += teens[lastTwoDigits - 11] + ' ';
        } else if (lastTwoDigits > 20 && lastTwoDigits < 30) {
          result += twentys[lastTwoDigits - 21] + ' ';
        } else if (lastTwoDigits > 30 && lastTwoDigits < 40) {
          result += thirtys[lastTwoDigits - 31] + ' ';
        } else if (lastTwoDigits > 40 && lastTwoDigits < 50) {
          result += fortys[lastTwoDigits - 41] + ' ';
        } else if (lastTwoDigits > 50 && lastTwoDigits < 60) {
          result += fiftys[lastTwoDigits - 51] + ' ';
        } else if (lastTwoDigits > 60 && lastTwoDigits < 70) {
          result += sixtys[lastTwoDigits - 61] + ' ';
        } else if (lastTwoDigits > 70 && lastTwoDigits < 80) {
          result += seventys[lastTwoDigits - 71] + ' ';
        } else if (lastTwoDigits > 80 && lastTwoDigits < 90) {
          result += eightys[lastTwoDigits - 81] + ' ';
        } else if (lastTwoDigits > 90 && lastTwoDigits < 100) {
          result += ninetys[lastTwoDigits - 91] + ' ';
        } else {
          const tensDigit = Math.floor(lastTwoDigits / 10);
          const onesDigit = lastTwoDigits % 10;

          result += tens[tensDigit - 1] + ' ';
          if (onesDigit > 0) {
            result += ones[onesDigit] + ' ';
          }
        }
      }

      return result;
    };

    // Convert crore, lakh, and thousand places
    const crore = Math.floor(value / 10000000);
    if (crore > 0) {
      if (this.lan == 0)
        words += convertThreeDigitNumber(crore) + 'Crore ';
      else
        words += convertThreeDigitNumberHindi(crore) + 'करोड़ ';
    }

    const lakh = Math.floor((value % 10000000) / 100000);
    if (lakh > 0) {
      if (this.lan == 0)
        words += convertThreeDigitNumber(lakh) + 'Lakh ';
      else
        words += convertThreeDigitNumberHindi(lakh) + 'लाख ';
    }

    const thousand = Math.floor((value % 100000) / 1000);
    if (thousand > 0) {
      if (this.lan == 0)
        words += convertThreeDigitNumber(thousand) + 'Thousand ';
      else
        words += convertThreeDigitNumberHindi(thousand) + 'हज़ार ';
    }

    // Convert last three digits
    const lastThreeDigits = value % 1000;
    if (this.lan == 0)
      words += convertThreeDigitNumber(lastThreeDigits);
    else
      words += convertThreeDigitNumberHindi(lastThreeDigits);

    return words.trim();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from '../modules/home/home.component';
import { AboutComponent } from '../modules/about/about.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DefaultComponent } from './default.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ChatbotComponent } from '../modules/chatbot/chatbot.component';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MaterialModule } from 'projects/common-lib/material/material.module';
import { CommonLibModule } from 'projects/common-lib/src/lib/common-lib.module';
import { httpTranslateLoader } from 'projects/common-lib/src/projects';




@NgModule({
  declarations: [
    HomeComponent,
    DefaultComponent,
    AboutComponent, 
    // ChatbotComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SlickCarouselModule,
    NgxSliderModule,
    NgbModule,
    //MatButtonModule,
    MaterialModule,
    CommonLibModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'hi'
    })
   
  ]
})
export class DefaultModule { }

import { EstampDetails } from "../estamp.model/estamp-details";
import { FranchiseModel } from "../franchise.model/franchise.model";
import { juisticPersonDetail } from "../juristic-Person.model/juristicPersonDetails.model";
import { Serviceprovider } from "../serviceprovider/serviceprovider.model";

export class CitizenUserProfileDetails {
    //citizen : CitizenUserProfile;
    id: number;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    firstNameEn: string;
    middleNameEn: string;
    lastNameEn: string;
    firstNameHi: string;
    middleNameHi: string;
    lastNameHi: string;
    motherName: string;
    motherNameHi: string;
    motherNameEn: string;
    email: string;
    contactNo: string;
    altEmail: string;
    altContactNo: string;
    gender: string;
    dob: string;
    relativeName: string;
    relativeNameEn: string;
    relativeNameHi: string;
    relativeRelation: string;
    caste: string;
    religion: string;
    userName: string;
    password: string;
    confirmPass: string;
    verificationLevel: number;
    status: number;
    demography: Demography;
    addressLine1: string;
    addressLine2: string;
    addressLine1Hi: string;
    addressLine2Hi: string;
    city: string;
    cityHi: string;
    districtAadhaar: string;
    state: string;
    stateName: string;
    postalCode: string;
    demographyPre: Demography;
    addressLine1Pre: string;
    addressLine2Pre: string;
    addressLine1PreHi: string;
    addressLine2PreHi: string;
    cityPre: string;
    cityPreHi: string;
    statePre: string;
    stateNamePre: string;
    postalCodePre: string;
    profilePic: string;
    oldPassword: string;
    newPassword: string;
    citizenType: number;
    roleMappingBean: RoleMappingBean;
    subRoleMappingBean: SubRoleMappingBean;
    juristicPerson: juisticPersonDetail;
    isIndianResident: number;
    isServiceProvider: number;
    serviceProvider: Serviceprovider = new Serviceprovider();
    kycType: string;
    kycType2: string;
    kycTxnLogNo: string;
    kycUniqueToken: string;
    isFranchise: number;
    franchiseDetails: FranchiseModel;
    location: LocationBean;

    referenceId: string;
    purposeId: string;
    countryNameEn: string;
    countryNameHi: string;
    country: string;
    captchaId: string;
    captchaStr: string;
    isFullProfile: number;
    passwordStr: string;
    demographyId: number;
    demographyIdPre: number;
    rResPhoto: string;
    orgDesignationEn: string;
    orgDesignationHi: string;
    aadharNumberMasked: string;
    panResponseId: string;
    panNumber: string
    govtDeptName: string
    featureList: FeatureList[] = new Array();
    nameOnPan: string;
    serviceProviderStatus:number;
    aadharRefKey: string;
    mobileNo:any;
    govtDeptId: number
}

export class CitizenUserProfile {
    id: number;
}
export class FeatureList {
    id: number;
    displayOrder: number;
    featureCode: string;
    featureIcon: string;
    featureNameEn: string;
    featureNameHi: string;
    featureUrl:string;
}

export class Demography {
    id: number;
    demographyType: DemographyType;
    parentDemographyId: number;
    demographyName: string;
    demographyNameHi: string;
    demographyNameEn: string;
    lgdCode: string;
}

export class DemographyType {
    id: number;
    demographyTypeName: string;
    demographyTypeNameEn: string;
    demographyTypeNameHi: string;
}

export class RoleMappingBean {
    id: number;
    demographyTypeName: string;
    roleId: Role;
    status: number;
    userId: User;
}

export class Role {
    id: number;
    roleCode: string;
    roleDesc: string;
    status: number;
}

export class User {
    id: number;
    email: string;
    emailVerifyStr: string;
    mobileNo: string;
    mobileVerifyStr: string;
    name: string;
    nameEn: string;
    nameHi: string;
    status: number;
    userName: string;
    userStatus: UserStatus;
}

export class UserStatus {
    id: number;
    description: string;
    name: string;
}

export class SubRoleMappingBean {
    id: number;
    status: number;
    subRole: SubRole;
    user: User;
}

export class SubRole {
    id: number;
    status: number;
    subRoleCode: string;
    subRoleDesc: string;
}

// export class juisticPersonProfileDetail{
//     id : number;
//     orgType: string;
//     GCNumber : string;
//     gcType : string;
//     gstNumber : string;
//     cinNumber : string;
//     companyName : string;
//     citizen : CitizenUserProfile;
//     registrationNumber: string;
//     companyCategory : string;
//     authorisedCapital : string;
//     paidUpCapital : string;
//     signatoryDetails : string;
//     emailId : string;
//     activeCompliance : string;
//     identityProof : string;
//     uploadDocument : string;
//     uploadDocumentName: string;
//     DOJ : string;
//     relatedPersonType : string;
//  }

export class franchiseProfileDetail {
    id: number;
    orgType: string;
    GCNumber: string;
    gcType: string;
    gstNumber: string;
    cinNumber: string;
    companyName: string;
    citizen: CitizenUserProfile;
    registrationNumber: string;
    companyCategory: string;
    authorisedCapital: string;
    paidUpCapital: string;
    signatoryDetails: string;
    emailId: string;
    activeCompliance: string;
    identityProof: string;
    uploadDocument: string;
    uploadDocumentName: string;
    DOJ: string;
    relatedPersonType: string;
}

export class LocationBean {
    state: Demography;
    zone: Demography;
    district: Demography;
    localBody: Demography;
    tehsil: Demography;
    ward: Demography;
    village: Demography;
    village_colony: Demography;
    ward_colony: Demography;
}

export class SPLicenseConfigBean {
    merchantCode: string;
    deptCode: string;
    actName: string;
    ru: string;
    majorHead: string;
    submajorHead: string;
    minorHead: string;
    schemeCode: string;
    purpose: string;
    circleCode: string;
    depttCode: string;
    officeName: string;
    enquiryReturnUrl: string;
    deptName: string;
    cyberCode: string;
    citizenId: number;
    referenceId: string;
    purposeId: string;
    amount: string;
    totalAmmount: string;
    citizenBean: CitizenUserProfileDetails;
    districCode: string;
    districName: string;
    officeId: number;
    pmntType: string;
    estampType:number;

    flagWalletOrSp: string;

    treasuryConfigBeanList: MstPayTreasuryConfigBean[] = new Array();

    walletListarr: IgrsWalletMasterBean[] = new Array();
    igrsSearchCertDocBean: DocSearchCertCopyBean[] = new Array();
    paymentResponseBean: IgrsPaymentResponseBean;
    paymentDescEn:string;
	paymentDescHi:string;
}

export class DocSearchCertCopyBean {
    id: number;
    majorHead: string;
    submajorHead: string;
    minorHead: string;
    schemeCode: string;
    purpose: string;
    amount: string;
    descEn: string;
    descHi: string;
    purposeId: number;
    debitAmount: string;
}

export class EstampPaymentUserConfigBean {
    merchantCode: string;
    deptCode: string;
    actName: string;
    ru: string;
    majorHead: string;
    submajorHead: string;
    minorHead: string;
    schemeCode: string;
    purpose: string;
    circleCode: string;
    depttCode: string;
    officeName: string;
    enquiryReturnUrl: string;
    deptName: string;
    cyberCode: string;
    citizenId: number;
    referenceId: string;
    purposeId: string;
    amount: string;
    totalAmmount: string;
    citizenBean: CitizenUserProfileDetails;
    districCode: string;
    districName: string;
    officeId: number;
    pmntType: string;
    urn: string;
    walletMasterId: number;
    walletTypeId: number;
    debitamount: string;
    flagWalletOrSp: string;
    treasuryConfigBeanList: MstPayTreasuryConfigBean[] = new Array();
    estampType:number;
    estampId: Number;

    paymentResponseBean: IgrsPaymentResponseBean;

    walletListarr: IgrsWalletMasterBean[] = new Array();

}

export class EstampPaymentMap {
    id: number;
    estampId: Number;
    paymentReqId: Number;
    paymentResId: Number;
}


export class MstPayTreasuryConfigBean {

    officeId: number;
    officeName: string;

}

export class IgrsWalletMasterBean {

    id: number;
    descEn: string;
    descHi: string;
    status: number;
    pos: number;

    walletListTypearr: IgrsWalletTypeMasterBean[] = new Array();

}

export class IgrsWalletTypeMasterBean {

    id: number;
    refId: number;
    refNo: string;
    descEn: string;
    descHi: string;
    status: number;
    serviceMatrixId: number;
    walletPayTypeId: number;
    position: number;

    purpose: string;
    purposeHi: string;

    maxAmount: number;
    minAmount: number;
    payServiceMatrixBean: MstPayServiceMatrixBean;

    walletPayHeadBean: MstWalletPayHeadBean;

    accName: string;
    accNo: string;
    amount: string;
    ifscCode: string;
    majorHeadCode: string;
    minorHeadCode: string;
    pmntType: string;
    schemeCode: string;
    subMajorHeadCode: string;
    debitAmount: string;
    commPer: number;

}

export class MstPayServiceMatrixBean {

    id: number;
    majorHead: string;
    subMajorHead: string;
    minorHead: string;
    schemeHead: string;
    schemeName: string;
    majorHeadCode: string;
    subMajorHeadCode: string;
    minorHeadCode: string;
    ifscCode: string;
    accountNumber: string;
    accountName: string;
    new1: string;
    new2: string;
    new3: string;

}

export class MstWalletPayHeadBean {

    id: number;
    descEn: string;
    status: string;

}

export class IgrsPaymentResponseBean {

    id: number;
    crn: string;
    cin: string;
    brn: string;
    status: string;
    status_DESC: string;
    total_AMOUNT: string;
    transaction_DATE_TIME: string;
    scroll_NUMBER: string;
    scroll_DATE: string;
    challan_NUMBER: string;
    urn: string;
    purpose: string;
    payment_MODE: string;
    majorHead: string;
    minorHead: string;
    subMajorHead: string;
    schemeCode: string;
    createdDateStr: string;
    amount: string;
    paymentResponseSubBeanList: IgrsPaymentResponseSubBean[] = new Array();

}

export class IgrsPaymentResponseSubBean {

    id: number;
    refId: number;
    crn: string;
    cin: string;
    challan_number: string;
    amount: string;
    createdDateStr: string;
    purpose: string;
    majorHead: string;
    minorHead: string;
    schemeCode: string;
    subMajorHead: string;
}


export class EstampPaymentRequest {
    id: number;
    name: string;
    namehi: string;
    add_LINE1: string;
    add_LINE2: string;
    add_LINE3: string;
    add_LINE4: string;
    add_LINE5: string;
    add_LINE_HI_1: string;
    add_LINE_HI_2: string;
    add_LINE_HI_3: string;
    add_LINE_HI_4: string;
    add_LINE_HI_5: string;
    add_PIN: string;
    crn: string;
    actName: string;
    assesmentYear: string;
    assesmentPeriod: string;
    major_HEAD: string;
    minor_HEAD: string;
    scheme_CODE: string;
    submajor_HEAD: string;
    urn: string;
    tin: string;
    amount: number;
    status: string;
    statusHi: string;
    statushi: string;
    purpose: string;
    paymentType: string;
   paymentMode: string;
	paymentModeHi: string;
    txnDate: string;
    referenceId: string;
    estampPaymentResponse: EstampPaymentResponse;
    igrsWalletCreditMstTxnBean: IgrsWalletCreditMstTxnBean[] = new Array();
    paymentDescEn:string;
	paymentDescHi:string;
    referenceCode:string;
    districtEn:string;
	districtHi:string;
	districtId:number;
    office_NAME:string;
    estDeductRefundPerc:number;
    estampId:number;
    redirectUrl:string;
}

export class EstampPaymentResponse {
    id: number;
    crn: string;
    urn: string;
    brn: string;
    status: string;
    scroll_NUMBER: string;
    scroll_DATE: string;
    cin: string;
    challan_NUMBER: string;
    transaction_DATE_TIME: string;
    total_AMOUNT: number;
    status_DESC: string;
}

export class EregPaymentRequest {
    id: number;
    name: string;
    add_LINE1: string;
    add_LINE2: string;
    add_LINE3: string;
    add_LINE4: string;
    add_LINE5: string;
    add_PIN: string;
    crn: string;
    actName: string;
    assesmentYear: string;
    assesmentPeriod: string;
    major_HEAD: string;
    minor_HEAD: string;
    scheme_CODE: string;
    submajor_HEAD: string;
    urn: string;
    tin: string;
    amount: number;
    status: string;
    purpose: string;
    paymentType: string;
    paymentDescEn:string;
    paymentDescHi:string;
    txnDate: string;
    referenceId: string;
    eregPaymentResponse: EregPaymentResponse;
    igrsWalletCreditMstTxnBean: IgrsWalletCreditMstTxnBean[] = new Array();
}

export class EregPaymentResponse {
    id: number;
    crn: string;
    urn: string;
    brn: string;
    status: string;
    scroll_NUMBER: string;
    scroll_DATE: string;
    cin: string;
    challan_NUMBER: string;
    transaction_DATE_TIME: string;
    total_AMOUNT: number;
    status_DESC: string;
}


export class saveGLDraftSuggestion {
    demographyid: number;
    officeId: number;
    title: string;
    firstNameEn: string;
    middleNameEn: string;
    lastNameEn: string;
    email: string;
    mobileNo: number;
    addressLine1: string;
    suggestion: string;
}
// for eRegistry other loan details
export class saveOtherLoanDetails {
    bankId: number;
    loanCersiaID: string;
    loanAmount: number;
    loanStartDate: string;
    loanTenure: number;
    ivrsNumber: number;
    ivrsLatitue: number;
    ivrsLongitude: number;
    ivrsAddress: string;
    propertyTax: number;
    waterTax: number;
    sewerTax: number;
    garbageTax: number;
    protestChargeDetails: string;
    stampcaseCharge: number;
    rrc: number;
    eRegId: number;
}

// for eRegistry Consederation payment information
export class saveConsederationPaymentDetails {
    eRegistryId: number;
    considerationTotalAmount: number;
    totalAmount: number;
    remainConsiderationAmount: number;
    considerationPaymentDetails: considerationPaymentDetails;
}
export class considerationPaymentDetails {
    paymentMode: number;
    paymentDate: string;
    transactionDetails: string;
    paidAmount: number;
}


export class WalletPaymentBean {
    purpose: string;
    spId: number;
    citizenId: number;
    referenceId: string;
    amount: string;
    totalAmmount: string;
    flagWalletOrSp: string;
    pmntType: string;
    urn: string;
    debitamount: string;
    citizenBean: CitizenUserProfileDetails;
    walletListarr: IgrsWalletMasterBean[] = new Array();
    estampId: number;
    paymentResponseBean: IgrsPaymentResponseBean;
}

export class IgrsWalletCreditMstTxnBean {
    id: number;
    citizenId: number;
    payReqId: number;
    urn: string;
    openBalAmt: string;
    closeBalAmt: string;
    totalAmt: string;
    operationType: string;
    status: number;
    txnId: string;
    requestBy: string;
    purpose: string;
    refId: number;
    walletName: string;
    walletNameHi: string;
    txnDate: string;
    walletMstId: string;
    remarks: string;
    makerRemarks: string;
    checkerRemarks: string;

    walletCreditDetailTxnList: IgrsWalletCreditDetailTxnBean[] = new Array();
    isSpCom:number;
}

export class IgrsWalletCreditDetailTxnBean {
    id: number;
    citizenId: number;
    walletCreditId: number;
    payReqSubId: number;
    walletTypeMstId: number;
    urn: string;
    crn: string;
    challanNo: string;
    openBalAmt: string;
    closeBalAmt: string;
    totalAmt: string;
    operationType: string;
    status: number;
    purpose: string;
    walletName: string;
    walletNameHi: string;
    remarks: string;
    statusEn: string;
    statusHi:string;
    paymentType:string;
    paymentTypeHi:string;
    isSpCom:number;
    isRefundApplicable:number;
    refundPerc:number;
    finalRefundAmount:number;
}

export class IgrsSearchCertDocBean {
    major_HEAD: string;
    minor_HEAD: string;
    scheme_CODE: string;
    submajor_HEAD: string;
    purpose: string;
    amount: number;
}

export class IgrsSam1SPDataBean {

    id: number;
    userFirstName: string;
    userMiddleName: string;
    userLastName: string;
    mobileNo: number;
    licenseNo: string;
    licenseFromDate: string;
    licenseEndDate: string;
    address: string;
    education: string;
    districtId: number;
    districtName: string;
    nomineeFirstName: string;
    nomineeLastName: string;
    pnomineeRelation: string;
    nomineeMobileNo: string;
    status: number;
    fatherName: string;
    photo:string;
    drOfficeId:string;
    drOfficeName:string;
}
export class ServiceProviderLicenseDetails {
   
    id: number;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    firstNameEn: string;
    middleNameEn: string;
    lastNameEn: string;
    firstNameHi: string;
    middleNameHi: string;
    lastNameHi: string;
    motherName: string;
    motherNameHi: string;
    motherNameEn: string;    
    demography: Demography;
    addressLine1: string;
    addressLine2: string;
    city: string;    
    profilePic: string;    
    citizenType: number;
    roleMappingBean: RoleMappingBean;
    subRoleMappingBean: SubRoleMappingBean;
    juristicPerson: juisticPersonDetail;   
    serviceProvider: Serviceprovider = new Serviceprovider();    
    location: LocationBean;
    referenceId: string;
    purposeId: string;
    countryNameEn: string;
    countryNameHi: string;
    relativeName: string;
    relativeNameEn: string;
    relativeNameHi: string;
    relativeRelation: string;
    demographyId: number;
    contactNo:string;
    email:string;
    featureList: FeatureList[] = new Array();
    nameOnPan: string
}

export class RepresentativeDetailsModel {
    id: number;
    representative_user_id: number;
    brach_id: string;
    bank_master_id: number;
    ifsc_code: string;
    branch_name: string;
    address: string;
    state_id: number;
    district_id: number;
    bank_name: string;
    bank_name_hi: string;
    designation: string;
    isRepresentative: number;
    companyIdNo: string;
}
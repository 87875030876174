import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppSetting, AppSettingService } from "projects/common-lib/src/lib/helper-lib/app.setting";
import { TranslateHEService } from "projects/common-lib/src/lib/helper-lib/services/common/translateHE.service";

@Component({
  selector: "app-document-search-details",
  templateUrl: "./document-search-details.component.html",
  styleUrls: ["./document-search-details.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
  ],
})
export class DocumentSearchDetailsComponent implements OnInit {
  private settings!: AppSetting;

  step = 0;
  lan: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateHEService,
    private translate: TranslateService,
    private appSettingsService: AppSettingService
  ) {
  this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });

 
    this.translateService.currentApprovalStageMessage.subscribe((msg) => {
      this.lan = msg;
      this.translate.use(msg == 1 ? "hi" : "en");
    });
  }

  ngOnInit(): void {}

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e: any) {
    if (window.pageYOffset > 210) {
      let element = document.querySelector("#sticky_Div");
      element.classList.add("sticky");
    } else {
      let element = document.querySelector("#sticky_Div");
      element.classList.remove("sticky");
    }
  }

  setStep(index: number) {
    this.step = index;
  }
  navigateSearch() {
    this.router.navigate([]).then((result) => {
      //window.open(this.settings.searchBaseUrl + '/#/VerifyRegdocument', '_blank');
          window.open(this.settings.searchBaseUrl+'/#/clogin', '_self');
    });
  }
  navigatesearchVerifyCertifiedEregistryNo() {
    this.router.navigate([]).then((result) => {
      //window.open(this.settings.searchBaseUrl + '/#/VerifyRegdocument', '_blank');
          window.open(this.settings.searchBaseUrl+'/#/VerifyCertifiedCopy', '_blank');
    });
  }
 
  searchRedirectVerifyEstamp() {
     window.open(this.settings.searchBaseUrl + '/#/VerifyEstamp', '_blank');
  }


  searchRedirectVerifyEregistry() {
     window.open(this.settings.searchBaseUrl + '/#/VerifyRegdocument', '_blank');
  }
}

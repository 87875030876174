import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from "../../../../common-lib/src/lib/helper-lib/app.setting";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CustomHttpClient } from "../../../../common-lib/src/lib/helper-lib/httpclient/custom.httpclient";
import { TranslateService } from "@ngx-translate/core";
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { map } from "rxjs/operators";
import { CitizenUserProfileDetails, EstampPaymentUserConfigBean, saveConsederationPaymentDetails, saveOtherLoanDetails, SPLicenseConfigBean } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { juisticPersonProfileDetail } from 'projects/common-lib/src/lib/models/juristic-Person.model/juristicPersonDetails.model';
import { eSignatureBean, Serviceprovider } from 'projects/common-lib/src/lib/models/serviceprovider/serviceprovider.model';
import { Representative } from 'projects/common-lib/src/lib/models/representative/representative.model';
import { FranchiseModel } from 'projects/common-lib/src/lib/models/franchise.model/franchise.model';
import { IgrsEregPartyDetailsBean, SaveERegFourBoundryDetails, SaveERegPropertyImageDetails } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { Citizen } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.login.model';
import { UserDeedTemplateBean } from 'projects/common-lib/src/lib/models/userDeedTemplate.model/user-deed-template-bean';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';


@Injectable({
    providedIn: 'root'
})
export class CitizenService {

    private settings!: AppSetting;
    public lan: number;


    constructor(private appSettingsService: AppSettingService, private router: Router, private httpClient: HttpClient, private customeHttpClient: CustomHttpClient, private translate: TranslateService, private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
            translate.compiler
        });
    }

    updateCitizenProfile(userProfile: CitizenUserProfileDetails) {
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/update_citizen_user_profile",
                userProfile
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {
                        return error;
                    }
                )
            );
    }

    updateCitizenProfileImg(userProfile: CitizenUserProfileDetails) {
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/update_citizen_profile_img",
                userProfile
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {
                        return error;
                    }
                )
            );
    }

    activateCitizenUserProfile(userProfile: CitizenUserProfileDetails) {
        
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/activate_citizen_user_profile",
                userProfile
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        //
                        return result;
                    } else {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    updateJuristicPersonProfile(userProfile: CitizenUserProfileDetails) {
        ;
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/update_juristic_person_profile",
                userProfile
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {
                        return error;
                    }
                )
            );
    }

    generateWalletForNewUser() {
        //let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(credential) );
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/generate_wallet_for_new_user", null
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {
                        return error;
                    }
                )
            );
    }

    updatePassword(formData: FormData) {
        return this.customeHttpClient
            .PostFormApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/reset_citizen_user_password",
                formData
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        //
                        return result;
                    } else {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    get_OrganizationType() {
        //
        let httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
        });
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_organization_type", { headers: httpHeaders }
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_CompanyIdType() {
        //
        let httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
        });
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_company_id_types", { headers: httpHeaders }
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    get_IdentityProofList() {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_identity_proof"
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getComplaintReqList() {
        //
        let httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
        });
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_complaint_request_type", { headers: httpHeaders }
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }


    gstValidationData(param: any) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                //   .PostFormApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_gst_validation", param
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        //
                        return result;
                    } else {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    cinValidationData(cinNumber: string) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                //   .PostFormApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_cin_validation", cinNumber
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        //
                        return result;
                    } else {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    cinTokenData(param: any) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                //   .PostFormApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_cin_token", param
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        //
                        return result;
                    } else {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    getCinDetails(param: any) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                //   .PostFormApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/getCinDetails", param,
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        //
                        return result;
                    } else {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    //this is older approach where juristic person saved from citizen profile
    saveJuristicPersonDetail(juristicProfile: CitizenUserProfileDetails) {
        ;
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/save_juristic_person_profile",
                juristicProfile
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    get_offices_map(districtId: number) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_office?demographyid=" + districtId
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    saveApplyForLicense(serviceprovider: Serviceprovider) {
        
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/save_apply_for_license",
                serviceprovider
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    applyForSPLicense(serviceprovider: Serviceprovider) {
        
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/applyForServiceProviderLicense",
                serviceprovider
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    saveRepresentative(representative: Representative) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/save_representative",
                representative
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    saveApplyForFranchise(franchiseModel: FranchiseModel) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/save_franchise",
                franchiseModel
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    checkFranchiseUserName(username: string) {
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/check_username?username=" + username, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    refreshToken(param: any) {
        //
        return this.customeHttpClient
            .GetApiForRefreshTokenOnly<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/regenerate_token",
                param
            ).pipe(
                map((result: any) => {
                    //  
                    return result;
                },
                    (error: any) => {
                        //    
                        return error;
                    }
                )
            );
    }

    generateOtpForSP(citizen: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen) );
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/generate_otp_for_citizen", encVal //?mobile_no=" + mobile, "
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    //generate otp without captcha 
    generateOtpWithoutCaptcha(mobile: string) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/profileUpdateOtp?mobile_no=" + mobile, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    generateOtpForProfileUpdate(mobile: string) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/profileUpdateOtp?mobile_no=" + mobile, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    get_serviceProviderDetailsbyid(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_userdetails_by_spid", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    setOnlinePaymentDetails(sPLicenseConfigBeanNew: SPLicenseConfigBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/send_request_onlinepayment",
                sPLicenseConfigBeanNew
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    get_walletdetails(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_walletdetails", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    setOnlinePaymentWallet(sPLicenseConfigBeanNew: SPLicenseConfigBean) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(sPLicenseConfigBeanNew) );
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/send_request_onlinepayment_wallet",
                sPLicenseConfigBeanNew
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }


    view_walletdetails(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/view_walletdetails", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    get_serviceProviderDetailForEstampPaymentsbyid(param: any) {
        // get_userdetails_by_spid
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_userdetails_estamp_payment_by_spid", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    setOnlinePaymentDetailsForEstamp(sPLicenseConfigBeanNew: EstampPaymentUserConfigBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/send_request_onlinepayment",
                sPLicenseConfigBeanNew
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    getWalletTypeByRefId(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_wallettypeByRefId", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result.responseData;
                    } else {
                        return result.responseMessage;
                    }

                })
            );
    }

    getWalletTransactionDetails(param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getWalletTransactionDetails", param
            ).pipe(
                map((result: any) => {

                    //
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getPaymentReqDetails(urnNumber: string) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getonlinepaymentreqdetails",
                urnNumber
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    getPaymentResDetails(urnNumber: string) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getonlinepaymentresdetails",
                urnNumber
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    convertNumberToText(num: number) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/convertNumberToText?amt=" + num
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getUrnNumberbyEstampId(estampId: string) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/getUrnNumberbyEstampId/" + estampId,
                estampId
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    ////////////////////////// Start e-Registry Related Services//////////////////
    //for get other loan details
    getOtherLoanDetailsByeRegId(eRegId: any) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url
                + "common/ereg/get_other_loan_details_by_eRegId?regId=" + eRegId
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    // for save other loan details
    saveRegOtherLoanDetails(saveRegOtherLoanDetails: saveOtherLoanDetails) {
        //post
        //API save_gl_draft_suggestion 
        console.log(saveRegOtherLoanDetails);
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_gl_url
                + "common/ereg/save_ereg_loan_details",
                saveRegOtherLoanDetails
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    ////Save Khasra And Four Boundry Details
    saveERegKhasraAndFourBoundryDetails(fourBoundryDetails: SaveERegFourBoundryDetails) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_url
                + "common/ereg/savePropertyDetailsPropId",
                fourBoundryDetails
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    //save Property Images taken from 3 Different angles
    saveERegPropertyImageDetails(propertyImageDetails: SaveERegPropertyImageDetails) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_gl_url
                + "common/ereg/save_ereg_consideration_payment",
                propertyImageDetails
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    // get property details by property id
    getPropertyDetailsByPropertyId(propId: any) {
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_services_gl_url
                + "common/ereg/getPropertyDetailsByPropId?propId=" + propId
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );

    }

    ////////////////////////// end e-Registry Related Services//////////////////

    getUrnNumberEncrypted(urn: string) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getUrnNumberEncrypted/" + urn,
                urn
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    downloadSignedSpLicense(spLicence: string) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/serviceprovider/downloadSPLicensePDF/" + spLicence,
                spLicence
                // filename
            ).pipe(
                map((result: any) => {
                    if (result) {
                        // var string1 = JSON.stringify(result);
                        //   var data:any;
                        //  filename: 'yourFileName.pdf',
                        //   data= result.blob()
                        return result;
                    }
                },
                    (error: any) => {
                        alert("not found");
                        return error;
                    }
                )
            );
    }
    setWalletPaymentDetailsForEstamp(sPLicenseConfigBeanNew: EstampPaymentUserConfigBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/send_request_estamp_payment_debit_from_wallet",
                sPLicenseConfigBeanNew
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    setWalletPaymentDetailsForSearchCertified(sPLicenseConfigBean: SPLicenseConfigBean) {
        console.log(" bean ", sPLicenseConfigBean)
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/send_request_search_cert_payment_debit_from_wallet",
                sPLicenseConfigBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    sendRequestSpLicensePaymentDebitFromWallet(sPLicenseConfigBean: SPLicenseConfigBean) {
        console.log(" bean ", sPLicenseConfigBean)
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/sendRequestSpLicensePaymentDebitFromWallet",
                sPLicenseConfigBean
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }


    getWalletDetailsbyPurposeId(purposeId: string) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getWalletDetailsByPurposeId",
                purposeId
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    getWalletToDetailRouteService() {
        let resp = { status: 'Success' }
        return resp;
    }
    set(key: string, value: any) {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            console.error('Error while setting local storage', err);
        }
    }

    get(key: string): any {
        try {
            return JSON.parse(sessionStorage.getItem(key));
        } catch (err) {
            console.error('Error while getting local storage key ', key, err);
            return '';
        }
    }

    getWalletPaymentResDetails(param: {}) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getWalletTxnPaymentDetails",
                param
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }
    getUrnWalletByRefId(param: {}) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getUrnByRefIdWalletTxnPaymentDetails",
                param
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    checkForPaymentStatus(sPLicenseConfigBeanNew: EstampPaymentUserConfigBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/doubleVerificationPendingTransaction",
                sPLicenseConfigBeanNew
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }


    updatePaymentStatus(citizenId: number) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/double-verification-by-spid",
                citizenId
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    getEstampStatusbyid(estampId: number) {

        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getEstampStatusbyid", estampId
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getPaymentDetailsByCitizen(pageNo: number, pageSize: number, param: any) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getTransactionHistory/" + pageNo + "/" + pageSize, param
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );

    }
    getTransactionDetailsByCitizen(reqId: string) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getTransactionDetailsByReq/" + reqId, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );

    }
    getTransactionDetailsByCitizenByUrn(urn: string) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getTransactionDetailsByUrn/" + urn, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );

    }
    getTransactionDetailsByCitizenByUrnDept(urn: string) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/getTransactionDetailsByUrnDept/" + urn, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );

    }
    getOtpforWalletPayment(totalDuty: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/generate_otp_for_wallet_payment/" + totalDuty, totalDuty
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }
    verifyOtpForWallet(mobileNo: string, otp: string, userId: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/verify_otp_for_wallet_payment?mobile_no=" + mobileNo + "&otp=" + otp + "&userId=" + userId, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    //for save consederation payment details
    saveERegconsiderationPayment(saveConsederationPayment: any) {
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_services_url
                + "common/ereg/save_ereg_consideration_payment",
                saveConsederationPayment
            ).pipe(
                map((result: any) => {
                    if (result) {
                        return result;
                    }
                },
                    (error: any) => {

                        return error;
                    }
                )
            );
    }

    getTransactionDetailsByCitizenBySlotId(slotId: string) {

        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/getTransactionDetailsBySlotId/" + slotId, ""
            ).pipe(
                map((result: any) => {

                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );

    }

    payFromWalletService(paymentbean: any) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/send_request_ereg_payment_debit_from_wallet", paymentbean
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    sendSmsForEsign(partyObj: IgrsEregPartyDetailsBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/ereg/sendSmsForEsign", partyObj

            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getAllUserDeedTemplateList(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/getUserDeedTemplateList", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getUserDeedTemplate(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/getUserDeedTemplate", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    addDeedTemplate(userDeedTemplateBean: UserDeedTemplateBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/saveUserDeedTempalate", userDeedTemplateBean
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    updateDeedTemplate(userDeedTemplateBean: UserDeedTemplateBean) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/updateUserDeedTempalate", userDeedTemplateBean
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }
    deleteDeedTemplate(param: any) {
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/delateUserDeedTempalate", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }

    getActiveUserDeedTemplateList(param: any) {
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_services_url + "citizen/getActiveUserDeedTemplateList", param
            ).pipe(
                map((result: any) => {
                    return result;
                })
            );
    }


  get_transactionCount(param: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/get_wallet_treasury_transaction_count", param
      ).pipe(
        map((result: any) => {

          //
          if (result && result.responseStatus == 'true') {
            return result.responseData;
          } else {
            return result.responseMessage;
          }

        })
      );
  }
  getPaymentDetailsForSPWallate(pay_purpose_option:any){
   
    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url+ "citizen/get_userdetails_search_doc/"+pay_purpose_option,{}
    ).pipe(
        map((result: any) => {
  
            if (result && result.responseStatus == 'true') {
                return result.responseData;
            } else {
                return result;
            }
  
  
        })
    );
      }


      updateSpWalletPaymentStatus(serviceprovider: Serviceprovider){
        return this.customeHttpClient
        .PostApiToken<ResultModel<any>>(
            this.settings.igrs_gateway_url + "citizen/updateSpPaymentStatusForWallet", serviceprovider
        ).pipe(
            map((result: any) => {
                return result;
            })
        );

     }
  get_FinancialYearBySelectedDate(selectedDate:string) {
    //
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_gl_url + "common/gl/fetchFinancialYearBySelectedDate?selectedDate=" + selectedDate
      ).pipe(
        map((result: any) => {

          //
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }
  checkServiceProiderNumber(mobileNo:any, userId: any) {
    return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "citizen/checkServiceProviderNumber?mobile_no="+mobileNo+"&user_id="+userId,{}
           
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {

                    return error;
                }
            )
        );
}

   getSam1SPUserDetail(licenseNo: String) {
        
    return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "citizen/getSPDetailsSam1?licenceNo=" + licenseNo ,{}            
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {

                    return error;
                }
            )
        );
}

generateOtpForSPSam1(mobile: String) {
    return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url + "citizen/verifyNumberSPSam1?mobile_no=" + mobile, ""
        ).pipe(
            map((result: any) => {

                // 
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
}


  checkMinimumBalanceAndBalanceAmount(minAmount: number, balanceAmount: number, userProfile:CitizenUserProfileDetails):boolean {
    let paymentWalletValidate: boolean = false;
    if (minAmount < balanceAmount || (userProfile.isServiceProvider==undefined || userProfile.isServiceProvider==0|| userProfile.isServiceProvider==null || userProfile.serviceProvider.status!=19)) {
      paymentWalletValidate = true;
    }
    return paymentWalletValidate;
  }

  getPayPurposeList(otherPayServ:Number){
   
    return this.customeHttpClient
    .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url+ "citizen/getPayPurposeList/"+otherPayServ,{}
    ).pipe(
        map((result: any) => {
  
            if (result && result.responseStatus == 'true') {
                return result;
            } else {
                return result;
            }
  
  
        })
    );
      }
      getPayPurposeRefList(otherPayServ:Number){
   
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url+ "citizen/getPayPurposeRefList/"+otherPayServ,{}
        ).pipe(
            map((result: any) => {
      
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }
      
      
            })
        );
          }
      getPayPurposeListDept(otherPayServ:Number){
   
        return this.customeHttpClient
        .GetApi<ResultModel<any>>(
            this.settings.igrs_gateway_url+ "department/getPayPurposeList/"+otherPayServ,{}
        ).pipe(
            map((result: any) => {
      
                if (result && result.responseStatus == 'true') {
                    return result.responseData;
                } else {
                    return result;
                }
      
      
            })
        );
          }

          getBase64OfFileRefund(param: any) {
            return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_base64_from_file_refund", param
            ).pipe(
                map((result: any) => {
    
                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
    
                })
            );
        }

        getBase64OfFileRefundDept(param: any) {
            return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "department/get_base64_from_file_refund", param
            ).pipe(
                map((result: any) => {
    
                    // 
                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }
    
                })
            );
        }
} 

<div class="row" *ngIf="componentFlag=='columnwise'">
    <div class="col-md-12">
        <div class="alert alert-danger" role="alert" *ngIf="demographynofound">
            {{'thereisnooffice' | translate}}
      </div>
    </div>

    <!-- for state -->

    <div class="col-md-12" *ngIf="isState">
        <div class="form-group">
            <label>{{'demography-common.select-state' | translate}}</label>
            <ng-select  (change)="onChange($event)"
            placeholder="{{'demography-common.select-state' | translate}}" appendTo="body" [virtualScroll]="true">

                <ng-option value="">{{'demography-common.select-state' | translate}} </ng-option>
                <ng-option *ngFor="let d of stateList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>


    <div class="col-md-12" *ngIf="isZoneList">
        <div class="form-group">
            <label> {{'demography-common.select-zone' | translate}}</label>
            <ng-select  (change)="onChange($event, 'isZoneList')"  [clearable]="false"
            appendTo="body" [virtualScroll]="true"
                placeholder="{{'demography-common.select-zone' | translate}}">
                <ng-option value="" disabled>{{'demography-common.select-zone' | translate}}</ng-option>
                <ng-option *ngFor="let d of zoneList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="isDivision">
        <label>{{'demography-common.select-division' | translate}} <span class="rstarcolor">*</span></label>
        <ng-select  (change)="onChange($event)">
            <ng-option value="">{{'demography-common.select-division' | translate}}</ng-option>
            <ng-option *ngFor="let d of divisionList" value="{{d.id}}">
                {{ d.demographyName }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
    </div> -->

    <!-- [(ngModel)]="distictid" -->

    <div class="col-md-12" *ngIf="isDistrict">
        <div class="form-group">
            <label> {{'demography-common.select-district' | translate}}</label>
            <ng-select   (change)="onChange($event, 'isDistrict')"  [(ngModel)]="distictid"
            placeholder="{{'demography-common.select-district' | translate}}" [clearable]="false"
            appendTo="body" [virtualScroll]="true">
                <ng-option value="" disabled>{{'demography-common.select-district' | translate}}</ng-option>
                <ng-option *ngFor="let d of districtList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-12" *ngIf="isSrOffice">
        <div class="form-group">
            <label>{{'demography-common.select-srooffice' | translate}}<span class="rstarcolor">*</span></label>
            <ng-select   (change)="onChange($event, 'isSrOffice')"  [(ngModel)]="srofficeid"
            placeholder="{{'demography-common.select-srooffice' | translate}}" [clearable]="false"
            appendTo="body" [virtualScroll]="true">
                <ng-option value="" disabled>{{'demography-common.select-srooffice' | translate}}</ng-option>
                <ng-option *ngFor="let d of srOfficeList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-12" *ngIf="showAreaType">
        <div class="form-group">
            <label class="w-100">{{'demography-common.select-area-type' | translate}}</label>
            <div class="form-check form-check-inline mt-2" (click)="onItemChange(urban)" style="cursor: pointer;">
                <input class="form-check-input" [disabled]="isdisableAreaType"  [(ngModel)]="areaType" #urban   type="radio"
                    name="statusfilter" id="statusfilter1" value="U">
                <label class="form-check-label" for="statusfilter1">{{'urban' | translate}}</label>
            </div>
            <div class="form-check form-check-inline" (click)="onItemChange(rural)" style="cursor: pointer;">
                <input class="form-check-input" [disabled]="isdisableAreaType"  [(ngModel)]="areaType" #rural type="radio"
                    name="statusfilter" id="statusfilter2" value="R">
                <label class="form-check-label" for="statusfilter2">{{'rural' | translate}}</label>
            </div>

        </div>
    </div>

    <div class="col-md-12" *ngIf="isLocalBody">
        <div class="form-group">
            <label> {{'demography-common.select-localbody' | translate}}</label>
            <ng-select  [disabled]="isdisableLocalBody"  (change)="onChange($event)" [clearable]="false"   
            placeholder="{{'demography-common.select-localbody' | translate}}" [(ngModel)]="localBody"
            appendTo="body" [virtualScroll]="true">
                <ng-option value="" disabled>{{'demography-common.select-localbody' | translate}}</ng-option>
                <ng-option *ngFor="let d of localBodyList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>


    <div class="col-md-12 mb-2" *ngIf="isWardList">
        <label>{{'demography-common.select-ward' | translate}}</label>
        <ng-select   [disabled]="isdisableWard"  (change)="onChange($event)"  [clearable]="false" 
        placeholder="{{'demography-common.select-ward' | translate}}" [(ngModel)]="wardNgselect"
        appendTo="body" [virtualScroll]="true">
            <ng-option value="" disabled>{{'demography-common.select-ward' | translate}}</ng-option>
            <ng-option *ngFor="let d of wardList" [value]="d">
                <!-- {{ d.demographyName }} -->
                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
    </div>

    <div class="col-md-12" *ngIf="isWardColonyList">
        <label> {{'demography-common.select-ward-colony' | translate}}</label>
        <ng-select  [disabled]="isdisableWardColony" (change)="onChange($event)" [clearable]="false" 
        placeholder="{{'demography-common.select-ward-colony' | translate}}" [(ngModel)]="wardColonyNgselect"
        appendTo="body" [virtualScroll]="true">
            <ng-option value="" disabled>{{'demography-common.select-ward-colony' | translate}}</ng-option>
            <ng-option *ngFor="let d of wardColonyList" [value]="d">
                <!-- {{ d.demographyName }} -->
                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
    </div>

    <div class="col-md-12" *ngIf="isTehsilList">
        <div class="form-group">
            <label> {{'demography-common.select-tehsil' | translate}}</label>
            <ng-select  [disabled]="isdisableTahsil" (change)="onChange($event)" [clearable]="false"  
            placeholder="{{'demography-common.select-tehsil' | translate}}" [(ngModel)]="tehsilNgselect"
            appendTo="body" [virtualScroll]="true">
                <ng-option value="" disabled>{{'demography-common.select-tehsil' | translate}}</ng-option>
                <ng-option *ngFor="let d of tehsilList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>


    <div class="col-md-12  mb-2" *ngIf="isVillageList">
        <label class="col-md-12 p-0"> {{'demography-common.select-village' | translate}}</label>
        <ng-select [disabled]="isdisableVillage" (change)="onChange($event)" [clearable]="false" 
        placeholder="{{'demography-common.select-village' | translate}}" class="col-md-12 p-0" [(ngModel)]="villageNgselect"
        appendTo="body" [virtualScroll]="true">
            <ng-option value="" disabled>{{'demography-common.select-village' | translate}}</ng-option>
            <ng-option *ngFor="let d of villageList" [value]="d">
                <!-- {{ d.demographyName }} -->
                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
    </div>

     <div class="col-md-12" *ngIf="isVillageCollony">
        <label class="col-md-12 p-0">{{'demography-common.select-village-collony' | translate}} </label>
        <ng-select  [disabled]="isdisableVillageColony"(change)="onChange($event)" [clearable]="false" 
        placeholder="{{'demography-common.select-village-collony' | translate}}" class="col-md-12 p-0" [(ngModel)]="villageColonyNgselect"
        appendTo="body" [virtualScroll]="true">
            <ng-option value="" disabled>{{'demography-common.select-village-collony' | translate}}</ng-option>
            <ng-option *ngFor="let d of villageCollony" [value]="d">
                <!-- {{ d.demographyName }} -->
                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
    </div>

    <!-- <div class="col-md-12">
        <div *ngIf="selectAreaTypeErrormsg==true" class="customerrormsg">
            {{'mandatory' | translate}}
        </div>
    </div> -->
</div>

<!-- rowwise div vinita -->
 <div class="row" *ngIf="componentFlag=='rowwise'">
    <div class="col-md-12">
        <div class="alert alert-danger" role="alert" *ngIf="demographynofound">
            {{'thereisnooffice' | translate}}
      </div>
    </div>



    <div class="col-md-12" *ngIf="isState">
        <div class="form-group">
            <label>{{'demography-common.select-state' | translate}}</label>
            <ng-select  (change)="onChange($event)" appendTo="body" [virtualScroll]="true"
            placeholder="{{'demography-common.select-state' | translate}}">

                <ng-option value="">{{'demography-common.select-state' | translate}} </ng-option>
                <ng-option *ngFor="let d of stateList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>


    <div class="col-md-4" *ngIf="isZoneList">
        <div class="form-group">
            <label> {{'demography-common.select-zone' | translate}}</label>
            <ng-select  (change)="onChange($event, 'isZoneList')" 
            appendTo="body" [virtualScroll]="true"
        placeholder="{{'demography-common.select-zone' | translate}}"  [(ngModel)]="zoneNgselect">
                <ng-option value="">{{'demography-common.select-zone' | translate}}</ng-option>
                <ng-option *ngFor="let d of zoneList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>



    <div class="col-md-4" *ngIf="isDistrict">
        <div class="form-group">
            <label> {{'demography-common.select-district' | translate}}</label>
            <ng-select   (change)="onChange($event, 'isDistrict')"  [(ngModel)]="distictid"
            placeholder="{{'demography-common.select-district' | translate}}"
            appendTo="body" [virtualScroll]="true">
                <ng-option value="">{{'demography-common.select-district' | translate}}</ng-option>
                <ng-option *ngFor="let d of districtList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="isSrOffice">
        <div class="form-group">
            <label>  {{'demography-common.select-srooffice' | translate}}</label>
            <ng-select   (change)="onChange($event, 'isSrOffice')"  [(ngModel)]="srofficeid"
            placeholder="{{'demography-common.select-srooffice' | translate}}"
            appendTo="body" [virtualScroll]="true">
                <ng-option value="">{{'demography-common.select-srooffice' | translate}}</ng-option>
                <ng-option *ngFor="let d of srOfficeList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>
    <div class="col-md-4" *ngIf="showAreaType">
        <div class="form-group">
            <label class="w-100">{{'demography-common.select-area-type' | translate}}</label>
            <div class="form-check form-check-inline mt-2" (click)="onItemChange(urban)" style="cursor: pointer;">
                <input class="form-check-input"  [disabled]="isdisableAreaType" [(ngModel)]="areaType" #urban   type="radio"
                    name="statusfilter" id="statusfilter1" value="U">
                <label class="form-check-label" for="inlineRadio1">{{'urban' | translate}}</label>
            </div>
            <div class="form-check form-check-inline" (click)="onItemChange(rural)" style="cursor: pointer;">
                <input class="form-check-input"  [disabled]="isdisableAreaType" [(ngModel)]="areaType" #rural type="radio"
                    name="statusfilter" id="statusfilter1" value="R">
                <label class="form-check-label" for="inlineRadio2">{{'rural' | translate}}</label>
            </div>

        </div>
    </div>

    <div class="col-md-4" *ngIf="isLocalBody">
        <div class="form-group">
            <label> {{'demography-common.select-localbody' | translate}}</label>
            <ng-select [disabled]="isdisableLocalBody"  (change)="onChange($event)" 
             placeholder="{{'demography-common.select-localbody' | translate}}" [clearable]="false" [(ngModel)]="localBody"
             appendTo="body" [virtualScroll]="true">
                <ng-option value="">{{'demography-common.select-localbody' | translate}}</ng-option>
                <ng-option *ngFor="let d of localBodyList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>


    <div class="col-md-4 mb-2" *ngIf="isWardList">
        <div class="form-group">
        <label>{{'demography-common.select-ward' | translate}}</label>
        <ng-select  [disabled]="isdisableWard" (change)="onChange($event)" 
        placeholder="{{'demography-common.select-ward' | translate}}" [clearable]="false" [(ngModel)]="wardNgselect" 
        appendTo="body" [virtualScroll]="true">
            <ng-option value="">{{'demography-common.select-ward' | translate}}</ng-option>
            <ng-option *ngFor="let d of wardList" [value]="d">

                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
        </div>
    </div>

    <div class="col-md-4" *ngIf="isWardColonyList">
        <div class="form-group">
        <label> {{'demography-common.select-ward-colony' | translate}}</label>
        <ng-select  (change)="onChange($event)" placeholder="{{'demography-common.select-ward-colony' | translate}}" [clearable]="false" 
        [(ngModel)]="wardColonyNgselect"  appendTo="body" [virtualScroll]="true">
            <ng-option value="">{{'demography-common.select-ward-colony' | translate}}</ng-option>
            <ng-option *ngFor="let d of wardColonyList" [value]="d">

                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
        </div>
    </div>

    <div class="col-md-4" *ngIf="isTehsilList">
        <div class="form-group">
            <label> {{'demography-common.select-tehsil' | translate}}</label>
            <ng-select  [disabled]="isdisableTahsil"  (change)="onChange($event)"  [clearable]="false" 
            placeholder="{{'demography-common.select-tehsil' | translate}}" [(ngModel)]="tehsilNgselect" appendTo="body" [virtualScroll]="true">
                <ng-option value="">{{'demography-common.select-tehsil' | translate}}</ng-option>
                <ng-option *ngFor="let d of tehsilList" [value]="d">
                    {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
                </ng-option>
            </ng-select>
            <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
                <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
            </div>
        </div>
    </div>


    <div class="col-md-4  mb-2" *ngIf="isVillageList">
        <div class="form-group">
        <label class=""> {{'demography-common.select-village' | translate}}</label>
        <ng-select [disabled]="isdisableVillage" (change)="onChange($event)"  [clearable]="false" 
        placeholder="{{'demography-common.select-village' | translate}}"   [(ngModel)]="villageNgselect"
        appendTo="body" [virtualScroll]="true">
            <ng-option value="">{{'demography-common.select-village' | translate}}</ng-option>
            <ng-option *ngFor="let d of villageList" [value]="d">

                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
        </div>
    </div>

     <div class="col-md-4" *ngIf="isVillageCollony">
        <div class="form-group">
        <label>{{'demography-common.select-village-collony' | translate}} </label>
        <ng-select  (change)="onChange($event)" placeholder="{{'demography-common.select-village-collony' | translate}}" [clearable]="false"  
        [(ngModel)]="villageColonyNgselect" appendTo="body" [virtualScroll]="true">
            <ng-option value="">{{'demography-common.select-village-collony' | translate}}</ng-option>
            <ng-option *ngFor="let d of villageCollony" [value]="d">

                {{ lan==0?d.demographyNameEn:d.demographyNameHi }}
            </ng-option>
        </ng-select>
        <div *ngIf="isEnableDistrictreqred" class="invalid-feedback">
            <div *ngIf="isEnableDistrictreqred">{{'mandatory' | translate}}</div>
        </div>
        </div>
    </div>

</div>

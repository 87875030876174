import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MapMyindiaService } from 'projects/common-lib/src/lib/helper-lib/services/MapMyindia.service';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';
import { error } from 'console';



@Injectable({
  providedIn: 'any'
})
export class PropertyValuationService {
  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient, private http: HttpClient,private mapmyindia:MapMyindiaService,private _AESEncryptDecryptService: AESEncryptDecryptService) {

    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
      this.settings = settings;

    });
  }
  flag: boolean = true;
  getDemograghyByName(dName: any) {
    // demography="+demography_id+"&
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/searchdemographybyname?searchText=" + dName
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getLatLongDetailsByGIS(url:any){
    return this.customeHttpClient.getGISMap(url).pipe(
      map((result:any)=>{
        console.log("gis result",result);
        return result;
      })
    )

  }

  getAllSubClauses() {
    //
    // demography="+demography_id+"&
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/get_all_subclauses"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  getAllPropertyType() {
    //
    // demography="+demography_id+"&
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/get_all_property_type"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  unmapKhashraByWebgisId(webgisId:number,propertyValuationRequestBean:PropertyValuationRequestBean){

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"citizen/pv/indentifation/unmapKhashraByWebgisId?webgisId="+webgisId,propertyValuationRequestBean
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }

  getAllUOM() {
    //
    // demography="+demography_id+"&
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/get_all_uom"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  getKhasraRoadBufferDistance() {

    let obj={uid:23084440269177913031101001}
    return this.customeHttpClient
      .PostGISApi<ResultModel<any>>(
        "https://geoportal.mp.gov.in/igr_powerbiservice/Service.svc/rest/GetIGRSKhasraRoad",obj
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  propValuation(propObj:any){

    return this.customeHttpClient
    .Post<ResultModel<any>>(
      this.settings.igrs_services_url+"common/pv/calculatePropertyValuation",propObj
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
  // getDemographyDataByLatlng(lat:any,lng:any){
  //   return this.customeHttpClient
  //   .Get<ResultModel<any>>(
  //     this.settings.igrs_services_gis_url + "common/gis/getColonyID?lat=" + lat +"&long="+lng

  //   ).pipe(
  //     map((result: any) => {
  //       return result;
  //     })
  //   );
  // }

  propIndentifation(propObj:any){

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"citizen/pv/indentifation/saveIndentifiedProperty",propObj
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }
  // getDemographyDataByLatlng(lat:any,lng:any){
  //   return this.customeHttpClient
  //   .Get<ResultModel<any>>(
  //     this.settings.igrs_services_gis_url + "common/gis/getColonyID?lat=" + lat +"&long="+lng

  //   ).pipe(
  //     map((result: any) => {
  //       return result;
  //     })
  //   );
  // }

  getDemographyDataByLatlng(lat:any,lng:any){
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_gis_url + "common/gis/getPropertyByLatLong?lat=" + lat +"&long="+lng

    ).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

getCurrentGLbyDemography(demographyId:any){
  return this.customeHttpClient
  .Get<ResultModel<any>>(
    this.settings.igrs_services_gl_url + "common/gl/getCurrentGLbyDemography?demographyId=" + demographyId
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}

getCurrentGLbyColonyId(col_Id:any){
  return this.customeHttpClient
  .Get<ResultModel<any>>(
    this.settings.igrs_services_gl_url + "common/gl/getCurrentGLbyColonyId?colonyId=" + col_Id 
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}
/*
getKhasraAndOwnerByKhasraId(khasraId:any[],propertyValuationRequestBean:PropertyValuationRequestBean,propertyImageFlag:boolean){

  return this.customeHttpClient
  .Post<ResultModel<any>>(
    this.settings.igrs_services_gis_url+"common/getKhasraAndOwnerByKhasraId?unqId="+khasraId.join(",")+"&&propertyImageFlag="+propertyImageFlag,propertyValuationRequestBean
  ).pipe(
    map((result:any)=>{
      return result;
    })
  )
}*/
getKhasraAndOwnerByKhasraId(khasraId:any[],propertyValuationRequestBean:PropertyValuationRequestBean,propertyImageFlag:boolean,isValuationModule?:boolean){

  let encVal = this._AESEncryptDecryptService.encrypt("unqId="+khasraId.join(",")+"&propertyImageFlag="+propertyImageFlag+"&isValuationModule="+isValuationModule);
  //let encValPv = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(propertyValuationRequestBean) );
  return this.customeHttpClient
  .Post<ResultModel<any>>(
    this.settings.igrs_services_gis_url+"common/getKhasraAndOwnerByKhasraId?request="+encVal,
    propertyValuationRequestBean
    
  ).pipe(
    map((result:any)=>{
      return result;
    })
  )
} 

addMoreKhashraByWorkGroupId(unqId:any,workGroupId:number){
  return this.customeHttpClient
  .Get<ResultModel<any>>(
    this.settings.igrs_services_gis_url + "common/addKhasraAOwnerByKhasraId?unqId=" + unqId+"&workGroupId="+workGroupId
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}

getpropertyResponseByValId(valTxnId:any,fromCheckerModule:boolean,fromCMSModule:boolean){
  let controllerName:string = "citizen";
  if(fromCheckerModule||fromCMSModule){
    controllerName = "department";
  }
  return this.customeHttpClient
  .GetApi<ResultModel<any>>(
    this.settings.igrs_services_url +controllerName+ "/pv/indentifation/getpropertyResponseByValId?valTxnId=" + valTxnId ,{}
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}

// getKhasraAndOwnerByKhasraId1(khasraId:any[]){



//   return this.customeHttpClient
//   .Get<ResultModel<any>>(
//     this.settings.igrs_services_gis_url + "common/getKhasraAndOwnerByKhasraId?unqId="+khasraId.join(",")
//   ).pipe(
//     map((result: any) => {
//       return result;
//     })
//   );
// }

getpnrdPropertyinfo(propId:any,isValuationModule?:boolean){
  return this.customeHttpClient
  .Get<ResultModel<any>>(
    this.settings.igrs_services_gis_url + "common/pnrdPropertyinfo?PropertyId=" + propId+"&isValuationModule="+isValuationModule
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}



//for Valuation
getmpenagarpalikaPropertyinfo(enpId:any,isValuationModule?:boolean){
  return this.customeHttpClient
  .Get<ResultModel<any>>(
    this.settings.igrs_services_gis_url + "common/mpenagarpalikaPropertyinfo?searchBy=PropertyNo&filter=" + enpId+"&isValuationModule="+isValuationModule
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}

//for ereg comment
// getmpenagarpalikaPropertyinfom(khasraId:any){
//   //let encVal = this._AESEncryptDecryptService.encrypt("searchBy=PropertyNo&filter=" + khasraId);
//   return this.customeHttpClient
//   .Get<ResultModel<any>>(
//     this.settings.igrs_services_gis_url + "common/mpenagarpalikaPropertyinfom?searchBy=PropertyNo&filter=" + khasraId
//   ).pipe(
//     map((result: any) => {
//       return result;
//     })
//   );
// }

selectDemography(demographyParentId:any,type_id?:number){
  let encVal = this._AESEncryptDecryptService.encrypt("parent_id="+demographyParentId+"&type_id="+type_id);
  return this.customeHttpClient
  .Get<ResultModel<any>>(
    this.settings.igrs_gateway_url + "common/get_demographies_map?request="+encVal
  ).pipe(
    map((result: any) => {
      return result;
    })
  );
}


//-Rahul------------------------------------------------------------------------------------------------------------------------------------------
  getPlaceSuggetion<T>(url:string, param:any):Observable<T>{
    let querystring='';
    if(param !== null){
        param.updates.forEach((param:any, i:number)=>{
            //console.log(i, param);
            if(i === 0){
                querystring='?'+param.param+'='+param.value;
            }else{
                querystring=querystring+'&'+param.param+'='+param.value;
            }
        })
    }
    //console.log(querystring);
    return this.http.jsonp<T | any>(url+querystring, 'callback').pipe(
        map((data:any) => {
            if (!data) {
                throw { Message: data, Type: "Failed" };
            }
            return data;
        }),catchError(err=> throwError(this.handleError(err)))
    );
  }

  // getPlaceSuggetion<T>(url:string, param:any):Observable<T>{
  //   let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json').set('Authorization', 'Bearer '+this.mapmyindia.getToken());
  //   return this.http.get<T | any>(url, {headers:httpHeaders, params:param}).pipe(
  //   map((data:any) => {
  //       if (!data) {
  //           throw { Message: data, Type: "Failed" };
  //       }
  //       return data;
  //   }),catchError(err=> throwError((err)))
  //   );
  // }

  customgetRequest<T>(methodType:any, url:string, param:any, tocken:string):Observable<T>{
    let httpHeaders = new HttpHeaders().set('Accept-Language', 'application/json').set('Authorization', `Bearer ${tocken}`);
    return this.http.request<T | any>(methodType, url,{headers:httpHeaders, params:param}).pipe(
        map((data:any) => {
            if (!data) {
                throw { Message: data, Type: "Failed" };
            }
            return data;
        }),catchError(err=> throwError(this.handleError(err)))
      );
  }

  handleError = (error: HttpErrorResponse) => {
   // console.log('Handle on Component', error);
  };
  changeUserStatus(id: any) {

    return this.customeHttpClient
        .GetApi<ResultModel<any>>(
          this.settings.igrs_services_url + "department/update_department_user_status_by_id", id
        ).pipe(
            map((result: any) => {

                //

                return result;


            })
        );
}
  savePropertySubclauseMaster(PropertySubclauseMasterObject:any){

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"department/master/addSubclause",PropertySubclauseMasterObject
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  }


  updatePropertySubclauseMasterById(propertySubclauseMaster:any){

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"department/master/updateSubclause",propertySubclauseMaster
    ).pipe(
      map((result:any)=>{
        return result;
        console.log(result);

      })
    )
  }
  getAllPropertySubclauseMasterDetails() {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url+"department/master/getAllSubclause",{}
      ).pipe(
        map((result: any) => {

          return result;


        })
      )
  }


  addProperty(property:any){

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"department/master/addPropertyType",property
    ).pipe(
      map((result:any)=>{
        return result;
        console.log(result);

      })
    )
  }
  updateProperty(propertyType:any){

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"department/master/updateProperty",propertyType
    ).pipe(
      map((result:any)=>{
        return result;
        console.log(result);

      })
    )
  }

  getAllPropertyNames() {

      return this.customeHttpClient
          .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url+"department/master/getPropertyName",{}
          ).pipe(
              map((result: any) => {

          return result;


        })
      )
  }
  getPropertyNameDropDown() {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_services_url+"department/master/getAllName",{}
      ).pipe(
        map((result: any) => {

                  return result;


        })
      )
  }

  get_propertyByid(id: number) {
    let param={id:id}
     return this.customeHttpClient
      .GetApi<ResultModel<any>>(
          this.settings.igrs_services_url + "department/master/get_propertybyid",param
      ).pipe(
          map((result: any) => {

                  return result;

              })
          );
  }

  getAllByNameAndStatus(param:any) {

    return this.customeHttpClient
    .PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url+"department/master/getPropNameAndStatus",param
    ).pipe(
      map((result: any) => {

        return result;
      },
      (error:any)=>{

          return error;
      }

      )
    )
  }

  getPropertyByEregId(id: number) {

     return this.customeHttpClient
      .Get<ResultModel<any>>(
          this.settings.igrs_services_url + "citizen/pv/indentifation/getIndentifiedPropertyByEregId/"+id
      ).pipe(
          map((result: any) => {

                  return result;

              })
          );
  }


  getPropertyByGeoId(id:string) {
    return this.customeHttpClient.Get<ResultModel<any>>(
      this.settings.igrs_services_url + "common/ereg/getPropImgDetailsByReferenceId?referenceId="+id,
     ).pipe(
         map((result: any) => {

             if (result && result.responseStatus == 'true') {
                 return result;
             } else {
                 return result;
             }

         })
     );


  }

  getstateGeom() {
    return this.http.get<ResultModel<any>>(
      "assets/data/mpstate.json",
     ).pipe(
         map((result: any) => {

             if (result) {
                 return result;
             } else {
                 return result;
             }

         })
     );


  }
  getKhasraMapOwnerByKhasraId(khasraId:any,webGisId:number){

    let encVal = this._AESEncryptDecryptService.encrypt("unqId="+khasraId+"&&webGisId="+webGisId);
    //let encValPv = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(propertyValuationRequestBean) );
   // let eRegIdLet = this._AESEncryptDecryptService.encrypt(id.toString())
    return this.customeHttpClient
    .Get<ResultModel<any>>(
      this.settings.igrs_services_gis_url+"common/getKhasraMapByLandParcel?request="+encVal,
      
      
    ).pipe(
      map((result:any)=>{
        return result;
      })
    )
  } 
  //-End------------------------------------------------------------------------------------------------------------------------------------------
}

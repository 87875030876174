import { CommercialConstructionType } from "./CommercialConstructionType";
import { ResidentialConstructionType } from "./ResidentialConstructionType";
import { ResidentialCumCommercialConstructionType } from "./ResidentialCumCommercialConstructionType";

export class IndependentBuildingTypeBean{
    	
	floorId:number;
	resiConstType:ResidentialConstructionType ;
	commConstType:CommercialConstructionType;
	industrialConstructionType:ResidentialConstructionType;
	healthAreaConstructionType:ResidentialConstructionType;
	eduAreaConstructionType:ResidentialConstructionType;
	resiCumCommType:ResidentialCumCommercialConstructionType;
    indbulflow: IndependentBuildingTypeBean;
    otherAreaConstructionType: ResidentialConstructionType;
	constCost:number
}
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { UserProfileModel } from "projects/common-lib/src/lib/models/auth.userdetail.model/user.profile.model";
import { FeatureList } from "projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model";
@Injectable()
export class AuthGuardCitizen implements CanActivate {
     featureList?: FeatureList[];
    userProfileModel:UserProfileModel;
    constructor(private router: Router) { 
  
    }
  
  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
      let url: string = state.url;
      console.log(url)
      console.log(next)
      let path =url.replace('/citizen/', '');
      this.featureList = JSON.parse(sessionStorage.getItem("featureList") || "");
      // this.featureList?.forEach(url=>{
      //   if(url.featureUrl!=path){
      //     this.router.navigate(['/clogin']);
      //     return url.featureUrl!=path

      //   }
      //  return url.featureUrl!=path
      
     
      

      // })
      let result=this.hasMatchingUrl(this.featureList,path)
      if(!result){
        this.router.navigate(['/clogin']);
      }
      else{

      }
    
      return this.checkLogin(url);
    }
    hasMatchingUrl(array:FeatureList[], path:string) {
      return array.some(url => url?.featureUrl=== path);
    }
  
    checkLogin(url: string): any {
     

      if (sessionStorage.getItem('authData') != undefined && sessionStorage.getItem('authData') != '') {
        this.userProfileModel = JSON.parse(sessionStorage.getItem('authData') || "");
        if((this.userProfileModel.mobileVerifyStr!=null && this.userProfileModel.mobileVerifyStr=='true')
          && (this.userProfileModel.emailVerifyStr!=null && this.userProfileModel.emailVerifyStr=='true')){
            sessionStorage.setItem('isUserLoggedIn','true');
        } 
      
      }
      ;
   
      
      if (sessionStorage.getItem('isUserLoggedIn')!=undefined&&sessionStorage.getItem('isUserLoggedIn')!=null&&sessionStorage.getItem('isUserLoggedIn')=='true') {
        
         if (sessionStorage.getItem('loginType') == "citizen") {
          
          
          if (url.includes("citizen") === true) {
            this.router.parseUrl(url);
            return true;
         
          } else {
            // sessionStorage.setItem('isUserLoggedIn','');
            this.router.navigate(['/']);
          }
      
        } else {
          
          sessionStorage.setItem('isUserLoggedIn','');
          this.router.navigate(['/']);
          return true;
        }
      } else {
        ;
        
        sessionStorage.setItem('isUserLoggedIn','');
        if (sessionStorage.getItem('loginType') == "admin") {
          this.router.navigate(['/alogin']);
        } else if (sessionStorage.getItem('loginType') == "citizen") {
          this.router.navigate(['/clogin']);
        } else {
          this.router.navigate(['/']);
        }
        return true;
      }
    }
  }
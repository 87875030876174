export class DutyCalculationData {

 principalStampDutyType:string;
 principalStampDuty:number;
 municipalDuty:number;
 janpadDuty:number;
 upkarDuty:number;
 minStampDuty:number;
 maxStampduty:number;
  minStampDutyType:string;
  maxStampDutyType:string;
  minStampDutyTypeHi:string;
  maxStampDutyTypeHi:string;
  registrationFeeType:string;
  registrationFeeTypeHi:string;
 registrationFee:number;
 minRegistrationFee:number;
 maxRegistrationFee:number;

  totalPayableStampDutyPer:number;	
  totalPayableRegFeePer:number;
  totalPayableStampAndRegPer:number;
  principalStampDutyTypeHi:string;

	municipalDutyType:string;
	municipalDutyTypeHi:string;
	municipalDutyMinType:string;
	municipalDutyMinTypeHi:string;
	municipalDutyMaxType:string;
	municipalDutyMaxTypeHi:string;
	janpadDutyType:string;
	janpadDutyTypeHi:string;
	janpadDutyMinType:string;
	janpadDutyMinTypeHi:string;
	janpadDutyMaxType:string;
	janpadDutyMaxTypeHi:string;
	upkarDutyType:string;
	upkarDutyTypeHi:string;
	upkarDutyMinType:string;
	upkarDutyMinTypeHi:string;
	upkarDutyMaxType:string;
	upkarDutyMaxTypeHi:string;
	minRegistrationFeeType:string;
	maxRegistrationFeeType:string;
	minRegistrationFeeTypeHi:string;
	maxRegistrationFeeTypeHi:string;
	
}

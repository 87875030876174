import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DefaultServiceService {

  constructor() { }

  private defaultType$ = new BehaviorSubject<any>({} as any);
  
  setDefaultType(defaultType: any) {
    this.defaultType$.next(defaultType);
  }

  getDefaultType() {
    return this.defaultType$.asObservable();
  }



}
